<template>
  <div>
    <div
      class="customer-offer-detail-wrapper row vh-100"
      v-if="!loading">
      <div class="d-sm-none d-md-none d-lg-block col-lg-3"></div>
      <div class="col-sm-12 col-md-12 col-lg-6 centered-content">
        <div class="buttons">
          <div>
            <button class="btn print-btn">
              <i class="fa-light fa-print mr-2"></i> {{$t('Print')}}
            </button>
            <button class="btn download-btn">
              <i class="fa-solid fa-down-to-bracket mr-2"></i> {{$t('Download')}}
            </button>
            <button
              class="btn accept-offer-btn"
              @click="$router.push(`/customer/offer/${$route.params.token}`)">
              {{$t('Accept Offer')}}
            </button>
          </div>
        </div>
        <div class="image-offer-id">
          <div>
            <img
              :src="offer.account.asset_path"
              width="auto"
              height="50" v-if="offer.account.asset_path" />
            <img
              src="../../../assets/images/logo.png"
              width="auto"
              height="50" v-else />
          </div>
          <div class="offer-id">{{$t('Offer').toLocaleUpperCase()}} #{{ offer.id }}</div>
        </div>

        <div class="d-flex justify-content-between mt-3">
          <div></div>
          <div class="name-address-email">
            <span class="name">{{ offer.branch ? offer.branch.name : '' }}</span>
            <span class="address">
              {{ offer.branch ? offer.branch.address : '' }}
            </span>
            <span class="email-phone">
              <span>{{ offer.branch ? offer.branch.email : '' }}</span>
              <span>{{ offer.branch ? ' | '+ offer.branch.phone : '' }}</span>
            </span>
          </div>
        </div>

        <div>
          <div class="d-flex justify-content-between">
            <div class="customer-name">
              {{ offer.customer.name }} {{ offer.customer.surname }}
            </div>
            <div></div>
          </div>

          <div class="d-flex justify-content-between align-items-end">
            <div class="customer-address-text mt-2">
              <div
                class="d-flex"
                v-if="offer.customer.address">
                <span>{{ offer.customer.address.address }}</span>
                <span
                  class="ml-1"
                  v-if="offer.customer.address.district"
                  >{{ ' ' + offer.customer.address.district.name }}</span
                >
                <span
                  class="ml-1"
                  v-if="offer.customer.address.city"
                  >{{ ' / ' + offer.customer.address.city.name }}</span
                >
              </div>
            </div>
            <div class="d-flex justify-content-between w-35">
              <div class="left-text">{{$t('Offer No')}}:</div>
              <div class="right-text">#{{ offer.id }}</div>
            </div>
          </div>

          <div class="d-flex justify-content-between align-items-center mt-2">
            <div class="customer-address-text">
              {{ offer.customer.email }} | {{ offer.customer.phone }}
            </div>
            <div class="d-flex justify-content-between w-35">
              <div class="left-text">{{$t('Offer Date')}}:</div>
              <div class="right-text">
                {{ offer.offer_date ? offer.offer_date : '-' }}
              </div>
            </div>
          </div>

          <div class="d-flex justify-content-between align-items-center mt-2">
            <div></div>
            <div class="d-flex justify-content-between w-35">
              <div class="left-text">{{$t('Offer End Date')}}:</div>
              <div class="right-text">
                {{
                  offer.expiration_at
                    ? offer.expiration_at.substring(0, 16)
                    : '-'
                }}
              </div>
            </div>
          </div>

          <div class="mt-5 table-responsive">
            <table class="table table-sm">
              <thead>
                <tr>
                  <th
                    scope="col"
                    class="text-left">
                    {{$t('Product/Service')}}
                  </th>
                  <th
                    scope="col"
                    class="text-center">
                    {{$t('Quantity')}}
                  </th>
                  <th
                    scope="col"
                    class="text-center">
                    {{$t('Amount')}}
                  </th>
                  <th
                    scope="col"
                    class="text-center">
                    {{$t('Discount')}}
                  </th>
                  <th
                    scope="col"
                    class="text-center">
                    {{$t('VAT')}}
                  </th>
                  <th
                    scope="col"
                    class="text-right">
                    {{$t('Total')}}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in offer.items"
                  :key="item.$index">
                  <td class="text-left">{{ item.name }}</td>
                  <td class="text-center">{{ item.quantity }}</td>
                  <td class="text-center">{{ item.price }}</td>
                  <td class="text-center">{{ item.discount_amount }}</td>
                  <td class="text-center">{{ item.tax_amount }}</td>
                  <td class="text-right">{{ item.amount }}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="mt-3 d-flex justify-content-between">
            <div></div>
            <div class="summary-card">
              <div class="summary-card-item">
                <div class="left-text">{{$t('Sub Total')}}:</div>
                <div class="right-text">{{ offer.total_price }}</div>
              </div>
              <div class="summary-card-item mt-2">
                <div class="left-text">{{$t('VAT')}}:</div>
                <div class="right-text">{{ offer.total_tax }}</div>
              </div>
              <div class="summary-card-item mt-2">
                <div class="left-text">{{$t('Gross Discount')}}:</div>
                <div class="right-text">{{ offer.total_discount }}</div>
              </div>
              <div class="summary-card-item mt-2">
                <div class="left-text">{{$t('Cargo Price')}}:</div>
                <div class="right-text">{{ offer.total_cargo }}</div>
              </div>

              <div class="summary-card-item mt-4">
                <div class="left-text fw-700">{{$t('General Total')}}:</div>
                <div class="right-text fw-700">{{ offer.total_amount }}</div>
              </div>
            </div>
          </div>

          <div class="mt-5 d-flex justify-content-between align-baseline">
            <div class="left-infos w-50 pt-0">
              <div
                v-if="offer.cargo_delivery"
                class="mb-3">
                <div class="title">{{$t('Delivery')}}:</div>
                <div class="content">{{$t('Delivery by cargo')}}</div>
              </div>

              <!--                <div class="title">Ödeme Yöntemi:</div>-->
              <!--                <div class="content">Banka / Kredi Kartı</div>-->
            </div>
            <div
              class="right-infos w-50 pt-0"
              v-if="offer.notes">
              <div class="title">{{$t('Notes')}}:</div>
              <div class="content">{{ offer.notes }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-sm-none d-md-none d-lg-block col-lg-3"></div>
    </div>
    <div
      v-else
      class="customer-offer-detail-wrapper row">
      <div class="d-sm-none d-md-none d-lg-block col-lg-3"></div>
      <div class="col-sm-12 col-md-12 col-lg-6 centered-content vh-100">
        <content-placeholders :rounded="true">
          <content-placeholders-img />
          <content-placeholders-text :lines="15" />
        </content-placeholders>
      </div>
      <div class="d-sm-none d-md-none d-lg-block col-lg-3"></div>
    </div>
  </div>
</template>

<script>
import axios from "@/plugins/axios";

export default {
  name: 'CustomerOffer',
  data() {
    return {
      loading: true,
      offer: {}
    };
  },
  mounted() {
    this.getOfferDetail();
  },
  methods: {
    getOfferDetail() {
      this.loading = true;
      axios.get('/tire/offers/from-token',{
        headers: {
          Authorization: `Bearer ${this.$route.params.token}`
        }
      }).then((response) => {
        this.offer = response.data.responseData.tireOffer;
        this.offer.total_tax = window.helper.currenctFormat(
          'tr-TR',
          this.offer.total_tax
        );
        this.offer.total_discount = window.helper.currenctFormat(
          'tr-TR',
          this.offer.total_discount
        );
        this.offer.total_price = window.helper.currenctFormat(
          'tr-TR',
          this.offer.total_price
        );
        this.offer.total_amount = window.helper.currenctFormat(
          'tr-TR',
          this.offer.total_amount
        );
        this.offer.total_cargo = window.helper.currenctFormat(
          'tr-TR',
          this.offer.total_cargo
        );
        this.offer.items.forEach((item) => {
          item.discount_amount = 0;
          item.tax_amount = window.helper.currenctFormat(
            'tr-TR',
            item.tax_amount
          );
          item.price = window.helper.currenctFormat('tr-TR', item.price);
          item.amount = window.helper.currenctFormat('tr-TR', item.amount);
          item.discounts.forEach(
            (discount) => (item.discount_amount += discount.discount_amount)
          );
          item.discount_amount = window.helper.currenctFormat(
            'tr-TR',
            item.discount_amount
          );
        });
        this.$store.commit('offer/setTireOffer', this.offer);
        this.loading = false;
      });
    }
  }
};
</script>

<style scoped>
.customer-offer-detail-wrapper {
  width: 100%;
}
.centered-content {
  background-color: #eaeaea;
  padding: 1.5rem;
}
.buttons {
  display: flex;
  justify-content: flex-end;
}
.print-btn,
.print-btn:hover {
  color: #777;
  border: 1px solid #777;
  font-size: 12px;
  margin-right: 1rem;
  padding: 8px 20px;
}

.download-btn,
.download-btn:hover {
  color: #777;
  border: 1px solid #777;
  font-size: 12px;
  margin-right: 1rem;
  padding: 8px 20px;
}

.accept-offer-btn,
.accept-offer-btn:hover {
  color: #fff;
  background-color: #1c1c1c;
  font-size: 12px;
  padding: 8px 20px;
}

.image-offer-id {
  margin-top: 3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.image-offer-id .offer-id {
  font-size: 24px;
  font-weight: 700;
  color: #1c1c1c;
}
.name-address-email {
  text-align: right;
  display: flex;
  flex-direction: column;
  width: 45%;
}
.name-address-email .name {
  font-weight: 700;
  color: #1c1c1c;
  font-size: 18px;
}

.name-address-email .address,
.name-address-email .email-phone {
  font-weight: 400;
  color: #1c1c1c;
  font-size: 13px;
  margin-top: 0.5rem;
}

.customer-name {
  font-weight: 700;
  color: #1c1c1c;
  font-size: 18px;
}
.customer-address-text {
  text-align: left;
  font-weight: 400;
  color: #1c1c1c;
  font-size: 13px;
  width: 40%;
}
.left-text {
  font-weight: 400;
  color: #1c1c1c;
  font-size: 13px;
  text-align: left;
}
.right-text {
  font-weight: 600;
  color: #1c1c1c;
  font-size: 13px;
  text-align: right;
}
.w-35 {
  width: 35%;
}
.summary-card {
  background-color: #dedede;
  padding: 0.75rem 1rem;
  width: 50%;
  border: 1px solid #cdcdcd;
  border-radius: 10px;
}
.summary-card-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.summary-card-item .left-text,
.summary-card-item .right-text {
  font-size: 12px;
  font-weight: 400;
  color: #0a2540;
}
.fw-700 {
  font-weight: 700 !important;
}
.title {
  font-size: 12px;
  font-weight: 700;
  color: #1c1c1c;
}
.content {
  font-size: 12px;
  font-weight: 400;
  color: #1c1c1c;
}
</style>
