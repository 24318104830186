<template>
  <div id="app">
    <!--    <LeftNavigation />-->

    <div
        id="left-sidebar-wrapper-g"
        @mouseover="changeW100()"
        @mouseleave="removeW100()"
        :class="{
      'w-100': expanded_menu === true,
      'max-width-100': !expanded_menu
    }">
      <div
          id="left-sidebar-wrapper"
          :class="{ 'max-width-100': expanded_menu === false }">
        <!-- @mouseover="expanded_menu = true" @mouseleave="expanded_menu = false" -->


        <span id="toggle-ico" class="neu" @click="menuToggle">
        <i id="toggle-icon" class="fa-solid" :class="expanded_menu ? 'fa-arrow-left-from-line' : 'fa-arrow-right-from-line'"></i>
          <!--          <i class="fa-regular fa-circle-dot" :class="expanded_menu ? '' : ''"></i>-->
      </span>

        <div style="min-height: 60px;">
          <router-link class="expand-logo" to="/" >
            <img
                alt="Servislet Pro"
                class="logo mt-3"
                :class="expanded_menu ? 'opacity-100' : 'opacity-0'"
                src="../assets/images/logo.png" />
          </router-link>
          <router-link to="/" class="d-block expand-mobil-logo">

            <img
                alt="Servislet Pro"
                class="mobile-logo mt-3"
                :class="!expanded_menu ? 'opacity-100' : 'opacity-0'"
                src="../assets/images/mobile-logo.png" />

          </router-link>
          <!--          <router-link class="expand-logo" :class="!expanded_menu ? 'd-block opacity-0 h-0' : ''" to="/" >-->
          <!--            <img-->
          <!--                alt="Servislet Pro"-->
          <!--                class="logo mt-3"-->
          <!--                src="../assets/images/logo.png" />-->
          <!--          </router-link>-->
          <!--          <router-link to="/" class="expand-mobil-logo" :class="expanded_menu ? 'd-block opacity-0 h-0' : ''">-->
          <!--            <div class="d-flex justify-content-center">-->
          <!--              <img-->
          <!--                  alt="Servislet Pro"-->
          <!--                  class="mobile-logo mt-3"-->
          <!--                  src="../assets/images/mobile-logo.png" />-->
          <!--            </div>-->
          <!--          </router-link>-->
        </div>

        <ul id="navigation-list">
          <router-link to="/">
            <li
                class="hover-center"
                :class=" expanded_menu === false ? 'justify-content-center' : '' "
                v-b-toggle.sum-toggle>
              <div class="navigation-list-flex">
                <i class="fa-regular fa-home menu-ico"></i>
                <span
                    class="menu-txt"
                    :class="expanded_menu ? 'd-block' : 'd-none'"
                >{{ $t('Dashboard') }}</span
                >
              </div>
            </li>
          </router-link>

          <router-link to="/customers">
            <!--            :class="{'router-link-exact-active': currentPath === '/customer-add' || currentPath.includes('/customer/') }"-->
            <li class="hover-center"
                :class=" expanded_menu === false ? 'justify-content-center' : '' ">
              <div class="navigation-list-flex">
                <i class="fa-regular fa-user menu-ico"></i>
                <span
                    class="menu-txt"
                    :class="expanded_menu ? 'd-block' : 'd-none'"
                >{{ $t('Customers') }}</span
                >
              </div>
            </li>
          </router-link>
          <router-link
              :class="{
            'router-link-exact-active':
              currentPath === '/car-add' || currentPath.includes('/car/')
          }"
              to="/cars">
            <li class="hover-center"
                :class=" expanded_menu === false ? 'justify-content-center' : '' ">
              <div class="navigation-list-flex">
                <i class="fa-regular fa-car menu-ico"></i>
                <!-- v-if="expanded_menu === false" -->
                <span
                    :class="expanded_menu ? 'd-block' : 'd-none'"
                    class="menu-txt"
                >{{ $t('Cars') }}</span
                >
              </div>
            </li>
          </router-link>
          <router-link
              :class="{
            'router-link-exact-active':
              currentPath === '/product-add' ||
              currentPath.includes('/product-detail/')
          }"
              to="/products">
            <li class="hover-center"
                :class=" expanded_menu === false ? 'justify-content-center' : '' ">
              <div class="navigation-list-flex">
                <i class="fa-regular fa-box menu-ico"></i>
                <!-- v-if="expanded_menu === false" -->
                <span
                    :class="expanded_menu ? 'd-block' : 'd-none'"
                    class="menu-txt"
                >{{ $t('Products') }}</span
                >
              </div>
            </li>
          </router-link>
          <router-link to="/services">
            <!-- /products-view-2 -->
            <li class="hover-center"
                :class=" expanded_menu === false ? 'justify-content-center' : '' ">
              <div class="navigation-list-flex">
                <i class="fa-regular fa-wrench-simple menu-ico"></i>
                <!-- v-if="expanded_menu === false" -->
                <span
                    :class="expanded_menu ? 'd-block' : 'd-none'"
                    class="menu-txt"
                >{{ $t('Services') }}</span
                >
              </div>
            </li>
          </router-link>
          <router-link
              :class="{
            'router-link-exact-active':
              currentPath === '/add-sale' || currentPath.includes('/sale/')
          }"
              to="/sales">
            <li class="hover-center"
                :class=" expanded_menu === false ? 'justify-content-center' : '' ">
              <div class="navigation-list-flex">
                <i class="fa-regular fa-cart-shopping menu-ico"></i>
                <!-- v-if="expanded_menu === false" -->
                <span
                    :class="expanded_menu ? 'd-block' : 'd-none'"
                    class="menu-txt"
                >{{ $t('Sales') }}</span
                >
              </div>
            </li>
          </router-link>
          <router-link
              :class="{
            'router-link-exact-active':
              currentPath === '/add-offer' || currentPath.includes('/offer/')
          }"
              to="/offers">
            <li class="hover-center"
                :class=" expanded_menu === false ? 'justify-content-center' : '' ">
              <div class="navigation-list-flex">
                <i class="fa-regular fa-copy menu-ico"></i>
                <!-- v-if="expanded_menu === false" -->
                <span
                    :class="expanded_menu ? 'd-block' : 'd-none'"
                    class="menu-txt"
                >{{ $t('Offers') }}</span
                >
              </div>
            </li>
          </router-link>
          <router-link
              :class="{
            'router-link-exact-active': currentPath === '/add-appointment'
          }"
              to="/calendar">
            <li class="hover-center"
                :class=" expanded_menu === false ? 'justify-content-center' : '' ">
              <div class="navigation-list-flex">
                <i class="fa-regular fa-calendar menu-ico"></i>
                <!-- v-if="expanded_menu === false" -->
                <span
                    :class="expanded_menu ? 'd-block' : 'd-none'"
                    class="menu-txt"
                >{{ $t('Calendar') }}</span
                >
              </div>
            </li>
          </router-link>
          <li @click="collapse = !collapse" class="hover-center cursor-pointer"
              :class=" expanded_menu === false ? 'justify-content-center' : '' ">
            <div class="navigation-list-flex finance-li" :class="expanded_menu === true ? 'w-100' : '' ">
              <i class="fa-regular fa-credit-card menu-ico"></i>
              <!-- v-if="expanded_menu === false" -->
              <div class="d-flex justify-content-between align-items-center w-100">
                <span :class="expanded_menu ? 'd-block' : 'd-none'" class="menu-txt">{{ $t('Finance') }}</span>
                <i class="fa-regular fa-angle-down menu-txt" style="padding-right: 12px;" :class="expanded_menu ? 'd-block' : 'd-none'"></i>
              </div>
            </div>
          </li>
          <b-collapse
              :visible="collapse"
              id="finance-toggle"
              :class="{ 'expanded-menu-toggle-txt': expanded_menu === false }">
            <router-link to="/payments">
              <li class="hover-center">
                {{ $t('Payments') }}
              </li>
            </router-link>
            <router-link to="/invoices" :class="{disabled: disabled}">
              <li class="hover-center"
                  :class=" expanded_menu === false ? 'justify-content-center' : '' ">
                {{ $t('Invoices') }}
              </li>
            </router-link>
          </b-collapse>
          <router-link
              :class="{ disabled: disabled }"
              to="/reviews">
            <li class="hover-center"
                :class=" expanded_menu === false ? 'justify-content-center' : '' ">
              <div class="navigation-list-flex">
                <i class="fa-regular fa-message menu-ico"></i>
                <!-- v-if="expanded_menu === false" -->
                <span
                    :class="expanded_menu ? 'd-block' : 'd-none'"
                    class="menu-txt"
                >{{ $t('Reviews') }}</span
                >
              </div>
            </li>
          </router-link>
          <router-link
              :class="{ disabled: disabled }"
              to="#">
            <li class="hover-center"
                :class=" expanded_menu === false ? 'justify-content-center' : '' ">
              <div class="navigation-list-flex">
                <i class="fa-regular fa-file-lines menu-ico"></i>
                <!-- v-if="expanded_menu === false" -->
                <span
                    :class="expanded_menu ? 'd-block' : 'd-none'"
                    class="menu-txt"
                >{{ $t('Reports') }}</span
                >
              </div>
            </li>
          </router-link>
          <a href="https://pro-cdn.servislet.com/Servislet-pro-support.pdf" target="_blank">
            <li class="hover-center"
                :class=" expanded_menu === false ? 'justify-content-center' : '' ">
              <div class="navigation-list-flex">
                <i class="fa-regular fa-question-circle menu-ico"></i>
                <span
                    :class="expanded_menu ? 'd-block' : 'd-none'"
                    class="menu-txt"
                >{{ $t('Help & Support') }}</span
                >
              </div>
            </li>
          </a>

          <router-link
              :class="{
            'router-link-exact-active': currentPath === '/integrations'
          }"
              to="/integrations">
            <li class="hover-center"
                :class=" expanded_menu === false ? 'justify-content-center' : '' ">
              <div class="navigation-list-flex">
                <i class="fa-regular fa-link menu-ico"></i>
                <!-- v-if="expanded_menu === false" -->
                <span
                    :class="expanded_menu ? 'd-block' : 'd-none'"
                    class="menu-txt"
                >{{ $t('Integration') }}</span
                >
              </div>
            </li>
          </router-link>
          <router-link
              :class="{ disabled: disabled }"
              to="#">
            <li class="hover-center"
                :class=" expanded_menu === false ? 'justify-content-center' : '' ">
              <div class="navigation-list-flex">
                <i class="fa-regular fa-gear menu-ico"></i>
                <span
                    :class="expanded_menu ? 'd-block' : 'd-none'"
                    class="menu-txt"
                >{{ $t('Settings') }}</span
                >
              </div>
            </li>
          </router-link>
          <router-link
              :class="{ disabled: disabled }"
              to="#">
            <li
                class="go-pro hover-center p-2"
                :class=" expanded_menu === false ? 'justify-content-center' : '' "
            >
            <span
                :class="expanded_menu ? 'd-block' : 'd-none'"
                class="menu-txt"
            >{{ $t('Upgrate Plan') }}</span
            >
              <b :class="expanded_menu ? 'd-block' : 'd-none'">PRO</b>
              <b :class="!expanded_menu ? 'd-block' : 'd-none'">PRO</b>
              <i
                  :class="expanded_menu ? 'd-block' : 'd-none'"
                  class="fa-regular fa-angle-right"></i>
            </li>
          </router-link>
        </ul>
        <span class="version-text">Version 1.0</span>
      </div>
    </div>
    <div
        id="main-content"
        :class="$store.state.sidemenu.show ? 'w-260' : 'w--100'">
      <TopBar />
      <slot></slot>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import LeftNavigation from '@/components/LeftNavigation.vue';
import TopBar from '@/components/TopBar.vue';

export default {
  name: 'DefaultLayout',
  components: {
    LeftNavigation,
    TopBar
  },
  data() {
    return {
      disabled: true,
      width:0,
      collapse: false,
      currentPath: localStorage.currentPath,
      expanded_menu: localStorage.getItem('show_menu') ? JSON.parse(localStorage.getItem('show_menu')) : this.$store.state.sidemenu.show
    };
  },
  watch:{
    width(){
      this.closeMenu();
    },
    currentPath(){
      this.closeMenu();
    }
  },
  methods:{
    closeMenu(){
      if(this.width<992){
        this.expanded_menu = false
        this.$store.dispatch('sidemenu/dispatchMenu', false);
        localStorage.setItem('show_menu', false);
      }
    },
    menuToggle() {
      this.expanded_menu = !this.expanded_menu
      this.$store.dispatch('sidemenu/dispatchMenu', this.expanded_menu);
      localStorage.setItem('show_menu', this.expanded_menu);
    },
    handleResize() {
      this.width = window.innerWidth;
    },
    changeW100(){
      if(!this.expanded_menu){
        var arrow = document.getElementById('toggle-icon')
        arrow.style.transform = 'rotate(0.5turn)';

        var li = document.getElementsByClassName("finance-li");
        for (var i = 0; i < li.length; i++) {
          li[i].classList.add('w-100');
        }

        var elements = document.getElementsByClassName("menu-txt");
        for (var i = 0; i < elements.length; i++) {
          elements[i].classList.replace('d-none','d-block');
        }

        var el = document.getElementsByClassName("logo");
        for (var i = 0; i < el.length; i++) {
          el[i].classList.replace('opacity-0','opacity-100');
          // el[i].classList.replace('opacity-0','opacity-100');
          // el[i].classList.remove('h-0');
          // el[i].classList.add('d-block')
        }

        var eml = document.getElementsByClassName("mobile-logo");
        for (var i = 0; i < eml.length; i++) {
          eml[i].classList.replace('opacity-100','opacity-0');
          // eml[i].classList.replace('opacity-100','opacity-0');
          // eml[i].classList.add('h-0');
          // eml[i].classList.add('d-none')
        }

        var hc = document.getElementsByClassName("hover-center");
        for (var i = 0; i < hc.length; i++) {
          hc[i].classList.replace('justify-content-center','justify-content-between');
        }
        var w100 = document.getElementsByClassName("w--100");
        for (var i = 0; i < w100.length; i++) {
          w100[i].classList.replace('w--100','w-100-hover');
        }
      }
    },
    removeW100(){
      if(!this.expanded_menu){
        this.collapse = false
        var arrow = document.getElementById('toggle-icon')
        arrow.style.transform = 'rotate(-1turn)';

        var li = document.getElementsByClassName("finance-li");
        for (var i = 0; i < li.length; i++) {
          li[i].classList.remove('w-100');
        }

        var elements = document.getElementsByClassName("menu-txt");
        for (var i = 0; i < elements.length; i++) {
          elements[i].classList.replace('d-block','d-none');
        }
        var el = document.getElementsByClassName("logo");
        for (var i = 0; i < el.length; i++) {
          el[i].classList.replace('opacity-100','opacity-0');
          // el[i].classList.replace('opacity-100','opacity-0');
          // el[i].classList.remove('d-block');
          // el[i].classList.add('h-0')
        }

        var eml = document.getElementsByClassName("mobile-logo");
        for (var i = 0; i < eml.length; i++) {
          eml[i].classList.replace('opacity-0','opacity-100');
          // eml[i].classList.replace('opacity-0','opacity-100');
          // eml[i].classList.remove('h-0');
          // eml[i].classList.replace('d-none','d-block')
        }

        var hc = document.getElementsByClassName("hover-center");
        for (var i = 0; i < hc.length; i++) {
          hc[i].classList.replace('justify-content-between','justify-content-center');
        }

        var w100 = document.getElementsByClassName("w-100-hover");
        for (var i = 0; i < w100.length; i++) {
          w100[i].classList.replace('w-100-hover','w--100');
        }
      }
    }
  },
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  }
};
</script>

<style scoped>
#app {
  display: flex;
}
#main-content {
  padding: 24px 24px 12px 24px;
}

.margin-content {
  margin-left: 100px;
}
.disabled {
  opacity: 0.5;
  pointer-events: none;
}

#toggle-ico {
//position: absolute;
//top: 10px;
//right: 10px;
//cursor: pointer;

  position: absolute;
  top: 0px;
  right: 1px;
  cursor: pointer;
  width: 28px;
  height: 28px;
  background: #f9f9f9;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0 0 5px 5px;
  color: #b3b3b3;
}
#toggle-icon{
  transition: .5s all;
}
.menu-ico {
  padding-left: 0.5rem;
}

.p-left {
  padding-left: 0.5rem !important;
}

.p-right {
  padding-right: 0.5rem !important;
}

.max-width-100 {
  max-width: 100px !important;
  width: 100% !important;
  min-width: 100px !important;
}

.max-width-100 .menu-ico {
  padding-left: 0;
}

.max-width-100 .p-left {
  padding-left: 0 !important;
}
.w-260 {
  width: calc(100% - 230px);
}
.w--100 {
  width: calc(100% - 100px);
}
.max-width-100:hover {
  max-width: 230px !important;
}
.max-width-100:hover .menu-ico {
  padding-left: 0.5rem;
}
.h-0{
  position: absolute;
}
@media screen and (min-width: 992px) {
  .w-100-hover{
    min-width: calc(100% - 100px);
  }
}
</style>
