import Vue from 'vue';
import Vuex from 'vuex';
import axios from '@/plugins/axios';

Vue.use(Vuex);

const state = {
    warehouses: []
};

const mutations = {
    setWarehouses(state, data) {
        state.warehouses = data;
    }
};

const actions = {
    fetchWarehouses({commit}, data) {
        try {
            axios.get(`/warehouses`, {
                params: {
                    is_active: data.is_active ?? undefined
                }
            }).then((response) => {
                commit('setWarehouses', response.data.responseData.warehouses);
                return true
            })
            return true
        } catch (error) {
            console.error(error);
            return false
        }
    }
};

const getters = {
    getTireWidthsData(state) {
        return state.widths;
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};
