// store/carYears.js

import Vue from 'vue';
import Vuex from 'vuex';
// import axios from 'axios';
import axios from '@/plugins/axios';

// const TOKEN = localStorage.userData ? 'Bearer '+JSON.parse(localStorage.userData).access_token : ''
// const API_BASE_URL = 'https://staging-pro-api.servislet.com/api/v1/web/main'

Vue.use(Vuex);

const state = {
  years: []
};

const mutations = {
  setCarYearsData(state, data) {
    state.years = data;
  }
};

const actions = {
  fetchCarYears({ commit }, car_brand_id) {
    axios
      .get(`/cars/years?car_brand_id=${car_brand_id}`, {})
      .then((response) => {
        commit('setCarYearsData', response.data.responseData.carYears);
      })
      .catch((error) => {
        console.error(error);
      });
  }
};

const getters = {
  getCarYearsData(state) {
    return state.years;
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
