<template>
  <div>
    <div id="star-rating-wrapper">
      <div class="stars">
        <i
          class="fa-solid fa-star"
          v-for="star in 5"
          :key="star"></i>
      </div>
      <div class="number">
        <span>(95)</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
