/* eslint-disable */
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import globals from './globals.js';
import helpers from "@/helpers";

import store from './store';
import i18n from './i18n';

Vue.mixin({
  methods: {
    ...helpers
  }
})

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App)
}).$mount('#app');
