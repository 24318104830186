// store/carBrands.js

import Vue from 'vue';
import Vuex from 'vuex';
// import axios from 'axios';
import axios from '@/plugins/axios';

// const TOKEN = localStorage.userData ? 'Bearer '+JSON.parse(localStorage.userData).access_token : ''
// const API_BASE_URL = 'https://staging-pro-api.servislet.com/api/v1/web/main'

Vue.use(Vuex);

const state = {
  districts: []
};

const mutations = {
  setDistrictsData(state, data) {
    state.districts = data;
  }
};

const actions = {
  fetchDistricts({ commit }, city_id) {
    axios
      .get(`/regions/districts?city_id=${city_id}`, {})
      .then((response) => {
        commit('setDistrictsData', response.data.responseData.districts);
      })
      .catch((error) => {
        console.error(error);
      });
  }
};

const getters = {
  getDistrictsData(state) {
    return state.districts;
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
