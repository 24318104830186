<template>
  <DefaultLayout>
    <div>
      <PageBreadcrumbs :breadcrumbs="breadcrumbs" />
      <div id="car-adding-steps-wrapper">
        <div class="car-adding-steps">
          <div
            class="car-adding-step-item"
            :class="{ activeCarAddingStep: carStep }">
            <div>
              <i class="fa-regular fa-car"></i>
            </div>
            <div>
              <p class="car-adding-step-title">{{ $t('Car') }}</p>
            </div>
          </div>
          <div class="car-adding-step-indicator">
            <i class="fa-solid fa-angle-right"></i>
          </div>
          <div
            class="car-adding-step-item"
            :class="{ activeCarAddingStep: customerStep }">
            <div>
              <i class="fa-regular fa-user"></i>
            </div>
            <div>
              <p class="car-adding-step-title">{{ $t('User') }}</p>
            </div>
          </div>
        </div>
      </div>
      <div
        class="row"
        id="car-add-main-wrapper">
        <div class="col-lg-9">
          <ValidationObserver ref="customerAddRules">
            <div class="product-detail-left-wrapper">
              <div id="car-add-wrapper">
                <div
                  id="car-step"
                  v-if="carStep === true">
                  <p class="page-title">{{ $t('Add Car') }}</p>
                  <div class="car-add-row">
                    <b-form-group class="mr-6">
                      <label for="plate">{{$t('Plate')}} <span class="text-danger">*</span></label>
                      <ValidationProvider
                        :name="$t('Plate')"
                        rules="required|max:20"
                        #default="{ errors }">
                        <input
                            class="form-control"
                          :placeholder="$t('Plate')"
                          v-model="car_plate"
                          @input="formatCarPlate()"
                            maxlength="20"
                          required />
                        <label
                          for="car_plate"
                          v-if="errors.length > 0"
                          class="text-danger"
                          ><small>*</small>{{ errors[0] }}.</label
                        >
                      </ValidationProvider>
                    </b-form-group>

                    <b-form-group class="mr-6">
                      <label for="chassis_number">{{$t('Chassis number')}}</label>
                      <ValidationProvider
                        :name="$t('Chassis number')"
                        rules="min:17|max:17"
                        #default="{ errors }">
                        <input
                            maxlength="17"
                            class="form-control"
                          :placeholder="$t('Chassis number')"
                          v-model="car_chassis_number" />
                        <label
                          for="car_chassis_number"
                          v-if="errors.length > 0"
                          class="text-danger"
                          ><small>*</small>{{ errors[0] }}.</label
                        >
                      </ValidationProvider>
                    </b-form-group>
                    <b-form-group class="visibility-hidden">
                      <b-form-input></b-form-input>
                    </b-form-group>
                  </div>
                  <p class="page-title">{{ $t('Car Information') }}</p>
                  <div class="car-add-row mt-3">
                    <b-form-group class="mr-6">
                      <label for="brand">{{$t('Brand')}} <span class="text-danger">*</span></label>
                        <b-form-select
                            v-model="car_brand"
                            @change="setCarBrand(car_brand.car_brand_id)">
                          <b-form-select-option
                              :value="brand"
                              v-for="brand in carBrands"
                              :key="brand.$index"
                          >{{ brand.name }}</b-form-select-option
                          >
                        </b-form-select>
                        <label for="brand" v-if="brand_error" class="text-danger"><small>*</small>{{ $t('Brand field is required.') }}</label>

                    </b-form-group>
                    <b-form-group class="mr-6">
                      <label for="year">{{$t('Year')}} <span class="text-danger">*</span></label>
                      <!-- <b-form-select v-model="selected" :options="options"></b-form-select> -->
                      <b-form-select
                        v-model="car_year"
                        @change="setCarYear()">
                        <b-form-select-option
                          :value="year"
                          v-for="year in carYears"
                          :key="year.$index"
                          >{{ year }}</b-form-select-option
                        >
                      </b-form-select>
                      <label
                          for="year"
                          v-if="year_error"
                          class="text-danger"
                      ><small>*</small>{{ $t('Year field is required.') }}</label
                      >
                    </b-form-group>
                    <b-form-group>
                      <label for="model">{{$t('Model')}} <span class="text-danger">*</span></label>
                      <b-form-select
                        v-model="car_model"
                        @change="setCarModel()">
                        <b-form-select-option
                          :value="model"
                          v-for="model in carModels"
                          :key="model.$index"
                          >{{ model.car_model_name }}</b-form-select-option
                        >
                      </b-form-select>
                      <label
                          for="model"
                          v-if="model_error"
                          class="text-danger"
                      ><small>*</small>{{ $t('Model field is required.') }}</label
                      >
                    </b-form-group>
                  </div>
                  <div class="car-add-row">
                    <b-form-group class="mr-6">
                      <label for="version">{{$t('Version')}} <span class="text-danger">*</span></label>
                      <b-form-select
                        v-model="car_version"
                        @change="version_error = false; findCar()">
                        <b-form-select-option
                          :value="version"
                          v-for="version in carVersions"
                          :key="version.$index"
                          >{{
                            version.version_name_1 +
                            ' ' +
                            version.version_name_2
                          }}</b-form-select-option
                        >
                      </b-form-select>
                      <label
                          for="version"
                          v-if="version_error"
                          class="text-danger"
                      ><small>*</small>{{ $t('Version field is required.') }}</label
                      >
                    </b-form-group>
                    <b-form-group class="visibility-hidden">
                      <b-form-input></b-form-input>
                    </b-form-group>
                    <b-form-group class="visibility-hidden">
                      <b-form-input></b-form-input>
                    </b-form-group>
                  </div>
                  <p class="page-title">{{ $t('Tire Information') }}</p>
                  <div class="car-add-row mt-3">
                    <b-form-group
                        :label="$t('Brand')"
                      class="mr-6">
                      <b-form-select
                        v-model="tire_brand"
                        @change="setTireBrand(tire_brand.slug)">
                        <b-form-select-option
                          :value="brand"
                          v-for="brand in tireBrands"
                          :key="brand.$index"
                          >{{ brand.name }}</b-form-select-option
                        >
                      </b-form-select>
                    </b-form-group>
                    <b-form-group
                      :label="$t('Width')"
                      class="mr-6">
                      <b-form-input
                        v-model="tire_width"
                        class="input"
                        type="number"></b-form-input>
                      <!-- <b-form-select v-model="tire_width"  @change="setTireWidth()">
                                        <b-form-select-option :value="width" v-for="width in tireWidths" :key="width.$index">{{ width }}</b-form-select-option>
                                    </b-form-select> -->
                    </b-form-group>
                    <b-form-group :label="$t('Height')">
                      <b-form-input
                        v-model="tire_height"
                        class="input"
                        type="number"></b-form-input>
                      <!-- <b-form-select v-model="tire_height" @change="setTireHeight()">
                                        <b-form-select-option :value="height" v-for="height in tireHeights" :key="height.$index">{{ height }}</b-form-select-option>
                                    </b-form-select> -->
                    </b-form-group>
                  </div>
                  <div class="car-add-row">
                    <b-form-group
                      :label="$t('Radius')"
                      class="mr-6">
                      <b-form-input
                        v-model="tire_radius"
                        class="input"
                        type="number"></b-form-input>
                      <!-- <b-form-select v-model="tire_radius">
                                        <b-form-select-option :value="radius" v-for="radius in tireRadiuses" :key="radius.$index">{{ radius }}</b-form-select-option>
                                    </b-form-select> -->
                    </b-form-group>
                    <b-form-group class="visibility-hidden">
                      <b-form-input></b-form-input>
                    </b-form-group>
                    <b-form-group class="visibility-hidden">
                      <b-form-input></b-form-input>
                    </b-form-group>
                  </div>
                  <b-button @click="checkCarStep()">{{
                    $t('Continue')
                  }}</b-button>
                </div>

                <div
                  id="customer-step"
                  v-if="customerStep === true">
                  <div class="car-add-row">
                    <div>
                      <p class="page-title">
                        {{ $t('Select from Existing Customers') }}
                      </p>
                      <p class="page-txt">Lorem ispum dolor sit amet</p>
                    </div>
                  </div>
                  <div class="car-add-row">
                    <div
                      class="products-double-flex p-0"
                      v-if="selected_customer !== 1 && selected_customer !== 2">
                      <div
                        class="d-flex align-items-center"
                        style="gap: 10px">
                        <div
                          class="choose-customer-container"
                          v-click-outside="(event) => (customer.box = false)">
                          <div
                            class="choose-product"
                            v-if="Object.keys(customer.info).length > 0"
                            :class="[
                              { 'shadow-sm': customer.box },
                              {
                                'customer-div':
                                  Object.keys(customer.info).length > 0
                              }
                            ]">
                            <div
                              class="choose-product-label"
                              v-if="Object.keys(customer.info).length > 0">
                              <div class="customer-info">
                                <div class="customer-name">
                                  <div class="customer-name-name">
                                    <i
                                      class="fa-regular fa-user"
                                      style="color: #7367f0"></i>
                                    <strong
                                      >{{ customer.info.name }}
                                      {{ customer.info.surname }}</strong
                                    >
                                  </div>
                                  <div class="customer-name-info">
                                    <span
                                      ><i class="fa-regular fa-envelope"></i>
                                      {{
                                        customer.info.email ?? $t('Not defined')
                                      }}</span
                                    >
                                    <span
                                      ><i class="fa-regular fa-phone"></i>
                                      {{
                                        customer.info.phones.phone ??
                                        $t('Not defined')
                                      }}</span
                                    >
                                  </div>
                                </div>
                                <div
                                  class="customer-name"
                                  v-if="Object.keys(customer.car).length > 0"
                                  style="
                                    border-left: 1px solid #dee2e6;
                                    margin-left: 25px;
                                    padding-left: 35px;
                                  ">
                                  <div class="customer-name-name">
                                    <i
                                      class="fa-regular fa-car"
                                      style="color: #7367f0"></i>
                                    <strong>{{
                                      customer.car.plate_number
                                    }}</strong>
                                  </div>
                                  <div class="customer-name-info">
                                    <span
                                      >{{ customer.car.brand }}
                                      {{ customer.car.model }}
                                      {{ customer.car.year }}</span
                                    >
                                    <span
                                      >{{ customer.car.version }}
                                      {{ customer.car.version_type }}</span
                                    >
                                  </div>
                                </div>
                                <i
                                  class="fa fa-times ml-3"
                                  @click="removeCustomer"></i>
                              </div>
                            </div>
                            <div
                              v-else
                              class="icon-with-name">
                              <span class="choose-product-label">{{
                                $t('Choose')
                              }}</span>
                              <i
                                class="fa-solid fa-circle-exclamation text-danger"
                                v-if="customer.errorMessage"></i>
                            </div>
                          </div>
                          <div
                            class="choose-product"
                            v-else
                            @click="openCustomerBox"
                            :class="[
                              { 'shadow-sm': customer.box },
                              {
                                'customer-div':
                                  Object.keys(customer.info).length > 0
                              }
                            ]">
                            <span
                              class="choose-product-label"
                              v-if="Object.keys(customer.info).length > 0">
                              <div class="customer-name">
                                <div class="customer-name-name">
                                  <i
                                    class="fa-regular fa-user"
                                    style="color: #7367f0"></i>
                                  <strong
                                    >{{ customer.info.name }}
                                    {{ customer.info.surname }}</strong
                                  >
                                </div>
                                <div class="customer-name-info">
                                  <span
                                    ><i class="fa-regular fa-envelope"></i>
                                    {{
                                      customer.info.email ?? $t('Not defined')
                                    }}</span
                                  >
                                  <span
                                    ><i class="fa-regular fa-phone"></i>
                                    {{
                                      customer.info.phones.phone ??
                                      $t('Not defined')
                                    }}</span
                                  >
                                </div>
                              </div>
                            </span>
                            <div
                              v-else
                              class="icon-with-name">
                              <span class="choose-product-label">{{
                                $t('Choose')
                              }}</span>
                              <i
                                class="fa-solid fa-circle-exclamation text-danger"
                                v-if="customer.errorMessage"></i>
                            </div>
                          </div>
                          <div
                            class="choose-product-list"
                            :class="customer.box ? 'shadow-sm' : ''"
                            v-show="customer.box">
                            <div class="choose-product-input-area">
                              <input
                                type="text"
                                v-model="customer.searchArea"
                                :id="`choose-customer-input`"
                                :placeholder="$t('Search Customer')"
                                class="form-control m-3 choose-product-input"
                                @keyup="searchInputCustomer" />
                              <b-spinner
                                label="Spinning"
                                small
                                class="input-spinner"
                                v-if="customer.loading"></b-spinner>
                            </div>
                            <div
                              class="choose-product-list-item"
                              v-for="cust in customer.searchResult"
                              :key="cust.$index"
                              @click="setCustomerSearch(cust)">
                              <strong class="choose-product-list-item-name"
                                >{{ cust.name }} {{ cust.surname }}
                                <small style="font-size: 10px">{{
                                  cust.customerType.name
                                }}</small></strong
                              >
                              <div class="choose-product-list-item-info">
                                <small style="font-size: 11px"
                                  ><strong>{{ $t('E-Mail') }}:</strong>
                                  {{ cust.email }}
                                </small>
                                <small style="font-size: 11px"
                                  ><strong>{{ $t('Phone') }}:</strong>
                                  {{ cust.phones.phone }}
                                </small>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="car-add-row mt-5"
                    v-if="Object.keys(customer.info).length === 0">
                    <div>
                      <p class="page-title">{{ $t('Add New Customer') }}</p>
                      <p class="page-txt">
                        Lorem ipsum dolor sit amet consectetur.
                      </p>
                    </div>
                    <div class="customer-type-selector-wrapper">
                      <!--  :selected="customerTypes.filter(element => element.code === '101')" -->
                      <b-form-select v-model="customerType">
                        <b-form-select-option
                          :value="c_type"
                          v-for="c_type in customerTypes"
                          :key="c_type.$index">
                          {{ c_type.name }}
                        </b-form-select-option>
                      </b-form-select>
                    </div>
                  </div>
                  <div
                    class="car-add-row"
                    v-if="Object.keys(customer.info).length === 0">
                    <b-form-group
                      :label="$t('Name')"
                      class="mr-6">
                      <ValidationProvider
                        :name="$t('Name')"
                        rules="required|max:20"
                        #default="{ errors }">
                        <b-form-input
                          :placeholder="$t('Name')"
                          v-model="name"
                          required></b-form-input>
                        <label
                          for="name"
                          v-if="errors.length > 0"
                          class="text-danger"
                          ><small>*</small>{{ errors[0] }}.</label
                        >
                      </ValidationProvider>
                    </b-form-group>
                    <b-form-group :label="$t('Surname')">
                      <ValidationProvider
                        :name="$t('Surname')"
                        rules="required|max:20"
                        #default="{ errors }">
                        <b-form-input
                          :placeholder="$t('Surname')"
                          v-model="surname"
                          required></b-form-input>
                        <label
                          for="surname"
                          v-if="errors.length > 0"
                          class="text-danger"
                          ><small>*</small>{{ errors[0] }}.</label
                        >
                      </ValidationProvider>
                    </b-form-group>
                  </div>
                  <div
                    class="car-add-row"
                    v-if="Object.keys(customer.info).length === 0">
                    <div class="w-100 mr-6">
                      <ValidationProvider
                        name="GSM"
                        rules="required|max:40"
                        #default="{ errors }">
                        <b-form-group>
                          <label
                            for="phone"
                            class="m-0"
                            style="padding: 0px 0px 6px 0px"
                            >GSM<small class="text-danger">*</small></label
                          >
                          <b-input-group>
                            <!-- <b-form-input v-model="phone" @input="address_phone = phone" class="input" v-mask="'+90 (###) ### ## ##'"></b-form-input> -->
                            <b-form-tags
                              v-model="phones"
                              no-outer-focus
                              class="mb-2"
                              id="form-tags">
                              <template
                                v-slot="{
                                  tags,
                                  inputAttrs,
                                  inputHandlers,
                                  tagVariant,
                                  addTag,
                                  removeTag
                                }">
                                <b-input-group class="mb-2">
                                  <b-form-input
                                    v-bind="inputAttrs"
                                    v-on="inputHandlers"
                                    :placeholder="
                                      $t('Enter for add new phone')
                                    "
                                    class="form-control"
                                    v-mask="'+90 (###) ### ## ##'"
                                    @blur="addTag()"
                                    style="border-right: none;"
                                    ref="phone"></b-form-input>
                                  <b-input-group-append>
                                    <div class="add-number-area">
                                      <div class="cursor-pointer add-number" @click="addTag(); findCustomer('phone',$refs.phone.value);">
                                        <i class="fa-regular fa-plus"></i>
                                        {{$t('Add Number')}}
                                      </div>
                                    </div>
<!--                                    <b-button-->
<!--                                      @click="-->
<!--                                        addTag();-->
<!--                                        findCustomer(-->
<!--                                          'phone',-->
<!--                                          $refs.phone.value-->
<!--                                        );-->
<!--                                      "-->
<!--                                      variant="primary"-->
<!--                                      style="width: 30px !important">-->
<!--                                      <i class="fa-regular fa-plus"></i>-->
<!--                                    </b-button>-->
                                  </b-input-group-append>
                                </b-input-group>
                                <div class="row" style="padding: 0 12px;">
                                  <div class="tag-item" v-for="tag in tags" :key="tag">
                                    {{tag}}
                                    <i class="fa-solid fa-xmark cursor-pointer" @click="removeTag(tag)"></i>
                                  </div>
                                </div>
<!--                                <div-->
<!--                                  class="d-inline-block"-->
<!--                                  style="font-size: 1.5rem">-->
<!--                                  <b-form-tag-->
<!--                                    v-for="tag in tags"-->
<!--                                    @remove="removeTag(tag)"-->
<!--                                    :key="tag"-->
<!--                                    :title="tag"-->
<!--                                    :variant="tagVariant"-->
<!--                                    class="mr-1"-->
<!--                                    >{{ tag }}</b-form-tag-->
<!--                                  >-->
<!--                                </div>-->
                              </template>
                            </b-form-tags>
                          </b-input-group>
                          <label
                            for="phone"
                            v-if="errors.length > 0"
                            class="text-danger"
                            ><small>*</small>{{ errors[0] }}.</label
                          >
                        </b-form-group>
                      </ValidationProvider>
                    </div>

                    <b-form-group
                      :label="$t('E-Mail')"
                      class="mr-6">
                      <ValidationProvider
                        :name="$t('E-Mail')"
                        rules="required|max:100"
                        #default="{ errors }">
                        <b-form-input
                          :placeholder="$t('E-Mail')"
                          v-model="email"
                          @blur.native="findCustomer('email', email)"
                          required></b-form-input>
                        <label
                          for="email"
                          v-if="errors.length > 0"
                          class="text-danger"
                          ><small>*</small>{{ errors[0] }}.</label
                        >
                      </ValidationProvider>
                    </b-form-group>
                  </div>

                  <p
                    class="page-title mt-4"
                    v-if="Object.keys(customer.info).length === 0">
                    {{ $t('Address Information') }}
                  </p>

                  <div
                    class="car-add-row mt-3"
                    v-if="Object.keys(customer.info).length === 0">
                    <b-form-group
                      :label="$t('Invoice Type')"
                      class="mr-6">
                      <b-form-select v-model="inovice_type">
                        <b-form-select-option
                          :value="i_type"
                          v-for="i_type in invoiceTypes"
                          :key="i_type.$index"
                          >{{ i_type.name }}</b-form-select-option
                        >
                      </b-form-select>
                    </b-form-group>
                    <b-form-group
                      class="mr-6"
                      style="visibility: hidden"
                      v-if="inovice_type.code !== '102'">
                      <b-form-input
                        placeholder="$t('E-Mail')"
                        v-model="email"
                        required></b-form-input>
                    </b-form-group>

                    <b-form-group
                      :label="$t('Company')"
                      class="mr-6"
                      v-if="inovice_type.code === '102'">
                      <b-form-input
                        :placeholder="$t('Company')"
                        v-model="company_name"
                        required></b-form-input>
                    </b-form-group>
                  </div>

                  <div
                    class="car-add-row"
                    v-if="inovice_type.code === '102'">
                    <b-form-group
                      :label="$t('Tax Administration')"
                      class="mr-6">
                      <b-input-group>
                        <b-form-input
                          :placeholder="$t('Tax Administration')"
                          v-model="tax_office"></b-form-input>
                      </b-input-group>
                    </b-form-group>

                    <b-form-group
                      :label="$t('Tax Number')"
                      class="mr-6">
                      <b-input-group>
                        <b-form-input
                          :placeholder="$t('Tax Number')"
                          v-model="tax_number"></b-form-input>
                      </b-input-group>
                    </b-form-group>
                  </div>

                  <div
                    class="car-add-row"
                    v-if="Object.keys(customer.info).length === 0">
                    <b-form-group
                      :label="$t('City')"
                      class="mr-6">
                      <b-form-select
                        v-model="city"
                        @change="setCity(city.id)">
                        <b-form-select-option
                          :value="city"
                          v-for="city in cities"
                          :key="city.$index"
                          >{{ city.name }}</b-form-select-option
                        >
                      </b-form-select>
                    </b-form-group>

                    <b-form-group
                      :label="$t('District')"
                      class="mr-6">
                      <b-form-select
                        v-model="district"
                        @change="setDistrict(district.id)">
                        <b-form-select-option
                          :value="district"
                          v-for="district in districts"
                          :key="district.$index"
                          >{{ district.name }}</b-form-select-option
                        >
                      </b-form-select>
                    </b-form-group>

                    <b-form-group :label="$t('Neighbourhood')">
                      <b-form-select v-model="area">
                        <b-form-select-option
                          :value="area"
                          v-for="area in areas"
                          :key="area.$index"
                          >{{ area.name }}</b-form-select-option
                        >
                      </b-form-select>
                    </b-form-group>
                  </div>

                  <b-form-group
                    :label="$t('Address Information')"
                    v-if="Object.keys(customer.info).length === 0">
                    <b-form-textarea
                      :placeholder="$t('Address Information')"
                      rows="3"
                      max-rows="6"
                      v-model="address"></b-form-textarea>
                  </b-form-group>
                  <div class="flex-center-space mt-4">
                    <b-form-checkbox
                      id="campaign-notification-checkbox"
                      value="notification"
                      unchecked-value="!notification"
                      switch
                      style="visibility: hidden">
                      <span class="campaign-notification-checkbox-txt">{{
                        $t(
                          'I want to receive campaign and information notifications.'
                        )
                      }}</span>
                    </b-form-checkbox>
                    <div class="flex-center-space">
                      <!-- <b-button @click="addCar()">Atla</b-button> -->
                      <b-button
                        class="black-save-btn"
                        @click="addCar()"
                        >{{ $t('Save') }}</b-button
                      >
                    </div>
                  </div>
                </div>
                <!--  -->
              </div>
            </div>
          </ValidationObserver>
        </div>
        <div class="col-lg-3">
          <div
            class="product-detail-right-wrapper"
            id="customer-detail-right-wrapper">
            <div class="add-car-right-info-card">
              <div class="flex-center-space">
                <div class="add-car-right-info-card-head">
                  <p>{{ $t('Car Information') }}</p>
                  <button
                    @click="
                      carStep = true;
                      customerStep = false;
                    ">
                    {{ $t('Edit') }}
                  </button>
                </div>
              </div>
              <div class="flex-center-space">
                <span>{{ $t('Plate') }}</span>
                <p>{{ car_plate }}</p>
              </div>
              <div class="flex-center-space">
                <span>{{ $t('Brand') }}/{{ $t('Model') }}</span>
                <p>
                  {{ car_brand.name }}
                  <span v-if="car_brand.name && car_model.car_model_name"
                    >-</span
                  >
                  {{ car_model.car_model_name }}
                </p>
              </div>
              <div class="flex-center-space">
                <span>{{ $t('Tire Information') }}</span>
                <p>
                  {{ tire_width }}
                  <span v-if="tire_width && tire_height">/</span>
                  {{ tire_height }}
                  <span v-if="tire_width && tire_height && tire_radius">/</span>
                  {{ tire_radius }}
                </p>
              </div>
            </div>
          </div>

          <div
            class="product-detail-right-wrapper mt-2"
            id="customer-detail-right-wrapper"
            v-if="customerStep">
            <div class="add-car-right-info-card">
              <div class="flex-center-space">
                <div class="add-car-right-info-card-head">
                  <p>{{ $t('User Information') }}</p>
                  <button>{{ $t('Edit') }}</button>
                </div>
              </div>
              <div class="flex-center-space">
                <span>{{ $t('Name Surname') }}</span>
                <p>
                  {{
                    Object.keys(customer.info).length === 0
                      ? name
                      : customer.info.name
                  }}
                  <span v-if="(name && surname) || customer.info">-</span>
                  {{
                    Object.keys(customer.info).length === 0
                      ? surname
                      : customer.info.surname
                  }}
                </p>
              </div>
              <div class="flex-center-space">
                <span>{{ $t('Phone Number') }}</span>
                <p>
                  {{
                    Object.keys(customer.info).length === 0
                      ? phones[0]
                      : customer.info.phones.phone
                  }}
                </p>
              </div>
              <div class="flex-center-space">
                <span>{{ $t('E-Mail') }}</span>
                <p>
                  {{
                    Object.keys(customer.info).length === 0
                      ? email
                      : customer.info.email
                  }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </DefaultLayout>
</template>

<script>
/* eslint-disable */
import DefaultLayout from '@/layouts/default';
import PageBreadcrumbs from '../../components/PageBreadcrumbs.vue';
import StarRating from '../../components/StarRating.vue';
import vClickOutside from 'v-click-outside';
export default {
  components: {
    PageBreadcrumbs,
    StarRating,
    DefaultLayout
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  data() {
    return {
      breadcrumbs: [
        {
          name: this.$i18n.t('Cars'),
          path: '/cars'
        },
        {
          name: this.$i18n.t('Add New')
        }
      ],
      selected: null,
      // car data
      car_brand: {},
      //car_brand_id: '',
      car_year: '',
      car_model: {},
      // car_model: '',
      car_version: '',
      car_plate: '',
      car_chassis_number: '',
      tire_brand: {},
      tire_width: '',
      tire_height: '',
      tire_radius: '',
      car_id: '',
      customerType: {},
      name: '',
      surname: '',
      phones: [],
      phone: '',
      phone_2: '',
      phone_3: '',
      email: '',
      city: {},
      district: {},
      area: {},
      inovice_type: {},
      address: '',
      company_name: '',
      tax_office: '',
      tax_number: '',
      options: [
        { value: null, text: this.$i18n.t('Completed'), disabled: true }
      ],
      customers: [
        { value: null, text: this.$i18n.t('Individual'), disabled: true },
        { value: null, text: this.$i18n.t('Corporate'), disabled: true }
      ],
      edit: false,
      carStep: true,
      customerStep: false,
      second_number: false,
      third_number: false,
      customer: {
        box: false,
        searchArea: '',
        searchResult: [],
        car: {},
        info: {}
      },
      selected_customer: null,
      registeredCustomers: [],
      button_loader: false,
      brand_error: null,
      model_error: null,
      year_error: null,
      version_error: null,
    };
  },
  computed: {
    date() {
      const today = new Date();
      const day = String(today.getDate()).padStart(2, '0');
      const month = String(today.getMonth() + 1).padStart(2, '0');
      const year = today.getFullYear();

      return `${day}/${month}/${year}`;
    },
    carBrands() {
      return this.$store.getters['carBrands/getCarBrandsData'];
    },
    carYears() {
      return this.$store.getters['carYears/getCarYearsData'];
    },
    carModels() {
      return this.$store.getters['carModels/getCarModelsData'];
    },
    carVersions() {
      return this.$store.getters['carVersions/getCarVersionsData'];
    },
    tireBrands() {
      return this.$store.getters['tireBrands/getTireBrandsData'];
    },
    customerTypes() {
      this.customerType = this.$store.state.customerTypes.customerTypes[0];
      return this.$store.getters['customerTypes/getCustomerTypesData'];
    },
    tireWidths() {
      return this.$store.getters['tireWidths/getTireWidthsData'];
    },
    tireHeights() {
      return this.$store.getters['tireHeights/getTireHeightsData'];
    },
    tireRadiuses() {
      return this.$store.getters['tireRadiuses/getTireRadiusesData'];
    },
    cities() {
      return this.$store.getters['cities/getCitiesData'];
    },
    districts() {
      return this.$store.getters['districts/getDistrictsData'];
    },
    areas() {
      return this.$store.getters['areas/getAreasData'];
    },
    invoiceTypes() {
      return this.$store.getters['invoiceTypes/getInvoiceTypesData'];
    }
  },
  mounted() {
    this.$store.dispatch('carBrands/fetchCarBrands');
    this.$store.dispatch('tireBrands/fetchTireBrands');
    this.$store.dispatch('customerTypes/fetchCustomerTypes');
    this.$store.dispatch('cities/fetchCities');
    this.$store.dispatch('invoiceTypes/fetchInvoiceTypes');
  },
  methods: {
    formatCarPlate() {
      if (this.car_plate) {
        if (!(this.car_plate.substring(2, 3) >= '0' && this.car_plate.substring(2, 3) <= '9')) {
          this.car_plate = window.helper.mask(this.car_plate)
        } else {
          this.$swal({
            iconHtml: '<img src="/img/warning.png" width="32" height="32">',
            toast: true,
            customClass: {
              icon: 'swal2-no-border'
            },
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            text: this.$i18n.t('Please enter plate in valid format.')
          })
        }
      }
    },
    findCustomer(type, data) {
      this.button_loader = true;
      var query = '';
      if (type === 'email') {
        query = 'email=' + data;
      } else {
        query = 'phone=' + data;
      }
      if (data) {
        this.$axios
          .get(`/customers/find-with-detail?${query}`)
          .then((response) => {
            if (response.data.status) {
              this.registeredCustomers = response.data.responseData;
              if (this.registeredCustomers.length > 0) {
                this.setCustomerSearch(this.registeredCustomers[0]);
              }
            }
          });
      }
      this.button_loader = false;
    },
    setCustomerSearch(customer) {
      this.$store
        .dispatch('sales/searchCustomerCar', customer.id)
        .then((response) => {
          if (response) {
            this.customer.car = response;
          }
          this.customer.info = customer;
          this.customer.box = false;
        });
    },
    removeCustomer() {
      this.customer = {
        box: false,
        searchArea: '',
        searchResult: [],
        car: {},
        info: {}
      };
    },
    openCustomerBox() {
      this.customer.box = !this.customer.box;
    },
    searchInputCustomer() {
      if (this.customer.searchArea === 0) {
        this.customer.searchResult = [];
      }
      if (this.customer.searchArea.length > 2) {
        this.customer.loading = true;
        this.$store
          .dispatch(`sales/searchCustomer`, this.customer.searchArea)
          .then((response) => {
            this.customer.searchResult = response;
            this.customer.loading = false;
          });
      }
    },
    setCarBrand(car_brand_id) {
      this.brand_error = false
      this.$store.dispatch('carYears/fetchCarYears', car_brand_id);
    },
    setCarYear() {
      this.year_error = false
      this.$store.dispatch('carModels/fetchCarModels', {
        car_brand_id: this.car_brand.car_brand_id,
        car_year: this.car_year
      });
    },
    setCarModel() {
      this.model_error = false
      this.$store.dispatch('carVersions/fetchCarVersions', {
        car_brand_id: this.car_brand.car_brand_id,
        car_year: this.car_year,
        car_model_id: this.car_model.car_model_id
      });
    },
    setTireBrand(brand_slug) {
      this.$store.dispatch('tireWidths/fetchTireWidths', brand_slug);
    },
    setTireWidth() {
      this.$store.dispatch('tireHeights/fetchTireHeights', {
        brand_slugs: this.tire_brand.slug,
        width: this.tire_width
      });
    },
    setTireHeight() {
      this.$store.dispatch('tireRadiuses/fetchTireRadiuses', {
        brand_slugs: this.tire_brand.slug,
        width: this.tire_width,
        height: this.tire_height
      });
    },
    setCity(city_id) {
      this.$store.dispatch('districts/fetchDistricts', city_id);
    },
    setDistrict(district_id) {
      this.$store.dispatch('areas/fetchAreas', district_id);
    },
    checkCarStep() {
      this.$refs.customerAddRules.validate().then((success) => {
        if(success){
          if (Object.keys(this.car_brand).length>0 && this.car_year && Object.keys(this.car_model).length>0 && this.car_version) {
            this.carStep = false;
            this.customerStep = true;
            this.brand_error = false
            this.year_error = false
            this.model_error = false
            this.version_error = false
          }else{
            if(Object.keys(this.car_brand).length<1){
              this.brand_error = true
            }
            if(!this.car_year){
              this.year_error = true
            }
            if(Object.keys(this.car_model).length<1){
              this.model_error = true
            }
            if(!this.car_version){
              this.version_error = true
            }
            this.$swal.fire({
              iconHtml: '<img src="/img/error.svg" width="32" height="32">',
              position: 'top-end',
              title: this.$i18n.t('Please fill the required fields.'),
              showConfirmButton: false,
              timer: 2500,
              toast: true,
              customClass: {
                icon: 'swal2-no-border'
              }
            });
          }
        }else{
          if(Object.keys(this.car_brand).length<1){
            this.brand_error = true
          }
          if(!this.car_year){
            this.year_error = true
          }
          if(Object.keys(this.car_model).length<1){
            this.model_error = true
          }
          if(!this.car_version){
            this.version_error = true
          }
          this.$swal.fire({
            iconHtml: '<img src="/img/error.svg" width="32" height="32">',
            position: 'top-end',
            title: this.$i18n.t('Please fill the required fields.'),
            showConfirmButton: false,
            timer: 2500,
            toast: true,
            customClass: {
              icon: 'swal2-no-border'
            }
          });
        }
      });
    },
    findCar() {
      this.car_id = this.car_version.car.id;
      this.tire_width = this.car_version.tire.width;
      this.tire_height = this.car_version.tire.height;
      this.tire_radius = this.car_version.tire.radius;
      return false;
      this.$axios
        .get(
          `/cars/find-car?car_brand_id=${this.car_brand.car_brand_id}&car_model_id=${this.car_model.car_model_id}&year=${this.car_year}&body_style=${this.car_version.body_style}&fuel_type=${this.car_version.fuel_type}&gear_type=${this.car_version.gear_type}&version_type=${this.car_version.version_type}`,
          {}
        )
        .then((response) => {
          this.car_id = response.data.responseData.cars[0].car_id;
          this.tire_width = response.data.responseData.cars[0].width;
          this.tire_height = response.data.responseData.cars[0].height;
          this.tire_radius = response.data.responseData.cars[0].radius;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    addCar() {
      if (this.phones.length > 0) {
        var manipulatedPhones = [];
        for (let index = 0; index < this.phones.length; index++) {
          const element = this.phones[index];
          manipulatedPhones.push({
            phone: element,
            is_default: false
          });
        }
        manipulatedPhones[0].is_default = true;
      }
      if (this.inovice_type) {
        if (!this.customerType) {
          this.customerType = this.customerTypes[0];
        }
        this.$axios
          .post('/customers', {
            customer: !this.customer.info.id
              ? {
                  id: null,
                  name: this.name.length > 0 ? this.name : null,
                  surname: this.surname.length > 0 ? this.surname : null,
                  email: this.email.length > 0 ? this.email : null,
                  mobile: manipulatedPhones ? manipulatedPhones[0].phone : null,
                  company_title:
                    this.company_name.length > 0 ? this.company_name : null,
                  tax_number:
                    this.tax_number.length > 0 ? this.tax_number : null,
                  tax_office:
                    this.tax_office.length > 0 ? this.tax_office : null,
                  customer_type_id: this.customerType.id
                    ? this.customerType.id
                    : this.customerTypes[0].id
                }
              : {
                  id: this.customer.info.id
                },
            address: !this.customer.info.id
              ? {
                  invoice_type_id: this.inovice_type.id,
                  city_id: this.city.id,
                  district_id: this.district.id,
                  area_id: this.area.id,
                  address: this.address.length > 0 ? this.address : null,
                  phone: manipulatedPhones ? manipulatedPhones[0].phone : null
                }
              : null,
            phone: manipulatedPhones,
            car: {
              car_id: this.car_id,
              plate_number: this.car_plate,
              chassis_number: this.car_chassis_number ?? '00000000000000000',
              year: this.car_year.toString(),
              tire_brand_id: this.tire_brand.id,
              width: this.tire_width,
              height: this.tire_height,
              radius: this.tire_radius,
              customer_type_id: this.customerType.id,
              invoice_type_id: this.inovice_type.id,
              is_active: true
            }
          }).then(response=> {
            if(response.data.status){
              this.$swal.fire({
                iconHtml: '<img src="/img/success.svg" width="32" height="32">',
                position: 'top-end',
                title: this.$i18n.t('Car successfully saved') + '!',
                showConfirmButton: false,
                timer: 2500,
                toast: true
              });
              this.$router.push('/cars');
            }
          }).catch(error => {
            this.$swal.fire({
              iconHtml: '<img src="/img/error.svg" width="32" height="32">',
              position: 'top-end',
              title: error.response.data.statusMessage,
              showConfirmButton: false,
              timer: 2500,
              toast: true,
              customClass: {
                icon: 'swal2-no-border'
              }
            });
          });
      } else {
        this.$swal.fire({
          iconHtml: '<img src="/img/error.svg" width="32" height="32">',
          position: 'top-end',
          title: this.$i18n.t('Please fill the required fields.'),
          showConfirmButton: false,
          timer: 2500,
          toast: true,
          customClass: {
            icon: 'swal2-no-border'
          }
        });
      }
    }
  }
};
</script>

<style scoped>
.tag-item{
  padding: 4px 8px;
  border-radius: 6px;
  font-size: 12px;
  background-color: #1C1C1C;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 2px .5rem 2px;
  white-space: nowrap;
  width: unset;
  gap: 1rem;
}
.tag-item .fa-xmark{
  color: #8f8f8f;
  font-size: 14px;
}
.red {
  color: #b94c4c !important;
}
.page-txt {
  visibility: hidden;
}
.car-add-row {
  align-items: flex-start;
}

/*  */
.product-line-services {
  display: flex;
  gap: 15px;
  align-items: center;
  width: 100%;
  margin-top: 17px;
}

.choose-product-container {
  position: relative;
  width: 100% !important;
}

.choose-customer-container {
  position: relative;
  max-width: 500px;
  min-width: 500px;
}

.choose-product {
  border: 1px solid #dee2e6;
  padding: 0.65rem 0.85rem !important;
  border-radius: 5px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 8px;
  background-color: #f4f4f4;
  cursor: pointer;
}

.choose-product-label {
  font-size: 14px;
  color: #6e6b7b !important;
  font-style: normal;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.icon-with-name {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.choose-product-list {
  position: absolute;
  top: 40px;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  border: 1px solid #dee2e6;
  background-color: #f4f4f4;
  border-top: none;
  z-index: 1;
  border-radius: 5px;
}

.choose-product-list-item {
  color: #6e6b7b !important;
  border-top: 1px solid #dee2e6;
  padding: 0.65rem 0.85rem;
  font-size: 14px;
  cursor: pointer;
}

.choose-product-list-item:hover {
  background-color: #dadada;
  cursor: pointer;
}

.choose-product-input {
  width: 92%;
}

.input-group-text {
  background-color: #f4f4f4;
  border-left: 1px solid #dee2e6 !important;
}

.choose-product-input-area {
  position: relative;
}

.input-spinner {
  position: absolute;
  right: 26px;
  top: 27px;
}

#services-lines:first-child {
  border-top: 1px solid #dee2e6;
  margin-bottom: 20px;
}

#services-lines:last-child {
  border-bottom: 1px solid #dee2e6;
  margin-bottom: 20px;
}
.isDesc {
  border-bottom: 1px solid #dee2e6 !important;
  width: 100%;
  max-width: 100% !important;
  margin-bottom: 20px !important;
}

.choose-product-list-item-info {
  display: flex;
  flex-direction: column;
  border-top: 1px solid #dee2e6;
  padding-top: 5px;
  margin-top: 5px;
}

.choose-product-list-item-name {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.customer-name {
  display: flex;
  gap: 5px;
  flex-direction: column;
}

.customer-div {
  height: auto;
  width: auto !important;
}

.customer-name-name {
  font-size: 20px;
}

.customer-name-info {
  font-size: 12px;
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.customer-info {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  position: relative;
}

.customer-div .choose-product-label {
  width: 1000%;
}

.discount-line {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.add-invoice-flex-total-wrapper {
  flex: 1;
}

.bill-info {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex-direction: column;
}

.bill-info-line {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px dashed #dee2e6;
  padding-bottom: 10px;
  padding-top: 10px;
  width: 650px;
  gap: 20px;
}

.addDiscount {
  border: none !important;
  background-color: rgba(119, 119, 119, 0.21) !important;
  border-radius: 5px !important;
  color: #777777 !important;
  margin: 5px !important;
  max-width: 30px;
}

.addDiscount:hover {
  background-color: black;
  color: #dee2e6;
}
.black-save-btn {
  color: #f4f4f4 !important;
  border-color: #1c1c1c !important;
  background: #1c1c1c !important;
}
.add-number-area{
  background-color: #F4F4F4;
  border: 1px solid #dee2e6;
  border-left: none;
}
.add-number{
  background-color: #D3D3D399;
  border: 1px solid transparent;
  color: #6E6B7B;
  border-radius: 2px;
  margin: 4px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: .5rem;
  padding: 4px 8px;
}
</style>
