<template>
  <DefaultLayout>
    <div>
      <PageBreadcrumbs :breadcrumbs="breadcrumbs"/>

      <div v-if="!$store.state.payments.loading">
        <div class="products-double-flex">
          <div class="products-double-flex-left">
            <p class="page-title">{{ $t('Payments') }}</p>
          </div>
        </div>
        <div id="products-listing-table-wrapper" class="mt-4 py-4">
          <div class="products-double-flex">
            <div class="products-double-flex-left d-flex">
              <div class="filter-input cursor-pointer">
                <i class="fa-regular fa-calendar-check" style="margin-right: .5rem;"></i>
                <flat-pickr :placeholder="$t('Date Range')" v-model="date"
                            class="form-control text-nowrap bg-transparent"
                            :config="{dateFormat: 'Y-m-d',mode: 'range',locale: locale,allowInput: true,enableTime: false,maxDate: end_date,minDate: start_date,onReady: addCustomButton,closeOnSelect:false}"/>
                <i class="fa-solid fa-xmark cursor-pointer" @click="date = null; clearDateRange()"
                   v-if="date"></i>
              </div>
              <div class="search-area ml-2">
                <b-form-input
                    type="number"
                    v-model="order_id"
                    :class="!show_search_text ? 'show-search_text' : ''"
                    :placeholder="$t('Search')"
                    class="search-text-input p-0"
                    style="width: 100% !important; padding: 0 !important"
                    v-on:keydown.enter="filter()"
                />
                <i class="fa-solid fa-xmark cursor-pointer" v-if="order_id" @click="clearRoute()"></i>
                <i class="fa-solid fa-filter" v-else></i>
              </div>
            </div>
            <div class="products-double-flex-right">
              <div class="products-double-flex-right-actions" @click="excel()">

                 <span>
                    <i class="fa-regular fa-arrow-up-right-from-square"></i>
                  <p>{{ $t('Export') }}</p>
                 </span>

              </div>

              <!--              <div class="products-double-flex-right">-->
              <!--                <div class="products-double-flex-right-actions">-->
              <!--                  <span class="d-flex gap-1">-->
              <!--                    <i class="fa-solid fa-arrow-down-to-bracket"></i>-->
              <!--                    <p>{{ $t('Download') }}</p>-->
              <!--                  </span>-->
              <!--                </div>-->
              <!--              </div>-->
            </div>
          </div>
          <div class="product-listing-table">
            <table>
              <tr class="product-listing-table-head lc-w-120">
                <th>
                  <label class="product-listing-checkbox-label">
                    <input
                        type="checkbox"
                        @click="checkUncheck()"/>
                    <span class="checkmark"></span>
                  </label>
                </th>
                <th>
                  <div class="d-flex align-items-center gap-2"
                       @click="sort_column = 'id'; sort_direction = sort_direction === 'desc' ? 'asc' : 'desc'; setSort()">
                    {{ $t('Order No.') }}
                    <i class="fa-solid fa-chevron-up" v-if="sort_column === 'id' && sort_direction === 'asc'"></i>
                    <i class="fa-solid fa-chevron-down" v-else></i>
                  </div>
                </th>
                <th>{{ $t('Sale Channel') }}</th>
                <th>{{ $t('Mounting Point') }}</th>
                <th>
                  <div class="d-flex align-items-center gap-2"
                       @click="sort_column = 'total_amount'; sort_direction = sort_direction === 'desc' ? 'asc' : 'desc'; setSort()">
                    {{ $t('Amount') }}
                    <i class="fa-solid fa-chevron-up"
                       v-if="sort_column === 'total_amount' && sort_direction === 'asc'"></i>
                    <i class="fa-solid fa-chevron-down" v-else></i>
                  </div>
                </th>
                <th>
                  <div class="d-flex align-items-center gap-2"
                       @click="sort_column = 'created_at'; sort_direction = sort_direction === 'desc' ? 'asc' : 'desc'; setSort()">
                    {{ $t('Payment Date') }}
                    <i class="fa-solid fa-chevron-up"
                       v-if="sort_column === 'created_at' && sort_direction === 'asc'"></i>
                    <i class="fa-solid fa-chevron-down" v-else></i>
                  </div>
                </th>
                <th>{{ $t('Status') }}</th>
                <th></th>
              </tr>
              <tr
                  v-for="payment in payments"
                  :key="payment.$index">
                <td>
                  <label class="product-listing-checkbox-label">
                    <input
                        type="checkbox"
                        name="single-payment-checkbox"/>
                    <span class="checkmark"></span>
                  </label>
                </td>
                <td>{{ payment.id }}</td>
                <td>Servislet</td>
                <td>-</td>
                <td>{{ currenctFormat('tr-TR', payment.transfer) }}</td>
                <td>{{ dateFormat(payment.expectedDate) }}</td>
                <td>
                  <p class="servislet-badge text-nowrap w-100"
                     :class="{'success-badge' : payment.status, 'danger-badge': !payment.status}">
                    {{ payment.status ? $t('Completed') : $t('Payment Waiting') }}
                  </p>
                </td>
                <td>
                  <b-dropdown
                      id="product-actions-dropdown"
                      class="product-listing-action-select"
                      no-caret
                      right>
                    <template #button-content>
                        <span class="button-content">
                          {{ $t('Actions') }}
                          <i class="fa-solid fa-chevron-down"></i>
                        </span>
                    </template>
                    <b-dropdown-item class="table-dropdown-actions"
                                     @click="$router.push(`/payment-detail/${payment.id}`)">
                      {{ $t('See Detail') }}
                    </b-dropdown-item>
                    <!--                    <b-dropdown-item class="table-dropdown-actions">-->
                    <!--                      {{ $t('Copy') }}-->
                    <!--                    </b-dropdown-item>-->
                    <!--                    <b-dropdown-item class="table-dropdown-actions">{{ $t('Delete') }}-->
                    <!--                    </b-dropdown-item>-->
                  </b-dropdown>
                </td>
              </tr>
            </table>
            <div v-if="!$store.state.payments.loading && $store.state.payments.pagination.total>0" id="table-info-and-pagination">
              <div class="table-results-info">
                <p>
                  {{$t('{total} {from} {start}-{end} is showing.',{total: $store.state.payments.pagination.total, from: $t('from payments'), start: $store.state.payments.list.length>0 ? ( $store.state.payments.pagination.current_page === 1 ? 1 : (($store.state.payments.pagination.current_page - 1) * $store.state.payments.pagination.per_page) + 1 ) : 0, end: $store.state.payments.pagination.total_page === $store.state.payments.pagination.current_page ? $store.state.payments.pagination.total : $store.state.payments.pagination.current_page * $store.state.payments.pagination.per_page})}}
                </p>
              </div>
              <div class="pagination-wrapper">

                <b-pagination
                    v-model="current_page"
                    :total-rows="$store.state.payments.pagination.total"
                    :per-page="$store.state.payments.pagination.per_page"
                    align="center"
                    pills
                    class="my-1"
                    @input="setPage()"/>
              </div>
            </div>
            <div v-if="!$store.state.payments.loading && $store.state.payments.pagination.total === 0" class="d-flex flex-column align-items-center">
              <p class="table-no-text"> {{
                  $t('No {data} found to list.', {data: $t('Payment').toLocaleLowerCase()})
                }}</p>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="loader px-3 mt-3">
        <content-placeholders :rounded="true">
          <content-placeholders-img/>
          <content-placeholders-text :lines="10"/>
        </content-placeholders>
      </div>
    </div>
  </DefaultLayout>
</template>

<script>
/* eslint-disable */
import PageBreadcrumbs from '../../components/PageBreadcrumbs.vue';
import PageWidgets from '../../components/PageWidgets.vue';
import DefaultLayout from "@/layouts/default.vue";
import {Turkish} from "@/locales/flatpickr/tr";
import {English} from "@/locales/flatpickr/en";
import axios from "@/plugins/axios";

export default {
  components: {
    PageBreadcrumbs,
    PageWidgets,
    DefaultLayout
  },
  data() {
    return {
      breadcrumbs: [
        {
          name: this.$i18n.t('Payments'),
          path: '/#'
        }
      ],
      show_search_text: true,
      order_id: null,
      date: '',
      locale: localStorage.getItem('locale') === 'tr' ? Turkish : English,
      end_date: '',
      start_date: '',
      sort_direction: 'desc',
      sort_column: 'id',
      current_page: 1
    };
  },
  watch: {
    '$route.query': function () {
      this.getList()
    },
    date(){
      if(this.date){
        if(this.date.length === 10){
          this.end_date = this.calculate2MonthAfter(this.date);
          this.start_date = this.calculate2MonthAgo(this.date);
        }
      }else{
        const currentDate2 = new Date();
        const year2 = currentDate2.getFullYear();
        const month2 = String(currentDate2.getMonth() + 1).padStart(2, '0');
        const day2 = String(currentDate2.getDate()).padStart(2, '0');

        this.end_date = `${year2}-${month2}-${day2}`;

        this.start_date = this.calculate2MonthAgo(this.end_date)
      }
    }
  },
  mounted() {
    const currentDate2 = new Date();
    const year2 = currentDate2.getFullYear();
    const month2 = String(currentDate2.getMonth() + 1).padStart(2, '0');
    const day2 = String(currentDate2.getDate()).padStart(2, '0');

    this.end_date = `${year2}-${month2}-${day2}`;

    this.start_date = this.calculate2MonthAgo(this.end_date)

    this.date = this.start_date + ' - '+ this.end_date
    this.getList();
  },
  computed: {
    payments() {
      return this.$store.state.payments.list
    }
  },
  methods: {
    calculate2MonthAgo(text) {
      const ago = new Date(new Date(text).getTime() - 60 * 24 * 60 * 60 * 1000);
      const year = ago.getFullYear();
      const month = String(ago.getMonth() + 1).padStart(2, '0');
      const date = String(ago.getDate()).padStart(2, '0');

      return `${year}-${month}-${date}`;
    },
    calculate2MonthAfter(text) {
      const date2 = new Date(new Date(text).getTime() + 60 * 24 * 60 * 60 * 1000);
      const year = date2.getFullYear();
      const month = String(date2.getMonth() + 1).padStart(2, '0');
      const date = String(date2.getDate()).padStart(2, '0');

      return `${year}-${month}-${date}`;
    },
    getList() {
      if (this.$route.query.order_id) {
        this.order_id = this.$route.query.order_id
      }
      if (this.$route.query.start_date && this.$route.query.end_date) {
        this.date = this.$route.query.start_date + ' - ' + this.$route.query.end_date
        this.start_date = this.$route.query.start_date
        this.end_date = this.$route.query.end_date
      }
      if (this.$route.query.sort_column) {
        this.sort_column = this.$route.query.sort_column
      }
      if (this.$route.query.sort_direction) {
        this.sort_direction = this.$route.query.sort_direction
      }
      if (this.$route.query.page) {
        this.current_page = parseInt(this.$route.query.page)
      }

      this.$store.dispatch('payments/getPayments', {
        page: this.current_page,
        order_id: this.order_id,
        start_date: this.$route.query.start_date ? this.$route.query.start_date + ' 00:00:00' : this.start_date+ ' 00:00:00',
        end_date: this.$route.query.end_date ? this.$route.query.end_date + ' 23:59:00' : this.end_date + ' 23:59:00',
        sort_column: this.sort_column,
        sort_direction: this.sort_direction
      })
    },
    addCustomButton(selectedDates, dateStr, instance) {
      const customButton = document.createElement('button');
      customButton.innerHTML = this.$i18n.t('Apply Range');
      customButton.className = 'btn dealer-bg custom-flatpickr-button';
      customButton.onclick = () => {
        this.setDateRange()
      };

      instance.calendarContainer.appendChild(customButton);
    },
    setDateRange() {
      if (this.date && this.date.length === 23) {
        var ar = this.date.split(' ')
        const currentQuery = this.$route.query;
        const newQuery = {
          ...currentQuery,
          start_date: ar[0],
          end_date: ar[2],
        };
        const queryString = Object.keys(newQuery).map(key => `${key}=${newQuery[key]}`).join('&');
        const url = `${window.location.pathname}?${queryString}`;
        window.location.href = url;
      }
    },
    clearDateRange() {
      delete this.$route.query['start_date']
      delete this.$route.query['end_date']
      const currentQuery = this.$route.query;
      const newQuery = {
        ...currentQuery
      };
      const queryString = Object.keys(newQuery).map(key => `${key}=${newQuery[key]}`).join('&');
      const url = `${window.location.pathname}?${queryString}`;

      window.location.href = url;
    },
    checkUncheck(checkBox) {
      var get = document.getElementsByName('single-payment-checkbox');
      for (var i = 0; i < get.length; i++) {
        if (get[i].checked === true) {
          get[i].checked = false;
        } else {
          get[i].checked = true;
        }
        // without if => get[i].checked = checkBox.checked;}
      }
    },
    clearRoute() {
      delete this.$route.query['order_id']
      const currentQuery = this.$route.query;
      const newQuery = {
        ...currentQuery
      };
      const queryString = Object.keys(newQuery).map(key => `${key}=${newQuery[key]}`).join('&');
      const url = `${window.location.pathname}?${queryString}`;

      window.location.href = url;
    },
    filter() {
      const currentQuery = this.$route.query;
      const newQuery = {
        ...currentQuery,
        order_id: this.order_id
      };
      const queryString = Object.keys(newQuery).map(key => `${key}=${newQuery[key]}`).join('&');
      const url = `${window.location.pathname}?${queryString}`;
      window.location.href = url;
    },
    setSort() {
      const currentQuery = this.$route.query;
      const newQuery = {
        ...currentQuery,
        sort_direction: this.sort_direction,
        sort_column: this.sort_column
      };
      const queryString = Object.keys(newQuery).map(key => `${key}=${newQuery[key]}`).join('&');
      const url = `${window.location.pathname}?${queryString}`;

      window.location.href = url;
    },
    setPage() {
      const currentQuery = this.$route.query;
      const newQuery = {
        ...currentQuery,
        page: this.current_page
      };
      const queryString = Object.keys(newQuery).map(key => `${key}=${newQuery[key]}`).join('&');
      const url = `${window.location.pathname}?${queryString}`;

      window.location.href = url;
    },
    excel() {
      try {
        axios.post('/payment-transfers', {
          order_id: this.order_id,
          start_date: this.$route.query.start_date ? this.$route.query.start_date + ' 00:00:00' : null,
          end_date: this.$route.query.end_date ? this.$route.query.end_date + ' 23:59:00' : null,
          sort_column: this.sort_column,
          sort_direction: this.sort_direction
        }).then(response=>{
          if(response.data.status){
            this.$swal.fire({
              iconHtml: '<img src="/img/success.svg" width="32" height="32">',
              position: 'top-end',
              title: this.$i18n.t('You can save exported payments in the downloaded files section.'),
              showConfirmButton: false,
              timer: 2500,
              toast: true,
              customClass: {
                icon: 'swal2-no-border'
              }
            });
            setTimeout(()=>{
              this.$store.dispatch('topbar/getCount')
            },5000)
          }
        });
      } catch (error) {
        this.$swal({
          iconHtml: '<img src="/img/error.svg" width="32" height="32">',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
          title: this.$i18n.t('Error') + ' !',
          text: error.response.data.statusMessage,
          customClass: {
            icon: 'swal2-no-border'
          }
        })
      }
    }
  }
};
</script>

<style scoped>
.servislet-badge {
  margin: 0 !important;
  max-width: 180px;
  font-size: 12px;
}

.success-badge {
  background-color: #06A5D01F;
  color: #06a5d0;
}

.danger-badge {
  background-color: #EA54551F;
  color: #EA5455;
}

.search-area {
  border: 1px solid #06a5d0;
  border-radius: 5px;
  background-color: #f9f9f9;
  display: flex;
  align-items: center;
  color: #06a5d0;
  min-width: 300px;
  font-size: 14px;
  height: 40px;
  padding: 0 13.6px;
}

.search-text-input, .search-text-input::placeholder {
  color: #06a5d0 !important;
  font-size: 14px;
}

.filter-input {
  display: flex;
  border: 1px solid #06a5d0;
  align-items: center;
  border-radius: 5px;
  padding: 0 16px;
  color: #06a5d0;
  min-width: 300px;
}

.filter-input input, .filter-input input:focus {
  color: #06a5d0 !important;
  padding: 0 !important;
  font-size: 14px;
  border: none !important;
}

.filter-input input::placeholder {
  color: #06a5d0;
}

.products-double-flex-right-actions {
  padding: 12px 24px;
  height: unset;
}
</style>
