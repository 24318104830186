<template>
  <div>
    <div class="pro-card neu">
      <div class="pro-title">{{$t('Become a Business in Servislet Pro')}}</div>
      <div class="steps">
        <ul>
          <li>{{$t('Import products from catalog!')}}</li>
          <li>{{$t('Easily create offers for your customers!')}}</li>
          <li>{{$t('Easily add your customers in multiple ways!')}}</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DashboardPro"
}
</script>

<style scoped>
.pro-card {
  margin-top: 24px;
  border-radius: 10px;
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 262px;
}
.pro-title{
  font-family: Inter;
  font-weight: 800;
  font-size: 22px;
  line-height: 34px;
  letter-spacing: 0;
  color: #1C1C1C;
}
.steps ul li {
  font-family: Montserrat;
  font-weight: 400;
  font-size: 12px;
  line-height: 21px;
  letter-spacing: 0px;
  color: #1C1C1C;
  margin-top: 18px;
}
</style>
