<template>
  <DefaultLayout>
    <div>
      <PageBreadcrumbs :breadcrumbs="breadcrumbs" />
      <div
        id="products-listing-table-wrapper"
        class="mt-3 py-4">
        <div class="products-double-flex">
          <div class="products-double-flex-left">
            <button
              class="add-new-product-btn"
              @click="$router.push({ name: 'AddOffer' })">
              <i class="fa-solid fa-plus"></i>
              {{ $t('Add Offer') }}
            </button>
          </div>
          <div class="products-double-flex-right">
            <div
              class="products-double-flex-right-actions"
              style="max-width: unset;"
              v-if="!show_search_text"
              :class="!show_search_text ? 'opened' : ''"
              v-click-outside="close">
              <span>
                <i class="fa-solid fa-search ml-0"></i>
              </span>
              <b-form-input
                class="search-text-input"
                v-model="filter"
                v-on:keyup.enter="getOffers()"
                :placeholder="$t('Order ID, Customer or Product')"
                :class="!show_search_text ? 'show-search_text' : ''" />
              <i
                class="fa-solid fa-xmark close"
                @click="
                  show_search_text = !show_search_text;
                  filter = '';
                  getOffers();
                "></i>
            </div>
            <div
              class="products-double-flex-right-actions"
              @click="show_search_text = !show_search_text"
              v-else>
              <span>
                <i class="fa-solid fa-search"></i>
                <p>{{ $t('Search') }}</p>
              </span>
            </div>
            <!--            <div class="products-double-flex-right-actions">-->
            <!--              <span>-->
            <!--                  <i class="fa-solid fa-filter"></i>-->
            <!--                  <p>Filtre</p>-->
            <!--              </span>-->
            <!--            </div>-->
            <!--            <div class="products-double-flex-right-actions">-->
            <!--              <span>-->
            <!--                  <i class="fa-regular fa-link-simple"></i>-->
            <!--                  <p>Gönder</p>-->
            <!--              </span>-->
            <!--            </div>-->
          </div>
        </div>
        <div class="product-listing-table">
          <table v-if="!loader">
            <tr class="product-listing-table-head">
              <th>
                <label class="product-listing-checkbox-label">
                  <input
                    type="checkbox"
                    @click="checkUncheck()" />
                  <span class="checkmark"></span>
                </label>
              </th>
              <th>ID</th>
              <th>{{ $t('Customer') }}</th>
              <th>{{ $t('Car') }}</th>
              <th>{{ $t('Product/Service') }}</th>
              <th>{{ $t('Total') }}</th>
              <th>{{ $t('Order Channel') }}</th>
              <th>{{ $t('Date') }}</th>
              <th>{{ $t('Status') }}</th>
              <th></th>
            </tr>
            <tr
              v-for="offer in offers"
              :key="offer.$index">
              <td>
                <label class="product-listing-checkbox-label">
                  <input
                    type="checkbox"
                    name="single-payment-checkbox" />
                  <span class="checkmark"></span>
                </label>
              </td>
              <td>#{{ offer.id }}</td>
              <td>{{ offer.customer_name + ' ' + offer.customer_surname }}</td>
              <td>-</td>
              <td>-</td>
              <td>{{ formatPrice(offer.total_amount) }}</td>
              <td>{{ offer.branch ? offer.branch.company_title : '-' }}</td>
              <td>-</td>
              <td>
                <span class="servislet-badge sale-badge">
                  {{ $t('Completed') }}
                </span>
              </td>
              <td>
                <b-dropdown
                  right
                  no-caret>
                  <template #button-content>
                    <i class="fa-solid fa-ellipsis-vertical"></i>
                  </template>
                  <b-dropdown-item class="table-dropdown-actions">
                    <router-link :to="'/offer/' + offer.id">{{
                      $t('See More')
                    }}</router-link>
                  </b-dropdown-item>
                  <b-dropdown-item class="table-dropdown-actions">
                    <router-link :to="'/update-offer/' + offer.id">{{
                      $t('Edit')
                    }}</router-link>
                  </b-dropdown-item>
                  <b-dropdown-item
                    href="#"
                    class="table-dropdown-actions"
                    @click="openDeleteModal(offer.id)"
                    >{{ $t('Delete') }}</b-dropdown-item
                  >
                </b-dropdown>
              </td>
            </tr>
          </table>
          <div
            id="table-info-and-pagination"
            v-if="!loader && offers.length>0">
            <div class="table-results-info">
              <p>
                {{$t('{total} {from} {start}-{end} is showing.',{total: pagination.total, from: $t('from offers'), start: offers.length>0 ? ( pagination.current_page === 1 ? 1 : (( pagination.current_page - 1) * pagination.per_page) + 1 ) : 0, end: pagination.total_page === pagination.current_page ? pagination.total : pagination.current_page * pagination.per_page})}}
              </p>
            </div>
            <div class="pagination-wrapper">

              <b-pagination
                  v-model="current_page"
                  :total-rows="pagination.total"
                  :per-page="pagination.per_page"
                  align="center"
                  class="my-1"
                  pills
                  @input="getOffers()"/>
            </div>
            <b-modal
              id="delete-modal"
              ref="delete-modal"
              size="md"
              hide-footer
              centered
              hide-header>
              <div class="delete-modal-body">
                <div class="delete-modal-header">
                  <i class="fa-solid fa-xmark cursor-pointer"></i>
                </div>
                <div class="delete-modal-content-text">
                  {{ $t('Are you sure you want to delete the offer?') }}
                </div>
                <div class="delete-modal-buttons">
                  <b-button
                    class="cancel-button"
                    @click="$refs['delete-modal'].hide()"
                    >{{ $t('Cancel') }}</b-button
                  >
                  <b-button
                    class="delete-button"
                    @click="deleteOffer()"
                    >{{ $t('Delete') }}</b-button
                  >
                </div>
              </div>
            </b-modal>
          </div>
          <div v-if="!loader && offers.length === 0" class="d-flex flex-column align-items-center">
            <p class="table-no-text"> {{
                $t('No {data} found to list.', {data: $t('Offer').toLocaleLowerCase()})
              }}</p>
            <button @click="$router.push({ name: 'AddOffer' })" class="no-data-btn"><i class="fa-solid fa-plus"></i> {{$t('Add Offer')}}</button>
          </div>

          <div
            class="loader"
            v-if="loader">
            <content-placeholders :rounded="true">
              <content-placeholders-img />
              <content-placeholders-text :lines="10" />
            </content-placeholders>
          </div>
        </div>
      </div>
    </div>
  </DefaultLayout>
</template>

<script>
/* eslint-disable */
import DefaultLayout from '@/layouts/default';
import PageBreadcrumbs from '../../components/PageBreadcrumbs.vue';
import vClickOutside from "v-click-outside";

export default {
  components: {
    PageBreadcrumbs,
    DefaultLayout
  },
  data() {
    return {
      breadcrumbs: [
        {
          name: this.$i18n.t('Offers'),
          path: '/#'
        }
      ],
      per_page: 20,
      current_page: 1,
      deleted_offer_id: '',
      show_search_text: true,
      filter: null
    };
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  computed: {
    offers() {
      return this.$store.state.offers.list.items;
    },
    loader() {
      return this.$store.state.offers.list.loading;
    },
    pagination() {
      return this.$store.state.offers.list.pagination;
    }
  },
  mounted() {
    this.getOffers();
  },
  methods: {
    close(){
      this.show_search_text = true
    },
    openDeleteModal(id) {
      this.deleted_offer_id = id;
      this.$refs['delete-modal'].show();
    },
    deleteOffer() {
      this.$refs['delete-modal'].hide();
      this.$axios
        .delete(`/tire/offers/${this.deleted_offer_id}`)
        .then((response) => {
          if (response.data.status) {
            this.$swal.fire({
              iconHtml: '<img src="/img/success.svg" width="32" height="32">',
              position: 'top-end',
              title: this.$i18n.t('Offer successfully deleted.'),
              showConfirmButton: false,
              timer: 2500,
              toast: true,
              customClass: {
                icon: 'swal2-no-border'
              }
            });
            this.offers = [];
            this.getOffers();
          }
        });
    },
    getOffers() {
      this.$store.dispatch('offers/getOffersItem', {
        page: this.current_page,
        limit: this.per_page,
        search: this.filter
      });
    },
    formatPrice(price) {
      return price.toLocaleString('tr-TR', {
        style: 'currency',
        currency: 'TRY'
      });
    },
    checkUncheck(checkBox) {
      var get = document.getElementsByName('single-payment-checkbox');
      for (var i = 0; i < get.length; i++) {
        if (get[i].checked === true) {
          get[i].checked = false;
        } else {
          get[i].checked = true;
        }
        // without if => get[i].checked = checkBox.checked;}
      }
    }
  }
};
</script>

<style></style>
