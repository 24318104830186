import Vue from 'vue';
import CustomSelect from '@/components/shared/CustomSelect';
import GraySelect from '@/components/shared/GraySelect';
import TireBrand from '@/components/shared/TireBrandSelect';
import CategorySelect from '@/components/shared/CategorySelect';
import CustomerSelect from '@/components/shared/CustomerTypeSelect';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import './plugins/styles'

// Import Bootstrap and BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import Chartkick from 'vue-chartkick';
import Chart from 'chart.js';
import VueMask from 'v-mask';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.min.css';

import 'vue-select/dist/vue-select.css';
import { VueSelect } from 'vue-select';

import VueContentPlaceholders from 'vue-content-placeholders';
import money from 'v-money';
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate';
import '@/plugins/validation/validations';

import SsrCarousel from 'vue-ssr-carousel';
import ssrCarouselCss from 'vue-ssr-carousel/index.css';

Vue.use(VueContentPlaceholders);

Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);
Vue.use(Chartkick.use(Chart));
Vue.use(VueSweetalert2);

Vue.use(money, { precision: 4 });

Vue.use(VueMask);

Vue.component('ssr-carousel', SsrCarousel, ssrCarouselCss);
Vue.component('custom-select', CustomSelect);
Vue.component('gray-select', GraySelect);
Vue.component('tire-brand-select', TireBrand);
Vue.component('category-select', CategorySelect);
Vue.component('customer-select', CustomerSelect);
Vue.component('content');
// register globally
Vue.component('multiselect', Multiselect);
Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);
var locale = localStorage.getItem('locale') ? localStorage.getItem('locale') : 'tr'
localize(locale);

window.helper = require('@/helpers/index').default;

import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
Vue.component('flat-pickr', flatPickr);
