export default {
  listCollection(data) {
    if (data && data.length > 0) {
      data = data.map((item) => {
        item.car_name = item.car ? item.car.name : '-';
        item.full_name = item.name + ' ' + item.surname;
        item.customer_type = item.customerType.name;
        item.mobile = item.phones ? item.phones.phone : '-';
        item.full_car = item.car
          ? item.car.brand +
            ' ' +
            item.car.model +
            ' ' +
            item.car.version +
            ' ' +
            item.car.version_type
          : '-';

        return item;
      });
    }
    return data;
  },

  get(data) {
    return {
      data
    };
  }
};
