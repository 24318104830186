<template>
  <Default>

    <div class="profile-tabs">
      <span class="profile-tab-item" :class="tab === 1 ? 'active' : ''" @click="tab = 1; getProfile();">
        <i class="fa-regular fa-user"></i>
        {{ $t('Profile') }}
      </span>

      <span class="profile-tab-item" :class="tab === 5 ? 'active' : ''" @click="tab = 5; getWarehouses()">
        <i class="fa-regular fa-database"></i>
        {{ $t('Warehouses') }}
      </span>

      <span class="profile-tab-item" :class="tab === 2 ? 'active' : ''" @click="tab = 2; getMerchantUsers()">
        <i class="fa-regular fa-lock"></i>
        {{ $t('Users') }}
      </span>

      <span class="profile-tab-item" :class="tab === 3 ? 'active' : ''" @click="tab = 3">
        <i class="fa-regular fa-flag"></i>
        {{ $t('Payments and Plans') }}
      </span>
      <span class="profile-tab-item" :class="tab === 4 ? 'active' : ''" @click="tab = 4">
        <i class="fa-regular fa-bell"></i>
        {{ $t('Settings') }}
      </span>
    </div>

    <div v-if="tab === 1">
      <div class="neu profile-main-card" v-if="!loading">
        <div class="space-between">
          <p class="page-title">{{ $t('Account Information') }}</p>
          <div class="edit-btn" @click="disable_form_input = false">
            <img src="/icons/edit-icon.png" alt="" width="14">
            {{ $t('Edit') }}
          </div>
        </div>


        <div class="mt-30">
          <div class="d-flex justify-content-start">
            <div class="profile-img">
              <img :src="user.logo_path" v-if="user.logo_path"/>
              <img src="@/assets/images/no_image.png" v-else/>
            </div>
            <input type="file" ref="file" accept="image/*" style="display: none" @change="selectFileClick">

            <div class="img-btn-area">
              <div class="add-img-btn" @click="$refs.file.click()">
                <img src="/icons/take-photo.png" alt="" width="19">
                <span style="margin-top: 2px;">{{ $t('Add Logo') }}</span>
              </div>
              <div class="img-description">
                {{ $t('Upload an image in JPG, JPEG or PNG format.') }}
                <br>
                {{ $t('Maximum file size that can be uploaded') }}: 5MB
              </div>
            </div>
          </div>
        </div>

        <div class="mt-30">
          <div class="row">
            <div class="col-sm-12 col-md-6">
              <b-form-group :label="$t('Company Name')" class="w-100">
                <b-form-input type="text" v-model="user.title" :disabled="disable_form_input"></b-form-input>
              </b-form-group>
            </div>

            <div class="col-sm-12 col-md-6">
              <b-form-group :label="$t('E-Posta')" class="w-100">
                <b-form-input type="text" v-model="user.email" :disabled="disable_form_input"></b-form-input>
              </b-form-group>
            </div>

            <div class="col-sm-12 col-md-6">
              <b-form-group :label="$t('Phone')" class="w-100">
                <b-form-input v-model="user.phone" v-mask="'+90 (###) ### ## ##'"
                              :disabled="disable_form_input"></b-form-input>
              </b-form-group>
            </div>

            <div class="col-sm-12 col-md-6">
              <b-form-group :label="$t('Fax')" class="w-100">
                <b-form-input v-model="user.fax" v-mask="'+90 (###) ### ## ##'"
                              :disabled="disable_form_input"></b-form-input>
              </b-form-group>
            </div>

            <div class="col-sm-12 col-md-6">
              <b-form-group :label="$t('Tax Number')" class="w-100">
                <b-form-input type="number" v-model="user.tax_number" :disabled="disable_form_input"></b-form-input>
              </b-form-group>
            </div>

            <div class="col-sm-12 col-md-6">
              <b-form-group :label="$t('Tax Office')" class="w-100">
                <b-form-input type="text" v-model="user.tax_office" :disabled="disable_form_input"></b-form-input>
              </b-form-group>
            </div>

            <div class="col-sm-12 col-md-6">
              <b-form-group :label="$t('Bank Informations')" class="w-100">
                <b-form-select v-model="user.iban_bank_id" :options="banks"
                               :disabled="disable_form_input"></b-form-select>
              </b-form-group>
            </div>

            <div class="col-sm-12 col-md-6">
              <b-form-group :label="$t('IBAN')">
                <b-form-input v-model="user.iban" v-mask="'TR## #### #### #### ####'"
                              :disabled="disable_form_input"></b-form-input>
              </b-form-group>
            </div>
          </div>

          <div class="mt-3 d-flex align-items-center justify-content-end">
            <button class="empty-btn" v-if="!disable_form_input" @click="reset(); disable_form_input = true;">{{$t('Give Up')}}
            </button>
            <button class="save-btn" @click="updateForm()">{{$t('Save')}}</button>
          </div>
        </div>
      </div>

      <div
          v-else
          class="neu profile-main-card">
        <content-placeholders :rounded="true">
          <content-placeholders-img/>
          <content-placeholders-text :lines="10"/>
        </content-placeholders>
      </div>


      <!--      <div class="mt-30">-->
      <!--        <p class="page-title">PRO Plan</p>-->
      <!--      </div>-->

      <!--      <div class="pro-plan-card">-->
      <!--        <div class="plan-card-wrapper">-->
      <!--          <div class="flex-center-space">-->
      <!--            <div class="plan-card-left">-->
      <!--              <span class="plan-card-current-plan">PREMIUM</span>-->
      <!--              <p class="plan-card-price mt-4">₺250</p>-->
      <!--              <span>Per member, per yearly</span>-->
      <!--            </div>-->
      <!--            <div class="plan-card-list">-->
      <!--              <div class="d-flex gap-2 align-items-center" v-for="i in 4" :key="i.$index">-->
      <!--                <i class="fa-solid fa-check-circle"></i>-->
      <!--                Lorem ispum dolor sit-->
      <!--              </div>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--          <button>-->
      <!--            <i class="fa-solid fa-arrow-up-from-line mr-2"></i>-->
      <!--            Planı Yükselt-->
      <!--          </button>-->
      <!--        </div>-->
      <!--      </div>-->
    </div>

    <div v-if="tab === 2">
      <div class="neu profile-main-card">
        <div class="space-between">
          <p class="page-title">{{ $t('Users') }}</p>
          <div></div>
<!--          <div class="edit-btn">-->
<!--            <img src="/icons/edit-icon.png" alt="" width="14">-->
<!--            {{ $t('Edit') }}-->
<!--          </div>-->
        </div>
        <p class="page-description">{{ $t('Manage panel users and account permissions here.') }}</p>
        <hr>

        <div class="profile-tab-content">
          <div class="products-double-flex-left">
            <div class="search-area">
              <b-form-input
                  :placeholder="$t('Search by name or e-mail')"
                  class="p-0"
                  style="width: 100% !important; padding: 0 !important"
                  v-model="search_user"
                  v-on:keydown.enter="getMerchantUsers()"
              />
              <i class="fa-solid fa-xmark" @click="search_user = null; getMerchantUsers()" v-if="search_user"></i>
              <i class="fa-solid fa-filter" v-else></i>
              <!--              <i class="fa-solid fa-xmark cursor-pointer" v-else></i>-->
            </div>
          </div>
          <div class="products-double-flex-right">
            <div class="save-btn add-btn cursor-pointer" @click="openMUserOpen()">
              <i class="fa-solid fa-plus"></i>
              {{ $t('Add User') }}
            </div>
          </div>
        </div>

        <div class="product-listing-table px-0" v-if="!m_user_loading">
          <table>
            <tr class="product-listing-table-head lc-w-120">
<!--              <th>-->
<!--                <label class="product-listing-checkbox-label">-->
<!--                  <input type="checkbox"/>-->
<!--                  <span class="checkmark"></span>-->
<!--                </label>-->
<!--              </th>-->
              <th>{{ $t('Name Surname') }}</th>
              <th>{{ $t('Phone') }}</th>
              <th>{{ $t('E-Mail') }}</th>
              <th>{{ $t('Authorities') }}</th>
              <th></th>
            </tr>
            <tr v-for="item in m_users" :key="item.$index">
<!--              <td>-->
<!--                <label class="product-listing-checkbox-label">-->
<!--                  <input-->
<!--                      type="checkbox"-->
<!--                      name="single-payment-checkbox"/>-->
<!--                  <span class="checkmark"></span>-->
<!--                </label>-->
<!--              </td>-->
              <td>{{item.name + ' '+ item.surname}}</td>
              <td>{{item.phone ?? '-'}}</td>
              <td>{{item.email ?? '-'}}</td>
              <td>
                  <span class="servislet-badge" :class="item.merchant.is_admin === true ? 'sale-badge' : 'user-badge'">
                    {{ item.merchant.is_admin === true ? $t('Admin') : $t('User') }}
                  </span>
              </td>
              <td>
                <b-dropdown id="product-actions-dropdown" class="product-listing-action-select" no-caret right>
                  <template #button-content>
                        <span class="button-content">
                          {{ $t('Actions') }}
                          <i class="fa-solid fa-chevron-down"></i>
                        </span>
                  </template>
                  <b-dropdown-item class="table-dropdown-actions" @click="getUserDetail(item)">
                    {{ $t('Edit') }}
                  </b-dropdown-item>
                  <b-dropdown-item class="table-dropdown-actions" @click="m_id = item.id; $refs['delete-modal'].show()">
                    {{ $t('Delete') }}
                  </b-dropdown-item>
                </b-dropdown>
              </td>
            </tr>
          </table>

          <div v-if="m_users.length === 0" class="d-flex flex-column align-items-center">
            <p class="table-no-text"> {{
                $t('No {data} found to list.', {data: $t('User').toLocaleLowerCase()})
              }}</p>
          </div>
        </div>
        <div v-else class="product-listing-table px-0">
          <content-placeholders :rounded="true">
            <content-placeholders-img/>
            <content-placeholders-text :lines="10"/>
          </content-placeholders>
        </div>
      </div>
    </div>

    <div v-if="tab === 4">
      <div class="neu profile-main-card" v-if="!loading">
        <p class="page-title">{{ $t('Settings') }}</p>
        <hr>

        <div class="mt-30 d-flex align-items-center justify-content-between">
          <div class="d-flex align-items-center gap-3">
            <span class="span-text">{{ $t('Multiple Language') }}</span>

            <b-form-checkbox switch size="lg" class="cursor-pointer" v-model="user.multi_language"></b-form-checkbox>
          </div>

        </div>

        <div class="mt-30">
          <div class="row">
            <div class="col-sm-12 col-md-6">
              <b-form-group
                  :label="$t('Language')"
                  class="w-100">
                <b-form-input v-model="user.default_language"/>
                <!--                <b-form-select v-model="user.language" :options="languages"></b-form-select>-->
              </b-form-group>
            </div>
            <div class="col-sm-12 col-md-6">
              <b-form-group
                  :label="$t('Currency')"
                  class="w-100">
                <b-form-select v-model="user.currency_type_id" :options="currency"></b-form-select>
              </b-form-group>
            </div>

            <div class="col-sm-12 col-md-6">
              <b-form-group
                  :label="$t('Appointment Planning')"
                  class="w-100">
                <b-form-input v-model="user.waiting_days"></b-form-input>
                <i class="fa-solid fa-circle-info info-circle-tooltip-icon cursor-pointer" v-b-tooltip.hover.bottom
                   :title="$t('Determine how many days later users can select an appointment.')"></i>
              </b-form-group>
            </div>

            <div class="col-sm-12 col-md-6"/>
            <div class="col-sm-12 col-md-6"/>

            <div class="mt-3 d-flex align-items-center justify-content-end">
              <button class="empty-btn" v-if="!disable_form_input" @click="reset();">{{$t('Give Up')}}</button>

              <button class="save-btn" @click="updateForm()">{{$t('Save')}}</button>
            </div>
          </div>
        </div>

      </div>

      <div
          v-else
          class="neu profile-main-card">
        <content-placeholders :rounded="true">
          <content-placeholders-img/>
          <content-placeholders-text :lines="10"/>
        </content-placeholders>
      </div>
    </div>

    <div v-if="tab === 5">
      <div class="neu profile-main-card">
        <div class="space-between">
          <p class="page-title">{{ $t('Warehouses') }}</p>
          <div></div>
          <!--          <div class="edit-btn">-->
          <!--            <img src="/icons/edit-icon.png" alt="" width="14">-->
          <!--            {{ $t('Edit') }}-->
          <!--          </div>-->
        </div>
        <!--        <p class="page-description">{{ $t('Manage panel users and account permissions here.') }}</p>-->
        <hr>

        <div class="profile-tab-content">
          <div class="products-double-flex-left">
            <!--            <div class="search-area">-->
            <!--              <b-form-input-->
            <!--                  :placeholder="$t('Search by name or e-mail')"-->
            <!--                  class="p-0"-->
            <!--                  style="width: 100% !important; padding: 0 !important"-->
            <!--              />-->
            <!--              <i class="fa-solid fa-filter"></i>-->
            <!--              &lt;!&ndash;              <i class="fa-solid fa-xmark cursor-pointer" v-else></i>&ndash;&gt;-->
            <!--            </div>-->
          </div>
          <div class="products-double-flex-right">
            <div class="save-btn add-btn cursor-pointer" @click="openWarehouseModal()">
              <i class="fa-solid fa-plus"></i>
              {{ $t('Add Warehouse') }}
            </div>
          </div>
        </div>

        <div class="product-listing-table px-0" v-if="!warehouse_loading">
          <table>
            <tr class="product-listing-table-head lc-w-120">
              <!--                <th>-->
              <!--                  <label class="product-listing-checkbox-label">-->
              <!--                    <input type="checkbox"/>-->
              <!--                    <span class="checkmark"></span>-->
              <!--                  </label>-->
              <!--                </th>-->
              <th>{{ $t('ID') }}</th>
              <th>{{ $t('Code') }}</th>
              <th>{{ $t('Warehouse') }}</th>
              <th>{{ $t('E-Mail') }}</th>
              <th>{{ $t('Phone') }}</th>
              <th>{{ $t('City') }}</th>
              <th>{{ $t('District') }}</th>
              <th>{{ $t('Status') }}</th>
              <th></th>
            </tr>
            <tr v-for="item in warehouses" :key="item.$index">
              <!--                <td>-->
              <!--                  <label class="product-listing-checkbox-label">-->
              <!--                    <input-->
              <!--                        type="checkbox"-->
              <!--                        name="single-payment-checkbox"/>-->
              <!--                    <span class="checkmark"></span>-->
              <!--                  </label>-->
              <!--                </td>-->
              <td>{{ item.id }}</td>
              <td>{{ item.code }}</td>
              <td>{{ item.name }}</td>
              <td>{{ item.email ?? '-' }}</td>
              <td>{{ item.phone ?? '-' }}</td>
              <td>{{ item.city ? item.city.name : '-' }}</td>
              <td>{{ item.district ? item.district.name : '-' }}</td>
              <td>
                  <span class="servislet-badge" :class="item.is_active === true ? 'sale-badge' : 'error-badge'">
                    {{ item.is_active === true ? $t('Active') : $t('Inactive') }}
                  </span>
              </td>
              <td>
                <b-dropdown id="product-actions-dropdown" class="product-listing-action-select" no-caret right>
                  <template #button-content>
                            <span class="button-content">
                              {{ $t('Actions') }}
                              <i class="fa-solid fa-chevron-down"></i>
                            </span>
                  </template>
                  <!--          <b-dropdown-item class="table-dropdown-actions">-->
                  <!--            {{ $t('See Detail') }}-->
                  <!--          </b-dropdown-item>-->
                  <b-dropdown-item class="table-dropdown-actions" @click="setWarehouseUpdate(item)">
                    {{ $t('Edit') }}
                  </b-dropdown-item>
                  <!--          <b-dropdown-item class="table-dropdown-actions">-->
                  <!--            {{ $t('Delete') }}-->
                  <!--          </b-dropdown-item>-->
                </b-dropdown>
              </td>
            </tr>
          </table>

          <div v-if="warehouses.length === 0" class="d-flex flex-column align-items-center">
            <p class="table-no-text"> {{
                $t('No {data} found to list.', {data: $t('warehouse')})
              }}</p>
          </div>
        </div>

        <div v-else class="product-listing-table px-0">
          <content-placeholders :rounded="true">
            <content-placeholders-img/>
            <content-placeholders-text :lines="10"/>
          </content-placeholders>
        </div>
      </div>
    </div>

    <b-modal id="add-warehouse-modal" ref="add-warehouse-modal" size="md" centered hide-header>
      <div class="col-sm-12 space-between px-0">
        <p class="customer-add-modal-title">{{ $t('Add Warehouse') }}</p>

        <i class="fa-solid fa-xmark cursor-pointer" @click="$refs['add-warehouse-modal'].hide()"></i>
      </div>

      <ValidationObserver ref="addWarehouseRules">
        <div class="row px-0">
          <div class="col-sm-12 col-md-6 mt-2">
            <ValidationProvider rules="required|min:1|max:10" :name="$t('Code')" #default="{ errors }">
              <b-form-group :label="$t('Code')+ ' *'" class="w-100">
                <b-form-input type="text" class="modal-input" autocomplete="false" v-model="w_code"></b-form-input>
                <label for="code" v-if="errors.length > 0" class="text-danger"><small>*</small>{{ errors[0] }}.</label>
                <!--                <label for="code" v-else class="opacity-0"><small>*</small></label>-->
              </b-form-group>
            </ValidationProvider>
          </div>

          <div class="col-sm-12 col-md-6 mt-2">
            <ValidationProvider rules="required|min:1|max:30" :name="$t('Warehouse Name')" #default="{ errors }">
              <b-form-group :label="$t('Warehouse Name')+' *'" class="w-100">
                <b-form-input type="text" class="modal-input" autocomplete="false" v-model="w_name"></b-form-input>
                <label for="name" v-if="errors.length > 0" class="text-danger"><small>*</small>{{ errors[0] }}.</label>
                <!--                <label for="name" v-else class="opacity-0"><small>*</small></label>-->
              </b-form-group>
            </ValidationProvider>
          </div>

          <div class="col-sm-12 col-md-6">
            <ValidationProvider rules="min:19|max:19" :name="$t('Phone')" #default="{ errors }">
              <b-form-group :label="$t('Phone')" class="w-100">
                <b-form-input type="text" class="modal-input" autocomplete="false" v-mask="'+90 (###) ### ## ##'"
                              v-model="w_phone"></b-form-input>
                <label for="phone" v-if="errors.length > 0" class="text-danger"><small>*</small>{{ errors[0] }}.</label>
                <!--                <label for="phone" v-else class="opacity-0"><small>*</small></label>-->
              </b-form-group>
            </ValidationProvider>
          </div>

          <div class="col-sm-12 col-md-6">
            <ValidationProvider rules="email|max:40" :name="$t('E-Mail')" #default="{ errors }">
              <b-form-group :label="$t('E-Mail')" class="w-100">
                <b-form-input type="text" class="modal-input" autocomplete="false" v-model="w_email"></b-form-input>
                <label for="email" v-if="errors.length > 0" class="text-danger"><small>*</small>{{ errors[0] }}.</label>
                <!--                <label for="email" v-else class="opacity-0"><small>*</small></label>-->
              </b-form-group>
            </ValidationProvider>
          </div>

          <div class="col-sm-12 col-md-6">
            <ValidationProvider rules="max:40" :name="$t('Latitude')" #default="{ errors }">
              <b-form-group :label="$t('Latitude')" class="w-100">
                <b-form-input type="number" autocomplete="false" class="modal-input" v-model="w_lat"></b-form-input>
                <label for="latitude" v-if="errors.length > 0" class="text-danger"><small>*</small>{{
                    errors[0]
                  }}.</label>
                <!--                <label for="latitude" v-else class="opacity-0"><small>*</small></label>-->
              </b-form-group>
            </ValidationProvider>
          </div>

          <div class="col-sm-12 col-md-6">
            <ValidationProvider rules="max:40" :name="$t('Longitude')" #default="{ errors }">
              <b-form-group :label="$t('Longitude')" class="w-100">
                <b-form-input type="number" class="modal-input" autocomplete="false" v-model="w_lon"></b-form-input>
                <label for="longitude" v-if="errors.length > 0" class="text-danger"><small>*</small>{{
                    errors[0]
                  }}.</label>
                <!--                <label for="longitude" v-else class="opacity-0"><small>*</small></label>-->
              </b-form-group>
            </ValidationProvider>
          </div>

          <div class="col-sm-12 col-md-6">
            <b-form-group :label="$t('Country')" class="w-100">
              <b-form-select class="modal-input" :options="countries" v-model="w_country_id"></b-form-select>
            </b-form-group>
          </div>

          <div class="col-sm-12 col-md-6">
            <b-form-group :label="$t('City')" class="w-100">
              <b-form-select class="modal-input" :options="cities" v-model="w_city_id"
                             @change="getDistricts()"></b-form-select>
            </b-form-group>
          </div>

          <div class="col-sm-12 col-md-6">
            <b-form-group :label="$t('District')" class="w-100">
              <b-form-select class="modal-input" :options="districts" v-model="w_district_id"
                             @change="getAreas()"></b-form-select>
            </b-form-group>
          </div>

          <div class="col-sm-12 col-md-6">
            <b-form-group :label="$t('Area')" class="w-100">
              <b-form-select class="modal-input" :options="areas" v-model="w_area_id"></b-form-select>
            </b-form-group>
          </div>

          <div class="col-sm-12 col-md-12">
            <ValidationProvider rules="max:200" :name="$t('Address')" #default="{ errors }">
              <b-form-group :label="$t('Address')" class="w-100">
                <b-form-textarea class="modal-input" rows="3" v-model="w_address"></b-form-textarea>
                <label for="address" v-if="errors.length > 0" class="text-danger"><small>*</small>{{
                    errors[0]
                  }}.</label>
                <!--                <label for="address" v-else class="opacity-0"><small>*</small></label>-->
              </b-form-group>
            </ValidationProvider>
          </div>

          <div class="col-sm-12 col-md-6">

            <div class="d-flex align-items-center gap-3">
              <span class="fs-12" style="color: #6E6B7B">{{ $t('Active Status') }}</span>

              <b-form-checkbox switch size="lg" class="cursor-pointer" v-model="w_is_active"></b-form-checkbox>
            </div>
          </div>

          <div class="col-sm-12 col-md-12" v-if="show_integrate">
            <hr>
          </div>

          <div class="col-sm-12 col-md-6" v-if="show_integrate">
            <b-form-group :label="$t('Integration')" class="w-100">
              <b-form-select class="modal-input" :options="integrations" v-model="w_integration_id"></b-form-select>
            </b-form-group>
          </div>

          <div class="col-sm-12 col-md-5" v-if="show_integrate">
            <b-form-group :label="$t('Integration Branch')" class="w-100">
              <b-form-select class="modal-input" :options="integration_branches"
                             v-model="w_integration_branch_id"></b-form-select>
            </b-form-group>
          </div>

          <div class="col-sm-12 col-md-1 pl-0 d-flex align-items-center" v-if="show_integrate">
            <b-form-group class="w-100 mb-0 mt-2">
              <button class="update-btn p-2" @click="addIntegration()">
                <i class="fa-solid fa-plus"></i>
              </button>
            </b-form-group>
          </div>

          <div class="col-sm-12 col-md-12" v-if="list.length>0">
            <table class="table" style="max-height: 200px;">
              <thead>
              <tr>
                <th scope="col">{{$t('Integration')}}</th>
                <th scope="col">{{$t('Integration Branch')}}</th>
                <th></th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(i,key) in list" :key="i.$index">
                <td>{{i.integration ? i.integration.name : ''}}</td>
                <td>{{i.integration_branch ? i.integration_branch.name : ''}}</td>
                <td class="pr-0">
                  <div class="d-flex justify-content-end pr-0">
                    <button class="trash-btn" @click="list.splice(key,1)">
                      <i class="fa-regular fa-trash"></i>
                    </button>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </ValidationObserver>

      <template #modal-footer="{}">
        <button class="update-btn" @click="addWarehouse()">
          {{ $t('Add') }}
        </button>
      </template>
    </b-modal>

    <b-modal id="update-warehouse-modal" ref="update-warehouse-modal" size="md" centered hide-header>
      <div class="col-sm-12 space-between px-0">
        <p class="customer-add-modal-title">{{ $t('Update Warehouse') }}</p>

        <i class="fa-solid fa-xmark cursor-pointer" @click="$refs['update-warehouse-modal'].hide()"></i>
      </div>

      <ValidationObserver ref="updateWarehouseRules">
        <div class="row px-0">
          <div class="col-sm-12 col-md-6 mt-2">
            <ValidationProvider rules="required|min:1|max:10" :name="$t('Code')" #default="{ errors }">
              <b-form-group :label="$t('Code')+ ' *'" class="w-100">
                <b-form-input type="text" class="modal-input" autocomplete="false" v-model="w_code"></b-form-input>
                <label for="code" v-if="errors.length > 0" class="text-danger"><small>*</small>{{ errors[0] }}.</label>
                <!--                <label for="code" v-else class="opacity-0"><small>*</small></label>-->
              </b-form-group>
            </ValidationProvider>
          </div>

          <div class="col-sm-12 col-md-6 mt-2">
            <ValidationProvider rules="required|min:1|max:30" :name="$t('Warehouse Name')" #default="{ errors }">
              <b-form-group :label="$t('Warehouse Name')+' *'" class="w-100">
                <b-form-input type="text" class="modal-input" autocomplete="false" v-model="w_name"></b-form-input>
                <label for="name" v-if="errors.length > 0" class="text-danger"><small>*</small>{{ errors[0] }}.</label>
                <!--                <label for="name" v-else class="opacity-0"><small>*</small></label>-->
              </b-form-group>
            </ValidationProvider>
          </div>

          <div class="col-sm-12 col-md-6">
            <ValidationProvider rules="min:19|max:19" :name="$t('Phone')" #default="{ errors }">
              <b-form-group :label="$t('Phone')" class="w-100">
                <b-form-input type="text" class="modal-input" autocomplete="false" v-mask="'+90 (###) ### ## ##'"
                              v-model="w_phone"></b-form-input>
                <label for="phone" v-if="errors.length > 0" class="text-danger"><small>*</small>{{ errors[0] }}.</label>
                <!--                <label for="phone" v-else class="opacity-0"><small>*</small></label>-->
              </b-form-group>
            </ValidationProvider>
          </div>

          <div class="col-sm-12 col-md-6">
            <ValidationProvider rules="email|max:40" :name="$t('E-Mail')" #default="{ errors }">
              <b-form-group :label="$t('E-Mail')" class="w-100">
                <b-form-input type="text" class="modal-input" autocomplete="false" v-model="w_email"></b-form-input>
                <label for="email" v-if="errors.length > 0" class="text-danger"><small>*</small>{{ errors[0] }}.</label>
                <!--                <label for="email" v-else class="opacity-0"><small>*</small></label>-->
              </b-form-group>
            </ValidationProvider>
          </div>

          <div class="col-sm-12 col-md-6">
            <ValidationProvider rules="max:40" :name="$t('Latitude')" #default="{ errors }">
              <b-form-group :label="$t('Latitude')" class="w-100">
                <b-form-input type="number" autocomplete="false" class="modal-input" v-model="w_lat"></b-form-input>
                <label for="latitude" v-if="errors.length > 0" class="text-danger"><small>*</small>{{
                    errors[0]
                  }}.</label>
                <!--                <label for="latitude" v-else class="opacity-0"><small>*</small></label>-->
              </b-form-group>
            </ValidationProvider>
          </div>

          <div class="col-sm-12 col-md-6">
            <ValidationProvider rules="max:40" :name="$t('Longitude')" #default="{ errors }">
              <b-form-group :label="$t('Longitude')" class="w-100">
                <b-form-input type="number" class="modal-input" autocomplete="false" v-model="w_lon"></b-form-input>
                <label for="longitude" v-if="errors.length > 0" class="text-danger"><small>*</small>{{
                    errors[0]
                  }}.</label>
                <!--                <label for="longitude" v-else class="opacity-0"><small>*</small></label>-->
              </b-form-group>
            </ValidationProvider>
          </div>

          <div class="col-sm-12 col-md-6">
            <b-form-group :label="$t('Country')" class="w-100">
              <b-form-select class="modal-input" :options="countries" v-model="w_country_id"></b-form-select>
            </b-form-group>
          </div>

          <div class="col-sm-12 col-md-6">
            <b-form-group :label="$t('City')" class="w-100">
              <b-form-select class="modal-input" :options="cities" v-model="w_city_id"
                             @change="getDistricts()"></b-form-select>
            </b-form-group>
          </div>

          <div class="col-sm-12 col-md-6">
            <b-form-group :label="$t('District')" class="w-100">
              <b-form-select class="modal-input" :options="districts" v-model="w_district_id"
                             @change="getAreas()"></b-form-select>
            </b-form-group>
          </div>

          <div class="col-sm-12 col-md-6">
            <b-form-group :label="$t('Area')" class="w-100">
              <b-form-select class="modal-input" :options="areas" v-model="w_area_id"></b-form-select>
            </b-form-group>
          </div>

          <div class="col-sm-12 col-md-12">
            <ValidationProvider rules="max:200" :name="$t('Address')" #default="{ errors }">
              <b-form-group :label="$t('Address')" class="w-100">
                <b-form-textarea class="modal-input" rows="3" v-model="w_address"></b-form-textarea>
                <label for="address" v-if="errors.length > 0" class="text-danger"><small>*</small>{{
                    errors[0]
                  }}.</label>
                <!--                <label for="address" v-else class="opacity-0"><small>*</small></label>-->
              </b-form-group>
            </ValidationProvider>
          </div>

          <div class="col-sm-12 col-md-6">
            <div class="d-flex align-items-center gap-3">
              <span class="fs-12" style="color: #6E6B7B">{{ $t('Active Status') }}</span>

              <b-form-checkbox switch size="lg" class="cursor-pointer" v-model="w_is_active"></b-form-checkbox>
            </div>
          </div>

          <div class="col-sm-12 col-md-12" v-if="show_integrate">
            <hr>
          </div>

          <div class="col-sm-12 col-md-6" v-if="show_integrate">
            <b-form-group :label="$t('Integration')" class="w-100">
              <b-form-select class="modal-input" :options="integrations" v-model="w_integration_id"></b-form-select>
            </b-form-group>
          </div>

          <div class="col-sm-12 col-md-5" v-if="show_integrate">
            <b-form-group :label="$t('Integration Branch')" class="w-100">
              <b-form-select class="modal-input" :options="integration_branches"
                             v-model="w_integration_branch_id"></b-form-select>
            </b-form-group>
          </div>

          <div class="col-sm-12 col-md-1 pl-0 d-flex align-items-center" v-if="show_integrate">
            <b-form-group class="w-100 mb-0 mt-2">
              <button class="update-btn p-2" @click="addIntegration()">
                <i class="fa-solid fa-plus"></i>
              </button>
            </b-form-group>
          </div>

          <div class="col-sm-12 col-md-12" v-if="list.length>0">
            <table class="table" style="max-height: 200px;">
              <thead>
              <tr>
                <th scope="col">{{$t('Integration')}}</th>
                <th scope="col">{{$t('Integration Branch')}}</th>
                <th></th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(i,key) in list" :key="i.$index">
                <td>{{i.integration ? i.integration.name : ''}}</td>
                <td>{{i.integration_branch ? i.integration_branch.name : ''}}</td>
                <td class="pr-0">
                  <div class="d-flex justify-content-end pr-0">
                    <button class="trash-btn" @click="list.splice(key,1)">
                      <i class="fa-regular fa-trash"></i>
                    </button>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </ValidationObserver>

      <template #modal-footer="{}">
        <button class="update-btn" @click="updateWarehouse()">
          {{ $t('Update') }}
        </button>
      </template>
    </b-modal>

    <b-modal id="add-user-modal" ref="add-user-modal" size="md" centered hide-header>
      <div class="col-sm-12 space-between px-0">
        <p class="customer-add-modal-title">{{ $t('Add User') }}</p>

        <i class="fa-solid fa-xmark cursor-pointer" @click="$refs['add-user-modal'].hide()"></i>
      </div>

      <ValidationObserver ref="addUserRules">
        <div class="row px-0">
          <div class="col-sm-12 col-md-6 mt-2">
            <ValidationProvider rules="required|min:1|max:60" :name="$t('Name')" #default="{ errors }">
              <b-form-group :label="$t('Name')+ ' *'" class="w-100">
                <b-form-input type="text" class="modal-input" autocomplete="false" v-model="m_name"></b-form-input>
                <label for="code" v-if="errors.length > 0" class="text-danger"><small>*</small>{{ errors[0] }}.</label>
              </b-form-group>
            </ValidationProvider>
          </div>

          <div class="col-sm-12 col-md-6 mt-2">
            <ValidationProvider rules="required|min:1|max:60" :name="$t('Surname')" #default="{ errors }">
              <b-form-group :label="$t('Surname')+' *'" class="w-100">
                <b-form-input type="text" class="modal-input" autocomplete="false" v-model="m_surname"></b-form-input>
                <label for="name" v-if="errors.length > 0" class="text-danger"><small>*</small>{{ errors[0] }}.</label>
              </b-form-group>
            </ValidationProvider>
          </div>

          <div class="col-sm-12 col-md-6">
            <ValidationProvider rules="min:19|max:19" :name="$t('Phone')" #default="{ errors }">
              <b-form-group :label="$t('Phone')" class="w-100">
                <b-form-input type="text" class="modal-input" autocomplete="false" v-mask="'+90 (###) ### ## ##'"
                              v-model="m_phone"></b-form-input>
                <label for="phone" v-if="errors.length > 0" class="text-danger"><small>*</small>{{ errors[0] }}.</label>
              </b-form-group>
            </ValidationProvider>
          </div>

          <div class="col-sm-12 col-md-6">
            <ValidationProvider rules="required|email|max:40" :name="$t('E-Mail')" #default="{ errors }">
              <b-form-group :label="$t('E-Mail')+' *'" class="w-100">
                <b-form-input type="text" class="modal-input" autocomplete="false" v-model="m_email"></b-form-input>
                <label for="email" v-if="errors.length > 0" class="text-danger"><small>*</small>{{ errors[0] }}.</label>
              </b-form-group>
            </ValidationProvider>
          </div>

          <div class="col-sm-12 col-md-6">
            <ValidationProvider rules="required|min:6|max:50" vid="m_password" :name="$t('Password')" #default="{ errors }">
              <b-form-group :label="$t('Password')" class="w-100">
                <b-form-input type="password" v-if="m_is_password" class="modal-input" autocomplete="false" v-model="m_password"></b-form-input>
                <b-form-input type="text" v-else class="modal-input" autocomplete="false" v-model="m_password"></b-form-input>
                <i class="fa-regular info-circle-tooltip-icon cursor-pointer" style="top: 35%"  :class="m_is_password ? 'fa-eye-slash' : 'fa-eye'" @click="m_is_password = !m_is_password"></i>
                <label for="email" v-if="errors.length > 0" class="text-danger fs-12"><small>*</small>{{ errors[0] }}.</label>
                <label for="email" v-else class="text-danger opacity-0 fs-12"><small>*</small></label>
              </b-form-group>
            </ValidationProvider>
          </div>

          <div class="col-sm-12 col-md-6">
            <ValidationProvider rules="required|confirmed:m_password" :name="$t('Password Confirmation')" #default="{ errors }">
              <b-form-group :label="$t('Password Confirmation')" class="w-100">
                <b-form-input type="password" v-if="m_is_password_confirm" class="modal-input" autocomplete="false" v-model="m_password_confirmation"></b-form-input>
                <b-form-input type="text" v-else class="modal-input" autocomplete="false" v-model="m_password_confirmation"></b-form-input>
                <i class="fa-regular info-circle-tooltip-icon cursor-pointer" style="top: 35%" :class="m_is_password_confirm ? 'fa-eye-slash' : 'fa-eye'" @click="m_is_password_confirm = !m_is_password_confirm"></i>
                <label for="email" v-if="errors.length > 0" class="text-danger fs-12"><small>*</small>{{ errors[0] }}.</label>
                <label for="email" v-else class="text-danger opacity-0 fs-12"><small>*</small></label>
              </b-form-group>
            </ValidationProvider>
          </div>


          <div class="col-sm-12 col-md-6">
            <div class="d-flex align-items-center gap-3">
              <span class="fs-12" style="color: #6E6B7B">{{ $t('Active') }}?</span>

              <b-form-checkbox switch size="lg" class="cursor-pointer" v-model="m_is_active"></b-form-checkbox>
            </div>
          </div>

          <div class="col-sm-12 col-md-6">
            <div class="d-flex align-items-center gap-3">
              <span class="fs-12" style="color: #6E6B7B">{{ $t('Admin') }}?</span>

              <b-form-checkbox switch size="lg" class="cursor-pointer" v-model="m_is_admin"></b-form-checkbox>
            </div>
          </div>

        </div>
      </ValidationObserver>

      <template #modal-footer="{}">
        <button class="update-btn" @click="addUser()">
          {{ $t('Add') }}
        </button>
      </template>
    </b-modal>

    <b-modal id="update-user-modal" ref="update-user-modal" size="md" centered hide-header>
      <div class="col-sm-12 space-between px-0">
        <p class="customer-add-modal-title">{{ $t('Update User') }}</p>

        <i class="fa-solid fa-xmark cursor-pointer" @click="$refs['update-user-modal'].hide()"></i>
      </div>

      <ValidationObserver ref="updateUserRules">
        <div class="row px-0">
          <div class="col-sm-12 col-md-6 mt-2">
            <ValidationProvider rules="required|min:1|max:60" :name="$t('Name')" #default="{ errors }">
              <b-form-group :label="$t('Name')+ ' *'" class="w-100">
                <b-form-input type="text" class="modal-input" autocomplete="false" v-model="m_name"></b-form-input>
                <label for="code" v-if="errors.length > 0" class="text-danger"><small>*</small>{{ errors[0] }}.</label>
              </b-form-group>
            </ValidationProvider>
          </div>

          <div class="col-sm-12 col-md-6 mt-2">
            <ValidationProvider rules="required|min:1|max:60" :name="$t('Surname')" #default="{ errors }">
              <b-form-group :label="$t('Surname')+' *'" class="w-100">
                <b-form-input type="text" class="modal-input" autocomplete="false" v-model="m_surname"></b-form-input>
                <label for="name" v-if="errors.length > 0" class="text-danger"><small>*</small>{{ errors[0] }}.</label>
              </b-form-group>
            </ValidationProvider>
          </div>

          <div class="col-sm-12 col-md-6">
            <ValidationProvider rules="min:19|max:19" :name="$t('Phone')" #default="{ errors }">
              <b-form-group :label="$t('Phone')" class="w-100">
                <b-form-input type="text" class="modal-input" autocomplete="false" v-mask="'+90 (###) ### ## ##'"
                              v-model="m_phone"></b-form-input>
                <label for="phone" v-if="errors.length > 0" class="text-danger"><small>*</small>{{ errors[0] }}.</label>
              </b-form-group>
            </ValidationProvider>
          </div>

          <div class="col-sm-12 col-md-6">
            <ValidationProvider rules="required|email|max:40" :name="$t('E-Mail')" #default="{ errors }">
              <b-form-group :label="$t('E-Mail')+' *'" class="w-100">
                <b-form-input type="text" class="modal-input" autocomplete="false" v-model="m_email"></b-form-input>
                <label for="email" v-if="errors.length > 0" class="text-danger"><small>*</small>{{ errors[0] }}.</label>
              </b-form-group>
            </ValidationProvider>
          </div>

          <div class="col-sm-12 col-md-6">
            <div class="d-flex align-items-center gap-3">
              <span class="fs-12" style="color: #6E6B7B">{{ $t('Active') }}?</span>

              <b-form-checkbox switch size="lg" class="cursor-pointer" v-model="m_is_active"></b-form-checkbox>
            </div>
          </div>

          <div class="col-sm-12 col-md-6">
            <div class="d-flex align-items-center gap-3">
              <span class="fs-12" style="color: #6E6B7B">{{ $t('Admin') }}?</span>

              <b-form-checkbox switch size="lg" class="cursor-pointer" v-model="m_is_admin"></b-form-checkbox>
            </div>
          </div>

          <div class="col-sm-12 col-md-6 mt-3">
            <div class="d-flex align-items-center justify-content-between fs-12 font-servislet cursor-pointer" @click="visible_collapse = !visible_collapse">
              <span>{{$t('I want to update password')}}</span>
              <i class="fa-solid" :class="visible_collapse ? 'fa-chevron-up' : 'fa-chevron-down'"></i>
            </div>
          </div>
          <div class="col-sm-12 col-md-6 mt-3" />

          <b-collapse id="collapse-password" :visible="visible_collapse">
            <div class="row mt-3">
              <div class="col-sm-12 col-md-6">
                <ValidationProvider rules="min:6|max:50" vid="m_password" :name="$t('Password')" #default="{ errors }">
                  <b-form-group :label="$t('Password')" class="w-100">
                    <b-form-input type="password" v-if="m_is_password" class="modal-input" autocomplete="false" v-model="m_password"></b-form-input>
                    <b-form-input type="text" v-else class="modal-input" autocomplete="false" v-model="m_password"></b-form-input>
                    <i class="fa-regular info-circle-tooltip-icon cursor-pointer" style="top: 35%"  :class="m_is_password ? 'fa-eye-slash' : 'fa-eye'" @click="m_is_password = !m_is_password"></i>
                    <label for="email" v-if="errors.length > 0" class="text-danger fs-12"><small>*</small>{{ errors[0] }}.</label>
                    <label for="email" v-else class="text-danger opacity-0 fs-12"><small>*</small></label>
                  </b-form-group>
                </ValidationProvider>
              </div>

              <div class="col-sm-12 col-md-6">
                <ValidationProvider :rules="m_password ? 'required|confirmed:m_password' : ''" :name="$t('Password')" #default="{ errors }">
                  <b-form-group :label="$t('Password Confirmation')" class="w-100">
                    <b-form-input type="password" v-if="m_is_password_confirm" class="modal-input" autocomplete="false" v-model="m_password_confirmation"></b-form-input>
                    <b-form-input type="text" v-else class="modal-input" autocomplete="false" v-model="m_password_confirmation"></b-form-input>
                    <i class="fa-regular info-circle-tooltip-icon cursor-pointer" style="top: 35%" :class="m_is_password_confirm ? 'fa-eye-slash' : 'fa-eye'" @click="m_is_password_confirm = !m_is_password_confirm"></i>
                    <label for="email" v-if="errors.length > 0" class="text-danger fs-12"><small>*</small>{{ errors[0] }}.</label>
                    <label for="email" v-else class="text-danger opacity-0 fs-12"><small>*</small></label>
                  </b-form-group>
                </ValidationProvider>
              </div>
            </div>
          </b-collapse>

        </div>
      </ValidationObserver>

      <template #modal-footer="{}">
        <button class="update-btn" @click="updateUser()">
          {{ $t('Update') }}
        </button>
      </template>
    </b-modal>

    <b-modal
        id="delete-modal"
        ref="delete-modal"
        size="md"
        hide-footer
        centered
        hide-header>
      <div class="delete-modal-body">
        <div class="delete-modal-header">
          <i class="fa-solid fa-xmark cursor-pointer" @click="$refs['delete-modal'].hide()"></i>
        </div>
        <div class="delete-modal-content-text">
          {{ $t('Are you sure you want to delete user?') }}
        </div>
        <div class="delete-modal-buttons">
          <b-button
              class="cancel-button"
              @click="$refs['delete-modal'].hide()"
          >{{ $t('Cancel') }}</b-button
          >
          <b-button
              class="delete-button"
              @click="deleteUser()"
          >{{ $t('Delete') }}</b-button
          >
        </div>
      </div>
    </b-modal>
  </Default>
</template>

<script>
/* eslint-disable */
import PageBreadcrumbs from '../../components/PageBreadcrumbs.vue';
import Default from "@/layouts/default.vue";
import axios from "@/plugins/axios";

export default {
  components: {
    PageBreadcrumbs,
    Default
  },
  data() {
    return {
      tab: 1,
      loading: true,
      user: {},
      selectedFile: null,
      disable_form_input: true,
      banks: [],
      currency: [
        {value: null, text: this.$i18n.t('Choose')}
      ],
      warehouse_loading: true,
      warehouses: [],
      countries: [
        {value: 'tr', text: this.$i18n.t('Turkey')}
      ],
      cities: [],
      districts: [
        {value: null, text: this.$i18n.t('Choose')}
      ],
      areas: [
        {value: null, text: this.$i18n.t('Choose')}
      ],
      integration_branches: [
        {value: null, text: this.$i18n.t('Choose')}
      ],
      //profil resetleme için
      title: '',
      email: '',
      phone: '',
      fax: '',
      tax_number: '',
      tax_office: '',
      iban_bank_id: '',
      iban: '',
      currency_type_id: '',
      default_language: '',
      multi_language: null,
      waiting_days: '',

      //depolar için ekleme güncelleme keyleri
      w_code: null,
      w_name: null,
      w_phone: null,
      w_email: null,
      w_lat: null,
      w_lon: null,
      w_country_id: 'tr',
      w_city_id: null,
      w_district_id: null,
      w_area_id: null,
      w_address: null,
      w_integration_id: null,
      w_integration_branch_id: null,
      w_is_active: true,
      w_id: null,
      w_i_id:null,

      show_integrate: false,
      list:[],

      //merchant user
      m_user_loading: true,
      m_users:[],
      m_name: null,
      m_surname: null,
      m_phone: null,
      m_email: null,
      m_password: null,
      m_password_confirmation: null,
      m_is_active: true,
      m_is_admin: false,
      m_is_password:true,
      m_is_password_confirm:true,
      visible_collapse: false,
      m_id: null,
      search_user: null
    }
  },
  mounted() {
    this.getCurrencyTypes()
    this.getBanks()
    this.getProfile()
    if(JSON.parse(localStorage.getItem('userData')).integrations.length>0){
      this.show_integrate = true
    }
  },
  computed: {
    integrations() {
      if (localStorage.getItem('userData')) {
        var ints = [
          {value: null, text: this.$i18n.t('Choose')}
        ]
        JSON.parse(localStorage.getItem('userData')).integrations.forEach(item => {
          ints.push({
            value: item,
            text: item.name
          })
        })
        return ints
      } else {
        return [
          {value: null, text: this.$i18n.t('Choose')}
        ]
      }
    }
  },
  methods: {
    addIntegration(){
      this.list.push({
        integration: this.w_integration_id,
        integration_id: this.w_integration_id.id,
        integration_branch_id: this.w_integration_branch_id.id,
        integration_branch: this.w_integration_branch_id,
      })
      this.w_integration_branch_id = null
      this.w_integration_id = null
    },
    getProfile() {
      this.loading = true
      axios.get('/merchants').then(response => {
        this.user = response.data.responseData.merchant
        this.title = response.data.responseData.merchant.title
        this.email = response.data.responseData.merchant.email
        this.phone = response.data.responseData.merchant.phone
        this.fax = response.data.responseData.merchant.fax
        this.tax_number = response.data.responseData.merchant.tax_number
        this.tax_office = response.data.responseData.merchant.tax_office
        this.iban_bank_id = response.data.responseData.merchant.iban_bank_id
        this.iban = response.data.responseData.merchant.iban
        this.currency_type_id = response.data.responseData.merchant.currency_type_id
        this.default_language = response.data.responseData.merchant.default_language
        this.multi_language = response.data.responseData.merchant.multi_language
        this.waiting_days = response.data.responseData.merchant.waiting_days
        this.loading = false
      })
    },
    getWarehouses() {
      this.warehouse_loading = true
      axios.get('/warehouses').then(response => {
        if (response.data.status) {
          this.warehouses = response.data.responseData.warehouses
          this.warehouse_loading = false
        }
      }).catch(error => {
        var message = error.response.data.responseData.error ? error.response.data.responseData.error[0].message : error.response.data.responseData.message;
        this.$swal.fire({
          iconHtml: '<img src="/img/error.svg" width="32" height="32">',
          position: 'top-end',
          title: message,
          showConfirmButton: false,
          timer: 2500,
          toast: true,
          customClass: {
            icon: 'swal2-no-border'
          }
        });
      })
    },
    setWarehouseUpdate(w) {
      axios.get(`/warehouses/${w.id}`).then(response=>{
       if(response.data.status){
         var item = response.data.responseData.warehouse
         this.w_id = item.id
         this.w_code = item.code
         this.w_name = item.name
         this.w_phone = item.phone
         this.w_email = item.email
         this.w_lat = item.latitude
         this.w_lon = item.longitude
         this.w_country_id = 'tr'
         this.getCities();
         this.w_city_id = item.city ? item.city.id : null
         if(this.w_city_id){
           this.w_district_id = item.district ? item.district.id : null
           this.getDistricts()
         }
         if(this.w_district_id){
           this.w_area_id = item.neighborhood ? item.neighborhood.id : null
           this.getAreas()
         }
         this.w_address = item.address
         this.w_is_active = item.is_active
         this.list = item.warehouseIntegrations
         this.w_integration_id = null
         this.w_integration_branch_id = null

         this.getIntegrationBranches();

         this.$refs["update-warehouse-modal"].show()
       }
      }).catch(error => {
        var message = error.response.data.responseData.error ? error.response.data.responseData.error[0].message : error.response.data.responseData.message;
        this.$swal.fire({
          iconHtml: '<img src="/img/error.svg" width="32" height="32">',
          position: 'top-end',
          title: message,
          showConfirmButton: false,
          timer: 2500,
          toast: true,
          customClass: {
            icon: 'swal2-no-border'
          }
        });
      })
    },
    selectFileClick(event) {
      if (event.target.files[0].size < 5 * 1024 * 1024) {
        this.selectedFile = event.target.files[0];
        this.user.logo_path = URL.createObjectURL(this.selectedFile)
      } else {
        this.$swal.fire({
          iconHtml: '<img src="/img/error.svg" width="32" height="32">',
          position: 'top-end',
          title: this.$i18n.t('Please upload a logo smaller than 5MB.'),
          showConfirmButton: false,
          timer: 3000,
          toast: true,
          customClass: {
            icon: 'swal2-no-border'
          }
        });
      }
    },

    updateForm() {
      const fd = new FormData();

      if (this.selectedFile) {
        fd.append('logo', this.selectedFile)
      }
      fd.append('name', this.user.name)
      fd.append('_method', 'put')
      fd.append('email', this.user.email ?? '')
      fd.append('phone', this.user.phone ?? '')
      fd.append('tax_number', this.user.tax_number ?? '')
      fd.append('tax_office', this.user.tax_office ?? '')
      fd.append('iban', this.user.iban ?? '')
      fd.append('fax', this.user.fax ?? '')
      fd.append('iban_bank_id', this.user.iban_bank_id ?? '')
      fd.append('currency_type_id', this.user.currency_type_id ?? '')
      fd.append('title', this.user.title ?? '')
      fd.append('default_language', this.user.default_language ?? '')
      fd.append('waiting_days', this.user.waiting_days ?? '')
      fd.append('multi_language', this.user.multi_language === true ? 1 : 0)

      axios.post('/merchants', fd).then(response => {
        if (response.data.status) {
          this.$swal.fire({
            iconHtml: '<img src="/img/success.svg" width="32" height="32">',
            position: 'top-end',
            title: this.$i18n.t('User informations saved successfully.'),
            showConfirmButton: false,
            timer: 3000,
            toast: true,
            customClass: {
              icon: 'swal2-no-border'
            }
          });

          this.getProfile();
        }
      }).catch(error => {
        this.reset();
        var message = error.response.data.responseData.error ? error.response.data.responseData.error[0].message : error.response.data.responseData.message;
        this.$swal.fire({
          iconHtml: '<img src="/img/error.svg" width="32" height="32">',
          position: 'top-end',
          title: message,
          showConfirmButton: false,
          timer: 2500,
          toast: true,
          customClass: {
            icon: 'swal2-no-border'
          }
        });
      })
    },
    reset() {
      this.user.title = this.title
      this.user.email = this.email
      this.user.phone = this.phone
      this.user.fax = this.fax
      this.user.tax_number = this.tax_number
      this.user.tax_office = this.tax_office
      this.user.iban_bank_id = this.iban_bank_id
      this.user.currency_type_id = this.currency_type_id
      this.user.iban = this.iban
      this.user.default_language = this.default_language
      this.user.multi_language = this.multi_language
      this.user.waiting_days = this.waiting_days
    },
    getBanks() {
      axios.get('/banks').then(response => {
        this.banks = [
          {value: null, text: this.$i18n.t('Choose')}
        ]
        for (let i in response.data.responseData.banks) {
          this.banks.push({
            value: response.data.responseData.banks[i].id,
            text: response.data.responseData.banks[i].name
          })
        }
      })
    },
    getCurrencyTypes() {
      this.currency = [
        {value: null, text: this.$i18n.t('Choose')}
      ]
      axios.get('currency-types').then(response => {
        for (let i in response.data.responseData.currencyTypes) {
          this.currency.push({
            value: response.data.responseData.currencyTypes[i].id,
            text: response.data.responseData.currencyTypes[i].name + '(' + response.data.responseData.currencyTypes[i].symbol + ')'
          })
        }
      })
    },
    openWarehouseModal() {
      this.w_code = null
      this.w_name = null
      this.w_phone = null
      this.w_email = null
      this.w_lat = null
      this.w_lon = null
      this.w_country_id = 'tr'
      this.w_city_id = null
      this.w_district_id = null
      this.w_area_id = null
      this.w_address = null
      this.w_integration_id = null
      this.w_integration_branch_id = null
      this.w_is_active = true
      this.list = []
      this.getCities();
      if(JSON.parse(localStorage.getItem('userData')).integrations.length>0){
        this.getIntegrationBranches()
      }
      this.$refs["add-warehouse-modal"].show()
    },
    getCities() {
      axios.get('/regions/cities').then((response) => {
        if (response.data.status) {
          this.cities = [
            {
              value: null,
              text: this.$i18n.t('Choose')
            }
          ];
          response.data.responseData.cities.forEach((city) => {
            this.cities.push({
              value: city.id,
              text: city.name
            });
          });
        }
      });
    },
    getDistricts() {
      if (this.w_city_id) {
        this.districts = []
        this.areas = [
          {
            value: null,
            text: this.$i18n.t('Choose')
          }
        ];
        this.w_district_id = null
        this.w_area_id = null
        axios.get(`/regions/districts`, {
          params: {
            city_id: this.w_city_id
          }
        }).then((response) => {
          this.districts = [
            {
              value: null,
              text: this.$i18n.t('Choose')
            }
          ];
          response.data.responseData.districts.forEach((district) => {
            this.districts.push({
              value: district.id,
              text: district.name
            });
          });
        });
      } else {
        this.districts = []
        this.areas = []
        this.w_district_id = null
        this.w_area_id = null
      }
    },
    getAreas() {
      if (this.w_district_id) {
        this.areas = []
        this.w_area_id = null
        axios.get(`/regions/neighborhoods`, {
          params: {
            district_id: this.w_district_id
          }
        }).then((response) => {
          this.areas = [
            {
              value: null,
              text: this.$i18n.t('Choose')
            }
          ];
          response.data.responseData.neighborhoods.forEach((area) => {
            this.areas.push({
              value: area.id,
              text: area.name
            });
          });
        });
      } else {
        this.areas = []
        this.w_area_id = null
      }
    },
    getIntegrationBranches() {
      axios.get('/merchants/branches-web-service').then(response => {
        if (response.data.status) {
          this.integration_branches = [
            {value: null, text: this.$i18n.t('Choose')}
          ]
          response.data.responseData.branches.forEach(item=>{
            this.integration_branches.push({
              value: item,
              text: item.name
            })
          })
        }
      }).catch(error => {
        var message = error.response.data.responseData.error ? error.response.data.responseData.error[0].message : error.response.data.responseData.message;
        this.$swal.fire({
          iconHtml: '<img src="/img/error.svg" width="32" height="32">',
          position: 'top-end',
          title: message,
          showConfirmButton: false,
          timer: 2500,
          toast: true,
          customClass: {
            icon: 'swal2-no-border'
          }
        });
      })
    },
    addWarehouse() {
      this.$refs.addWarehouseRules.validate().then(success => {
        if (success) {
          var payload = {
            code: this.w_code,
            name: this.w_name,
            email: this.w_email,
            phone: this.w_phone,
            latitude: this.w_lat,
            longitude: this.w_lon,
            address: this.w_address,
            city_id: this.w_city_id,
            district_id: this.w_district_id,
            neighborhood_id: this.w_area_id,
            is_active: this.w_is_active
          }

          if(this.list.length>0){
            payload.integrations = this.list
          }
          axios.post('/warehouses', payload).then(response => {
            if (response.data.status) {
              this.$swal.fire({
                iconHtml: '<img src="/img/success.svg" width="32" height="32">',
                position: 'top-end',
                title: this.$i18n.t('Warehouse added successfully.'),
                showConfirmButton: false,
                timer: 2500,
                toast: true,
                customClass: {
                  icon: 'swal2-no-border'
                }
              });
              this.$refs["add-warehouse-modal"].hide()
              this.tab = 5
              this.getWarehouses()
            }
          }).catch(error => {
            var message = error.response.data.responseData.error ? error.response.data.responseData.error[0].message : error.response.data.responseData.message;
            this.$swal.fire({
              iconHtml: '<img src="/img/error.svg" width="32" height="32">',
              position: 'top-end',
              title: message,
              showConfirmButton: false,
              timer: 2500,
              toast: true,
              customClass: {
                icon: 'swal2-no-border'
              }
            });
          })
        } else {
          this.$swal.fire({
            iconHtml: '<img src="/img/warning.png" width="32" height="32">',
            position: 'top-end',
            title: this.$i18n.t('Please check the fields.'),
            showConfirmButton: false,
            timer: 2500,
            toast: true,
            customClass: {
              icon: 'swal2-no-border'
            }
          });
        }
      })
    },
    updateWarehouse(){
      this.$refs.updateWarehouseRules.validate().then(success => {
        if (success) {
          var payload = {
            code: this.w_code,
            name: this.w_name,
            email: this.w_email,
            phone: this.w_phone,
            latitude: this.w_lat,
            longitude: this.w_lon,
            address: this.w_address,
            city_id: this.w_city_id,
            district_id: this.w_district_id,
            neighborhood_id: this.w_area_id,
            is_active: this.w_is_active
          }

          if(this.list.length>0){
            this.list.forEach(item=>{
              if(item.integration){
                item.integration_id = item.integration.id
              }
            })
            payload.integrations = this.list
          }else{
            payload.integrations = []
          }
          axios.put(`/warehouses/${this.w_id}`, payload).then(response => {
            if (response.data.status) {
              this.$swal.fire({
                iconHtml: '<img src="/img/success.svg" width="32" height="32">',
                position: 'top-end',
                title: this.$i18n.t('Warehouse updated successfully.'),
                showConfirmButton: false,
                timer: 2500,
                toast: true,
                customClass: {
                  icon: 'swal2-no-border'
                }
              });
              this.$refs["update-warehouse-modal"].hide()
              this.tab = 5
              this.getWarehouses()
            }
          }).catch(error => {
            var message = error.response.data.responseData.error ? error.response.data.responseData.error[0].message : error.response.data.responseData.message;
            this.$swal.fire({
              iconHtml: '<img src="/img/error.svg" width="32" height="32">',
              position: 'top-end',
              title: message,
              showConfirmButton: false,
              timer: 2500,
              toast: true,
              customClass: {
                icon: 'swal2-no-border'
              }
            });
          })
        } else {
          this.$swal.fire({
            iconHtml: '<img src="/img/warning.png" width="32" height="32">',
            position: 'top-end',
            title: this.$i18n.t('Please check the fields.'),
            showConfirmButton: false,
            timer: 2500,
            toast: true,
            customClass: {
              icon: 'swal2-no-border'
            }
          });
        }
      })
    },
    getMerchantUsers(){
      this.m_user_loading = true
      axios.get('/merchants/users',{
        params:{
          search_text: this.search_user
        }
      }).then(response=>{
        if(response.data.status){
          this.m_users = response.data.responseData
          this.m_user_loading = false
        }
      }).catch(error => {
        var message = error.response.data.responseData.error ? error.response.data.responseData.error[0].message : error.response.data.responseData.message;
        this.$swal.fire({
          iconHtml: '<img src="/img/error.svg" width="32" height="32">',
          position: 'top-end',
          title: message,
          showConfirmButton: false,
          timer: 2500,
          toast: true,
          customClass: {
            icon: 'swal2-no-border'
          }
        });
      })
    },
    openMUserOpen(){
      this.resetUser()

      this.$refs["add-user-modal"].show();
    },
    addUser(){
      this.$refs.addUserRules.validate().then(success=>{
        if(success){
          axios.post('/merchants/users',{
            is_active: this.m_is_active,
            is_admin: this.m_is_admin,
            name: this.m_name,
            surname: this.m_surname,
            phone: this.m_phone,
            email: this.m_email,
            password: this.m_password,
            password_confirmation: this.m_password_confirmation
          }).then(response=>{
            if(response.data.status){
              this.$swal.fire({
                iconHtml: '<img src="/img/success.svg" width="32" height="32">',
                position: 'top-end',
                title: this.$i18n.t('User added successfully.'),
                showConfirmButton: false,
                timer: 2500,
                toast: true,
                customClass: {
                  icon: 'swal2-no-border'
                }
              });
              this.$refs["add-user-modal"].hide();
              this.tab = 2
              this.search_user = null
              this.getMerchantUsers()
              this.resetUser()
            }
          }).catch(error => {
            var message = error.response.data.responseData.error ? error.response.data.responseData.error[0].message : error.response.data.responseData.message;
            this.$swal.fire({
              iconHtml: '<img src="/img/error.svg" width="32" height="32">',
              position: 'top-end',
              title: message,
              showConfirmButton: false,
              timer: 2500,
              toast: true,
              customClass: {
                icon: 'swal2-no-border'
              }
            });
          })
        }else{
          this.$swal.fire({
            iconHtml: '<img src="/img/warning.png" width="32" height="32">',
            position: 'top-end',
            title: this.$i18n.t('Please check the fields.'),
            showConfirmButton: false,
            timer: 2500,
            toast: true,
            customClass: {
              icon: 'swal2-no-border'
            }
          });
        }
      })
    },
    resetUser(){
      this.m_name = null
      this.m_surname = null
      this.m_phone = null
      this.m_email = null
      this.m_password = null
      this.m_password_confirmation = null
      this.m_is_active = true
      this.m_is_password = true
      this.m_is_password_confirm = true
      this.m_is_admin = false
    },
    getUserDetail(item){
      axios.get(`/merchants/users/${item.id}`).then(response=>{
        if(response.data.status){
          var user = response.data.responseData.user
          this.m_id = user.id
          this.m_name = user.name
          this.m_surname = user.surname
          this.m_phone = user.phone
          this.m_email = user.email
          this.m_is_admin = user.is_admin
          this.m_is_active = user.is_active
          this.m_is_password = true
          this.m_is_password_confirm = true
          this.m_password = null
          this.m_password_confirmation = null
          this.$refs["update-user-modal"].show()
        }
      }).catch(error => {
        var message = error.response.data.responseData.error ? error.response.data.responseData.error[0].message : error.response.data.responseData.message;
        this.$swal.fire({
          iconHtml: '<img src="/img/error.svg" width="32" height="32">',
          position: 'top-end',
          title: message,
          showConfirmButton: false,
          timer: 2500,
          toast: true,
          customClass: {
            icon: 'swal2-no-border'
          }
        });
      })
    },
    updateUser(){
      this.$refs.updateUserRules.validate().then(success=>{
        if(success){
          var payload = {
            is_active: this.m_is_active,
            is_admin: this.m_is_admin,
            name: this.m_name,
            surname: this.m_surname,
            phone: this.m_phone,
            email: this.m_email,
          }
          if(this.m_password && this.m_password_confirmation){
            payload.password = this.m_password
            payload.password_confirmation = this.m_password_confirmation
          }
          axios.put(`/merchants/users/${this.m_id}`,payload).then(response=>{
            if(response.data.status){
              this.$swal.fire({
                iconHtml: '<img src="/img/success.svg" width="32" height="32">',
                position: 'top-end',
                title: this.$i18n.t('User updated successfully.'),
                showConfirmButton: false,
                timer: 2500,
                toast: true,
                customClass: {
                  icon: 'swal2-no-border'
                }
              });
              this.$refs["update-user-modal"].hide();
              this.tab = 2
              this.getMerchantUsers()
              this.resetUser()
            }
          }).catch(error => {
            var message = error.response.data.responseData.error ? error.response.data.responseData.error[0].message : error.response.data.responseData.message;
            this.$swal.fire({
              iconHtml: '<img src="/img/error.svg" width="32" height="32">',
              position: 'top-end',
              title: message,
              showConfirmButton: false,
              timer: 2500,
              toast: true,
              customClass: {
                icon: 'swal2-no-border'
              }
            });
          })
        }else{
          this.$swal.fire({
            iconHtml: '<img src="/img/warning.png" width="32" height="32">',
            position: 'top-end',
            title: this.$i18n.t('Please check the fields.'),
            showConfirmButton: false,
            timer: 2500,
            toast: true,
            customClass: {
              icon: 'swal2-no-border'
            }
          });
        }
      })
    },
    deleteUser(){
      axios.delete(`/merchants/users/${this.m_id}`).then(response=>{
        if(response.data.status){
          this.$swal.fire({
            iconHtml: '<img src="/img/success.svg" width="32" height="32">',
            position: 'top-end',
            title: this.$i18n.t('User deleted successfully.'),
            showConfirmButton: false,
            timer: 2500,
            toast: true
          });
          this.$refs["delete-modal"].hide();
          this.tab = 2
          this.getMerchantUsers()
        }
      }).catch(error => {
        var message = error.response.data.responseData.error ? error.response.data.responseData.error[0].message : error.response.data.responseData.message;
        this.$swal.fire({
          iconHtml: '<img src="/img/error.svg" width="32" height="32">',
          position: 'top-end',
          title: message,
          showConfirmButton: false,
          timer: 2500,
          toast: true,
          customClass: {
            icon: 'swal2-no-border'
          }
        });
      })
    }
  }
};
</script>

<style scoped>
.profile-tabs {
  margin-top: 32px;
  display: flex;
  gap: 1rem;
}

.profile-tab-item {
  display: flex;
  align-items: center;
  gap: 6px;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  line-height: 17.07px;
  letter-spacing: 0.4000000059604645px;
  color: #5E5873;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  transition: .3s all;
}

.profile-tab-item.active, .profile-tab-item:hover {
  background-color: #06a5d0;
  color: #fff;
}

.profile-main-card {
  margin-top: 32px;
  padding: 24px;
}

.edit-btn {
  border: 1px solid #6D6E6F66;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  color: #323338;
  padding: 8px 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 6px;
}

.mt-30 {
  margin-top: 30px;
}

.profile-img {
  width: 100px;
  height: 100px;
  border: 1px solid #D3D3D3;
  border-radius: 5px;
  padding: 0 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
}

.profile-img img {
  width: 100%;
}

.add-img-btn {
  padding: 6px 12px;
  border-radius: 5px;
  border: 1px solid #1C1C1C;
  background-color: #1C1C1C;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  color: #f9f9f9;
  font-size: 12px;
  font-weight: 500;
  max-width: 170px;
  white-space: nowrap;
  cursor: pointer;
}

.img-btn-area {
  margin-left: 14px;
  padding: 12px 0;
}

.img-description {
  font-family: Montserrat;
  font-size: 11px;
  font-weight: 400;
  color: #6E6B7B;
  margin-top: 10px;
}

.form-control, .custom-select {
  color: #6E6B7B !important;
}

.pro-plan-card {
  margin-top: 20px;
}

.plan-card-wrapper {
  max-width: fit-content;
  width: 100%;
  box-shadow: -5px -5px 5px #ffffff, 5px 5px 13px #ebf0f1;
  border-radius: 10px;
  padding: 30px 25px;
}

.plan-card-current-plan {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  letter-spacing: 0.366667px;
  color: #1c1c1c;
  border: 1px solid #1c1c1c;
  padding: 6px 8px;
  border-radius: 5px;
}

.plan-card-price {
  font-style: normal;
  font-weight: 600;
  font-size: 50px;
  letter-spacing: 0.366667px;
  color: #000000;
  margin: 0;
  line-height: 60px;
}

.plan-card-wrapper span {
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.366667px;
  color: #000000;
}

.plan-card-list {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.366667px;
  color: #000000;
  line-height: 2;
  margin: 0;
  padding-left: 32px;
}

.plan-card-wrapper button {
  width: 100%;
  outline: none;
  border: 1px solid #1c1c1c;
  background: #1c1c1c;
  color: #fff;
  padding: 11px;
  border-radius: 10px;
  margin-top: 20px;
  transition: 0.5s;
  font-size: 14px;
  font-weight: 500;
  line-height: 17.07px;

}

.plan-card-wrapper button:hover {
  box-shadow: rgba(28, 28, 28, 0.2) 0px 8px 30px 0px;
  transition: 0.5s;
}

.plan-card-left {
  border-right: 1px solid rgba(211, 211, 211, 0.5);
  padding-right: 32px;
}

.empty-btn {
  transition: 0.5s;
  background: #f9f9f9;
  color: #82868B;
  border: 1px solid #82868B;
  outline: none;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.4px;
  padding: 10px 23px;
  margin-right: 24px;
  border-radius: 5px;
}

.empty-btn:hover {
  transition: 0.5s;
  box-shadow: rgba(28, 28, 28, 0.2) 0px 8px 30px 0px;
  background: var(--servislet-black);
  color: var(--servislet-white);
  border: 1px solid var(--servislet-black);
}

.save-btn {
  transition: 0.5s;
  min-width: 150px;
  background: var(--servislet-black) !important;
  color: var(--servislet-white) !important;
  border: 1px solid var(--servislet-black) !important;
  outline: none !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  letter-spacing: 0.4px !important;
  padding: 10px 23px;
  border-radius: 5px !important;
}

.save-btn:hover {
  transition: 0.5s;
  box-shadow: rgba(28, 28, 28, 0.2) 0px 8px 30px 0px;
}

.search-area {
  border: 1px solid #06a5d0;
  border-radius: 5px;
  background-color: #f9f9f9;
  display: flex;
  align-items: center;
  color: #06a5d0;
  min-width: 300px;
  font-size: 14px;
  height: 40px;
  padding: 0 13.6px;
}

.search-area input::placeholder {
  font-size: 14px;
  font-weight: 500;
  line-height: 16.94px;
  color: #06a5d0;
}

.search-area input, .search-area input:focus {
  border: none !important;
  background-color: transparent !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  color: #06a5d0 !important;
}

.profile-tab-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.add-btn {
  padding: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: .25rem;
}

.span-text {
  color: #323338;
  font-size: 16px;
  font-weight: 500;
  line-height: 21px;
}

.b-custom-control-lg {
  margin-top: -30px !important;
}

.form-control, .custom-select {
  background-color: #f9f9f9 !important;
}

.form-control:disabled, .custom-select:disabled {
  background-color: #f4f4f4 !important;
}

.update-btn {
  padding: 8px 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: .5rem;
  color: #f9f9f9;
  cursor: pointer;
  border-radius: 5px;
  background-color: #06a5d0;
  border: 1px solid #06a5d0;
  font-size: 14px;
}

.modal-input {
  background-color: transparent !important;
}
table thead th{
  font-weight: 500;
  font-size: 14px;
  border-bottom: none;
}
table tbody td{
  font-weight: 400;
  color: #6e6b7b;
  font-size: 14px;
}
.trash-btn {
  padding: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: .5rem;
  color: #f9f9f9;
  cursor: pointer;
  border-radius: 5px;
  background-color: #dc3545;
  border: 1px solid #dc3545;
  font-size: 12px;
}
</style>

<style>
</style>
