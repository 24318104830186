import { render, staticRenderFns } from "./TireBrandSelect.vue?vue&type=template&id=b8d022b0&scoped=true&"
import script from "./TireBrandSelect.vue?vue&type=script&lang=js&"
export * from "./TireBrandSelect.vue?vue&type=script&lang=js&"
import style0 from "./TireBrandSelect.vue?vue&type=style&index=0&id=b8d022b0&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b8d022b0",
  null
  
)

export default component.exports