import axios from '@/plugins/axios';
const state = {
  seasons: []
};

const mutations = {
  setTireSeasonData(state, data) {
    state.seasons = data;
  }
};

const actions = {
  fetchTireCategories({ commit }) {
    axios
      .get('/tire/seasons')
      .then((response) => {
        commit('setTireSeasonData', response.data.responseData);
      })
      .catch((error) => {
        console.error(error);
      });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
