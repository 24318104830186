import Vue from 'vue';
import Vuex from 'vuex';
import axios from '@/plugins/axios';

Vue.use(Vuex);

const state = {
    count: -1,
    file_count: 0
};

const mutations = {
    setCount(state, data) {
        state.count = data.count;
        state.file_count = data.file_count;
    }
};

const actions = {
    getCount({ commit }) {
        try{
            axios.get('/merchants/activities/count').then(response => {
                commit('setCount',response.data.responseData)
                return true
            });
            return true
        }catch (error){
            console.log(error)
            return false
        }
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions
};
