<template>
  <div>
    <div class="component-container mt-3">
      <div :class="show ? 'opened-border' : 'closed-border'" class="component__select cursor-pointer" @click="show = !show">
        <span v-if="Object.keys(selected).length>0" class="component__select--name">{{selected.title}}</span>
        <span v-else class="component__select--name">{{$t('Choose')}}</span>

        <div>
          <i v-if="selected && show" class="fa-solid fa-xmark mr-2" @click="selected = {}; $emit('select', null);"></i>
          <i v-if="!show" class="fa-solid fa-chevron-down cursor-pointer"></i>
          <i v-if="show" class="fa-solid fa-chevron-up cursor-pointer"></i>
        </div>
      </div>
      <div v-if="show" class="component__select-options" v-click-outside="close">
        <div
            v-for="(option,key) in options"
            class="select--option"
            :class="{ 'active': option.title === selected.title, 'border-top-first': key===0}"
            @click="selected = option; $emit('select', selected.key);
          ">
          <div>{{ option.title }}</div>
          <i class="fa-solid fa-check" v-if="selected.title === option.title"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vClickOutside from "v-click-outside";
export default {
  name: 'ExportSidebarSelect',
  data() {
    return {
      options: [
        {
          key: 0,
          title: this.$i18n.t('Selected Products')
        },
        {
          key: 1,
          title: this.$i18n.t('Products Available for Sale')
        },
        {
          key: 2,
          title: this.$i18n.t('Best Sellers')
        },
        {
          key: 3,
          title: this.$i18n.t('Products Closed for Sale')
        },
        {
          key: 4,
          title: this.$i18n.t('All Products')
        },
        {
          key: 5,
          title: this.$i18n.t('Selected Brands')
        }
      ],
      selected:{},
      show: false
    };
  },
  props:{
    selected_ids:{
      type: Array,
      default: []
    },
    selected_option:{
      type: Number,
      default: -1
    }
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  watch:{
    selected_ids(){
      if(this.selected_ids.length>0){
        this.selected = this.options[0];
        this.$emit('select', this.selected.key);
      }
    },
    selected_option(){
      if(this.selected_option === null){
        this.selected = {}
      }
    }
  },
  methods:{
    close(){
      this.show = false;
    }
  }
};
</script>

<style scoped>
.component__select {
  height: 40px;
  line-height: 38px;
  width: 100%;
  grid-template-columns: 10fr 1fr;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  background-color: #2C2C2E;
  border: 1px solid #2C2C2E;
  color: #f4f4f4;
  padding: 1.5rem 1rem;
}

.component__select--name {
  font-size: 13px;
  cursor: pointer;
}

.component__select-options {
  //position: absolute;
  z-index: 1500;
  width: 100%;
  margin: 0;
  padding: 0;
}

.select--option {
  height: 38px;
  display: flex;
  font-size: 13px;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  background-color: #2C2C2E;
  cursor: pointer;
}

.select--option:hover, .select--option.active {
  background: #06A5D01F;
  color: #06a5d0;
}

.select--option:last-child {
  border-bottom: none;
  border-radius: 0 0 5px 5px;
}

.select--option input {
  display: none;
}

.component__select i {
  font-size: 12px;
  cursor: pointer;
}
.border-top-first{
  border-top: 1px solid #404040;
}
.closed-border{
  border-radius: 5px;
}

.opened-border{
  border-radius: 5px 5px 0 0;
}
</style>
