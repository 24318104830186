<template>
  <div>
    <div
      class="component-container"
      v-click-outside="closeSelect">
      <div
        class="component__select cursor-pointer"
        :class="showOptions ? 'opened-border' : 'closed-border'"
        @click="showOptions = !showOptions">
        <span
          class="component__select--name select-text"
          v-if="!selected"
          >{{$t('Choose')}}</span
        >
        <span
          class="component__select--name"
          v-else
          >{{ selected.name }}</span
        >

        <div>
          <i
            class="fa-solid fa-xmark mr-2"
            @click="
              selected = null;
              search_text = '';
            "
            v-if="selected"></i>
          <i
            class="fa-solid fa-chevron-down"
            v-if="!showOptions"></i>
          <i
            class="fa-solid fa-chevron-up"
            v-if="showOptions"></i>
        </div>
      </div>

      <div
        class="component__select-options"
        v-if="showOptions">
        <div
          class="search-input"
          v-if="show_search_input">
          <b-form-input
            v-model="search_text"
            class="bg-transparent"
            :placeholder="$t('Search')" />
        </div>
        <div
          class="scroll-body"
          v-if="filteredOptions.length > 0">
          <div
            @click="
              selected = option;
              closeSelect();
              $emit('select', selected);
            "
            class="select--option"
            :class="selected === option ? 'font-servislet' : ''"
            v-for="option in filteredOptions">
            <div>{{ option.name }}</div>
            <div>
              <i
                class="fas fa-check"
                v-if="selected === option"></i>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="select--option no-hover">{{$t('No result.')}}..</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vClickOutside from 'v-click-outside';

export default {
  name: 'CustomSelect',
  props: {
    options: {
      type: Array,
      default: () => []
    },
    show_search_input: {
      type: Boolean,
      default: true
    }
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  computed: {
    filteredOptions() {
      return this.search_text
        ? this.options.filter((item) =>
            item.name.toUpperCase().includes(this.search_text.toUpperCase())
          )
        : this.options;
    }
  },
  data() {
    return {
      showOptions: false,
      selected: null,
      search_text: ''
    };
  },
  methods: {
    closeSelect() {
      this.showOptions = false;
    }
  }
};
</script>

<style scoped>
.component__select {
  height: 38px;
  background-color: #f9f9f9;
  border: 1px solid #e5e5e5;
  line-height: 38px;
  width: 350px;
  grid-template-columns: 10fr 1fr;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
}

.component__select--name {
  font-size: 0.8rem;
  padding: 0 25px 0 25px;
  cursor: pointer;
}

.component__select-options {
  border: 1px solid #e5e5e5;
  border-top: none;
  position: unset !important;
  z-index: 1500;
  /* max-width: 500px; */
  /* width: 350px; */
  margin: 0;
  padding: 0;
  border-radius: 0 0 0.375rem 0.375rem;
  width: 100% !important;
  padding-right: calc(var(--bs-gutter-x) * 0.5) !important;
}

.select--option {
  height: 38px;
  display: flex;
  font-size: 0.8rem;
  align-items: center;
  justify-content: space-between;
  padding: 0 25px 0 25px;
  background-color: #fff;
  border-bottom: 1px solid #f5f5f5;
  cursor: pointer;
}

.select--option:first-child {
  border-top: none;
}
.select--option:last-child {
  border-bottom: none;
  border-radius: 0 0 5px 5px;
}

.component__select i {
  margin-right: 25px;
  font-size: 12px;
  cursor: pointer;
  color: #828282;
}
.opened-border {
  border-radius: 0.375rem 0.375rem 0 0;
}
.closed-border {
  border-radius: 0.375rem;
}
.select--option:hover {
  background: rgba(6, 165, 208, 0.12);
  color: var(--servislet-blue);
}
.scroll-body {
  max-height: 180px;
  overflow-y: auto;
}
.scroll-body::-webkit-scrollbar {
  display: none !important;
}
.search-input {
  padding: 0.5rem;
  background-color: #fff;
}
.select-text {
  color: #1C1C1C;
}
.no-hover, .no-hover:hover{
  color: #1C1C1C;
  background-color: #fff;
}

@media screen and (max-width: 1000px) {
  .component__select--name {
    padding: 0;
  }
}
</style>
