// store/carBrands.js

import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const state = {
  selectedDates: []
};

const mutations = {
  setSelectedDates(state, data) {
    state.selectedDates = data;
  }
};

// const actions = {
//   fetchCities({ commit }) {
//     axios.get(API_BASE_URL+'/regions/cities', {
//         headers: {
//             Authorization: TOKEN
//         }
//     })
//       .then(response => {
//         commit('setCitiesData', response.data.responseData.cities);
//       })
//       .catch(error => {
//         console.error(error);
//       });
//   },
// };

const getters = {
  getSelectedDates(state) {
    return state.selectedDates;
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  getters
};
