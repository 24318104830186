<template>
  <div
    class="srvslt-select"
    :class="`${model ? 'selected' : 'not-selected'}`"
    v-click-outside="close">
    <div
      class="input"
      @click="show = !show"
      :class="show ? 'focused-border' : 'not-focused-border'">
      <div class="text-overflow">
        <span class="select-content"
          >{{ content_will_select
          }}<span
            v-if="selected_count > 0"
            class="selected_count"
            >{{ selected_array.length }}</span
          ></span
        >
      </div>
      <div class="icons">
        <i
          class="fa-solid fa-chevron-up"
          v-if="show" />
        <i
          class="fa-solid fa-chevron-down"
          v-else />
      </div>
    </div>
    <div
      class="select-list"
      v-if="show">
      <div>
        <div
          class="select-control"
          v-if="options.length > 1">
          <input
            type="text"
            class="select-input"
            v-model="search"
            :placeholder="$t('Search')" />
        </div>
      </div>
      <div class="select-div">
        <div v-if="select_multiple">
          <div
            class="list-content"
            v-for="item in list"
            :key="item.$index"
            @click="setItem(item)"
            v-if="list.length > 0"
            :class="
              selected_array.includes(item) ? 'font-servislet' : 'font-gray'
            ">
            <span class="text-overflow">{{ item.name }}</span>
            <i
              class="fas fa-check"
              v-if="selected_array.includes(item)"></i>
          </div>
        </div>
        <div v-else>
          <div
            class="list-content"
            v-for="item in list"
            :key="item.$index"
            @click="setItem(item)"
            v-if="list.length > 0"
            :class="
              selected_array.includes(item) || model === item
                ? 'font-servislet'
                : 'font-gray'
            ">
            <span class="text-overflow">{{ item.name }}</span>
            <i
              class="fas fa-check"
              v-if="selected_array.includes(item)"></i>
            <i
              class="fas fa-check"
              v-if="model === item"></i>
          </div>
        </div>
        <div
          class="list-content"
          v-if="list.length === 0">
          <span class="no-option">{{$t('No result.')}}..</span>
        </div>
      </div>

      <div
        class="apply"
        v-if="options.length > 0">
        <button
          class="apply-btn"
          @click="getResult()">
          {{$t('Apply')}}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import vClickOutside from 'v-click-outside';
import i18n from "@/i18n";

export default {
  name: 'UserSelect',
  directives: {
    clickOutside: vClickOutside.directive
  },
  props: {
    options: {
      type: Array,
      default: () => []
    },
    content_will_select: {
      type: String,
      default: i18n.t('Choose')
    },
    select_multiple: {
      type: Boolean,
      default: true
    },
    selected_count: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      show: false,
      search: '',
      model: '',
      selected_array: []
    };
  },
  mounted() {
    this.assignCategories();
  },
  computed: {
    list() {
      return this.search
        ? this.options.filter((item) =>
            item.name.toUpperCase().includes(this.search.toUpperCase())
          )
        : this.options;
    }
  },
  methods: {
    assignCategories() {
      if (this.$route.query.category) {
        if (this.select_multiple) {
          this.selected_array = [];
          let split_arr = this.$route.query.category.split(',');
          for (let i in split_arr) {
            this.$store.state.category.categories.forEach((value) => {
              if (value.slug === split_arr[i]) {
                this.selected_array.push(value);
              }
            });
          }
          this.getResult();
        } else {
          this.selected_array = [];
          this.$store.state.category.categories.forEach((value) => {
            if (value.slug === this.$route.query.category) {
              this.selected_array.push(value);
            }
          });
          this.getResult();
        }
      }
    },
    close() {
      this.show = false;
      this.search = '';
    },
    setItem(item) {
      if (this.select_multiple) {
        if (this.selected_array.includes(item)) {
          this.selected_array.splice(this.selected_array.indexOf(item), 1);
        } else {
          this.selected_array.push(item);
        }
        this.model = this.selected_array;
      } else {
        if (this.selected_array.includes(item)) {
          this.selected_array.splice(this.selected_array.indexOf(item), 1);
          this.model = '';
        } else {
          this.selected_array = [item.slug];
          this.model = item;
        }
      }
    },
    removeModel() {
      this.model = '';
    },
    getResult() {
      if (Object.keys(this.model).length > 0) {
        let categories = '';
        if (this.select_multiple) {
          categories = this.selected_array
            .map(function (item) {
              return item.slug;
            })
            .join(',');
        } else {
          categories = this.model.slug;
        }

        var url = new URL(window.location.href);
        url.searchParams.set('category', categories);

        if (this.model === '') {
          this.$route.query.category = '';
          url.searchParams.delete('category');
        } else {
          this.$route.query.category = categories;
        }
        url.search = decodeURIComponent(url.search);
        window.history.pushState({}, '', url);
        this.$emit('filterByCategory');
      }
      if (this.selected_array.length === 0) {
        const currentQuery = { ...this.$route.query }; // Make a copy of the current query object
        delete currentQuery.category;
        this.$router.push({ path: '/products', query: { ...currentQuery } });
        this.$emit('filterByCategory');
      }
    }
  }
};
</script>

<style scoped>
.srvslt-select {
  position: relative;
  width: 100%;
  z-index: 1;
}
.not-selected .input {
  border-radius: 5px;
  background: rgba(246, 246, 246, 0.8);
}
.input {
  background-color: white;
  padding: 15px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  position: relative;
  z-index: 2;
  height: 38px;
  width: 150px;
  font-size: 14px;
  border-radius: 5px;
}

.select-list {
  position: absolute;
  cursor: pointer;
  width: 100%;
  z-index: 3;
  max-height: 330px;
  overflow-y: auto;
  border-radius: 5px;
  background: #f6f6f6;
  box-shadow: -2px 2px 10px 0 rgba(0, 0, 0, 0.2);
  margin-top: 5px;
}

.select-list::-webkit-scrollbar {
  display: none !important;
}

.list-content {
  display: flex;
  align-items: center;
  padding: 10px;
  gap: 10px;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  justify-content: space-between;
}
.list-content .no-option {
  font-size: 12px;
}

.select-content {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  font-size: 12px;
  align-items: center;
}

.icons {
  display: flex;
  gap: 5px;
}
.icons i {
  font-size: 12px;
}

.select-control {
  padding: 10px 10px;
}

.select-input {
  width: 100%;
  height: 35px;
  border: none;
  padding: 0 10px;
  color: #777;
  font-size: 11px;
  border-radius: 4px;
  background: rgba(211, 211, 211, 0.4) !important;
}

.list-content:hover {
  background: rgba(6, 165, 208, 0.12);
  color: var(--servislet-blue);
}

@media only screen and (max-width: 575px) {
  .srvslt-select {
    max-width: 100%;
  }
}

.checked {
  background: rgba(6, 165, 208, 0.12);
  color: var(--servislet-blue);
}

.apply {
  padding: 15px 10px 10px;
  background-color: var(--background-color-sidebar);
}

.apply-btn {
  border: 1px solid var(--servislet-blue);
  width: 100%;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background-color: var(--servislet-blue);
  color: var(--background-color-sidebar);
}

.apply-btn:hover {
  background-color: var(--servislet-blue);
  color: var(--background-color-sidebar);
}
.select-div {
  max-height: 120px;
  overflow-y: auto;
}
.select-div::-webkit-scrollbar {
  display: none;
}
.focused-border {
  border: 1px solid #06a5d0;
  color: #06a5d0;
}
.not-focused-border {
  border: 1px solid rgba(119, 119, 119, 0.6);
}

.selected_count {
  background-color: rgba(6, 165, 208, 0.16);
  color: var(--servislet-blue);
  width: 10px;
  height: 10px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  margin-left: 0.5rem;
}
</style>
