<template>
  <DefaultLayout>
    <div>
      <PageBreadcrumbs :breadcrumbs="breadcrumbs" />

      <div class="text-center">
        <p class="title">{{$t('Integrations')}}</p>
        <div class="title-description">Lorem ipsum dolor sit amet consectetur nulla nisi pellentesque.</div>
      </div>

      <div class="d-flex align-items-center justify-content-center">
        <div class="filter-area">
          <i class="fa-solid fa-magnifying-glass" style="color: #77777780;"></i>
          <input type="text" class="bg-transparent" :placeholder="$t('Search by app name, keyword or description')" v-model="search">
        </div>
      </div>

      <div class="tabs">
        <div class="tab-item" :class="code === null ? 'active' : ''" @click="code = null">{{$t('All')}}</div>
        <div class="tab-item" :class="code === 'commerce' ? 'active' : ''" @click="code = 'commerce'">{{$t('E-Commerce')}}</div>
        <div class="tab-item" :class="code === 'payment' ? 'active' : ''" @click="code = 'payment'">{{$t('Payment')}} / {{$t('Virtual POS')}}</div>
        <div class="tab-item" :class="code === 'b2b' ? 'active' : ''" @click="code = 'b2b'">B2B</div>
        <div class="tab-item" :class="code === 'crm' ? 'active' : ''" @click="code = 'crm'">CRM</div>
        <div class="tab-item" :class="code === 'highlight' ? 'active' : ''" @click="code = 'highlight'">{{$t('Highlights')}}</div>
        <div class="tab-item" :class="code === '' ? 'active' : ''" @click="code = ''">{{$t('Linked Accounts')}}</div>
      </div>


      <div class="row">
        <div class="col-sm-12 col-md-6 col-lg-4" v-for="item in options" :key="item.id" @click="code !== '' ? openModal(item) : ''">
          <div class="integration-card">
            <div class="d-flex justify-content-between align-items-start">
              <div class="icon">
                <img :src="item.asset" :alt="item.name" class="w-100">
              </div>

              <div class="remove-mark cursor-pointer" v-if="code === ''" @click="selected = item; $refs['remove-modal'].show();">
                <i class="fa-solid fa-xmark"></i>
              </div>
            </div>

            <div class="text-area">
              <div class="text-title d-flex align-items-center">{{ item.name }}
                <span class="highlight" v-if="item.is_featured">{{$t('Highlight')}}</span>
                <span class="preffered" v-if="item.best">{{$t('Most Preferred')}}</span>
              </div>
              <div class="text-description">
                {{item.description}}
              </div>
            </div>
          </div>
        </div>
      </div>

      <b-modal id="integration-modal" ref="integration-modal" centered hide-header hide-footer>
        <div class="d-flex flex-column align-items-center integration-gap">
          <div class="integration-modal-title">{{ $t('Connect Your Account to {name} {platform} Platform',{name: selected.name, platform: selected.platform}) }}</div>

          <div class="d-flex align-items-center" style="gap: 30px;">
            <div class="img-area">
              <img src="../../assets/images/mobile-logo.png" />
            </div>

            <i class="fa-regular fa-arrow-right-arrow-left" style="color: #9c9c9c;"></i>

            <div class="img-area">
              <img :src="selected.asset" :alt="selected.name">
            </div>
          </div>

          <div class="branch-area">
            <span class="branch-image">
              <img :src="selected.asset" :alt="selected.name" width="16">
            </span>
            <span class="branch-name">{{selected.name}}</span>
          </div>
        </div>

        <div class="info-title">
          {{ $t('You connect your Servislet account with the {name} platform.',{name: selected.name}) }}
        </div>

        <div class="steps">
          <div class="d-flex gap-2 align-items-center">
            <i class="fa-solid fa-check step-check font-servislet"></i>
            <span class="info-text">{{$t('Access your account information and contact details.')}}</span>
          </div>

          <div class="d-flex gap-2 align-items-center">
            <i class="fa-solid fa-check step-check  font-servislet"></i>
            <span class="info-text">{{$t('Can create order and transaction notifications.')}}</span>
          </div>

          <div class="d-flex gap-2 align-items-center">
            <i class="fa-solid fa-check step-check font-servislet"></i>
            <span class="info-text">{{$t('View and update your product catalog.')}}</span>
          </div>

          <div class="d-flex gap-2 align-items-center">
            <i class="fa-solid fa-check step-check font-servislet"></i>
            <span class="info-text">{{$t('Manage your panel settings.')}}</span>
          </div>
        </div>

        <div class="contract-text">
<!--          Bu bağlantıyı kurarak, uygulamanın <span class="text-decoration-underline">Gizlilik Politikası</span> ve-->
<!--          <span class="text-decoration-underline">Hizmet Koşulları</span>’nı kabul etmiş olursunuz.-->
          {{ $t("By establishing this link, you agree to be bound by the application's Privacy Policy and You agree to the Terms of Service.") }}
        </div>

        <div class="d-flex justify-content-end">
          <div class="cancel-btn" @click="$refs['integration-modal'].hide();">{{$t('Cancel')}}</div>
          <div class="allow-btn" @click="allow()">{{$t('Allow and Upload')}}</div>
        </div>
      </b-modal>

      <b-modal id="remove-modal" ref="remove-modal" centered hide-header hide-footer>
        <div class="d-flex flex-column align-items-center integration-gap">
          <img src="../../../public/icons/warning.png" />

          <p class="remove-text text-center">
            {{$t('Are you sure you want to remove integration of {name}?',{name: selected.name})}}
          </p>
        </div>


        <div class="d-flex justify-content-center">
          <div class="remove-cancel-btn" @click="$refs['remove-modal'].hide();">{{$t('Cancel')}}</div>
          <div class="remove-allow-btn" @click="$refs['remove-modal'].hide(); options.splice(options.indexOf(selected),1)">{{$t('Approve')}}</div>
        </div>
      </b-modal>
    </div>
  </DefaultLayout>
</template>

<script>
/* eslint-disable */
import DefaultLayout from '@/layouts/default';
import PageBreadcrumbs from "@/components/PageBreadcrumbs.vue";

export default {
  components: {
    PageBreadcrumbs,
    DefaultLayout
  },
  data() {
    return {
      code: null,
      search: null,
      breadcrumbs:[
        {
          name: this.$i18n.t('Dashboard'),
          path: '/'
        },
        {
          name: this.$i18n.t('Integrations')
        }
      ],
      selected: {},
      list:[
        {
          id: 22,
          asset: 'https://pro-cdn.servislet.com/icons/continental-small.png',
          name: 'Continental ERP',
          is_featured: true,
          best: false,
          description: 'Lorem ipsum tellus quam ipsum varius adipiscing feugiat quis a feugiat tincidun',
          code: null,
          platform: ''
        },
        {
          id: 1,
          asset: 'https://pro-cdn.servislet.com/icons/lastsis.png',
          name: 'Lastsis',
          is_featured: true,
          best: false,
          description: 'Lorem ipsum tellus quam ipsum varius adipiscing feugiat quis a feugiat tincidun',
          code: 'commerce',
          platform: this.$i18n.t('E-Commerce')
        },
        {
          id: 2,
          asset: 'https://pro-cdn.servislet.com/icons/lastik-gonder.png',
          name: 'Lastik Gönder',
          is_featured: false,
          best: false,
          description: 'Lorem ipsum tellus quam ipsum varius adipiscing feugiat quis a feugiat tincidun',
          code: 'commerce',
          platform: this.$i18n.t('E-Commerce')
        },
        {
          id: 3,
          asset: 'https://pro-cdn.servislet.com/icons/akbak.png',
          name: 'Akbak',
          is_featured: false,
          best: false,
          description: 'Lorem ipsum tellus quam ipsum varius adipiscing feugiat quis a feugiat tincidun',
          code: 'b2b',
          platform: 'B2B'
        },
        {
          id: 4,
          asset: 'https://pro-cdn.servislet.com/icons/hepsiburada.png',
          name: 'Hepsiburada',
          is_featured: false,
          best: false,
          description: 'Lorem ipsum tellus quam ipsum varius adipiscing feugiat quis a feugiat tincidun',
          code: 'commerce',
          platform: this.$i18n.t('E-Commerce')
        },
        {
          id: 5,
          asset: 'https://pro-cdn.servislet.com/icons/trendyol.png',
          name: 'Trendyol',
          is_featured: true,
          best: false,
          description: 'Lorem ipsum tellus quam ipsum varius adipiscing feugiat quis a feugiat tincidun',
          code: 'commerce',
          platform: this.$i18n.t('E-Commerce')
        },
        {
          id: 6,
          asset: 'https://pro-cdn.servislet.com/icons/n11.png',
          name: 'N11',
          is_featured: false,
          best: false,
          description: 'Lorem ipsum tellus quam ipsum varius adipiscing feugiat quis a feugiat tincidun',
          code: 'commerce',
          platform: this.$i18n.t('E-Commerce')
        },
        {
          id: 7,
          asset: 'https://pro-cdn.servislet.com/icons/pazarama.png',
          name: 'Pazarama',
          is_featured: false,
          best: false,
          description: 'Lorem ipsum tellus quam ipsum varius adipiscing feugiat quis a feugiat tincidun',
          code: 'commerce',
          platform: this.$i18n.t('E-Commerce')
        },
        {
          id: 8,
          asset: 'https://pro-cdn.servislet.com/icons/param.png',
          name: 'Param',
          is_featured: false,
          best: false,
          description: 'Lorem ipsum tellus quam ipsum varius adipiscing feugiat quis a feugiat tincidun',
          code: 'payment',
          platform: this.$i18n.t('Payment')+' / '+ this.$i18n.t('Virtual POS')
        },
        {
          id: 9,
          asset: 'https://pro-cdn.servislet.com/icons/iyizico.png',
          name: 'Iyzico',
          is_featured: false,
          best: true,
          description: 'Lorem ipsum tellus quam ipsum varius adipiscing feugiat quis a feugiat tincidun',
          code: 'payment',
          platform: this.$i18n.t('Payment')+' / '+ this.$i18n.t('Virtual POS')
        },
        {
          id: 10,
          asset: 'https://pro-cdn.servislet.com/icons/logo-crm.png',
          name: 'Logo',
          is_featured: false,
          best: false,
          description: 'Lorem ipsum tellus quam ipsum varius adipiscing feugiat quis a feugiat tincidun',
          code: 'crm',
          platform: 'CRM'
        },
        {
          id: 11,
          asset: 'https://pro-cdn.servislet.com/icons/net-gsm.png',
          name: 'Net GSM',
          is_featured: false,
          best: false,
          description: 'Lorem ipsum tellus quam ipsum varius adipiscing feugiat quis a feugiat tincidun',
          code: null,
          platform: ''
        },
        {
          id: 12,
          asset: 'https://pro-cdn.servislet.com/icons/finrota.png',
          name: 'Finrota',
          is_featured: false,
          best: false,
          description: 'Lorem ipsum tellus quam ipsum varius adipiscing feugiat quis a feugiat tincidun',
          code: 'payment',
          platform: this.$i18n.t('Payment')+' / '+ this.$i18n.t('Virtual POS')
        },
        {
          id: 13,
          asset: 'https://pro-cdn.servislet.com/icons/ziraat.png',
          name: 'Ziraat POS',
          is_featured: false,
          best: false,
          description: 'Lorem ipsum tellus quam ipsum varius adipiscing feugiat quis a feugiat tincidun',
          code: 'payment',
          platform: this.$i18n.t('Payment')+' / '+ this.$i18n.t('Virtual POS')
        },
        {
          id: 14,
          asset: 'https://pro-cdn.servislet.com/icons/ykb.png',
          name: 'Yapıkredi POS',
          is_featured: false,
          best: false,
          description: 'Lorem ipsum tellus quam ipsum varius adipiscing feugiat quis a feugiat tincidun',
          code: 'payment',
          platform: this.$i18n.t('Payment')+' / '+ this.$i18n.t('Virtual POS')
        },
        {
          id: 15,
          asset: 'https://pro-cdn.servislet.com/icons/garanti.png',
          name: 'Garanti POS',
          is_featured: false,
          best: false,
          description: 'Lorem ipsum tellus quam ipsum varius adipiscing feugiat quis a feugiat tincidun',
          code: 'payment',
          platform: this.$i18n.t('Payment')+' / '+ this.$i18n.t('Virtual POS')
        },
        {
          id: 16,
          asset: 'https://pro-cdn.servislet.com/icons/garanti-pay.png',
          name: 'GarantiPay',
          is_featured: false,
          best: false,
          description: 'Lorem ipsum tellus quam ipsum varius adipiscing feugiat quis a feugiat tincidun',
          code: 'payment',
          platform: this.$i18n.t('Payment')+' / '+ this.$i18n.t('Virtual POS')
        },
        {
          id: 17,
          asset: 'https://pro-cdn.servislet.com/icons/is-bank.png',
          name: 'İşbankası POS',
          is_featured: false,
          best: false,
          description: 'Lorem ipsum tellus quam ipsum varius adipiscing feugiat quis a feugiat tincidun',
          code: 'payment',
          platform: this.$i18n.t('Payment')+' / '+ this.$i18n.t('Virtual POS')
        },
        {
          id: 18,
          asset: 'https://pro-cdn.servislet.com/icons/akbank.png',
          name: 'Akbank POS',
          is_featured: false,
          best: false,
          description: 'Lorem ipsum tellus quam ipsum varius adipiscing feugiat quis a feugiat tincidun',
          code: 'payment',
          platform: this.$i18n.t('Payment')+' / '+ this.$i18n.t('Virtual POS')
        },
        {
          id: 19,
          asset: 'https://pro-cdn.servislet.com/icons/parampos.png',
          name: 'Parampos',
          is_featured: false,
          best: false,
          description: 'Lorem ipsum tellus quam ipsum varius adipiscing feugiat quis a feugiat tincidun',
          code: 'payment',
          platform: this.$i18n.t('Payment')+' / '+ this.$i18n.t('Virtual POS')
        },
        {
          id: 20,
          asset: 'https://pro-cdn.servislet.com/icons/netahsilat.png',
          name: 'Netahsilat',
          is_featured: false,
          best: false,
          description: 'Lorem ipsum tellus quam ipsum varius adipiscing feugiat quis a feugiat tincidun',
          code: 'payment',
          platform: this.$i18n.t('Payment')+' / '+ this.$i18n.t('Virtual POS')
        },
        {
          id: 21,
          asset: 'https://pro-cdn.servislet.com/icons/paytr.png',
          name: 'PayTR',
          is_featured: false,
          best: false,
          description: 'Lorem ipsum tellus quam ipsum varius adipiscing feugiat quis a feugiat tincidun',
          code: 'payment',
          platform: this.$i18n.t('Payment')+' / '+ this.$i18n.t('Virtual POS')
        },
      ]
    }
  },
  computed:{
    options (){
      if(this.code === 'highlight'){
        var list = this.list.filter((item) => item.is_featured === true )
        return this.search ? list.filter((item) => item.name.toLocaleUpperCase().includes(this.search.toLocaleUpperCase()) || item.description.toLocaleUpperCase().includes(this.search.toLocaleUpperCase()) ) : list;

      }else{
        return this.search ? this.filtered.filter((item) => item.name.toLocaleUpperCase().includes(this.search.toLocaleUpperCase()) || item.description.toLocaleUpperCase().includes(this.search.toLocaleUpperCase()) ) : this.filtered;
      }
    },
    filtered(){
      return this.code ? this.list.filter((item) => item.code === this.code ) : this.list;
    }
  },
  mounted() {
  },
  methods: {
    openModal(item){
      this.selected = item;
      this.$refs['integration-modal'].show();
    },
    allow(){
      this.$refs['integration-modal'].hide();
      this.$swal.fire({
        iconHtml: '<img src="/img/success.svg" width="32" height="32">',
        position: 'top-end',
        title: this.$i18n.t('Added successfully.'),
        showConfirmButton: false,
        timer: 2500,
        toast: true,
        customClass: {
          icon: 'swal2-no-border'
        }
      });
    }
  }
};
</script>

<style scoped>
.row {
  margin-top: 40px;
}

.integration-card {
  margin-bottom: 22px;
  padding: 24px 28px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-height: 200px;
  border: 1px solid transparent;
  cursor: pointer;
  transition: .3s all;
  border-radius: 10px;
  box-shadow: 5px 5px 13px 0px #EBF0F1,-5px -5px 5px 0px #FFFFFF;

}
.integration-card:hover{
  border: 1px solid #06a5d0;
}

.integration-card .icon {
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background-color: #fff;
}

.integration-card .text-area .text-title {
  font-family: Montserrat;
  font-weight: 600;
  font-size: 18px;
  letter-spacing: 0.37px;
  color: #000;
}

.integration-card .text-area .text-description {
  font-family: Montserrat;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.37px;
  color: #777;
  margin-top: 10px;
}

.integration-card .button {
  background-color: #1C1C1C;
  color: #fff;
  border-radius: 5px;
  font-family: Montserrat;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0px;
  text-align: center;
  padding: 11px;
  cursor: pointer;
}

.integration-card .button.active {
  background-color: #06a5d0;
}

.integration-modal-title {
  font-family: Montserrat;
  font-weight: 800;
  font-size: 18px;
  line-height: 160%;
  letter-spacing: 0%;
  text-align: center;
  margin-top: 24px;
  color: #1C1C1C;
}

.branch-area {
  border: 1px solid #DEDEDE;
  background-color: #D3D3D34D;
  padding: 2px 10px 2px 3px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  gap: .5rem;
}

.branch-area .branch-image {
  background-color: #FFFFFF;
  border-radius: 100%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.branch-area .branch-name {
  font-weight: 400;
  font-size: 14px;
  line-height: 163%;
  color: #000000B5;
}

.info-title {
  font-family: Montserrat;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #1C1C1C;
  margin-top: 40px;
}

.step-check {
  font-size: 24px;
}

.info-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #5B5B5B;
}
.contract-text{
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #757575;
  margin-top: 24px;
}
.integration-gap {
  gap: 32px;
}

.steps {
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.cancel-btn,.allow-btn{
  padding: 16px 40px;
  border-radius: 5px;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  margin-top: 30px;
  cursor: pointer;
}
.allow-btn {
  background-color: #06a5d0;
  border: 1px solid #06a5d0;
  color: #fff;
}
.cancel-btn {
  background-color: #fff;
  border: 1px solid transparent;
  color: #757575;
}
.title{
  font-family: Inter;
  font-weight: 500;
  font-size: 22px;
  line-height: 100%;
  letter-spacing: 0px;
  color: #323338;
}
.title-description{
  font-family: Inter;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0%;
  vertical-align: middle;
  color: #1C1C1C;
  margin-top: 8px;
}
.filter-area{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 22px;
  gap: 12px;
  border: 1px solid #D3D3D3;
  border-radius: 100px;
  padding: 12px 17px;
  min-width: 550px;
}
.filter-area input, .filter-area input:focus-within, .filter-area input:focus, .filter-area input:focus-visible {
  border: none !important;
  width: 100%;
}
.filter-area input::placeholder{
  font-family: Inter;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0%;
  vertical-align: middle;
  color: #777;
}
.tabs{
  display: flex;
  align-items: baseline;
  justify-content: center;
  margin-top: 40px;
}
.tab-item {
  font-family: Inter;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
  text-align: center;
  color: #1C1C1C;
  padding: 16px 30px;
  cursor: pointer;
  border-bottom: 4px solid #D3D3D380;
  transition: .3s all;
  white-space: nowrap;
}
.tab-item.active, .tab-item:hover {
  color: #06a5d0;
  border-bottom: 4px solid #06a5d0;
}
.highlight{
  background-color: #07C7E31F;
  color: #06a5d0;
  font-family: Montserrat;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  padding: 5px 10px;
  border-radius: 100px;
  margin-left: 10px;
}
.preffered{
  background-color: #28C76F1F;
  color: #28C76F;
  font-family: Montserrat;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  padding: 5px 10px;
  border-radius: 100px;
  margin-left: 10px;
}
.img-area{
  width: 50px;
  height: 50px;
  border: 1px solid #D3D3D3;
  padding: 4px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.img-area img{
  width: 42px;
  height: auto;
}
.remove-mark{
  width: 22px;
  height: 22px;
  border-radius: 100px;
  background-color: #D3D3D34D;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #777;
}
.remove-text {
  font-family: Montserrat;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #000;
  margin-bottom: 0;
}
.remove-cancel-btn, .remove-allow-btn{
  padding: 11px 24px;
  border-radius: 5px;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  margin-top: 24px;
  cursor: pointer;
  text-align: center;
}
.remove-cancel-btn{
  border: 1px solid #777777;
  color: #777;
  background-color: #fff;
  width: 108px;
}
.remove-allow-btn{
  border: 1px solid #1c1c1c;
  color: #fff;
  background-color: #1c1c1c;
  margin-left: 22px;
  width: 150px;
}
@media screen and (max-width: 900px) {
  .tab-item {
    padding: 16px 10px;
  }
}
</style>

<style>
#integration-modal___BV_modal_body_ {
  padding: 30px 40px;
}
#integration-modal___BV_modal_content_ {
  min-width: 515px;
}
#remove-modal___BV_modal_body_{
  padding: 45px;
}
</style>
