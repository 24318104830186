import Vue from 'vue';
import Vuex from 'vuex';
import axios from '@/plugins/axios';

Vue.use(Vuex);

const state = {
  integrations: []
};

const mutations = {
  setIntegrationChannels(state, data) {
    state.integrations = data;
  }
};

const actions = {
  fetchIntegrationChannels({ commit }) {
    axios
      .get('/integrations', {})
      .then((response) => {
        commit(
          'setIntegrationChannels',
          response.data.responseData.integrations
        );
      })
      .catch((error) => {
        console.error(error);
      });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
