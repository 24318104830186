<template>
  <Default>
    <div>
      <PageBreadcrumbs :breadcrumbs="breadcrumbs" />
      <div class="row">
        <div class="col-lg-12">
          <div class="product-detail-left-wrapper">
            <div class="products-double-flex">
              <div class="products-double-flex-left">
                <p class="product-add-page-title">{{ $t('Add Product') }}</p>
              </div>
            </div>
            <ValidationObserver ref="productAddRules">
              <div id="product-add-wrapper">
                <p class="page-title my-2">{{ $t('Product Information') }}</p>
                <div class="row">
                  <div class="col-lg-4">
                    <b-form-group id="productImg-group">
                      <label for="productImg">{{ $t('Product Image') }}</label>
                      <input
                        type="file"
                        ref="file"
                        accept="image/*"
                        style="display: none"
                        @change="selectImage" />
                      <div class="cover-image">
                        <div
                          class="edit-icon"
                          @click="$refs.file.click()"
                          v-if="!imageFile">
                          <a style="color: #777; width: 50px"
                            ><i class="fa-solid fa-plus"></i
                          ></a>
                        </div>
                        <div
                          class="remove-icon"
                          v-else
                          @click="removeImage">
                          <a style="color: #f9f9f9; width: 50px"
                            ><i class="fa-solid fa-xmark"></i
                          ></a>
                        </div>
                        <div class="text-center">
                          <span class="drop-zone-prompt"
                            >({{
                              $t('Drag files or click to upload them.')
                            }})</span
                          >
                        </div>

                        <a @click="$refs.file.click()">
                          <b-img-lazy
                            fluid
                            :src.sync="form.asset_path"
                            style="width: 100%" />
                        </a>
                      </div>
                    </b-form-group>

                    <div class="mt-3 d-flex justify-content-between">
                      <b-form-group id="productImg-group">
                        <input
                          type="file"
                          ref="file2"
                          accept="image/*"
                          style="display: none"
                          @change="selectImage2" />
                        <div class="cover-image max-100">
                          <div
                            class="edit-icon"
                            @click="$refs.file2.click()"
                            v-if="!imageFile2">
                            <a style="color: #777; width: 50px"
                              ><i class="fa-solid fa-plus"></i
                            ></a>
                          </div>
                          <div
                            class="remove-icon"
                            v-else
                            @click="removeImage2">
                            <a style="color: #f9f9f9; width: 50px"
                              ><i class="fa-solid fa-xmark"></i
                            ></a>
                          </div>

                          <a @click="$refs.file2.click()">
                            <b-img-lazy
                              fluid
                              :src.sync="form.asset2"
                              style="width: 100px; height: auto" />
                          </a>
                        </div>
                      </b-form-group>

                      <b-form-group id="productImg-group">
                        <input
                          type="file"
                          ref="file3"
                          accept="image/*"
                          style="display: none"
                          @change="selectImage3" />
                        <div class="cover-image max-100">
                          <div
                            class="edit-icon"
                            @click="$refs.file3.click()"
                            v-if="!imageFile3">
                            <a style="color: #777; width: 50px"
                              ><i class="fa-solid fa-plus"></i
                            ></a>
                          </div>
                          <div
                            class="remove-icon"
                            v-else
                            @click="removeImage3">
                            <a style="color: #f9f9f9; width: 50px"
                              ><i class="fa-solid fa-xmark"></i
                            ></a>
                          </div>

                          <a @click="$refs.file3.click()">
                            <b-img-lazy
                              fluid
                              :src.sync="form.asset3"
                              style="width: 100px; height: auto" />
                          </a>
                        </div>
                      </b-form-group>

                      <b-form-group id="productImg-group">
                        <input
                          type="file"
                          ref="file4"
                          accept="image/*"
                          style="display: none"
                          @change="selectImage4" />
                        <div class="cover-image max-100">
                          <div
                            class="edit-icon"
                            @click="$refs.file4.click()"
                            v-if="!imageFile4">
                            <a style="color: #777; width: 50px"
                              ><i class="fa-solid fa-plus"></i
                            ></a>
                          </div>
                          <div
                            class="remove-icon"
                            v-else
                            @click="removeImage4">
                            <a style="color: #f9f9f9; width: 50px"
                              ><i class="fa-solid fa-xmark"></i
                            ></a>
                          </div>

                          <a @click="$refs.file4.click()">
                            <b-img-lazy
                              fluid
                              :src.sync="form.asset4"
                              style="width: 100px; height: auto" />
                          </a>
                        </div>
                      </b-form-group>
                    </div>
                  </div>
                  <div class="col-lg-8">
                    <div class="row">
                      <div class="col-lg-6">
                        <div class="row">
                          <b-col sm="12">
                            <ValidationProvider
                              :name="$t('Name')"
                              rules="required|max:120"
                              #default="{ errors }">
                              <b-form-group>
                                <label for="name">{{ $t('Name') }}</label>
                                <b-form-input
                                  v-model="form.name"
                                  type="text"
                                  @input="callSlugify()"
                                  class="bg-transparent"></b-form-input>
                                <label
                                  for="name"
                                  class="text-danger"
                                  v-if="errors.length > 0"
                                  ><small>* </small>{{ errors[0] }}.</label
                                >
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>

                          <b-col sm="12">
                            <b-form-group id="brand-group">
                              <label for="brand">{{ $t('Brand') }}</label>
                              <b-form-select
                                class="bg-transparent"
                                v-model="form.tire_brand_id"
                                @change="getWidths(form.tire_brand_id.slug)">
                                <b-form-select-option
                                  :value="item"
                                  v-for="item in $store.state.tireBrands.brands"
                                  :key="item.$index">
                                  {{ item.name }}
                                </b-form-select-option>
                              </b-form-select>
                              <!--                              <custom-select :options="$store.state.tireBrands.brands"-->
                              <!--                                             @select="form.tire_brand_id =  $event.id; form.tire_brand_slug = $event.slug; getWidths($event.slug)"/>-->
                            </b-form-group>
                          </b-col>

                          <b-col sm="12">
                            <b-form-group id="brand-group">
                              <label for="brand">{{ $t('Season') }}</label>
                              <b-form-select
                                class="bg-transparent"
                                v-model="form.tire_season_id">
                                <b-form-select-option
                                  :value="item.id"
                                  v-for="item in $store.state.tireSeason
                                    .seasons"
                                  :key="item.$index">
                                  {{ item.name }}
                                </b-form-select-option>
                              </b-form-select>
                              <!--                              <custom-select :options="$store.state.tireSeason.seasons" @select="form.tire_season_id =  $event.id"/>-->
                            </b-form-group>
                          </b-col>

                          <b-col sm="12">
                            <ValidationProvider
                              :name="$t('Fuel Label')"
                              rules="max:10"
                              #default="{ errors }">
                              <b-form-group>
                                <label for="fuel_label">{{
                                  $t('Fuel Label')
                                }}</label>
                                <b-form-input
                                  v-model="form.fuel_label"
                                  type="text"
                                  class="bg-transparent" />
                                <label
                                  for="fuel_label"
                                  class="text-danger"
                                  v-if="errors.length > 0"
                                  ><small>* </small>{{ errors[0] }}.</label
                                >
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>

                          <b-col sm="12">
                            <ValidationProvider
                              :name="$t('Wet Grip')"
                              rules="max:10"
                              #default="{ errors }">
                              <b-form-group>
                                <label for="wet_grip">{{
                                  $t('Wet Grip')
                                }}</label>
                                <b-form-input
                                  v-model="form.wet_grip"
                                  type="text"
                                  class="bg-transparent" />
                                <label
                                  for="wet_grip"
                                  class="text-danger"
                                  v-if="errors.length > 0"
                                  ><small>* </small>{{ errors[0] }}.</label
                                >
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>

                          <b-col sm="12">
                            <ValidationProvider
                              :name="$t('Speed Symbol')"
                              rules="max:3"
                              #default="{ errors }">
                              <b-form-group>
                                <label for="speed_symbol">{{
                                  $t('Speed Symbol')
                                }}</label>
                                <b-form-input
                                  v-model="form.speed_symbol"
                                  type="text"
                                  class="bg-transparent" />
                                <label
                                  for="speed_symbol"
                                  class="text-danger"
                                  v-if="errors.length > 0"
                                  ><small>* </small>{{ errors[0] }}.</label
                                >
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>

                          <b-col sm="12">
                            <ValidationProvider
                              name="Slug"
                              rules="required|max:120"
                              #default="{ errors }">
                              <b-form-group>
                                <label for="slug">{{ $t('Slug') }}</label>
                                <b-form-input
                                  v-model="form.slug"
                                  type="text"
                                  class="bg-transparent" />
                                <label
                                  for="cai"
                                  class="text-danger"
                                  v-if="errors.length > 0"
                                  ><small>* </small>{{ errors[0] }}.</label
                                >
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                        </div>
                      </div>

                      <div class="col-lg-6">
                        <div class="row">
                          <b-col sm="12">
                            <ValidationProvider
                              name="CAI"
                              rules="max:60"
                              #default="{ errors }">
                              <b-form-group>
                                <label for="cai">CAI</label>
                                <b-form-input
                                  v-model="form.cai"
                                  type="text"
                                  class="bg-transparent" />
                                <label
                                  for="cai"
                                  class="text-danger"
                                  v-if="errors.length > 0"
                                  ><small>* </small>{{ errors[0] }}.</label
                                >
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>

                          <b-col sm="12">
                            <b-form-group id="brand-group">
                              <label for="category">{{ $t('Category') }}</label>

                              <b-form-select
                                v-model="form.tire_category_id"
                                class="bg-transparent">
                                <b-form-select-option
                                  :value="item.id"
                                  v-for="item in $store.state.category
                                    .categories"
                                  :key="item.$index">
                                  {{ item.name }}
                                </b-form-select-option>
                              </b-form-select>
                              <!--                              <custom-select :options="$store.state.category.categories"-->
                              <!--                                             @select="form.tire_category_id =  $event.id"/>-->
                            </b-form-group>
                          </b-col>

                          <b-col
                            sm="12"
                            md="4">
                            <b-form-group id="brand-group">
                              <label for="width">{{ $t('Width') }}</label>
                              <b-form-input
                                v-model="form.width"
                                class="bg-transparent"
                                type="number"></b-form-input>
                              <!-- <b-form-select v-model="form.width" class="bg-transparent" @change="getHeights">
                                <b-form-select-option :value="item" v-for="item in $store.state.tireWidths.widths" :key="item.$index">
                                  {{item}}
                                </b-form-select-option>
                              </b-form-select> -->
                              <!--  <custom-select :options="widths" @select="form.width =  $event.id; getHeights()"/>-->
                            </b-form-group>
                          </b-col>

                          <b-col
                            sm="12"
                            md="4">
                            <b-form-group id="brand-group">
                              <label for="height">{{ $t('Height') }}</label>
                              <b-form-input
                                v-model="form.height"
                                class="bg-transparent"
                                type="number"></b-form-input>
                              <!-- <b-form-select v-model="form.height" class="bg-transparent" @change="getRadiuses">
                                <b-form-select-option :value="item" v-for="item in $store.state.tireHeights.heights" :key="item.$index">
                                  {{item}}
                                </b-form-select-option>
                              </b-form-select> -->
                              <!--  <custom-select :options="heights" @select="form.height =  $event.id; getRadiuses()"/>-->
                            </b-form-group>
                          </b-col>

                          <b-col
                            sm="12"
                            md="4">
                            <b-form-group id="brand-group">
                              <label for="radius">{{ $t('Radius') }}</label>
                              <b-form-input
                                v-model="form.radius"
                                class="bg-transparent"
                                type="number"></b-form-input>
                              <!-- <b-form-select v-model="form.radius" class="bg-transparent">
                                <b-form-select-option :value="item" v-for="item in $store.state.tireRadiuses.radiuses" :key="item.$index">
                                  {{item}}
                                </b-form-select-option>
                              </b-form-select> -->
                              <!--  <custom-select :options="radiuses" @select="form.radius =  $event.id;"/>-->
                            </b-form-group>
                          </b-col>

                          <b-col sm="12">
                            <b-form-group>
                              <label for="radial_structure">{{
                                $t('Radial Structure')
                              }}</label>
                              <b-form-input
                                v-model="form.radial_structure"
                                type="text"
                                class="bg-transparent" />
                            </b-form-group>
                          </b-col>

                          <b-col sm="12">
                            <b-form-group>
                              <label for="load_index">{{
                                $t('Load Index')
                              }}</label>
                              <input
                                class="form-control bg-transparent"
                                v-model="form.load_index"
                                type="number"
                                min="0"
                                oninput="validity.valid||(value='');" />
                            </b-form-group>
                          </b-col>

                          <b-col sm="12">
                            <ValidationProvider
                              :name="$t('Noise Label')"
                              rules="max:10"
                              #default="{ errors }">
                              <b-form-group>
                                <label for="noise_label">{{
                                  $t('Noise Label')
                                }}</label>
                                <b-form-input
                                  v-model="form.noise_label"
                                  type="text"
                                  class="bg-transparent" />
                                <label
                                  for="noise_label"
                                  class="text-danger"
                                  v-if="errors.length > 0"
                                  ><small>* </small>{{ errors[0] }}.</label
                                >
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>

                          <b-col
                            sm="12"
                            md="6">
                            <b-form-group>
                              <label for="is_active">Run Flat</label>
                              <b-form-checkbox
                                id="checkbox-2"
                                name="checkbox-2"
                                :value="1"
                                :unchecked-value="0"
                                switch
                                v-model="form.run_flat" />
                            </b-form-group>
                          </b-col>

                          <b-col
                            sm="12"
                            md="6">
                            <b-form-group>
                              <label for="is_active">{{ $t('Status') }}</label>
                              <b-form-checkbox
                                :id="'checkbox-1'"
                                name="checkbox-1"
                                :value="1"
                                :unchecked-value="0"
                                switch
                                v-model="form.is_active" />
                            </b-form-group>
                          </b-col>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div style="display: flex; justify-content: flex-end">
                      <button
                        class="save-product-btn"
                        @click="saveProduct()">
                        {{ $t('Save') }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </ValidationObserver>
          </div>
        </div>
        <!--        <div class="col-lg-3">-->
        <!--          <div class="product-detail-right-wrapper">-->
        <!--            <b-form-group id="channel-group" label="Satış Kanalı:" label-for="channel">-->
        <!--              <b-form-input-->
        <!--                  id="channel"-->
        <!--                  type="text"-->
        <!--                  placeholder="Satış Kanalı"-->
        <!--                  disabled-->
        <!--              ></b-form-input>-->
        <!--            </b-form-group>-->

        <!--            <div class="product-detail-right-checkboxes" v-for="item in 3" :key="item">-->
        <!--              <span>Stok Aç / Kapat</span>-->

        <!--              <b-form-checkbox-->
        <!--                  id="product-detail-checkbox"-->
        <!--                  value="open_stock"-->
        <!--                  unchecked-value="close_stock"-->
        <!--                  switch-->
        <!--              ></b-form-checkbox>-->
        <!--            </div>-->

        <!--            <b-button>Kaydet</b-button>-->
        <!--          </div>-->
        <!--        </div>-->
      </div>
    </div>
  </Default>
</template>

<script>
/* eslint-disable */
import PageBreadcrumbs from '../../components/PageBreadcrumbs.vue';
import StarRating from '../../components/StarRating.vue';
import Default from '@/layouts/default';

export default {
  components: {
    PageBreadcrumbs,
    StarRating,
    Default
  },
  data() {
    return {
      breadcrumbs: [
        {
          name: this.$i18n.t('Products'),
          path: '/products'
        },
        {
          name: this.$i18n.t('Tire'),
          path: '/products'
        },
        {
          name: this.$i18n.t('Add New')
        }
      ],
      form: {
        asset_path: require('@/assets/images/no_image.png'),
        asset2: require('@/assets/images/no_image.png'),
        asset3: require('@/assets/images/no_image.png'),
        asset4: require('@/assets/images/no_image.png')
      },
      imageFile: null,
      imageFile2: null,
      imageFile3: null,
      imageFile4: null,
      selected: null,
      options: [
        { value: null, text: 'Please select an option' },
        { value: 'a', text: 'This is First option' },
        { value: 'b', text: 'Selected Option' },
        { value: { C: '3PO' }, text: 'This is an option with object value' },
        { value: 'd', text: 'This one is disabled', disabled: true }
      ],
      widths: [],
      heights: [],
      radiuses: [],
      assets: []
    };
  },
  mounted() {
    this.$store.dispatch('tireBrands/fetchTireBrands');
    this.$store.dispatch('category/fetchTireCategories');
    this.$store.dispatch('tireSeason/fetchTireCategories');
  },
  methods: {
    createSlug() {
      this.form.slug = window.helper.slugify(this.form.slug);
    },
    callSlugify() {
      this.form.slug = this.form.name;
      this.createSlug();
    },
    getWidths(slug) {
      this.widths = [];
      this.$store.dispatch('tireWidths/fetchTireWidths', slug);
      //   .then(() => {
      // this.$store.state.tireWidths.widths.forEach(value => {
      //   this.widths.push({
      //     id: value,
      //     name: value.toString()
      //   })
      // })
      // })
    },
    getHeights() {
      this.heights = [];
      this.$store.dispatch('tireHeights/fetchTireHeights', {
        brand_slugs: this.form.tire_brand_id.slug,
        width: this.form.width
      });
      //   .then(() => {
      // this.$store.state.tireHeights.heights.forEach(value => {
      //   this.heights.push({
      //     id: value,
      //     name: value.toString()
      //   })
      // })
      // })
    },
    getRadiuses() {
      this.radiuses = [];
      this.$store.dispatch('tireRadiuses/fetchTireRadiuses', {
        brand_slugs: this.form.tire_brand_id.slug,
        width: this.form.width,
        height: this.form.height
      });
      //     .then(() => {
      //   this.$store.state.tireRadiuses.radiuses.forEach(value => {
      //     this.radiuses.push({
      //       id: value,
      //       name: value.toString()
      //     })
      //   })
      // })
    },
    selectImage(e) {
      this.imageFile = e.target.files[0];
      this.form.asset_path = URL.createObjectURL(this.imageFile);
    },
    selectImage2(e) {
      this.imageFile2 = e.target.files[0];
      this.form.asset2 = URL.createObjectURL(this.imageFile2);
      this.assets[0] = {
        path: this.imageFile2
      };
    },
    selectImage3(e) {
      this.imageFile3 = e.target.files[0];
      this.form.asset3 = URL.createObjectURL(this.imageFile3);
      this.assets[1] = {
        path: this.imageFile3
      };
    },
    selectImage4(e) {
      this.imageFile4 = e.target.files[0];
      this.form.asset4 = URL.createObjectURL(this.imageFile4);
      this.assets[2] = {
        path: this.imageFile4
      };
    },
    removeImage() {
      this.$refs.file = '';
      this.form.asset_path = require('@/assets/images/no_image.png');
    },
    removeImage2() {
      this.$refs.file2 = '';
      this.form.asset2 = require('@/assets/images/no_image.png');
      this.assets[0] = null;
    },
    removeImage3() {
      this.$refs.file3 = '';
      this.form.asset3 = require('@/assets/images/no_image.png');
      this.assets[1] = null;
    },
    removeImage4() {
      this.$refs.file = '';
      this.form.asset4 = require('@/assets/images/no_image.png');
      this.assets[2] = null;
    },
    saveProduct() {
      this.$refs.productAddRules.validate().then((success) => {
        if (success) {
          const fd = new FormData();
          fd.append('is_active', this.form.is_active);
          fd.append(
            'tire_category_id',
            this.form.tire_category_id ? this.form.tire_category_id : ''
          );
          fd.append(
            'tire_brand_id',
            this.form.tire_brand_id ? this.form.tire_brand_id.id : ''
          );
          fd.append(
            'tire_season_id',
            this.form.tire_season_id ? this.form.tire_season_id : ''
          );
          fd.append('sku', this.form.cai ? this.form.cai : '');
          fd.append('name', this.form.name);
          fd.append('slug', this.form.slug);
          fd.append('width', this.form.width ? this.form.width : '');
          fd.append('height', this.form.height ? this.form.height : '');
          fd.append('radius', this.form.radius ? this.form.radius : '');
          fd.append(
            'radial_structure',
            this.form.radial_structure ? this.form.radial_structure : ''
          );
          fd.append(
            'speed',
            this.form.speed_symbol ? this.form.speed_symbol : ''
          );
          fd.append(
            'load_index',
            this.form.load_index ? this.form.load_index : ''
          );
          fd.append('run_flat', this.form.run_flat);
          if (this.imageFile) {
            fd.append('asset_path', this.imageFile);
          }
          fd.append(
            'fuel_label',
            this.form.fuel_label ? this.form.fuel_label : ''
          );
          fd.append(
            'wet_grip_label',
            this.form.wet_grip_label ? this.form.wet_grip_label : ''
          );
          fd.append(
            'noise_label',
            this.form.noise_label ? this.form.noise_label : ''
          );

          this.assets.forEach((item, index) => {
            if (item) {
              fd.append(`assets[${index}]`, item);
            }
          });
          this.$axios
            .post('/tire/products', fd)
            .then((response) => {
              if (response.data.status) {
                this.$swal.fire({
                  iconHtml: '<img src="/img/success.svg" width="32" height="32">',
                  position: 'top-end',
                  title: this.$i18n.t('Product successfully added.'),
                  showConfirmButton: false,
                  timer: 2500,
                  toast: true,
                  customClass: {
                    icon: 'swal2-no-border'
                  }
                });
                this.form = {
                  asset_path: require('@/assets/images/no_image.png'),
                  asset2: require('@/assets/images/no_image.png'),
                  asset3: require('@/assets/images/no_image.png'),
                  asset4: require('@/assets/images/no_image.png')
                };
                this.assets = [];
              }
            })
            .catch((error) => {
              var message = error.response.data.responseData.error
                ? error.response.data.responseData.error[0].message
                : error.response.data.responseData.message;
              this.$swal.fire({
                iconHtml: '<img src="/img/error.svg" width="32" height="32">',
                position: 'top-end',
                title: message,
                showConfirmButton: false,
                timer: 2500,
                toast: true,
                customClass: {
                  icon: 'swal2-no-border'
                }
              });
            });
        } else {
          this.$swal.fire({
            iconHtml: '<img src="/img/error.svg" width="32" height="32">',
            position: 'top-end',
            title: this.$i18n.t('Please fill the required fields.'),
            showConfirmButton: false,
            timer: 2500,
            toast: true,
            customClass: {
              icon: 'swal2-no-border'
            }
          });
        }
      });
    }
  }
};
</script>

<style scoped>
.product-add-page-title {
  color: #1c1c1c;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
</style>
<style>
#brand-group .component__select {
  height: 38px;
  background-color: transparent;
  border: 1px solid #dee2e6;
  width: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#brand-group .component__select--name {
  font-size: 0.8rem;
  padding: 0 25px;
  cursor: pointer;
}

#brand-group .component__select-options {
  border: 1px solid #e5e5e5;
  border-top: none;
  position: absolute;
  z-index: 1500;
  width: auto;
  margin: 0;
  padding: 0;
}

#brand-group .select--option {
  background-color: #fff;
  /* width: 278px; */
  position: relative;
  z-index: 9;
}

#brand-group .select--option:hover {
  color: var(--servislet-blue);
}

.save-product-btn {
  transition: 0.5s;
  margin-top: 24px;
  background: var(--servislet-black);
  color: var(--servislet-white);
  border: 1px solid var(--servislet-black);
  outline: none;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.4px;
  padding: 8px 24px;
  border-radius: 5px;
}
.save-product-btn:hover {
  transition: 0.5s;
  background: var(--servislet-black);
  color: var(--servislet-white);
  box-shadow: rgba(28, 28, 28, 0.2) 0px 8px 30px 0px;
}

.cover-image {
  position: relative;
  border: 1px solid #d8d6de;
  border-radius: 0.65rem !important;
}
.max-100 {
  max-width: 100px;
  max-height: 100px;
}

.edit-icon,
.remove-icon {
  position: absolute;
  top: 10px;
  right: 12px;
  border-radius: 3px;
  background: rgba(211, 211, 211, 0.3);
  padding: 2px 6px;
  cursor: pointer;
}
.remove-icon {
  background-color: red;
}
</style>
