<template>
  <DefaultLayout>
    <div id="dashboard">
      <div class="row">
        <div class="col-lg-12" v-if="asset !== '1'">
          <dashboard-campaign-slider />
        </div>
        <div class="col-lg-8">
          <div id="wrapper-8">
            <DashboardWidgets />
            <SalesChart />
            <NotificationTable />
          </div>
        </div>
        <div class="col-lg-4">
          <DashboardPro />
          <CustomersChart />
          <DashboardCalendar />
        </div>
      </div>
    </div>
  </DefaultLayout>
</template>

<script>
/* eslint-disable */
import DefaultLayout from '@/layouts/default';
import DashboardWidgets from '@/components/DashboardWidgets.vue';
import SalesChart from '@/components/SalesChart.vue';
import NotificationTable from '@/components/NotificationTable.vue';
import DashboardCalendar from '@/components/DashboardCalendar.vue';
import CustomersChart from '@/components/CustomersChart.vue';
import DashboardCampaignSlider from "@/components/DashboardCampaignSlider.vue";
import DashboardPro from "@/components/DashboardPro.vue";

export default {
  name: 'DashboardView',
  components: {
    DashboardWidgets,
    SalesChart,
    NotificationTable,
    DashboardCalendar,
    CustomersChart,
    DefaultLayout,
    DashboardCampaignSlider,
    DashboardPro
  },
  data() {
    return {
      slide: 0,
      sliding: null,
      loader: false,
      promos: []
    };
  },
  computed:{
    asset(){
      return localStorage.getItem('account_asset')
    }
  },
  mounted() {
    this.getPromos();
  },
  methods: {
    getPromos() {
      this.loader = true;
      this.$axios
        .get(`/campaigns`, {})
        .then((response) => {
          this.promos = response.data.responseData;
          this.loader = false;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    onSlideStart(slide) {
      this.sliding = true;
    },
    onSlideEnd(slide) {
      this.sliding = false;
    }
  }
};
</script>

<style></style>
