<template>
  <div>
    <ValidationObserver ref="userCarUpdateRules">
      <div class="row">
        <b-col
          sm="12"
          md="12"
          lg="6">
          <ValidationProvider
            rules="required|min:1|max:20"
            :name="$t('Plate')"
            #default="{ errors }">
            <b-form-group>
              <label for="name"
                >{{ $t('Plate') }} <small class="text-danger">*</small></label
              >
              <b-form-input
                v-model="plate"
                class="bg-transparent"
                @input="formatCarPlate" />
              <label
                for="name"
                v-if="errors.length > 0"
                class="text-danger"
                ><small>*</small>{{ errors[0] }}.</label
              >
            </b-form-group>
          </ValidationProvider>
        </b-col>

        <b-col
          sm="12"
          md="12"
          lg="6">
          <ValidationProvider
            rules="required|min:17|max:17"
            :name="$t('Chassis Number')"
            #default="{ errors }">
            <b-form-group>
              <label for="name"
                >{{ $t('Chassis Number') }}
                <small class="text-danger">*</small></label
              >
              <b-form-input
                v-model="chassis_number"
                class="bg-transparent" />
              <label
                for="name"
                v-if="errors.length > 0"
                class="text-danger"
                ><small>*</small>{{ errors[0] }}.</label
              >
            </b-form-group>
          </ValidationProvider>
        </b-col>

        <b-col
          sm="12"
          md="12"
          lg="6">
          <ValidationProvider
            rules="required"
            :name="$t('Kilometer')"
            #default="{ errors }">
            <b-form-group>
              <label for="total_mileage">{{ $t('Kilometer') }}</label>
              <b-form-input
                v-model="total_mileage"
                min="0"
                oninput="validity.valid||(value='');"
                class="bg-transparent" />
              <label
                for="total_mileage"
                v-if="errors.length > 0"
                class="text-danger"
                ><small>* </small>{{ errors[0] }}.</label
              >
            </b-form-group>
          </ValidationProvider>
        </b-col>

        <b-col
          sm="12"
          md="12"
          lg="6">
          <ValidationProvider
            rules="required"
            :name="$t('Tire Brand')"
            #default="{ errors }">
            <b-form-group>
              <label for="total_mileage">{{ $t('Tire Brand') }}</label>
              <b-form-select
                v-model="tire_brand_id"
                @change="getWidthList()"
                class="bg-transparent">
                <b-form-select-option
                  :value="brand.id"
                  v-for="brand in tireBrands.brands"
                  :key="brand.$index">
                  {{ brand.name }}
                </b-form-select-option>
              </b-form-select>
              <label
                for="total_mileage"
                v-if="errors.length > 0"
                class="text-danger"
                ><small>* </small>{{ errors[0] }}.</label
              >
            </b-form-group>
          </ValidationProvider>
        </b-col>

        <b-col
          sm="12"
          md="12"
          lg="6">
          <b-form-group>
            <label for="tire_sizes">{{ $t('Tire Sizes') }}</label>
            <div class="row">
              <b-col
                sm="12"
                md="12"
                lg="4">
                <ValidationProvider
                  rules="required"
                  :name="$t('Width')"
                  #default="{ errors }">
                  <b-form-input
                    v-model="width"
                    class="bg-transparent"
                    type="number"></b-form-input>
                  <!-- <b-form-select v-model="width" @change="getHeightList()" class="bg-transparent">
                    <b-form-select-option :value="width" v-for="width in tireWidths.widths" :key="width.$index">{{
                        width
                      }}
                    </b-form-select-option>
                  </b-form-select> -->
                  <label
                    for="width"
                    v-if="errors.length > 0"
                    class="text-danger"
                    ><small>* </small>{{ errors[0] }}.</label
                  >
                </ValidationProvider>
              </b-col>

              <b-col
                sm="12"
                md="12"
                lg="4">
                <ValidationProvider
                  rules="required"
                  :name="$t('Height')"
                  #default="{ errors }">
                  <b-form-input
                    v-model="height"
                    class="bg-transparent"
                    type="number"></b-form-input>
                  <!-- <b-form-select v-model="height" @change="getRadiusList()" class="bg-transparent">
                    <b-form-select-option :value="height" v-for="height in tireHeights.heights" :key="height.$index">
                      {{ height }}
                    </b-form-select-option>
                  </b-form-select> -->
                  <label
                    for="height"
                    v-if="errors.length > 0"
                    class="text-danger"
                    ><small>* </small>{{ errors[0] }}.</label
                  >
                </ValidationProvider>
              </b-col>

              <b-col
                sm="12"
                md="12"
                lg="4">
                <ValidationProvider
                  rules="required"
                  :name="$t('Radius')"
                  #default="{ errors }">
                  <b-form-input
                    v-model="radius"
                    class="bg-transparent"
                    type="number"></b-form-input>
                  <!-- <b-form-select v-model="radius" class="bg-transparent">
                    <b-form-select-option :value="radius" v-for="radius in tireRadiuses.radiuses" :key="radius.$index">
                      {{ radius }}
                    </b-form-select-option>
                  </b-form-select> -->
                  <label
                    for="radius"
                    v-if="errors.length > 0"
                    class="text-danger"
                    ><small>* </small>{{ errors[0] }}.</label
                  >
                </ValidationProvider>
              </b-col>
            </div>
          </b-form-group>
        </b-col>
      </div>
      <div class="d-flex justify-content-end update">
        <b-button @click="updateUserCar()">{{ $t('Update') }}</b-button>
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'userCarForm',
  props: {
    user_car: {
      type: Object,
      default: () => {}
    },
    tire_brand_slug: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      plate: this.user_car.plate_number,
      chassis_number: this.user_car.chassis_number,
      total_mileage: this.user_car.total_mileage,
      tire_brand_id: this.user_car.tireBrand
        ? this.user_car.tireBrand.id
        : null,
      brand_slug: this.tire_brand_slug,
      width: this.user_car.width,
      height: this.user_car.height,
      radius: this.user_car.radius
    };
  },
  computed: {
    ...mapState(['tireBrands']),
    ...mapState(['tireWidths']),
    ...mapState(['tireHeights']),
    ...mapState(['tireRadiuses'])
  },
  mounted() {
    this.$store.dispatch('tireBrands/fetchTireBrands');
    this.getWidthList();
    this.getHeightList();
    this.getRadiusList();
  },
  methods: {
    formatCarPlate() {
      const cleanedNumber = this.plate.toUpperCase();
      const formattedNumber = cleanedNumber.replace(
        /(\d{2})(\s?[A-Z]{1,3})(\d{3,4})/,
        '$1 $2 $3'
      );
      this.plate = formattedNumber;
    },
    getWidthList() {
      this.$store.state.tireBrands.brands.find((item) =>
        item.id === this.tire_brand_id ? (this.brand_slug = item.slug) : null
      );
      if (this.brand_slug) {
        this.$store.dispatch('tireWidths/fetchTireWidths', this.brand_slug);
      }
    },
    getHeightList() {
      if (this.user_car.width && this.brand_slug) {
        this.$store.dispatch('tireHeights/fetchTireHeights', {
          brand_slugs: this.brand_slug,
          width: this.width
        });
      }
    },
    getRadiusList() {
      if (this.user_car.height && this.user_car.width && this.brand_slug) {
        this.$store.dispatch('tireRadiuses/fetchTireRadiuses', {
          brand_slugs: this.brand_slug,
          width: this.width,
          height: this.height
        });
      }
    },
    updateUserCar() {
      this.$refs.userCarUpdateRules.validate().then((success) => {
        if (success) {
          this.$axios
            .put(`/customers/cars/${this.user_car.id}`, {
              is_default: this.user_car.is_default,
              is_active: this.user_car.is_active,
              plate_number: this.plate,
              year: this.user_car.year,
              chassis_number: this.chassis_number,
              tire_brand_id: this.tire_brand_id,
              width: this.width,
              height: this.height,
              radius: this.radius,
              total_mileage: this.total_mileage
            })
            .then((response) => {
              if (response.data.status) {
                this.$swal.fire({
                  iconHtml: '<img src="/img/success.svg" width="32" height="32">',
                  position: 'top-end',
                  title: this.$i18n.t(
                    'Vehicle information has been successfully updated.'
                  ),
                  showConfirmButton: false,
                  timer: 2500,
                  toast: true,
                  customClass: {
                    icon: 'swal2-no-border'
                  }
                });
                this.$emit('refresh');
              }
            })
            .catch((error) => {
              var message = error.response.data.responseData.error
                ? error.response.data.responseData.error[0].message
                : error.response.data.responseData.message;
              this.$swal.fire({
                iconHtml: '<img src="/img/error.svg" width="32" height="32">',
                position: 'top-end',
                title: message,
                showConfirmButton: false,
                timer: 2500,
                toast: true,
                customClass: {
                  icon: 'swal2-no-border'
                }
              });
            });
        } else {
          this.$swal.fire({
            iconHtml: '<img src="/img/warning.png" width="32" height="32">',
            position: 'top-end',
            title: this.$i18n.t('Please fill the required fields.'),
            showConfirmButton: false,
            timer: 2500,
            toast: true,
            customClass: {
              icon: 'swal2-no-border'
            }
          });
        }
      });
    }
  }
};
</script>

<style scoped>
.update button {
  transition: 0.5s;
  margin-top: 24px;
  background: var(--servislet-black);
  color: var(--servislet-white);
  border: 1px solid var(--servislet-black);
  outline: none;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.4px;
  padding: 8px 24px;
}
.update button:hover {
  transition: 0.5s;
  background: var(--servislet-black);
  color: var(--servislet-white);
  box-shadow: rgba(28, 28, 28, 0.2) 0px 8px 30px 0px;
}
</style>
