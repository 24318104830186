<template>
  <div id="dashboard-calendar-wrapper">
    <div class="dashboard-calendar-flex">
      <p class="dashborad-calendar-title">{{ $t('Calendar') }}</p>
      <p
        class="dashboard-calendar-show-detail"
        @click="$router.push('/calendar')">
        {{ $t('Read More') }}
      </p>
    </div>
    <div v-if="loader">
      <div class="px-4">
        <content-placeholders :rounded="true">
          <content-placeholders-text  :lines="5" />
        </content-placeholders>
      </div>
    </div>
    <div v-else>
        <div
            class="dashboard-calendar-loop-item"
            v-for="(event, key) in events"
            :key="event.$index"
            v-if="events.length>0"
        >
          <div class="dashboard-calendar-loop-item-flex">
            <p class="dashboard-calendar-date"> {{spliceDatePartFromDate(event.start_date)}}</p>
            <button class="dashboard-calendar-actions">...</button>
          </div>
          <div class="dashboard-calendar-item">
            <p class="dashboard-calendar-item-hour default-border" :class="{'appointment-border' : event.title.includes('Randevu'),'payment-border' : event.title.includes('Ödeme') }">
              {{ event.start_date.slice(11,16)}}
            </p>
            <div class="dashboard-calendar-item-info-div">
              <p
                  class="dashboard-calendar-item-type dashboard-calendar-item-type-payment" :class="{'appointment-color' : event.title.includes('Randevu'),'payment-color' : event.title.includes('Ödeme') }">
                {{ event.title }}
              </p>
              <p class="dashboard-calendar-item-info" v-if="event.customer">{{ event.customer.name + ' ' + event.customer.surname }}</p>
            </div>
          </div>
        </div>

      <div class="dashboard-calendar-flex-body" v-if="events.length === 0">
        <p class="no-text">
          {{ $t('There are no events to list. You can add events to your calendar.') }}
        </p>
        <p class="dashboard-calendar-show-detail" @click="$router.push('/calendar')">
          {{ $t('Add Event') }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loader: true,
      events: [],
      start_date: '',
      end_date: '',
    };
  },
  mounted() {
    this.getFirstDayOfMonth();
    this.getLastDayOfMonth();
    //this.getEvents();
    this.getChannelevents()
  },
  methods: {
    getFirstDayOfMonth() {
      // Get the current date
      const currentDate = new Date();
      // Set the day of the month to 1
      currentDate.setDate(1);
      // Now `currentDate` represents the first day of the current month
      // Extract the relevant components (year, month, day)
      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, '0');
      const day = String(currentDate.getDate()).padStart(2, '0');
      // Combine the components into the "yyyy-mm-dd" format
      this.start_date = `${year}-${month}-${day}`;
    },
    getLastDayOfMonth() {
      // Get the current date
      const currentDate = new Date();
      // Set the day of the month to 1
      currentDate.setDate(1);
      // Move to the next month
      currentDate.setMonth(currentDate.getMonth() + 1);
      // Go back one day to get the last day of the current month
      currentDate.setDate(currentDate.getDate() - 1);
      // Extract the relevant components (year, month, day)
      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, '0');
      const day = String(currentDate.getDate()).padStart(2, '0');
      // Combine the components into the "yyyy-mm-dd" format
      this.end_date = `${year}-${month}-${day}`;
    },
    getEvents() {
      this.loader = true;
      this.$axios
        .get(`/events/dashboard`, {})
        .then((response) => {
          this.events = response.data.responseData.events;
          this.loader = false;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getChannelevents() {
      this.loader = true;
      this.$axios
          .get('/events/channel', {
            params: {
              code: 'servislet',
              start_date: this.start_date,
              end_date: this.end_date
            }
          })
          .then((response) => {
            this.events = response.data.responseData.events;
            //console.log(this.events)
            // for (let index = 0; index < array.length; index++) {
            //   const element = array[index];
            //   var start_date = this.convertDateFormat(element.start_date)
            //   var end_date = this.convertDateFormat(element.end_date)
            //   this.events.push({
            //     id: element.id,
            //     startDate: start_date,
            //     endDate: end_date,
            //     title: element.title,
            //     code: 'servislet'
            //   });
            // }
            this.loader = false;
          });
    },
  }
};
</script>

<style scoped>
.no-text{
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
}
.dashboard-calendar-flex-body{
  padding: 12px 20px;
}
.default-border {
  border-right: 2px solid #1C1C1C;
}
.appointment-border {
  border-right: 2px solid #07C7E3;
  color: #07C7E3;
}
.payment-border {
  border-right: 2px solid #28C76F;
}
.payment-color {
  color: #28C76F;
}

.appointment-color {
  color: #07C7E3;
}

</style>
