<template>
  <svg width="35" height="34" viewBox="0 0 35 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="1" y="1" width="33.0356" height="31.8965" rx="5" :stroke="text_color"/>
    <path d="M19.7701 8.30432C19.5679 8.23851 19.3435 8.26688 19.168 8.38035C18.9924 8.49383 18.8874 8.67836 18.8862 8.87582V13.5059H16.2341L16.1634 8.81082C16.1608 8.61593 16.0573 8.43395 15.8847 8.32091C15.7122 8.208 15.4912 8.17765 15.2904 8.23949C13.9655 8.66063 12.8184 9.45495 12.0118 10.5098C11.2052 11.5647 10.7804 12.8265 10.7974 14.1161C10.7897 15.2464 11.1189 16.3571 11.7495 17.3271C12.3798 18.2973 13.2874 19.0898 14.3733 19.6186V25.0784C14.3733 25.2402 14.4431 25.3954 14.5673 25.5097C14.6918 25.6242 14.8604 25.6884 15.0362 25.6884H20.0044C20.1802 25.6884 20.3488 25.6242 20.4732 25.5097C20.5975 25.3954 20.6673 25.2402 20.6673 25.0784V19.6796C21.7532 19.1508 22.6608 18.3583 23.2911 17.3881C23.9217 16.4181 24.2509 15.3074 24.2432 14.1771C24.2346 12.896 23.8018 11.6477 23.0027 10.5984C22.2035 9.54936 21.076 8.74899 19.7701 8.30432ZM19.7303 18.7359C19.6121 18.7834 19.5114 18.8616 19.4408 18.9608C19.3701 19.0603 19.3325 19.1763 19.3326 19.295V24.468H15.6992V19.234C15.7 19.1163 15.6638 19.0007 15.5947 18.9015C15.5256 18.8023 15.4268 18.7235 15.3103 18.6749C14.3546 18.2825 13.5442 17.6423 12.9783 16.8325C12.4122 16.0227 12.1151 15.0786 12.1233 14.116C12.1125 13.2296 12.3595 12.3566 12.8386 11.5876C13.3178 10.8186 14.0116 10.1813 14.8484 9.742L14.9036 14.1219C14.9036 14.2836 14.9734 14.4388 15.0978 14.5532C15.2221 14.6676 15.3908 14.7318 15.5667 14.7318H19.5447C19.7205 14.7318 19.8891 14.6676 20.0135 14.5532C20.1378 14.4388 20.2078 14.2836 20.2078 14.1219V9.85166C21.8733 10.7559 22.9016 12.3974 22.9173 14.1767C22.9255 15.1394 22.6284 16.0835 22.0623 16.8933C21.4964 17.703 20.686 18.3436 19.7303 18.7359Z" :fill="text_color"/>
  </svg>
</template>

<script>
export default {
  name: 'Setting',
  computed:{
    text_color() {
      return 'rgba' + localStorage.getItem('account_text_color')
    }
  }
}

</script>
