<template>
  <DefaultLayout>
    <PageBreadcrumbs :breadcrumbs="breadcrumbs" />
    <div>
      <div
        id="products-listing-table-wrapper"
        class="mt-3 py-4">
        <div
          v-if="show_filter"
          class="filter-double-flex">
          <div class="filter-left-content-flex">
            <div class="filter-text">{{ $t('Filters') }}:</div>
            <div class="ml-3">
              <order-status-select
                :content_will_select="$t('Status')"
                :options="orderStatuses"
                :selected_count="$route.query.status_code ? 1 : 0"
                @clearQuery="clearQueryParams()"
                @filterByStatusCode="
                  getSaleListByChannel();
                  getSalesList();
                " />
            </div>
            <div class="ml-3">
              <filter-by-date
                @clearQueryDates="clearQueryDates()"
                @filterByDate="
                  getSalesList();
                  getSaleListByChannel();
                " />
            </div>
            <div class="ml-3">
              <b-form-checkbox
                id="checkbox-1"
                v-model="today_appointment"
                :unchecked-value="false"
                :value="true"
                name="checkbox-1"
                @change="isTodayAppointment()">
                <span style="font-size: 13px">{{
                  $t("Show today's sales")
                }}</span>
              </b-form-checkbox>
            </div>
          </div>
          <div class="filter-double-flex-right">
            <div class="filter-double-flex-right-actions">
              <span
                class="clear-filters-text"
                @click="clearQueryParameters()"
                >{{ $t('Clear Filters') }}</span
              >
              <i
                class="fas fa-xmark"
                @click="show_filter = !show_filter"></i>
            </div>
          </div>
        </div>
        <div
          v-if="!show_filter"
          class="products-double-flex">
          <div class="products-double-flex-left">
            <button
              class="add-new-product-btn"
              @click="$router.push({ name: 'AddSale' })">
              <i class="fa-solid fa-plus"></i>
              {{ $t('Sale') }}
            </button>
          </div>
          <div class="products-double-flex-right">
            <div
              v-if="!show_search_text"
              v-click-outside="close"
              :class="!show_search_text ? 'opened' : ''"
              class="products-double-flex-right-actions"
              style="max-width: unset;"
            >
              <span>
                <i class="fa-solid fa-search ml-0"></i>
              </span>
              <b-form-input
                v-model="filter"
                :class="!show_search_text ? 'show-search_text' : ''"
                :placeholder="$t('Order ID, Customer or Product')"
                class="search-text-input"
                v-on:keyup.enter="
                  getSalesList();
                  getSaleListByChannel();
                " />
              <i
                class="fa-solid fa-xmark close"
                @click="
                  show_search_text = !show_search_text;
                  filter = '';
                  getSalesList();
                  getSaleListByChannel();
                "></i>
            </div>
            <div
              v-if="show_search_text"
              class="products-double-flex-right-actions"
              @click="show_search_text = !show_search_text">
              <span>
                <i class="fa-solid fa-search"></i>
                <p>{{ $t('Search') }}</p>
              </span>
            </div>
            <div
              class="products-double-flex-right-actions"
              @click="show_filter = !show_filter">
              <span>
                <i class="fa-solid fa-filter"></i>
                <p>{{ $t('Filter') }}</p>
              </span>
            </div>
          </div>
        </div>
        <b-tabs
          active-nav-item-class="product-detail-tabs-active"
          class="product-detail-tabs mt-3"
          content-class="mt-2">
          <b-tab
            active
            title="Servislet">
            <div
              v-if="!$store.state.sales.sales_by_channel.loading"
              class="product-listing-table">
              <table>
                <tr class="product-listing-table-head">
                  <th>
                    <label class="product-listing-checkbox-label">
                      <input
                        type="checkbox"
                        @click="checkUncheck()" />
                      <span class="checkmark"></span>
                    </label>
                  </th>
                  <th>ID</th>
                  <th>{{ $t('Customer') }}</th>
                  <th>{{ $t('Car') }}</th>
                  <th>{{ $t('Product') }} / {{ $t('Service') }}</th>
                  <th>{{ $t('Price') }}</th>
                  <th>{{ $t('Order Channel') }}</th>
                  <th>{{ $t('Date') }}</th>
                  <th class="text-center">{{ $t('Status') }}</th>
                  <th></th>
                </tr>
                <tr
                  v-for="order in channel_orders"
                  :key="order.$index">
                  <td>
                    <label class="product-listing-checkbox-label">
                      <input
                        name="single-payment-checkbox"
                        type="checkbox" />
                      <span class="checkmark"></span>
                    </label>
                  </td>
                  <td>#SP{{ order.id }}</td>
                  <td style="font-weight: 600">
                    {{ order.customer_name }} {{ order.customer_surname }}
                  </td>
                  <td class="text-nowrap">
                    {{
                      order.customer_car_plate_number
                        ? order.customer_car_plate_number
                        : '-'
                    }}
                  </td>
                  <td>{{ order.item.name }}</td>
                  <td>₺{{ getAmount(order.total_amount) }}</td>
                  <td>Servislet</td>
                  <td> {{dateFormat(order.created_at)}}</td>
                  <td>
                    <p
                        :class="{'commission-badge': order.orderStatus.code === 'completed','user-badge': order.orderStatus.code === 'payment-completed','error-badge': order.orderStatus.code === 'cancelled','sale-badge': order.orderStatus.code === 'approved'}"
                      class="servislet-badge w-100">
                      {{ order.orderStatus.name }}
                    </p>
                  </td>
                  <td>

                    <b-dropdown
                        id="product-actions-dropdown"
                        class="product-listing-action-select"
                        no-caret
                        right>
                      <template #button-content>
                        <div class="button-content">
                          {{ $t('Actions') }}
                          <i class="fa-solid fa-chevron-down"></i>
                        </div>
                      </template>
                      <b-dropdown-item class="table-dropdown-actions">
                        <router-link :to="`/sale-channel/${order.id}`">
                          {{ $t('See More') }}
                       </router-link>
                      </b-dropdown-item>
                    </b-dropdown>
                  </td>
                </tr>
              </table>
              <div
                v-if="$store.state.sales.sales_by_channel.pagination.total > 0"
                id="table-info-and-pagination">
                <div class="table-results-info">
                  <p>
                    {{$t('{total} {from} {start}-{end} is showing.',{total: $store.state.sales.sales_by_channel.pagination.total, from: $t('from sales'), start: $store.state.sales.sales_by_channel.items.length>0 ? ( $store.state.sales.sales_by_channel.pagination.current_page === 1 ? 1 : (($store.state.sales.sales_by_channel.pagination.current_page - 1) * $store.state.sales.sales_by_channel.pagination.per_page) + 1 ) : 0, end: $store.state.sales.sales_by_channel.pagination.total_page === $store.state.sales.sales_by_channel.pagination.current_page ? $store.state.sales.sales_by_channel.pagination.total : $store.state.sales.sales_by_channel.pagination.current_page * $store.state.sales.sales_by_channel.pagination.per_page})}}
                  </p>
                </div>
                <div class="pagination-wrapper">
                  <b-pagination
                    v-model="channel_current_page"
                    :per-page="
                      $store.state.sales.sales_by_channel.pagination.per_page
                    "
                    :total-rows="
                      $store.state.sales.sales_by_channel.pagination.total
                    "
                    align="center"
                    class="my-1"
                    pills
                    size="sm"
                    @input="getSaleListByChannel()" />
                </div>
                <b-modal
                  id="delete-modal"
                  ref="delete-modal"
                  centered
                  hide-footer
                  hide-header
                  size="md">
                  <div class="delete-modal-body">
                    <div class="delete-modal-header">
                      <i class="fa-solid fa-xmark cursor-pointer"></i>
                    </div>
                    <div class="delete-modal-content-text">
                      {{ $t('Are you sure you want to delete the sale?') }}
                    </div>
                    <div class="delete-modal-buttons">
                      <b-button
                        class="cancel-button"
                        @click="$refs['delete-modal'].hide()"
                        >{{ $t('Cancel') }}
                      </b-button>
                      <b-button
                        class="delete-button"
                        @click="deleteOrder()"
                        >{{ $t('Delete') }}
                      </b-button>
                    </div>
                  </div>
                </b-modal>
              </div>
              <div v-else class="d-flex flex-column align-items-center">
                <p class="table-no-text"> {{
                    $t('No {data} found to list.', {data: $t('order')})
                  }}</p>
              </div>
            </div>
            <div
              v-else
              class="loader"
              style="padding: 24px 16px">
              <content-placeholders :rounded="true">
                <content-placeholders-img />
                <content-placeholders-text :lines="10" />
              </content-placeholders>
            </div>
          </b-tab>
          <b-tab :title="$t('Store Sales')">
            <div
              v-if="!$store.state.sales.list.loading"
              class="product-listing-table">
              <table>
                <tr class="product-listing-table-head">
                  <th>
                    <label class="product-listing-checkbox-label">
                      <input
                        type="checkbox"
                        @click="checkUncheck()" />
                      <span class="checkmark"></span>
                    </label>
                  </th>
                  <th>ID</th>
                  <th>{{ $t('Customer') }}</th>
                  <th>{{ $t('Car') }}</th>
                  <th>{{ $t('Product') }}/ {{ $t('Service') }}</th>
                  <th>{{ $t('Price') }}</th>
                  <th>{{ $t('Order Channel') }}</th>
                  <th>{{ $t('Date') }}</th>
                  <th>{{ $t('Status') }}</th>
                  <th></th>
                </tr>
                <tr
                  v-for="order in orders"
                  :key="order.$index">
                  <td>
                    <label class="product-listing-checkbox-label">
                      <input
                        name="single-payment-checkbox"
                        type="checkbox" />
                      <span class="checkmark"></span>
                    </label>
                  </td>
                  <td>#{{ order.order_no }}</td>
                  <td style="font-weight: 600">
                    {{ order.customer_name }} {{ order.customer_surname }}
                  </td>
                  <td class="text-nowrap">{{ order.customer_car_plate_number }}</td>
                  <td>Michelin Crossclimate 2...</td>
                  <td>₺{{ getAmount(order.total_amount) }}</td>
                  <td>{{ order.orderChannel.name }}</td>
                  <td>{{ order.created_at.substring(0, 10) }}</td>
                  <td>
                    <p
                      :style="`${'background:' + order.orderStatus.color}`"
                      class="servislet-badge w-100">
                      {{ order.orderStatus.name }}
                    </p>
                  </td>
                  <td>
                    <b-dropdown
                      no-caret
                      right>
                      <template #button-content>
                        <i class="fa-solid fa-eye"></i>
                      </template>
                      <b-dropdown-item class="table-dropdown-actions">
                        <router-link :to="`/sale/${order.id}`"
                          ><p class="w-100 m-0">
                            {{ $t('See More') }}
                          </p></router-link
                        >
                      </b-dropdown-item>

                      <b-dropdown-item class="table-dropdown-actions">
                        <router-link :to="`/update-sale/${order.id}`"
                          ><p class="w-100 m-0">
                            {{ $t('Edit') }}
                          </p></router-link
                        >
                      </b-dropdown-item>
                      <b-dropdown-item
                        class="table-dropdown-actions"
                        href="#"
                        ><p
                          class="w-100 m-0"
                          @click="openDeleteModal(order.id)">
                          {{ $t('Delete') }}
                        </p></b-dropdown-item
                      >
                    </b-dropdown>
                  </td>
                </tr>
              </table>
              <div
                v-if="pagination.total > 0"
                id="table-info-and-pagination">
                <div class="table-results-info">
                  <p>
                    {{$t('{total} {from} {start}-{end} is showing.',{total: pagination.total, from: $t('from sales'), start: orders.length>0 ? ( pagination.current_page === 1 ? 1 : ((pagination.current_page - 1) * pagination.per_page) + 1 ) : 0, end: pagination.total_page === pagination.current_page ? pagination.total : pagination.current_page * pagination.per_page})}}
                  </p>
                </div>
                <div class="pagination-wrapper">
                  <b-pagination
                    v-model="current_page"
                    :per-page="$store.state.sales.list.pagination.per_page"
                    :total-rows="$store.state.sales.list.pagination.total"
                    align="center"
                    class="my-1"
                    pills
                    size="sm"
                    @input="getSalesList()" />
                </div>
                <b-modal
                  id="delete-modal"
                  ref="delete-modal"
                  centered
                  hide-footer
                  hide-header
                  size="md">
                  <div class="delete-modal-body">
                    <div class="delete-modal-header">
                      <i class="fa-solid fa-xmark cursor-pointer"></i>
                    </div>
                    <div class="delete-modal-content-text">
                      {{ $t('Are you sure you want to delete the order?') }}
                    </div>
                    <div class="delete-modal-buttons">
                      <b-button
                        class="cancel-button"
                        @click="$refs['delete-modal'].hide()"
                        >{{ $t('Cancel') }}
                      </b-button>
                      <b-button
                        class="delete-button"
                        @click="deleteOrder()"
                        >{{ $t('Delete') }}
                      </b-button>
                    </div>
                  </div>
                </b-modal>
              </div>
              <div v-else class="d-flex flex-column align-items-center">
                <p class="table-no-text"> {{
                    $t('No {data} found to list.', {data: $t('order')})
                  }}</p>
              </div>
            </div>
            <div
              v-else
              class="loader"
              style="padding: 24px 16px">
              <content-placeholders :rounded="true">
                <content-placeholders-img />
                <content-placeholders-text :lines="10" />
              </content-placeholders>
            </div>
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </DefaultLayout>
</template>

<script>
/* eslint-disable */
import PageBreadcrumbs from '../../components/PageBreadcrumbs.vue';
import DefaultLayout from '@/layouts/default.vue';
import OrderStatusSelect from '@/components/shared/OrderStatusSelect';
import FilterByDate from '@/components/shared/FilterByDate';
import vClickOutside from 'v-click-outside';

export default {
  components: {
    DefaultLayout,
    PageBreadcrumbs,
    OrderStatusSelect,
    FilterByDate
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  data() {
    return {
      breadcrumbs: [
        {
          name: this.$i18n.t('Sales'),
          path: '/#'
        }
      ],
      show_search_text: true,
      filter: null,
      deleted_order_id: '',
      current_page: 1,
      channel_current_page: 1,
      show_filter: false,
      today_appointment: false
    };
  },
  computed: {
    orders() {
      return this.$store.state.sales.list.items;
    },
    channel_orders() {
      return this.$store.state.sales.sales_by_channel.items;
    },
    pagination() {
      return this.$store.state.sales.list.pagination;
    },
    orderStatuses() {
      if (this.$store.state.orderStatus.statuses.length < 1) {
        this.$store.dispatch('orderStatus/fetchOrderStatuses');
      }
      return this.$store.state.orderStatus.statuses;
    }
  },
  mounted() {
    this.getSaleListByChannel();
    this.getSalesList();
  },
  methods: {
    openDeleteModal(id) {
      this.deleted_order_id = id;
      this.$refs['delete-modal'].show();
    },
    deleteOrder() {
      this.$refs['delete-modal'].hide();
      this.$axios
        .delete(`/tire/orders/${this.deleted_order_id}`)
        .then((response) => {
          if (response.data.status) {
            this.$swal.fire({
              iconHtml: '<img src="/img/success.svg" width="32" height="32">',
              position: 'top-end',
              title: this.$i18n.t('Order successfully deleted.'),
              showConfirmButton: false,
              timer: 2500,
              toast: true,
              customClass: {
                icon: 'swal2-no-border'
              }
            });
            this.orders = [];
            this.getSalesList();
          }
        });
    },
    getSaleListByChannel() {
      if (this.$route.query.today_appointment) {
        this.today_appointment =
          this.$route.query.today_appointment == 1 ? true : false;
      }
      var data = {
        filter: this.filter,
        current_page: this.channel_current_page,
        status_code: this.$route.query.status_code,
        start_date: this.$route.query.start_date,
        end_date: this.$route.query.end_date,
        today_appointment: this.$route.query.today_appointment
      };
      this.$store.dispatch('sales/getSalesByChannel', data);
    },
    getSalesList() {
      var data = {
        filter: this.filter,
        current_page: this.current_page,
        status_code: this.$route.query.status_code,
        start_date: this.$route.query.start_date,
        end_date: this.$route.query.end_date,
        today_appointment: this.$route.query.today_appointment
      };
      this.$store.dispatch('sales/getSalesListItem', data);
    },
    getAmount(amount) {
      var options = {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      };
      return parseFloat(amount).toLocaleString('tr-TR', options);
    },
    checkUncheck(checkBox) {
      var get = document.getElementsByName('single-payment-checkbox');
      for (var i = 0; i < get.length; i++) {
        if (get[i].checked === true) {
          get[i].checked = false;
        } else {
          get[i].checked = true;
        }
      }
    },
    clearQueryParams() {
      const currentQuery = { ...this.$route.query }; // Make a copy of the current query object
      delete currentQuery.status_code;
      this.$router.push({ path: '/sales', query: { ...currentQuery } });
      this.getSaleListByChannel();
      this.getSalesList();
    },
    clearQueryDates() {
      if (this.$route.query.start_date || this.$route.query.end_date) {
        const currentQuery = { ...this.$route.query }; // Make a copy of the current query object
        delete currentQuery.start_date;
        delete currentQuery.end_date;
        this.$router.push({ path: '/sales', query: { ...currentQuery } });
        this.getSaleListByChannel();
        this.getSalesList();
      }
    },
    isTodayAppointment() {
      console.log(this.today_appointment);
      var url = new URL(window.location.href);
      url.searchParams.set(
        'today_appointment',
        this.today_appointment === true ? 1 : 0
      );
      this.$route.query.today_appointment =
        this.today_appointment === true ? 1 : 0;
      url.search = decodeURIComponent(url.search);
      window.history.pushState({}, '', url);
      this.getSaleListByChannel();
      this.getSalesList();
    },
    clearQueryParameters() {
      const currentQuery = { ...this.$route.query }; // Make a copy of the current query object
      delete currentQuery.status_code;
      delete currentQuery.start_date;
      delete currentQuery.end_date;
      delete currentQuery.today_appointment;
      this.$router.push({ path: '/sales', query: { ...currentQuery } });
      this.getSaleListByChannel();
      this.getSalesList();
    },
    close() {
      this.show_search_text = !this.show_search_text;
    }
  }
};
</script>

<style scoped>
.servislet-badge {
  margin: 0 !important;
  max-width: 150px;
  font-size: 12px;
}
</style>
