<template>
  <DefaultLayout>
    <div id="add-appointment-wrapper">
      <PageBreadcrumbs :breadcrumbs="breadcrumbs" />
      <div
        id="products-listing-table-wrapper"
        class="py-4">
        <div class="products-double-flex">
          <div class="products-double-flex-left">
            <p class="page-title">{{ $t('Add Appointment') }}</p>
            <p class="page-txt">Lorem ipsum dolor sit amet consectetur.</p>
          </div>
        </div>
        <div
          class="add-appointment-wrapper pt-2"
          id="calendar-wrapper">
          <div v-if="added_customer">
            <span class="title">{{ $t('Customer') }}</span>
            <div class="mt-3 added-customer-card">
              <div class="flex-start-space w-100">
                <div class="added-customer-info-content">
                  <i
                    class="fa-regular fa-user"
                    style="color: #7367f0; font-size: 20px"></i>
                  <div class="ml-3">
                    <span class="customer-name">{{ name }} {{ surname }}</span>
                    <span class="phone mt-3">{{ phones[0] }}</span>
                  </div>
                </div>
                <div>
                  <i
                    class="fa-solid fa-xmark cursor-pointer"
                    @click="added_customer = !added_customer"></i>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <ValidationObserver ref="customerAddRules">
              <div class="flex-center-space">
                <div
                  class="products-double-flex p-0"
                  v-if="selected_customer !== 1 && selected_customer !== 2">
                  <div class="choose-product-container">
                    <div
                      class="choose-product"
                      v-if="Object.keys(customer.info).length > 0"
                      :class="[
                        { 'shadow-sm': customer.box },
                        {
                          'customer-div': Object.keys(customer.info).length > 0
                        }
                      ]">
                      <div
                        class="choose-product-label"
                        v-if="Object.keys(customer.info).length > 0">
                        <div class="customer-info">
                          <div class="customer-name">
                            <div class="customer-name-name">
                              <i
                                class="fa-regular fa-user"
                                style="color: #7367f0"></i>
                              <strong
                                >{{ customer.info.name }}
                                {{ customer.info.surname }}</strong
                              >
                            </div>
                            <div class="customer-name-info">
                              <span
                                ><i class="fa-regular fa-envelope"></i>
                                {{
                                  customer.info.email ?? $t('Not defined')
                                }}</span
                              >
                              <span
                                ><i class="fa-regular fa-phone"></i>
                                {{
                                  customer.info.phones.phone ??
                                  $t('Not defined')
                                }}</span
                              >
                            </div>
                          </div>
                          <div
                            class="customer-name"
                            v-if="Object.keys(customer.car).length > 0"
                            style="
                              border-left: 1px solid #dee2e6;
                              margin-left: 25px;
                              padding-left: 35px;
                            ">
                            <div class="customer-name-name">
                              <i
                                class="fa-regular fa-car"
                                style="color: #7367f0"></i>
                              <strong>{{ customer.car.plate_number }}</strong>
                            </div>
                            <div class="customer-name-info">
                              <span
                                >{{ customer.car.brand }}
                                {{ customer.car.model }}
                                {{ customer.car.year }}</span
                              >
                              <span
                                >{{ customer.car.version }}
                                {{ customer.car.version_type }}</span
                              >
                            </div>
                          </div>
                          <i
                            class="fa fa-times ml-3"
                            @click="removeCustomer"></i>
                        </div>
                      </div>
                      <div
                        v-else
                        class="icon-with-name">
                        <span class="choose-product-label">{{
                          $t('Choose')
                        }}</span>
                        <i
                          class="fa-solid fa-circle-exclamation text-danger"
                          v-if="customer.errorMessage"></i>
                      </div>
                    </div>
                    <div
                      class="choose-product"
                      v-else
                      @click="openCustomerBox"
                      :class="[
                        { 'shadow-sm': customer.box },
                        {
                          'customer-div': Object.keys(customer.info).length > 0
                        }
                      ]">
                      <span
                        class="choose-product-label"
                        v-if="Object.keys(customer.info).length > 0">
                        <div class="customer-name">
                          <div class="customer-name-name">
                            <i
                              class="fa-regular fa-user"
                              style="color: #7367f0"></i>
                            <strong
                              >{{ customer.info.name }}
                              {{ customer.info.surname }}</strong
                            >
                          </div>
                          <div class="customer-name-info">
                            <span
                              ><i class="fa-regular fa-envelope"></i>
                              {{
                                customer.info.email ?? $t('Not defined')
                              }}</span
                            >
                            <span
                              ><i class="fa-regular fa-phone"></i>
                              {{
                                customer.info.phones.phone ?? $t('Not defined')
                              }}</span
                            >
                          </div>
                        </div>
                      </span>
                      <div
                        v-else
                        class="icon-with-name">
                        <span class="choose-product-label">{{
                          $t('Choose Customer')
                        }}</span>
                        <div>
                          <i
                            class="fa-solid fa-circle-exclamation text-danger"
                            v-if="customer.errorMessage"></i>
                          <i class="fas fa-chevron-down"></i>
                          <i
                            class="fas fa-plus mx-2"
                            v-b-modal.customer-add-modal></i>
                        </div>
                      </div>
                    </div>
                    <div
                      class="choose-product-list"
                      :class="customer.box ? 'shadow-sm' : ''"
                      v-show="customer.box">
                      <div class="choose-product-input-area">
                        <input
                          type="text"
                          v-model="customer.searchArea"
                          :id="`choose-customer-input`"
                          class="form-control m-3 choose-product-input"
                          @keyup="searchInputCustomer" />
                        <b-spinner
                          label="Spinning"
                          small
                          class="input-spinner"
                          v-if="customer.loading"></b-spinner>
                      </div>
                      <div
                        class="choose-product-list-item"
                        v-for="cust in customer.searchResult"
                        :key="cust.$index"
                        @click="setCustomerSearch(cust)">
                        <strong class="choose-product-list-item-name"
                          >{{ cust.name }} {{ cust.surname }}
                          <small style="font-size: 10px">{{
                            cust.customerType.name
                          }}</small></strong
                        >
                        <div class="choose-product-list-item-info">
                          <small style="font-size: 11px"
                            ><strong>{{ $t('E-Mail') }}:</strong>
                            {{ cust.email }}
                          </small>
                          <small style="font-size: 11px"
                            ><strong>{{ $t('Phone') }}:</strong>
                            {{ cust.phones.phone }}
                          </small>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div></div>
                </div>
                <!--  -->
                <b-form-group class="visibility-hidden w-100">
                  <b-form-input></b-form-input>
                </b-form-group>
                <b-form-group class="visibility-hidden w-100">
                  <b-form-input></b-form-input>
                </b-form-group>
              </div>
            </ValidationObserver>
          </div>
          <!-- customer add modal -->
          <b-modal
            id="customer-add-modal"
            ref="customer-add-modal"
            size="xl"
            centered
            hide-footer
            hide-header>
            <div>
              <div class="flex-start-space">
                <div>
                  <p class="customer-add-modal-title">
                    {{ $t('Add New Customer') }}
                  </p>
                  <b-form-group class="mt-3">
                    <b-form-select v-model="customerType">
                      <b-form-select-option
                        :value="option.id"
                        v-for="option in customerTypes"
                        :key="option.$index">
                        {{ option.name }}
                      </b-form-select-option>
                    </b-form-select>
                  </b-form-group>
                </div>
                <div>
                  <i
                    class="fas fa-xmark cursor-pointer close-icon"
                    @click="$refs['customer-add-modal'].hide()"></i>
                </div>
              </div>
            </div>
            <ValidationObserver ref="customerAddModalRules">
              <div class="row">
                <b-col
                  sm="12"
                  md="3"
                  lg="3">
                  <ValidationProvider
                    :name="$t('Name')"
                    rules="required|min:1|max:60"
                    #default="{ errors }">
                    <b-form-group>
                      <label for="name"
                        >{{ $t('Name') }}
                        <small class="text-danger">*</small></label
                      >
                      <input
                        v-model="name"
                        class="form-control" />
                      <label
                        for="name"
                        v-if="errors.length > 0"
                        class="text-danger"
                        >* {{ errors[0] }}.</label
                      >
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col
                  sm="12"
                  md="3"
                  lg="3">
                  <ValidationProvider
                    :name="$t('Surname')"
                    rules="required|min:1|max:30"
                    #default="{ errors }">
                    <b-form-group>
                      <label for="surname"
                        >{{ $t('Surname') }}
                        <small class="text-danger">*</small></label
                      >
                      <input
                        v-model="surname"
                        class="form-control" />
                      <label
                        for="surname"
                        v-if="errors.length > 0"
                        class="text-danger"
                        >* {{ errors[0] }}.</label
                      >
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col
                  sm="12"
                  md="6"
                  lg="6">
                  <b-form-group>
                    <label for="phone"
                      >{{ $t('Mobile') }}
                      <small class="text-danger">*</small></label
                    >
                    <b-input-group>
                      <!-- <b-form-input v-model="phone" @input="address_phone = phone" class="input" v-mask="'+90 (###) ### ## ##'"></b-form-input> -->
                      <b-form-tags
                        v-model="phones"
                        no-outer-focus
                        class="mb-2"
                        id="form-tags">
                        <template
                          v-slot="{
                            tags,
                            inputAttrs,
                            inputHandlers,
                            tagVariant,
                            addTag,
                            removeTag
                          }">
                          <b-input-group class="mb-2">
                            <b-form-input
                              v-bind="inputAttrs"
                              v-on="inputHandlers"
                              :placeholder="$t('Enter for add new phone')"
                              class="form-control"
                              v-mask="'+90 (###) ### ## ##'"
                              @blur="addTag()"
                              ref="phone"></b-form-input>
                            <b-input-group-append>
                              <b-button
                                class="add-btn"
                                @click="addTag()"
                                variant="primary"
                                style="width: 30px !important">
                                <i class="fa-regular fa-plus"></i>
                              </b-button>
                            </b-input-group-append>
                          </b-input-group>
                          <div
                            class="d-inline-block"
                            style="font-size: 1.5rem">
                            <b-form-tag
                              v-for="tag in tags"
                              @remove="removeTag(tag)"
                              :key="tag"
                              :title="tag"
                              :variant="tagVariant"
                              class="mr-1"
                              >{{ tag }}
                            </b-form-tag>
                          </div>
                        </template>
                      </b-form-tags>
                    </b-input-group>
                  </b-form-group>
                </b-col>

                <b-col
                  sm="12"
                  md="6"
                  lg="6">
                  <ValidationProvider
                    :name="$t('E-Mail')"
                    rules="required|email|max:40"
                    #default="{ errors }">
                    <b-form-group>
                      <label for="email"
                        >{{ $t('E-Mail') }}
                        <small class="text-danger">*</small></label
                      >
                      <b-form-input v-model="email" />
                      <label
                        for="surname"
                        v-if="errors.length > 0"
                        class="text-danger"
                        >* {{ errors[0] }}.</label
                      >
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col
                  sm="12"
                  md="6"
                  lg="6">
                  <ValidationProvider
                    :name="$t('Company Title')"
                    rules="max:120"
                    #default="{ errors }">
                    <b-form-group>
                      <label for="company_title">{{
                        $t('Company Title')
                      }}</label>
                      <b-form-input v-model="company_title"></b-form-input>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col
                  sm="12"
                  md="6"
                  lg="6">
                  <ValidationProvider
                    :name="$t('Tax Administration')"
                    rules="max:60"
                    #default="{ errors }">
                    <b-form-group>
                      <label for="tax_office">{{
                        $t('Tax Administration')
                      }}</label>
                      <b-form-input v-model="tax_office"></b-form-input>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col
                  sm="12"
                  md="6"
                  lg="6">
                  <ValidationProvider
                    :name="$t('Tax Number')"
                    rules="max:20"
                    #default="{ errors }">
                    <b-form-group>
                      <label for="tax_office">{{ $t('Tax Number') }}</label>
                      <b-form-input
                        v-model="tax_number"
                        type="number"></b-form-input>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
              </div>
              <p class="customer-add-modal-title">
                {{ $t('Address Informations') }}
              </p>
              <div class="row">
                <b-col
                  sm="12"
                  md="4"
                  lg="4">
                  <b-form-group>
                    <label for="invoice_type_id">{{
                      $t('Address Type')
                    }}</label>
                    <b-form-select v-model="invoice_type_id">
                      <b-form-select-option
                        v-for="option in invoiceTypes"
                        :value="option.id"
                        :key="options.$index"
                        >{{ option.name }}</b-form-select-option
                      >
                    </b-form-select>
                  </b-form-group>
                </b-col>
                <b-col
                  sm="12"
                  md="4"
                  lg="4" />
                <b-col
                  sm="12"
                  md="4"
                  lg="4" />
                <b-col
                  sm="12"
                  md="4"
                  lg="4">
                  <b-form-group>
                    <label for="city">İl</label>
                    <b-form-select
                      v-model="city"
                      @change="
                        city
                          ? $store.dispatch('districts/fetchDistricts', city)
                          : ''
                      ">
                      <b-form-select-option :value="null">{{
                        $t('Choose')
                      }}</b-form-select-option>
                      <b-form-select-option
                        v-for="city in cities"
                        :key="city.$index"
                        :value="city.id"
                        >{{ city.name }}
                      </b-form-select-option>
                    </b-form-select>
                  </b-form-group>
                </b-col>

                <b-col
                  sm="12"
                  md="4"
                  lg="4">
                  <b-form-group>
                    <label for="district">{{ $t('District') }}</label>
                    <b-form-select
                      v-model="district"
                      @change="
                        district
                          ? $store.dispatch('areas/fetchAreas', district)
                          : ''
                      ">
                      <b-form-select-option :value="null"
                        >{{$t('Choose')}}</b-form-select-option
                      >
                      <b-form-select-option
                        v-for="district in $store.state.districts.districts"
                        :key="district.$index"
                        :value="district.id"
                        >{{ district.name }}
                      </b-form-select-option>
                    </b-form-select>
                  </b-form-group>
                </b-col>

                <b-col
                  sm="12"
                  md="4"
                  lg="4">
                  <b-form-group>
                    <label for="area"
                      >{{ $t('Neighborhood') }} / {{ $t('Street') }}</label
                    >
                    <b-form-select v-model="area">
                      <b-form-select-option :value="null">{{
                        $t('Choose')
                      }}</b-form-select-option>
                      <b-form-select-option
                        v-for="area in $store.state.areas.areas"
                        :key="area.$index"
                        :value="area.id"
                        >{{ area.name }}
                      </b-form-select-option>
                    </b-form-select>
                  </b-form-group>
                </b-col>

                <b-col
                  sm="12"
                  md="12"
                  lg="12">
                  <ValidationProvider
                    :name="$t('Address Definition')"
                    rules="max:200"
                    #default="{ errors }">
                    <b-form-group>
                      <label for="address">{{ $t('Address') }}</label>
                      <b-form-textarea
                        rows="3"
                        v-model="address" />
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
              </div>
              <div
                class="flex-center-space"
                id="modal-foot">
                <div></div>
                <div>
                  <button
                    class="modal-foot-deny"
                    @click="hideCustomerModal">
                    {{ $t('Skip') }}
                  </button>
                  <button
                    class="modal-foot-save"
                    @click="showAlert">
                    {{ $t('Save') }}
                  </button>
                </div>
              </div>
            </ValidationObserver>
          </b-modal>
          <!-- end of customer add modal -->
          <div class="row mt-4 mb-3">
            <div class="col-4">
              <ValidationProvider
                :name="$t('Start Date')"
                rules="required|max:20"
                #default="{ errors }">
                <b-form-group :label="$t('Start Date') + ' *'">
                  <b-form-datepicker
                    :labelNoDateSelected="$t('The date was not chosen.')"
                    labelHelp=""
                    v-model="start_date"
                    locale="tr"
                    :placeholder="$t('Choose')" />
                  <label
                    for="start_date"
                    v-if="errors.length > 0"
                    class="text-danger"
                    ><small>*</small>{{ errors[0] }}.</label
                  >
                </b-form-group>
              </ValidationProvider>
            </div>
            <div class="col-4">
              <ValidationProvider
                :name="$t('Start Hour')"
                rules="required|max:20"
                #default="{ errors }">
                <b-form-group :label="$t('Start Hour') + ' :'">
                  <b-form-timepicker
                    :labelNoTimeSelected="$t('The hour was not chosen.')"
                    :labelCloseButton="$t('Close')"
                    v-model="start_time"
                    locale="tr"
                    :placeholder="$t('Choose')"
                    style="min-height: 62px" />
                  <label
                    for="start_time"
                    v-if="errors.length > 0"
                    class="text-danger"
                    ><small>*</small>{{ errors[0] }}.</label
                  >
                </b-form-group>
              </ValidationProvider>
            </div>
          </div>

          <div class="row mt-3 mb-3">
            <div class="col-4">
              <b-form-group
                :label="$t('End Date') + ' *'"
                class="w-100">
                <ValidationProvider
                  :name="$t('End Date')"
                  rules="required|max:20"
                  #default="{ errors }">
                  <b-form-datepicker
                    :labelNoDateSelected="$t('The date was not chosen.')"
                    labelHelp=""
                    v-model="end_date"
                    locale="tr"
                    :placeholder="$t('Choose')" />
                  <label
                    for="end_date"
                    v-if="errors.length > 0"
                    class="text-danger"
                    ><small>*</small>{{ errors[0] }}.</label
                  >
                </ValidationProvider>
              </b-form-group>
            </div>
            <div class="col-4">
              <b-form-group
                :label="$t('End Hour') + ' *'"
                class="w-100 pl-2">
                <ValidationProvider
                  :name="$t('End Hour')"
                  rules="required|max:20"
                  #default="{ errors }">
                  <b-form-timepicker
                    :labelNoTimeSelected="$t('The hour was not chosen.')"
                    :labelCloseButton="$t('Close')"
                    v-model="end_time"
                    locale="tr"
                    :placeholder="$t('Choose')"
                    style="min-height: 62px"></b-form-timepicker>
                  <label
                    for="end_time"
                    v-if="errors.length > 0"
                    class="text-danger"
                    ><small>*</small>{{ errors[0] }}.</label
                  >
                </ValidationProvider>
              </b-form-group>
            </div>
          </div>
          <div>
            <b-form-group :label="$t('Title')">
              <ValidationProvider
                :name="$t('Title')"
                rules="required|max:20"
                #default="{ errors }">
                <b-form-input
                  type="text"
                  :placeholder="$t('Title')"
                  v-model="title"></b-form-input>
                <label
                  for="title"
                  v-if="errors.length > 0"
                  class="text-danger"
                  ><small>*</small>{{ errors[0] }}.</label
                >
              </ValidationProvider>
            </b-form-group>

            <b-form-group :label="$t('Note')">
              <b-form-textarea
                :placeholder="$t('Note')"
                v-model="description"
                rows="3"
                max-rows="6"></b-form-textarea>
            </b-form-group>
            <b-form-checkbox
              id="campaign-notification-checkbox"
              v-model="is_notify"
              switch>
              <span class="campaign-notification-checkbox-txt">{{
                $t('Be reminded of appointment information via SMS and E-Mail.')
              }}</span>
            </b-form-checkbox>
          </div>
          <div class="flex-center-space mt-4">
            <!-- <button class="empty-btn" @click="sale = true">
              <i class="fa-solid fa-plus"></i>
              Satış Ekle
            </button> -->
            <button
              class="servislet-black-btn"
              @click="addAppointment()">
              {{ $t('Save') }}
            </button>
          </div>
          <div
            class="mt-4"
            id="add-sale-wrapper"
            v-if="sale === true">
            <p class="page-title">{{ $t('Add Sale') }}</p>
            <div
              class="mt-3"
              style="max-width: 565px">
              <div class="add-invoice-customer-card">
                <div class="add-invoice-customer-info">
                  <i class="fa-solid fa-database"></i>
                  <div>
                    <p>İsmail Abi</p>
                    <p>0541 850 60 70</p>
                    <p>Servislet Yazılım A.Ş</p>
                  </div>
                </div>
                <div class="add-invoice-vehicle-info">
                  <i class="fa-solid fa-car"></i>
                  <div>
                    <p>06 SZM 685</p>
                    <p>Ferrari 2022 F8 Spyder</p>
                  </div>
                </div>
                <div
                  class="remove-add-invoice-customer-card"
                  @click="selected_customer = null">
                  <i class="fa-solid fa-close"></i>
                </div>
              </div>
            </div>
            <div class="flex-center-space mt-3">
              <b-form-group
                id="product-or-service-selector-wrapper"
                :label="$t('Choose product or service')">
                <b-form-select
                  v-model="selected"
                  :options="options"></b-form-select>
              </b-form-group>

              <b-form-group
                id="dynamic-unit-group"
                class="add-invoice-flex-amount-wrapper"
                :label="$t('Quantity')">
                <b-input-group>
                  <b-form-input value="10"></b-form-input>
                  <b-input-group-append>
                    <b-form-select
                      v-model="selected"
                      :options="unit"></b-form-select>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>

              <b-form-group
                id="dynamic-unit-group"
                class="add-invoice-flex-price-wrapper"
                :label="$t('Amount')">
                <b-input-group>
                  <b-form-input value="100,00"></b-form-input>
                  <b-input-group-append>
                    <b-form-select
                      v-model="selected"
                      :options="currency"></b-form-select>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>

              <b-form-group
                id="dynamic-unit-group"
                class="add-invoice-flex-vat-wrapper"
                :label="$t('VAT')">
                <b-input-group>
                  <b-form-input value="10"></b-form-input>
                  <b-input-group-append>
                    <b-form-select
                      v-model="selected"
                      :options="currency"></b-form-select>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>

              <b-form-group
                id="dynamic-unit-group"
                class="add-invoice-flex-total-wrapper"
                :label="$t('Total')">
                <b-input-group>
                  <b-form-input value="120,00"></b-form-input>
                  <b-input-group-append>
                    <b-form-select
                      v-model="selected"
                      :options="currency"></b-form-select>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </div>
            <div>
              <b-form-group :label="$t('Note')">
                <b-form-textarea
                  :placeholder="$t('Note')"
                  rows="3"
                  max-rows="6"></b-form-textarea>
              </b-form-group>
              <button class="servislet-black-btn mt-2">
                <i class="fa-solid fa-plus"></i>
                {{ $t('Product / Service Add') }}
              </button>
            </div>
            <div class="products-double-flex invoice-bill-section-wrapper mt-5">
              <div></div>
              <div class="invoice-bill-section">
                <div class="invoice-bill-item p-0">
                  <p>{{ $t('Sub Total') }}:</p>
                  <p>₺1.800</p>
                </div>
                <div
                  class="invoice-bill-item p-0"
                  v-if="discount === true">
                  <p>{{ $t('Sub Total Discount') }}:</p>
                  <div class="flex-center-space">
                    <b-input-group style="max-width: 150px">
                      <b-form-input value="20,00"></b-form-input>
                      <b-input-group-append>
                        <b-form-select
                          v-model="selected"
                          :options="discount_unit"></b-form-select>
                      </b-input-group-append>
                    </b-input-group>
                    <button @click="discount = false">
                      <i class="fa-solid fa-close"></i>
                    </button>
                  </div>
                </div>
                <div class="invoice-bill-item p-0">
                  <p>{{ $t('VAT') }}:</p>
                  <p>₺1.000</p>
                </div>
                <div class="invoice-bill-item invoice-bill-item-total">
                  <p>{{ $t('Total') }}:</p>
                  <p>₺2.000</p>
                </div>
              </div>
            </div>

            <div>
              <b-form-group :label="$t('Note') + ' :'">
                <b-form-textarea
                  value="Thanks for your business"
                  rows="1"
                  max-rows="6"></b-form-textarea>
              </b-form-group>
            </div>
          </div>
        </div>
      </div>
    </div>
  </DefaultLayout>
</template>

<script>
/* eslint-disable */
import DefaultLayout from '@/layouts/default';
import PageBreadcrumbs from '../../components/PageBreadcrumbs.vue';
import VueClickOutside from 'vue-click-outside';

export default {
  components: {
    PageBreadcrumbs,
    DefaultLayout
  },
  data() {
    return {
      breadcrumbs: [
        {
          name: this.$i18n.t('Calendar'),
          path: '/calendar'
        },
        {
          name: this.$i18n.t('Add New'),
          path: '/#'
        }
      ],
      selected: null,
      selected_customer: null,
      customers: [
        {
          value: null,
          text: this.$i18n.t('Choose Customer Information'),
          disabled: true
        },
        { value: 1, text: this.$i18n.t('Corporate Customer') + ' X' },
        { value: 2, text: this.$i18n.t('Individual Customer') + ' X' }
      ],
      options: [
        { value: null, text: this.$i18n.t('Completed'), disabled: true }
      ],
      sale: false,
      unit: [{ value: null, text: '| adet', disabled: true }],
      currency: [
        { value: null, text: '₺', disabled: true },
        ,
        { value: null, text: '$', disabled: true }
      ],
      second_number: false,
      third_number: false,
      customer: {
        box: false,
        searchArea: '',
        searchResult: [],
        car: {},
        info: {}
      },
      money: {
        decimal: ',',
        thousands: '.',
        prefix: '',
        suffix: '',
        precision: 2
      },
      title: '',
      description: '',
      is_notify: false,
      start_date: '',
      end_date: '',
      start_time: '',
      end_time: '',
      customerType: null,
      name: null,
      surname: null,
      phones: [],
      email: null,
      company_title: null,
      tax_office: null,
      tax_number: null,
      city: null,
      district: null,
      area: null,
      address: null,
      added_customer: false,
      invoice_type_id: null,
      added_customer_id: null
    };
  },
  directives: {
    VueClickOutside
  },
  computed: {
    customerTypes() {
      if (this.$store.state.customerTypes.customerTypes.length < 1) {
        this.$store.dispatch('customerTypes/fetchCustomerTypes');
      } else {
        this.customerType = this.$store.state.customerTypes.customerTypes[0].id;
      }
      return this.$store.state.customerTypes.customerTypes;
    },
    cities() {
      if (this.$store.state.cities.cities.length < 1) {
        this.$store.dispatch('cities/fetchCities');
      }
      return this.$store.state.cities.cities;
    },
    invoiceTypes() {
      if (this.$store.state.invoiceTypes.invoiceTypes.length < 1) {
        this.$store.dispatch('invoiceTypes/fetchInvoiceTypes');
      } else {
        this.invoice_type_id =
          this.$store.state.invoiceTypes.invoiceTypes[0].id;
      }
      return this.$store.state.invoiceTypes.invoiceTypes;
    }
  },
  mounted() {
    const startDateString = this.$store.state.selectedDate.selectedDates[0];
    const start_date = new Date(startDateString);

    const start_year = start_date.getFullYear();
    const start_month = String(start_date.getMonth() + 1).padStart(2, '0'); // months are zero-based
    const start_day = String(start_date.getDate()).padStart(2, '0');
    // const start_hours = String(start_date.getHours()).padStart(2, '0');
    // const start_minutes = String(start_date.getMinutes()).padStart(2, '0');
    // const start_seconds = String(start_date.getSeconds()).padStart(2, '0');

    this.start_date = `${start_year}-${start_month}-${start_day}`;

    const endDateString = (this.end_date =
      this.$store.state.selectedDate.selectedDates[1]);
    const end_date = new Date(endDateString);

    const end_year = end_date.getFullYear();
    const end_month = String(end_date.getMonth() + 1).padStart(2, '0');
    const end_day = String(end_date.getDate()).padStart(2, '0');

    this.end_date = `${end_year}-${end_month}-${end_day}`;
  },
  methods: {
    hideCustomerModal() {
      this.$bvModal.hide('customer-add-modal');
    },
    setCustomerSearch(customer) {
      this.$store
        .dispatch('sales/searchCustomerCar', customer.id)
        .then((response) => {
          if (response) {
            this.customer.car = response;
          }
          this.customer.info = customer;
          this.customer.box = false;
          this.added_customer_id = customer.id;
        });
    },
    openCustomerBox() {
      this.customer.box = !this.customer.box;
    },
    searchInputCustomer() {
      if (this.customer.searchArea === 0) {
        this.customer.searchResult = [];
      }
      if (this.customer.searchArea.length > 2) {
        this.customer.loading = true;
        this.$store
          .dispatch(`sales/searchCustomer`, this.customer.searchArea)
          .then((response) => {
            this.customer.searchResult = response;
            this.customer.loading = false;
          });
      }
    },
    showAlert() {
      this.$refs.customerAddModalRules.validate().then((success) => {
        if (success) {
          let p = [];

          for (let phone in this.phones) {
            p.push({
              is_default: false,
              phone: this.phones[phone]
            });
          }
          p[0].is_default = true;

          this.$axios
            .post(`/customers`, {
              customer: {
                name: this.name,
                surname: this.surname,
                email: this.email,
                customer_type_id: this.customerType
              },
              phone: p,
              address: {
                is_active: true,
                is_default: true,
                invoice_type_id: this.invoice_type_id,
                city_id: this.city,
                district_id: this.district,
                area_id: this.area,
                address: this.address,
                phone: this.phones[0]
              }
            })
            .then((response) => {
              if (response.data.status) {
                this.added_customer_id = response.data.responseData.customer_id;
                this.added_customer = true;
                this.$refs['customer-add-modal'].hide();
              }
            })
            .catch((error) => {
              var message = error.response.data.responseData.error
                ? error.response.data.responseData.error[0].message
                : error.response.data.responseData.message;
              this.$swal.fire({
                iconHtml: '<img src="/img/error.svg" width="32" height="32">',
                position: 'top-end',
                title: message,
                showConfirmButton: false,
                timer: 2500,
                toast: true,
                customClass: {
                  icon: 'swal2-no-border'
                }
              });
            });
        } else {
          this.$swal.fire({
            iconHtml: '<img src="/img/warning.png" width="32" height="32">',
            position: 'top-end',
            title: this.$i18n.t('Please check the fields.'),
            showConfirmButton: false,
            timer: 2500,
            toast: true,
            customClass: {
              icon: 'swal2-no-border'
            }
          });
        }
      });
    },
    removeCustomer() {
      this.customer = {
        box: false,
        searchArea: '',
        searchResult: [],
        car: {},
        info: {}
      };
    },
    addAppointment() {
      var startTime = '';
      var endTime = '';
      if (this.start_time) {
        startTime = this.start_time;
      } else {
        startTime = '00:00:00';
      }
      if (this.end_time) {
        endTime = this.end_time;
      } else {
        endTime = '00:00:00';
      }
      this.$axios.post('/events', {
          customer_id: this.added_customer_id,
          // user_id: this.added_customer_id,
          app_type_id: 1,
          // module_id: "dolorem",
          title: this.title,
          description: this.description,
          is_notify: this.is_notify,
          // latitude: "sequi",
          // longitude: "debitis",
          start_date: this.start_date + ' ' + startTime,
          end_date: this.end_date + ' ' + endTime
          // referer_id: "perspiciatis"
        })
        .then(response => {
          if(response.data.status){
            this.$swal.fire({
              iconHtml: '<img src="/img/success.svg" width="32" height="32">',
              position: 'top-end',
              title: this.$i18n.t('Date successfully saved') + '!',
              showConfirmButton: false,
              timer: 2500,
              toast: true,
              customClass: {
                icon: 'swal2-no-border'
              }
            });
            this.$router.push('/calendar');
          }
        }).catch(error=> {
          var message = error.response.data.responseData.error
            ? error.response.data.responseData.error[0].message
            : error.response.data.responseData.message;
          this.$swal.fire({
            iconHtml: '<img src="/img/error.svg" width="32" height="32">',
            position: 'top-end',
            title: message,
            showConfirmButton: false,
            timer: 2500,
            toast: true,
            customClass: {
              icon: 'swal2-no-border'
            }
          });
        });
    }
  }
};
</script>

<style>
#add-appointment-wrapper .b-form-timepicker {
  height: auto !important;
  align-items: center !important;
  max-height: 30px !important;
}
</style>

<style scoped>
.page-txt {
  visibility: hidden;
}

.choose-product-container {
  position: relative;
}

.choose-product {
  border: 1px solid #dee2e6;
  padding: 0.65rem 0.85rem !important;
  border-radius: 5px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 8px;
  background-color: #f4f4f4;
  cursor: pointer;
  min-width: 400px;
  max-width: 400px;
}

.choose-product-label {
  font-size: 14px;
  color: #6e6b7b !important;
  font-style: normal;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.icon-with-name {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.choose-product-list {
  position: absolute;
  top: 40px;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  border: 1px solid #dee2e6;
  background-color: #f4f4f4;
  border-top: none;
  z-index: 1;
  border-radius: 5px;
}

.choose-product-list-item {
  color: #6e6b7b !important;
  border-top: 1px solid #dee2e6;
  padding: 0.65rem 0.85rem;
  font-size: 14px;
  cursor: pointer;
}

.choose-product-list-item:hover {
  background-color: #dadada;
  cursor: pointer;
}

.choose-product-input {
  width: 92%;
}

.input-group-text {
  background-color: #f4f4f4;
  border-left: 1px solid #dee2e6 !important;
}

.choose-product-input-area {
  position: relative;
}

.input-spinner {
  position: absolute;
  right: 26px;
  top: 27px;
}

#services-lines {
  border-bottom: 1px solid #dee2e6;
  margin-bottom: 20px;
}

#services-lines .choose-product {
  max-width: 359px;
  min-width: 359px;
}

.isDesc {
  border-bottom: 1px solid #dee2e6 !important;
  width: 100%;
  max-width: 100% !important;
  margin-bottom: 20px;
}

.choose-product-list-item-info {
  display: flex;
  flex-direction: column;
  border-top: 1px solid #dee2e6;
  padding-top: 5px;
  margin-top: 5px;
}

.choose-product-list-item-name {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.customer-name {
  display: flex;
  gap: 5px;
  flex-direction: column;
}

.customer-div {
  height: auto;
  width: auto !important;
}

.customer-name-name {
  font-size: 20px;
}

.customer-name-info {
  font-size: 12px;
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.customer-info {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  position: relative;
}

.customer-div .choose-product-label {
  width: 1000%;
}

.choose-product.customer-div {
  max-width: 500px;
  min-width: 500px;
}

.discount-line {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.add-invoice-flex-total-wrapper {
  flex: 1;
}

.flex-start-space {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

#form-tags .add-btn {
  transition: 0.5s;
  background: #f9f9f9;
  color: #d3d3d3;
  border: 1px solid #d3d3d3;
  outline: none;
  font-weight: 500;
}

#form-tags .add-btn:hover {
  transition: 0.5s;
  box-shadow: rgba(28, 28, 28, 0.2) 0px 8px 30px 0px;
  background: var(--servislet-black);
  color: var(--servislet-white);
  border: 1px solid var(--servislet-black);
}
.add-appointment-wrapper .title {
  font-size: 20px;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0px;
  text-align: left;
}
.added-customer-card {
  border: 1px solid #dee2e6;
  padding: 0.85rem !important;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  background-color: #f4f4f4;
  cursor: pointer;
  width: 400px;
}
.added-customer-info-content {
  display: flex;
  align-items: flex-start;
}
.added-customer-info-content .customer-name,
.added-customer-info-content .phone {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  color: #1c1c1c;
}
</style>

<style>
#customer-add-modal___BV_modal_body_ {
  padding: 2rem;
}
</style>
