<template>
  <div>
    <div class="component-container" v-click-outside="close">
      <div :class="show ? 'opened-border' : 'closed-border'" class="component__select cursor-pointer" @click="show = !show">
        <span v-if="selected_array.length>0" class="component__select--name">{{$t('Selected warehouses')}}:
          <span class="array-length">
            {{selected_array.length}}
          </span>
        </span>
        <span v-else class="component__select--name">{{$t('Choose')}}</span>

        <div>
          <i v-if="selected_array.length>0 && show" class="fa-solid fa-xmark mr-2" @click="selected_array = [];"></i>
          <i v-if="!show" class="fa-solid fa-chevron-down cursor-pointer"></i>
          <i v-if="show" class="fa-solid fa-chevron-up cursor-pointer"></i>
        </div>
      </div>

      <div v-if="show" class="component__select-options">
        <div class="px-3">
          <b-form-input class="bg-transparent" :placeholder="$t('Search')" v-model="search" />
        </div>

      <div class="scroll-area">
        <div v-for="option in list" class="select--option" :class="{ 'active': selected_array.includes(option)}" @click="selectOption(option)">
          <div>{{ option.name }}</div>
          <i class="fa-solid fa-check" v-if="selected_array.includes(option)"></i>
        </div>
      </div>

        <div class="no-option" v-if="list.length === 0">
          {{$t('No result.')}}..
        </div>
      </div>
    </div>


  </div>
</template>

<script>
import vClickOutside from "v-click-outside";

export default {
  name: 'MultipleWarehouseSelect',
  directives: {
    clickOutside: vClickOutside.directive
  },
  data() {
    return {
      selected: {},
      show: false,
      selected_array:[],
      search: ''
    };
  },
  props:{
    options:{
      type: Array,
      default: () => []
    }
  },
  computed:{
    list(){
      return this.search ? this.options.filter((item) => item.name.toUpperCase().includes(this.search.toUpperCase())) : this.options;
    }
  },
  mounted(){},
  methods:{
    close(){
      this.show = false
    },
    selectOption(option){
      if(this.selected_array.includes(option)){
        this.selected_array.splice(this.selected_array.indexOf(option),1)
      }else{
        this.selected_array.push(option)
      }
      this.$emit('setWarehouseId',this.selected_array)
      console.log(this.selected_array)
    }
  }
};
</script>

<style scoped>
.component__select {
  height: 40px;
  line-height: 38px;
  width: 100%;
  grid-template-columns: 10fr 1fr;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  background-color: #fff;
  border: 1px solid #6D6E6F66;
  padding: 1.5rem 1rem;
}

.component__select--name {
  font-size: 13px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: .5rem;
}

.component__select-options {
//position: absolute;
//z-index: 1500;
  width: 100%;
  margin: 0;
  padding: 0;
  border: 1px solid #6D6E6F66;
  border-top: none;
  background-color: #fff;
  border-radius: 0 0 5px 5px;
}

.select--option {
  height: 38px;
  display: flex;
  font-size: 13px;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  background-color: #fff;
  cursor: pointer;
  margin: 2px 0;
}

.select--option:hover, .select--option.active {
  background: #06A5D01F;
  color: #06a5d0;
}

.select--option:last-child {
  border-bottom: none;
  border-radius: 0 0 5px 5px;
}

.select--option input {
  display: none;
}

.component__select i {
  font-size: 12px;
  cursor: pointer;
}
.border-top-first{
  border-top: 1px solid #404040;
}
.closed-border{
  border-radius: 5px;
}

.opened-border{
  border-radius: 5px 5px 0 0;
  border-bottom: none;
}
.no-option{
  height: 38px;
  display: flex;
  font-size: 13px;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  background-color: #fff;
}
.scroll-area{
  max-height: 170px;
  overflow: scroll;
}
.scroll-area::-webkit-scrollbar{
  display: none;
}
.array-length{
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #06A5D01F;
  color: #06a5d0;
  border-radius: 50px;
  font-size: 13px;
  font-weight: 500;
}
</style>
