<template>
  <div id="top-bar-wrapper">
    <div id="top-bar">
      <div class="left">
        <b-input-group>
          <template #prepend>
            <div class="prepend">
              <i class="fa-regular fa-plus"></i>
            </div>
          </template>
          <b-form-select v-model="selected"
            @change="routerWizar()">
            <b-form-select-option :value="null">{{$t('Add New')}}</b-form-select-option>
            <b-form-select-option value="/add-sale">{{$t('Sale')}}</b-form-select-option>
            <b-form-select-option value="/add-appointment">{{$t('Appointment')}}</b-form-select-option>
            <b-form-select-option value="/customer-add">{{$t('Customer')}}</b-form-select-option>
            <b-form-select-option value="/car-add">{{$t('Car')}}</b-form-select-option>
            <b-form-select-option value="/product-add">{{$t('Product')}}</b-form-select-option>
          </b-form-select>
          <template #append>
            <div class="append">
              <i class="fa-regular fa-angle-down"></i>
            </div>
          </template>
        </b-input-group>
      </div>
     <div class="d-flex align-items-center">
       <img :src="asset" height="22" v-if="asset !== '1'">
       <div class="right" style="border-left: none; padding-right: 12px;">
         <div class="lang-container">
          <span
              class="mr-12 fw-bold cursor-pointer"
              @click="localeDrop = !localeDrop"
          >{{ $i18n.locale.toUpperCase() }}</span
          >
           <div
               v-if="localeDrop"
               class="drop-div-lang">
             <p
                 v-for="locale in locales"
                 :key="locale.$key"
                 class="mb-1 cursor-pointer"
                 @click="setLocale(locale)">
               {{ locale.toUpperCase() }}
             </p>
           </div>
         </div>
         <span style="margin-right: 12px;">
           <setting />
         </span>
<!--         <img-->
<!--             class="qr-img"-->
<!--             style="margin-right: 12px;"-->
<!--             src="../assets/images/settings.png"-->
<!--             />-->
         <span v-b-modal.customer-qr-modal-2 @click="getQr()">
           <qr />
         </span>
       </div>
       <div class="right">
         <i class="fa-regular fa-arrow-down-to-bracket" :class="$store.state.topbar.file_count ? 'cursor-pointer' : ''" :style="`color: ${$store.state.topbar.file_count >0 ? color : ''}` " @click="$router.push('/downloaded-files')" style="font-size: 19px; margin-bottom: 3px;"></i>
         <i class="fa-regular fa-envelope"></i>
         <i
             v-b-toggle.notifications-sidebar
             class="fa-regular fa-bell">
           <span v-if="$store.state.topbar.count > -1">{{$store.state.topbar.count > 9 ? '9+' : $store.state.topbar.count}}</span>
         </i>

         <img
             class="avatar cursor-pointer"
             :src="icon"
             @click="drop = !drop"
            @mouseover="drop = true;"
         />
         <span class="online-dot" />
         <div
             v-click-outside="closeMenu"
             v-if="drop"
             class="drop-div">
             <router-link
                 to="/profile" class="mb-0 px-2">
               <div class="d-flex align-items-center gap-2">
                 <i class="fa-regular fa-user p-0" style="font-size: 14px;"></i> {{ $t('Profile') }}
               </div>
             </router-link>
           <hr class="m-0">
           <p class="mb-0 px-2" @click="logout()">
             <span class="d-flex align-items-center gap-2">
               <i class="fa-regular fa-right-from-bracket p-0" style="font-size: 14px;"></i> {{ $t('Logout') }}
             </span>
           </p>
         </div>
       </div>
     </div>
    </div>
    <b-sidebar
      id="notifications-sidebar"
      :backdrop-variant="variant"
      backdrop
      no-header
      right
      shadow>
      <div class="px-4 py-3">
        <p class="noti-sidebar-header-title">{{$t('Notifications')}}</p>
        <div v-if="!loader">
          <div
            v-for="noti in activities.items"
            :key="noti.$index"
            class="single-noti-item">
            <span class="single-noti-item-span-sale">
              <i class="fa-solid fa-shopping-basket"></i>
            </span>
            <div class="single-noti-item-info-div">
              <p class="single-noti-item-info">{{ noti.title }}</p>
              <p class="single-noti-item-info-detail">{{ noti.created_at }}</p>
            </div>
          </div>
        </div>
        <div v-else>
          <content-placeholders :rounded="true">
            <content-placeholders-text :lines="10" />
          </content-placeholders>
        </div>
        <p class="noti-sidebar-header-title mt-3">{{$t('Activities')}}</p>
      </div>
    </b-sidebar>

    <!-- modal -->
    <b-modal
      id="customer-qr-modal-2"
      centered
      hide-footer
      hide-header
      size="md">
      <p class="customer-add-modal-title">{{$t('Read QR')}}</p>
      <p class="customer-add-modal-text">
        {{$t('Scan the QR code from the phone of the customer you want to add.')}}
      </p>
      <img
        v-if="qr_img"
        :src="qr_img" />
    </b-modal>
  </div>
</template>

<script>
import vClickOutside from 'v-click-outside';
import Qr from "@/components/topbar/Qr.vue";
import Setting from "@/components/topbar/Setting.vue";
export default {
  data() {
    return {
      variant: 'dark',
      selected: null,
      qr_img: '',
      activities: {},
      drop: false,
      localeDrop: false,
      currentPage: 1,
      loader: true,
      count: -1
    };
  },
  components:{
    Qr,
    Setting
  },
  mounted() {
    this.getActivities();
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  computed: {
    locales() {
      var _this = this;
      var locales = [];
      this.$i18n.availableLocales.find((locale) => {
        if (locale !== _this.$i18n.locale) {
          locales.push(locale);
        }
      });
      console.log(locales);
      return locales;
    },
    icon(){
      return localStorage.getItem('account_icon');
    },
    asset(){
      return localStorage.getItem('account_asset')
    },
    color(){
      return localStorage.getItem('account_text_color')
    }
  },
  methods: {
    setLocale(locale) {
      window.location.reload();
      localStorage.setItem('locale', locale);
      // this.$i18n.locale = locale;
      this.localeDrop = false;
    },
    logout() {
      localStorage.clear();
      this.$router.push('/login');
    },
    getActivities() {
      this.loader = true;
      this.$axios
        .get(`/merchants/activities`, {
          params: {
            page: 1,
            limit: 6
          }
        })
        .then((response) => {
          this.activities = response.data.responseData;
          this.$store.dispatch('topbar/getCount').then(response=>{
            if(response){
              this.loader = false;
            }
          })
          // this.$axios.get('/merchants/activities/count').then((response) => {
          //   this.count = response.data.responseData.count;
          //
          // });
        })
        .catch((error) => {
          console.error(error);
        });
    },
    routerWizar() {
      if (this.selected !== null && this.$route.path !== this.selected) {
        this.$router.push(this.selected);
      }
    },
    getQr() {
      var self = this;
      this.$axios
        .post('/customers/create-by-qr', {})
        .then(function (response) {
          self.qr_img =
            'data:image/svg+xml;base64,' + response.data.responseData.qrCode;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    closeMenu(){
      this.drop = false
    }
  }
};
</script>

<style scoped>
.append {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #1c1c1c;
  color: #f4f4f4;
  padding-right: 0.5rem;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.prepend {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #1c1c1c;
  color: #f4f4f4;
  padding-left: 0.5rem;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

#top-bar {
  position: relative;
}

.lang-container {
  position: relative;
}

.drop-div-lang {
  position: absolute;
  background: #fff;
  padding: 1rem;
  font-size: 12px;
  z-index: 99;
  width: 60px;
  border-radius: 5px;
  right: 0;
}

.drop-div {
  position: absolute;
  right: 24px;
  bottom: -80px;
  background: #fff;
  padding: 12px 8px;
  font-size: 12px;
  border-radius: 5px;
  width: 100%;
  max-width: 120px;
  transition: 0.5s;
  z-index: 9999;
  box-shadow: -2px 2px 10px 0px #00000033;
  display: flex;
  flex-direction: column;
  gap: .5rem;
}

.drop-div a,
.drop-div p {
  transition: 0.5s;
  text-decoration: none;
  cursor: pointer;
  font-size: 14px !important;
  font-weight: 500;
  color: #323338;
}

.drop-div a:hover,
.drop-div p:hover {
  transition: 0.3s;
  color: #06a5d0;
}

.online-dot{
  position: absolute;
  width: 12px;
  height: 12px;
  background-color: #28C76F;
  border: 2px solid #f9f9f9;
  border-radius: 50px;
  right: 24px;
  bottom: 12px;
  display: flex;
  flex-direction: column;
  gap: 0;
}
.first-p{
  border-bottom: 1px solid #6D6E6F33;
}
hr{
  border-top: 2px solid #b8b8b8;
}
</style>
