import Vue from 'vue';
import Vuex from 'vuex';
import axiosInstance from '@/plugins/axios';
import swal from 'vue-sweetalert2';
Vue.use(Vuex);

const state = {
  list: {
    items: [],
    pagination: {},
    loading: true
  },
  item: {
    sale: {},
    loading: true
  },
  addUpdateItem: {
    step: 1,
    order_items: [
      {
        tire_stock_id: '',
        quantity: '1',
        maxQuantity: 4,
        price: '',
        name: '',
        tax_rate: '20',
        description: '',
        isDesc: false,
        total: '',
        openProductBox: false,
        searchArea: '',
        searchResult: [],
        loading: false,
        afterSearch: false,
        errorMessage: '',
        services: [],
        discounts: [],
        radius: '',
        tire_brand_id: ''
      }
    ],
    order_discounts: [],
    emails: [
      {
        email: ''
      }
    ],
    phones: [
      {
        phone: ''
      }
    ],
    customer: {
      box: false,
      searchArea: '',
      searchResult: [],
      car: {},
      info: {},
      notes: ''
    },
    cargo_delivery: '',
    mounting_delivery: '',
    pay_on_online: '',
    pay_on_appointment: '',
    total_cargo: '',
    expiration_at: ''
  }
};

const mutations = {
  setOffersListItem(state, payload) {
    state.list.items = payload;
  },
  setOffersListPagination(state, payload) {
    state.list.pagination = payload;
  },
  setOffersListLoading(state, payload) {
    state.list.loading = payload;
  },
  setStep(state, payload) {
    state.addUpdateItem.step = payload;
  },
  setCustomer(state, payload) {
    state.addUpdateItem.customer = payload;
  },
  setAddUpdateItem(state, payload) {
    state.addUpdateItem = payload;
  }
};

const actions = {
  getOffersItem({ commit }, data) {
    commit('setOffersListLoading', true);
    axiosInstance
      .get(`/tire/offers`, {
        params: {
          limit: data.limit,
          page: data.page,
          search: data.search
        }
      })
      .then((response) => {
        if (response.data.status) {
          commit(
            'setOffersListItem',
            response.data.responseData.tireOffers.items
          );
          commit(
            'setOffersListPagination',
            response.data.responseData.tireOffers.pagination
          );
          commit('setOffersListLoading', false);
        }
      });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
