export default {
  currenctFormat(locale, price, currency = 'TRY') {
    return new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: currency
    }).format(price);
  },

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  },
  slugify(text) {
    if (text) {
      const a = 'çÇğĞşŞüÜıİöÖ';
      const b = 'ccggssuuiioo';
      const p = new RegExp(a.split('').join('|'), 'g');

      return text
        .toString()
        .toLowerCase()
        .replace(/[\s_]+/g, '-')
        .replace(p, (c) => b.charAt(a.indexOf(c)))
        .replace(/[^\w-]+/g, '')
        .replace(/--+/g, '-')
        .replace(/^-+|-+$/g, '');
    }
  },
  dateFormat(string){
    if(string){
      const datePart = string.split(' ')[0];

      // Split the date into day, month, year
      const [day, month, year] = datePart.split('-');

      // Format the new date string
      const formattedDate = `${day}/${month}/${year}`;

      return formattedDate;
    }
  },
  dateTimeFormat(string){
    if(string){
      const [datePart, timePart] = string.split(' ');

      // Split the date into day, month, year
      const [day, month, year] = datePart.split('-');

      // Format the new date string
      const formattedDate = `${day}/${month}/${year} ${timePart.slice(0, 5)}`;

      return formattedDate;
    }
  },
  mask(text) {
    const cleanedNumber = text.toUpperCase();
    const formattedNumber = cleanedNumber.replace(/^(\d{2})(\s?[A-Z]{1,3})\s?(\d{1,2})$/, '$1 $2 $3');
    return formattedNumber
  },
  spliceDatePartFromDate(text){
    if(text){
      var months = [this.$i18n.t('January'),this.$i18n.t('February'),this.$i18n.t('March'),this.$i18n.t('April'),this.$i18n.t('May'),this.$i18n.t('June'),this.$i18n.t('July'),this.$i18n.t('August'),this.$i18n.t('September'),this.$i18n.t('October'),this.$i18n.t('November'),this.$i18n.t('December')]

      var d = text.split(' ')[0]
      var day = d.split('-')[0]
      var month = d.split('-')[1]
      return day+ ' '+ months[parseInt(month)-1]
      //retun 28 Ocak format
    }
  }
};
