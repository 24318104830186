<template>
  <div>
    <div
      class="row w-100"
      v-if="!$store.state.offer.loading && Object.keys(offer).length > 0">
      <div class="d-sm-none d-md-none d-lg-block col-lg-3"></div>
      <div class="col-sm-12 col-md-12 col-lg-6 mt-3 offer-content">
        <img
          alt="Servislet Pro"
          class="logo pr-0"
          src="../../../assets/images/logo.png" />
        <div class="steps">
          <div class="step1">{{$t('Offer')}}</div>
          <div
            class="step2"
            @click="
              $router.push(`/customer/offer/delivery/${$route.params.token}`)
            ">
            {{$t('Delivery')}}
          </div>
          <div
            class="step3"
            @click="
              $router.push(`/customer/offer/payment/${$route.params.token}`)
            ">
            {{$t('Payment')}}
          </div>
        </div>
        <div class="mt-4 content-card centered-content">
          <div class="image-offer-id">
            <div>
              <img
                :src="offer.branchGroup.asset_path"
                width="auto"
                height="50" />
            </div>
            <div class="offer-id">{{$t('Offer').toLocaleUpperCase()}} #{{ offer.id }}</div>
          </div>

          <div class="d-flex justify-content-between mt-3">
            <div></div>
            <div class="name-address-email">
              <span class="name">Özkan Lastik</span>
              <span class="address">
                Koru Mah. Ahmet Taner Kışlalı Cad. North Star Plaza No:18
                Çankaya/Ankara
              </span>
              <span class="email-phone">
                <span>ozkanlastik@gmail.com</span> |
                <span>+90 541 250 31 45</span>
              </span>
            </div>
          </div>

          <div>
            <div class="d-flex justify-content-between">
              <div class="customer-name">
                {{ offer.customer.name }} {{ offer.customer.surname }}
              </div>
              <div></div>
            </div>

            <div class="d-flex justify-content-between align-items-end">
              <div class="customer-address-text mt-2">
                <div
                  class="d-flex"
                  v-if="offer.customer.address">
                  <span>{{ offer.customer.address.address }}</span>
                  <span
                    class="ml-1"
                    v-if="offer.customer.address.district"
                    >{{ ' ' + offer.customer.address.district.name }}</span
                  >
                  <span
                    class="ml-1"
                    v-if="offer.customer.address.city"
                    >{{ ' / ' + offer.customer.address.city.name }}</span
                  >
                </div>
              </div>
              <div class="d-flex justify-content-between w-35">
                <div class="left-text">{{$t('Offer No')}}:</div>
                <div class="right-text">#{{ offer.id }}</div>
              </div>
            </div>

            <div class="d-flex justify-content-between align-items-center mt-2">
              <div class="customer-address-text">
                {{ offer.customer.email }} | {{ offer.customer.phone }}
              </div>
              <div class="d-flex justify-content-between w-35">
                <div class="left-text">{{$t('Offer Date')}}:</div>
                <div class="right-text">
                  {{ offer.offer_date ? offer.offer_date : '-' }}
                </div>
              </div>
            </div>

            <div class="d-flex justify-content-between align-items-center mt-2">
              <div></div>
              <div class="d-flex justify-content-between w-35">
                <div class="left-text">{{$t('Offer End Date')}}:</div>
                <div class="right-text">
                  {{
                    offer.expiration_at
                      ? offer.expiration_at.substring(0, 16)
                      : '-'
                  }}
                </div>
              </div>
            </div>

            <div class="mt-5 table-responsive">
              <table class="table table-sm">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      class="text-left">
                      {{$t('Product/Service')}}
                    </th>
                    <th
                      scope="col"
                      class="text-center">
                      {{$t('Quantity')}}
                    </th>
                    <th
                      scope="col"
                      class="text-center">
                      {{$t('Amount')}}
                    </th>
                    <th
                      scope="col"
                      class="text-center">
                      {{$t('VAT')}}
                    </th>
                    <th
                      scope="col"
                      class="text-right">
                      {{$t('Total')}}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="item in offer.items"
                    :key="item.$index">
                    <td class="text-left">{{ item.name }}</td>
                    <td class="text-center">{{ item.quantity }}</td>
                    <td class="text-center">{{ item.price }}</td>
                    <td class="text-center">{{ item.discount_amount }}</td>
                    <td class="text-center">{{ item.tax_amount }}</td>
                    <td class="text-right">{{ item.amount }}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="mt-3 d-flex justify-content-between">
              <div></div>
              <div class="summary-card">
                <div class="summary-card-item">
                  <div class="left-text">{{$t('Sub Total')}}:</div>
                  <div class="right-text">{{ offer.total_price }}</div>
                </div>
                <div class="summary-card-item mt-2">
                  <div class="left-text">{{$t('VAT')}}:</div>
                  <div class="right-text">{{ offer.total_tax }}</div>
                </div>
                <div class="summary-card-item mt-2">
                  <div class="left-text">{{$t('Gross Discount')}}:</div>
                  <div class="right-text">{{ offer.total_discount }}</div>
                </div>
                <div class="summary-card-item mt-2">
                  <div class="left-text">{{$t('Cargo Price')}}:</div>
                  <div class="right-text">{{ offer.total_cargo }}</div>
                </div>
                <div class="summary-card-item mt-4">
                  <div class="left-text fw-700">{{$t('General Total')}}:</div>
                  <div class="right-text fw-700">{{ offer.total_amount }}</div>
                </div>
              </div>
            </div>

            <div class="mt-5 d-flex justify-content-between align-baseline">
              <div class="left-infos w-50 pt-0">
                <div
                  v-if="offer.cargo_delivery"
                  class="mb-3">
                  <div class="title">{{$t('Delivery')}}:</div>
                  <div class="content">{{$t('Delivery by cargo')}}</div>
                </div>

                <!--              <div class="title">Ödeme Yöntemi:</div>-->
                <!--              <div class="content">Banka / Kredi Kartı</div>-->
              </div>
              <div
                class="right-infos w-50 pt-0"
                v-if="offer.desription">
                <div class="title">{{$t('Notes')}}:</div>
                <div class="content">{{ offer.description }}</div>
              </div>
            </div>
          </div>

          <div class="mt-5 d-flex justify-content-end">
            <button
              class="btn continue-btn"
              @click="
                $router.push(`/customer/offer/delivery/${$route.params.token}`)
              ">
              {{$t('Continue')}}
            </button>
          </div>
        </div>
      </div>
      <div class="d-sm-none d-md-none d-lg-block col-lg-3"></div>
    </div>

    <div
      v-else
      class="customer-offer-detail-wrapper row">
      <div class="d-sm-none d-md-none d-lg-block col-lg-3"></div>
      <div class="col-sm-12 col-md-12 col-lg-6 centered-content vh-100">
        <content-placeholders :rounded="true">
          <content-placeholders-img />
          <content-placeholders-text :lines="15" />
        </content-placeholders>
      </div>
      <div class="d-sm-none d-md-none d-lg-block col-lg-3"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'step1',
  mounted() {
    this.$store.dispatch('cities/fetchCities');
  },
  computed: {
    offer() {
      if (Object.keys(this.$store.state.offer.offer).length < 1) {
        this.$store.dispatch('offer/fetchTireOffer', this.$route.params.token);
      }
      return this.$store.state.offer.offer;
    }
  }
};
</script>

<style scoped>
.customer-offer-detail-wrapper {
  width: 100%;
}
.centered-content {
  height: fit-content;
  padding: 1.5rem;
}
.offer-content {
  text-align: center;
}
.steps {
  display: flex;
  margin-top: 2rem;
  padding: 0 4rem;
}
.step1,
.step2,
.step3 {
  width: 33.3%;
  border: none;
  font-size: 14px;
  text-decoration: underline;
  cursor: pointer;
}
.step1 {
  border-top: 7px solid #06a5d0;
  padding: 0.5rem 0 0 0;
}
.step2 {
  border-top: 7px solid #d3d3d3;
  padding: 0.5rem 0 0 0;
}

.step3 {
  border-top: 7px solid #eaeaea;
  padding: 0.5rem 0 0 0;
}
.content-card {
  box-shadow: 5px 5px 8px #dcdcdc, -5px -5px 8px #f6f6f6;
  border-radius: 15px;
}

.image-offer-id {
  margin-top: 3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.image-offer-id .offer-id {
  font-size: 24px;
  font-weight: 700;
  color: #1c1c1c;
}
.name-address-email {
  text-align: right;
  display: flex;
  flex-direction: column;
  width: 45%;
}
.name-address-email .name {
  font-weight: 700;
  color: #1c1c1c;
  font-size: 18px;
}

.name-address-email .address,
.name-address-email .email-phone {
  font-weight: 400;
  color: #1c1c1c;
  font-size: 13px;
  margin-top: 0.5rem;
}

.customer-name {
  font-weight: 700;
  color: #1c1c1c;
  font-size: 18px;
}
.customer-address-text {
  text-align: left;
  font-weight: 400;
  color: #1c1c1c;
  font-size: 13px;
  width: 40%;
}
.left-text {
  font-weight: 400;
  color: #1c1c1c;
  font-size: 13px;
  text-align: left;
}
.right-text {
  font-weight: 600;
  color: #1c1c1c;
  font-size: 13px;
  text-align: right;
}
.w-35 {
  width: 35%;
}
.summary-card {
  background-color: #dedede;
  padding: 0.75rem 1rem;
  width: 50%;
  border: 1px solid #cdcdcd;
  border-radius: 10px;
}
.summary-card-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.summary-card-item .left-text,
.summary-card-item .right-text {
  font-size: 12px;
  font-weight: 400;
  color: #0a2540;
}
.fw-700 {
  font-weight: 700 !important;
}
.title {
  font-size: 12px;
  font-weight: 700;
  color: #1c1c1c;
}
.content {
  font-size: 12px;
  font-weight: 400;
  color: #1c1c1c;
}
.continue-btn,
.continue-btn:hover {
  background-color: #1c1c1c;
  border: 1px solid #1c1c1c;
  color: #fff;
  padding: 7px 30px;
}
.left-infos,
.right-infos {
  text-align: left;
}
</style>
