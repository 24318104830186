<template>
  <div id="login">
    <div class="container-fluid">
      <div class="row">
        <div
            class="col-sm-12 col-md-12 col-lg-6"
            id="left">
          <div class="forms-g-wrapper">
            <h2 class="text-center">{{ $t('Login') }}</h2>
            <b-tabs content-class="mt-3">
              <b-tab
                  :title="$t('Login')"
                  active>
                <div class="form-wrapper">
                  <div class="input-wrapper mt-5">
                    <b-input-group>
                      <template #prepend>
                        <i class="fa-solid fa-envelope"></i>
                      </template>
                      <b-form-input
                          :placeholder="$t('E-Mail')"
                          type="text"
                          v-model="form.email"
                          v-on:keyup.enter="login()"></b-form-input>
                    </b-input-group>
                  </div>
                  <div class="input-wrapper">
                    <b-input-group>
                      <template #prepend>
                        <i class="fa-solid fa-lock-keyhole"></i>
                      </template>
                      <b-form-input
                          :placeholder="$t('Password')"
                          type="password"
                          v-model="form.password"
                          v-if="!show_password"
                          v-on:keyup.enter="login()"></b-form-input>
                      <b-form-input
                          :placeholder="$t('Password')"
                          type="text"
                          v-model="form.password"
                          v-if="show_password"
                          v-on:keyup.enter="login()"></b-form-input>
                      <button
                          class="toggle-password"
                          @click="show_password = !show_password">
                        <i class="fa-sharp fa-regular fa-eye-slash"></i>
                      </button>
                    </b-input-group>
                  </div>
                  <div class="form-actions">
                    <router-link to="/forget-password">{{
                        $t('Forget password?')
                      }}
                    </router-link>
                  </div>
                  <button @click="login()">{{ $t('Login') }}</button>
                </div>
              </b-tab>
              <b-tab
                  :title="$t('Register')"
                  @click="goRegiser()"
              >
                <p>
                  {{ $t('Redirect register page.') }}
                  <b-spinner
                      label="Spinning"
                      class="ml-2"
                      small></b-spinner>
                </p
                >
              </b-tab>
            </b-tabs>
          </div>
        </div>
        <div
            class="col-lg-6"
            id="right">
          <ssr-carousel
              :show-dots="true"
              :show-arrows="false"
              :loop="false"
              :autoplay-delay="5"
              gutter="0"
              class="ssr-carousel login-ssr">
            <div class="slide step1">
              <div class="slide-step-item">
                <div class="title">{{ sliders[0].title }}</div>
                <div class="title-description">
                  {{ sliders[0].description }}
                </div>
              </div>
            </div>
            <div class="slide step2">
              <div class="slide-step-item">
                <div class="title">{{ sliders[1].title }}</div>
                <div class="title-description">
                  {{ sliders[1].description }}
                </div>
              </div>
            </div>
            <div class="slide step3">
              <div class="slide-step-item">
                <div class="title">{{ sliders[2].title }}</div>
                <div class="title-description">
                  {{ sliders[2].description }}
                </div>
              </div>
            </div>
          </ssr-carousel>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axiosInstance from '@/plugins/axios';
/* eslint-disable */
export default {
  name: 'LoginView',
  data() {
    return {
      form: {
        email: '',
        password: ''
      },
      show_password: false,
      sliders: [
        {
          title: this.$i18n.t(
              'Add Your Customers and Their Vehicles to the System and Track Them Easily!'
          ),
          description: this.$i18n.t(
              "You can track customer information and your customers' vehicles by adding them to Servislet Pro. You can increase the efficiency of your business by entering user types, contact information and vehicle details into the system."
          )
        },
        {
          title: this.$i18n.t(
              'Manage Your Online and Offline Sales at the Same Time!'
          ),
          description: this.$i18n.t(
              'With marketplace integrations, you can easily manage all your online and offline sales from one place and review your reports.'
          )
        },
        {
          title: this.$i18n.t('Get Offers, Focus on Making Profitable Sales!'),
          description: this.$i18n.t(
              'Servislet Pro offers businesses the advantage of profit-oriented sales with the offer scenario it creates. Thus, you can evaluate the offers created by your customers and make your business more efficient while increasing your sales volume.'
          )
        }
      ],
      responsive: [
        {
          maxWidth: 3500,
          slidesPerPage: 1
        }
      ]
    };
  },
  mounted() {
    if (this.$route.query.uuid) {
      this.redirect()
    }
  },
  methods: {
    redirect() {
      axiosInstance.post('/auth/uuid', {
        uuid: this.$route.query.uuid
      }).then(response => {
        localStorage.setItem('locale', 'tr')
        localStorage.setItem('appTypes', JSON.stringify(response.data.responseData.appTypes ?? []))
        localStorage.setItem('color', response.data.responseData.account_color ?? '(247,247,247,1)')
        localStorage.setItem('account_text_color', response.data.responseData.account_text_color ?? '(119,119,119,1)')
        localStorage.setItem('account_icon', response.data.responseData.account_icon ?? '/img/dealer-logo.png')
        localStorage.setItem('account_asset', response.data.responseData.account_asset_path ?? '1')
        localStorage.setItem(
            'userData',
            JSON.stringify(response.data.responseData)
        );
        localStorage.setItem('app_type', 'tire');
        localStorage.setItem('show_menu', true);
        axiosInstance.defaults.headers.common[
            'Authorization'
            ] = `Bearer ${response.data.responseData.access_token}`;
        axiosInstance.defaults.headers.Authorization = `Bearer ${response.data.responseData.access_token}`;
        if (response.data.responseData.integrations) {
          var status = response.data.responseData.integrations.find(item => item.code === 'servislet')
          if (status) {
            localStorage.setItem('show_commission', true)
            localStorage.setItem('options', JSON.stringify(status.options))
          } else {
            localStorage.setItem('show_commission', false)
            localStorage.setItem('options', JSON.stringify([]))
          }
        } else {
          localStorage.setItem('show_commission', false)
          localStorage.setItem('options', JSON.stringify([]))
        }
        const text = 'rgba' + localStorage.getItem('color');
        const active_text = 'rgba' + localStorage.getItem('account_text_color');
        const bg = 'rgba'+ localStorage.getItem('color');

        const styles = `
  .pro-text {
    color: ${text} !important;
  }
  #top-bar {
    background: ${bg};
  }
  .right {
    color: ${active_text};
    border-left: 1px solid ${active_text === '#000000' ? '#d3d3d3' : active_text};
  }
  .right i span {
    background: ${active_text === '#000000' ? '#06a5d0' : active_text};
  }
`;
        const styleElement = document.createElement('style');
        styleElement.innerHTML = styles;
        document.head.appendChild(styleElement);
        this.$router.push('/');
      })
    },
    goRegiser() {
      window.location.href = 'https://www.servislet.com/satici/kayit';
    },
    login() {
      localStorage.clear();
      var self = this;
      if (this.form.email !== '' && this.form.password !== '') {
        axiosInstance
            .post('/auth/login', {
              email: this.form.email,
              password: this.form.password
            })
            .then(function (response) {
              localStorage.setItem('locale', 'tr')
              localStorage.setItem('appTypes', JSON.stringify(response.data.responseData.appTypes ?? []))
              localStorage.setItem('color', response.data.responseData.account_color ?? '(247,247,247,1)')
              localStorage.setItem('account_text_color', response.data.responseData.account_text_color ?? '(119,119,119,1)')
              localStorage.setItem('account_icon', response.data.responseData.account_icon ?? '/img/dealer-logo.png')
              localStorage.setItem('account_asset', response.data.responseData.account_asset_path ?? '1')
              localStorage.setItem('userData', JSON.stringify(response.data.responseData));
              localStorage.setItem('app_type', 'tire');
              localStorage.setItem('show_menu', true);
              axiosInstance.defaults.headers.common[
                  'Authorization'
                  ] = `Bearer ${response.data.responseData.access_token}`;
              axiosInstance.defaults.headers.Authorization = `Bearer ${response.data.responseData.access_token}`;
              if (response.data.responseData.integrations) {
                var status = response.data.responseData.integrations.find(item => item.code === 'servislet')
                if (status) {
                  localStorage.setItem('show_commission', true)
                  localStorage.setItem('options', JSON.stringify(status.options))
                } else {
                  localStorage.setItem('show_commission', false)
                  localStorage.setItem('options', JSON.stringify([]))
                }
              } else {
                localStorage.setItem('show_commission', false)
                localStorage.setItem('options', JSON.stringify([]))
              }
              const text = 'rgba'+localStorage.getItem('color');
              const active_text = 'rgba' + localStorage.getItem('account_text_color');
              const bg = 'rgba'+localStorage.getItem('color');

              const styles = `
  .pro-text {
    color: ${text} !important;
  }
  #top-bar {
    background: ${bg};
  }
  .right {
    color: ${active_text};
    border-left: 1px solid ${active_text === '#000000' ? '#d3d3d3' : active_text};
  }
  .right i span {
    background: ${active_text === '#000000' ? '#06a5d0' : active_text};
  }
`;
              const styleElement = document.createElement('style');
              styleElement.innerHTML = styles;
              document.head.appendChild(styleElement);
              self.$router.push('/');
            })
            .catch(function (error) {
              self.$swal.fire({
                iconHtml: '<img src="/img/error.svg" width="32" height="32">',
                position: 'top-end',
                title: error.response.data.statusMessage,
                showConfirmButton: false,
                timer: 2500,
                toast: true,
                customClass: {
                  icon: 'swal2-no-border'
                }
              });
            });
      } else {
        self.$swal.fire({
          iconHtml: '<img src="/img/warning.png" width="32" height="32">',
          position: 'top-end',
          title: this.$i18n.t('Email and password field is required.'),
          showConfirmButton: false,
          timer: 2500,
          toast: true,
          customClass: {
            icon: 'swal2-no-border'
          }
        });
      }
    }
  }
};
</script>

<style scoped>
.p-relative {
  position: relative;
}

.title {
  font-size: 26px;
  font-weight: 800;
  color: #f4f4f4;
  width: 100%;
  padding-right: 5rem;
}

.title-description {
  font-size: 15px;
  font-weight: 300;
  margin-top: 10px;
  color: #f4f4f4;
  letter-spacing: 0.3px;
  line-height: 1.5;
  max-width: 90%;
}

.step0 {
  background-image: url('https://brand.servislet.com/img/motul-login-image.jpg');
  height: 100vh;
}

.step1 {
  background-image: url('@/assets/images/login-1.jpeg');
  height: 100vh;
}

.step2 {
  background-image: url('@/assets/images/login-2.jpeg');
  height: 100vh;
}

.step3 {
  background-image: url('@/assets/images/login-3.jpeg');
  height: 100vh;
}

.step4 {
  background-image: url('@/assets/images/login-4.jpeg');
  height: 100vh;
  background-size: auto !important;
}

.step0,
.step1,
.step2,
.step3,
.step4 {
  overflow: hidden;
  display: grid;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position-x: 0rem;
}

.step4 {
  background-position-x: -5rem;
}

.step2 {
  background-size: 100% 100%;
}

.slide-step-item {
  top: 65%;
  position: relative;
  right: 55%;
  left: 5%;
}

#login .col-lg-6 {
  padding: 0 !important;
}

#left {
  display: flex;
  align-items: center;
  justify-content: center;
}

.forms-g-wrapper h2 {
  color: #000;
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 2rem;
}

.input-wrapper:first-child {
  margin-top: 1.75rem !important;
}

.input-wrapper {
  border: 1px solid #d8d6de;
  padding: 2px 12px;
  border-radius: 5px;
  width: 100%;
  margin-top: 1rem;
  position: relative;
}

.form-actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

.form-actions a {
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin-top: 1rem;
  text-decoration: none !important;
}

.form-wrapper button {
  border-radius: 5px;
  background: #1c1c1c;
  color: #f4f4f4;
  width: 100%;
  margin-top: 1.5rem;
  border: 1px solid #1c1c1c;
  outline: none !important;
  box-shadow: none !important;
  transition: 0.5s;
  padding: 10px;
}

.form-wrapper button:hover {
  background: #06a5d0;
  border-color: #06a5d0;
  transition: 0.5s;
}

.input-wrapper svg {
  color: #d9d9d9;
}

.toggle-password {
  border: none !important;
  background: transparent !important;
  outline: none !important;
  box-shadow: none !important;
  color: #d9d9d9 !important;
  margin: 0 !important;
  width: min-content !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

@media only screen and (max-width: 992px) {
  #right {
    display: none;
  }

  #login {
    height: 100vh;
    display: flex;
    align-items: center;
  }
}
</style>

<style>

[aria-disabled] > .ssr-carousel-dot-icon {
  background: #06a5d0 !important;
  border: none !important;
  width: 60px;
  position: relative;
  z-index: 999999;
  height: 8px;
}

:not([aria-disabled]) > .ssr-carousel-dot-icon {
  background: #99999966 !important;
  border: none !important;
  width: 60px;
  position: relative;
  z-index: 999999;
  height: 8px;
}
</style>
