<template>
  <div>
    <div class="component-container" v-click-outside="close">
      <div :class="show ? 'opened-border' : 'closed-border'" class="component__select cursor-pointer" @click="show = !show">
        <span v-if="Object.keys(selected_update_type).length>0" class="component__select--name">{{selected_update_type.title}}</span>
        <span v-else class="component__select--name">{{$t('Choose Update Type')}}</span>

        <div>
<!--          <i v-if="Object.keys(selected_update_type).length > 0 && show" class="fa-solid fa-xmark mr-2" @click="selected_update_type = {}; $emit('select', null);"></i>-->
          <i v-if="!show" class="fa-solid fa-chevron-down cursor-pointer"></i>
          <i v-if="show" class="fa-solid fa-chevron-up cursor-pointer"></i>
        </div>
      </div>
      <div v-if="show" class="component__select-options">
        <div
            v-for="(option,key) in options"
            :key="option.$index"
            class="select--option"
            :class="{ 'active': option.title === selected_update_type.title, 'border-top-first': key===0}"
            @click="selected_update_type = option; close(); $emit('setUpdateType',option.code)">
          <div>{{ option.title }}</div>
          <i class="fa-solid fa-check" v-if="selected_update_type.title === option.title"></i>
        </div>
      </div>
    </div>

    <div class="mt-3" v-if="Object.keys(selected_update_type).length > 0 && selected_update_type.key < 2 ">
      <div :class="show ? 'opened-border' : 'closed-border'" class="component__select cursor-pointer pr-0 gap-2"  @click="show = !show">
        <div class="space-between">
          <span>{{selected_update_type.key === 0 ? '%' : '₺'}}</span>
          <b-form-input @input="setPriceOrRate()" class="bg-transparent border-0"  v-model="number" v-if="selected_update_type.key === 0" />
          <b-form-input @input="setPriceOrRate()" v-model="number" v-money="money" class="bg-transparent border-0" v-if="selected_update_type.key === 1" />
          <div class="component-container" v-click-outside="close2">
            <div :class="show2 ? 'opened-border' : 'closed-border'" class="component__select cursor-pointer" style="width: 142px;" @click="show2 = !show2">
              <span style="border-left: 1px solid #404040; padding-left: .5rem;" v-if="Object.keys(selected_action).length>0" class="component__select--name text-nowrap">{{selected_action.title}}</span>
              <span style="border-left: 1px solid #404040; padding-left: .5rem;" v-else class="component__select--name">{{$t('Choose')}}</span>

              <div>
<!--                <i v-if="Object.keys(selected_action).length > 0 && show" class="fa-solid fa-xmark mr-2" @click="selected_action = {};"></i>-->
                <i v-if="!show" class="fa-solid ml-1 fa-chevron-down cursor-pointer"></i>
                <i v-if="show" class="fa-solid ml-1 fa-chevron-up cursor-pointer"></i>
              </div>
            </div>
            <div v-if="show2" class="component__select-options" style="width: 126px; margin-left: 16px;" >
              <div
                  v-for="(option,key) in options2"
                  :key="option.$index"
                  class="select--option"
                  :class="{ 'active': option.title === selected_action.title}"
                  @click="selected_action = option; close2(); $emit('setOption',option.key)">
                <div class="text-nowrap">{{ option.title }}</div>
                <i class="fa-solid fa-check" v-if="selected_action.title === option.title"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="component-container mt-3" v-click-outside="close4" v-if="Object.keys(selected_update_type).length > 0 && selected_update_type.key === 2">
      <div :class="show4 ? 'opened-border' : 'closed-border'" class="component__select cursor-pointer" @click="show4 = !show4">
        <span v-if="Object.keys(close_stock).length>0" class="component__select--name">{{close_stock.title}}</span>
        <span v-else class="component__select--name">{{$t('Choose')}}</span>

        <div>
          <i v-if="!show4" class="fa-solid fa-chevron-down cursor-pointer"></i>
          <i v-if="show4" class="fa-solid fa-chevron-up cursor-pointer"></i>
        </div>
      </div>
      <div v-if="show4" class="component__select-options">
        <div
            v-for="(option,key) in close_stock_options"
            :key="option.$index"
            class="select--option"
            :class="{ 'active': option.title === close_stock.title, 'border-top-first': key===0}"
            @click="close_stock = option; close4(); $emit('setUpdateType', 'activate'); $emit('setOption', null);  $emit('setDisable',false)">
          <div>{{ option.title }}</div>
          <i class="fa-solid fa-check" v-if="selected_update_type.title === option.title"></i>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import vClickOutside from "v-click-outside";

export default {
  name: 'BySelectedProducts',
  data() {
    return {
      options: [
        {
          key: 0,
          title: this.$i18n.t('Percent')+ ' (%)',
          code: 'percent'
        },
        {
          key: 1,
          title: this.$i18n.t('Amount')+ ' (₺)',
          code: 'equal'
        },
        {
          key: 2,
          title: this.$i18n.t('Close/Open Stocks'),
          code: 'activate'
        }
      ],
      selected_update_type:{},
      show: false,
      show2: false,
      show4:false,
      options2:[
        {
          title: this.$i18n.t('Increase'),
          key: 'increase',
        },
        {
          title: this.$i18n.t('Decrease'),
          key: 'decrease'
        },
        {
          title: this.$i18n.t('Apply Price'),
          key: 'equal'
        }
      ],
      selected_action:{},
      number: '',
      money: {
        decimal: ',',
        thousands: '.',
        prefix: '',
        suffix: '',
        precision: 2,
        masked: false
      },
      close_stock: {},
      close_stock_options:[
        // {
        //   key: true,
        //   title: this.$i18n.t('Open Stocks')
        // },
        {
          key: false,
          title: this.$i18n.t('Close Stocks')
        },
      ],
    };
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  mounted(){
    this.selected_action = this.options2[0]
    this.$emit('setOption', this.selected_action.key)
  },
  methods:{
    close(){
      this.show = false
    },
    close2(){
      this.show2 = false
    },
    close4(){
      this.show4 = false
    },
    setPriceOrRate(){
      this.$emit('setPriceOrRate',this.number)
      this.$emit('setDisable',false)
    }
  }
};
</script>

<style scoped>
.component__select {
  height: 40px;
  line-height: 38px;
  width: 260px;
  grid-template-columns: 10fr 1fr;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  background-color: #2C2C2E;
  border: 1px solid #2C2C2E;
  color: #f4f4f4;
  padding: 1.5rem 1rem;
}

.component__select--name {
  font-size: 13px;
  cursor: pointer;
}

.component__select-options {
  position: absolute;
  z-index: 1500;
  width: 260px;
  margin: 0;
  padding: 0;
  background-color: #2C2C2E;
  border-radius:  0 0 5px 5px;
}

.select--option {
  height: 38px;
  display: flex;
  font-size: 13px;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  background-color: #2C2C2E;
  cursor: pointer;
}

.select--option:hover, .select--option.active {
  background: #06A5D01F;
  color: #06a5d0;
}

.select--option:last-child {
  border-bottom: none;
  border-radius: 0 0 5px 5px;
}

.select--option input {
  display: none;
}

.component__select i {
  font-size: 12px;
  cursor: pointer;
}
.border-top-first{
  border-top: 1px solid #404040;
}
.closed-border{
  border-radius: 5px;
}

.opened-border{
  border-radius: 5px 5px 0 0;
}
.form-control{
  color: #f4f4f4 !important;
}
.form-control:focus{
  border: none !important;
}
</style>
