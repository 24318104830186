<template>
  <div>
    <ValidationObserver ref="userCorporateUpdateRules">
      <div class="row">
        <b-col
          sm="12"
          md="6"
          lg="6">
          <div class="row">
            <b-col
              sm="12"
              md="12"
              lg="12">
              <ValidationProvider
                rules="max:120"
                :name="$t('Customer')"
                #default="{ errors }">
                <b-form-group>
                  <label for="name"
                    >{{ $t('Customer') }}
                    <small class="text-danger">*</small></label
                  >
                  <b-form-input
                    v-model="company_title"
                    class="bg-transparent" />
                  <label
                    for="name"
                    v-if="errors.length > 0"
                    class="text-danger"
                    ><small>*</small>{{ errors[0] }}.</label
                  >
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col
              sm="12"
              md="12"
              lg="12">
              <ValidationProvider
                rules="max:60"
                :name="$t('Tax Administration')"
                #default="{ errors }">
                <b-form-group>
                  <label for="name"
                    >{{ $t('Tax Administration') }}
                    <small class="text-danger">*</small></label
                  >
                  <b-form-input
                    v-model="tax_office"
                    class="bg-transparent" />
                  <label
                    for="name"
                    v-if="errors.length > 0"
                    class="text-danger"
                    ><small>*</small>{{ errors[0] }}.</label
                  >
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col
              sm="12"
              md="12"
              lg="12">
              <ValidationProvider
                rules="max:20"
                :name="$t('Tax Number')"
                #default="{ errors }">
                <b-form-group>
                  <label for="name"
                    >{{ $t('Tax Number')
                    }}<small class="text-danger">*</small></label
                  >
                  <b-form-input
                    v-model="tax_number"
                    class="bg-transparent" />
                  <label
                    for="name"
                    v-if="errors.length > 0"
                    class="text-danger"
                    ><small>*</small>{{ errors[0] }}.</label
                  >
                </b-form-group>
              </ValidationProvider>
            </b-col>
          </div>
        </b-col>
        <b-col
          sm="12"
          md="6"
          lg="6">
          <div class="row">
            <b-col
              sm="12"
              md="6"
              lg="6">
              <b-form-group>
                <label for="city_id">{{ $t('City') }}</label>
                <b-form-select
                  v-model="city_id"
                  @change="getDistrictList()"
                  class="bg-transparent">
                  <b-form-select-option
                    :value="city.id"
                    v-for="city in cities.cities"
                    :key="city.$index"
                    >{{ city.name }}
                  </b-form-select-option>
                </b-form-select>
              </b-form-group>
            </b-col>

            <b-col
              sm="12"
              md="6"
              lg="6">
              <b-form-group>
                <label for="district_id">{{ $t('District') }}</label>
                <b-form-select
                  v-model="district_id"
                  @change="getAreaList()"
                  class="bg-transparent">
                  <b-form-select-option
                    :value="district.id"
                    v-for="district in districts.districts"
                    :key="district.$index"
                    >{{ district.name }}
                  </b-form-select-option>
                </b-form-select>
              </b-form-group>
            </b-col>

            <b-col
              sm="12"
              md="12"
              lg="12">
              <b-form-group>
                <label for="area_id">{{ $t('Neighbourhood') }}</label>
                <b-form-select
                  v-model="area_id"
                  class="bg-transparent">
                  <b-form-select-option
                    :value="area.id"
                    v-for="area in areas.areas"
                    :key="area.$index"
                    >{{ area.name }}
                  </b-form-select-option>
                </b-form-select>
              </b-form-group>
            </b-col>

            <b-col
              sm="12"
              md="12"
              lg="12">
              <ValidationProvider
                rules="max:200"
                :name="$t('Address Information')"
                #default="{ errors }">
                <b-form-group>
                  <label for="address">{{ $t('Address Information') }}</label>
                  <b-form-input
                    id="address-textarea"
                    v-model="address"
                    class="bg-transparent" />
                  <label
                    for="address"
                    v-if="errors.length > 0"
                    class="text-danger"
                    ><small>*</small>{{ errors[0] }}.</label
                  >
                </b-form-group>
              </ValidationProvider>
            </b-col>
          </div>
        </b-col>
      </div>
      <div class="d-flex justify-content-end update">
        <b-button @click="updateUserCorparateInfos()">{{
          $t('Update')
        }}</b-button>
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'companyEditForm',
  props: {
    user: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      company_title: this.user.company_title,
      tax_office: this.user.tax_office,
      tax_number: this.user.tax_number,
      city_id:
        this.user.addresses.length > 0
          ? this.user.addresses[0].city
            ? this.user.addresses[0].city.id
            : null
          : null,
      district_id:
        this.user.addresses.length > 0
          ? this.user.addresses[0].district
            ? this.user.addresses[0].district.id
            : null
          : null,
      area_id:
        this.user.addresses.length > 0
          ? this.user.addresses[0].area
            ? this.user.addresses[0].area.id
            : null
          : null,
      address: this.user.addresses.length > 0 ? this.user.addresses.address : ''
    };
  },
  computed: {
    ...mapState(['cities']),
    ...mapState(['districts']),
    ...mapState(['areas'])
  },
  mounted() {
    this.$store.dispatch('cities/fetchCities');
    this.getDistrictList();
    this.getAreaList();
  },
  methods: {
    getDistrictList() {
      if (this.city_id) {
        this.$store.dispatch('districts/fetchDistricts', this.city_id);
      }
    },
    getAreaList() {
      if (this.district_id) {
        this.$store.dispatch('areas/fetchAreas', this.district_id);
      }
    },
    updateUserCorparateInfos() {
      this.$refs.userCorporateUpdateRules.validate().then((success) => {
        if (success) {
          var address = this.user.addresses[0];
          this.$axios
            .put(`/customers/addresses/${address.id}`, {
              is_default: address.is_default,
              is_active: address.is_active,
              invoice_type_id: address.invoiceType.id,
              name: address.name,
              surname: address.surname,
              email: address.email,
              phone: address.phone,
              identity_number: address.identity_number,
              tax_office: this.user.tax_office,
              tax_number: this.user.tax_number,
              city_id: this.city_id,
              district_id: this.district_id,
              area_id: this.area_id,
              address: this.address
            })
            .then((response) => {
              if (response.data.status) {
                this.update();
              }
            })
            .catch((error) => {
              var message = error.response.data.responseData.error
                ? error.response.data.responseData.error[0].message
                : error.response.data.responseData.message;
              this.$swal.fire({
                iconHtml: '<img src="/img/error.svg" width="32" height="32">',
                position: 'top-end',
                title: message,
                showConfirmButton: false,
                timer: 2500,
                toast: true,
                customClass: {
                  icon: 'swal2-no-border'
                }
              });
            });
        } else {
          this.$swal.fire({
            iconHtml: '<img src="/img/error.svg" width="32" height="32">',
            position: 'top-end',
            title: this.$i18n.t('Please fill the required fields.'),
            showConfirmButton: false,
            timer: 2500,
            toast: true,
            customClass: {
              icon: 'swal2-no-border'
            }
          });
        }
      });
    },
    update() {
      this.$axios
        .put(`/customers/${this.user.id}`, {
          name: this.user.name,
          surname: this.user.surname,
          email: this.user.email,
          mobile: this.user.phones[0].phone,
          job_title: this.user.job_title,
          company_title: this.company_title,
          tax_number: this.tax_number,
          tax_office: this.tax_office,
          customer_type_id: this.user.customerType.id,
          is_active: true
        })
        .then((response) => {
          if (response.data.status) {
            this.$swal.fire({
              iconHtml: '<img src="/img/success.svg" width="32" height="32">',
              position: 'top-end',
              title: this.$i18n.t(
                'Company information has been successfully updated.'
              ),
              showConfirmButton: false,
              timer: 2500,
              toast: true,
              customClass: {
                icon: 'swal2-no-border'
              }
            });
            this.$emit('refresh');
          }
        })
        .catch((error) => {
          var message = error.response.data.responseData.error
            ? error.response.data.responseData.error[0].message
            : error.response.data.responseData.message;
          this.$swal.fire({
            iconHtml: '<img src="/img/error.svg" width="32" height="32">',
            position: 'top-end',
            title: message,
            showConfirmButton: false,
            timer: 2500,
            toast: true,
            customClass: {
              icon: 'swal2-no-border'
            }
          });
          this.$emit('refresh');
        });
    }
  }
};
</script>

<style scoped>
.update button {
  transition: 0.5s;
  margin-top: 24px;
  background: var(--servislet-black);
  color: var(--servislet-white);
  border: 1px solid var(--servislet-black);
  outline: none;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.4px;
  padding: 8px 24px;
}
.update button:hover {
  transition: 0.5s;
  background: var(--servislet-black);
  color: var(--servislet-white);
  box-shadow: rgba(28, 28, 28, 0.2) 0px 8px 30px 0px;
}
#address-textarea {
  background-color: transparent !important;
}
</style>
