<template>
  <div>
    <PageBreadcrumbs :breadcrumbs="breadcrumbs" />

    <div class="widgets">
      <div id="page-widgets-wrapper">
        <div class="row">
          <div
            class="col-lg-6"
            v-for="widget in 2"
            :key="widget.$index">
            <PageWidgets
              :title="$t('Invoice Amount')"
              mainData="₺25.246,75"
              number="1.657"
              :icon="false"
              :text="$t('Invoice Count')" />
          </div>
        </div>
      </div>
    </div>

    <div
      id="products-listing-table-wrapper"
      class="mt-5 py-4">
      <div class="products-double-flex">
        <div class="products-double-flex-left"></div>
        <div class="products-double-flex-right">
          <div
            class="products-double-flex-right-actions"
            v-for="action in 4"
            :key="action.$index">
            <span>
              <i class="fa-solid fa-search"></i>
              <p>{{ $t('Search') }}</p>
            </span>
          </div>
        </div>
      </div>
      <div class="product-listing-table">
        <table>
          <tr class="product-listing-table-head lc-w-120">
            <th>
              <label class="product-listing-checkbox-label">
                <input
                  type="checkbox"
                  @click="checkUncheck()" />
                <span class="checkmark"></span>
              </label>
            </th>
            <th style="text-transform: uppercase">{{ $t('Date') }}</th>
            <th style="text-transform: uppercase">
              {{ $t('Invoice Category') }}
            </th>
            <th style="text-transform: uppercase">{{ $t('Invoice No') }}</th>
            <th style="text-transform: uppercase">
              {{ $t('Invoice Amount') }}
            </th>

            <th></th>
            <th></th>
          </tr>
          <tr
            v-for="item in 10"
            :key="item.$index">
            <td>
              <label class="product-listing-checkbox-label">
                <input
                  type="checkbox"
                  name="single-payment-checkbox" />
                <span class="checkmark"></span>
              </label>
            </td>
            <td>27/12/2022</td>
            <td>
              <span class="servislet-badge commission-badge">
                {{ $t('Commission') }}
              </span>
            </td>
            <td>2348473983749</td>
            <td>₺12.650,78</td>
            <td>
              <button class="show-in-website-btn">
                <i class="fa-solid fa-eye"></i>
              </button>
            </td>
            <td>
              <select class="product-listing-action-select">
                <option value="">{{ $t('Actions') }}</option>
                <option value="">{{ $t('Download') }} Excel</option>
                <option value="">PDF {{ $t('Download') }}</option>
              </select>
            </td>
          </tr>
        </table>
        <div id="table-info-and-pagination">
          <div class="table-results-info">
            <p>Showing 1 to 20 of 100 entries</p>
          </div>
          <div class="pagination-wrapper">
            <div class="pagination">
              <a
                href="#"
                class="pagination-arrows"
                ><i class="fa-solid fa-angle-left"></i
              ></a>
              <div class="pagination-numbers">
                <a href="#">1</a>
                <a
                  href="#"
                  class="active"
                  >2</a
                >
                <a href="#">3</a>
                <a href="#">4</a>
                <a href="#">5</a>
                <a href="#">6</a>
              </div>
              <a
                href="#"
                class="pagination-arrows"
                ><i class="fa-solid fa-angle-right"></i
              ></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import PageBreadcrumbs from '../../components/PageBreadcrumbs.vue';
import PageWidgets from '../../components/PageWidgets.vue';
export default {
  components: {
    PageBreadcrumbs,
    PageWidgets
  },
  data() {
    return {
      breadcrumbs: [
        {
          name: this.$i18n.t('Incoming Invoices'),
          path: '/#'
        }
      ]
    };
  },
  methods: {
    checkUncheck(checkBox) {
      var get = document.getElementsByName('single-payment-checkbox');
      for (var i = 0; i < get.length; i++) {
        if (get[i].checked === true) {
          get[i].checked = false;
        } else {
          get[i].checked = true;
        }
        // without if => get[i].checked = checkBox.checked;}
      }
    }
  }
};
</script>

<style></style>
