// store/carYears.js

import Vue from 'vue';
import Vuex from 'vuex';
// import axios from 'axios';
import axios from '@/plugins/axios';

// const TOKEN = localStorage.userData ? 'Bearer '+JSON.parse(localStorage.userData).access_token : ''
// const API_BASE_URL = 'https://staging-pro-api.servislet.com/api/v1/web/main'

Vue.use(Vuex);

const state = {
  versions: []
};

const mutations = {
  setCarVersionsData(state, data) {
    state.versions = data;
  }
};

const actions = {
  fetchCarVersions({ commit }, data) {
    axios
      .get(
        `/cars/versions?car_brand_id=${data.car_brand_id}&year=${data.car_year}&car_model_id=${data.car_model_id}`,
        {}
      )
      .then((response) => {
        commit('setCarVersionsData', response.data.responseData);
      })
      .catch((error) => {
        console.error(error);
      });
  }
};

const getters = {
  getCarVersionsData(state) {
    return state.versions;
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
