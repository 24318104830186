<template>
  <DefaultLayout>
    <PageBreadcrumbs :breadcrumbs="breadcrumbs"/>
    <div class="row" v-if="!$store.state.sales.item.loading">
      <div class="col-lg-9">
        <div class="product-detail-left-wrapper">
          <div class="products-double-flex">
            <div class="products-double-flex-left">
              <p class="page-title">{{ $t('Customer') }}</p>
            </div>
          </div>
          <div class="add-invoice mt-4">
            <div class="products-double-flex" v-if="selected_customer !== 1 && selected_customer !== 2">
              <div class="d-flex align-items-center" style="gap: 10px">
                <div class="choose-customer-container" v-click-outside="(event) => customer.box = false">
                  <div class="choose-product" v-if="Object.keys(customer.info).length > 0" :class="[{'shadow-sm': customer.box}, {'customer-div': Object.keys(customer.info).length > 0}]">
                    <div class="choose-product-label" v-if="Object.keys(customer.info).length > 0">
                      <div class="customer-info">
                        <div class="customer-name">
                          <div class="customer-name-name">
                            <i class="fa-regular fa-user" style="color: #7367F0"></i> <strong>{{ customer.info.name }} {{ customer.info.surname }}</strong>
                          </div>
                          <div class="customer-name-info">
                            <span><i class="fa-regular fa-envelope"></i> {{ customer.info.email ?? $t('Not defined')}}</span>
                            <span><i class="fa-regular fa-phone"></i> {{ customer.info.phones.phone ?? $t('Not defined') }}</span>
                          </div>
                        </div>
                        <div class="customer-name" v-if="Object.keys(customer.car).length > 0" style="border-left: 1px solid #dee2e6; margin-left: 25px; padding-left: 35px">
                          <div class="customer-name-name">
                            <i class="fa-regular fa-car" style="color: #7367F0"></i> <strong>{{ customer.car.plate_number }}</strong>
                          </div>
                          <div class="customer-name-info">
                            <span>{{ customer.car.brand }} {{customer.car.model }} {{ customer.car.year }}</span>
                            <span>{{ customer.car.version }} {{ customer.car.version_type}}</span>
                          </div>
                        </div>
                        <i class="fa fa-times ml-3" @click="removeCustomer"></i>
                      </div>
                    </div>
                    <div v-else class="icon-with-name">
                      <span class="choose-product-label">{{$t('Choose')}}</span>
                      <i class="fa-solid fa-circle-exclamation text-danger" v-if="customer.errorMessage"></i>
                    </div>
                  </div>
                  <div class="choose-product" v-else @click="openCustomerBox" :class="[{'shadow-sm': customer.box}, {'customer-div': Object.keys(customer.info).length > 0}]">
                  <span class="choose-product-label" v-if="Object.keys(customer.info).length > 0">
                   <div class="customer-name">
                     <div class="customer-name-name">
                       <i class="fa-regular fa-user" style="color: #7367F0"></i> <strong>{{
                         customer.info.name
                       }} {{ customer.info.surname }}</strong>
                     </div>
                     <div class="customer-name-info">
                       <span><i class="fa-regular fa-envelope"></i> {{ customer.info.email ?? $t('Not defined') }}</span>
                       <span><i class="fa-regular fa-phone"></i> {{ customer.info.phones.phone ?? $t('Not defined') }}</span>
                     </div>
                   </div>
                  </span>
                    <div v-else class="icon-with-name">
                      <span class="choose-product-label">{{$t('Choose')}}</span>
                      <i class="fa-solid fa-circle-exclamation text-danger" v-if="customer.errorMessage"></i>
                    </div>
                  </div>
                  <div class="choose-product-list" :class="customer.box ? 'shadow-sm' : ''" v-show="customer.box">
                    <div class="choose-product-input-area">
                      <input type="text" v-model="customer.searchArea" :id="`choose-customer-input`" :placeholder="$t('Search Customer')" class="form-control m-3 choose-product-input" @keyup="searchInputCustomer">
                      <b-spinner label="Spinning" small class="input-spinner" v-if="customer.loading"></b-spinner>
                    </div>
                    <div class="choose-product-list-item" v-for="cust in customer.searchResult" :key="cust.$index"
                         @click="setCustomerSearch(cust)">
                      <strong class="choose-product-list-item-name">{{ cust.name }} {{ cust.surname }} <small
                        style="font-size: 10px">{{ cust.customerType.name }}</small></strong>
                      <div class="choose-product-list-item-info">
                        <small style="font-size: 11px"><strong>{{$t('E-Mail')}}:</strong> {{ cust.email }} </small>
                        <small style="font-size: 11px"><strong>{{$t('Phone')}}:</strong> {{ cust.phones.phone }} </small>
                      </div>
                    </div>
                  </div>
                </div>
                <button class="addDiscount" style="margin-top: 12px" v-b-modal.customer-add-modal>
                  <i class="fa fa-plus"></i>
                </button>
              </div>
              <div>
                <p>{{ date }}</p>
              </div>
            </div>
            <div class="add-invoice-inputs">
              <p class="page-title">
                {{$t('Product')}} / {{$t('Service')}}
              </p>
              <div id="invoice-loop-item" v-for="(order, key) in order_items" :key="order.$index">
                <div class="row">
                  <div class="col-sm-12 col-lg-3">
                    <div class="form-group">
                      <legend class="bv-no-focus-ring col-form-label pt-0">{{$t('Product')}}</legend>
                    </div>
                    <div class="choose-product-container" v-b-tooltip.hover :title="order.name" v-click-outside="(event) => order.openProductBox = false">
                      <div class="choose-product" :class="[{'shadow-sm': order.openProductBox}]" @click="openProductBox(key)">
                        <span class="choose-product-label" v-if="order.name">{{ order.name }}</span>
                        <div v-else class="icon-with-name">
                          <span class="choose-product-label">{{$t('Choose Product')}}</span>
                          <i class="fa-solid fa-circle-exclamation text-danger" v-if="order.errorMessage"></i>
                        </div>
                      </div>
                      <div class="choose-product-list" v-show="order.openProductBox">
                        <div class="choose-product-input-area">
                          <input type="text" v-model="order.searchArea" :id="`choose-product-input-${key}`"
                                 class="form-control m-3 choose-product-input"
                                 @keyup="searchInput(key)">
                          <b-spinner label="Spinning" small class="input-spinner" v-if="order.loading"></b-spinner>
                        </div>
                        <span class="choose-product-list-item" v-for="search in order.searchResult" :key="search.$index" @click="setSearchInput(key, search)"><strong>{{ search.brand_name }}</strong> {{search.name }} - {{ search.sku }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12 col-lg-2">
                    <b-form-group id="dynamic-unit-group" class="" >
                      <template #label>
                        {{$t('Unit Price')}} <i class="fa-duotone fa-circle-info" v-b-tooltip.hover :title="$t('Unit price excluding VAT')"></i>
                      </template>
                      <b-input-group>
                        <b-form-input v-model="order.price" v-money="money" @keyup="getTotal(key)"></b-form-input>
                        <b-input-group-append>
                          <b-form-select v-model="selected" :options="currency"></b-form-select>
                        </b-input-group-append>
                      </b-input-group>
                    </b-form-group>
                  </div>
                  <div class="col-sm-12 col-lg-2">
                    <b-form-group id="dynamic-unit-group" class="" :label="$t('Count')">
                      <b-input-group>
                        <b-form-input type="number" @keyup="getTotal(key)" @change="getTotal(key)"  v-model="order.quantity" :min="1" :max="order.maxQuantity"></b-form-input>
                        <b-input-group-append>
                          <b-form-select v-model="selected" :options="unit"></b-form-select>
                        </b-input-group-append>
                      </b-input-group>
                    </b-form-group>
                  </div>
                  <div class="col-sm-12 col-lg-2">
                    <b-form-group id="dynamic-unit-group" class="" :label="$t('VAT')">
                      <b-input-group append="%">
                        <input class="form-control" v-model="order.tax_rate" @keyup="getTotal(key)"></input>
                      </b-input-group>
                    </b-form-group>
                  </div>
                  <div class="col-sm-12 col-lg-2">
                    <b-form-group id="dynamic-unit-group" class="" :label="$t('Total')">
                      <b-input-group>
                        <b-form-input v-model="order.total" :id="`total-price-${key}`" @keyup="productLineTotalChange(key)" v-money="money"></b-form-input>
                        <b-input-group-append>
                          <b-form-select v-model="selected" :options="currency"></b-form-select>
                        </b-input-group-append>
                      </b-input-group>
                    </b-form-group>
                  </div>
                  <div class="col-sm-12 col-lg-1">
                    <b-form-group id="add-invoice-inline-btn-group" class="" label="" style="margin-top: 21px">
                      <b-dropdown right>
                        <template #button-content>
                          <i class="fa-solid fa-plus"></i>
                        </template>
                        <b-dropdown-item class="drop-btn-content" @click="addService(key)">
                          <i class="fa-solid fa-file"></i>
                          {{$t('Add Service')}}
                        </b-dropdown-item>
                        <b-dropdown-item class="drop-btn-content" href="#">
                        <span @click="setProductDiscount(key)" v-if="order.discounts.length == 0">
                            <i class="fa-solid fa-percent"></i>
                            {{$t('Add Discount')}}
                        </span>
                        </b-dropdown-item>
                        <b-dropdown-item class="drop-btn-content" @click="addDesc(key)">
                          <i class="fa-solid fa-file"></i>
                          {{$t('Add/Remove Description')}}
                        </b-dropdown-item>
                      </b-dropdown>
                      <button class="no-bg" v-if="key != 0" @click="deleteProductLine(key)">
                        <i class="fa-solid fa-trash"></i>
                      </button>
                    </b-form-group>
                  </div>
                </div>
                <div class="row" v-for="(discount, discountkey) in order.discounts" :key="discount.$index" style="display: flex; align-items: center">
                  <div class="col-sm-12 col-lg-8"></div>
                  <div class="col-sm-12 col-lg-3">
                    <b-form-group id="dynamic-unit-group" class="" :label="$t('Discount')">
                      <b-input-group style="align-items: center" class="prepend-icon">
                        <b-input-group-prepend>
                          <b-form-select v-model="discount.discount_type_id" :options="discountType"></b-form-select>
                        </b-input-group-prepend>
                        <b-form-input v-money="money" @keyup="getTotal(key)" v-model="discount.amount" v-if="discount.type == 'amount'"></b-form-input>
                        <input type="text" v-if="discount.type == 'rate'" @keyup="getTotal(key)" v-model="discount.rate" class="form-control" />
                        <b-input-group-append>
                          <b-form-select v-model="discount.type" :options="discount_unit" @change="changeProductLineDiscountType(key, discountkey)"></b-form-select>
                        </b-input-group-append>
                      </b-input-group>
                    </b-form-group>
                  </div>
                  <div class="col-sm-12 col-lg-1">
                    <i class="fa-solid fa-trash" style="cursor: pointer; margin-left: 7px; margin-top: 14px; color: #777777" @click="deleteDiscountProduct(key, discountkey)"></i>
                  </div>
                </div>
                <div id="services-lines" v-for="(service, servicekey) in order.services" :key="service.$index" v-if="order.services.length > 0">
                  <div class="row">
                    <div class="col-sm-12 col-lg-3">
                      <div class="product-line-services">
                        <i class="fa fa-boxes"></i>
                        <div class="choose-product-container" v-b-tooltip.hover :title="service.name" v-click-outside="(event) => service.openSearchBox = false">
                          <div class="choose-product" :class="[{'shadow-sm': service.openSearchBox}]" @click="openProductServiceBox(key, servicekey)">
                            <span class="choose-product-label" v-if="service.name">{{ service.name }}</span>
                            <div v-else class="icon-with-name">
                              <span class="choose-product-label">{{$t('Choose Service')}}</span>
                            </div>
                          </div>
                          <div class="choose-product-list" v-if="service.openSearchBox">
                            <div class="choose-product-input-area">
                              <input type="text" v-model="service.searchArea" :id="`service-box-${key}-${servicekey}`"
                                     class="form-control m-3 choose-product-input"
                                     @keyup="searchInputService(key, servicekey)">
                              <b-spinner label="Spinning" small class="input-spinner" v-if="service.loading"></b-spinner>
                            </div>
                            <span class="choose-product-list-item" v-for="search in service.searchResult"
                                  :key="search.$index"
                                  @click="setSearchInputService(key, servicekey, search)">{{ search.name }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-12 col-lg-2">
                      <b-form-group id="dynamic-unit-group" class="">
                        <template #label>
                          {{$t('Unit Price')}} <i class="fa-duotone fa-circle-info" v-b-tooltip.hover :title="$t('Unit price excluding VAT')"></i>
                        </template>
                        <b-input-group>
                          <b-form-input v-model="service.price" @keyup="getTotalService(key, servicekey)" v-money="money"></b-form-input>
                          <b-input-group-append>
                            <b-form-select v-model="selected" :options="currency"></b-form-select>
                          </b-input-group-append>
                        </b-input-group>
                      </b-form-group>
                    </div>
                    <div class="col-sm-12 col-lg-2">
                      <b-form-group id="dynamic-unit-group" class="" :label="$t('Count')">
                        <b-input-group>
                          <b-form-input type="number" @change="getTotalService(key, servicekey)" @keyup="getTotalService(key, servicekey)" v-model="service.quantity" :min="1" :max="order.maxQuantity"></b-form-input>
                          <b-input-group-append>
                            <b-form-select v-model="selected" :options="unit"></b-form-select>
                          </b-input-group-append>
                        </b-input-group>
                      </b-form-group>
                    </div>
                    <div class="col-sm-12 col-lg-2">
                      <b-form-group id="dynamic-unit-group" class="" :label="$t('VAT')">
                        <b-input-group append="%">
                          <b-form-input v-model="service.tax_rate" @keyup="getTotalService(key, servicekey)"></b-form-input>
                        </b-input-group>
                      </b-form-group>
                    </div>
                    <div class="col-sm-12 col-lg-2">
                      <b-form-group id="dynamic-unit-group" class="" :label="$t('Total')">
                        <b-input-group>
                          <b-form-input v-model="service.total" v-money="money" :id="`total-price-service-${key}-${servicekey}`"  @keyup="productLineTotalChangeDiscount(key, servicekey)"></b-form-input>
                          <b-input-group-append>
                            <b-form-select v-model="selected" :options="currency"></b-form-select>
                          </b-input-group-append>
                        </b-input-group>
                      </b-form-group>
                    </div>
                    <div class="col-sm-12 col-lg-1">
                      <b-form-group id="add-invoice-inline-btn-group" class="" label="" style="margin-top: 21px">
                        <button class="no-bg" @click="deleteProductLineService(key, servicekey)">
                          <i class="fa-solid fa-trash"></i>
                        </button>
                      </b-form-group>
                    </div>
                  </div>
                </div>
                <div class="products-double-flex m-0 no-border max-width-743 align-items-flex-start isDesc" v-if="order.isDesc">
                  <b-form-group :label="$t('Description')+':'">
                    <b-form-textarea
                      :placeholder="$t('Description')"
                      v-model="order.description"
                      rows="3"
                      max-rows="6"
                    ></b-form-textarea>
                  </b-form-group>
                </div>
              </div>
              <b-button class="add-more mt-3 ml-1" @click="addProductLine">
                <i class="fa-solid fa-plus"></i>
                {{$t('Add Product')}}
              </b-button>
              <div class="bill-info">
                <div class="bill-info-line">
                  <strong>{{$t('Sub Total')}}:</strong>
                  <span>{{ subTotal }} ₺
                    <button class="addDiscount" @click="addDiscount">
                      <i class="fa-solid fa-plus"></i>
                    </button>
                  </span>
                </div>
                <div class="bill-info-line" v-for="(discount, key) in order_discounts" :key="discount.$index">
                  <b-form-group id="dynamic-unit-group" class="" :label="$t('Sub Total Discount')" style="width: 100%">
                    <b-input-group class="prepend-icon">
                      <b-input-group-prepend>
                        <b-form-select v-model="discount.discount_type_id" :options="discountType"></b-form-select>
                      </b-input-group-prepend>
                      <b-form-input v-money="money" v-model="discount.amount" v-if="discount.type == 'amount'"></b-form-input>
                      <input type="text" v-if="discount.type == 'rate'" v-model="discount.rate" class="form-control" />
                      <b-input-group-append>
                        <b-form-select v-model="discount.type" :options="discount_unit" @change="changeProductLineDiscountTypeTotal(key)"></b-form-select>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                  <button class="addDiscount" @click="deleteDiscount(key)" style="margin-top: 12px">
                    <i class="fa-solid fa-trash"></i>
                  </button>
                </div>
                <div class="bill-info-line">
                  <strong>{{$t('Line Discount')}}:</strong>
                  <span>{{ lineDiscountPrice }} ₺
                  </span>
                </div>
                <div class="bill-info-line">
                  <strong>{{$t('VAT')}}:</strong>
                  <span>{{ totalVat }} ₺
                  </span>
                </div>
                <div class="bill-info-line">
                  <strong>{{$t('Total')}}:</strong>
                  <span>{{ totalPrice}} ₺</span>
                </div>
              </div>
              <div class="incoive-notes-section">
                <b-form-group :label="$t('Note')+':'">
                  <b-form-textarea v-model="description" rows="3" max-rows="6"></b-form-textarea>
                </b-form-group>
                <hr>
                <div class="row">
                  <div class="col-6">
                    <b-form-group :label="$t('Appointment Date')+':'">
                      <b-form-datepicker :labelNoDateSelected="$t('The date was not chosen.')" labelHelp="" v-model="appointment_date" locale="tr" :placeholder="$t('Choose')"></b-form-datepicker>
                    </b-form-group>
                  </div>
                  <div class="col-6">
                    <b-form-group :label="$t('Appointment Hour')+':'">
                      <b-form-timepicker :labelNoTimeSelected="$t('The hour was not chosen.')" :labelCloseButton="$t('Close')" v-model="appointment_hour" locale="tr" :placeholder="$t('Choose')"></b-form-timepicker>
                    </b-form-group>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3">
        <div class="product-detail-right-wrapper">
          <b-button @click="updateOrder">{{$t('Update')}}</b-button>
          <b-button class="add-invoice-prev-btn mt-2">{{$t('Preview')}}</b-button>
          <b-form-group id="channel-group" :label="$t('Payment Channel')+':'">
            <b-form-select v-model="payment_channel_id" :options="paymentChannels" class="add-invoice-payment-c-selector"></b-form-select>
          </b-form-group>
          <hr>
          <b-form-group id="channel-group" :label="$t('Order Channel')+':'">
            <b-form-select v-model="order_channel_id" :options="orderChannels" class="add-invoice-payment-c-selector"></b-form-select>
          </b-form-group>
          <hr>
          <b-form-group id="channel-group" :label="$t('Order Status')+':'">
            <b-form-select v-model="order_status_id" :options="orderStatuses" class="add-invoice-payment-c-selector"></b-form-select>
          </b-form-group>
          <hr>
          <b-form-group id="channel-group" :label="$t('Cargo Amount')+':'">
            <b-input v-model="total_cargo" v-money="money2" />
          </b-form-group>
          <hr>
        </div>
      </div>
    </div>
    <div v-else>
      <b-overlay :show="$store.state.sales.item.loading">
        <div class="row">
          <div class="col-lg-9">
            <div class="product-detail-left-wrapper">
            </div>
          </div>
          <div class="col-lg-3">
            <div class="product-detail-right-wrapper" style="height: 100vh"></div>
          </div>
        </div>
      </b-overlay>
    </div>
  </DefaultLayout>
</template>

<script>
import DefaultLayout from "@/layouts/default.vue";
import PageBreadcrumbs from "@/components/PageBreadcrumbs.vue";
import vClickOutside from 'v-click-outside'
import events from "events";

export default {
  name: "SaleUpdateView",
  components: {PageBreadcrumbs, DefaultLayout},
  directives: {
    clickOutside: vClickOutside.directive
  },
  data() {
    return {
      breadcrumbs: [
        {
          name: this.$i18n.t('Sales'),
          path: '/sales'
        },
        {
          name: this.$i18n.t('Edit'),
          path: '/#'
        }
      ],
      order_items: [],
      money: {
        decimal: ',',
        thousands: '.',
        prefix: '',
        suffix: '',
        precision: 2,
      },
      money2: {
        decimal: ',',
        thousands: '.',
        prefix: '₺',
        suffix: '',
        precision: 2,
      },
      currency: [
        {value: null, text: '₺', disabled: true}, ,
        {value: 'usd', text: '$', disabled: true},
        {value: 'euro', text: '€', disabled: true},
      ],
      selected: null,
      selected_customer: null,
      unit: [
        {value: null, text: '| adet', disabled: true},
      ],
      discount_unit: [
        {value: 'amount', text: '₺'}, ,
        {value: 'rate', text: '%'},
      ],
      discountType: [],
      order_discounts: [],
      paymentChannels: [],
      orderChannels: [],
      orderStatuses: [],
      payment_channel_id: '',
      order_channel_id: '1',
      order_status_id: '',
      total_cargo: '',
      appointment_date: '',
      appointment_hour: '',
      customer: {},
      description: ''
    }
  },
  mounted() {
    this.$store.dispatch('sales/getDiscountType').then((response) => {
      this.discountType = response
    })
    this.$store.dispatch('sales/getPaymentChannel').then((response) => {
      this.paymentChannels = response
    })
    this.$store.dispatch('sales/getOrderChannel').then((response) => {
      this.orderChannels = response
    })
    this.$store.dispatch('sales/getOrderStatus').then((response) => {
      this.orderStatuses = response
    })
    this.$store.dispatch('sales/getSale', this.$route.params.id).then((response) => {
      var order = response
      var item = []
      var _this = this
      this.payment_channel_id = order.paymentChannel.id
      this.order_channel_id = order.orderChannel.id
      this.order_status_id = order.orderStatus.id
      this.total_cargo = parseFloat(order.total_cargo).toFixed(2)
      this.description = order.description
      if (order.appointment) {
        var date = order.appointment.split(' ')
        var parts = date[0].split('-');
        // Yeniden düzenlenmiş tarih stringi oluşturmak
        var outputDate = parts[2] + '-' + parts[1] + '-' + parts[0];
        this.appointment_date = outputDate
        this.appointment_hour = date[1]
      }
      this.customer = {
        box: false,
        searchArea: '',
        searchResult: [],
        car: {},
        info: {
          id: order.customer_id,
          name: order.customer_name,
          surname: order.customer_surname,
          phones: {
            "id": 3,
            "is_default": true,
            "phone": order.customer_phone
          },
          email: order.customer_email,
          customerType: {
            "id": 1,
            "name": this.$i18n.t('Individual')
          }
        }
      }
      var orderDiscount = []
      order.discounts.forEach(function (dis) {
        var type = "amount"
        if (dis.discount_rate === "0,00") {
          type = "amount"
        }
        if (dis.discount_amount === "0,00") {
          type = "rate"
        }

        orderDiscount.push({
          id: dis.id,
          discount_type_id: dis.discountType.id,
          rate: dis.discount_rate,
          type: type,
          amount: parseFloat(dis.discount_amount).toFixed(2)
        })
      })
      this.order_discounts = orderDiscount
      order.items.forEach(item => {
        var services = []
        item.services.forEach(function(service) {
          var serviceTotal = (service.price * service.quantity) + ((service.price * service.quantity) * ((service.tax_rate * 100) / 100))
          services.push({
            name: service.reference.name,
            id: service.id,
            service_id: service.reference.id,
            quantity: service.quantity,
            price: parseFloat(service.price).toFixed(2),
            tax_rate: service.tax_rate * 100,
            description: service.description,
            total: parseFloat(serviceTotal).toFixed(2),
            searchArea: '',
            searchResult: '',
            openSearchBox: false,
            loading: false
          })
        })
        var discounts = []
        item.discounts.forEach(function(discount) {
          var type = 'amount'
          if (discount.discount_rate) {
            type = 'rate'
          }
          discounts.push({
            amount: parseFloat(discount.discount_amount).toFixed(2),
            rate: discount.discount_rate ? discount.discount_rate : '',
            discount_type_id: discount.discountType.id,
            type: type,
            id: discount.id
          })
        })
        var total = (item.price * item.quantity) + ((item.price * item.quantity) * ((item.tax_rate * 100) / 100))
        _this.order_items.push({
          tire_stock_id: item.reference_id,
          id: item.id,
          quantity: item.quantity,
          maxQuantity: 4,
          price: parseFloat(item.price).toFixed(2),
          name: `${item.brand} ${item.name}`,
          tax_rate: item.tax_rate * 100,
          description: item.description,
          isDesc: item.description ? true : false,
          total: parseFloat(item.amount).toFixed(2),
          openProductBox: false,
          searchArea: '',
          searchResult: [],
          loading: false,
          afterSearch: false,
          errorMessage: '',
          services: services,
          discounts: discounts,
          radius: '',
          tire_brand_id: ''
        })
      })
    })
  },
  computed: {
    events() {
      return events
    },
    date() {
      const today = new Date();
      const day = String(today.getDate()).padStart(2, '0');
      const month = String(today.getMonth() + 1).padStart(2, '0');
      const year = today.getFullYear();

      return `${day}/${month}/${year}`;
    },
    totalPrice() {
      var total = this.subTotal
      total = total.replace(".", "")
      total = parseFloat(total.replace(",", "."))
      var lineDiscount = this.lineDiscountPrice.replace(".", "")
      lineDiscount = parseFloat(lineDiscount.replace(",", "."))
      total = total - lineDiscount
      var _this = this
      this.order_discounts.forEach(function (discount) {
        if (discount.type == 'amount') {
          var amount = _this.getTotalDiscountWithDouble(discount.amount)
          total -= parseFloat(amount)
        } else if (discount.type == 'rate') {
          var rate = discount.rate
          total -= (total * (rate / 100))
        }
      })
      var options = {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      };
      if (this.total_cargo) {
        var totalcargo = this.total_cargo.substring(1, this.total_cargo.length)
        var totalcargo = totalcargo.replace(".", "")
        totalcargo = totalcargo.replace(",", ".")
        total += parseFloat(totalcargo)
      }
      if (this.totalVat != '0,00') {
        var vat = this.totalVat.replace(".", "")
        vat = vat.replace(",", ".")
        total += parseFloat(vat)
      }
      return total.toLocaleString("tr-TR", options)
    },
    subTotal() {
      var total = 0
      var _this = this
      if (this.order_items.length > 0) {
        this.order_items.forEach(function (item, key) {
          total += (_this.getTotalWithDoublePrice(key) * item.quantity)
          if (item.services.length > 0) {
            item.services.forEach(function (service, servicekey) {
              total += (_this.getTotalServiceWithDoublePrice(key, servicekey) * service.quantity)
            })
          }
        })
      }
      var options = {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      };
      console.log(total)
      return total.toLocaleString("tr-TR", options)
    },
    lineDiscountPrice() {
      var total = 0
      var _this = this
      if (this.order_items.length > 0) {
        this.order_items.forEach(function (item, key) {
          item.discounts.forEach(function (discount, discountkey) {
            if (discount.type == 'amount') {
              var amount = _this.getTotalDiscountWithDouble(discount.amount)
              total += parseFloat(amount)
            } else if (discount.type == 'rate') {
              var rate = discount.rate
              var pprice = item.price.replace(".", "")
              pprice = pprice.replace(",", ".")
              total += parseFloat((pprice * item.quantity) * ((rate / 100)))
            }
          })
        })
      }
      var options = {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      };
      return parseFloat(total).toLocaleString("tr-TR", options)
    },
    totalVat() {
      var total = 0
      var _this = this
      this.order_discounts.forEach(function (discount) {
        if (discount.type == 'amount') {
          var amount = _this.getTotalDiscountWithDouble(discount.amount)
          total += parseFloat(amount)
        } else if (discount.type == 'rate') {
          var rate = discount.rate
          var t = _this.subTotal.replace(".", "")
          t = t.replace(",", ".")
          var lineDiscount2 = _this.lineDiscountPrice.replace(".", "")
          lineDiscount2 = parseFloat(lineDiscount2.replace(",", "."))
          t = t - lineDiscount2
          total += parseFloat(parseFloat(t) * ((rate / 100)))
        }
      })
      var subtotal = this.subTotal.replace(".", "")
      subtotal = subtotal.replace(",", ".")
      var lineDiscount = this.lineDiscountPrice.replace(".", "")
      lineDiscount = parseFloat(lineDiscount.replace(",", "."))
      subtotal = subtotal - lineDiscount
      var ratio = (subtotal - total) / subtotal
      var vat = 0
      this.order_items.forEach(function (item) {
        if (item.total != '0,00') {
          var t = item.total.replace(".", "")
          t = t.replace(",", ".")
        } else {
          var t = 0
        }
        if (item.price != '0,00') {
          var p = item.price.replace(".", "")
          p = p.replace(",", ".")
        } else {
          var p = 0
        }
        if (ratio > 0) {
          if (item.discounts.length > 0) {
            item.discounts.forEach(function (discountitem) {
              if (discountitem.type == 'amount') {
                var amount = _this.getTotalDiscountWithDouble(discountitem.amount)
                vat += (((p * item.quantity) - amount) * (item.tax_rate / 100)) * ratio
              } else if (discountitem.type == 'rate') {
                var rate = discountitem.rate
                vat += (((p * item.quantity) - ((p * item.quantity) * rate / 100)) * (item.tax_rate / 100)) * ratio
              }
            })
          } else {
            console.log(t,p, ratio)
            vat += (t - (p * item.quantity)) * ratio
            //console.log((t - (p * item.quantity)) * ratio)
          }
        } else {
          vat += t - ( t / (1 + (item.tax_rate / 100)))
        }
        item.services.forEach(function (service) {
          var servicePrice = service.price.replace(".", "")
          servicePrice = servicePrice.replace(",", ".")
          var totalPrice = service.total.replace(".", "")
          totalPrice = totalPrice.replace(",", ".")
          if (ratio > 0) {
            vat += (totalPrice - (servicePrice * service.quantity)) * ratio
          } else {
            vat += totalPrice - (servicePrice * service.quantity)
          }
        })
      })
      var options = {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      };
      return parseFloat(vat).toLocaleString("tr-TR", options)
    },
  },
  methods: {
    updateOrder() {
      var orders = []
      this.order_items.forEach(function(item) {
        var services = []
        item.services.forEach(function(service) {
          var priceser = service.price.replace(".", "")
          priceser = priceser.replace(",", ".")
          services.push({
            id: service.id,
            service_id: service.service_id,
            quantity: parseInt(service.quantity),
            price: priceser,
            tax_rate: parseFloat(service.tax_rate).toFixed(2),
            description: service.description
          })
        })
        var discounts = []
        item.discounts.forEach(function(discount) {
          var pricedis = discount.amount.replace(".", "")
          pricedis = pricedis.replace(",", ".")
          discounts.push({
            id: discount.id,
            discount_type_id: discount.discount_type_id,
            rate: discount.rate,
            amount: pricedis
          })
        })
        var price = item.price.replace(".", "")
        price = price.replace(",", ".")
        orders.push({
          id: item.id,
          tire_stock_id: item.tire_stock_id,
          quantity: item.quantity,
          price: price,
          tax_rate: parseFloat(item.tax_rate).toFixed(2),
          description: item.description,
          services: services,
          discounts: discounts
        })
      })
      var orderDiscount = []
      this.order_discounts.forEach(function (dis) {
        var pricedisc = dis.amount.replace(".", "")
        pricedisc = pricedisc.replace(",", ".")
        orderDiscount.push({
          id: dis.id,
          discount_type_id: dis.discount_type_id,
          rate: dis.rate,
          amount: pricedisc
        })
      })
      var totalcargo = this.total_cargo.substring(1, this.total_cargo.length)
      var totalcargo = totalcargo.replace(".", "")
      totalcargo = totalcargo.replace(",", ".")
      var appointment = ""
      if (this.appointment_date && this.appointment_hour) {
        appointment = `${this.appointment_date} ${this.appointment_hour}`
      }
      var order = {
        payment_channel_id: this.payment_channel_id,
        order_channel_id: this.order_channel_id,
        order_status_id: this.order_status_id,
        total_cargo: totalcargo,
        order_items: orders,
        order_discounts: orderDiscount,
        customer_id: this.customer.info.id,
        appointment_date: appointment
      }
      this.$axios.put(`/tire/orders/${this.$route.params.id}`, order).then((response) => {
        if (response.data.status) {
          this.$swal.fire({
            iconHtml: '<img src="/img/success.svg" width="32" height="32">',
            position: 'top-end',
            title: this.$i18n.t('Order updated successfully.'),
            showConfirmButton: false,
            timer: 2500,
            toast: true,
            customClass: {
              icon: 'swal2-no-border'
            }
          }).then(() => {
            this.$router.push('/sale/'+this.$route.params.id)
            // this.$router.push('/sales')
          })
        }
      }).catch(error => {
        if (error.response.data.responseData) {
          if (error.response.data.responseData.error) {
            if (error.response.data.responseData.error.length > 0) {
              this.$swal.fire({
                iconHtml: '<img src="/img/error.svg" width="32" height="32">',
                position: 'top-end',
                title: error.response.data.responseData.error[0].message,
                showConfirmButton: false,
                timer: 2500,
                toast: true,
                customClass: {
                  icon: 'swal2-no-border'
                }
              })
            }
          }

          if (error.response.data.responseData.message) {
            this.$swal.fire({
              iconHtml: '<img src="/img/error.svg" width="32" height="32">',
              position: 'top-end',
              title: error.response.data.responseData.message,
              showConfirmButton: false,
              timer: 2500,
              toast: true,
              customClass: {
                icon: 'swal2-no-border'
              }
            })
          }
        } else {
          this.$swal.fire({
            iconHtml: '<img src="/img/error.svg" width="32" height="32">',
            position: 'top-end',
            title: error.response.data.statusMessage,
            showConfirmButton: false,
            timer: 2500,
            toast: true,
            customClass: {
              icon: 'swal2-no-border'
            }
          })
        }
      })
    },
    addDiscount() {
      this.order_discounts.push({
        amount: '',
        rate: '',
        discount_type_id: '2',
        type: 'amount'
      })
    },
    deleteDiscount(key) {
      this.order_discounts.splice(key, 1)
    },
    changeProductLineDiscountType(key, discountkey) {
      if (this.order_items[key].discounts[discountkey].type == 'amount') {
        this.order_items[key].discounts[discountkey].rate = ''
      }
      if (this.order_items[key].discounts[discountkey].type == 'rate') {
        this.order_items[key].discounts[discountkey].amount = ''
      }
    },
    changeProductLineDiscountTypeTotal(key) {
      if (this.order_discounts[key].type == 'amount') {
        this.order_discounts[key].rate = ''
      }
      if (this.order_discounts[key].type == 'rate') {
        this.order_discounts[key].amount = ''
      }
    },
    deleteDiscountProduct(key, discountkey) {
      var price =this.order_items[key].price.replace(".", "")
      price = parseFloat(price.replace(",", ".")).toFixed(2)
      var quantity = this.order_items[key].quantity
      var options = {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      };
      var result = price * quantity
      result = result + (result * (parseFloat(this.order_items[key].tax_rate) / 100))
      this.order_items[key].total = result.toLocaleString("TR-tr", options)
      this.order_items[key].discounts.splice(discountkey, 1);
    },
    setProductDiscount(key) {
      if (this.order_items[key].discounts.length == 0) {
        if (this.order_items[key].name != '') {
          this.order_items[key].discounts.push({
            amount: '',
            rate: '',
            discount_type_id: '2',
            type: 'amount'
          })
        } else {
          this.order_items[key].errorMessage = this.$i18n.t('Please select a product for this row.')
        }
      }
    },
    removeCustomer() {
      this.customer = {
        box: false,
        searchArea: '',
        searchResult: [],
        car: {},
        info: {}
      }
    },
    setCustomerSearch(customer) {
      this.$store.dispatch('sales/searchCustomerCar', customer.id).then((response) => {
        if (response) {
          this.customer.car = response
        }
        this.customer.info = customer
        this.customer.box = false
      })
    },
    addService(key) {
      if (this.order_items[key].name != '') {
        this.order_items[key].services.push({
          service_id: '',
          quantity: '',
          price: '',
          tax_rate: '20',
          description: '',
          searchArea: '',
          searchResult: '',
          openSearchBox: false,
          loading: false
        })
      } else {
        this.order_items[key].errorMessage = this.$i18n.t('Please select a product for this row.')
      }
    },
    openProductServiceBox(key, servicekey) {
      this.order_items[key].services[servicekey].openSearchBox = !this.order_items[key].services[servicekey].openSearchBox
      if (this.order_items[key].services[servicekey].openSearchBox == true) {
        setTimeout(() => document.getElementById(`service-box-${key}-${servicekey}`).focus(), 1)
      }
    },
    productLineTotalChange(key) {
      var price = document.getElementById(`total-price-${key}`).value.replace(".", "")
      price = parseFloat(price.replace(",", ".")).toFixed(2)
      var quantity = this.order_items[key].quantity
      var options = {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      };
      var total = 0
      var _this = this
      this.order_items[key].discounts.forEach(function (discount) {
        if (discount.type == 'amount') {
          var amount = _this.getTotalDiscountWithDouble(discount.amount)
          total += amount
        } else if (discount.type == 'rate') {
          var rate = discount.rate
          var product = _this.getTotalWithDouble(key)
          total += product * ((rate / 100))
        }
      })
      var result = price / (1 + ((parseFloat(this.order_items[key].tax_rate)) / 100))
      result = result + parseFloat(total)
      result = (result / quantity)
      result = result.toLocaleString("tr-TR", options);
      this.order_items[key].price = result
    },
    getTotalLineDiscount(key) {

    },
    productLineTotalChangeDiscount(key,servicekey) {
      var price = document.getElementById(`total-price-service-${key}-${servicekey}`).value.replace(".", "")
      price = parseFloat(price.replace(",", ".")).toFixed(2)
      var quantity = this.order_items[key].services[servicekey].quantity
      var options = {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      };
      var result = price / (1 + ((parseFloat(this.order_items[key].services[servicekey].tax_rate)) / 100))
      result = result / quantity
      result = result.toLocaleString("tr-TR", options);
      this.order_items[key].services[servicekey].price = result
    },
    getTotal(key) {
      if (this.order_items[key].price != '0,00') {
        var price = this.order_items[key].price.replace(".", "")
        price = parseFloat(price.replace(",", ".")).toFixed(2)
        var quantity = this.order_items[key].quantity
        var options = {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        };
        var total = 0
        var _this = this
        if (this.order_items[key].discounts.length > 0 ) {
          this.order_items[key].discounts.forEach(function (discount) {
            if (discount.type == 'amount') {
              var amount = _this.getTotalDiscountWithDouble(discount.amount)
              total += amount
              var result = (quantity * (price)) - total
              result = result + (result * (parseFloat(_this.order_items[key].tax_rate) / 100))
              result = result.toLocaleString("tr-TR", options);
              _this.order_items[key].total = result
            } else if (discount.type == 'rate') {
              var rate = discount.rate
              var productTotalPrice = (price * quantity) * (1 + (_this.order_items[key].tax_rate / 100))
              var dc = productTotalPrice - ((productTotalPrice) * ((rate / 100)))
              _this.order_items[key].total = dc.toLocaleString("tr-TR", options);
            }
          })
        } else {
          var result = (quantity * (price))
          result = result + (result * (parseFloat(this.order_items[key].tax_rate) / 100))
          result = result.toLocaleString("tr-TR", options);
          this.order_items[key].total = result
        }
      }
    },
    getTotalWithDouble(key) {
      var price = this.order_items[key].total.replace(".", "")
      price = parseFloat(price.replace(",", ".")).toFixed(2)
      return parseFloat(price)
    },
    getTotalWithDoublePrice(key) {
      var price = this.order_items[key].price.replace(".", "")
      price = parseFloat(price.replace(",", ".")).toFixed(2)
      return parseFloat(price)
    },
    getTotalServiceWithDouble(key, servicekey) {
      if (this.order_items[key].services[servicekey].total) {
        var price = this.order_items[key].services[servicekey].total.replace(".", "")
        price = parseFloat(price.replace(",", ".")).toFixed(2)
        return parseFloat(price)
      }
    },
    getTotalServiceWithDoublePrice(key, servicekey) {
      if (this.order_items[key].services[servicekey].total) {
        var price = this.order_items[key].services[servicekey].price.replace(".", "")
        price = parseFloat(price.replace(",", ".")).toFixed(2)
        return parseFloat(price)
      }
    },
    getTotalDiscountWithDouble(amount) {
      var price = amount.replace(".", "")
      price = parseFloat(price.replace(",", ".")).toFixed(2)
      return price
    },
    getTotalService(key, servicekey) {
      var price = this.order_items[key].services[servicekey].price.replace(".", "")
      price = parseFloat(price.replace(",", ".")).toFixed(2)
      var quantity = this.order_items[key].services[servicekey].quantity
      var options = {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      };
      var result = (quantity * price)
      result = result + (result * (parseFloat(this.order_items[key].services[servicekey].tax_rate) / 100))
      result = result.toLocaleString("tr-TR", options);
      this.order_items[key].services[servicekey].total = result
    },
    changeQuantityInput(key) {
      this.order_items[key].quantity = document.getElementById(`quantity-${key}`).value
    },
    setSearchInput(key, search) {
      this.order_items[key].name = `${search.brand_name} ${search.name} - ${search.sku}`
      this.order_items[key].openProductBox = false
      this.order_items[key].searchArea = search.sku
      this.order_items[key].tax_rate = search.tax_rate
      if (search.quantity > 4) {
        this.order_items[key].quantity = 4
      } else {
        this.order_items[key].quantity = search.quantity
      }
      this.order_items[key].maxQuantity = search.quantity
      var options = {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      };
      this.order_items[key].price = search.price.toLocaleString("tr-TR", options);
      this.order_items[key].total = parseFloat(search.amount * search.quantity).toLocaleString("tr-TR", options);
      this.order_items[key].radius = search.radius
      this.order_items[key].tire_brand_id = search.brand_id
      this.order_items[key].tire_stock_id = search.id
    },
    setSearchInputService(key, servicekey, search) {
      console.log(search)
      this.order_items[key].services[servicekey].name = `${search.name}`
      this.order_items[key].services[servicekey].openSearchBox = false
      this.order_items[key].services[servicekey].searchArea = search.name
      this.order_items[key].services[servicekey].quantity = this.order_items[key].quantity
      this.order_items[key].services[servicekey].maxQuantity = this.order_items[key].maxQuantity
      this.order_items[key].services[servicekey].service_id = search.id
      var options = {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      };
      this.order_items[key].services[servicekey].price = search.price.toLocaleString("tr-TR", options);
      this.order_items[key].services[servicekey].total = parseFloat(search.amount * this.order_items[key].services[servicekey].quantity).toLocaleString("tr-TR", options);
    },
    searchInputService(key, servicekey) {
      if (this.order_items[key].services[servicekey].searchArea === 0) {
        this.order_items[key].services[servicekey].searchResult = []
        this.order_items[key].services[servicekey].afterSearch = true
      }
      if (this.order_items[key].services[servicekey].searchArea.length > 2) {
        this.order_items[key].services[servicekey].loading = true
        this.$store.dispatch('sales/searchTireProductService', {
          search: this.order_items[key].services[servicekey].searchArea,
          tire_brand_id: this.order_items[key].tire_brand_id,
          radius: this.order_items[key].radius
        }).then((response) => {
          this.order_items[key].services[servicekey].searchResult = response
          this.order_items[key].services[servicekey].afterSearch = true
          this.order_items[key].services[servicekey].loading = false
        })
      }
    },
    searchInput(key) {
      if (this.order_items[key].searchArea === 0) {
        this.order_items[key].searchResult = []
        this.order_items[key].afterSearch = true
      }
      if (this.order_items[key].searchArea.length > 2) {
        this.order_items[key].loading = true
        this.$store.dispatch('sales/searchTireProduct', this.order_items[key].searchArea).then((response) => {
          this.order_items[key].searchResult = response
          this.order_items[key].afterSearch = true
          this.order_items[key].loading = false
        })
      }
    },
    searchInputCustomer() {
      if (this.customer.searchArea === 0) {
        this.customer.searchResult = []
      }
      if (this.customer.searchArea.length > 2) {
        this.customer.loading = true
        this.$store.dispatch(`sales/searchCustomer`, this.customer.searchArea).then((response) => {
          this.customer.searchResult = response
          this.customer.loading = false
        })
      }
    },
    addProductLine() {
      var size = this.order_items.length
      if (size > 0) {
        if (this.order_items[size - 1].name != '') {
          this.order_items[size - 1].errorMessage = ''
          this.order_items.push({
            tire_stock_id: '',
            quantity: '1',
            maxQuantity: 4,
            price: '',
            name: '',
            tax_rate: '20',
            description: '',
            isDesc: false,
            total: '',
            openProductBox: false,
            searchArea: '',
            searchResult: [],
            loading: false,
            afterSearch: false,
            errorMessage: '',
            services: [],
            discounts: [],
            radius: '',
            tire_brand_id: ''
          })
        } else {
          this.order_items[size - 1].errorMessage = this.$i18n.t('Please select a product for this row.')
        }
      }
    },
    addProductLineService(key, servicekey) {
      var size = this.order_items[key].services.length
      if (size > 0) {
        if (this.order_items[key].services[servicekey - 1].name != '') {
          console.log('asd')
          this.order_items[key].services[servicekey - 1].errorMessage = ''
          this.order_items[key].services.push({
            service_id: '',
            quantity: '',
            price: '',
            tax_rate: '20',
            description: '',
            searchArea: '',
            searchResult: '',
            openSearchBox: false,
            loading: false
          })
        } else {
          this.order_items[key].services[servicekey - 1].errorMessage = this.$i18n.t('Please select a product for this row.')
        }
      }
    },
    deleteProductLine(key) {
      this.order_items[key] = {
        tire_stock_id: '',
        quantity: '1',
        price: '',
        tax_rate: '20',
        description: '',
        isDesc: false,
        total: '',
        openProductBox: false,
        searchArea: ''
      }
      this.order_items.splice(key, 1);
    },
    deleteProductLineService(key, servicekey) {
      console.log(servicekey)
      this.order_items[key].services.splice(servicekey, 1);
    },
    addDesc(key) {
      this.order_items[key].isDesc = !this.order_items[key].isDesc
      if (!this.order_items[key].isDesc) {
        this.order_items[key].description = ""
      }
    },
    closeProductBox(key) {
      console.log(event)
    },
    openProductBox(key) {
      this.order_items[key].openProductBox = !this.order_items[key].openProductBox
      if (this.order_items[key].openProductBox == true) {
        this.$store.dispatch('sales/searchTireProduct', '205').then((response) => {
          this.order_items[key].searchResult = response
          this.order_items[key].afterSearch = true
          this.order_items[key].loading = false
        })
        setTimeout(() => document.getElementById(`choose-product-input-${key}`).focus(), 1)
      }
    },
    openCustomerBox() {
      this.customer.box = !this.customer.box
    },
    hideCustomerModal() {
      this.$bvModal.hide('customer-add-modal')
    },
    showAlert() {
      this.$bvModal.hide('customer-add-modal')
      // Use sweetalert2
      this.$swal.fire(
        {
          iconHtml: '<img src="/img/success.svg" width="32" height="32">',
          title: this.$i18n.t('Customer successfully added.'),
          text: '',
          showConfirmButton: false,
          timer: 1500,
          toast: true,
          customClass: {
            icon: 'swal2-no-border'
          }
        }
      )
    },
  }
}

</script>

<style scoped>

.product-line-services {
  display: flex;
  gap: 15px;
  align-items: center;
  width: 100%;
  margin-top: 17px;
}

.choose-product-container {
  position: relative;
  width: 100% !important;
}

.choose-customer-container {
  position: relative;
  max-width: 500px;
  min-width: 500px;
}


.choose-product {
  border: 1px solid #dee2e6;
  padding: .65rem .85rem !important;
  border-radius: 5px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 8px;
  background-color: #F4F4F4;
  cursor: pointer;
}

.choose-product-label {
  font-size: 14px;
  color: #6E6B7B !important;
  font-style: normal;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.icon-with-name {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.choose-product-list {
  position: absolute;
  top: 40px;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  border: 1px solid #dee2e6;
  background-color: #F4F4F4;
  border-top: none;
  z-index: 1;
  border-radius: 5px;
}

.choose-product-list-item {
  color: #6E6B7B !important;
  border-top: 1px solid #dee2e6;
  padding: .65rem .85rem;
  font-size: 14px;
  cursor: pointer;
}

.choose-product-list-item:hover {
  background-color: #dadada;
  cursor: pointer;
}

.choose-product-input {
  width: 92%;
}

.input-group-text {
  background-color: #F4F4F4;
  border-left: 1px solid #dee2e6 !important;
}

.choose-product-input-area {
  position: relative;
}

.input-spinner {
  position: absolute;
  right: 26px;
  top: 27px;
}


#services-lines:first-child {
  border-top: 1px solid #dee2e6;
  margin-bottom: 20px;
}

#services-lines:last-child{
  border-bottom: 1px solid #dee2e6;
  margin-bottom: 20px;
}
.isDesc {
  border-bottom: 1px solid #dee2e6 !important;
  width: 100%;
  max-width: 100% !important;
  margin-bottom: 20px !important;
}

.choose-product-list-item-info {
  display: flex;
  flex-direction: column;
  border-top: 1px solid #dee2e6;
  padding-top: 5px;
  margin-top: 5px;
}

.choose-product-list-item-name {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.customer-name {
  display: flex;
  gap: 5px;
  flex-direction: column;
}

.customer-div {
  height: auto;
  width: auto !important;
}

.customer-name-name {
  font-size: 20px;
}

.customer-name-info {
  font-size: 12px;
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.customer-info {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  position: relative;
}

.customer-div .choose-product-label {
  width: 1000%;
}

.discount-line {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.add-invoice-flex-total-wrapper {
  flex: 1;
}

.bill-info {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex-direction: column;
}

.bill-info-line {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px dashed #dee2e6;
  padding-bottom: 10px;
  padding-top: 10px;
  width: 650px;
  gap: 20px;
}

.addDiscount {
  border: none;
  background-color: rgba(119, 119, 119, 0.21);
  border-radius: 5px;
  color: #777777;
  margin: 5px;
}

.addDiscount:hover {
  background-color: black;
  color: #dee2e6;
}

</style>

<style>
.add-invoice textarea {
  overflow-y: hidden !important;
}
</style>
