<template>
  <div>
    <PageBreadcrumbs :breadcrumbs="breadcrumbs" />

    <div
      id="products-listing-table-wrapper"
      class="mt-5 py-4"
      style="box-shadow: none">
      <div class="helper-wrapper">
        <p class="helper-title">How Can We Help You?</p>
        <p class="helper-desc">
          Lorem ipsum dolor sit amet consectetur nulla nisi pellentesque.
        </p>
        <form class="nosubmit">
          <input
            class="nosubmit"
            type="search"
            placeholder="Search for something" />
        </form>
        <div class="popular-searches-wrapper">
          <p class="popular-search">Popüler Aramalar:</p>
          <span class="popular-search-keys">planlar</span>
          <span class="popular-search-keys">satış</span>
        </div>
      </div>
      <div class="helper-tabs-wrapper">
        <!-- <b-tabs
                active-nav-item-class="helper-tabs-active"
                content-class="sales-detail-tabs-content mt-2"
                class="helper-tabs mt-3"
            >
            <b-tab title="OVERVIEW" active> </b-tab>
            <b-tab title="TUTORIALS"> </b-tab>
            <b-tab title="SIKÇA SORULAN SORULAR"> </b-tab>
            <b-tab title="PLAN VE ÖDEME"> </b-tab>
            <b-tab title="CONTACT US"> </b-tab>
            </b-tabs> -->

        <div class="hypers">
          <router-link
            to="#overview"
            class="helper-hyperlink"
            >OVERVIEW</router-link
          >
          <router-link
            to="#video"
            class="helper-hyperlink"
            >TUTORIALS</router-link
          >
          <router-link
            to="#"
            class="helper-hyperlink"
            >SIKÇA SORULAN SORULAR</router-link
          >
          <router-link
            to="#"
            class="helper-hyperlink"
            >PLAN VE ÖDEME</router-link
          >
          <router-link
            to="#"
            class="helper-hyperlink"
            >CONTACT US</router-link
          >
        </div>

        <!-- overview -->
        <div
          class="row mt-5"
          id="overview">
          <div class="col-lg-8">
            <div class="row">
              <div class="col-lg-4">
                <div class="helper-category-card helper-category-card-pink">
                  <router-link to="#">
                    <span>Hesap ve fatura sorunları</span>
                    <i class="fa-solid fa-angle-right"></i>
                  </router-link>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="helper-category-card helper-category-card-blue">
                  <router-link to="#">
                    <span>Uygulama sorunları</span>
                    <i class="fa-solid fa-angle-right"></i>
                  </router-link>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="helper-category-card helper-category-card-green">
                  <router-link to="#">
                    <span>Lorem ipsum dolor sit amet</span>
                    <i class="fa-solid fa-angle-right"></i>
                  </router-link>
                </div>
              </div>
              <div class="col-lg-12 mt-4">
                <div class="helper-category-card helper-category-card-yellow">
                  <router-link to="#">
                    <span>Uygulamayı nasıl<br />kullanacağınızı öğrenin</span>
                    <i class="fa-solid fa-angle-right"></i>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div id="faq-div-wrapper">
              <div class="flex-center-space">
                <p class="faq-div-title">Lorem ipsum dolor sit amet</p>
                <router-link to="#">Tümünü Gör</router-link>
              </div>
              <div
                class="mt-3"
                v-for="faq in 3"
                :key="faq.$index">
                <p
                  class="faq-div-q"
                  v-b-toggle.faq>
                  Lorem ipsum dolor sit amet consectetur Sceleris?
                </p>
                <b-collapse id="faq">
                  <p class="faq-div-desc">
                    Lorem ipsum dolor sit amet consectetur. Faucibus elementum
                    nunc quam bibendum pulvinar quis at. Quis sed enim tellus
                    blandit feugiat sagittis.
                  </p>
                </b-collapse>
              </div>
            </div>
          </div>
        </div>
        <!-- end of overview -->
        <div
          class="row mt-5"
          id="video">
          <div class="flex-center-space">
            <p class="page-title">Video Tutorials</p>
            <router-link to="#">Tümünü Gör</router-link>
          </div>
          <div class="row">
            <div
              class="col-lg-4"
              v-for="item in 3"
              :key="item.$index">
              <b-card
                title="Admin Panel - How to Started the Dashboard Tutorial"
                img-src="https://dummyimage.com/350x160/06a5d0/ffffff"
                img-top
                class="mb-2">
                <b-card-text>
                  Lorem ipsum dolor sit amet consectetur. Magnis interdum
                  consectetur consectetur et nec fermentum.
                </b-card-text>
              </b-card>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import PageBreadcrumbs from '../../components/PageBreadcrumbs.vue';
export default {
  components: {
    PageBreadcrumbs
  },
  data() {
    return {
      breadcrumbs: [
        {
          name: 'Yardım & Destek',
          path: '/#'
        }
      ]
    };
  }
};
</script>

<style scoped>
input[type='search'] {
  border: none;
  background: transparent;
  margin: 0;
  padding: 7px 8px;
  font-size: 14px;
  color: inherit;
  border: 1px solid transparent;
  border-radius: inherit;
  transition: 0.5s;
}

input[type='search']::placeholder {
  color: #bbb;
}
input[type='search']:focus {
  box-shadow: 1px 0px 10px 0px rgb(28 28 28 / 30%);
  border-color: rgb(28 28 28 / 30%);
  outline: none;
  transition: 0.5s;
}

form.nosubmit {
  border: none;
  padding: 0;
}

input.nosubmit {
  border: 1px solid #d3d3d3;
  width: 100%;
  padding: 9px 4px 9px 40px;
  background: transparent
    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E")
    no-repeat 13px center;
  max-width: 550px;
  margin: 10px 0px;
  border-radius: 100px;
}
</style>
