<!-- eslint-disable -->
<template>
  <div>
    <!-- <PageBreadcrumbs :breadcrumbs="breadcrumbs" /> -->
    <div id="car-adding-steps-wrapper">
      <div class="car-adding-steps">
        <div
          class="car-adding-step-item"
          :class="{ activeCarAddingStep: carStep }">
          <div>
            <i class="fa-solid fa-car"></i>
          </div>
          <div>
            <p class="car-adding-step-title">{{ $t('Car') }}</p>
            <p class="car-adding-step-desc">
              {{ $t('Enter your vehicle details') }}
            </p>
          </div>
        </div>
        <div class="car-adding-step-indicator">
          <i class="fa-solid fa-angle-right"></i>
        </div>
        <div
          class="car-adding-step-item"
          :class="{ activeCarAddingStep: serviceStep }">
          <div>
            <i class="fa-solid fa-home"></i>
          </div>
          <div>
            <p class="car-adding-step-title">
              {{ $t('Enter your vehicle details') }}
            </p>
            <p class="car-adding-step-desc">{{ $t('Choose Maintenance') }}</p>
          </div>
        </div>
        <div class="car-adding-step-indicator">
          <i class="fa-solid fa-angle-right"></i>
        </div>
        <div
          class="car-adding-step-item"
          :class="{ activeCarAddingStep: serviceDetailStep }">
          <div>
            <i class="fa-solid fa-car"></i>
          </div>
          <div>
            <p class="car-adding-step-title">{{ $t('Maintenance Content') }}</p>
            <p class="car-adding-step-desc">{{ $t('See Details') }}</p>
          </div>
        </div>
      </div>
    </div>
    <div
      class="row"
      id="car-add-main-wrapper">
      <div class="col-lg-9">
        <div class="product-detail-left-wrapper">
          <div id="car-add-wrapper">
            <div
              id="car-step"
              v-if="carStep === true">
              <p class="page-title">{{ $t('Add Car') }}</p>
              <p class="page-txt">Lorem ipsum dolor sit amet consectetur.</p>
              <div class="car-add-row">
                <b-form-group
                  :label="$t('Plate') + ' *'"
                  class="mr-6">
                  <b-form-input
                    :placeholder="$t('Plate')"
                    required></b-form-input>
                </b-form-group>

                <b-form-group :label="$t('Chassis No.')">
                  <b-form-input :placeholder="$t('Chassis No.')"></b-form-input>
                </b-form-group>
                <b-form-group class="visibility-hidden">
                  <b-form-input></b-form-input>
                </b-form-group>
              </div>
              <p class="page-title">{{ $t('Car Information') }}</p>
              <div class="car-add-row mt-3">
                <b-form-group
                  :label="$t('Brand') + ' *'"
                  class="mr-6">
                  <b-form-select
                    v-model="brand"
                    :options="options"></b-form-select>
                </b-form-group>

                <b-form-group
                  label="Model *"
                  class="mr-6">
                  <b-form-select
                    v-model="model"
                    :options="options"></b-form-select>
                </b-form-group>

                <b-form-group :label="$t('Year') + ' *'">
                  <b-form-select
                    v-model="year"
                    :options="options"></b-form-select>
                </b-form-group>
              </div>
              <div class="car-add-row">
                <b-form-group
                  :label="$t('Version') + ' *'"
                  class="mr-6">
                  <multiselect
                    v-model="version"
                    :options="versions"
                    :multiple="true"
                    group-values="libs"
                    group-label="language"
                    :group-select="true"
                    :placeholder="$t('Search Version')"
                    track-by="name"
                    label="name">
                    <span slot="noResult">{{
                      $t('There is no such version.')
                    }}</span>
                    <!-- <template slot="option" slot-scope="props">
                                            <input type="checkbox">
                                            <span class="option__title">
                                                {{ props.option.libs.name }}
                                            </span>
                                        </template> -->
                  </multiselect>
                </b-form-group>

                <b-form-group class="visibility-hidden">
                  <b-form-input></b-form-input>
                </b-form-group>
              </div>
              <p class="page-title">{{ $t('Tire Information') }}</p>
              <div class="car-add-row mt-3">
                <b-form-group
                  :label="$t('Brand')"
                  class="mr-6">
                  <b-form-select
                    v-model="tireBrand"
                    :options="options"></b-form-select>
                </b-form-group>

                <b-form-group
                  :label="$t('Width')"
                  class="mr-6">
                  <b-form-input
                    v-model="width"
                    type="number"></b-form-input>
                  <!-- <b-form-select v-model="width" :options="options"></b-form-select> -->
                </b-form-group>

                <b-form-group :label="$t('Height')">
                  <b-form-input
                    v-model="height"
                    type="number"></b-form-input>
                  <!-- <b-form-select v-model="height" :options="options"></b-form-select> -->
                </b-form-group>
              </div>
              <div class="car-add-row">
                <b-form-group
                  :label="$t('Radius')"
                  class="mr-6">
                  <b-form-input
                    v-model="radius"
                    type="number"></b-form-input>
                  <!-- <b-form-select v-model="radius" :options="options"></b-form-select> -->
                </b-form-group>
                <b-form-group class="visibility-hidden">
                  <b-form-input></b-form-input>
                </b-form-group>
                <b-form-group class="visibility-hidden">
                  <b-form-input></b-form-input>
                </b-form-group>
              </div>
              <div class="flex-center-space">
                <div></div>
                <div class="flex-center-space">
                  <b-button class="step-btn visibility-hidden">{{
                    $t('Continue')
                  }}</b-button>
                  <b-button
                    class="step-btn"
                    @click="
                      carStep = false;
                      serviceDetailStep = false;
                      serviceStep = true;
                    "
                    >{{ $t('Continue') }}</b-button
                  >
                </div>
              </div>
            </div>
            <!--  -->
            <div
              id="customer-step"
              v-if="serviceStep === true">
              <div class="car-add-row">
                <div>
                  <p class="page-title">{{ $t('Maintenance') }}</p>
                  <p class="page-txt">
                    Lorem ipsum dolor sit amet consectetur.
                  </p>
                </div>
              </div>
              <div class="car-add-row">
                <b-form-group
                  :label="$t('Maintenance')"
                  class="mr-6">
                  <b-form-select
                    v-model="selected"
                    :options="options"></b-form-select>
                </b-form-group>

                <b-form-group
                  :label="$t('Detail')"
                  class="mr-6">
                  <multiselect
                    v-model="service"
                    :options="services"
                    :multiple="true"
                    group-values="libs"
                    group-label="language"
                    :group-select="true"
                    :placeholder="$t('Search Detail')"
                    track-by="name"
                    label="name">
                    <span slot="noResult">{{
                      $t('There is no such version.')
                    }}</span>
                  </multiselect>
                </b-form-group>
              </div>

              <b-button
                class="step-btn"
                @click="
                  carStep = false;
                  serviceStep = false;
                  serviceDetailStep = true;
                "
                >Devam Et</b-button
              >
            </div>
            <!--  -->
            <div
              id="customer-step"
              v-if="serviceDetailStep === true">
              <div class="car-add-row">
                <div>
                  <p class="page-title">{{ $t('Maintenance Content') }}</p>
                  <p class="page-txt">
                    Lorem ipsum dolor sit amet consectetur.
                  </p>
                </div>
                <!-- <div class="customer-type-selector-wrapper">
                                    <b-form-select v-model="selected" :options="customers"></b-form-select>
                                </div> -->
              </div>
              <div class="mt-3">
                <div
                  class="table-editable-head"
                  v-if="editable === true">
                  <span>
                    <b>3</b>
                    {{ $t('Item Selected') }}
                  </span>
                  <span>
                    <i class="fa-solid fa-edit"></i>
                    {{ $t('Edit') }}
                  </span>
                  <span>
                    <i class="fa-solid fa-trash"></i>
                    {{ $t('Delete') }}
                  </span>
                </div>
                <table>
                  <tr
                    class="product-listing-table-head"
                    v-if="editable === false">
                    <th>
                      <label class="product-listing-checkbox-label">
                        <input
                          type="checkbox"
                          @click="checkUncheck()" />
                        <span class="checkmark"></span>
                      </label>
                    </th>
                    <th>KM</th>
                    <th>{{ $t('Price') }}</th>
                    <th>{{ $t('Maintenance Detail') }}</th>
                    <th></th>
                  </tr>
                  <tr
                    class="tr-content"
                    v-for="item in 1"
                    :key="item.$index">
                    <td>
                      <label class="product-listing-checkbox-label">
                        <input
                          type="checkbox"
                          name="single-payment-checkbox" />
                        <span class="checkmark"></span>
                      </label>
                    </td>
                    <td>20.000 KM</td>
                    <td>
                      <div class="product-listing-input-wrapper">
                        <input
                          type="text"
                          value="₺1.650,78" />
                      </div>
                    </td>
                    <td v-b-modal.modal-center>
                      <span>- Total/Elf 5W30 Motor Yağı </span>
                      <br />
                      <span>- Yağ Filtresi...</span>
                      <b-modal
                        id="modal-center"
                        size="sm"
                        centered
                        hide-footer
                        hide-header
                        title="BootstrapVue">
                        <div class="edit-services-wrapper">
                          <b-form-textarea
                            rows="3"
                            max-rows="6"
                            v-model="service_detail"></b-form-textarea>
                          <div class="flex-center-space">
                            <b-button
                              class="mt-3 mr-6 w-100 copy-services-btn"
                              id="copy-services-btn"
                              @click="copy()">
                              <i class="fa-regular fa-copy"></i>
                            </b-button>
                            <b-button
                              class="mt-3 ml-6 w-100 edit-services-btn"
                              id="edit-services-btn">
                              Güncelle
                            </b-button>
                          </div>
                        </div>
                      </b-modal>
                    </td>
                    <td>
                      <i class="fa-solid fa-pencil"></i>
                    </td>
                  </tr>
                </table>
              </div>

              <div class="flex-center-space mt-5">
                <div></div>
                <div class="flex-center-space">
                  <b-button class="visibility-hidden">Önceki</b-button>
                  <b-button>Kaydet</b-button>
                </div>
              </div>
            </div>
            <!--  -->
          </div>
        </div>
      </div>
      <div class="col-lg-3">
        <div
          class="product-detail-right-wrapper"
          id="customer-detail-right-wrapper">
          <div class="add-car-right-info-card">
            <div class="flex-center-space">
              <div class="add-car-right-info-card-head">
                <p>Kullanıcı Bilgileri</p>
                <button>Düzenle</button>
              </div>
            </div>
            <div class="flex-center-space">
              <span>Ad Soyad</span>
              <p>{{ name }}</p>
            </div>
            <div class="flex-center-space">
              <span>Telefon Numarası</span>
              <p>{{ phone }}</p>
            </div>
            <div class="flex-center-space">
              <span>E-Posta Adresi</span>
              <p>{{ email }}</p>
            </div>
          </div>
        </div>
        <div
          class="product-detail-right-wrapper mt-2"
          id="customer-detail-right-wrapper">
          <div class="add-car-right-info-card">
            <div class="flex-center-space">
              <div class="add-car-right-info-card-head">
                <p>Adres Bilgileri</p>
                <button>Düzenle</button>
              </div>
            </div>
            <div class="flex-center-space">
              <span>İl / İlçe</span>
              <p>{{ city }}</p>
            </div>
            <div class="flex-center-space">
              <span>Adres Tanımı</span>
              <p>{{ address }}</p>
            </div>
          </div>
        </div>
        <div
          class="product-detail-right-wrapper mt-2"
          id="customer-detail-right-wrapper">
          <div class="add-car-right-info-card">
            <div class="flex-center-space">
              <div class="add-car-right-info-card-head">
                <p>Araç Bilgileri</p>
                <button>Düzenle</button>
              </div>
            </div>
            <div class="flex-center-space">
              <span>Plaka</span>
              <p>{{ plate }}</p>
            </div>
            <div class="flex-center-space">
              <span>Marka / Model</span>
              <p>
                {{ brand }} <span v-if="brand.length > 0">/</span> {{ model }}
              </p>
            </div>
            <div class="flex-center-space">
              <span>Lastik Ebatları</span>
              <p>
                {{ width }} <span v-if="width.length > 0">/</span> {{ height }}
                <span v-if="height.length > 0">/</span> {{ radius }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import PageBreadcrumbs from '../../components/PageBreadcrumbs.vue';
import StarRating from '../../components/StarRating.vue';
export default {
  components: {
    PageBreadcrumbs,
    StarRating
  },
  data() {
    return {
      breadcrumbs: [
        {
          name: 'Ürünler',
          path: '/#'
        },
        {
          name: 'Yeni Fiyat Ekle'
        }
      ],
      form: {
        stock: '',
        dot: '',
        salesPrice: '',
        paymemnt: '',
        commission: ''
      },
      selected: null,
      options: [{ value: null, text: 'Tamamlandı', disabled: true }],
      customers: [
        { value: null, text: 'Bireysel', disabled: true },
        { value: null, text: 'Kurumsal', disabled: true }
      ],
      edit: false,
      carStep: true,
      serviceStep: false,
      serviceDetailStep: false,
      name: '',
      phone: '',
      email: '',
      city: '',
      address: '',
      plate: '',
      brand: '',
      model: '',
      version: [],
      year: '',
      tireBrand: '',
      width: '',
      height: '',
      radius: '',
      versions: [
        {
          language: 'Benzin',
          libs: [
            { name: 'Ford Fiesta 2021 S 1.6 Benzin', category: 'Ford' },
            { name: 'Ford Fiesta 2021 S 1.6 Benzin', category: 'Ford' },
            { name: 'Ford Fiesta 2021 S 1.6 Benzin', category: 'Ford' },
            { name: 'Ford Fiesta 2021 S 1.6 Benzin', category: 'Ford' }
          ]
        },
        {
          language: 'Dizel',
          libs: [
            { name: 'Ford Fiesta 2021 S 1.6 Benzin', category: 'Ford' },
            { name: 'Ford Fiesta 2021 S 1.6 Benzin', category: 'Ford' },
            { name: 'Ford Fiesta 2021 S 1.6 Benzin', category: 'Ford' },
            { name: 'Ford Fiesta 2021 S 1.6 Benzin', category: 'Ford' }
          ]
        }
      ],
      service: [],
      services: [
        {
          language: 'Tümü',
          libs: [
            { name: '10.000 KM', category: 'KM' },
            { name: '10.000 KM', category: 'KM' },
            { name: '10.000 KM', category: 'KM' },
            { name: '10.000 KM', category: 'KM' }
          ]
        }
      ],
      editable: false,
      editDetails: false,
      service_detail: '-Yağ Bakımı'
    };
  },
  methods: {
    customLabel({ title, desc }) {
      return `${title} – ${desc}`;
    },
    checkUncheck(checkBox) {
      var get = document.getElementsByName('single-payment-checkbox');
      for (var i = 0; i < get.length; i++) {
        if (get[i].checked === true) {
          get[i].checked = false;
          this.editable = false;
        } else {
          get[i].checked = true;
          this.editable = true;
        }
        // without if => get[i].checked = checkBox.checked;}
      }
    },
    copy(service_detail) {}
  }
};
</script>

<style scoped>
.tr-content > * {
  padding: 6px 0px 12px 12px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #6e6b7b;
  border-bottom: 1px solid #e9ecef;
}
.tr-content i {
  background: rgba(6, 165, 208, 0.14);
  border-radius: 5px;
  color: #06a5d0;
  padding: 12px;
  cursor: pointer;
  transition: 0.5s;
}
.tr-content i:hover {
  box-shadow: rgb(3 169 244 / 40%) 0px 8px 30px 0px;
  transition: 0.5s;
}
</style>
