<template>
  <div id="register">
    <div v-if="!loading && Object.keys(branch).length > 0">
      <div class="container web-register d-sm-none d-md-block">
        <div class="register-head-wrapper">
          <img
              alt="Servislet Pro"
              class="web-logo pr-0"
              src="../../assets/images/logo.png"/>
        </div>

        <div
            class="branch-head-wrapper neu"
            :class="branch.branch_group_color ? '' : 'neu'"
            :style="`background-color:${branch.branch_group_color ? 'rgba'+branch.branch_group_color : '#06a5d0'}`">
          <div class="branch-head-left-wrapper">
            <b-avatar
                badge
                badge-variant="success"
                :src="branch.branch_group_icon"
                width="400"
                height="400"
                class="bg-light"
                v-if="branch.branch_group_icon"></b-avatar>
            <span class="branch-name"> {{ branch.name }}</span>
          </div>
          <div class="branch-head-right-wrapper">
            <img
                :src="branch.branch_group_asset_path"
                :alt="branch.name"
                v-if="branch.branch_group_asset_path"
                width="120"/>
          </div>
        </div>

        <div class="text-center">
          <div class="customer-info-form-header-title">
            {{ $t('Customer Information Form') }}
          </div>
          <div class="customer-info-form-header-info">
            {{
              $t(
                  'Follow the steps, enter the information on the form and create your registration.'
              )
            }}
          </div>
        </div>

        <div class="row mt-5">
          <div class="col-md-9 col-lg-9">
            <div class="register-content-wrapper neu">
              <div
                  class="flex-center-space"
                  v-if="customer && !address && !vehicle">
                <h4 class="form-title">{{ $t('Customer Informations') }}</h4>
                <b-form-group id="customer-type-selector">
                  <b-form-select v-model="customerType">
                    <b-form-select-option
                        :value="c_type"
                        v-for="c_type in customerTypes"
                        :key="c_type.$index">
                      {{ c_type.name }}
                    </b-form-select-option>
                  </b-form-select>
                </b-form-group>
              </div>
              <ValidationObserver
                  ref="customerAddRules"
                  v-if="customer && !address && !vehicle">
                <div class="row">
                  <div class="col-lg-6">
                    <b-form-group>
                      <label for="name"
                      >{{ $t('Name') }}
                        <small class="text-danger"> *</small></label
                      >
                      <ValidationProvider
                          :name="$t('Name')"
                          rules="required|max:40"
                          #default="{ errors }">
                        <b-form-input
                            type="text"
                            v-model="name"/>
                        <label
                            for="name"
                            v-if="errors.length > 0"
                            class="text-danger"
                        ><small>*</small>{{ errors[0] }}.</label
                        >
                      </ValidationProvider>
                    </b-form-group>
                  </div>
                  <div class="col-lg-6">
                    <b-form-group>
                      <label for="surname"
                      >{{ $t('Surname') }}
                        <small class="text-danger"> *</small></label
                      >
                      <ValidationProvider
                          :name="$t('Surname')"
                          rules="required|max:40"
                          #default="{ errors }">
                        <b-form-input
                            type="text"
                            v-model="surname"/>
                        <label
                            for="surname"
                            v-if="errors.length > 0"
                            class="text-danger"
                        ><small>*</small>{{ errors[0] }}.</label
                        >
                      </ValidationProvider>
                    </b-form-group>
                  </div>
                  <div class="col-lg-6">
                    <b-form-group>
                      <label for="phone"
                      >{{ $t('Phone') }}
                        <small class="text-danger"> *</small></label
                      >
                      <ValidationProvider
                          :name="$t('Phone')"
                          rules="required|max:20"
                          #default="{ errors }">
                        <b-form-input
                            type="text"
                            v-model="phone"
                            v-mask="'+90 (###) ### ## ##'"/>
                        <label
                            for="phone"
                            v-if="errors.length > 0"
                            class="text-danger"
                        ><small>*</small>{{ errors[0] }}.</label
                        >
                      </ValidationProvider>
                    </b-form-group>
                  </div>
                  <div class="col-lg-6">
                    <b-form-group>
                      <label for="email"
                      >{{ $t('E-Mail') }}
                        <small class="text-danger"> *</small></label
                      >
                      <ValidationProvider
                          :name="$t('E-Mail')"
                          rules="required|email|max:50"
                          #default="{ errors }">
                        <b-form-input
                            type="text"
                            v-model="email"/>
                        <label
                            for="email"
                            v-if="errors.length > 0"
                            class="text-danger"
                        ><small>*</small>{{ errors[0] }}.</label
                        >
                      </ValidationProvider>
                    </b-form-group>
                  </div>
                  <!--                  <div class="col-lg-6" v-if="customerType.code !== '101'">-->
                  <div class="col-lg-6">
                    <b-form-group>
                      <label for="job_title">{{ $t('Job Description') }}</label>
                      <ValidationProvider
                          :name="$t('Job Description')"
                          rules="max:120"
                          #default="{ errors }">
                        <b-form-input
                            type="text"
                            v-model="job_title"/>
                        <label
                            for="job_title"
                            v-if="errors.length > 0"
                            class="text-danger"
                        ><small>*</small>{{ errors[0] }}.</label
                        >
                      </ValidationProvider>
                    </b-form-group>
                  </div>
                  <!--                  <div class="col-lg-6" v-if="customerType.code !== '101'">-->
                  <div
                      class="col-lg-6"
                      v-if="customerType.code !== '101'">
                    <b-form-group>
                      <label for="company_title"
                      >{{ $t('Company Title') }} <small>*</small></label
                      >
                      <ValidationProvider
                          :name="$t('Company Title')"
                          rules="required|max:120"
                          #default="{ errors }">
                        <b-form-input
                            type="text"
                            v-model="company_title"/>
                        <label
                            for="company_title"
                            v-if="errors.length > 0"
                            class="text-danger"
                        ><small>*</small>{{ errors[0] }}.</label
                        >
                      </ValidationProvider>
                    </b-form-group>
                  </div>
                  <!--                  <div class="col-lg-6" v-if="customerType.code !== '101'">-->
                  <div
                      class="col-lg-6"
                      v-if="customerType.code !== '101'">
                    <b-form-group>
                      <label for="tax_office"
                      >{{ $t('Tax Administration') }} <small>*</small></label
                      >
                      <ValidationProvider
                          :name="$t('Tax Administration')"
                          rules="required|max:60"
                          #default="{ errors }">
                        <b-form-input
                            type="text"
                            v-model="tax_office"/>
                        <label
                            for="tax_office"
                            v-if="errors.length > 0"
                            class="text-danger"
                        ><small>*</small>{{ errors[0] }}.</label
                        >
                      </ValidationProvider>
                    </b-form-group>
                  </div>
                  <!--                  <div class="col-lg-6" v-if="customerType.code !== '101'">-->
                  <div
                      class="col-lg-6"
                      v-if="customerType.code !== '101'">
                    <b-form-group>
                      <label for="tax_number"
                      >{{ $t('Tax Number') }} <small>*</small></label
                      >
                      <ValidationProvider
                          :name="$t('Tax Number')"
                          rules="required|max:20"
                          #default="{ errors }">
                        <b-form-input
                            type="number"
                            v-model="tax_number"/>
                        <label
                            for="tax_number"
                            v-if="errors.length > 0"
                            class="text-danger"
                        ><small>*</small>{{ errors[0] }}.</label
                        >
                      </ValidationProvider>
                    </b-form-group>
                  </div>
                </div>
                <div class="register-actions mt-10rem">
                  <button class="cancel">{{ $t('Cancel') }}</button>
                  <button
                      class="next"
                      @click="checkCustomerStep()">
                    {{ $t('Continue') }}
                  </button>
                </div>
              </ValidationObserver>

              <div
                  class="flex-center-space"
                  v-if="customer && address && !vehicle">
                <h4 class="form-title">{{ $t('Address Informations') }}</h4>
                <b-form-group id="customer-type-selector">
                  <label for="address_type"
                  >{{ $t('Address Type') }}
                    <small
                        class="text-danger"
                        v-if="address_type.value || address_str"
                    >*</small
                    ></label
                  >
                  <b-form-select v-model="address_type">
                    <b-form-select-option
                        :value="a_type"
                        v-for="a_type in address_types"
                        :key="a_type.$index">
                      {{ a_type.text }}
                    </b-form-select-option>
                  </b-form-select>
                </b-form-group>
              </div>

              <ValidationObserver
                  ref="addressAddRules"
                  v-if="customer && address && !vehicle">
                <div class="row">
                  <div class="col-lg-4">
                    <b-form-group :label="$t('City')">
                      <b-form-select
                          v-model="city"
                          @change="
                          setCity(city.id);
                          city_focus = false;
                          district_focus = true;
                        "
                          :class="city_focus ? 'focused-select' : ''">
                        <b-form-select-option
                            :value="city"
                            :selected="city.id"
                            v-for="city in cities"
                            :key="city.$index"
                        >{{ city.name }}
                        </b-form-select-option>
                      </b-form-select>
                    </b-form-group>
                  </div>
                  <div class="col-lg-4">
                    <b-form-group :label="$t('District')">
                      <b-form-select
                          v-model="district"
                          @change="
                          setDistrict(district.id);
                          district_focus = false;
                          area_focus = true;
                        "
                          :class="district_focus ? 'focused-select' : ''">
                        <b-form-select-option
                            :value="district"
                            v-for="district in districts"
                            :key="district.$index">
                          {{ district.name }}
                        </b-form-select-option>
                      </b-form-select>
                    </b-form-group>
                  </div>
                  <div class="col-lg-4">
                    <b-form-group :label="$t('Neighborhood')">
                      <b-form-select
                          v-model="area"
                          @change="area_focus = false"
                          :class="area_focus ? 'focused-select' : ''">
                        <b-form-select-option
                            :value="area"
                            v-for="area in areas"
                            :key="area.$index"
                        >{{ area.name }}
                        </b-form-select-option>
                      </b-form-select>
                    </b-form-group>
                  </div>
                  <div class="col-lg-12">
                    <ValidationProvider
                        :name="$t('Address')"
                        rules="max:200"
                        #default="{ errors }">
                      <b-form-group :label="$t('Address')">
                        <b-form-textarea
                            rows="3"
                            v-model="address_str"/>
                        <label
                            for="address_str"
                            class="text-danger"
                            v-if="errors.length > 0"
                        ><small>*</small> {{ errors[0] }}.</label
                        >
                      </b-form-group>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="register-actions mt-5">
                  <button
                      class="cancel"
                      @click="
                      customer = true;
                      address = false;
                    ">
                    {{ $t('Back') }}
                  </button>
                  <button
                      class="next"
                      @click="checkAddressStep()">
                    {{ $t('Continue') }}
                  </button>
                </div>
              </ValidationObserver>

              <div
                  class="flex-center-space"
                  v-if="customer && address && vehicle">
                <h4 class="form-title">{{ $t('Car Information') }}</h4>
              </div>

              <ValidationObserver
                  ref="carAddRules"
                  v-if="customer && address && vehicle">
                <div class="row mt-2">
                  <div class="col-lg-4">
                    <b-form-group>
                      <label for="car_plate"
                      >{{ $t('Plate') }}
                        <small
                            class="text-danger"
                            v-if="car_id">
                          *</small
                        ></label
                      >
                      <ValidationProvider
                          :name="$t('Plate')"
                          rules="max:20"
                          #default="{ errors }"
                          :rules="car_id ? 'required' : ''">
                        <input type="text" class="form-control" maxlength="13" v-model="car_plate" @input="formatCarPlate()">
<!--                        <b-form-input-->
<!--                            type="text"-->
<!--                            max="12"-->
<!--                            @input="formatCarPlate()"-->
<!--                            v-model="car_plate"></b-form-input>-->
                        <label
                            for="car_plate"
                            v-if="errors.length > 0"
                            class="text-danger"
                        ><small>*</small>{{ errors[0] }}.</label
                        >
                      </ValidationProvider>
                    </b-form-group>
                  </div>
                  <div class="col-lg-4">
                    <ValidationProvider
                        :name="$t('Chassis number')"
                        rules="max:20"
                        #default="{ errors }">
                      <b-form-group>
                        <label for="car_chassis_number">{{
                            $t('Chassis number')
                          }}</label>
                        <b-form-input
                            type="number"
                            v-model="car_chassis_number"/>
                        <label
                            for="car_chassis_number"
                            v-if="errors.length > 0"
                            class="text-danger"
                        ><small>*</small>{{ errors[0] }}.</label
                        >
                      </b-form-group>
                    </ValidationProvider>
                  </div>
                  <div class="col-lg-12">
                    <hr/>
                  </div>

                  <div class="col-lg-4">
                    <ValidationProvider
                        #default="{ errors }"
                        :name="$t('Brand')"
                        :rules="car_plate ? 'required' : ''">
                      <b-form-group class="mr-6">
                        <label for="car_brand"
                        >{{ $t('Brand') }}
                          <small
                              class="text-danger"
                              v-if="car_plate">
                            *</small
                          ></label
                        >
                        <b-form-select
                            v-model="car_brand"
                            @change="
                            setCarBrand(car_brand.car_brand_id);
                            car_brand_focus = false;
                            car_year_focus = true;
                          "
                            :class="car_brand_focus ? 'focused-select' : ''">
                          <b-form-select-option
                              :value="brand"
                              v-for="brand in carBrands"
                              :key="brand.$index"
                          >{{ brand.name }}
                          </b-form-select-option>
                        </b-form-select>
                        <label
                            for="car_brand"
                            v-if="errors.length > 0"
                            class="text-danger"
                        ><small>* </small>{{ errors[0] }}.</label
                        >
                      </b-form-group>
                    </ValidationProvider>
                  </div>
                  <div class="col-lg-4">
                    <ValidationProvider
                        #default="{ errors }"
                        :name="$t('Year')"
                        :rules="car_plate ? 'required' : ''">
                      <b-form-group class="mr-6">
                        <label for="car_year"
                        >{{ $t('Year') }}
                          <small
                              class="text-danger"
                              v-if="car_plate">
                            *</small
                          ></label
                        >
                        <b-form-select
                            v-model="car_year"
                            @change="
                            setCarYear();
                            car_year_focus = false;
                            car_model_focus = true;
                          "
                            :class="car_year_focus ? 'focused-select' : ''">
                          <b-form-select-option
                              :value="year"
                              v-for="year in carYears"
                              :key="year.$index"
                          >{{ year ? year : $t('Choose') }}
                          </b-form-select-option>
                        </b-form-select>
                        <label
                            for="car_year"
                            v-if="errors.length > 0"
                            class="text-danger"
                        ><small>* </small>{{ errors[0] }}.</label
                        >
                      </b-form-group>
                    </ValidationProvider>
                  </div>
                  <div class="col-lg-4">
                    <ValidationProvider
                        #default="{ errors }"
                        name="Model"
                        :rules="car_plate ? 'required' : ''">
                      <b-form-group>
                        <label for="car_model"
                        >Model
                          <small
                              class="text-danger"
                              v-if="car_plate">
                            *</small
                          ></label
                        >
                        <b-form-select
                            v-model="car_model"
                            @change="
                            setCarModel();
                            car_model_focus = false;
                            car_version_focus = true;
                          "
                            :class="car_model_focus ? 'focused-select' : ''">
                          <b-form-select-option
                              :value="model"
                              v-for="model in carModels"
                              :key="model.$index">
                            {{ model.car_model_name }}
                          </b-form-select-option>
                        </b-form-select>
                        <label
                            for="car_model"
                            v-if="errors.length > 0"
                            class="text-danger"
                        ><small>* </small>{{ errors[0] }}.</label
                        >
                      </b-form-group>
                    </ValidationProvider>
                  </div>
                  <div class="col-lg-4">
                    <ValidationProvider
                        #default="{ errors }"
                        :name="$t('Version')"
                        :rules="car_plate ? 'required' : ''">
                      <b-form-group class="mr-6">
                        <label for="car_version"
                        >{{ $t('Version') }}
                          <small
                              class="text-danger"
                              v-if="car_plate">
                            *</small
                          ></label
                        >
                        <b-form-select
                            v-model="car_version"
                            @change="
                            findCar();
                            car_version_focus = false;
                          "
                            :class="car_version_focus ? 'focused-select' : ''">
                          <b-form-select-option
                              :value="version"
                              v-for="version in carVersions"
                              :key="version.$index">
                            {{
                              version.version_name_1 +
                              ' ' +
                              version.version_name_2
                            }}
                          </b-form-select-option>
                        </b-form-select>
                        <label
                            for="car_version"
                            v-if="errors.length > 0"
                            class="text-danger"
                        ><small>* </small>{{ errors[0] }}.</label
                        >
                      </b-form-group>
                    </ValidationProvider>
                  </div>
                  <h4 class="form-title mt-4">{{ $t('Tire Information') }}</h4>
                  <div class="col-lg-4">
                    <b-form-group
                        :label="$t('Brand')"
                        class="mr-6">
                      <b-form-select
                          v-model="tire_brand"
                          @change="
                          setTireBrand(tire_brand.slug);
                          brand_focus = false;
                          width_focus = true;
                        "
                          :class="brand_focus ? 'focused-select' : ''">
                        <b-form-select-option
                            :value="brand"
                            v-for="brand in tireBrands"
                            :key="brand.$index"
                        >{{ brand.name }}
                        </b-form-select-option>
                      </b-form-select>
                    </b-form-group>
                  </div>
                  <div class="col-lg-4">
                    <b-form-group
                        :label="$t('Width')"
                        class="mr-6">
                      <b-form-select
                          v-model="tire_width"
                          @change="
                          setTireWidth();
                          width_focus = false;
                          height_focus = true;
                        "
                          :class="width_focus ? 'focused-select' : ''">
                        <b-form-select-option
                            :value="width"
                            v-for="width in tireWidths"
                            :key="width.$index"
                        >{{ width ? width : $t('Choose') }}
                        </b-form-select-option>
                      </b-form-select>
                    </b-form-group>
                  </div>
                  <div class="col-lg-4">
                    <b-form-group :label="$t('Height')">
                      <b-form-select
                          v-model="tire_height"
                          @change="
                          setTireHeight();
                          height_focus = false;
                          radius_focus = true;
                        "
                          :class="height_focus ? 'focused-select' : ''">
                        <b-form-select-option
                            :value="height"
                            v-for="height in tireHeights"
                            :key="height.$index"
                        >{{ height ? height : $t('Choose') }}
                        </b-form-select-option>
                      </b-form-select>
                    </b-form-group>
                  </div>
                  <div class="col-lg-4">
                    <b-form-group
                        :label="$t('Radius')"
                        class="mr-6">
                      <b-form-select
                          v-model="tire_radius"
                          @change="radius_focus = false"
                          :class="radius_focus ? 'focused-select' : ''">
                        <b-form-select-option
                            :value="radius"
                            v-for="radius in tireRadiuses"
                            :key="radius.$index">
                          {{ radius ? radius : $t('Choose') }}
                        </b-form-select-option>
                      </b-form-select>
                    </b-form-group>
                  </div>

                  <div class="col-lg-12">
                    <div class="contracts">
                      <b-form-checkbox
                          id="checkbox-1"
                          name="checkbox-1"
                          v-model="check_contract_status"
                          :value="true"
                          :unchecked-value="false"/>
                      <span
                          class="font-servislet cursor-pointer m-0"
                          :class="check_contract ? 'text-danger' : ''"
                      >{{$t('Membership Agreement')}}</span
                      >{{$t('has read and accepted.')}}
                    </div>

                    <div class="contracts mt-2">
                      <b-form-checkbox
                          id="checkbox-2"
                          name="checkbox-2"
                          v-model="check_custom_contract_status"
                          :value="true"
                          :unchecked-value="false"/>
                      <span
                          class="font-servislet cursor-pointer m-0"
                          :class="check_custom_contract ? 'text-danger' : ''"
                      >{{$t('General Disclosure Statement Regarding the Processing of Personal Data')}}</span
                      >{{$t('has read and accepted.')}}
                    </div>
                    <div class="contracts mt-2">
                      <b-form-checkbox
                          id="checkbox-3"
                          name="checkbox-3"
                          v-model="accept_status"
                          :value="true"
                          :unchecked-value="false"/>
                      <span>
                        {{$t('Processing my personal data to be informed about developments and campaigns and')}}
                        <span class="font-servislet cursor-pointer"
                        >{{$t('Consent Text')}}</span
                        > {{$t('has agreed to be sent electronic messages.')}}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="register-actions mt-5">
                  <button
                      class="cancel"
                      @click="
                      address = true;
                      vehicle = false;
                    ">
                    {{ $t('Back') }}
                  </button>
                  <button
                      class="next"
                      @click="addToCustomerStore()">
                    {{ $t('Save') }}
                  </button>
                </div>
              </ValidationObserver>
            </div>
          </div>

          <div class="col-md-3 col-lg-3">
            <div
                class="neu right-customer-content"
                :class="customer && !address && !vehicle ? 'active-border' : ''">
              <div class="customer-info-title">
                {{ customerType.name }} {{ $t('User Informations') }}
              </div>

              <div
                  class="customer-right-flex-wrapper mt-2"
                  v-if="customerType.code !== '101'">
                <div class="left-flex">{{ $t('Company Title') }}</div>
                <div class="right-flex">{{ company_title }}</div>
              </div>
              <div
                  class="customer-right-flex-wrapper mt-1"
                  v-else>
                <div class="left-flex">{{ $t('Name Surname') }}</div>
                <div class="right-flex">{{ name }} {{ surname }}</div>
              </div>
              <div class="customer-right-flex-wrapper mt-1">
                <div class="left-flex">{{ $t('E-Mail') }}</div>
                <div class="right-flex">{{ email }}</div>
              </div>
              <div
                  class="customer-right-flex-wrapper mt-1"
                  v-if="customerType.code !== '101'">
                <div class="left-flex">{{ $t('Tax Number') }}</div>
                <div class="right-flex">{{ tax_number }}</div>
              </div>
              <div
                  class="customer-right-flex-wrapper mt-1"
                  v-else>
                <div class="left-flex">{{ $t('Phone') }}</div>
                <div class="right-flex">{{ phone }}</div>
              </div>
            </div>

            <div
                class="neu right-customer-content mt-4"
                v-if="address"
                :class="address && customer && !vehicle ? 'active-border' : ''">
              <div class="customer-info-title">
                {{ $t('Address Informations') }}
              </div>
              <div class="customer-right-flex-wrapper mt-2">
                <div class="left-flex">
                  {{ $t('City') }} / {{ $t('District') }}
                </div>
                <div class="right-flex">
                  <span v-if="city.name">{{ city.name }}</span>
                  <span v-if="district.name">/ {{ district.name }}</span>
                </div>
              </div>
              <div class="customer-right-flex-wrapper mt-1">
                <div class="left-flex">{{ $t('Address') }}</div>
                <div class="right-flex">{{ address_str }}</div>
              </div>
            </div>

            <div
                class="neu right-customer-content mt-4"
                v-if="vehicle"
                :class="address && customer && vehicle ? 'active-border' : ''">
              <div class="customer-info-title">{{ $t('Car Information') }}</div>
              <div class="customer-right-flex-wrapper mt-2">
                <div class="left-flex">{{ $t('Plate') }}</div>
                <div class="right-flex">{{ car_plate }}</div>
              </div>
              <div class="customer-right-flex-wrapper mt-1">
                <div class="left-flex">{{ $t('Brand') }} / Model</div>
                <div class="right-flex">
                  {{ car_brand.name }}
                  <span v-if="car_model.car_model_name">
                    / {{ car_model.car_model_name }}</span
                  >
                </div>
              </div>
              <div class="customer-right-flex-wrapper mt-1">
                <div class="left-flex">{{ $t('Tire Sizes') }}</div>
                <div class="right-flex">
                  {{ tire_width }}
                  <span v-if="tire_height">/{{ tire_height }}</span
                  ><span v-if="tire_radius"> R{{ tire_radius }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container-fluid mobile-register m-0 p-0 d-sm-block d-md-none">
        <div v-if="!mobile_success">
          <div
              id="customer_type_popup"
              :class="show_bottom_customer_type_popup ? 'b-40-bg' : ''">
            <div class="mobile-register-head-wrapper">
              <div class="mobile-register-head">
                <img
                    alt="Servislet Pro"
                    class="pr-0"
                    src="../../assets/images/logo.png"
                    height="20"/>
              </div>
              <!--          <div class="mobile-branch-head-wrapper" :style="`background-color:${branch.branch_group_color}`">-->
              <div class="mobile-branch-head-wrapper" :style="`background-color:${branch.branch_group_color ? 'rgba'+branch.branch_group_color : '#06a5d0'} !important`">
                <div class="mobile-branch-head">
                  <div class="mobile-branch-asset-path">
                    <img
                        :src="branch.branch_group_asset_path"
                        :alt="branch.name"/>
                  </div>
                  <span class="mobile-branch-name"> Servislet</span>
                </div>
              </div>

              <div class="mobile-customer-info-form-text">
                <div class="title">{{ $t('Customer Information Form') }}</div>
                <div class="description">
                  {{
                    $t(
                        'Follow the steps, enter the information on the form and create your registration.'
                    )
                  }}
                </div>

                <!--              <div class="add-car-text">-->
                <!--                <span>-->
                <!--                  Aracınızı ekleyin, 400 TL indirim kazanın-->
                <!--                </span>-->
                <!--                <i class="fa-solid fa-chevron-right"></i>
                </div>-->
              </div>
            </div>
            <div v-if="mobile_user">
              <ValidationObserver ref="mobileCustomerAddRules">
                <div class="mobile-register-form">
                  <div class="personal-info-text">
                    {{ $t('Personal Information') }}
                  </div>
                  <div class="mt-2">
                    <div
                        class="customer-type-select"
                        @click="
                        show_bottom_customer_type_popup = true;
                        type_popup = true;
                      ">
                      <div class="customer-type-name">
                        {{ customerType ? customerType.name : '' }}
                      </div>
                      <i class="fa-solid fa-chevron-down"></i>
                    </div>

                    <div class="d-flex justify-content-end mt-3 required-text">
                      *{{ $t('Required Field') }}
                    </div>

                    <ValidationProvider
                        :name="$t('Name')"
                        rules="required|max:40"
                        #default="{ errors }">
                      <div class="form-group input-material mt-3 pb-0 mb-0">
                        <input
                            autocomplete="off"
                            type="text"
                            class="form-control"
                            :class="name ? 'bg-transparent' : ''"
                            v-model="name"
                            required/>
                        <label for="name-field">{{ $t('Name') }}</label>
                      </div>
                      <small
                          v-if="errors.length > 0"
                          class="text-danger mt-2"
                      ><i class="fa-solid fa-circle-info"></i>
                        {{ errors[0] }}.</small
                      >
                    </ValidationProvider>

                    <ValidationProvider
                        :name="$t('Surname')"
                        rules="required|max:40"
                        #default="{ errors }">
                      <div class="form-group input-material mt-3 pb-0 mb-0">
                        <input
                            autocomplete="off"
                            type="text"
                            class="form-control"
                            v-model="surname"
                            :class="surname ? 'bg-transparent' : ''"
                            required/>
                        <label for="name-field">{{ $t('Surname') }}</label>
                      </div>
                      <small
                          v-if="errors.length > 0"
                          class="text-danger mt-2"
                      ><i class="fa-solid fa-circle-info"></i>
                        {{ errors[0] }}.</small
                      >
                    </ValidationProvider>

                    <ValidationProvider
                        :name="$t('Phone')"
                        rules="required|max:20"
                        #default="{ errors }">
                      <div class="form-group input-material mt-4 pb-0 mb-0">
                        <input
                            autocomplete="off"
                            v-mask="'+90 (###) ### ## ##'"
                            v-model="phone"
                            type="text"
                            class="form-control"
                            :class="phone ? 'bg-transparent' : ''"
                            required/>
                        <label for="name-field">{{ $t('Phone') }}</label>
                      </div>
                      <small
                          v-if="errors.length > 0"
                          class="text-danger mt-2"
                      ><i class="fa-solid fa-circle-info"></i>
                        {{ errors[0] }}.</small
                      >
                    </ValidationProvider>

                    <ValidationProvider
                        :name="$t('E-Mail')"
                        rules="required|email|max:50"
                        #default="{ errors }">
                      <div class="form-group input-material mt-4 mb-0 pb-0">
                        <input
                            autocomplete="off"
                            type="text"
                            class="form-control"
                            required
                            v-model="email"
                            :class="email ? 'bg-transparent' : ''"/>
                        <label for="name-field">{{ $t('E-Mail') }}</label>
                      </div>
                      <small
                          v-if="errors.length > 0"
                          class="text-danger mt-2"
                      ><i class="fa-solid fa-circle-info"></i>
                        {{ errors[0] }}.</small
                      >
                    </ValidationProvider>

                    <div
                        class="form-group input-material mt-4"
                        v-if="customerType.code != '101'">
                      <input
                          autocomplete="off"
                          type="text"
                          class="form-control no-required"
                          required
                          v-model="job_title"
                          :class="job_title ? 'bg-transparent' : ''"/>
                      <label for="name-field">{{
                          $t('Job Description')
                        }}</label>
                    </div>

                    <div
                        class="form-group input-material mt-4"
                        v-if="customerType.code != '101'">
                      <input
                          autocomplete="off"
                          type="text"
                          class="form-control no-required"
                          required
                          v-model="company_title"
                          :class="company_title ? 'bg-transparent' : ''"/>
                      <label for="name-field">{{ $t('Company Title') }}</label>
                    </div>

                    <div
                        class="form-group input-material mt-4"
                        v-if="customerType.code != '101'">
                      <input
                          autocomplete="off"
                          type="text"
                          class="form-control no-required"
                          required
                          v-model="tax_office"
                          :class="tax_office ? 'bg-transparent' : ''"/>
                      <label for="name-field">{{
                          $t('Tax Administration')
                        }}</label>
                    </div>

                    <div
                        class="form-group input-material mt-4"
                        v-if="customerType.code != '101'">
                      <input
                          autocomplete="off"
                          type="number"
                          class="form-control no-required"
                          required
                          v-model="tax_number"
                          :class="tax_number ? 'bg-transparent' : ''"/>
                      <label for="name-field">{{ $t('Tax Number') }}</label>
                    </div>
                  </div>
                </div>
              </ValidationObserver>

              <div
                  class="continue-button"
                  v-if="!show_bottom_customer_type_popup">
                <button
                    class="btn"
                    :disabled="!(name && surname && email && phone)"
                    @click="
                    mobile_address = true;
                    mobile_user = false;
                  ">
                  {{ $t('Continue') }}
                </button>
              </div>
            </div>

            <div v-if="mobile_address">
              <ValidationObserver ref="mobileAddressAddRules">
                <div class="mobile-register-form">
                  <div class="personal-info-text">
                    {{ $t('Address Informations') }}
                  </div>
                  <div class="mt-2">
                    <ValidationProvider
                        :name="$t('Name')"
                        rules="max:60"
                        #default="{ errors }">
                      <div class="form-group input-material mt-3">
                        <input
                            autocomplete="off"
                            type="text"
                            class="form-control no-required"
                            :class="address_title ? 'bg-transparent' : ''"
                            v-model="address_title"
                            required/>
                        <label for="name-field">{{
                            $t('Address Title')
                          }}</label>
                      </div>
                    </ValidationProvider>

                    <div
                        class="customer-type-select"
                        :class="city.name ? 'bg-transparent' : 'bg-f4'"
                        @click="
                        show_bottom_customer_type_popup =
                          !show_bottom_customer_type_popup;
                        city_popup = !city_popup;
                      ">
                      <div class="customer-type-name">
                        {{ city.name ? city.name : $t('City') }}
                      </div>
                      <i class="fa-solid fa-chevron-down"></i>
                    </div>

                    <div
                        class="customer-type-select mt-3"
                        :class="district.name ? 'bg-transparent' : 'bg-f4'"
                        @click="showDistrictPopup()">
                      <div class="customer-type-name">
                        {{ district.name ? district.name : $t('District') }}
                      </div>
                      <i class="fa-solid fa-chevron-down"></i>
                    </div>

                    <div
                        class="customer-type-select mt-3"
                        :class="area.name ? 'bg-transparent' : 'bg-f4'"
                        @click="showAreaPopup()">
                      <div class="customer-type-name">
                        {{ area.name ? area.name : $t('Neighborhood') }}
                      </div>
                      <i class="fa-solid fa-chevron-down"></i>
                    </div>

                    <div class="form-group input-material mt-3">
                      <textarea
                          autocomplete="off"
                          type="text"
                          class="form-control no-required"
                          :class="address_str ? 'bg-transparent' : ''"
                          v-model="address_str"
                          required/>
                      <label for="name-field">{{ $t('Address') }}</label>
                    </div>
                  </div>
                </div>
              </ValidationObserver>

              <div
                  class="continue-button"
                  v-if="!show_bottom_customer_type_popup">
                <button
                    class="btn"
                    @click="
                    mobile_address = false;
                    mobile_car = true;
                  ">
                  {{ $t('Continue') }}
                </button>
              </div>
            </div>

            <div v-if="mobile_car">
              <ValidationObserver ref="mobileCarAddRules">
                <div class="mobile-register-form">
                  <div class="personal-info-text">
                    {{ $t('Car Information') }}
                  </div>
                  <div class="mt-2">
                    <div class="form-group input-material mt-3 mb-0 pb-0">
                      <input
                          autocomplete="off"
                          type="text"
                          class="form-control no-required"
                          :class="car_plate ? 'bg-transparent' : ''"
                          v-model="car_plate"
                          maxlength="13"
                          @input="formatCarPlate()"
                          required/>
                      <label for="name-field">{{ $t('Plate') }}</label>
                    </div>
                    <small
                        v-if="show_plate_error"
                        class="text-danger mt-2"
                    ><i class="fa-solid fa-circle-info"></i>
                      {{ $t('Plate is required.') }}</small
                    >

                    <ValidationProvider
                        :name="$t('Chassis number')"
                        rules="max:20"
                        #default="{ errors }">
                      <div class="form-group input-material mt-3">
                        <input
                            autocomplete="off"
                            type="number"
                            class="form-control no-required"
                            :class="car_chassis_number ? 'bg-transparent' : ''"
                            v-model="car_chassis_number"
                            required/>
                        <label for="name-field">{{
                            $t('Chassis number')
                          }}</label>
                      </div>
                    </ValidationProvider>

                    <div class="d-flex">
                      <div class="col-sm-6 col-md-6 col-lg-6 p-0">
                        <div
                            class="customer-type-select"
                            :class="car_brand.name ? 'bg-transparent' : 'bg-f4'"
                            @click="
                            show_bottom_customer_type_popup =
                              !show_bottom_customer_type_popup;
                            brand_popup = !brand_popup;
                            year_popup = false;
                            model_popup = false;
                            version_popup = false;
                          ">
                          <div class="customer-type-name">
                            {{ car_brand.name ? car_brand.name : $t('Brand') }}
                          </div>
                          <i class="fa-solid fa-chevron-down"></i>
                        </div>
                      </div>

                      <div class="col-sm-6 col-md-6 col-lg-6 pr-0">
                        <div
                            class="customer-type-select"
                            :class="car_year ? 'bg-transparent' : 'bg-f4'"
                            @click="showYearPopup()">
                          <div class="customer-type-name">
                            {{ car_year ? car_year : $t('Year') }}
                          </div>
                          <i class="fa-solid fa-chevron-down"></i>
                        </div>
                      </div>
                    </div>

                    <div class="d-flex mt-3">
                      <div class="col-sm-6 col-md-6 col-lg-6 p-0">
                        <div
                            class="customer-type-select"
                            :class="
                            car_model.car_model_name
                              ? 'bg-transparent'
                              : 'bg-f4'
                          "
                            @click="showModelPopup()">
                          <div class="customer-type-name">
                            {{
                              car_model.car_model_name
                                  ? car_model.car_model_name
                                  : 'Model'
                            }}
                          </div>
                          <i class="fa-solid fa-chevron-down"></i>
                        </div>
                      </div>

                      <div class="col-sm-6 col-md-6 col-lg-6 pr-0">
                        <div
                            class="customer-type-select"
                            :class="car_version ? 'bg-transparent' : 'bg-f4'"
                            @click="showVersionPopup()">
                          <div
                              class="customer-type-name"
                              style="
                              max-width: 85px;
                              white-space: nowrap;
                              overflow: hidden;
                              text-overflow: ellipsis;
                            ">
                            {{
                              car_version
                                  ? car_version.version_name_1 +
                                  ' ' +
                                  car_version.version_name_2
                                  : $t('Version')
                            }}
                          </div>
                          <i class="fa-solid fa-chevron-down"></i>
                        </div>
                      </div>
                    </div>

                    <div class="tire-info-text">
                      {{ $t('Tire Information') }}
                    </div>
                    <!--                    <div>-->
                    <!--                      *Orijinal ekipman boyutları şu an elimizde bulunan en iyi-->
                    <!--                      bilgilere dayanır. Ebat opsiyonlarına veya... devamını gör-->
                    <!--                    </div>-->

                    <div class="d-flex mt-3">
                      <div class="col-sm-6 col-md-6 col-lg-6 p-0">
                        <div
                            class="customer-type-select"
                            :class="tire_brand.name ? 'bg-transparent' : 'bg-f4'"
                            @click="showTireBrandPopup()">
                          <div class="customer-type-name">
                            {{
                              tire_brand.name ? tire_brand.name : $t('Brand')
                            }}
                          </div>
                          <i class="fa-solid fa-chevron-down"></i>
                        </div>
                      </div>

                      <div class="col-sm-6 col-md-6 col-lg-6 pr-0">
                        <div
                            class="customer-type-select"
                            :class="tire_width ? 'bg-transparent' : 'bg-f4'"
                            @click="showTireWidthPopup()">
                          <div class="customer-type-name">
                            {{ tire_width ? tire_width : $t('Width') }}
                          </div>
                          <i class="fa-solid fa-chevron-down"></i>
                        </div>
                      </div>
                    </div>

                    <div class="d-flex mt-3">
                      <div class="col-sm-6 col-md-6 col-lg-6 p-0">
                        <div
                            class="customer-type-select"
                            :class="tire_height ? 'bg-transparent' : 'bg-f4'"
                            @click="showTireHeightPopup()">
                          <div class="customer-type-name">
                            {{ tire_height ? tire_height : $t('Height') }}
                          </div>
                          <i class="fa-solid fa-chevron-down"></i>
                        </div>
                      </div>

                      <div class="col-sm-6 col-md-6 col-lg-6 pr-0">
                        <div
                            class="customer-type-select"
                            :class="tire_radius ? 'bg-transparent' : 'bg-f4'"
                            @click="showTireRadiusPopup()">
                          <div class="customer-type-name">
                            {{ tire_radius ? tire_radius : $t('Radius') }}
                          </div>
                          <i class="fa-solid fa-chevron-down"></i>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-sm-12 mt-4 mb-5 p-0">
                    <div class="contracts">
                      <b-form-checkbox
                          id="checkbox-1"
                          name="checkbox-1"
                          v-model="check_contract_status"
                          :value="true"
                          :unchecked-value="false"/>
                      <div class="w-100">
                        <span
                            class="font-servislet cursor-pointer m-0"
                            :class="check_contract ? 'text-danger' : ''"
                        >{{$t('Membership Agreement')}}</span
                        >{{$t('has read and accepted.')}}
                      </div>
                    </div>

                    <div class="contracts mt-2">
                      <b-form-checkbox
                          id="checkbox-2"
                          name="checkbox-2"
                          v-model="check_custom_contract_status"
                          :value="true"
                          :unchecked-value="false"/>
                      <div class="w-100">
                        <span
                            class="font-servislet cursor-pointer m-0"
                            :class="check_custom_contract ? 'text-danger' : ''"
                        >{{$t('General Disclosure Statement Regarding the Processing of Personal Data')}}</span
                        >{{$t('has read and accepted.')}}
                      </div>
                    </div>

                    <div class="contracts mt-2">
                      <b-form-checkbox
                          id="checkbox-3"
                          name="checkbox-3"
                          v-model="accept_status"
                          :value="true"
                          :unchecked-value="false"/>
                      <p>
                        {{$t('Processing my personal data to be informed about developments and campaigns and')}}
                        <span class="font-servislet cursor-pointer"
                        >{{$t('Consent Text')}}</span
                        > {{$t('has agreed to be sent electronic messages.')}}
                      </p>
                    </div>
                  </div>
                </div>
              </ValidationObserver>

              <div
                  class="continue-button"
                  v-if="!show_bottom_customer_type_popup">
                <button
                    class="btn"
                    @click="addToCustomerStoreFromMobile()">
                  {{ $t('Continue') }}
                </button>
              </div>
            </div>
          </div>

          <div
              class="customer-types-wrapper"
              v-if="show_bottom_customer_type_popup && type_popup"
              v-click-outside="closePopup">
            <div
                class="d-flex justify-content-center"
                @click="closePopup">
              <div class="toggle-menu"/>
            </div>
            <div
                class="customer-types"
                v-if="customerTypes.length > 0">
              <div
                  v-for="type in customerTypes"
                  :key="type.$index"
                  class="type-item"
                  :class="type === customerType ? 'selected-type' : ''"
                  @click="
                  customerType = type;
                  show_bottom_customer_type_popup = false;
                  type_popup = false;
                ">
                <i :class="type.icon"></i>
                <span>{{ type.name }}</span>
              </div>
            </div>
          </div>

          <div
              class="city-wrapper"
              v-if="show_bottom_customer_type_popup && city_popup"
              v-click-outside="closeCityPopup">
            <div
                class="d-flex justify-content-center"
                @click="closeCityPopup">
              <div class="toggle-menu"/>
            </div>
            <div class="mt-3 select-city-text">{{ $t('Choose City') }}</div>

            <div class="cities">
              <div
                  v-for="c in cities"
                  :key="c.$index"
                  class="city-item"
                  @click="
                  city = c;
                  setCity(city.id);
                  closeCityPopup();
                "
                  :class="city === c ? 'font-servislet' : 'font-gray'">
                <span>{{ c.name }}</span>
                <i
                    class="fa-solid fa-check"
                    v-if="c === city"></i>
              </div>
            </div>
          </div>

          <div
              class="city-wrapper"
              v-if="show_bottom_customer_type_popup && district_popup"
              v-click-outside="closeDistrictPopup">
            <div
                class="d-flex justify-content-center"
                @click="closeDistrictPopup">
              <div class="toggle-menu"/>
            </div>
            <div class="mt-3 select-city-text">{{ $t('Choose District') }}</div>

            <div class="cities">
              <div
                  v-for="d in districts"
                  :key="d.$index"
                  class="city-item"
                  @click="
                  district = d;
                  setDistrict(district.id);
                  closeDistrictPopup();
                "
                  :class="city === d ? 'font-servislet' : 'font-gray'">
                <span>{{ d.name }}</span>
                <i
                    class="fa-solid fa-check"
                    v-if="d === city"></i>
              </div>
            </div>
          </div>

          <div
              class="city-wrapper"
              v-if="show_bottom_customer_type_popup && area_popup"
              v-click-outside="closeAreaPopup">
            <div
                class="d-flex justify-content-center"
                @click="closeAreaPopup">
              <div class="toggle-menu"/>
            </div>
            <div class="mt-3 select-city-text">
              {{ $t('Choose Neighborhood') }}
            </div>

            <div class="cities">
              <div
                  v-for="a in areas"
                  :key="a.$index"
                  class="city-item"
                  @click="
                  area = a;
                  closeAreaPopup();
                "
                  :class="area === a ? 'font-servislet' : 'font-gray'">
                <span>{{ a.name }}</span>
                <i
                    class="fa-solid fa-check"
                    v-if="a === area"></i>
              </div>
            </div>
          </div>

          <div
              class="city-wrapper"
              v-if="show_bottom_customer_type_popup && brand_popup"
              v-click-outside="closeBrandPopup">
            <div
                class="d-flex justify-content-center"
                @click="closeBrandPopup">
              <div class="toggle-menu"/>
            </div>
            <div class="mt-3 select-city-text">{{ $t('Choose Brand') }}</div>

            <div class="cities">
              <div
                  v-for="brand in carBrands"
                  :key="brand.$index"
                  class="city-item"
                  @click="
                  car_brand = brand;
                  setCarBrand(car_brand.car_brand_id);
                  brand_popup = false;
                  show_bottom_customer_type_popup = false;
                "
                  :class="car_brand === brand ? 'font-servislet' : 'font-gray'">
                <span>{{ brand.name }}</span>
                <i
                    class="fa-solid fa-check"
                    v-if="brand === car_brand"></i>
              </div>
            </div>
          </div>

          <div
              class="city-wrapper"
              v-if="show_bottom_customer_type_popup && year_popup"
              v-click-outside="closeYearPopup">
            <div
                class="d-flex justify-content-center"
                @click="closeYearPopup">
              <div class="toggle-menu"/>
            </div>
            <div class="mt-3 select-city-text">{{ $t('Choose Year') }}</div>

            <div class="cities">
              <div
                  v-for="year in carYears"
                  :key="year.$index"
                  class="city-item"
                  @click="
                  car_year = year;
                  setCarYear();
                  show_bottom_customer_type_popup = false;
                  year_popup = false;
                "
                  :class="car_year === year ? 'font-servislet' : 'font-gray'">
                <span>{{ year ? year : $t('Choose') }}</span>
                <i
                    class="fa-solid fa-check"
                    v-if="car_year !== '' && year === car_year"></i>
              </div>
            </div>
          </div>

          <div
              class="city-wrapper"
              v-if="show_bottom_customer_type_popup && model_popup"
              v-click-outside="closeModelPopup">
            <div
                class="d-flex justify-content-center"
                @click="closeModelPopup">
              <div class="toggle-menu"/>
            </div>
            <div class="mt-3 select-city-text">{{ $t('Choose Model') }}</div>

            <div class="cities">
              <div
                  v-for="model in carModels"
                  :key="model.$index"
                  class="city-item"
                  @click="
                  car_model = model;
                  setCarModel();
                  show_bottom_customer_type_popup = false;
                  model_popup = false;
                "
                  :class="car_model === model ? 'font-servislet' : 'font-gray'">
                <span>{{ model.car_model_name }}</span>
                <i
                    class="fa-solid fa-check"
                    v-if="model === car_model"></i>
              </div>
            </div>
          </div>

          <div
              class="city-wrapper"
              v-if="show_bottom_customer_type_popup && version_popup"
              v-click-outside="closeVersionPopup">
            <div
                class="d-flex justify-content-center"
                @click="closeVersionPopup">
              <div class="toggle-menu"/>
            </div>
            <div class="mt-3 select-city-text">{{ $t('Choose Version') }}</div>

            <div class="cities">
              <div
                  v-for="version in carVersions"
                  :key="version.$index"
                  class="city-item"
                  @click="
                  car_version = version;
                  findCar();
                  show_bottom_customer_type_popup = false;
                  version_popup = false;
                "
                  :class="
                  car_version === version ? 'font-servislet' : 'font-gray'
                ">
                <span>{{
                    version.version_name_1 + ' ' + version.version_name_2
                  }}</span>
                <i
                    class="fa-solid fa-check"
                    v-if="version === car_version"></i>
              </div>
            </div>
          </div>

          <div
              class="city-wrapper"
              v-if="show_bottom_customer_type_popup && tire_brand_popup"
              v-click-outside="closeTireBrandPopup">
            <div
                class="d-flex justify-content-center"
                @click="closeTireBrandPopup">
              <div class="toggle-menu"/>
            </div>
            <div class="mt-3 select-city-text">{{ $t('Choose Brand') }}</div>

            <div class="cities">
              <div
                  v-for="brand in tireBrands"
                  :key="brand.$index"
                  class="city-item"
                  @click="
                  tire_brand = brand;
                  setTireBrand(tire_brand.slug);
                  show_bottom_customer_type_popup = false;
                  tire_brand_popup = false;
                "
                  :class="tire_brand === brand ? 'font-servislet' : 'font-gray'">
                <span>{{ brand.name }}</span>
                <i
                    class="fa-solid fa-check"
                    v-if="tire_brand === car_version"></i>
              </div>
            </div>
          </div>

          <div
              class="city-wrapper"
              v-if="show_bottom_customer_type_popup && tire_width_popup"
              v-click-outside="closeTireWidthPopup">
            <div
                class="d-flex justify-content-center"
                @click="closeTireWidthPopup">
              <div class="toggle-menu"/>
            </div>
            <div class="mt-3 select-city-text">{{ $t('Choose Width') }}</div>

            <div class="cities">
              <div
                  v-for="width in tireWidths"
                  :key="width.$index"
                  class="city-item"
                  @click="
                  tire_width = width;
                  setTireWidth();
                  show_bottom_customer_type_popup = false;
                  tire_width_popup = false;
                "
                  :class="tire_width === width ? 'font-servislet' : 'font-gray'">
                <span>{{ width ? width : $t('Choose') }}</span>
                <i
                    class="fa-solid fa-check"
                    v-if="tire_width === width"></i>
              </div>
            </div>
          </div>

          <div
              class="city-wrapper"
              v-if="show_bottom_customer_type_popup && tire_height_popup"
              v-click-outside="closeTireHeightPopup">
            <div
                class="d-flex justify-content-center"
                @click="closeTireHeightPopup">
              <div class="toggle-menu"/>
            </div>
            <div class="mt-3 select-city-text">{{ $t('Choose Height') }}</div>

            <div class="cities">
              <div
                  v-for="height in tireHeights"
                  :key="height.$index"
                  class="city-item"
                  @click="
                  tire_height = height;
                  setTireHeight();
                  show_bottom_customer_type_popup = false;
                  tire_height_popup = false;
                "
                  :class="
                  tire_height === height ? 'font-servislet' : 'font-gray'
                ">
                <span>{{ height ? height : $t('Choose') }}</span>
                <i
                    class="fa-solid fa-check"
                    v-if="tire_height === height"></i>
              </div>
            </div>
          </div>

          <div
              class="city-wrapper"
              v-if="show_bottom_customer_type_popup && tire_radius_popup"
              v-click-outside="closeTireRadiusPopup">
            <div
                class="d-flex justify-content-center"
                @click="closeTireRadiusPopup">
              <div class="toggle-menu"/>
            </div>
            <div class="mt-3 select-city-text">{{ $t('Choose Radius') }}</div>

            <div class="cities">
              <div
                  v-for="radius in tireRadiuses"
                  :key="radius.$index"
                  class="city-item"
                  @click="
                  tire_radius = radius;
                  setTireWidth();
                  show_bottom_customer_type_popup = false;
                  tire_radius = false;
                "
                  :class="
                  tire_radius === radius ? 'font-servislet' : 'font-gray'
                ">
                <span>{{ radius ? radius : $t('Choose') }}</span>
                <i
                    class="fa-solid fa-check"
                    v-if="tire_radius === radius"></i>
              </div>
            </div>
          </div>
        </div>

        <div
            class="mobile-success"
            v-if="mobile_success">
          <img
              alt="Servislet Pro"
              class="mt-3"
              src="../../assets/images/logo.png"
              height="30"/>
          <div class="success-card neu">
            <span class="check-icon">
              <i class="fa-solid fa-circle-check"></i>
            </span>

            <div class="success-text">
              <!--              {{ branch.name }} İçin Müşteri Kaydı Başarıyla Oluşturuldu-->
              {{ $t('Customer Registration Successfully Created') }}
            </div>

            <div class="success-text-description">
              {{
                $t(
                    'You can easily and quickly access all the needs of your vehicle by becoming a member of Servislet immediately with the password you will set.'
                )
              }}
            </div>
            <div class="d-flex justify-content-center">
              <a
                  href="https://www.servislet.com/uyelik"
                  target="_blank"
                  class="btn servislet-register-btn"
              >{{ $t('Sign Up Now') }}</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
        class="container"
        v-else>
      <div class="row mt-5">
        <div class="col-lg-12">
          <div class="neu p-4">
            <content-placeholders :rounded="true">
              <content-placeholders-text :lines="3"/>
            </content-placeholders>
          </div>
        </div>
        <div class="col-md-9 col-lg-9 mt-3">
          <div class="neu p-4">
            <content-placeholders :rounded="true">
              <content-placeholders-text :lines="10"/>
            </content-placeholders>
          </div>
        </div>
        <div class="col-md-3 col-lg-3 mt-3">
          <div class="neu p-4">
            <content-placeholders :rounded="true">
              <content-placeholders-text :lines="10"/>
            </content-placeholders>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import axios from "@/plugins/axios";
import vClickOutside from 'v-click-outside';

export default {
  name: 'RegisterView',
  directives: {
    clickOutside: vClickOutside.directive
  },
  data() {
    return {
      loading: true,
      customer: true,
      address: false,
      vehicle: false,
      name: '',
      surname: '',
      phone: '',
      email: '',
      job_title: '',
      company_title: '',
      tax_office: '',
      tax_number: '',
      city: {
        id: null,
        name: this.$i18n.t('Choose')
      },
      district: {
        id: null,
        name: this.$i18n.t('Choose')
      },
      area: {
        id: null,
        name: this.$i18n.t('Choose')
      },
      customerType: {},
      car_brand: {
        car_brand_id: null,
        name: this.$i18n.t('Choose')
      },
      car_year: '',
      car_model: {
        car_model_id: null,
        car_model_name: this.$i18n.t('Choose')
      },
      car_version: '',
      car_plate: '',
      car_chassis_number: '',
      tire_brand: {
        id: null,
        name: this.$i18n.t('Choose')
      },
      tire_width: '',
      tire_height: '',
      tire_radius: '',
      car_id: null,
      address_str: '',
      address_type: {
        value: null,
        text: this.$i18n.t('Choose')
      },
      address_types: [
        {
          value: null,
          text: this.$i18n.t('Choose')
        }
      ],
      city_focus: true,
      district_focus: false,
      area_focus: false,
      car_brand_focus: true,
      car_year_focus: false,
      car_model_focus: false,
      car_version_focus: false,
      brand_focus: true,
      width_focus: false,
      height_focus: false,
      radius_focus: false,
      check_contract_status: false,
      check_contract: null,
      check_custom_contract_status: false,
      check_custom_contract: null,
      accept_status: null,
      branch: {},
      carBrands: [
        {
          car_brand_id: null,
          name: this.$i18n.t('Choose')
        },
      ],
      carYears: [''],
      carModels: [
        {
          car_model_id: null,
          car_model_name: this.$i18n.t('Choose')
        }
      ],
      carVersions: [],
      tireBrands: [
        {
          id: null,
          name: this.$i18n.t('Choose')
        }
      ],
      customerTypes: [],
      tireWidths: [''],
      tireHeights: [''],
      tireRadiuses: [''],
      cities: [
        {
          id: null,
          name: this.$i18n.t('Choose')
        }
      ],
      districts: [
        {
          id: null,
          name: this.$i18n.t('Choose')
        }
      ],
      areas: [
        {
          id: null,
          name: this.$i18n.t('Choose')
        }
      ],
      invoiceTypes: [],
      show_bottom_customer_type_popup: false,
      mobile_user: true,
      mobile_address: false,
      mobile_car: false,
      address_title: '',
      city_popup: false,
      district_popup: false,
      area_popup: false,
      brand_popup: false,
      year_popup: false,
      model_popup: false,
      version_popup: false,
      tire_brand_popup: false,
      tire_width_popup: false,
      tire_height_popup: false,
      tire_radius_popup: false,
      type_popup: false,
      show_plate_error: false,
      mobile_success: false
    };
  },
  mounted() {
    this.fetchInvoiceTypes();
    this.fetchCarBrands();
    this.fetchTireBrands();
    this.fetchCustomerTypes();
    this.fetchCities();
    axios.get('/account-info', {
      headers: {
        Authorization: `Bearer ${this.$route.params.token}`
      }
    }).then((response) => {
      this.branch = response.data.responseData[0];
      this.customerType = this.customerTypes[0]
          ? this.customerTypes[0]
          : this.customerType;
      this.loading = false;
      // this.mobile_success = false
    })
        .catch((error) => {
          var message =
              error.response.status === 400
                  ? this.$i18n.t(
                      'The redirect link is not valid. Please create a new link.'
                  )
                  : error.response.data.statusMessage;
          this.$swal.fire({
            iconHtml: '<img src="/img/error.svg" width="32" height="32">',
            position: 'top-end',
            title: message,
            showConfirmButton: false,
            timer: 2500,
            toast: true,
            customClass: {
              icon: 'swal2-no-border'
            }
          });
        });
  },
  watch:{
    customerType(){
      if(this.customerType.code !== '101'){
        this.address_types.forEach(item=>{
          if(item.code === "102"){
            this.address_type = item
          }
        })
      }
    }
  },
  methods: {
    formatCarPlate() {
      if (this.car_plate) {
        if (!(this.car_plate.substring(2, 3) >= '0' && this.car_plate.substring(2, 3) <= '9')) {
          this.car_plate = window.helper.mask(this.car_plate)
        } else {
          this.$swal({
            iconHtml: '<img src="/img/warning.png" width="32" height="32">',
            icon: 'warning',
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            text: this.$i18n.t('Please enter plate in valid format.'),
            customClass: {
              icon: 'swal2-no-border'
            }
          })
        }
      }
    },
    fetchCarBrands() {
      axios.get('/cars/brands', {
        headers: {
          Authorization: `Bearer ${this.$route.params.token}`
        }
      }).then((response) => {
        this. carBrands = [
          {
            car_brand_id: null,
            name: this.$i18n.t('Choose')
          }
        ]
        response.data.responseData.carBrands.forEach(item=>{
          this.carBrands.push(item)
        })
        // this.carBrands = response.data.responseData.carBrands;
      });
    },
    fetchTireBrands() {
      axios.get('/tire/brands', {
        headers: {
          Authorization: `Bearer ${this.$route.params.token}`
        }
      }).then((response) => {
        // this.tireBrands = response.data.responseData.brands;
        this.tireBrands = [
          {
            id: null,
            name: this.$i18n.t('Choose')
          }
        ]
        response.data.responseData.brands.forEach(item=>{
          this.tireBrands.push(item)
        })
      });
    },
    fetchCustomerTypes() {
      axios.get('/customer-types', {
        headers: {
          Authorization: `Bearer ${this.$route.params.token}`
        }
      }).then((response) => {
        this.customerTypes = response.data.responseData.customerTypes;
        response.data.responseData.customerTypes.forEach(item=>{
          if(item.code === '101'){
            this.customerType = item
          }
        })
      });
      axios.get('/invoice-types', {
        headers: {
          Authorization: `Bearer ${this.$route.params.token}`
        }
      }).then((response) => {
        this.invoiceTypes = response.data.responseData.invoiceTypes;
        response.data.responseData.invoiceTypes.forEach((type) => {
          this.address_types.push({
            value: type.id,
            text: type.name,
            code: type.code
          });
        });
        this.address_types.forEach(item=>{
          if(item.code === "101"){
            this.address_type = item
          }
        })
      });
    },
    fetchCities() {
      axios.get('/regions/cities', {
        headers: {
          Authorization: `Bearer ${this.$route.params.token}`
        }
      }).then((response) => {
        // this.cities = response.data.responseData.cities;
        this.cities = [
          {
            id: null,
            name: this.$i18n.t('Choose')
          }
        ]
        response.data.responseData.cities.forEach(item=>{
          this.cities.push(item)
        })
      });
    },
    fetchInvoiceTypes() {

    },
    setCarBrand(car_brand_id) {
      axios.get(`/cars/years?car_brand_id=${car_brand_id}`, {
        headers: {
          Authorization: `Bearer ${this.$route.params.token}`
        }
      }).then((response) => {
        // this.carYears = response.data.responseData.carYears;
        this.carYears = ['']
        response.data.responseData.carYears.forEach(item=>{
          this.carYears.push(item)
        })
      });
    },
    setCarYear() {
      axios.get(`/cars/models?car_brand_id=${this.car_brand.car_brand_id}&year=${this.car_year}`, {
        headers: {
          Authorization: `Bearer ${this.$route.params.token}`
        }
      }).then((response) => {
        // this.carModels = response.data.responseData.carModels;
        this.carModels = [
          {
            car_model_id: null,
            car_model_name: this.$i18n.t('Choose')
          }
        ]
        response.data.responseData.carModels.forEach(item=>{
          this.carModels.push(item)
        })
      });
    },
    setCarModel() {
      axios.get(`/cars/versions?car_brand_id=${this.car_brand.car_brand_id}&year=${this.car_year}&car_model_id=${this.car_model.car_model_id}`, {
        headers: {
          Authorization: `Bearer ${this.$route.params.token}`
        }
      }).then((response) => {
        this.carVersions = response.data.responseData;
      });
    },
    setTireBrand(brand_slug) {
      axios.get(`/tire/widths?brand_slugs=${brand_slug}`, {
        headers: {
          Authorization: `Bearer ${this.$route.params.token}`
        }
      }).then((response) => {
        // this.tireWidths = response.data.responseData.widths;
        this.tireWidths = ['']
        response.data.responseData.widths.forEach(item=>{
          this.tireWidths.push(item)
        })
      });
    },
    setTireWidth() {
      axios.get(`/tire/heights?brand_slugs=${this.tire_brand.slug}&width=${this.tire_width}`, {
        headers: {
          Authorization: `Bearer ${this.$route.params.token}`
        }
      }).then((response) => {
        // this.tireHeights = response.data.responseData.heights;
        this.tireHeights = ['']
        response.data.responseData.heights.forEach(item=>{
          this.tireHeights.push(item)
        })
      });
    },
    setTireHeight() {
      axios.get(`/tire/radiuses?brand_slugs=${this.tire_brand.slug}&width=${this.tire_width}&height=${this.tire_height}`, {
        headers: {
          Authorization: `Bearer ${this.$route.params.token}`
        }
      }).then((response) => {
        // this.tireRadiuses = response.data.responseData.radiuses;
        this.tireRadiuses = ['']
        response.data.responseData.radiuses.forEach(item=>{
          this.tireRadiuses.push(item)
        })
      });
    },
    setCity(city_id) {
      axios.get(`/regions/districts?city_id=${city_id}`, {
        headers: {
          Authorization: `Bearer ${this.$route.params.token}`
        }
      }).then((response) => {
        // this.districts = response.data.responseData.districts;
        this.districts = [
          {
            id: null,
            name: this.$i18n.t('Choose')
          }
        ]
        response.data.responseData.districts.forEach(item=>{
          this.districts.push(item)
        })
      });
    },
    setDistrict(district_id) {
      axios.get(`/regions/areas?district_id=${district_id}`, {
        headers: {
          Authorization: `Bearer ${this.$route.params.token}`
        }
      }).then((response) => {
        // this.areas = response.data.responseData.areas;
        this.areas = [
          {
            id: null,
            name: this.$i18n.t('Choose')
          }
        ]
        response.data.responseData.areas.forEach(item=>{
          this.areas.push(item)
        })
      });
    },
    checkCustomerStep() {
      this.$refs.customerAddRules.validate().then((success) => {
        if (success) {
          this.address = true;
        } else {
          this.$swal.fire({
            iconHtml: '<img src="/img/warning.png" width="32" height="32">',
            position: 'top-end',
            title: this.$i18n.t('Please fill the required fields.'),
            showConfirmButton: false,
            timer: 2500,
            toast: true,
            customClass: {
              icon: 'swal2-no-border'
            }
          });
        }
      });
      // if(Object.keys(this.customerType).length !== 0 && this.name && this.surname && this.phone && this.email) {
      //     this.address = true
      // } else {
      //     this.$swal.fire({
      //         position: 'top-end',
      //         icon: 'error',
      //         title: 'Lütfen zorunlu alanları doldurun.',
      //         showConfirmButton: false,
      //         timer: 2500,
      //         toast: true
      //     })
      // }
    },
    checkAddressStep() {
      if (Object.keys(this.city).length > 0 || this.address_str) {
        if (this.address_type.value) {
          this.$refs.addressAddRules.validate().then((success) => {
            if (success) {
              this.vehicle = true;
            } else {
              this.$swal.fire({
                iconHtml: '<img src="/img/warning.png" width="32" height="32">',
                position: 'top-end',
                title: this.$i18n.t('Please fill the required fields.'),
                showConfirmButton: false,
                timer: 2500,
                toast: true,
                customClass: {
                  icon: 'swal2-no-border'
                }
              });
            }
          });
        } else {
          this.$swal.fire({
            iconHtml: '<img src="/img/error.svg" width="32" height="32">',
            position: 'top-end',
            title: this.$i18n.t('Please select address type.'),
            showConfirmButton: false,
            timer: 2500,
            toast: true,
            customClass: {
              icon: 'swal2-no-border'
            }
          });
        }
      } else {
        this.vehicle = true;
      }
    },
    findCar() {
      this.car_id = this.car_version.car.id;
      this.tire_width = this.car_version.tire.width;
      this.tire_height = this.car_version.tire.height;
      this.tire_radius = this.car_version.tire.radius;
      return false;
      axios({
        method: 'get',
        baseURL: 'https://staging-pro-api.servislet.com/api/v1/web/main',
        url: `/cars/find-car?car_brand_id=${this.car_brand.car_brand_id}&car_model_id=${this.car_model.car_model_id}&year=${this.car_year}&body_style=${this.car_version.body_style}&fuel_type=${this.car_version.fuel_type}&gear_type=${this.car_version.gear_type}&version_type=${this.car_version.version_type}`,
        headers: {
          Authorization: `Bearer ${this.$route.params.token}`
        }
      }).then((response) => {
        if (response.data.responseData.cars.length > 0) {
          this.car_id = response.data.responseData.cars[0].car_id;
        } else {
          this.$swal.fire({
            iconHtml: '<img src="/img/warning.png" width="32" height="32">',
            position: 'top-end',
            title: 'Seçtiğiniz filtrelere göre araç bulunamadı.',
            showConfirmButton: false,
            timer: 2500,
            toast: true,
            customClass: {
              icon: 'swal2-no-border'
            }
          });
        }
      });
    },
    addToCustomerStore() {
      this.$refs.carAddRules.validate().then((success) => {
        this.check_contract = this.check_contract_status === false;
        this.check_custom_contract = this.check_custom_contract_status === false;

        if (success && !this.check_contract && !this.check_custom_contract) {
          this.addFunction();
        } else {
          this.$swal.fire({
            iconHtml: '<img src="/img/warning.png" width="32" height="32">',
            position: 'top-end',
            title: this.$i18n.t('Please fill the required fields.'),
            showConfirmButton: false,
            timer: 2500,
            toast: true,
            customClass: {
              icon: 'swal2-no-border'
            }
          });
        }
      });
    },
    addToCustomerStoreFromMobile() {
      this.$refs.mobileCarAddRules.validate().then((success) => {
        this.check_contract = this.check_contract_status === false;
        this.check_custom_contract =
            this.check_custom_contract_status === false;
        if (this.car_id && !this.car_plate) {
          this.show_plate_error = true;
        }
        if (!this.car_id && this.car_plate) {
          this.$swal.fire({
            iconHtml: '<img src="/img/error.svg" width="32" height="32">',
            position: 'top-end',
            title: this.$i18n.t('Please choose car.'),
            showConfirmButton: false,
            timer: 2500,
            toast: true,
            customClass: {
              icon: 'swal2-no-border'
            }
          });
        }

        if ((success && !this.check_contract && !this.check_custom_contract) || !this.show_plate_error) {
          this.addFunction();
        } else {
          this.$swal.fire({
            iconHtml: '<img src="/img/warning.png" width="32" height="32">',
            position: 'top-end',
            title: this.$i18n.t('Please fill the required fields.'),
            showConfirmButton: false,
            timer: 2500,
            toast: true,
            customClass: {
              icon: 'swal2-no-border'
            }
          });
        }
      });
    },
    addFunction() {
      var self = this;
      let body = {
        customer: {
          name: this.name,
          surname: this.surname,
          email: this.email,
          mobile: this.phone,
          job_title: this.job_title,
          company_title: this.company_title,
          tax_number: this.tax_number,
          tax_office: this.tax_office,
          customer_type_id: this.customerType.id
              ? this.customerType.id
              : this.customerTypes[0].id
        },
        phone: [
          {
            is_default: true,
            phone: this.phone
          }
        ]
      };
      if (this.address_type.value) {
        body.address = {
          phone: this.phone,
          invoice_type_id: this.address_type.value,
          city_id: this.city.id,
          district_id: this.district.id,
          area_id: this.area.id,
          address: this.address_str
        };
        if (this.car_id || this.car_plate) {
          body.car = {
            car_id: this.car_id,
            plate_number: this.car_plate,
            chassis_number: this.car_chassis_number ?? '00000000000000000',
            year: this.car_year.toString(),
            tire_brand_id: this.tire_brand.id,
            width: this.tire_width,
            height: this.tire_height,
            radius: this.tire_radius,
            is_active: true
          };
        }
      }
      axios.post(`/customers`, body, {
        headers: {
          Authorization: `Bearer ${this.$route.params.token}`
        }
      }).then((response) => {
        if (response.data.status) {
          if (window.screen.width < 600) {
            this.mobile_success = true;
          } else {
            self.$swal.fire({
              iconHtml: '<img src="/img/success.svg" width="32" height="32">',
              position: 'top-end',
              title: this.$i18n.t('Customer successfully registered.'),
              showConfirmButton: false,
              timer: 2500,
              toast: true,
              customClass: {
                icon: 'swal2-no-border'
              }
            });
          }
          setTimeout(()=>{
            window.location.reload();
          },1500)
        }
      }).catch(function (error) {
        self.$swal.fire({
          iconHtml: '<img src="/img/error.svg" width="32" height="32">',
          position: 'top-end',
          title: error.response.data.responseData.message,
          showConfirmButton: false,
          timer: 2500,
          toast: true,
          customClass: {
            icon: 'swal2-no-border'
          }
        });
      });
    },

    closePopup() {
      var element = document.getElementById('customer_type_popup');
      element.classList.remove('b-40-bg');
      this.show_bottom_customer_type_popup =
          !this.show_bottom_customer_type_popup;
    },
    checkMobileCustomerStep() {
      this.$refs.mobileCustomerAddRules.validate().then((success) => {
        if (success) {
          this.mobile_address = true;
        } else {
          this.$swal.fire({
            iconHtml: '<img src="/img/warning.png" width="32" height="32">',
            position: 'top-end',
            title: this.$i18n.t('Please fill the required fields.'),
            showConfirmButton: false,
            timer: 2500,
            toast: true,
            customClass: {
              icon: 'swal2-no-border'
            }
          });
        }
      });
    },
    closeCityPopup() {
      this.closePopup();
      this.city_popup = false;
    },
    closeDistrictPopup() {
      this.closePopup();
      this.district_popup = false;
    },
    closeAreaPopup() {
      this.closePopup();
      this.area_popup = false;
    },
    closeBrandPopup() {
      this.closePopup();
      this.brand_popup = false;
    },
    closeYearPopup() {
      this.closePopup();
      this.year_popup = false;
    },
    closeModelPopup() {
      this.closePopup();
      this.model_popup = false;
    },
    closeVersionPopup() {
      this.closePopup();
      this.version_popup = false;
    },
    closeTireBrandPopup() {
      this.closePopup();
      this.tire_brand_popup = false;
    },
    closeTireWidthPopup() {
      this.closePopup();
      this.tire_width_popup = false;
    },
    closeTireHeightPopup() {
      this.closePopup();
      this.tire_height_popup = false;
    },
    closeTireRadiusPopup() {
      this.closePopup();
      this.tire_radius_popup = false;
    },
    showDistrictPopup() {
      if (this.city.name) {
        this.show_bottom_customer_type_popup =
            !this.show_bottom_customer_type_popup;
        this.district_popup = !this.district_popup;
      }
    },
    showAreaPopup() {
      if (this.district.name) {
        this.show_bottom_customer_type_popup =
            !this.show_bottom_customer_type_popup;
        this.area_popup = !this.area_popup;
      }
    },
    showYearPopup() {
      if (this.tire_brand.name) {
        this.show_bottom_customer_type_popup =
            !this.show_bottom_customer_type_popup;
        this.year_popup = !this.year_popup;
        this.brand_popup = false;
        this.model_popup = false;
        this.version_popup = false;
      }
    },
    showModelPopup() {
      if (this.car_year) {
        this.show_bottom_customer_type_popup =
            !this.show_bottom_customer_type_popup;
        this.model_popup = !this.model_popup;
        this.brand_popup = false;
        this.year_popup = false;
        this.version_popup = false;
      }
    },
    showVersionPopup() {
      this.show_bottom_customer_type_popup =
          !this.show_bottom_customer_type_popup;
      this.version_popup = !this.version_popup;
      this.brand_popup = false;
      this.year_popup = false;
      this.model_popup = false;
    },
    showTireBrandPopup() {
      this.show_bottom_customer_type_popup =
          !this.show_bottom_customer_type_popup;
      this.tire_brand_popup = !this.tire_brand_popup;
      this.brand_popup = false;
      this.model_popup = false;
      this.year_popup = false;
      this.version_popup = false;
      this.tire_width_popup = false;
    },
    showTireWidthPopup() {
      this.show_bottom_customer_type_popup =
          !this.show_bottom_customer_type_popup;
      this.tire_width_popup = !this.tire_width_popup;
      this.brand_popup = false;
      this.model_popup = false;
      this.year_popup = false;
      this.version_popup = false;
      this.tire_brand_popup = false;
    },
    showTireHeightPopup() {
      this.show_bottom_customer_type_popup =
          !this.show_bottom_customer_type_popup;
      this.tire_height_popup = !this.tire_height_popup;
      this.brand_popup = false;
      this.model_popup = false;
      this.year_popup = false;
      this.version_popup = false;
      this.tire_brand_popup = false;
      this.tire_width_popup = false;
    },
    showTireRadiusPopup() {
      this.show_bottom_customer_type_popup =
          !this.show_bottom_customer_type_popup;
      this.tire_radius_popup = !this.tire_radius_popup;
      this.brand_popup = false;
      this.model_popup = false;
      this.year_popup = false;
      this.version_popup = false;
      this.tire_brand_popup = false;
    }
  }
};
</script>

<style scoped>
.web-logo{
  max-width: 150px;
}
#register {
  background: #f9f9f9;
}

.register-head-wrapper {
  text-align: right;
  color: #393939;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
}

.neu {
  background: #f9f9f9;
  box-shadow: 5px 5px 8px #dcdcdc, -5px -5px 8px #f6f6f6;
  border-radius: 15px;
}

.register-content-wrapper {
  width: 100%;
  max-width: 950px;
  margin-left: auto;
  margin-right: auto;
  padding: 2.5rem;
}

.form-title {
  color: var(--1-theme-color-heading-display-text, #5e5873);
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

#customer-type-selector .custom-select {
  color: #777;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}

.register-actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
}

.register-actions button {
  border-radius: 5px;
  border: 1px solid;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.4px;
  width: 100%;
  max-width: 140px;
  height: 36px;
  transition: 0.5s;
}

.cancel {
  background: #f9f9f9;
  color: #d3d3d3;
}

.next {
  background: #1c1c1c;
  color: #f4f4f4;
}

.mt-10rem {
  margin-top: 5rem !important;
}

.register-content-wrapper .form-control {
  background: #f4f4f4 !important;
}

.branch-head-wrapper {
  width: 100%;
  color: #f9f9f9;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 2rem;
  padding: 0.75rem 1rem;
  border-radius: 10px;
}

.branch-head-left-wrapper {
  display: flex;
  align-items: center;
}

.branch-head-left-wrapper .branch-name {
  margin-left: 1rem;
  font-size: 18px;
  font-weight: 500;
}

.customer-info-form-header-title {
  color: #1c1c1c;
  font-weight: 700;
  font-size: 26px;
}

.customer-info-form-header-info {
  color: #777;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  margin-top: 1rem;
}

.right-customer-content {
  padding: 1rem;
}

.customer-info-title {
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  color: #1c1c1c;
}

.customer-right-flex-wrapper {
  display: flex;
  justify-content: space-between;
}

.customer-right-flex-wrapper .left-flex {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
  color: #6e6b7b;
}

.customer-right-flex-wrapper .right-flex {
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  text-align: right;
  color: #1c1c1c;
  width: 50%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

hr {
  border-top: 1px solid rgba(211, 211, 211);
}

.active-border {
  border: 1px solid #06a5d0;
}

.contracts {
  display: flex;
  align-items: flex-start;
  font-size: 12px;
}

.custom-control {
  display: flex;
  align-items: flex-start;
  z-index: 0;
}

@media screen and (max-width: 600px) {
  .web-register {
    display: none;
  }

  .mobile-register {
    display: block;
  }
}

@media screen and (min-width: 600px) {
  .mobile-register {
    display: none;
  }

  .web-register {
    display: block;
  }

  #register {
    padding-top: 2rem;
  }

  #register .form-control:valid ~ label {
    background-color: transparent;
  }
}

.mobile-register {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.mobile-register-head-wrapper {
  background-color: #d3d3d3;
  text-align: right;
  padding: 2rem;
  color: #1c1c1c;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  /*position: absolute;*/
  height: fit-content;
  width: 100%;
}

.mobile-branch-head-wrapper {
  width: 100%;
  color: #f9f9f9;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 0.75rem 1rem;
  border-radius: 10px;
}

.mobile-branch-head {
  display: flex;
  align-items: center;
}

.mobile-branch-asset-path img {
  background-color: #fff;
  height: 30px;
  padding: 0.25rem 0.5rem;
  border-radius: 5px;
}

.mobile-branch-name {
  margin-left: 1rem;
  font-weight: 600;
  font-size: 15px;
  color: #f9f9f9;
}

.mobile-customer-info-form-text {
  text-align: left;
}

.mobile-customer-info-form-text .title {
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
}

.mobile-customer-info-form-text .description {
  font-size: 14px;
  font-weight: 300;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
  margin-top: 0.5rem;
  color: #777;
}

.add-car-text {
  display: flex;
  align-items: center;
  color: #1c1c1c;
  font-weight: 600;
  margin-top: 1rem;
}

.add-car-text span {
  text-decoration: underline;
  margin-right: 0.5rem;
}

.continue-button {
  background-color: #fff;
  height: 12%;
  position: fixed;
  bottom: 0;
  width: 100%;
  box-shadow: 0px -4px 9px 0px #1c1c1c2b;
  padding: 0 2rem;
  display: flex;
  align-items: center;
}

.continue-button button {
  height: 50px;
  font-size: 14px;
  font-weight: 600;
}

.mobile-register-form {
  background-color: #fff;
  padding: 1.5rem 2rem 5rem 1.5rem;
  width: 100%;
  height: fit-content;
}

.personal-info-text {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  color: #777;
}

.continue-button button,
.continue-button button:hover {
  background-color: #1c1c1c;
  color: #eaeaea;
  width: 100%;
  border-radius: 5px;
}

.customer-types-wrapper {
  padding: 0.5rem 2rem 2rem;
  background-color: #fff;
  height: 25%;
  position: fixed;
  bottom: 0;
  width: 100%;
  border: 1px solid transparent;
  border-radius: 20px 20px 0 0;
}

.toggle-menu {
  width: 70px;
  height: 5px;
  background-color: #d3d3d3;
  border-radius: 5px;
  cursor: pointer;
}

.customer-types {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  width: 100%;
  overflow-x: scroll;
}

.customer-types::-webkit-scrollbar {
  display: none;
}

.type-item {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  min-width: 100px;
  height: 100px;
  /*padding: .5rem .75rem;*/
  border: 1px solid #d3d3d3;
  border-radius: 10px;
  margin-right: 0.5rem;
}

.type-item i {
  font-size: 20px;
  background-color: rgba(217, 217, 217, 0.5);
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
}

.b-40-bg {
  filter: brightness(40%);
  overflow-y: hidden;
}

.type-item span {
  margin-top: 0.55rem;
  color: #1c1c1c;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.selected-type {
  border: 1px solid #06a5d0;
  background: rgba(6, 165, 208, 0.08);
}

.selected-type span {
  color: #06a5d0;
}

.selected-type i {
  background-color: #06a5d0;
  color: #fff;
}

.customer-type-select {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.65rem 0.85rem;
  border: 1px solid #dee2e6;
  border-radius: 0.375rem;
  min-height: 50px;
}

.customer-type-select .customer-type-name,
.customer-type-select i {
  font-size: 13px;
  font-weight: normal;
  color: #777;
}

.required-text {
  color: rgba(119, 119, 119, 0.8);
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.city-wrapper {
  padding: 0.5rem 2rem 2rem;
  background-color: #fff;
  height: 35%;
  position: fixed;
  bottom: 0;
  width: 100%;
  border: 1px solid transparent;
  border-radius: 20px 20px 0 0;
}

.select-city-text {
  color: #1c1c1c;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-bottom: 1px solid #ebe9f1;
  padding-bottom: 0.5rem;
}

.cities {
  padding: 0.5rem 0;
  display: flex;
  flex-direction: column;
  height: 250px;
  overflow: scroll;
}

.city-item {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 0.5rem 1rem 0.5rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bg-f4 {
  background-color: #f4f4f4;
}

.tire-info-text {
  color: #1c1c1c;
  margin: 1.5rem 0;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.mobile-success {
  padding: 1rem;
  text-align: center;
}

.success-card {
  margin-top: 1rem;
  padding: 1.5rem;
}

.check-icon i {
  color: #06a5d0;
  font-size: 60px;
  background-color: rgba(6, 165, 208, 0.2);
  padding: 1rem;
  border-radius: 100%;
}

.success-text {
  color: #000;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  margin-top: 1.5rem;
}

.success-text-description {
  color: #1c1c1c;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  margin-top: 1rem;
}

.servislet-register-btn,
.servislet-register-btn:focus {
  border: 1px solid #06a5d0;
  color: #06a5d0;
  margin-top: 1rem;
  min-height: 50px;
  padding: 6px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
}
</style>
<style>
#register .form-group.input-material {
  position: relative;
  margin-bottom: 20px;
  /*padding: 1rem;*/
  height: 50px;
}

/*
scope içinde olmayan tüm input cssleri aşağıdaki gibi başına wrapper elementinin id'si ile yazılmalı
çünkü genel input yapısını bozuyor. aşağıdaki örnek çok bariz olan bir bozulmaydı onu düzenledim ama diğerlerinin de
bu şekilde düzenlenmesi gerekiyor. yaptığın değişiklikler bozmayayım diye sadece bir tanesine ekledim
*/
#register .form-group .form-control {
  min-height: 50px;
}

#register .form-control:focus .input-material {
  border: 1px solid #06a5d0;
}

#register .form-group.input-material label {
  color: #777;
  font-size: 13px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  bottom: 10px;
  left: 10px;
  transition: 0.1s ease all;
  -moz-transition: 0.1s ease all;
  -webkit-transition: 0.1s ease all;
}

#register .form-group.input-material .form-control:required ~ label::after,
#register .form-group.input-material .form-select:required ~ label::after {
  content: '*';
  /*color: #dc3545;*/
  color: #777;
  padding-left: 2px;
  font-size: 0.8em;
}

#register .form-group.input-material .no-required:required ~ label::after {
  content: '';
}

#register .form-group.input-material .form-control:focus,
#register .form-group.input-material .form-control[value]:not([value='']),
#register .form-group.input-material .form-select[value]:not([value='']) {
  border: 1px solid #06a5d0 !important;
  background-color: #fff !important;
  color: #777;
}

#register .form-group.input-material .form-control:focus ~ label,
#register .form-group.input-material .form-select:focus ~ label,
#register
.form-group.input-material
.form-control[value]:not([value=''])
~ label,
#register
.form-group.input-material
.form-select[value]:not([value=''])
~ label {
  top: unset;
  bottom: 100%;
  bottom: calc(100% - 16px);
  font-size: 12px;
  color: #06a5d0;
  font-weight: 500;
  margin-bottom: 10px;
  padding: 0 5px;
  background-color: #ffffff;
}

#register .form-group.input-material .form-control:focus.invalid,
#register .form-group.input-material .form-control.invalid {
  border-bottom-color: #dc3545;
}

#register .form-group.input-material .form-control:focus.invalid ~ label,
#register .form-group.input-material .form-select:focus.invalid ~ label,
#register .form-group.input-material .form-control.invalid ~ label,
#register .form-group.input-material .form-select.invalid ~ label,
#register .form-group.input-material .form-control:focus.parsley-error ~ label,
#register .form-group.input-material .form-select:focus.parsley-error ~ label,
#register
.form-group.input-material
.form-control[value]:not([value='']).parsley-error
~ label,
#register
.form-group.input-material
.form-select[value]:not([value='']).parsley-error
~ label {
  color: #dc3545;
}

#register .form-control:valid ~ label {
  top: unset;
  bottom: calc(100% - 16px);
  font-size: 10px;
  color: #777777;
  font-weight: 500;
  margin-bottom: 10px;
  padding: 0 10px;
  background: #fff;
  left: 10px;
}

#register .form-group.input-material .form-control:focus ~ label::after {
  color: #06a5d0;
}
</style>
