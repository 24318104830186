<template>
  <div>
    <div class="component-container mt-3">
      <div :class="show ? 'opened-border' : 'closed-border'" class="component__select cursor-pointer" @click="show = !show">
        <span v-if="Object.keys(selected).length>0" class="component__select--name">{{selected.name}}</span>
        <span v-else class="component__select--name">{{$t('Choose')}}</span>

        <div>
          <i v-if="selected && show" class="fa-solid fa-xmark mr-2" @click="selected = {};"></i>
          <i v-if="!show" class="fa-solid fa-chevron-down cursor-pointer"></i>
          <i v-if="show" class="fa-solid fa-chevron-up cursor-pointer"></i>
        </div>
      </div>
      <div v-if="show" class="component__select-options" v-click-outside="close">
        <div
            v-for="(option,key) in $store.state.warehouse.warehouses"
            class="select--option"
            :class="{ 'active': option.name === selected.name, 'border-top-first': key===0}"
            @click="selected = option;
            setId(selected.id)
          ">
          <div>{{ option.name }}</div>
          <i class="fa-solid fa-check" v-if="selected.name === option.name"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vClickOutside from "v-click-outside";

export default {
  name: 'ExportWarehouseSelect',
  data() {
    return {
      selected:{},
      show: false
    };
  },
  watch:{
    '$store.state.warehouse.warehouses': function () {
      if(this.$store.state.warehouse.warehouses.length>0){
        this.selected = this.$store.state.warehouse.warehouses[0]
        this.setId(this.selected.id)
      }
    }
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  methods: {
    setId(id){
      this.$emit('select',id)
    },
    close(){
      this.show = false;
    }
  }
};
</script>

<style scoped>
.component__select {
  height: 40px;
  line-height: 38px;
  width: 100%;
  grid-template-columns: 10fr 1fr;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  background-color: #2C2C2E;
  border: 1px solid #2C2C2E;
  color: #f4f4f4;
  padding: 1.5rem 1rem;
}

.component__select--name {
  font-size: 13px;
  cursor: pointer;
}

.component__select-options {
  position: absolute;
  z-index: 1501;
  width: 100%;
  margin: 0;
  padding: 0;
  max-width: 260px;
  background-color: #2C2C2E;
}

.select--option {
  height: 38px;
  display: flex;
  font-size: 13px;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  background-color: #2C2C2E;
  cursor: pointer;
}

.select--option:hover, .select--option.active {
  background: #06A5D01F;
  color: #06a5d0;
}

.select--option:last-child {
  border-bottom: none;
  border-radius: 0 0 5px 5px;
}

.select--option input {
  display: none;
}

.component__select i {
  font-size: 12px;
  cursor: pointer;
}
.border-top-first{
  border-top: 1px solid #404040;
}
.closed-border{
  border-radius: 5px;
}

.opened-border{
  border-radius: 5px 5px 0 0;
}
</style>
