<template>
  <div class="row">
    <div class="col-sm-12 col-md-12 col-lg-9">
      <div class="product-detail-left-wrapper">
        <div class="add-invoice-inputs">
          <p
            class="page-title"
            style="margin-bottom: 20px; font-size: 16px">
            {{ $t('Customer') }}
          </p>
          <div
            class="products-double-flex"
            style="padding: 0"
            v-if="selected_customer !== 1 && selected_customer !== 2">
            <div
              class="d-flex align-items-center"
              style="gap: 10px">
              <div
                class="choose-customer-container"
                v-click-outside="(event) => (customer.box = false)">
                <div
                  class="choose-product"
                  v-if="Object.keys(customer.info).length > 0"
                  :class="[
                    { 'shadow-sm': customer.box },
                    { 'customer-div': Object.keys(customer.info).length > 0 }
                  ]">
                  <div
                    class="choose-product-label"
                    v-if="Object.keys(customer.info).length > 0">
                    <div class="customer-info">
                      <div class="customer-name">
                        <div class="customer-name-name">
                          <i
                            class="fa-regular fa-user"
                            style="color: #7367f0"></i>
                          <strong
                            >{{ customer.info.name }}
                            {{ customer.info.surname }}</strong
                          >
                        </div>
                        <div class="customer-name-info">
                          <span
                            ><i class="fa-regular fa-envelope"></i>
                            {{ customer.info.email ?? $t('Not defined') }}</span
                          >
                          <span
                            ><i class="fa-regular fa-phone"></i>
                            {{
                              customer.info.phones.phone ?? $t('Not defined')
                            }}</span
                          >
                        </div>
                      </div>
                      <div
                        class="customer-name"
                        v-if="Object.keys(customer.car).length > 0"
                        style="
                          border-left: 1px solid #dee2e6;
                          margin-left: 25px;
                          padding-left: 35px;
                        ">
                        <div class="customer-name-name">
                          <i
                            class="fa-regular fa-car"
                            style="color: #7367f0"></i>
                          <strong>{{ customer.car.plate_number }}</strong>
                        </div>
                        <div class="customer-name-info">
                          <span
                            >{{ customer.car.brand }} {{ customer.car.model }}
                            {{ customer.car.year }}</span
                          >
                          <span
                            >{{ customer.car.version }}
                            {{ customer.car.version_type }}</span
                          >
                        </div>
                      </div>
                      <i
                        class="fa fa-times ml-3"
                        @click="removeCustomer"></i>
                    </div>
                  </div>
                  <div
                    v-else
                    class="icon-with-name">
                    <span class="choose-product-label">{{$t('Choose')}}</span>
                    <i
                      class="fa-solid fa-circle-exclamation text-danger"
                      v-if="customer.errorMessage"></i>
                  </div>
                </div>
                <div
                  class="choose-product"
                  v-else
                  @click="openCustomerBox"
                  :class="[
                    { 'shadow-sm': customer.box },
                    { 'customer-div': Object.keys(customer.info).length > 0 }
                  ]">
                  <span
                    class="choose-product-label"
                    v-if="Object.keys(customer.info).length > 0">
                    <div class="customer-name">
                      <div class="customer-name-name">
                        <i
                          class="fa-regular fa-user"
                          style="color: #7367f0"></i>
                        <strong
                          >{{ customer.info.name }}
                          {{ customer.info.surname }}</strong
                        >
                      </div>
                      <div class="customer-name-info">
                        <span
                          ><i class="fa-regular fa-envelope"></i>
                          {{ customer.info.email ?? $t('Not defined') }}</span
                        >
                        <span
                          ><i class="fa-regular fa-phone"></i>
                          {{
                            customer.info.phones.phone ?? $t('Not defined')
                          }}</span
                        >
                      </div>
                    </div>
                  </span>
                  <div
                    v-else
                    class="icon-with-name">
                    <span class="choose-product-label">$t('Choose')</span>
                    <i
                      class="fa-solid fa-circle-exclamation text-danger"
                      v-if="customer.errorMessage"></i>
                  </div>
                </div>
                <div
                  class="choose-product-list"
                  :class="customer.box ? 'shadow-sm' : ''"
                  v-show="customer.box">
                  <div class="choose-product-input-area">
                    <input
                      type="text"
                      v-model="customer.searchArea"
                      :id="`choose-customer-input`"
                      :placeholder="$t('Search Customer')"
                      class="form-control m-3 choose-product-input"
                      @keyup="searchInputCustomer" />
                    <b-spinner
                      label="Spinning"
                      small
                      class="input-spinner"
                      v-if="customer.loading"></b-spinner>
                  </div>
                  <div
                    class="choose-product-list-item"
                    v-for="cust in customer.searchResult"
                    :key="cust.$index"
                    @click="setCustomerSearch(cust)">
                    <strong class="choose-product-list-item-name"
                      >{{ cust.name }} {{ cust.surname }}
                      <small style="font-size: 10px">{{
                        cust.customerType.name
                      }}</small></strong
                    >
                    <div class="choose-product-list-item-info">
                      <small style="font-size: 11px"
                        ><strong>{{ $t('E-Mail') }}:</strong> {{ cust.email }}
                      </small>
                      <small style="font-size: 11px"
                        ><strong>{{ $t('Phone') }}:</strong>
                        {{ cust.phones.phone }}
                      </small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="info">
            <p
              class="page-title"
              style="margin-bottom: 20px; font-size: 16px">
              {{ $t('Sharing') }}
            </p>
            <div
              class="form"
              style="width: 100%">
              <div class="row">
                <div class="col-sm-6">
                  <small style="font-size: 13px; color: #777777">{{
                    $t('Shared with E-Mail')
                  }}</small>
                  <div
                    class="mt-2"
                    style="display: flex; flex-direction: column; gap: 10px">
                    <div
                      v-for="(email, key) in emails"
                      :key="email.$index"
                      class="d-flex"
                      style="gap: 10px; align-items: center">
                      <input
                        type="text"
                        v-model="email.email"
                        :placeholder="$t('E-Mail')"
                        class="form-control"
                        style="width: 70%" />
                      <i
                        class="fa-solid fa-trash"
                        v-if="key > 0"
                        style="color: #777777; cursor: pointer"
                        @click="deleteEmail(key)"></i>
                    </div>
                  </div>
                  <div
                    class="add-share"
                    @click="addEmail">
                    <span
                      ><i class="fa fa-plus mr-2"></i>
                      {{ $t('Add E-Mail') }}</span
                    >
                  </div>
                </div>
                <div class="col-sm-6">
                  <small style="font-size: 13px; color: #777777">{{
                    $t('Shared with Phone')
                  }}</small>
                  <div
                    class="mt-2"
                    style="display: flex; flex-direction: column; gap: 10px">
                    <div
                      v-for="(phone, key) in phones"
                      :key="phone.$index"
                      class="d-flex"
                      style="gap: 10px; align-items: center">
                      <input
                        type="text"
                        v-mask="'+90 (###) ### ## ##'"
                        v-model="phone.phone"
                        :placeholder="$t('Phone')"
                        class="form-control"
                        style="width: 70%" />
                      <i
                        class="fa-solid fa-trash"
                        v-if="key > 0"
                        style="color: #777777; cursor: pointer"
                        @click="deletePhone(key)"></i>
                    </div>
                  </div>
                  <div
                    class="add-share"
                    @click="addPhone">
                    <span
                      ><i class="fa fa-plus mr-2"></i
                      >{{ $t('Add Phone') }}</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="conti">
            <b-button
              class="add-more mt-5"
              @click="conti">
              {{ $t('Continue') }}
            </b-button>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-12 col-md-12 col-lg-3">
      <div class="product-detail-right-wrapper">
        <p
          class="page-title"
          style="margin-bottom: 20px">
          {{ $t('Offer') }}
        </p>
        <div class="offer-lines">
          <div class="offer-line">
            <span class="offer-line-title">{{ $t('Products') }}:</span>
            <span>₺{{ totalProductPrice }}</span>
          </div>
          <div class="offer-line">
            <span class="offer-line-title">{{ $t('Services') }}:</span>
            <span>₺{{ totalServicePrice }}</span>
          </div>
          <div class="offer-line">
            <span class="offer-line-title">{{ $t('Total') }}:</span>
            <span>₺{{ totalPrice }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vClickOutside from 'v-click-outside';
export default {
  name: 'CustomerStep',
  directives: {
    clickOutside: vClickOutside.directive
  },
  data() {
    return {
      selected_customer: null,
      contact: 'email'
    };
  },
  computed: {
    emails() {
      return this.$store.state.offers.addUpdateItem.emails;
    },
    customer() {
      return this.$store.state.offers.addUpdateItem.customer;
    },
    phones() {
      return this.$store.state.offers.addUpdateItem.phones;
    },
    step() {
      this.$store.state.offers.addUpdateItem.step;
    },
    order_items() {
      return this.$store.state.offers.addUpdateItem.order_items;
    },
    order_discounts() {
      return this.$store.state.offers.addUpdateItem.order_discounts;
    },
    totalProductPrice() {
      var total = 0;
      var _this = this;
      if (this.order_items.length > 0) {
        this.order_items.forEach(function (item, key) {
          total += _this.getTotalWithDouble(key);
          if (item.discounts.length > 0) {
            item.discounts.forEach(function (discount, discountkey) {
              if (discount.type == 'amount') {
                var amount = _this.getTotalDiscountWithDouble(discount.amount);
                total -= amount;
              } else if (discount.type == 'rate') {
                var rate = discount.rate;
                var product = _this.getTotalWithDouble(key);
                total -= product * (rate / 100);
              }
            });
          }
        });
      }
      var options = {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      };
      return total.toLocaleString('tr-TR', options);
    },
    totalServicePrice() {
      var total = 0;
      var _this = this;
      if (this.order_items.length > 0) {
        this.order_items.forEach(function (item, key) {
          if (item.services.length > 0) {
            item.services.forEach(function (item, servicekey) {
              total += _this.getTotalServiceWithDouble(key, servicekey);
            });
          }
        });
      }
      var options = {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      };
      return total.toLocaleString('tr-TR', options);
    },
    totalPrice() {
      var total = 0;
      var _this = this;
      if (this.order_items.length > 0) {
        this.order_items.forEach(function (item, key) {
          total += _this.getTotalWithDouble(key);
          if (item.services.length > 0) {
            item.services.forEach(function (item, servicekey) {
              total += _this.getTotalServiceWithDouble(key, servicekey);
            });
          }
          if (item.discounts.length > 0) {
            item.discounts.forEach(function (discount, discountkey) {
              if (discount.type == 'amount') {
                var amount = _this.getTotalDiscountWithDouble(discount.amount);
                total -= amount;
              } else if (discount.type == 'rate') {
                var rate = discount.rate;
                var product = _this.getTotalWithDouble(key);
                total -= product * (rate / 100);
              }
            });
          }
        });
      }
      if (this.order_discounts.length > 0) {
        this.order_discounts.forEach(function (discount, key) {
          if (discount.type == 'amount') {
            var amount = _this.getTotalDiscountWithDouble(discount.amount);
            total -= amount;
          } else if (discount.type == 'rate') {
            var rate = discount.rate;
            var product = _this.getTotalWithDouble(key);
            total -= product * (rate / 100);
          }
        });
      }
      var options = {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      };
      if (this.total_cargo) {
        console.log(total);
        var totalcargo = this.total_cargo.substring(1, this.total_cargo.length);
        var totalcargo = totalcargo.replace('.', '');
        totalcargo = totalcargo.replace(',', '.');
        total += parseFloat(totalcargo);
      }
      return total.toLocaleString('tr-TR', options);
    }
  },
  methods: {
    deleteEmail(key) {
      this.emails.splice(key, 1);
    },
    deletePhone(key) {
      this.phones.splice(key, 1);
    },
    addEmail() {
      this.emails.push({
        email: ''
      });
    },
    addPhone() {
      this.phones.push({
        phone: ''
      });
    },
    openCustomerBox() {
      this.customer.box = !this.customer.box;
    },
    hideCustomerModal() {
      this.$bvModal.hide('customer-add-modal');
    },
    searchInputCustomer() {
      if (this.customer.searchArea === 0) {
        this.customer.searchResult = [];
      }
      if (this.customer.searchArea.length > 2) {
        this.customer.loading = true;
        this.$store
          .dispatch(`sales/searchCustomer`, this.customer.searchArea)
          .then((response) => {
            this.customer.searchResult = response;
            this.customer.loading = false;
          });
      }
    },
    setCustomerSearch(customer) {
      this.$store
        .dispatch('sales/searchCustomerCar', customer.id)
        .then((response) => {
          if (response) {
            this.customer.car = response;
          }
          this.customer.info = customer;
          this.customer.box = false;
        });
    },
    removeCustomer() {
      this.$store.commit('offers/setCustomer', {
        box: false,
        searchArea: '',
        searchResult: [],
        car: {},
        info: {}
      });
    },
    getTotalWithDouble(key) {
      var price = this.order_items[key].total.replace('.', '');
      price = parseFloat(price.replace(',', '.')).toFixed(2);
      return parseFloat(price);
    },
    getTotalDiscountWithDouble(amount) {
      var price = amount.replace('.', '');
      price = parseFloat(price.replace(',', '.')).toFixed(2);
      return price;
    },
    getTotalServiceWithDouble(key, servicekey) {
      if (this.order_items[key].services[servicekey].total) {
        var price = this.order_items[key].services[servicekey].total.replace(
          '.',
          ''
        );
        price = parseFloat(price.replace(',', '.')).toFixed(2);
        return parseFloat(price);
      }
    },
    conti() {
      this.$store.commit('offers/setStep', 3);
    }
  }
};
</script>

<style scoped>
.product-line-services {
  display: flex;
  gap: 15px;
  align-items: center;
  width: 100%;
  margin-top: 17px;
}

.choose-product-container {
  position: relative;
  width: 100% !important;
}

.choose-customer-container {
  position: relative;
  max-width: 500px;
  min-width: 500px;
}

.choose-product {
  border: 1px solid #dee2e6;
  padding: 0.65rem 0.85rem !important;
  border-radius: 5px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 8px;
  background-color: #f4f4f4;
  cursor: pointer;
}

.choose-product-label {
  font-size: 14px;
  color: #6e6b7b !important;
  font-style: normal;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.icon-with-name {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.choose-product-list {
  position: absolute;
  top: 40px;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  border: 1px solid #dee2e6;
  background-color: #f4f4f4;
  border-top: none;
  z-index: 5;
  border-radius: 5px;
}

.choose-product-list-item {
  color: #6e6b7b !important;
  border-top: 1px solid #dee2e6;
  padding: 0.65rem 0.85rem;
  font-size: 14px;
  cursor: pointer;
}

.choose-product-list-item:hover {
  background-color: #dadada;
  cursor: pointer;
}

.choose-product-input {
  width: 92%;
}

.input-group-text {
  background-color: #f4f4f4;
  border-left: 1px solid #dee2e6 !important;
}

.choose-product-input-area {
  position: relative;
}

.input-spinner {
  position: absolute;
  right: 26px;
  top: 27px;
}

#services-lines:first-child {
  border-top: 1px solid #dee2e6;
  margin-bottom: 20px;
}

#services-lines:last-child {
  border-bottom: 1px solid #dee2e6;
  margin-bottom: 20px;
}
.isDesc {
  border-bottom: 1px solid #dee2e6 !important;
  width: 100%;
  max-width: 100% !important;
  margin-bottom: 20px !important;
}

.choose-product-list-item-info {
  display: flex;
  flex-direction: column;
  border-top: 1px solid #dee2e6;
  padding-top: 5px;
  margin-top: 5px;
}

.choose-product-list-item-name {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.customer-name {
  display: flex;
  gap: 5px;
  flex-direction: column;
}

.customer-div {
  height: auto;
  width: auto !important;
}

.customer-name-name {
  font-size: 20px;
}

.customer-name-info {
  font-size: 12px;
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.customer-info {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  position: relative;
}

.customer-div .choose-product-label {
  width: 1000%;
}

.discount-line {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.add-invoice-flex-total-wrapper {
  flex: 1;
}

.bill-info {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex-direction: column;
}

.bill-info-line {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px dashed #dee2e6;
  padding-bottom: 10px;
  padding-top: 10px;
  width: 650px;
  gap: 20px;
}

.addDiscount {
  border: none;
  background-color: rgba(119, 119, 119, 0.21);
  border-radius: 5px;
  color: #777777;
  margin: 5px;
}

.addDiscount:hover {
  background-color: black;
  color: #dee2e6;
}

.info {
  margin-top: 50px;
}
</style>

<style>
.form .custom-control-label {
  font-size: 14px !important;
  color: #777777 !important;
}

.custom-control {
  display: flex;
  align-items: center;
}

.add-share {
  color: #777777;
  background-color: #eaeaea;
  padding: 5px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  margin-top: 20px;
  cursor: pointer;
  font-size: 14px;
  border-radius: 5px;
}
.offer-lines {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.offer-line {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
}

.offer-line-title {
  color: #777777;
}
.conti {
  display: flex;
  justify-content: flex-end;
}

.add-more {
  background: var(--servislet-black) !important;
  border-radius: 5px;
  border: 1px solid var(--servislet-black) !important;
  font-style: normal;
  font-weight: 500;
  font-size: 11px !important;
  text-align: center;
  letter-spacing: 0.366667px;
  color: #ffffff;
  outline: none;
  padding: 8px 14px;
  transition: 0.5s;
}

.add-more:hover {
  box-shadow: rgba(28, 28, 28, 0.2) 0px 8px 30px 0px;
  background: var(--servislet-black);
  border: 1px solid var(--servislet-black);
  color: #ffffff;
  outline: none;
  transition: 0.5s;
}

.add-more i {
  padding-right: 6pxs;
}
</style>
