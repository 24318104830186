import Vue from 'vue';
import Vuex from 'vuex';
import axios from '@/plugins/axios';

Vue.use(Vuex);

const state = {
  types: []
};

const mutations = {
  setCarTypes(state, data) {
    state.types = data;
  }
};

const actions = {
  fetchCarTypes({ commit }) {
    axios
      .get(`/car-types`)
      .then((response) => {
        commit('setCarTypes', response.data.responseData.carTypes);
      })
      .catch((error) => {
        console.error(error);
      });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
