<template>
  <div class="px-3">
    <div class="select-area" @click="show = !show;">
      <span class="select-area-text" v-if="Object.keys(selected_warehouse).length>0">{{selected_warehouse.name}}</span>
      <span class="select-area-text" v-else>{{ $t('Choose') }}</span>
      <i class="fa-solid" :class="show ? 'fa-chevron-up' : 'fa-chevron-down'"></i>
    </div>
    <div class="srvslt-select selected" v-click-outside="close" v-if="show">
      <div class="select-list">
        <div class="search-input">
          <div class="select-control">
            <input
                type="text"
                class="select-input"
                v-model="search"
                :placeholder="$t('Search')"/>
            <i class="fa-solid fa-magnifying-glass" style="color: #6d6e6f"></i>
          </div>
        </div>
        <div class="select-div">
          <div class="list-content font-gray" v-for="item in warehouses" :class="item === selected_warehouse ? 'checked-option' : ''" :key="item.$index" @click="setItem(item)">
            <span class="overflow-text">{{ item.name }}</span>
            <i class="fa-solid fa-check" v-if="selected_warehouse === item"></i>
          </div>
          <div
              class="list-content no-hover"
              v-if="warehouses.length === 0">
            <span>{{$t('No result.')}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vClickOutside from 'v-click-outside';
import axios from "@/plugins/axios";

export default {
  name: 'WarehouseSelect',
  directives: {
    clickOutside: vClickOutside.directive
  },
  data() {
    return {
      show: false,
      search: '',
      selected_warehouse: {}
    };
  },
  props:{
    warehouse_id:{
      type: Number,
      default: 0
    }
  },
  computed: {
    warehouses() {
      return this.search
          ? this.$store.state.warehouse.warehouses.filter((item) =>
              item.name.toString().toLocaleUpperCase().includes(this.search.toLocaleUpperCase())
          )
          : this.$store.state.warehouse.warehouses;
    },
  },
  watch:{

  },
  mounted() {
    axios.get(`/warehouses`, {
      params: {
        is_active: 1
      }
    }).then((response) => {
      this.$store.commit('warehouse/setWarehouses',response.data.responseData.warehouses)
      if(response.data.responseData.warehouses.length>0){
        if(localStorage.getItem('warehouse_id')){
          response.data.responseData.warehouses.forEach(item=>{
            if(item.id === parseInt(localStorage.getItem('warehouse_id'))){
              this.setItem(item)
              localStorage.setItem('warehouse_id',item.id)
            }
          })
        }else{
          this.setItem(response.data.responseData.warehouses[0])
          localStorage.setItem('warehouse_id',response.data.responseData.warehouses[0].id)
        }
      }
    })
  },
  methods: {
    close() {
      this.show = false;
      this.search = '';
    },
    setItem(item){
      this.selected_warehouse = item;
      localStorage.setItem('warehouse_id',item.id)
      this.$emit('setWarehouseId',item.id)
      this.close()
    }
  }
};
</script>

<style scoped>
.select-area {
  width: 195px;
  border: 1px solid #6D6E6F80;
  border-radius: 5px;
  padding: 10px;
  color: #777;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.srvslt-select {
  position: relative;
  width: 195px;
  z-index: 2;
  margin-top: 6px;
}


.select-list {
  position: absolute;
  cursor: pointer;
  width: 100%;
  z-index: 3;
  max-height: 400px;
  border-radius: 5px;
  background: #f6f6f6;
  box-shadow: -2px 2px 10px 0 rgba(0, 0, 0, 0.2);
}

.select-list::-webkit-scrollbar {
  display: none !important;
}

.list-content {
  display: flex;
  align-items: center;
  padding: 10px;
  gap: 10px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  justify-content: space-between;
}

.select-control {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(170, 170, 170, 0.8);
  margin: 0 10px 10px 10px;
  height: 40px;
}

.select-input {
  width: 100%;
  height: 35px;
  border: none;
  color: #777;
  font-size: 14px;
  border-radius: 0;
  background: #f4f4f4;
}

@media only screen and (max-width: 575px) {
  .srvslt-select {
    max-width: 100%;
  }
}

.select-div {
  height: fit-content;
}


.search-input {
  font-size: 14px;
}

.select-input:focus,
.select-input-inner:focus {
  border: none !important;
}

.select-input::placeholder {
  color: rgba(119, 119, 119, 0.8) !important;
  font-size: 14px;
}

.overflow-text {
  overflow-x: scroll;
  white-space: nowrap;
}

.overflow-text::-webkit-scrollbar {
  display: none;
}

.no-hover:hover {
  background-color: #f4f4f4;
  color: #212529;
}
.checked-option{
  background-color: #06A5D01F;
  color: #06a5d0;
}
</style>
