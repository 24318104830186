<template>
  <div id="periodic-maintenance-product-page-wrapper">
    <PageBreadcrumbs :breadcrumbs="breadcrumbs" />

    <div
      id="products-listing-table-wrapper"
      class="py-4">
      <p class="page-title">Araç Periyodik Bakım Detayları</p>
      <div class="flex-center-start mt-3">
        <div class="product-listing-img-wrapper">
          <div class="car-listing-img-wrapper">
            <img
              src="../../assets/images/car-brand-ex.png"
              alt="" />
          </div>
        </div>
        <div class="periodic-maintenance-product-detail-head">
          <p>LAMBORGHINI</p>
          <p>2022 Gallardo LP550-2 Valentino Balboni</p>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-lg-6">
          <div class="periodic-maintenance-product-detail-table-wrapper">
            <table>
              <tr>
                <th class="br-right-0">KM</th>
                <th class="br-0">TUTAR</th>
                <th class="text-right br-left-0">
                  <i class="fa-solid fa-angle-left"></i>
                  <i class="fa-solid fa-angle-right"></i>
                </th>
              </tr>
              <tr
                v-for="item in 6"
                :key="item.$index">
                <td>20.000 KM</td>
                <td>₺1.650,50</td>
                <td>
                  <div class="flex-center-end">
                    <button class="pm-edit-btn m-0">
                      <i class="fa-solid fa-pen"></i>
                    </button>
                    <button class="pm-show-btn">
                      <i class="fa-solid fa-eye"></i>
                    </button>
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="periodic-maintenance-product-detail-content-wrapper">
            <p class="page-title">20.000 KM Bakım İçeriği</p>
            <ul>
              <li
                v-for="item in 4"
                :key="item.$index">
                - Total/Elf 5W30 Motor Yağı
              </li>
            </ul>
            <i class="fa-solid fa-pen"></i>
            <div class="flex-center-space">
              <div></div>
              <div class="flex-center-space">
                <b-button>Atla</b-button>
                <b-button>Kaydet</b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import PageBreadcrumbs from '../../components/PageBreadcrumbs.vue';
export default {
  components: {
    PageBreadcrumbs
  },
  data() {
    return {
      breadcrumbs: [
        {
          name: 'Ürünler',
          path: '/#'
        },
        {
          name: 'Servis / Periyodik Bakım',
          path: '/#'
        },
        {
          name: '2022 Gallardo LP550-2 Valentino Balboni'
        }
      ]
    };
  }
};
</script>

<style scoped>
#products-listing-table-wrapper {
  padding: 1.5rem 16px;
}
</style>
