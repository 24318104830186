<template>
  <DefaultLayout>
    <div>
      <PageBreadcrumbs :breadcrumbs="breadcrumbs" />

      <div v-if="!loading">
       <div class="row printArea">
         <div class="col-sm-12 col-md-9 col-lg-9">
           <div id="products-listing-table-wrapper" class="main-card">
             <div class="order-id-text">{{$t('Sale')}} ID: <span>#{{payment.id}}</span></div>
             <div class="white-card">
               <span class="table-title">{{$t('Payment Amount')}}</span>
               <table class="table">
                 <thead>
                 <tr>
                   <th scope="col">ID</th>
                   <th scope="col" class="text-center">{{$t('Amount Payable')}}</th>
                   <th scope="col" class="text-center">{{$t('Commission')}}</th>
                   <th scope="col" class="text-end">{{$t('Total')}}</th>
                 </tr>
                 </thead>
                 <tbody>
                   <tr>
                     <td>{{payment.id}}</td>
                     <td class="text-center">{{currenctFormat('tr-TR',payment.transfer)}}</td>
                     <td class="text-center">{{currenctFormat('tr-TR',payment.commission)}}</td>
                     <td class="text-end">{{currenctFormat('tr-TR',payment.amount)}}</td>
                   </tr>
                 </tbody>
               </table>
             </div>
             <div class="white-card">
               <span class="table-title">{{$t('Order')}}</span>
               <table class="table">
                 <thead>
                 <tr>
                   <th scope="col">{{$t('Product')}} / {{$t('Service')}}</th>
                   <th scope="col" class="text-center">{{$t('Count')}}</th>
                   <th scope="col" class="text-center">{{$t('Amount')}}</th>
                   <th scope="col" class="text-end">{{$t('Total')}}</th>
                 </tr>
                 </thead>
                 <tbody>
                 <tr v-for="product in order.items" :key="product.$index">
                   <td>{{product.name}}</td>
                   <td class="text-center">{{product.quantity}}</td>
                   <td class="text-center">{{currenctFormat('tr-TR',product.price)}}</td>
                   <td class="text-end">{{currenctFormat('tr-TR',product.amount)}}</td>
                 </tr>
                 </tbody>
               </table>

               <div class="d-flex justify-content-end">
                 <div class="price-area mt-4">
                   <div class="space-between">
                     <span class="key">{{$t('Sub Total')}}:</span>
                     <span class="value">{{currenctFormat('tr-TR', order.total_price)}}</span>
                   </div>

                   <div class="space-between">
                     <span class="key">{{$t('VAT')}}:</span>
                     <span class="value">{{currenctFormat('tr-TR',order.total_tax)}}</span>
                   </div>

                   <hr class="m-0">

                   <div class="space-between">
                     <span class="general-key">{{$t('General Total')}}:</span>
                     <span class="general-value">{{$t(currenctFormat('tr-TR',order.total_amount))}}</span>
                   </div>
                 </div>
               </div>
             </div>
             <div class="white-card" v-if="payment.order.appointments">
               <span class="table-title">{{$t('Appointment')}}</span>
               <div class="row">
                 <div class="col-sm-12 col-md-6 col-lg-6">
                   <div class="space-between table-key-value">
                     <span class="key">{{$t('Name Surname')}}</span>
                     <span class="key-value" v-if="payment.order.appointments.user">{{payment.order.appointments.user.name}}</span>
                   </div>
                   <div class="space-between table-key-value">
                     <span class="key">{{$t('Phone')}}</span>
                     <span class="key-value">-</span>
                   </div>

                 </div>

                 <div class="col-sm-12 col-md-6 col-lg-6">
                   <div class="space-between table-key-value">
                     <span class="key">{{$t('E-Mail')}}</span>
                     <span class="key-value" v-if="payment.order.appointments.user">{{payment.order.appointments.user.email}}</span>
                   </div>
                   <div class="space-between table-key-value">
                     <span class="key">{{$t('Date')}}</span>
                     <span class="key-value" v-if="payment.order.appointments">{{dateTimeFormat(payment.order.appointments.appointment_date)}}</span>
                   </div>

                 </div>
               </div>
             </div>
           </div>
         </div>
         <div class="col-sm-12 col-md-3 col-lg-3">
           <div class="neu main-card gap-3">
             <div>
               <span class="input-label">{{$t('Sale Channel')}}</span>
               <b-form-input v-model="channel" disabled />
             </div>
             <div>
               <span class="input-label">{{$t('Payment Date')}}</span>
               <b-form-input v-model="payment_date" disabled />
             </div>
             <div>
               <span class="input-label">{{$t('Payment Status')}}</span>
               <div class="form-control text-nowrap" :class="{'success-badge' : payment.status, 'danger-badge': !payment.status}">{{payment.status ? $t('Completed') : $t('Payment Waiting')}}</div>
             </div>
             <div class="form-control print-btn" @click="download()">{{$t('Print')}}</div>
           </div>
         </div>
       </div>
      </div>
      <div v-else class="loader px-3 mt-3">
        <content-placeholders :rounded="true">
          <content-placeholders-img />
          <content-placeholders-text :lines="10" />
        </content-placeholders>
      </div>
    </div>
  </DefaultLayout>
</template>

<script>
/* eslint-disable */
import PageBreadcrumbs from '../../components/PageBreadcrumbs.vue';
import DefaultLayout from "@/layouts/default.vue";
import axios from "@/plugins/axios";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
export default {
  components: {
    PageBreadcrumbs,
    DefaultLayout
  },
  data() {
    return {
      breadcrumbs: [
        {
          name: this.$i18n.t('Payments'),
          path: '/payments'
        },
        {
          name: this.$i18n.t('Payment Detail'),
          path: '/#'
        }
      ],
      loading:true,
      payment:{},
      order:{},
      channel: 'Servislet',
      payment_date: '',
      payment_status: false
    };
  },
  mounted(){
    this.getDetail()
  },
  computed:{},
  methods: {
    getDetail(){
      this.loading = true
      axios.get(`/payment-transfers/${this.$route.params.id}`).then(response=>{
        if(response.data.status){
          this.payment = response.data.responseData.paymentTransfer
          this.payment_date = response.data.responseData.paymentTransfer.expectedDate ? window.helper.dateTimeFormat(response.data.responseData.paymentTransfer.expectedDate) : ''
          axios.get(`/tire/orders/channel/${this.$route.params.id}`).then(res=>{
            this.order = res.data.responseData.order
            this.loading = false
          })
        }
      }).catch(error=>{
        console.log(error)
      })
    },
    download(){
      const divToPrint = document.querySelector('.printArea');
      html2canvas(divToPrint, {
        useCORS: true,
        logging: true,
        scale: 2,
        backgroundColor: '#fff',
        width: divToPrint.offsetWidth
      }).then(canvas => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('l', 'px', [divToPrint.offsetWidth, divToPrint.offsetHeight]);
        pdf.addImage(imgData, 'PNG', 0, 0, divToPrint.offsetWidth, divToPrint.offsetHeight);
        const pdfBlob = pdf.output('blob');
        const link = document.createElement('a');
        link.href = URL.createObjectURL(pdfBlob);
        link.download = `${this.payment.id}` + ' ' +this.$i18n.t('Payment Detail')+'.pdf';
        link.click();
      });
    }
  }
};
</script>

<style scoped>
.main-card{
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.order-id-text{
  color: #5E5873;
  font-size: 16px;
  font-weight: 400;
}
.order-id-text span{
  font-size: 18px;
  font-weight: 600;
}
.white-card{
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
}
.table-title{
  color: #1C1C1C;
  font-size: 16px;
  font-weight: 600;
  line-height: 21px;

}
.table thead th{
  border: none;
}
.space-between .key{
  color: #777;
  font-size: 12px;
  font-weight: 400;
  line-height: 15.06px;
  text-align: left;
}
.space-between .key-value{
  font-size: 12px;
  font-weight: 500;
  line-height: 15.06px;
  text-align: right;
}
.table-key-value{
  padding: 12px 0;
  border-bottom: 1px solid #D9D9D980;
}
.white-card table thead th{
  color: #777;
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
}
.white-card table tbody td{
  color: #1C1C1C;
  font-size: 12px;
  font-weight: 500;
  line-height: 15.06px;
}
.price-area{
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 35%;
}
.price-area .key{
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #18020C;
}
.price-area .value{
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: right;
  color: #18020C;
}
.general-key{
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
  color: #18020C;
}
.general-value{
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  text-align: right;
  color: #18020C;
}
.input-label{
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.63px;
  text-align: left;
  color: #5E5873;
}
.success-badge{
  background-color: #06A5D01F;
  border: 1px solid #06a5d0;
  color: #06a5d0 !important;
}
.danger-badge{
  background-color: #EA54551F;
  color: #EA5455 !important;
  border: 1px solid #EA5455;
}
.print-btn{
  background-color: #1C1C1C !important;
  color: #f4f4f4 !important;
  cursor: pointer;
  padding-top: 13.6px !important;
  padding-bottom: 13.6px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
