<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
/* eslint-disable */
export default {
  name: 'App',
  data() {
    return {
      access_token: '',
      is_valid: false
    };
  },
  watch: {
    $route(to, from) {
      localStorage.setItem('currentPath', to.path);
    }
  },
  mounted() {
    if (this.$route.name !== 'Register' && this.$route.name !== 'CustomerOffer' && this.$route.name !== 'Resetpassword') {
      if (localStorage.userData) {
        this.access_token = JSON.parse(localStorage.userData).access_token;
        this.checkAccessToken(this.access_token);
        if (!this.access_token) {
          this.$router.push('/login');
        }
      } else {
        if (this.$route.path !== '/login') {
          this.$router.push('/login');
        }
      }
    }
  },
  methods: {
    checkAccessToken(token) {
      var self = this;
      if (
          self.$route.name !== 'Register' &&
          self.$route.name !== 'Login' &&
          self.$route.name !== 'CustomerOffer' &&
          self.$route.name !== 'Resetpassword'
      ) {
        self.$axios
            .post('/auth/check', {
              token: token
            })
            .then(function (response) {
              self.is_valid = response.data.responseData.check;
              if (!self.is_valid) {
                self.$router.push('/login');
              }
            })
            .catch(function (error) {
              self.$router.push('/login');
            });
      }
    }
  }
};
</script>

<style>
@import './assets/css/style.css';
@import './assets/css/responsive.css';
</style>
