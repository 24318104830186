const text = 'rgba'+localStorage.getItem('color');
const active_text = 'rgba' + localStorage.getItem('account_text_color');
const bg = 'rgba'+localStorage.getItem('color');

const styles = `
  .pro-text {
    color: ${text} !important;
  }
  #top-bar {
    background: ${bg};
  }
  .right {
    color: ${active_text};
    border-left: 1px solid ${active_text === 'rgba(119,119,119,1)' ? '#d3d3d3' : active_text};
  }
  .right i span {
    background: ${active_text === 'rgba(119,119,119,1)' ? '#06a5d0' : active_text};
  }
`;
const styleElement = document.createElement('style');
styleElement.innerHTML = styles;
document.head.appendChild(styleElement);
