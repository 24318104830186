<template>
  <DefaultLayout>
    <div>
      <PageBreadcrumbs :breadcrumbs="breadcrumbs" />
      <div
        class="row"
        v-if="!loader">
        <div class="col-lg-9">
          <div class="product-detail-left-wrapper">
            <div class="products-double-flex">
              <div class="products-double-flex-left">
                <p class="page-title">{{ product.tireProduct.name }}</p>
              </div>
            </div>
            <b-tabs
              active-nav-item-class="product-detail-tabs-active"
              content-class="product-detail-tabs-content mt-2"
              class="product-detail-tabs mt-3">
              <b-tab
                :title="$t('Product Details')"
                active>
                <b-form>
                  <div class="product-detail-form-flex">
                    <div class="product-detail-form-flex-left">
                      <b-form-group
                        id="stock-group"
                        :label="$t('Stock') + ':'"
                        label-for="stock">
                        <b-form-input
                          id="stock"
                          v-model="form.stock"
                          type="number"
                          :placeholder="$t('Stock')"
                          required></b-form-input>
                      </b-form-group>

                      <b-form-group
                        id="sales-price-group"
                        :label="$t('Sale Price') + ':'"
                        label-for="sales-price">
                        <b-form-input
                          id="sales-price"
                          v-model="form.salesPrice"
                          type="text"
                          :placeholder="$t('Sale Price')"
                          required
                          v-money="money"></b-form-input>
                      </b-form-group>

                      <b-form-group
                        id="payment-group"
                        :label="$t('Payment') + ':'"
                        label-for="payment">
                        <b-form-input
                          id="payment"
                          v-model="form.paymemnt"
                          type="text"
                          :placeholder="$t('Payment')"
                          disabled></b-form-input>
                      </b-form-group>
                    </div>
                    <div class="product-detail-form-flex-right">
                      <b-form-group
                        id="dot-group"
                        label="DOT:"
                        label-for="dot">
                        <b-form-input
                          id="dot"
                          v-model="form.dot"
                          type="number"
                          placeholder="DOT"
                          required></b-form-input>
                      </b-form-group>

                      <b-form-group
                        id="sales-price-group"
                        :label="$t('Sale Price') + ':'"
                        label-for="sales-price"
                        class="visibility-hidden">
                        <b-form-input
                          id="sales-price"
                          type="text"
                          :placeholder="$t('Sale Price')"
                          disabled></b-form-input>
                      </b-form-group>

                      <b-form-group
                        id="commission-group"
                        :label="$t('Commission') + ':'"
                        label-for="commission">
                        <b-form-input
                          id="commission"
                          v-model="form.commission"
                          type="text"
                          :placeholder="$t('Commission')"
                          disabled></b-form-input>
                      </b-form-group>
                    </div>
                  </div>

                  <div
                    class="product-detail-form-flex"
                    id="product-info-detail">
                    <div class="product-detail-form-flex-left">
                      <table>
                        <tr>
                          <td>{{ $t('Brand') }}</td>
                          <td>{{ product.tireProduct.brand.name }}</td>
                        </tr>
                        <tr>
                          <td>{{ $t('Pattern') }}</td>
                          <td>{{ product.tireProduct.category.name }}</td>
                        </tr>
                        <tr>
                          <td>{{ $t('Feedback') }}</td>
                          <td><StarRating /></td>
                        </tr>
                        <tr>
                          <td>{{ $t('Season') }}</td>
                          <td>{{ '-' }}</td>
                        </tr>
                        <tr>
                          <td>{{ $t('Width') }}</td>
                          <td>{{ product.tireProduct.width }}</td>
                        </tr>
                        <tr>
                          <td>{{ $t('Height') }}</td>
                          <td>{{ product.tireProduct.height }}</td>
                        </tr>
                        <tr>
                          <td>{{ $t('Radius') }}</td>
                          <td>R{{ product.tireProduct.radius }}</td>
                        </tr>
                      </table>
                    </div>
                    <div class="product-detail-form-flex-right">
                      <table>
                        <tr>
                          <td>CAI</td>
                          <td>{{ product.tireProduct.sku }}</td>
                        </tr>
                        <tr>
                          <td>Run Flat</td>
                          <td>
                            <span v-if="product.tireProduct.run_flat">{{
                              $t('Yes')
                            }}</span>
                            <span v-else>{{ $t('No') }}</span>
                          </td>
                        </tr>
                        <tr>
                          <td>{{ $t('Load Index') }}</td>
                          <td>{{ product.tireProduct.load_index }}</td>
                        </tr>
                        <tr>
                          <td>{{ $t('Speed Symbol') }}</td>
                          <td>{{ product.tireProduct.speed_symbol }}</td>
                        </tr>
                        <tr>
                          <td>{{ $t('Fuel Efficiency') }}</td>
                          <td>{{ product.tireProduct.fuel_label }}</td>
                        </tr>
                        <tr>
                          <td>{{ $t('Noise Label') }}</td>
                          <td>{{ product.tireProduct.noise_label }}</td>
                        </tr>
                        <tr>
                          <td>{{ $t('Wet Grip') }}</td>
                          <td>{{ product.tireProduct.wet_grip_label }}</td>
                        </tr>
                        <tr>
                          <td>{{ $t('Guarantee') }}</td>
                          <td>-</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </b-form>
              </b-tab>
              <b-tab :title="$t('Product History')">
                <!-- <div class="product-detail-history-flex">
                                <div>
                                    <table>
                                        <tr>
                                            <td>Tarih</td>
                                            <td>Saat</td>
                                        </tr>
                                        <tr v-for="item in 5" :key="item">
                                            <td>18/07/2022</td>
                                            <td>19:02:40</td>
                                        </tr>
                                    </table>
                                </div>
                                <div>
                                    <table>
                                        <tr>
                                            <td>Sipariş Durumu</td>
                                        </tr>
                                        <tr v-for="item in 5" :key="item">
                                            <td>Satış fiyatı güncellendi</td>
                                        </tr>
                                    </table>
                                </div>
                            </div> -->
              </b-tab>
              <b-tab :title="$t('Performance')"></b-tab>
            </b-tabs>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="product-detail-right-wrapper">
            <!-- <b-form-group id="channel-group" label="Satış Kanalı:" label-for="channel">
                        <b-form-input
                        id="channel"
                        type="text"
                        placeholder="Satış Kanalı"
                        disabled
                        ></b-form-input>
                    </b-form-group> -->
            <b-form-group
              id="channel-group"
              :label="$t('Order Channel') + ':'">
              <b-form-select
                v-model="order_channel_id"
                :options="orderChannels"
                class="add-invoice-payment-c-selector"></b-form-select>
            </b-form-group>

            <div class="product-detail-right-checkboxes">
              <span>{{ $t('Open / Close Stock') }}</span>

              <b-form-checkbox
                id="product-detail-checkbox"
                value="true"
                unchecked-value="false"
                v-model="form.is_active"
                switch></b-form-checkbox>
            </div>

            <b-button @click="updateProduct()">{{ $t('Update') }}</b-button>
          </div>
        </div>
      </div>
      <div
        class="row"
        v-if="loader">
        <div
          class="loader"
          v-if="loader">
          <content-placeholders :rounded="true">
            <content-placeholders-img />
            <content-placeholders-text :lines="10" />
          </content-placeholders>
        </div>
      </div>
    </div>
  </DefaultLayout>
</template>

<script>
/* eslint-disable */
import DefaultLayout from '@/layouts/default';
import PageBreadcrumbs from '../../components/PageBreadcrumbs.vue';
import StarRating from '../../components/StarRating.vue';
export default {
  components: {
    PageBreadcrumbs,
    StarRating,
    DefaultLayout
  },
  data() {
    return {
      breadcrumbs: [
        {
          name: this.$i18n.t('Products'),
          path: '/products'
        },
        {
          name: this.$i18n.t('Tire'),
          path: '/products'
        },
        {
          name: ''
        }
      ],
      form: {
        stock: '',
        dot: '',
        salesPrice: '',
        paymemnt: '',
        commission: ''
      },
      product: {
        tireProduct: {
          brand: {},
          category: {}
        }
      },
      loader: false,
      orderChannels: [],
      order_channel_id: '',
      money: {
        decimal: ',',
        thousands: '.',
        prefix: '₺',
        suffix: '',
        precision: 2,
        masked: false
      }
    };
  },
  mounted() {
    this.getProduct();
    this.$store.dispatch('sales/getOrderChannel').then((response) => {
      this.orderChannels = response;
    });
  },
  methods: {
    parsePrice(price) {
      return parseFloat(price.replace(/\D/g, '').replace(',', '.'));
    },
    updateProduct() {
      this.loader = true;
      var price = this.form.salesPrice;
      var price_no_fraction = this.parsePrice(
        price.substring(1, price.length - 2)
      );
      var price_fraction = this.parsePrice(
        price.substring(price.length - 2, price.length)
      );
      this.$axios
        .put(`/tire/stocks/${this.$route.params.id}`, {
          price: price_no_fraction + price_fraction / 100,
          quantity: this.form.stock,
          is_active: this.form.is_active,
          year: this.form.dot,
          order_channel_id: this.order_channel_id
        })
        .then((response) => {
          if (response.status) {
            this.$swal.fire({
              iconHtml: '<img src="/img/success.svg" width="32" height="32">',
              position: 'top-end',
              title: this.$i18n.t('Product updated successfully') + '!',
              showConfirmButton: false,
              timer: 2500,
              toast: true,
              customClass: {
                icon: 'swal2-no-border'
              }
            });
          }
          this.getProduct();
        })
        .catch((error) => {
          this.$swal.fire({
            iconHtml: '<img src="/img/error.svg" width="32" height="32">',
            position: 'top-end',
            title: error.response.data.statusMessage,
            text: error.response.data.responseData.message,
            showConfirmButton: false,
            timer: 2500,
            toast: true,
            customClass: {
              icon: 'swal2-no-border'
            }
          });
          this.loader = false;
        });
    },
    getProduct() {
      this.loader = true;
      this.$axios
        .get(`/tire/stocks/${this.$route.params.id}`, {})
        .then((response) => {
          this.product = response.data.responseData;
          this.breadcrumbs[2].name = this.product.tireProduct.name;
          this.form.stock = this.product.quantity;
          this.form.dot = this.product.year;
          this.form.salesPrice = this.formatPrice(this.product.price);
          this.form.is_active = this.product.is_active;
          this.order_channel_id = this.product.order_channel_id;
          this.loader = false;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    formatPrice(price) {
      price = price * 1;
      return price.toLocaleString('tr-TR', {
        style: 'currency',
        currency: 'TRY'
      });
    }
  }
};
</script>

<style></style>
