export default {
  list(data) {
    if (data && data.length > 0) {
      data = data.map((item) => {
        item.update_price_17 = item.price_17;
        item.update_price_16 = item.price_16;
        item.update_price_15 = item.price_15;
        item.price_15 = window.helper.currenctFormat('tr-TR', item.price_15);
        item.price_16 = window.helper.currenctFormat('tr-TR', item.price_16);
        item.price_17 = window.helper.currenctFormat('tr-TR', item.price_17);

        return item;
      });
    }
    return data;
  }
};
