<template>
  <div id="login">
    <div class="container-fluid">
      <ValidationObserver ref="sendRules">
        <div class="row">
          <div class="col-sm-12 col-md-12 col-lg-6"
               id="left">
            <div class="forms-g-wrapper text-center" style="width: 50%;">
              <img
                  alt="Servislet Pro"
                  style="width: 330px;"
                  src="@/assets/images/logo.png"/>
              <div class="form-wrapper">
                <ValidationProvider rules="required|email" #default="{errors}" :name="$t('E-Mail')">
                  <div class="input-wrapper mt-4">
                    <b-input-group>
                      <template #prepend>
                        <i class="fa-solid fa-envelope"></i>
                      </template>
                      <b-form-input
                          :placeholder="$t('E-Mail')"
                          type="text"
                          v-model="email"
                          v-on:keyup.enter="send()"></b-form-input>
                    </b-input-group>
                  </div>
                </ValidationProvider>
                <button @click="send()">{{ $t('Send') }}</button>
              </div>
            </div>
          </div>
          <div
              class="col-lg-6"
              id="right">
            <ssr-carousel
                :show-dots="true"
                :show-arrows="false"
                :loop="false"
                :autoplay-delay="5"
                gutter="0"
                class="ssr-carousel login-ssr">
              <div class="slide step1">
                <div class="slide-step-item">
                  <div class="title">{{ sliders[0].title }}</div>
                  <div class="title-description">
                    {{ sliders[0].description }}
                  </div>
                </div>
              </div>
              <div class="slide step2">
                <div class="slide-step-item">
                  <div class="title">{{ sliders[1].title }}</div>
                  <div class="title-description">
                    {{ sliders[1].description }}
                  </div>
                </div>
              </div>
              <div class="slide step3">
                <div class="slide-step-item">
                  <div class="title">{{ sliders[2].title }}</div>
                  <div class="title-description">
                    {{ sliders[2].description }}
                  </div>
                </div>
              </div>
            </ssr-carousel>
          </div>
        </div>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import axiosInstance from '@/plugins/axios';
/* eslint-disable */
export default {
  name: 'ForgetPassword',
  data() {
    return {
      email: null,
      sliders: [
        {
          title: this.$i18n.t(
              'Add Your Customers and Their Vehicles to the System and Track Them Easily!'
          ),
          description: this.$i18n.t(
              "You can track customer information and your customers' vehicles by adding them to Servislet Pro. You can increase the efficiency of your business by entering user types, contact information and vehicle details into the system."
          )
        },
        {
          title: this.$i18n.t(
              'Manage Your Online and Offline Sales at the Same Time!'
          ),
          description: this.$i18n.t(
              'With marketplace integrations, you can easily manage all your online and offline sales from one place and review your reports.'
          )
        },
        {
          title: this.$i18n.t('Get Offers, Focus on Making Profitable Sales!'),
          description: this.$i18n.t(
              'Servislet Pro offers businesses the advantage of profit-oriented sales with the offer scenario it creates. Thus, you can evaluate the offers created by your customers and make your business more efficient while increasing your sales volume.'
          )
        }
      ],
      responsive: [
        {
          maxWidth: 3500,
          slidesPerPage: 1
        }
      ]
    };
  },
  methods: {
    send() {
      this.$refs.sendRules.validate().then(success => {
        if (success) {
          axiosInstance.post('/auth/forget-password', {
              base_url: window.origin,
            email: this.email
          }).then(response => {
            if (response.data.status) {
              this.$swal.fire({
                iconHtml: '<img src="/img/success.svg" width="32" height="32">',
                position: 'top-end',
                title: this.$i18n.t('Reset password link was send to {email}.',{email: this.email}),
                showConfirmButton: false,
                timer: 2500,
                toast: true,
                customClass: {
                  icon: 'swal2-no-border'
                }
              });
            }
          }).catch(error => {
            this.$swal.fire({
              iconHtml: '<img src="/img/error.svg" width="32" height="32">',
              position: 'top-end',
              title: error.response.data.statusMessage,
              showConfirmButton: false,
              timer: 2500,
              toast: true,
              customClass: {
                icon: 'swal2-no-border'
              }
            });
          });
        } else {
          this.$swal.fire({
            iconHtml: '<img src="/img/warning.png" width="32" height="32">',
            position: 'top-end',
            title: this.$i18n.t('Please check the fields.'),
            showConfirmButton: false,
            timer: 2500,
            toast: true,
            customClass: {
              icon: 'swal2-no-border'
            }
          });
        }
      })
    }
  }
};
</script>

<style scoped>
.title {
  font-size: 26px;
  font-weight: 800;
  color: #f4f4f4;
  width: 100%;
  padding-right: 5rem;
}

.title-description {
  font-size: 15px;
  font-weight: 300;
  margin-top: 10px;
  color: #f4f4f4;
  letter-spacing: 0.3px;
  line-height: 1.5;
  max-width: 90%;
}

.step1 {
  background-image: url('@/assets/images/login-1.jpeg');
  height: 100vh;
}

.step2 {
  background-image: url('@/assets/images/login-2.jpeg');
  height: 100vh;
}

.step3 {
  background-image: url('@/assets/images/login-3.jpeg');
  height: 100vh;
}


.step1,
.step2,
.step3 {
  overflow: hidden;
  display: grid;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position-x: 0rem;
}

.step2 {
  background-size: 100% 100%;
}

.slide-step-item {
  top: 65%;
  position: relative;
  right: 55%;
  left: 5%;
}

#login .col-lg-6 {
  padding: 0 !important;
}

#left {
  display: flex;
  align-items: center;
  justify-content: center;
}

.forms-g-wrapper h2 {
  color: #000;
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 2rem;
}

.input-wrapper:first-child {
  margin-top: 1.75rem !important;
}

.input-wrapper {
  border: 1px solid #d8d6de;
  padding: 2px 12px;
  border-radius: 5px;
  width: 100%;
  margin-top: 1rem;
  position: relative;
}


.form-wrapper button {
  border-radius: 5px;
  background: #1c1c1c;
  color: #f4f4f4;
  width: 100%;
  margin-top: 1.5rem;
  border: 1px solid #1c1c1c;
  outline: none !important;
  box-shadow: none !important;
  transition: 0.5s;
  padding: 10px;
}

.form-wrapper button:hover {
  background: #06a5d0;
  border-color: #06a5d0;
  transition: 0.5s;
}

.input-wrapper svg {
  color: #d9d9d9;
}

.toggle-password {
  border: none !important;
  background: transparent !important;
  outline: none !important;
  box-shadow: none !important;
  color: #d9d9d9 !important;
  margin: 0 !important;
  width: min-content !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

@media only screen and (max-width: 992px) {
  #right {
    display: none;
  }

  #login {
    height: 100vh;
    display: flex;
    align-items: center;
  }
}
</style>
