import axios from '@/plugins/axios';
const state = {
  categories: []
};

const mutations = {
  setTireCategoryData(state, data) {
    state.categories = data;
  }
};

const actions = {
  fetchTireCategories({ commit }) {
    axios
      .get('/tire/categories')
      .then((response) => {
        commit('setTireCategoryData', response.data.responseData.categories);
      })
      .catch((error) => {
        console.error(error);
      });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
