<template>
  <Default>
    <div>
      <PageBreadcrumbs :breadcrumbs="breadcrumbs"/>
      <div class="row">
        <div class="col-lg-8">
          <div id="products-listing-table-wrapper" class="py-4" style="min-height: 500px;">
            <div class="products-double-flex">
              <div class="products-double-flex-left">
                <p class="transfer-page-title">{{ $t('Choose Warehouse') }}</p>
                <p class="page-txt">
                  {{ $t('Select the warehouse you want to import.') }}
                </p>
              </div>
            </div>
            <warehouse-select :warehouse_id="warehouse_id" @setWarehouseId="warehouse_id = $event; getTireBrands()"/>
            <div v-if="warehouse_id">
              <div v-if="!loading">
                <div class="products-double-flex mt-5">
                  <div class="products-double-flex-left">
                    <p class="transfer-page-title">{{ $t('Choose Brand') }}</p>
                    <p class="page-txt">
                      {{ $t('Select the product brands you want to import.') }}
                    </p>
                  </div>
                </div>
                <div
                    class="flex"
                    style="padding: 0px 16px">
                  <b-form-checkbox
                      id="import-all-brands"
                      v-model="if_checked"
                      name="import-all-brands"
                      :value="true"
                      :unchecked-value="false"
                      @change="checkUncheck()">
                    {{ $t('All') }}
                  </b-form-checkbox>

                  <div
                      class="d-flex align-items-center"
                      id="import-tool-brands-wrapper">
                    <b-form-checkbox
                        v-for="option in brands"
                        @change="selectBrand(option)"
                        v-model="option.in_uses"
                        :key="option.$index"
                        :value="true"
                        :disabled="in_uses.includes(option)"
                        name="checkbox-1"
                        inline>
                      <b-img
                          v-if="option.asset_path"
                          :class="option.asset_path ? 'brand-image' : ''"
                          :src="option.asset_path"
                          width="100"
                          fluid/>
                      <span class="brand-name">{{ option.name }}</span>
                    </b-form-checkbox>
                  </div>
                </div>
              </div>
              <div v-else class="row px-3 mt-4">
                <b-col sm="12" md="12" lg="12">
                  <content-placeholders :rounded="true">
                    <content-placeholders-text :lines="10"/>
                  </content-placeholders>
                </b-col>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div
              id="products-listing-table-wrapper"
              class="py-4"
              style="padding: 0 16px">
            <div class="import-products-count-wrapper">
              <div class="products-double-flex-left">
                <p class="preview-title">{{ $t('Preview') }}</p>
                <div class="flex-center-space">
                  <p class="import-products-count-brand">
                    {{ $t('Total Product Count') }}
                  </p>
                  <p class="import-products-count">
                    {{ total_product_count }} {{ $t('Product') }}
                  </p>
                </div>
                <div
                    v-for="item in checked"
                    :key="item.$index">
                  <div class="flex-center-space">
                    <p class="import-products-count-brand">
                      {{ item.name }}
                    </p>
                    <p class="import-products-count">
                      {{ item.tire_products_count }}
                      {{ $t('Product') }}
                    </p>
                  </div>
                </div>
                <button class="w-100 mt-5" @click="transferProducts()" :class="warehouse_id === null || checked.length === 0 ? 'disabled-btn' : 'servislet-black-btn'">
                  {{ $t('Import Products') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Default>
</template>

<script>
/* eslint-disable */
import PageBreadcrumbs from '../../components/PageBreadcrumbs.vue';
import Default from '@/layouts/default';
import WarehouseSelect from "@/components/products/import/WarehouseSelect.vue";

export default {
  components: {
    PageBreadcrumbs,
    Default,
    WarehouseSelect
  },
  data() {
    return {
      breadcrumbs: [
        {
          name: this.$i18n.t('Products'),
          path: '/products'
        },
        {
          name: this.$i18n.t('Import')
        }
      ],
      loading: true,
      checked: [],
      brands: [],
      brandList: [],
      total_product_count: 0,
      warehouses: [],
      warehouse_id: localStorage.getItem('warehouse_id') ? parseInt(localStorage.getItem('warehouse_id')) : null,
      if_checked: false,
      in_uses:[]
    };
  },
  mounted() {},
  methods: {
    getTireBrands() {
      this.loading = true;
      this.brandList = [];
      this.$axios.get('/tire/brands/transfer-brands', {
        params: {
          warehouse_id: this.warehouse_id
        }
      }).then((response) => {
        this.checked = []
        this.brands = response.data.responseData.brands;
        for (let i in this.brands) {
          this.brands[i].in_uses = this.brands[i].in_use
          if(this.brands[i].in_use){
            this.in_uses.push(this.brands[i])
          }
          this.brandList[this.brands[i].id] = this.brands[i];
        }
        this.loading = false;
      });
    },
    checkUncheck() {
      this.total_product_count = 0
      if (this.if_checked) {
        this.checked = [];
        for (let i in this.brands) {
          if(!this.brands[i].in_use){
            this.brands[i].in_uses = true
            this.checked.push(this.brands[i]);
            this.total_product_count += this.brands[i].tire_products_count;
          }
        }
      } else {
        this.checked = []
        for (let i in this.brands) {
          if(!this.brands[i].in_use){
            this.brands[i].in_uses = false
          }
        }
      }
    },
    selectBrand(option) {
      if(option.in_uses){
        this.checked.push(option)
        this.total_product_count += option.tire_products_count;
      }else{
        this.checked.splice(this.checked.indexOf(option),1)
        this.total_product_count -= option.tire_products_count;
      }
    },
    transferProducts() {
      var ids = this.checked.map(item=>item.id)
      this.$axios.post('/tire/products/transfer', {
        tire_brand_ids: ids,
        warehouse_id: this.warehouse_id
      }).then((response) => {
        if (response.data.status) {
          this.$swal.fire({
            iconHtml: '<img src="/img/success.svg" width="32" height="32">',
            position: 'top-end',
            title: this.$i18n.t('Products were successfully transferred.'),
            showConfirmButton: false,
            timer: 2500,
            toast: true,
            customClass: {
              icon: 'swal2-no-border'
            }
          });
          this.checked = [];
          this.total_product_count = 0;
          this.getTireBrands()
        }
      }).catch((error) => {
        var message = error.response.data.responseData.error
            ? error.response.data.responseData.error[0].message
            : error.response.data.responseData.message;
        this.$swal.fire({
          iconHtml: '<img src="/img/error.svg" width="32" height="32">',
          position: 'top-end',
          title: message,
          showConfirmButton: false,
          timer: 2500,
          toast: true,
          customClass: {
            icon: 'swal2-no-border'
          }
        });
      });
    }
  }
};
</script>

<style scoped>
.transfer-page-title {
  color: #5e5873;
  font-size: 18px;
  font-weight: 500;
}

.preview-title {
  color: #1c1c1c;
  font-size: 14px;
  font-weight: 500;
}

.brand-name {
  color: #777;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
}

.brand-image {
  background-color: #fff;
  border-radius: 5px;
  padding: 10px;
}

.custom-control-label {
  display: flex;
  align-items: center;
}

#products-listing-table-wrapper {
  height: fit-content;
}
.disabled-btn{
  transition: 0.5s;
  min-width: 150px;
  background: #d3d3d3;
  color: #777;
  border: 1px solid #d3d3d3;
  outline: none !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  letter-spacing: 0.4px !important;
  padding: 8px 24px !important;
  border-radius: 5px !important;
  pointer-events: none;
}
</style>
<style>
.warehouse-modal-content-text .component__select-options {
  width: 350px !important;
}
</style>
