// store/carBrands.js

import Vue from 'vue';
import Vuex from 'vuex';
// import axios from 'axios';
import axios from '@/plugins/axios';

// const TOKEN = localStorage.userData ? 'Bearer '+JSON.parse(localStorage.userData).access_token : ''
// const API_BASE_URL = 'https://staging-pro-api.servislet.com/api/v1/web/main'

Vue.use(Vuex);

const state = {
  cities: []
};

const mutations = {
  setCitiesData(state, data) {
    state.cities = data;
  }
};

const actions = {
  fetchCities({ commit }) {
    axios
      .get('/regions/cities', {})
      .then((response) => {
        commit('setCitiesData', response.data.responseData.cities);
      })
      .catch((error) => {
        console.error(error);
      });
  }
};

const getters = {
  getCitiesData(state) {
    return state.cities;
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
