<template>
  <div id="sales-chart-wrapper">
    <div
      v-if="!loader"
      class="sales-chart-flex">
      <div class="sales-chart-flex-left">
        <p class="sales-chart-title">
          {{ $t('Sales') }}
        </p>

        <p class="curr-week">
          <span>•</span>
          {{ $t('This Week') }} {{ formatPrice(this_week) }}
        </p>

        <p class="prev-week">
          <span>•</span>
          {{ $t('Last Week') }} {{ formatPrice(last_week) }}
        </p>
      </div>
      <div class="sales-chart-flex-right">
        <button
          class="actions-drop-btn"
          style="visibility: hidden">
          ...
        </button>
      </div>
    </div>
    <div
      v-if="!loader"
      class="sales-chart">
      <line-chart :data="data" :legend="false" />
    </div>
    <div
      v-if="loader"
      class="loader">
      <content-placeholders :rounded="true">
        <content-placeholders-img />
        <content-placeholders-text :lines="10" />
      </content-placeholders>
    </div>
  </div>
</template>

<script>
// https://github.com/ankane/vue-chartkick/blob/vue2-docs/README.md
/* eslint-disable */
export default {
  data() {
    return {
      data: [
        {
          name: this.$i18n.t('This Week'),
          color: '#1C1C1C',
          data: {
            [`${this.$i18n.t('Mon')}`]: 1,
            [`${this.$i18n.t('Tue')}`]: 4,
            [`${this.$i18n.t('Wed')}`]: 5,
            [`${this.$i18n.t('Thu')}`]: 3,
            [`${this.$i18n.t('Fri')}`]: 2,
            [`${this.$i18n.t('Sat')}`]: 4,
            [`${this.$i18n.t('Sun')}`]: 6
          }
        },
        {
          name: this.$i18n.t('Last Week'),
          color: '#A8C5DA',
          data: {
            [`${this.$i18n.t('Mon')}`]: 0,
            [`${this.$i18n.t('Tue')}`]: 3,
            [`${this.$i18n.t('Wed')}`]: 5,
            [`${this.$i18n.t('Thu')}`]: 4,
            [`${this.$i18n.t('Fri')}`]: 3,
            [`${this.$i18n.t('Sat')}`]: 3,
            [`${this.$i18n.t('Sun')}`]: 6
          }
        }
      ],
      loader: false,
      saleData: {},
      this_week: 0,
      last_week: 0
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.loader = true;
      this.$axios
        .get(`/tire/charts/sale`, {})
        .then((response) => {
          this.saleData = response.data.responseData.data;
          this.data[0].data[this.$i18n.t('Mon')] =
            this.saleData.this_week['Monday'];
          this.data[0].data[this.$i18n.t('Tue')] =
            this.saleData.this_week['Tuesday'];
          this.data[0].data[this.$i18n.t('Wed')] =
            this.saleData.this_week['Wednesday'];
          this.data[0].data[this.$i18n.t('Thu')] =
            this.saleData.this_week['Thursday'];
          this.data[0].data[this.$i18n.t('Fri')] =
            this.saleData.this_week['Friday'];
          this.data[0].data[this.$i18n.t('Sat')] =
            this.saleData.this_week['Saturday'];
          this.data[0].data[this.$i18n.t('Sun')] =
            this.saleData.this_week['Sunday'];

          Object.values(this.saleData.this_week).forEach((value) => {
            this.this_week += value;
          });

          this.data[1].data[this.$i18n.t('Mon')] =
            this.saleData.last_week['Monday'];
          this.data[1].data[this.$i18n.t('Tue')] =
            this.saleData.last_week['Tuesday'];
          this.data[1].data[this.$i18n.t('Wed')] =
            this.saleData.last_week['Wednesday'];
          this.data[1].data[this.$i18n.t('Thu')] =
            this.saleData.last_week['Thursday'];
          this.data[1].data[this.$i18n.t('Fri')] =
            this.saleData.last_week['Friday'];
          this.data[1].data[this.$i18n.t('Sat')] =
            this.saleData.last_week['Saturday'];
          this.data[1].data[this.$i18n.t('Sun')] =
            this.saleData.last_week['Sunday'];

          Object.values(this.saleData.last_week).forEach((value) => {
            this.last_week += value;
          });

          this.loader = false;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    formatPrice(price) {
      return price.toLocaleString('tr-TR', {
        style: 'currency',
        currency: 'TRY'
      });
    }
  }
};
</script>

<style></style>
