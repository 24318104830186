<template>
  <div>
    <div
      @click="show = !show"
      :class="show ? 'focused-border' : 'not-focused-border'">
      <div class="input-group">
        <flat-pickr
          v-model="date"
          :config="config"
          class="form-control"
          :placeholder="$t('Date Range')"
          style="border-right: none"
          @on-change="selectDate"
          name="date" />
        <div
          class="input-group-append"
          v-if="date"
          style="
            background-color: #f4f4f4;
            border-radius: 5px;
            border-left: none;
          ">
          <button
            class="btn btn-default"
            type="button"
            @click="date = null">
            <i class="fa fa-times" />
            <span
              aria-hidden="true"
              class="sr-only"
              >Clear</span
            >
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vClickOutside from 'v-click-outside';
import {Turkish} from "@/locales/flatpickr/tr";
import {English} from "@/locales/flatpickr/en";

export default {
  name: 'FilterByDate',
  directives: {
    clickOutside: vClickOutside.directive
  },
  props: {},
  data() {
    return {
      show: false,
      date: null,
      config: {
        enableTime: false,
        mode: 'range',
        dateFormat: 'Y-m-d',
        locale: localStorage.getItem('locale') ===  'tr' ? Turkish : English
      }
    };
  },
  watch: {
    $route: function () {
      if (!this.$route.query.start_date) {
        this.date = null;
      } else {
        this.assignFilter();
      }
    }
  },
  mounted() {},
  methods: {
    assignFilter() {
      if (this.$route.query.start_date && this.$route.query.end_date) {
        this.date =
          this.$route.query.start_date + ' - ' + this.$route.query.end_date;
        this.selectDate();
      } else {
        this.date = null;
      }
    },
    selectDate() {
      if (this.date) {
        if (this.date.length > 10) {
          var url = new URL(window.location.href);
          url.searchParams.set('start_date', this.date.substring(0, 10));
          url.searchParams.set('end_date', this.date.substring(13, 23));
          this.$route.query.start_date = this.date.substring(0, 10);
          this.$route.query.end_date = this.date.substring(13, 23);
          url.search = decodeURIComponent(url.search);
          window.history.pushState({}, '', url);
          this.$emit('filterByDate');
        } else {
          this.$swal.fire({
            iconHtml: '<img src="/img/warning.png" width="32" height="32">',
            position: 'top-end',
            title: this.$i18n.t('Please select end date.'),
            showConfirmButton: false,
            timer: 2500,
            toast: true,
            customClass: {
              icon: 'swal2-no-border'
            }
          });
        }
      } else {
        this.$emit('clearQueryDates');
      }
    }
  }
};
</script>

<style scoped>
.focused-border {
  border: 1px solid #06a5d0;
  color: #06a5d0;
  border-radius: 5px;
  background-color: var(--background-color-sidebar) !important;
}
.not-focused-border {
  border: 1px solid rgba(119, 119, 119, 0.6);
  border-radius: 5px;
}

::placeholder {
  font-size: 13px !important;
  color: #212529 !important;
}
.focused-border ::placeholder {
  color: #06a5d0 !important;
}
</style>
