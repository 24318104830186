import Vue from 'vue';
import Vuex from 'vuex';
import axiosInstance from '@/plugins/axios';

Vue.use(Vuex);

const state = {
    list: [],
    loading: true,
    pagination: {}
};

const mutations = {
    setList(state, payload) {
        state.list = payload;
    },
    setLoading(state, payload) {
        state.loading = payload;
    },
    setPagination(state, payload) {
        state.pagination = payload;
    }
};

const actions = {
    getPayments({commit}, data) {
        commit('setLoading', true);
        axiosInstance.get(`/payment-transfers`,{
            params:{
                page: data.page,
                limit: 10,
                search_text: data.order_id,
                start_date: data.start_date,
                end_date: data.end_date,
                sort_direction: data.sort_direction,
                sort_column: data.sort_column
            }
        }).then((response) => {
            if (response.data.status) {
                commit('setList', response.data.responseData.paymentTransfers.items);
                commit('setPagination', response.data.responseData.paymentTransfers.pagination);
                commit('setLoading', false);
            }
        });
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions
};
