import Vue from 'vue';
import Vuex from 'vuex';
import axios from '@/plugins/axios'

const API_BASE_URL = process.env.VUE_APP_URL ?? 'https://staging-pro-api.servislet.com/api/v1/web/main';

Vue.use(Vuex);

const state = {
  loading: false,
  offer: []
};

const mutations = {
  setTireOffer(state, data) {
    console.log(data);
    state.offer = data;
  }
};

const actions = {
  fetchTireOffer({ state, commit }, token) {
    state.loading = true;
    axios.get(API_BASE_URL + '/tire/offers/from-token', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then((response) => {
        var offer = response.data.responseData.tireOffer;
        offer.total_tax = window.helper.currenctFormat(
          'tr-TR',
          offer.total_tax
        );
        offer.total_discount = window.helper.currenctFormat(
          'tr-TR',
          offer.total_discount
        );
        offer.total_price = window.helper.currenctFormat(
          'tr-TR',
          offer.total_price
        );
        offer.total_amount = window.helper.currenctFormat(
          'tr-TR',
          offer.total_amount
        );
        offer.total_cargo = window.helper.currenctFormat(
          'tr-TR',
          offer.total_cargo
        );
        offer.items.forEach((item) => {
          item.discount_amount = 0;
          item.tax_amount = window.helper.currenctFormat(
            'tr-TR',
            item.tax_amount
          );
          item.price = window.helper.currenctFormat('tr-TR', item.price);
          item.amount = window.helper.currenctFormat('tr-TR', item.amount);
          item.discounts.forEach(
            (discount) => (item.discount_amount += discount.discount_amount)
          );
          item.discount_amount = window.helper.currenctFormat(
            'tr-TR',
            item.discount_amount
          );
        });
        commit('setTireOffer', response.data.responseData.tireOffer);
        state.loading = false;
      })
      .catch((error) => {
        console.error(error);
      });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
