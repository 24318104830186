<template>
  <DefaultLayout>
    <div id="car-detail">
      <PageBreadcrumbs :breadcrumbs="breadcrumbs" />
      <div
        class="row"
        v-if="!loader">
        <div class="col-lg-12">
          <div class="product-detail-left-wrapper">
            <div class="products-double-flex">
              <div class="products-double-flex-left">
                <p class="page-title">{{ $t('Car Details') }}</p>
              </div>
            </div>
            <b-tabs
              active-nav-item-class="product-detail-tabs-active"
              content-class="product-detail-tabs-content mt-2 car-detail-tabs-content"
              class="product-detail-tabs mt-3">
              <b-tab
                :title="$t('Car Informations')"
                active>
                <div
                  class="product-detail-history-flex"
                  v-if="Object.keys(car).length > 0">
                  <ValidationObserver ref="customerAddRules">
                    <div
                      class="row"
                      id="car-detail-cards">
                      <div class="flex-center-space">
                        <div></div>
                        <button
                          class="edit-btn"
                          @click="edit = true">
                          <i class="fa-regular fa-pen-to-square"></i>
                        </button>
                      </div>
                      <div class="col-lg-6">
                        <table>
                          <tr>
                            <td v-if="!edit">{{ $t('Plate') }}</td>
                            <td v-if="!edit">{{ car.plate_number }}</td>
                            <ValidationProvider
                              :name="$t('Plate')"
                              rules="required|max:20"
                              #default="{ errors }"
                              v-if="edit">
                              <b-form-group>
                                <label for="plate"
                                  >{{ $t('Plate') }}
                                  <small class="text-danger">*</small></label
                                >
                                <b-form-input
                                  v-model="car.plate_number"
                                  @input="formatCarPlate"></b-form-input>
                                <label
                                  for="plate"
                                  class="text-danger"
                                  v-if="errors.length > 0"
                                  ><small>*</small>{{ errors[0] }}.</label
                                >
                              </b-form-group>
                            </ValidationProvider>
                          </tr>
                          <tr>
                            <td v-if="!edit">{{ $t('Brand') }}</td>
                            <td v-if="!edit">{{ car.carBrand.name }}</td>
                            <b-form-group v-if="edit">
                              <label for="plate"
                                >{{ $t('Brand') }}
                                <small class="text-danger">*</small></label
                              >
                              <b-form-input
                                v-model="car.carBrand.name"
                                disabled></b-form-input>
                            </b-form-group>
                          </tr>
                          <tr>
                            <td v-if="!edit">{{ $t('Version') }}</td>
                            <td v-if="!edit">{{ car.car.version_type }}</td>
                            <b-form-group v-if="edit">
                              <label for="plate"
                                >{{ $t('Version') }}
                                <small class="text-danger">*</small></label
                              >
                              <b-form-input
                                v-model="car.car.version_type"
                                disabled></b-form-input>
                            </b-form-group>
                          </tr>
                          <tr>
                            <td v-if="!edit">{{ $t('Car Owner') }}</td>
                            <td v-if="!edit">
                              {{
                                car.customer.name + ' ' + car.customer.surname
                              }}
                            </td>
                            <b-form-group v-if="edit">
                              <label for="plate"
                                >{{ $t('Car Owner') }}
                                <small class="text-danger">*</small></label
                              >
                              <b-form-input
                                :value="
                                  car.customer.name + ' ' + car.customer.surname
                                "
                                disabled></b-form-input>
                            </b-form-group>
                          </tr>
                          <tr>
                            <td v-if="!edit">{{ $t('Tire Brand') }}</td>
                            <td v-if="!edit">{{ car.tireBrand.name }}</td>
                            <b-form-group
                              :label="$t('Tire Brand')"
                              class="mr-6"
                              v-if="edit">
                              <b-form-select
                                v-model="tire_brand"
                                @change="setTireBrand(tire_brand.slug)">
                                <b-form-select-option
                                  :value="brand"
                                  v-for="brand in tireBrands"
                                  :key="brand.$index"
                                  >{{ brand.name }}</b-form-select-option
                                >
                              </b-form-select>
                            </b-form-group>
                          </tr>
                          <tr>
                            <b-form-group
                              :label="$t('Width')"
                              v-if="edit">
                              <b-form-input
                                v-model="tire_width"
                                class="input"
                                type="number"></b-form-input>
                              <!-- <b-form-select v-model="tire_width"  @change="setTireWidth()">
                                                        <b-form-select-option :value="width" v-for="width in tireWidths" :key="width.$index">{{ width }}</b-form-select-option>
                                                    </b-form-select> -->
                            </b-form-group>
                          </tr>
                        </table>
                      </div>
                      <div class="col-lg-6">
                        <table>
                          <tr>
                            <td v-if="!edit">{{ $t('Chassis') }}</td>
                            <td v-if="!edit">{{ car.chassis_number }}</td>
                            <ValidationProvider
                              :name="$t('Chassis')"
                              rules="max:20"
                              #default="{ errors }"
                              v-if="edit">
                              <b-form-group>
                                <label for="plate"
                                  >{{ $t('Chassis') }}
                                  <small class="text-danger"></small
                                ></label>
                                <b-form-input
                                  v-model="car.chassis_number"></b-form-input>
                                <label
                                  for="plate"
                                  class="text-danger"
                                  v-if="errors.length > 0"
                                  ><small>*</small>{{ errors[0] }}.</label
                                >
                              </b-form-group>
                            </ValidationProvider>
                          </tr>
                          <tr>
                            <td v-if="!edit">{{ $t('Year') }} / Model</td>
                            <td v-if="!edit">
                              {{ car.year + ' / ' + car.carModel.name }}
                            </td>
                            <b-form-group v-if="edit">
                              <label for="plate"
                                >{{ $t('Year') }} / Model
                                <small class="text-danger">*</small></label
                              >
                              <b-form-input
                                :value="car.year + ' / ' + car.carModel.name"
                                disabled></b-form-input>
                            </b-form-group>
                          </tr>
                          <tr>
                            <td v-if="!edit">{{ $t('Fuel') }}</td>
                            <td v-if="!edit">{{ car.car.fuel_type }}</td>
                            <b-form-group v-if="edit">
                              <label for="plate"
                                >{{ $t('Fuel') }}
                                <small class="text-danger">*</small></label
                              >
                              <b-form-input
                                v-model="car.car.fuel_type"
                                disabled></b-form-input>
                            </b-form-group>
                          </tr>
                          <tr>
                            <td v-if="!edit">KM</td>
                            <td v-if="!edit">{{ car.total_mileage }}</td>
                            <ValidationProvider
                              name="KM"
                              rules="max:20"
                              #default="{ errors }"
                              v-if="edit">
                              <b-form-group>
                                <label for="plate"
                                  >KM <small class="text-danger"></small
                                ></label>
                                <b-form-input
                                  v-model="car.total_mileage"></b-form-input>
                                <label
                                  for="plate"
                                  class="text-danger"
                                  v-if="errors.length > 0"
                                  ><small>*</small>{{ errors[0] }}.</label
                                >
                              </b-form-group>
                            </ValidationProvider>
                          </tr>
                          <tr>
                            <td v-if="!edit">{{ $t('Tire Sizes') }}</td>
                            <td
                              v-if="
                                !edit && car.width && car.height && car.radius
                              ">
                              {{
                                car.width +
                                ' / ' +
                                car.height +
                                ' / ' +
                                'R' +
                                car.radius
                              }}
                            </td>
                          </tr>
                          <tr v-if="edit">
                            <b-form-group :label="$t('Height')">
                              <b-form-input
                                v-model="tire_height"
                                class="input"
                                type="number"></b-form-input>
                              <!-- <b-form-select v-model="tire_height" @change="setTireHeight()">
                                                        <b-form-select-option :value="height" v-for="height in tireHeights" :key="height.$index">{{ height }}</b-form-select-option>
                                                    </b-form-select> -->
                            </b-form-group>
                          </tr>
                          <tr v-if="edit">
                            <b-form-group
                              :label="$t('Radius')"
                              class="mr-6">
                              <b-form-input
                                v-model="tire_radius"
                                class="input"
                                type="number"></b-form-input>
                              <!-- <b-form-select v-model="tire_radius">
                                                        <b-form-select-option :value="radius" v-for="radius in tireRadiuses" :key="radius.$index">{{ radius }}</b-form-select-option>
                                                    </b-form-select> -->
                            </b-form-group>
                          </tr>
                        </table>
                      </div>

                      <div
                        class="col-lg-6"
                        v-if="edit"></div>
                      <div
                        class="col-lg-6 flex-center-space mt-4"
                        v-if="edit">
                        <button
                          class="cancel"
                          @click="edit = false">
                          {{ $t('Cancel') }}
                        </button>
                        <button
                          class="approve"
                          @click="updateCar()">
                          {{ $t('Save') }}
                        </button>
                      </div>
                    </div>
                  </ValidationObserver>
                </div>
              </b-tab>
              <b-tab :title="$t('Technical Informations')">
                <div
                  class="product-detail-history-flex"
                  v-if="Object.keys(car).length > 0">
                  <div
                    class="row"
                    id="car-detail-cards">
                    <div class="col-lg-6">
                      <table>
                        <tr>
                          <td>{{ $t('Plate') }}</td>
                          <td>{{ car.plate_number }}</td>
                        </tr>
                        <tr>
                          <td>{{ $t('Brand') }}</td>
                          <td>{{ car.carBrand.name }}</td>
                        </tr>
                        <tr>
                          <td>{{ $t('Version') }}</td>
                          <td>{{ car.car.version_type }}</td>
                        </tr>
                        <tr>
                          <td>{{ $t('Car Owner') }}</td>
                          <td>
                            {{ car.customer.name + ' ' + car.customer.surname }}
                          </td>
                        </tr>
                        <tr>
                          <td>{{ $t('Tire Brand') }}</td>
                          <td>{{ car.tireBrand.name }}</td>
                        </tr>
                      </table>
                    </div>
                    <div class="col-lg-6">
                      <table>
                        <tr>
                          <td>{{ $t('Chassis') }}</td>
                          <td>{{ car.chassis_number }}</td>
                        </tr>
                        <tr>
                          <td>{{ $t('Year') }} / Model</td>
                          <td>{{ car.year + ' / ' + car.carModel.name }}</td>
                        </tr>
                        <tr>
                          <td>{{ $t('Fuel') }}</td>
                          <td>{{ car.car.fuel_type }}</td>
                        </tr>
                        <tr>
                          <td>KM</td>
                          <td>{{ car.total_mileage }}</td>
                        </tr>
                        <tr>
                          <td>{{ $t('Tire Sizes') }}</td>
                          <td>
                            {{
                              car.width +
                              ' / ' +
                              car.height +
                              ' / ' +
                              'R' +
                              car.radius
                            }}
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
                <div
                  class="product-detail-history-flex"
                  v-if="Object.keys(car).length > 0">
                  <div
                    class="row mt-4"
                    id="car-detail-cards">
                    <div class="col-lg-12">
                      <p class="table-title mt-4">
                        {{ $t('Technical Informations') }}
                      </p>
                    </div>
                    <div class="col-lg-6">
                      <table>
                        <tr>
                          <td>{{ $t('Brand No') }}</td>
                          <td>{{ car.carBrand.id }}</td>
                        </tr>

                        <tr>
                          <td>{{ $t('Horsepower') }}</td>
                          <td>{{ car.car.hp }}</td>
                        </tr>
                        <tr>
                          <td>{{ $t('Gear Type') }}</td>
                          <td>{{ car.car.gear_tpye }}</td>
                        </tr>
                      </table>
                    </div>
                    <div class="col-lg-6">
                      <table>
                        <tr>
                          <td>{{ $t('Model No.') }}</td>
                          <td>{{ car.carModel.id }}</td>
                        </tr>
                        <tr>
                          <td>{{ $t('Case Type') }}</td>
                          <td>{{ car.car.body_style_type }}</td>
                        </tr>
                        <tr>
                          <td>{{ $t('Transmission Type') }}</td>
                          <td>{{ car.car.gear_type }}</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </div>
        <!-- <div class="col-lg-3">
                <div class="product-detail-right-wrapper">
                    <b-form-group id="channel-group" label="Satış Kanalı:" label-for="channel">
                        <b-form-input
                        id="channel"
                        type="text"
                        placeholder="Satış Kanalı"
                        disabled
                        ></b-form-input>
                    </b-form-group>

                    <div class="product-detail-right-checkboxes" v-for="item in 3" :key="item">
                        <span>Stok Aç / Kapat</span>

                        <b-form-checkbox
                        id="product-detail-checkbox"
                        value="open_stock"
                        unchecked-value="close_stock"
                        switch
                        ></b-form-checkbox>
                    </div>

                    <b-button>Güncelle</b-button>
                </div>
            </div> -->
      </div>
      <div
        class="row"
        v-if="loader">
        <content-placeholders :rounded="true">
          <content-placeholders-img />
          <content-placeholders-text :lines="10" />
        </content-placeholders>
      </div>
    </div>
  </DefaultLayout>
</template>

<script>
/* eslint-disable */
import DefaultLayout from '@/layouts/default';
import PageBreadcrumbs from '../../components/PageBreadcrumbs.vue';
import StarRating from '../../components/StarRating.vue';
export default {
  components: {
    PageBreadcrumbs,
    StarRating,
    DefaultLayout
  },
  data() {
    return {
      breadcrumbs: [
        {
          name: this.$i18n.t('Cars'),
          path: '/cars'
        },
        {
          name: '',
          path: '/#'
        }
      ],
      form: {
        stock: '',
        dot: '',
        salesPrice: '',
        paymemnt: '',
        commission: ''
      },
      car: {},
      loader: false,
      edit: false,
      tire_brand: {},
      tire_width: '',
      tire_height: '',
      tire_radius: ''
    };
  },
  computed: {
    tireBrands() {
      return this.$store.getters['tireBrands/getTireBrandsData'];
    },
    tireWidths() {
      return this.$store.getters['tireWidths/getTireWidthsData'];
    },
    tireHeights() {
      return this.$store.getters['tireHeights/getTireHeightsData'];
    },
    tireRadiuses() {
      return this.$store.getters['tireRadiuses/getTireRadiusesData'];
    }
  },
  mounted() {
    this.getCar();
    this.$store.dispatch('tireBrands/fetchTireBrands');
    this.tire_brand === this.car.tireBrand;
  },
  methods: {
    formatCarPlate() {
      const cleanedNumber = this.car.plate_number.toUpperCase();
      const formattedNumber = cleanedNumber.replace(
        /(\d{2})(\s?[A-Z]{1,3})(\d{3,4})/,
        '$1 $2 $3'
      );
      this.car.plate_number = formattedNumber;
    },
    getCar() {
      this.loader = true;
      this.$axios
        .get(`/customers/cars/${this.$route.params.id}`, {})
        .then((response) => {
          this.car = response.data.responseData;
          this.breadcrumbs[1].name = this.car.car.name;
          this.loader = false;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    setTireBrand(brand_slug) {
      this.$store.dispatch('tireWidths/fetchTireWidths', brand_slug);
    },
    setTireWidth() {
      this.$store.dispatch('tireHeights/fetchTireHeights', {
        brand_slugs: this.tire_brand.slug,
        width: this.tire_width
      });
    },
    setTireHeight() {
      this.$store.dispatch('tireRadiuses/fetchTireRadiuses', {
        brand_slugs: this.tire_brand.slug,
        width: this.tire_width,
        height: this.tire_height
      });
    },
    updateCar() {
      if (
        this.tire_brand &&
        this.tire_width &&
        this.tire_height &&
        this.tire_radius
      ) {
        this.loader = true;
        let body = {
          is_default: false,
          is_active: false,
          plate_number: this.car.plate_number,
          year: this.car.year,
          chassis_number: this.car.chassis_number,
          tire_brand_id: this.tire_brand.id,
          width: this.tire_width,
          height: this.tire_height,
          radius: this.tire_radius,
          total_mileage: this.car.total_mileage,
          monthly_mileage: this.car.monthly_mileage
        };
        this.$axios
          .put(`/customers/cars/${this.car.id}`, body, {})
          .then((response) => {
            if (response.status) {
              this.edit = false;
              this.$swal.fire({
                iconHtml: '<img src="/img/success.svg" width="32" height="32">',
                position: 'top-end',
                title: this.$i18n.t('Car successfully updated.'),
                showConfirmButton: false,
                timer: 2500,
                toast: true,
                customClass: {
                  icon: 'swal2-no-border'
                }
              });
            }
            this.getCar();
            this.loader = false;
          })
          .catch((error) => {
            var message = error.response.data.responseData.error
              ? error.response.data.responseData.error[0].message
              : error.response.data.responseData.message;
            this.$swal.fire({
              iconHtml: '<img src="/img/error.svg" width="32" height="32">',
              position: 'top-end',
              title: message,
              showConfirmButton: false,
              timer: 2500,
              toast: true,
              customClass: {
                icon: 'swal2-no-border'
              }
            });
          });
      } else {
        this.$swal.fire({
          iconHtml: '<img src="/img/error.svg" width="32" height="32">',
          position: 'top-end',
          title: this.$i18n.t('Please fill the required fields.'),
          showConfirmButton: false,
          timer: 2500,
          toast: true,
          customClass: {
            icon: 'swal2-no-border'
          }
        });
      }
    }
  }
};
</script>

<style>
.car-detail-tabs-content {
  background: unset !important;
  padding: 0;
}
</style>

<style scoped>
#car-detail-cards {
  background: #fff;
  padding: 16px 16px;
  margin: 0 8px;
}
.product-detail-history-flex {
  display: unset !important;
}
table tr td:nth-child(2) {
  color: #1c1c1c !important;
}
table tr {
  border-bottom: 1px solid rgba(233, 236, 239, 0.6);
}
.product-detail-history-flex div:nth-child(2) td:first-child {
  text-align: left;
}
.table-title {
  color: #1c1c1c;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.cancel,
.approve {
  padding: 0.75rem;
  width: 100%;
  margin: 0 0.75rem;
  outline: none;
  border-radius: 5px;
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.4px;
  transition: 0.5s;
}
.cancel {
  background: transparent;
  border: 1px solid #d3d3d3;
  color: #d3d3d3;
}
.approve {
  background: #1c1c1c;
  border: 1px solid #1c1c1c;
  color: #f4f4f4;
}
.edit-btn {
  background: rgba(211, 211, 211, 0.35);
  color: rgba(119, 119, 119, 1);
  transition: 0.5s;
  outline: none;
  border: 1px solid rgba(211, 211, 211, 0.35);
  font-size: 18px;
  border-radius: 5px;
  padding: 6px 12px;
}
input {
  background-color: #fff !important;
}
input:disabled {
  background-color: #f4f4f4 !important;
}
</style>
