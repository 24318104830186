<template>
  <div class="main-wrapper">
    <i class="fa-solid fa-chevron-left opacity-75"></i>
    <span class="campaign-text"><b>{{$t('With Servislet Pro')}}</b> {{$t('your product, sales and offer management processes are together.')}}</span>
    <i class="fa-solid fa-chevron-right opacity-75"></i>
  </div>
</template>

<script>
export default {
  name: 'DashboardCampaignSlider'
}

</script>

<style scoped>
.main-wrapper {
  background-color: #1C1C1C;
  color: #F4F4F4;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  padding: 0 40px;
  margin-top: 16px;
}
.campaign-text{
  font-family: Inter;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}
.campaign-text b{
  font-weight: 600;
}
</style>
