<template>
  <div id="notification-table-wrapper">
    <div
        class="notification-table"
        v-if="!loader">
      <div class="sales-chart-flex-left">
        <p class="sales-chart-title">{{ $t('Notifications') }}</p>
        <p class="notification-table-txt" v-if="activities.items.length>0">
          {{ $t('Your last {count} activities are displayed.', {count: activities.items.length}) }}
        </p>
      </div>
      <div class="notification-table-right">
        <!--        <b-form-select-->
        <!--          v-model="selected"-->
        <!--          :options="options"></b-form-select>-->
      </div>
    </div>
    <div
        class="notification-table"
        v-if="!loader">
      <table v-if="activities.items.length>0">
        <tr class="notification-table-head">
          <th>{{ $t('Category') }}</th>
          <th>{{ $t('Date') }}</th>
          <th>{{ $t('Description') }}</th>
          <th></th>
        </tr>
        <tr v-for="item in activities.items" :key="item.$index">
          <td>
            <p class="servislet-badge" :style="`${'background:' + item.module.color}`">
              {{ item.module.name }}
            </p>
          </td>
          <td>{{ item.created_at.slice(0, 10) }}</td>
          <td>{{ item.title }}</td>
          <td>
            <button>+</button>
          </td>
        </tr>
      </table>
      <p v-if="activities.items.length === 0">
        {{ $t('There are no notifications to list.') }}
      </p>
    </div>
    <div
        class="loader px-3"
        v-if="loader">
      <content-placeholders :rounded="true">
        <content-placeholders-img/>
        <content-placeholders-text :lines="10"/>
      </content-placeholders>
    </div>
  </div>
</template>

<script>
// https://github.com/ankane/vue-chartkick/blob/vue2-docs/README.md
export default {
  data() {
    return {
      selected: null,
      options: [
        {value: null, text: this.$t('See All')},
        {value: null, text: this.$t('Sales')},
        {value: null, text: this.$t('Appointment')},
        {value: null, text: this.$t('Customer')},
        {value: null, text: this.$t('Car')},
        {value: null, text: this.$t('Product')}
      ],
      loader: false,
      activities: {
        items: []
      }
    };
  },
  mounted() {
    this.getActivities();
  },
  methods: {
    getActivities() {
      this.loader = true;
      this.$axios.get(`/merchants/activities`).then((response) => {
        this.activities = response.data.responseData;
        this.loader = false;
      }).catch((error) => {
        console.error(error);
      });
    }
  }
};
</script>

<style scoped>
th {
  font-size: 12px;
  font-weight: 500;
}

td {
  font-size: 12px;
  font-weight: 600;
}
</style>
