<template>
  <Default>
    <div>
      <PageBreadcrumbs :breadcrumbs="breadcrumbs" />
      <div
        class="row"
        v-if="!loading">
        <div class="col-lg-12">
          <div class="product-detail-left-wrapper">
            <div class="products-double-flex">
              <div class="products-double-flex-left">
                <p class="page-title">
                  #{{ user.id }} {{ user.name + ' ' + user.surname }}
                </p>
              </div>
            </div>
            <b-tabs
              active-nav-item-class="product-detail-tabs-active"
              content-class="sales-detail-tabs-content mt-2"
              class="product-detail-tabs mt-3">
              <b-tab
                :title="$t('Customer Details')"
                active>
                <div
                  class="sales-detail-card"
                  id="customer-detail-editable"
                  v-if="user.customerType.name === 'Bireysel'">
                  <div class="d-flex justify-content-between">
                    <p class="sales-detail-card-title">{{ $t('Customer') }}</p>
                    <div id="edit-btn">
                      <i
                        class="fa-solid fa-pen-to-square"
                        @click="user_edit = true"
                        v-if="!user_edit"></i>
                      <i
                        class="fa-solid fa-xmark"
                        @click="user_edit = false"
                        v-if="user_edit"></i>
                    </div>
                  </div>
                  <div
                    class="product-detail-form-flex"
                    v-if="!user_edit">
                    <div class="product-detail-form-flex-left">
                      <div class="sales-detail-item">
                        <p class="sales-detail-item-title">
                          {{ $t('Name Surname') }}
                        </p>
                        <p class="sales-detail-item-info">
                          {{ user.name }} {{ user.surname }}
                        </p>
                      </div>
                      <div
                        class="sales-detail-item"
                        v-for="phone in user.phones">
                        <p class="sales-detail-item-title">{{ $t('Phone') }}</p>
                        <p class="sales-detail-item-info">
                          {{ phone.phone /*user.mobile*/ }}
                          <span
                            class="default-phone-badge"
                            v-if="phone.is_default"
                            >{{ $t('Default') }}</span
                          >
                        </p>
                      </div>
                      <div class="sales-detail-item">
                        <p class="sales-detail-item-title">
                          {{ $t('E-Mail') }}
                        </p>
                        <p class="sales-detail-item-info">{{ user.email }}</p>
                      </div>
                    </div>
                    <div class="product-detail-form-flex-right">
                      <div class="sales-detail-item">
                        <p class="sales-detail-item-title">
                          {{ $t('City') }} / {{ $t('District') }}
                        </p>
                        <p class="sales-detail-item-info">
                          {{
                            user.addresses.length > 0
                              ? user.addresses[0].city.name
                              : '-'
                          }}
                          /
                          {{
                            user.addresses.length > 0
                              ? user.addresses[0].district.name
                              : '-'
                          }}
                        </p>
                      </div>
                      <div class="sales-detail-item">
                        <p class="sales-detail-item-title">
                          {{ $t('Neighborhood') }}
                        </p>
                        <p class="sales-detail-item-info">
                          {{
                            user.addresses.length > 0
                              ? user.addresses[0].area.name
                              : '-'
                          }}
                        </p>
                      </div>
                      <div class="sales-detail-item">
                        <p class="sales-detail-item-title">
                          {{ $t('Address Definition') }}
                        </p>
                        <p class="sales-detail-item-info">
                          {{
                            user.addresses.length > 0
                              ? user.addresses[0].address
                              : '-'
                          }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div>
                    <ValidationObserver ref="userUpdateRules">
                      <div
                        class="row"
                        v-if="user_edit">
                        <b-col
                          sm="12"
                          md="12"
                          lg="6">
                          <div class="row">
                            <b-col
                              sm="12"
                              md="12"
                              lg="6">
                              <ValidationProvider
                                rules="required|min:1|max:60"
                                :name="$t('Name')"
                                #default="{ errors }">
                                <b-form-group>
                                  <label for="name"
                                    >{{ $t('Name') }}
                                    <small class="text-danger">*</small></label
                                  >
                                  <b-form-input
                                    v-model="name"
                                    class="bg-transparent" />
                                  <label
                                    for="name"
                                    v-if="errors.length > 0"
                                    class="text-danger"
                                    ><small>*</small>{{ errors[0] }}.</label
                                  >
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>

                            <b-col
                              sm="12"
                              md="12"
                              lg="6">
                              <ValidationProvider
                                rules="required|min:1|max:30"
                                :name="$t('Surname')"
                                #default="{ errors }">
                                <b-form-group>
                                  <label for="surname"
                                    >{{ $t('Surname') }}
                                    <small class="text-danger">*</small></label
                                  >
                                  <b-form-input
                                    v-model="surname"
                                    class="bg-transparent" />
                                  <label
                                    for="surname"
                                    v-if="errors.length > 0"
                                    class="text-danger"
                                    ><small>*</small>{{ errors[0] }}.</label
                                  >
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col
                              sm="12"
                              md="12"
                              lg="6">
                              <ValidationProvider
                                rules="max:20"
                                :name="$t('Identity No.')"
                                #default="{ errors }">
                                <b-form-group>
                                  <label for="identity_number"
                                    >{{ $t('Identity No.') }}
                                  </label>
                                  <b-form-input
                                    v-model="identity_number"
                                    type="number"
                                    class="bg-transparent" />
                                  <label
                                    for="identity_number"
                                    v-if="errors.length > 0"
                                    class="text-danger"
                                    ><small>*</small>{{ errors[0] }}.</label
                                  >
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>

                            <b-col
                              sm="12"
                              md="12"
                              lg="6">
                              <ValidationProvider
                                :name="$t('Phone Number')"
                                rules="required|max:40"
                                #default="{ errors }">
                                <b-form-group class="mr-6">
                                  <label for="phone"
                                    >{{ $t('Phone Number') }}
                                    <small class="text-danger">*</small></label
                                  >
                                  <b-form-input
                                    v-model="mobile"
                                    class="input bg-transparent"
                                    v-mask="'+90 (###) ### ## ##'" />
                                  <label
                                    for="phone"
                                    v-if="errors.length > 0"
                                    class="text-danger"
                                    ><small>*</small>{{ errors[0] }}.</label
                                  >
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>

                            <b-col
                              sm="12"
                              md="12"
                              lg="12">
                              <ValidationProvider
                                rules="required|email|max:40"
                                :name="$t('E-Mail')"
                                #default="{ errors }">
                                <b-form-group>
                                  <label for="name"
                                    >{{ $t('E-Mail') }}
                                    <small class="text-danger">*</small></label
                                  >
                                  <b-form-input
                                    v-model="email"
                                    class="bg-transparent" />
                                  <label
                                    for="name"
                                    v-if="errors.length > 0"
                                    class="text-danger"
                                    ><small>*</small>{{ errors[0] }}.</label
                                  >
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                          </div>
                        </b-col>
                        <b-col
                          sm="12"
                          md="12"
                          lg="6">
                          <div class="row">
                            <b-col
                              sm="12"
                              md="12"
                              lg="12">
                              <b-form-group>
                                <label for="city">{{ $t('City') }}</label>
                                <b-form-select
                                  v-model="city_id"
                                  @change="getDistrictList()"
                                  class="bg-transparent">
                                  <b-form-select-option
                                    :value="city.id"
                                    v-for="city in cities.cities"
                                    :key="city.$index"
                                    >{{ city.name }}
                                  </b-form-select-option>
                                </b-form-select>
                              </b-form-group>
                            </b-col>

                            <b-col
                              sm="12"
                              md="12"
                              lg="12">
                              <b-form-group>
                                <label for="city">{{ $t('District') }}</label>
                                <b-form-select
                                  v-model="district_id"
                                  @change="getAreaList()"
                                  class="bg-transparent">
                                  <b-form-select-option
                                    :value="district.id"
                                    v-for="district in districts.districts"
                                    :key="district.$index"
                                    >{{ district.name }}
                                  </b-form-select-option>
                                </b-form-select>
                              </b-form-group>
                            </b-col>

                            <b-col
                              sm="12"
                              md="12"
                              lg="12">
                              <b-form-group>
                                <label for="city">{{
                                  $t('Neighborhood')
                                }}</label>
                                <b-form-select
                                  v-model="area_id"
                                  class="bg-transparent">
                                  <b-form-select-option
                                    :value="area.id"
                                    v-for="area in areas.areas"
                                    :key="area.$index">
                                    {{ area.name }}
                                  </b-form-select-option>
                                </b-form-select>
                              </b-form-group>
                            </b-col>
                          </div>
                        </b-col>
                        <b-col
                          sm="12"
                          md="12"
                          lg="12">
                          <ValidationProvider
                            rules="max:200"
                            :name="$t('Address')"
                            #default="{ errors }">
                            <b-form-group>
                              <label for="name">{{ $t('Address') }}</label>
                              <b-form-textarea
                                id="address-textarea"
                                rows="5"
                                v-model="address"></b-form-textarea>
                              <label
                                for="name"
                                v-if="errors.length > 0"
                                class="text-danger"
                                ><small>*</small>{{ errors[0] }}.</label
                              >
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                        <div class="d-flex justify-content-end update">
                          <b-button @click="updateUserandAddress()">{{
                            $t('Update')
                          }}</b-button>
                        </div>
                      </div>
                    </ValidationObserver>
                  </div>
                </div>

                <div
                  class="sales-detail-card"
                  id="customer-detail-editable"
                  v-else>
                  <div class="d-flex justify-content-between">
                    <p class="sales-detail-card-title">{{ $t('Customer') }}</p>
                    <div id="edit-btn">
                      <i
                        class="fa-solid fa-pen-to-square"
                        @click="user_edit = true"
                        v-if="!user_edit"></i>
                      <i
                        class="fa-solid fa-xmark"
                        @click="user_edit = false"
                        v-if="user_edit"></i>
                    </div>
                  </div>
                  <div
                    class="product-detail-form-flex"
                    v-if="!user_edit">
                    <div class="product-detail-form-flex-left">
                      <div class="sales-detail-item">
                        <p class="sales-detail-item-title">
                          {{ $t('Name Surname') }}
                        </p>
                        <p class="sales-detail-item-info">
                          {{ user.name }} {{ user.surname }}
                        </p>
                      </div>
                      <div class="sales-detail-item no-border">
                        <p class="sales-detail-item-title">{{ $t('Phone') }}</p>
                        <p class="sales-detail-item-info">{{ user.mobile }}</p>
                      </div>
                    </div>
                    <div class="product-detail-form-flex-right">
                      <div class="sales-detail-item">
                        <p class="sales-detail-item-title">
                          {{ $t('Job Description') }}
                        </p>
                        <p class="sales-detail-item-info">
                          {{ user.job_title }}
                        </p>
                      </div>

                      <div class="sales-detail-item">
                        <p class="sales-detail-item-title">
                          {{ $t('E-Mail') }}
                        </p>
                        <p class="sales-detail-item-info">{{ user.email }}</p>
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <corporate-user-update-form
                      :user="user"
                      @refresh="
                        getUserDetail();
                        user_edit = false;
                      " />
                  </div>
                </div>

                <div v-if="user.customerType.name === 'Bireysel'">
                  <div
                    class=""
                    v-for="car in user_cars"
                    :key="car.$index">
                    <div class="sales-detail-card">
                      <div class="d-flex justify-content-between">
                        <p class="sales-detail-card-title">{{ $t('Car') }}</p>
                        <div id="edit-btn">
                          <i
                            class="fa-solid fa-pen-to-square"
                            @click="
                              car_edit = true;
                              current = car.id;
                            "
                            v-if="!car_edit"></i>
                          <i
                            class="fa-solid fa-xmark"
                            @click="car_edit = false"
                            v-if="car_edit && car.id === current"></i>
                        </div>
                      </div>
                      <div
                        class="product-detail-form-flex"
                        v-if="!car_edit || current !== car.id">
                        <div class="product-detail-form-flex-left">
                          <div class="sales-detail-item">
                            <p class="sales-detail-item-title">
                              {{ $t('Plate') }}
                            </p>
                            <p class="sales-detail-item-info">
                              {{ car.plate_number }}
                            </p>
                          </div>
                          <div class="sales-detail-item">
                            <p class="sales-detail-item-title">
                              {{ $t('Chassis No.') }}
                            </p>
                            <p class="sales-detail-item-info">
                              {{ car.chassis_number }}
                            </p>
                          </div>
                          <div class="sales-detail-item no-border">
                            <p class="sales-detail-item-title">
                              {{ $t('Fuel') }}
                            </p>
                            <p class="sales-detail-item-info">
                              {{ car.fuel_type }}
                            </p>
                          </div>
                        </div>
                        <div class="product-detail-form-flex-right">
                          <div class="sales-detail-item">
                            <p class="sales-detail-item-title">
                              {{ $t('Brand') }}
                            </p>
                            <p class="sales-detail-item-info">
                              {{ car.brand ? car.brand.name : '-' }}
                            </p>
                          </div>
                          <div class="sales-detail-item">
                            <p class="sales-detail-item-title">
                              {{ $t('Model') }} / {{ $t('Version') }}
                            </p>
                            <p class="sales-detail-item-info">
                              {{ car.model }} / {{ car.version }}
                            </p>
                          </div>
                          <div class="sales-detail-item no-border">
                            <p class="sales-detail-item-title">
                              {{ $t('Year') }}
                            </p>
                            <p class="sales-detail-item-info">{{ car.year }}</p>
                          </div>
                        </div>
                      </div>
                      <div v-if="car_edit === true && car.id === current">
                        <user-car-form
                          :user_car="car"
                          :tire_brand_slug="
                            car.tireBrand ? car.tireBrand.slug : null
                          "
                          @refresh="
                            getUserDetail();
                            car_edit = false;
                          " />
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="sales-detail-card"
                  v-if="user.customerType.name !== 'Bireysel'">
                  <div class="d-flex justify-content-between">
                    <p class="sales-detail-card-title">{{ $t('Company') }}</p>
                    <div id="edit-btn">
                      <i
                        class="fa-solid fa-pen-to-square"
                        @click="company_edit = true"
                        v-if="!company_edit"></i>
                      <i
                        class="fa-solid fa-xmark"
                        @click="company_edit = false"
                        v-if="company_edit"></i>
                    </div>
                  </div>
                  <div
                    class="product-detail-form-flex"
                    v-if="!company_edit">
                    <div class="product-detail-form-flex-left">
                      <div class="sales-detail-item">
                        <p class="sales-detail-item-title">
                          {{ $t('Company Title') }}
                        </p>
                        <p class="sales-detail-item-info">
                          {{ user.company_title }}
                        </p>
                      </div>
                      <div class="sales-detail-item">
                        <p class="sales-detail-item-title">
                          {{ $t('Tax Administration') }}
                        </p>
                        <p class="sales-detail-item-info">
                          {{ user.tax_office }}
                        </p>
                      </div>
                      <div class="sales-detail-item no-border">
                        <p class="sales-detail-item-title">
                          {{ $t('Tax Number') }}
                        </p>
                        <p class="sales-detail-item-info">
                          {{ user.tax_number }}
                        </p>
                      </div>
                    </div>
                    <div class="product-detail-form-flex-right">
                      <div class="sales-detail-item">
                        <p class="sales-detail-item-title">
                          {{ $t('City') }} / {{ $t('District') }}
                        </p>
                        <p class="sales-detail-item-info">
                          {{
                            user.addresses.length > 0
                              ? user.addresses[0].city.name
                              : '-'
                          }}
                          /
                          {{
                            user.addresses.length > 0
                              ? user.addresses[0].district.name
                              : '-'
                          }}
                        </p>
                      </div>
                      <div class="sales-detail-item">
                        <p class="sales-detail-item-title">
                          {{ $t('Neighborhood') }}
                        </p>
                        <p class="sales-detail-item-info">
                          {{
                            user.addresses.length > 0
                              ? user.addresses[0].area.name
                              : '-'
                          }}
                        </p>
                      </div>
                      <div class="sales-detail-item no-border">
                        <p class="sales-detail-item-title">
                          {{ $t('Address Definition') }}
                        </p>
                        <p class="sales-detail-item-info">
                          {{
                            user.addresses.length > 0
                              ? user.addresses[0].address
                              : '-'
                          }}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div v-else>
                    <company-edit-form
                      :user="user"
                      @refresh="
                        getUserDetail();
                        company_edit = false;
                      " />
                  </div>
                </div>
              </b-tab>
              <!--              <b-tab title="Müşteri Geçmişi">-->
              <!--                <div class="sales-detail-history-flex">-->
              <!--                  <div>-->
              <!--                    <table>-->
              <!--                      <tr>-->
              <!--                        <td>Tarih</td>-->
              <!--                        <td>Saat</td>-->
              <!--                      </tr>-->
              <!--                      <tr v-for="item in 5" :key="item">-->
              <!--                        <td>18/07/2022</td>-->
              <!--                        <td>19:02:40</td>-->
              <!--                      </tr>-->
              <!--                    </table>-->
              <!--                  </div>-->
              <!--                  <div>-->
              <!--                    <table>-->
              <!--                      <tr>-->
              <!--                        <td>Sipariş Durumu</td>-->
              <!--                        <td>Müşteri Bildirimi</td>-->
              <!--                      </tr>-->
              <!--                      <tr v-for="item in 5" :key="item">-->
              <!--                        <td>Satış fiyatı güncellendi</td>-->
              <!--                        <td>Evet</td>-->
              <!--                      </tr>-->
              <!--                    </table>-->
              <!--                  </div>-->
              <!--                </div>-->
              <!--              </b-tab>-->
            </b-tabs>
          </div>
        </div>
        <!--        <div class="col-lg-3">-->
        <!--          <div class="product-detail-right-wrapper" id="customer-detail-right-wrapper">-->
        <!--            <b-button>Güncelle</b-button>-->

        <!--            <b-form-group id="channel-group" label="Satış Kanalı:" class="mt-3" label-for="channel">-->
        <!--              <b-form-select v-model="selected" :options="options"-->
        <!--                             class="sales-status-select-completed"></b-form-select>-->
        <!--            </b-form-group>-->

        <!--            <b-form-group id="channel-group" label="Satış Kanalı:" label-for="channel">-->
        <!--              <b-form-input-->
        <!--                  id="channel"-->
        <!--                  type="text"-->
        <!--                  placeholder="Satış Kanalı"-->
        <!--                  disabled-->
        <!--              ></b-form-input>-->
        <!--            </b-form-group>-->

        <!--            <div class="product-detail-right-checkboxes" v-for="item in 3" :key="item">-->
        <!--              <span>Stok Aç / Kapat</span>-->

        <!--              <b-form-checkbox-->
        <!--                  id="product-detail-checkbox"-->
        <!--                  value="open_stock"-->
        <!--                  unchecked-value="close_stock"-->
        <!--                  switch-->
        <!--              ></b-form-checkbox>-->
        <!--            </div>-->

        <!--          </div>-->
        <!--        </div>-->
      </div>
      <div v-else>
        <div class="row">
          <div class="col-lg-9">
            <content-placeholders :rounded="true">
              <content-placeholders-img />
            </content-placeholders>
          </div>
          <div class="col-lg-3">
            <content-placeholders :rounded="true">
              <content-placeholders-img />
            </content-placeholders>
          </div>
        </div>
      </div>
    </div>
  </Default>
</template>

<script>
/* eslint-disable */
import PageBreadcrumbs from '../../components/PageBreadcrumbs.vue';
import Default from '@/layouts/default';
import { mapState } from 'vuex';
import userCarForm from '@/views/customer/userCarForm.vue';
import corporateUserUpdateForm from '@/views/customer/corporateUserUpdateForm.vue';
import companyEditForm from '@/views/customer/companyEditForm.vue';

export default {
  components: {
    PageBreadcrumbs,
    Default,
    userCarForm,
    corporateUserUpdateForm,
    companyEditForm
  },
  data() {
    return {
      loading: true,
      breadcrumbs: [
        {
          name: this.$i18n.t('Retail'),
          path: '/customers'
        },
        {
          name: this.$i18n.t('Customer Details')
        }
      ],
      selected: null,
      options: [
        { value: null, text: this.$i18n.t('Completed'), disabled: true }
      ],
      user_edit: false,
      car_edit: false,
      company_edit: false,
      user: {
        mobile: '',
        customerType: {},
        addresses: []
      },
      user_car: {},
      name: '',
      surname: '',
      mobile: '',
      email: '',
      city_id: null,
      district_id: null,
      area_id: null,
      address: '',
      user_address: {},
      identity_number: null,
      user_cars: [],
      current: ''
    };
  },
  mounted() {
    this.getUserDetail();
    this.$store.dispatch('cities/fetchCities');
  },
  computed: {
    ...mapState(['cities']),
    ...mapState(['districts']),
    ...mapState(['areas'])
  },
  methods: {
    getDistrictList() {
      this.$store.dispatch('districts/fetchDistricts', this.city_id);
    },
    getAreaList() {
      this.$store.dispatch('areas/fetchAreas', this.district_id);
    },
    getUserDetail() {
      this.loading = true;
      this.$axios
        .get(`/customers/${this.$route.params.id}`)
        .then((response) => {
          if (response.data.status) {
            this.user = response.data.responseData;
            this.user.phones.filter((item) =>
              item.is_default === true ? (this.user.mobile = item.phone) : ''
            );
            this.user_cars = this.user.cars;
            this.user_car = this.user.cars.length > 0 ? this.user.cars[0] : {};
            this.loading = false;
            this.name = response.data.responseData.name;
            this.surname = response.data.responseData.surname;
            this.mobile = this.user.mobile;
            this.email = this.user.email;
            this.phone2 = this.user.phones[1] ? this.user.phones[1].phone : '';
            this.phone3 = this.user.phones[2] ? this.user.phones[2].phone : '';
            this.user_address =
              this.user.addresses.length > 0 ? this.user.addresses[0] : {};
            this.city_id =
              this.user.addresses.length > 0
                ? this.user.addresses[0].city.id
                : null;
            this.identity_number =
              this.user.addresses.length > 0
                ? this.user.addresses[0].identity_number
                : null;
            if (this.city_id) {
              this.$store.dispatch('districts/fetchDistricts', this.city_id);
            }
            this.district_id =
              this.user.addresses.length > 0
                ? this.user.addresses[0].district.id
                : null;
            if (this.district_id) {
              this.$store.dispatch('areas/fetchAreas', this.district_id);
            }
            this.area_id =
              this.user.addresses.length > 0
                ? this.user.addresses[0].area.id
                : null;
            this.address =
              this.user.addresses.length > 0
                ? this.user.addresses[0].address
                : '';
          }
        })
        .catch((error) => {
          var message = error.response.data.responseData.error
            ? error.response.data.responseData.error[0].message
            : error.response.data.responseData.message;
          this.$swal.fire({
            iconHtml: '<img src="/img/error.svg" width="32" height="32">',
            position: 'top-end',
            title: message,
            showConfirmButton: false,
            timer: 2500,
            toast: true,
            customClass: {
              icon: 'swal2-no-border'
            }
          });
        });
    },
    updateUserandAddress() {
      this.$refs.userUpdateRules.validate().then((success) => {
        if (success) {
          this.$axios
            .put(`/customers/addresses/${this.user_address.id}`, {
              is_default: this.user_address.is_default,
              is_active: this.user_address.is_active,
              invoice_type_id: this.user_address.invoiceType.id,
              name: this.name,
              surname: this.surname,
              email: this.email,
              phone: this.mobile,
              identity_number: this.identity_number,
              tax_office: this.user.tax_office,
              tax_number: this.user.tax_number,
              city_id: this.city_id,
              district_id: this.district_id,
              area_id: this.area_id,
              address: this.address
            })
            .then((response) => {
              if (response.data.status) {
                this.updateUser();
              }
            })
            .catch((error) => {
              var message = error.response.data.responseData.error
                ? error.response.data.responseData.error[0].message
                : error.response.data.responseData.message;
              this.$swal.fire({
                iconHtml: '<img src="/img/error.svg" width="32" height="32">',
                position: 'top-end',
                title: message,
                showConfirmButton: false,
                timer: 2500,
                toast: true,
                customClass: {
                  icon: 'swal2-no-border'
                }
              });
            });
        } else {
          this.$swal.fire({
            iconHtml: '<img src="/img/error.svg" width="32" height="32">',
            position: 'top-end',
            title: this.$i18n.t('Please fill the required fields.'),
            showConfirmButton: false,
            timer: 2500,
            toast: true,
            customClass: {
              icon: 'swal2-no-border'
            }
          });
        }
      });
    },
    updateUser() {
      this.$axios
        .put(`/customers/${this.user.id}`, {
          name: this.name,
          surname: this.surname,
          email: this.email,
          mobile: this.mobile,
          customer_type_id: this.user.customerType.id
        })
        .then((response) => {
          if (response.data.status) {
            this.$swal.fire({
              iconHtml: '<img src="/img/success.svg" width="32" height="32">',
              position: 'top-end',
              title: this.$i18n.t(
                'Customer information has been successfully updated.'
              ),
              showConfirmButton: false,
              timer: 2500,
              toast: true,
              customClass: {
                icon: 'swal2-no-border'
              }
            });
            this.user_edit = false;
            this.getUserDetail();
          }
        });
    }
  }
};
</script>

<style scoped>
.default-phone-badge {
  background: #1c1c1c;
  color: #f4f4f4;
  padding: 6px 12px;
  border-radius: 5px;
  font-size: 10px;
  margin-left: 0.5rem;
}
.add-more-number-button span {
  margin: 4px 0;
  background: rgba(211, 211, 211, 0.6);
  padding: 8px;
  border-radius: 5px;
}

#customer-detail-edit-btn i,
#edit-btn i {
  background-color: rgba(211, 211, 211, 0.35);
  padding: 5px 5px 6px 6px;
  color: #777;
  border-radius: 5px;
}

.update button {
  transition: 0.5s;
  margin-top: 24px;
  background: var(--servislet-black);
  color: var(--servislet-white);
  border: 1px solid var(--servislet-black);
  outline: none;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.4px;
  padding: 8px 24px;
}

.update button:hover {
  transition: 0.5s;
  background: var(--servislet-black);
  color: var(--servislet-white);
  box-shadow: rgba(28, 28, 28, 0.2) 0px 8px 30px 0px;
}
</style>
<style>
#address-textarea {
  background-color: transparent !important;
}
</style>
