<template>
  <DefaultLayout>
    <div>
      <PageBreadcrumbs :breadcrumbs="breadcrumbs" />
      <div
        class="row"
        v-if="!loader">
        <div class="col-lg-9">
          <div
            class="product-detail-left-wrapper"
            v-if="!preview">
            <div class="products-double-flex">
              <div class="products-double-flex-left">
                <p class="page-title">#{{ offer.id }} {{ $t('Offer') }}</p>
              </div>
            </div>
            <b-tabs
              active-nav-item-class="product-detail-tabs-active"
              content-class="sales-detail-tabs-content mt-2"
              class="product-detail-tabs mt-3">
              <b-tab
                :title="$t('Offer Detail')"
                active>
                <div class="sales-detail-card">
                  <p class="sales-detail-card-title">{{ $t('Customer') }}</p>

                  <div class="product-detail-form-flex">
                    <div class="product-detail-form-flex-left">
                      <div class="sales-detail-item">
                        <p class="sales-detail-item-title">
                          {{ $t('Name') }} {{ $t('Surname') }}
                        </p>
                        <p class="sales-detail-item-info" v-if="offer.customer">
                          {{
                            offer.customer.name + ' ' + offer.customer.surname
                          }}
                        </p>
                        <p class="sales-detail-item-info" v-else>
                          -
                        </p>
                      </div>
                      <div class="sales-detail-item">
                        <p class="sales-detail-item-title">
                          {{ $t('E-Mail') }}
                        </p>
                        <p class="sales-detail-item-info" v-if="offer.customer">
                          {{ offer.customer.email }}
                        </p>
                        <p class="sales-detail-item-info" v-else>
                          -
                        </p>
                      </div>
                      <div class="sales-detail-item no-border">
                        <p class="sales-detail-item-title">{{ $t('Phone') }}</p>
                        <p class="sales-detail-item-info" v-if="offer.customer">
                          {{ offer.customer.phone }}
                        </p>
                        <p class="sales-detail-item-info" v-else>
                          -
                        </p>
                      </div>
                    </div>
                    <div class="product-detail-form-flex-right">
                      <div class="sales-detail-item">
                        <p class="sales-detail-item-title">
                          {{ $t('City') }} / {{ $t('District') }}
                        </p>
                        <p class="sales-detail-item-info" v-if="offer.customer">
                          {{
                            offer.customer.city.name +
                            ' / ' +
                            offer.customer.district.name
                          }}
                        </p>
                        <p class="sales-detail-item-info" v-else>
                          -
                        </p>
                      </div>
                      <div class="sales-detail-item">
                        <p class="sales-detail-item-title">
                          {{ $t('Neighborhood') }}
                        </p>
                        <p class="sales-detail-item-info" v-if="offer.customer">
                          {{ offer.customer.district.name }}
                        </p>
                        <p class="sales-detail-item-info" v-else>
                          -
                        </p>
                      </div>
                      <div class="sales-detail-item no-border">
                        <p class="sales-detail-item-title">
                          {{ $t('Address Definition') }}
                        </p>
                        <p class="sales-detail-item-info" v-if="offer.customer">
                          {{ offer.customer.address }}
                        </p>
                        <p class="sales-detail-item-info" v-else>
                          -
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="sales-detail-card">
                  <p class="sales-detail-card-title">{{ $t('Car') }}</p>

                  <div class="product-detail-form-flex">
                    <div class="product-detail-form-flex-left">
                      <div class="sales-detail-item">
                        <p class="sales-detail-item-title">{{ $t('Plate') }}</p>
                        <p class="sales-detail-item-info" v-if="offer.customer && offer.customer.car">
                          {{ offer.customer.car.plate_number }}
                        </p>
                        <p class="sales-detail-item-info" v-else>
                          -
                        </p>
                      </div>
                      <div class="sales-detail-item">
                        <p class="sales-detail-item-title">
                          {{ $t('Chassis No.') }}
                        </p>
                        <p class="sales-detail-item-info" v-if="offer.customer && offer.customer.car">
                          {{ offer.customer.car.chassis_number }}
                        </p>
                        <p class="sales-detail-item-info" v-else>
                          -
                        </p>
                      </div>
                      <div class="sales-detail-item no-border">
                        <p class="sales-detail-item-title">{{ $t('Fuel') }}</p>
                        <p class="sales-detail-item-info" v-if="offer.customer && offer.customer.car">
                          {{ offer.customer.car.fuel_type }}
                        </p>
                        <p class="sales-detail-item-info" v-else>
                          -
                        </p>
                      </div>
                    </div>
                    <div class="product-detail-form-flex-right">
                      <div class="sales-detail-item">
                        <p class="sales-detail-item-title">{{ $t('Brand') }}</p>
                        <p class="sales-detail-item-info" v-if="offer.customer && offer.customer.car">
                          {{ offer.customer.car.brand.name }}
                        </p>
                        <p class="sales-detail-item-info" v-else>
                          -
                        </p>
                      </div>
                      <div class="sales-detail-item">
                        <p class="sales-detail-item-title">
                          Model / {{ $t('Version') }}
                        </p>
                        <p class="sales-detail-item-info" v-if="offer.customer && offer.customer.car">
                          {{
                            offer.customer.car.version +
                            ' / ' +
                            offer.customer.car.version_type
                          }}
                        </p>
                        <p class="sales-detail-item-info" v-else>
                          -
                        </p>
                      </div>
                      <div class="sales-detail-item no-border">
                        <p class="sales-detail-item-title">{{ $t('Year') }}</p>
                        <p class="sales-detail-item-info" v-if="offer.customer && offer.customer.car">
                          {{ offer.customer.car.year }}
                        </p>
                        <p class="sales-detail-item-info" v-else>
                          -
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="sales-detail-card">
                  <p class="sales-detail-card-title">{{ $t('Sales') }}</p>

                  <div class="sales-detail-card-table">
                    <table>
                      <tr class="sales-detail-card-table-head">
                        <th>{{ $t('Product or Service') }}</th>
                        <th>{{ $t('Count') }}</th>
                        <th>{{ $t('Amount') }}</th>
                        <th>{{ $t('Total') }}</th>
                      </tr>
                      <tr
                        v-for="item in offer.items"
                        :key="item.$index">
                        <td>-</td>
                        <td>{{ item.quantity }}</td>
                        <td>{{ formatPrice(item.price * 1) }}</td>
                        <td>{{ formatPrice(item.price * item.quantity) }}</td>
                      </tr>
                      <tr class="sales-detail-table-sub-total">
                        <td></td>
                        <td></td>

                        <td>{{ $t('Sub total') }}</td>
                        <td class="sales-detail-table-sub-total-res">
                          {{ formatPrice(offer.total_amount) }}
                        </td>
                      </tr>
                      <tr class="sales-detail-table-vat">
                        <td></td>
                        <td></td>

                        <td>{{ $t('VAT') }}</td>
                        <td class="sales-detail-table-sub-total-res">
                          {{ formatPrice(offer.total_tax) }}
                        </td>
                      </tr>
                      <tr class="sales-detail-table-total">
                        <td></td>
                        <td></td>

                        <td>{{ $t('Total') }}</td>
                        <td>{{ formatPrice(offer.total_price) }}</td>
                      </tr>
                    </table>
                  </div>
                </div>
              </b-tab>
              <b-tab :title="$t('Offer History')">
                <!-- <div class="sales-detail-history-flex">
                    <div>
                        <table>
                            <tr>
                                <td>Tarih</td>
                                <td>Saat</td>
                            </tr>
                            <tr v-for="item in 5" :key="item">
                                <td>18/07/2022</td>
                                <td>19:02:40</td>
                            </tr>
                        </table>
                    </div>
                    <div>
                        <table>
                            <tr>
                                <td>Sipariş Durumu</td>
                            </tr>
                            <tr v-for="item in 5" :key="item">
                                <td>Satış fiyatı güncellendi</td>
                            </tr>
                        </table>
                    </div>
                </div> -->
              </b-tab>
            </b-tabs>
          </div>
          <!--  -->
          <div
            class="product-detail-left-wrapper"
            v-if="preview === true">
            <div id="offer-preview">
              <div class="d-flex align-items-start justify-content-between">
                <div class="offer-preview-head-left">
                  <img
                    alt="Servislet Pro"
                    class="offer-logo p-0"
                    src="../../assets/images/logo.png" />

                  <div class="offer-dealer-info mt-5">
                    <p class="offer-dealer-name">
                      {{ offer.branch ? offer.branch.company_title : '' }}
                    </p>
                    <p v-if="offer.branch && offer.branch.city_name">
                      {{
                        offer.branch.address +
                        ' ' +
                        offer.branch.city_name +
                        ' / ' +
                        offer.branch.district_name
                      }}
                    </p>
                    <div
                      class="offer-dealer-contact-info flex-center-start mt-3">
                      <span>{{ offer.branch ? offer.branch.email  : '' }}</span>
                      <span>{{ offer.branch ? ' | '+offer.branch.phone : ''  }}</span>
                    </div>
                  </div>
                </div>
                <div class="offer-preview-head-right">
                  <p class="offer-id">
                    {{ $t('Offer') }} ID: <b>#{{ offer.id }}</b>
                  </p>

                  <div class="offer-dealer-info mt-5">
                    <p class="offer-dealer-name">
                      {{ offer.customer.name + ' ' + offer.customer.surname }}
                    </p>
                    <p>{{ offer.customer.email }}</p>
                    <p class="mt-2">{{ offer.customer.phone }}</p>
                  </div>
                </div>
              </div>
              <div class="offer-dates mt-5">
                <p>{{ $t('Offer Date') }}: <span>-</span></p>
                <p>
                  {{ $t('Offer') }} {{ $t('End Date') }}:
                  <span>{{
                    offer.expiration_at.substring(
                      0,
                      offer.expiration_at.length - 8
                    )
                  }}</span>
                </p>
              </div>
              <table class="mt-4">
                <tr class="offer-preview-table-head">
                  <th style="text-transform: uppercase">
                    {{ $t('Offer') }} / {{ $t('Service') }}
                  </th>
                  <th style="text-transform: uppercase">{{ $t('Count') }}</th>
                  <th style="text-transform: uppercase">{{ $t('VAT') }}</th>
                  <th
                    style="text-transform: uppercase"
                    class="text-right">
                    {{ $t('Total') }}
                  </th>
                </tr>
                <tr
                  v-for="item in offer.items"
                  :key="item.$index">
                  <td>-</td>
                  <td>{{ item.quantity }}</td>
                  <td>{{ formatPrice(item.tax_amount) }}</td>
                  <td class="text-right">{{ formatPrice(item.price) }}</td>
                </tr>
              </table>
              <div class="flex-start-space mt-4">
                <div class="preview-sum-left">
                  <div class="preview-sum-item">
                    <p>
                      <b>{{ $t('Delivery') }}:</b> <br />
                      <span v-if="offer.cargo_delivery">{{
                        $t('Delivery by cargo')
                      }}</span>
                      <span v-else>{{ $t('Montage') }}</span>
                    </p>
                  </div>
                  <div class="preview-sum-item">
                    <p>
                      <b>{{ $t('Payment Type') }}:</b> <br />
                      -
                    </p>
                  </div>
                  <div class="preview-sum-item">
                    <p>
                      <b>{{ $t('Notes') }}:</b> <br />
                      {{ offer.notes }}
                    </p>
                  </div>
                </div>
                <div class="preview-sum-right">
                  <div class="offer-bill-section">
                    <div class="offer-bill-item">
                      <p>{{ $t('Sub Total') }}:</p>
                      <p>{{ formatPrice(offer.total_amount) }}</p>
                    </div>
                    <div class="offer-bill-item">
                      <p>{{ $t('VAT') }}:</p>
                      <p>{{ formatPrice(offer.total_tax) }}</p>
                    </div>
                    <div class="offer-bill-item offer-bill-item-total">
                      <p>{{ $t('Total') }}:</p>
                      <p>{{ formatPrice(offer.total_price) }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--  -->
        </div>
        <div class="col-lg-3">
          <div class="product-detail-right-wrapper">
            <b-form-group
              id="channel-group"
              :label="$t('Offer Status')+':'"
              label-for="channel">
              <b-form-select
                v-model="selected"
                :options="options"
                class="sales-status-select-completed"></b-form-select>
            </b-form-group>

            <b-button
              class="mt-1"
              @click="preview = true"
              v-if="!preview"
              >{{ $t('Offer Preview') }}</b-button
            >
            <b-button
              class="mt-1"
              @click="preview = false"
              v-if="preview"
              >{{ $t('Close Preview') }}</b-button
            >
          </div>
        </div>
      </div>
      <div
        class="row"
        v-if="loader">
        <content-placeholders :rounded="true">
          <content-placeholders-img />
          <content-placeholders-text :lines="10" />
        </content-placeholders>
      </div>
    </div>
  </DefaultLayout>
</template>

<script>
/* eslint-disable */
import DefaultLayout from '@/layouts/default';
import PageBreadcrumbs from '../../components/PageBreadcrumbs.vue';
import StarRating from '../../components/StarRating.vue';

export default {
  components: {
    PageBreadcrumbs,
    StarRating,
    DefaultLayout
  },
  data() {
    return {
      breadcrumbs: [
        {
          name: this.$i18n.t('Offers'),
          path: '/#'
        },
        {
          name: '#'
        }
      ],
      form: {
        stock: '',
        dot: '',
        salesPrice: '',
        paymemnt: '',
        commission: ''
      },
      selected: null,
      options: [
        { value: null, text: this.$i18n.t('Completed'), disabled: true }
      ],
      offer: {},
      loader: false,
      preview: false
    };
  },
  mounted() {
    this.getOffer();
  },
  methods: {
    getOffer() {
      this.loader = true;
      this.$axios
        .get(`/tire/offers/${this.$route.params.id}`, {})
        .then((response) => {
          this.offer = response.data.responseData.tireOffer;
          this.breadcrumbs[1].name = '#' + this.offer.id;
          this.loader = false;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    formatPrice(price) {
      price = price * 1;
      return price.toLocaleString('tr-TR', {
        style: 'currency',
        currency: 'TRY'
      });
    }
  }
};
</script>

<style scoped>
.offer-logo{
  width: 144px;
}
</style>
