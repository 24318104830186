// store/carBrands.js

import Vue from 'vue';
import Vuex from 'vuex';
// import axios from 'axios';
import axios from '@/plugins/axios';

// const TOKEN = localStorage.userData ? 'Bearer '+JSON.parse(localStorage.userData).access_token : ''
// const API_BASE_URL = 'https://staging-pro-api.servislet.com/api/v1/web/main'

Vue.use(Vuex);

const state = {
  customerTypes: []
};

const mutations = {
  setCustomerTypesData(state, data) {
    state.customerTypes = data;
  }
};

const actions = {
  fetchCustomerTypes({ commit }) {
    axios
      .get('/customer-types', {})
      .then((response) => {
        commit(
          'setCustomerTypesData',
          response.data.responseData.customerTypes
        );
      })
      .catch((error) => {
        console.error(error);
      });
  }
};

const getters = {
  getCustomerTypesData(state) {
    return state.customerTypes;
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
