<template>
  <div class="page-widget-card">
    <i
      class="fa-solid fa-angle-right"
      id="widget-cursor"
      v-if="cursorIcon"></i>
    <p class="page-widget-title">
      {{ title }}
      <i
        class="fa-solid fa-info-circle"
        v-if="tooltipIcon"
        v-b-tooltip.hover
        :title="tooltipText"></i>
    </p>
    <div class="page-widgets-flex">
      <p class="page-widgets-data">{{ mainData }}</p>
    </div>
    <p class="page-widget-text">
      <i
        class="fa-regular fa-arrow-trend-up"
        v-if="icon === true"></i>
      <span> {{ number }} </span>
      {{ text }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    mainData: {
      type: String,
      default: ''
    },
    number: {
      type: String,
      default: ''
    },
    icon: {
      type: Boolean,
      default: false
    },
    text: {
      type: String,
      default: ''
    },
    cursorIcon: {
      type: Boolean,
      default: false
    },
    tooltipIcon: {
      type: Boolean,
      default: false
    },
    tooltipText: {
      type: String,
      default: ''
    }
  }
};
</script>

<style scoped>
.page-widget-text i,
.page-widget-text svg {
  color: #06a5d0;
}
</style>
