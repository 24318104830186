<template>
  <div>
    <PageBreadcrumbs :breadcrumbs="breadcrumbs" />
    <div class="row">
      <div class="col-lg-9">
        <div class="product-detail-left-wrapper">
          <div class="products-double-flex">
            <div class="products-double-flex-left">
              <p class="page-title">{{ $t('Customer') }}</p>
            </div>
          </div>
          <div class="add-invoice mt-4">
            <div
              class="products-double-flex"
              v-if="selected_customer !== 1 && selected_customer !== 2">
              <b-input-group class="add-invoice-select-customer-wrapper">
                <b-form-group
                  id="channel-group"
                  label="">
                  <b-form-select
                    v-model="selected_customer"
                    :options="customers"></b-form-select>
                </b-form-group>
                <b-input-group-append>
                  <button v-b-modal.customer-add-modal>
                    <i class="fa-solid fa-plus"></i>
                  </button>
                </b-input-group-append>
              </b-input-group>
              <div>
                <p>#128731</p>
                <p>10.09.2022</p>
              </div>
            </div>
            <!-- customer add modal -->
            <b-modal
              id="customer-add-modal"
              size="xl"
              centered
              hide-footer>
              <template #modal-title>
                <div class="flex-start-space">
                  <div>
                    <p class="customer-add-modal-title">
                      {{ $t('Add New Customer') }}
                    </p>
                    <p class="customer-add-modal-text">
                      Lorem ipsum dolor sit amet consectetur.
                    </p>
                  </div>
                  <div>
                    <b-form-group>
                      <b-form-select
                        v-model="selected"
                        :options="customers"></b-form-select>
                    </b-form-group>
                  </div>
                </div>
              </template>
              <div class="flex-center-space">
                <b-form-group
                  :label="$t('Name Surname') + ' *'"
                  class="mr-6">
                  <b-form-input
                    :placeholder="$t('Name Surname')"></b-form-input>
                </b-form-group>
                <b-form-group :label="$t('Mobile') + ' *'">
                  <b-input-group>
                    <b-form-input
                      :placeholder="$t('Mobile')"
                      v-mask="'+90 (###) ### ## ##'"></b-form-input>
                    <b-input-group-append>
                      <button
                        class="add-more-number-btn"
                        @click="second_number = true">
                        <i class="fa-solid fa-plus mr-6"></i>
                        {{ $t('Add Number') }}
                      </button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </div>
              <div
                class="flex-center-space"
                v-if="second_number === true">
                <b-form-group
                  :label="$t('Mobile') + ' 2'"
                  class="mr-6">
                  <b-input-group>
                    <b-form-input
                      :placeholder="$t('Mobile') + ' 2'"
                      v-mask="'+90 (###) ### ## ##'"></b-form-input>
                    <b-input-group-append>
                      <button
                        class="add-more-number-btn"
                        @click="third_number = true">
                        <i class="fa-solid fa-plus mr-6"></i>
                        {{ $t('Add Number') }}
                      </button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>

                <b-form-group
                  style="visibility: hidden"
                  v-if="third_number === false">
                  <b-form-input></b-form-input>
                </b-form-group>

                <b-form-group
                  :label="$t('Mobile') + ' 3'"
                  v-if="third_number === true">
                  <b-input-group>
                    <b-form-input
                      :placeholder="$t('Mobile') + ' 3'"
                      v-mask="'+90 (###) ### ## ##'"></b-form-input>
                  </b-input-group>
                </b-form-group>
              </div>
              <div class="flex-center-space">
                <b-form-group
                  :label="$t('E-Mail') + ' *'"
                  class="mr-6">
                  <b-form-input :placeholder="$t('E-Mail')"></b-form-input>
                </b-form-group>
                <b-form-group :label="$t('Company Title') + ' *'">
                  <b-form-input
                    :placeholder="$t('Company Title')"></b-form-input>
                </b-form-group>
              </div>
              <div class="flex-center-space">
                <b-form-group
                  :label="$t('Tax Administration') + ' *'"
                  class="mr-6">
                  <b-form-input
                    :placeholder="$t('Tax Administration')"></b-form-input>
                </b-form-group>
                <b-form-group :label="$t('Company Title') + ' *'">
                  <b-form-input
                    :placeholder="$t('Company Title')"></b-form-input>
                </b-form-group>
              </div>
              <p class="customer-add-modal-title">
                {{ $t('Address Informations') }}
              </p>
              <div class="flex-center-space">
                <b-form-group
                  :label="$t('City')"
                  class="mr-6">
                  <b-form-select
                    v-model="selected"
                    :options="options"></b-form-select>
                </b-form-group>
                <b-form-group
                  :label="$t('District')"
                  class="mr-6">
                  <b-form-select
                    v-model="selected"
                    :options="options"></b-form-select>
                </b-form-group>
                <b-form-group :label="$t('Neighborhood') + '/' + $t('Street')">
                  <b-form-select
                    v-model="selected"
                    :options="options"></b-form-select>
                </b-form-group>
              </div>
              <div>
                <b-form-group :label="$t('Address Definition')">
                  <b-form-textarea
                    :placeholder="$t('Address Definition')"
                    rows="3"
                    max-rows="6"></b-form-textarea>
                </b-form-group>
              </div>
              <div
                class="flex-center-space"
                id="modal-foot">
                <div></div>
                <div>
                  <button
                    class="modal-foot-deny"
                    @click="hideCustomerModal">
                    {{ $t('Skip') }}
                  </button>
                  <button
                    class="modal-foot-save"
                    @click="showAlert">
                    {{ $t('Save') }}
                  </button>
                </div>
              </div>
            </b-modal>
            <!-- end of customer add modal -->
            <div id="add-invoice-customer-wrapper">
              <div
                class="add-invoice-customer-wrapper"
                v-if="selected_customer === 1">
                <div class="add-invoice-customer-card">
                  <div class="add-invoice-customer-info">
                    <i class="fa-solid fa-database"></i>
                    <div>
                      <p>İsmail Abi</p>
                      <p>0541 850 60 70</p>
                      <p>Servislet Yazılım A.Ş</p>
                    </div>
                  </div>
                  <div class="add-invoice-vehicle-info">
                    <i class="fa-solid fa-car"></i>
                    <div>
                      <p>06 SZM 685</p>
                      <p>Ferrari 2022 F8 Spyder</p>
                    </div>
                  </div>
                  <div
                    class="remove-add-invoice-customer-card"
                    @click="selected_customer = null">
                    <i class="fa-solid fa-close"></i>
                  </div>
                </div>
              </div>
              <div
                class="add-invoice-customer-wrapper"
                v-if="selected_customer === 2">
                2
              </div>
            </div>
            <div class="add-invoice-inputs">
              <p class="page-title">
                {{ $t('Product') }} / {{ $t('Service') }}
              </p>

              <div id="invoice-loop-item">
                <div class="add-invoice-flex">
                  <b-form-group
                    id="product-or-service-selector-wrapper"
                    :label="$t('Choose product or service')">
                    <b-form-select
                      v-model="selected"
                      :options="options"></b-form-select>
                  </b-form-group>

                  <b-form-group
                    id="dynamic-unit-group"
                    class="add-invoice-flex-amount-wrapper"
                    :label="$t('Quantity')">
                    <b-input-group>
                      <b-form-input value="10"></b-form-input>
                      <b-input-group-append>
                        <b-form-select
                          v-model="selected"
                          :options="unit"></b-form-select>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>

                  <b-form-group
                    id="dynamic-unit-group"
                    class="add-invoice-flex-price-wrapper"
                    :label="$t('Amount')">
                    <b-input-group>
                      <b-form-input value="100,00"></b-form-input>
                      <b-input-group-append>
                        <b-form-select
                          v-model="selected"
                          :options="currency"></b-form-select>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>

                  <b-form-group
                    id="dynamic-unit-group"
                    class="add-invoice-flex-vat-wrapper"
                    :label="$t('VAT')">
                    <b-input-group>
                      <b-form-input value="10"></b-form-input>
                      <b-input-group-append>
                        <b-form-select
                          v-model="selected"
                          :options="currency"></b-form-select>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>

                  <b-form-group
                    id="dynamic-unit-group"
                    class="add-invoice-flex-total-wrapper"
                    :label="$t('Total')">
                    <b-input-group>
                      <b-form-input value="120,00"></b-form-input>
                      <b-input-group-append>
                        <b-form-select
                          v-model="selected"
                          :options="currency"></b-form-select>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>

                  <b-form-group
                    id="add-invoice-inline-btn-group"
                    class="add-invoice-flex-button-wrapper"
                    label="">
                    <b-dropdown right>
                      <template #button-content>
                        <i class="fa-solid fa-plus"></i>
                      </template>
                      <b-dropdown-item
                        class="drop-btn-content"
                        href="#">
                        <span @click="discount = true">
                          <i class="fa-solid fa-percent"></i>
                          {{ $t('Add Discount') }}
                        </span>
                      </b-dropdown-item>
                      <b-dropdown-item
                        class="drop-btn-content"
                        href="#">
                        <i class="fa-solid fa-file"></i>
                        {{ $t('Add Description') }}
                      </b-dropdown-item>
                    </b-dropdown>

                    <button class="no-bg">
                      <i class="fa-solid fa-trash"></i>
                    </button>
                  </b-form-group>
                </div>

                <div
                  class="products-double-flex m-0 no-border max-width-743 align-items-flex-start">
                  <b-form-group :label="$t('Description') + ':'">
                    <b-form-textarea
                      :placeholder="$t('Description')"
                      rows="3"
                      max-rows="6"></b-form-textarea>
                  </b-form-group>

                  <!-- <b-form-group id="dynamic-unit-group" class="add-invoice-flex-total-wrapper" label="İndirim" v-if="discount === true">
                                        <b-input-group>
                                            <b-form-input value="20,00"></b-form-input>
                                            <b-input-group-append>
                                            <b-form-select v-model="selected" :options="discount_unit"></b-form-select>
                                            </b-input-group-append>
                                        </b-input-group>
                                    </b-form-group> -->
                  <b-form-group
                    id="dynamic-unit-group"
                    class="add-invoice-flex-total-wrapper max-width-172"
                    :label="$t('Discount')"
                    v-if="discount === true">
                    <b-input-group>
                      <b-form-input value="20,00"></b-form-input>
                      <b-input-group-append>
                        <b-form-select
                          v-model="selected"
                          :options="discount_unit"></b-form-select>
                      </b-input-group-append>
                      <button @click="discount = false">
                        <i class="fa-solid fa-close"></i>
                      </button>
                    </b-input-group>
                  </b-form-group>
                </div>
              </div>

              <b-button class="add-more mt-3">
                <i class="fa-solid fa-plus"></i>
                {{ $t('Product / Service Add') }}
              </b-button>

              <div class="products-double-flex invoice-bill-section-wrapper">
                <div></div>
                <div class="invoice-bill-section">
                  <div class="invoice-bill-item">
                    <p>{{ $t('Sub Total') }}:</p>
                    <p>
                      ₺1.800
                      <button @click="sub_discount = true">
                        <i class="fa-solid fa-plus"></i>
                      </button>
                    </p>
                  </div>
                  <div
                    class="invoice-bill-item"
                    v-if="sub_discount === true">
                    <p>{{ $t('Sub Total Discount') }}:</p>
                    <div class="flex-center-space">
                      <b-input-group style="max-width: 150px">
                        <b-form-input value="20,00"></b-form-input>
                        <b-input-group-append>
                          <b-form-select
                            v-model="selected"
                            :options="discount_unit"></b-form-select>
                        </b-input-group-append>
                      </b-input-group>
                      <button @click="sub_discount = false">
                        <i class="fa-solid fa-close"></i>
                      </button>
                    </div>
                  </div>
                  <div
                    class="invoice-bill-item"
                    v-if="discount === true">
                    <p>{{ $t('Line Discount') }}:</p>
                    <p>₺20.00</p>
                  </div>
                  <div class="invoice-bill-item">
                    <p>{{ $t('VAT') }}:</p>
                    <p>₺1.000</p>
                  </div>
                  <div class="invoice-bill-item invoice-bill-item-total">
                    <p>{{ $t('Total') }}:</p>
                    <p>₺2.000</p>
                  </div>
                </div>
              </div>

              <div class="incoive-notes-section">
                <b-form-group :label="$t('Note') + ':'">
                  <b-form-textarea
                    rows="3"
                    max-rows="6"></b-form-textarea>
                </b-form-group>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3">
        <div class="product-detail-right-wrapper">
          <b-button>{{ $t('Save') }}</b-button>
          <b-button class="add-invoice-prev-btn mt-2">{{
            $t('Preview')
          }}</b-button>

          <div class="add-invoice-right-checkboxes">
            <span>{{ $t('Payment') }}</span>
            <b-form-checkbox
              id="product-detail-checkbox"
              value="open_stock"
              unchecked-value="close_stock"></b-form-checkbox>
          </div>

          <b-form-group
            id="channel-group"
            :label="$t('Payment Channel') + ':'">
            <b-form-select
              v-model="selected"
              :options="payment_c"
              class="add-invoice-payment-c-selector"></b-form-select>
          </b-form-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import PageBreadcrumbs from '../../components/PageBreadcrumbs.vue';
import StarRating from '../../components/StarRating.vue';
export default {
  components: {
    PageBreadcrumbs,
    StarRating
  },
  data() {
    return {
      breadcrumbs: [
        {
          name: this.$i18n.t('Incoming Invoices'),
          path: '/#'
        },
        {
          name: this.$i18n.t('Add Invoice'),
          path: '/#'
        }
      ],
      form: {
        stock: '',
        dot: '',
        salesPrice: '',
        paymemnt: '',
        commission: ''
      },
      selected: null,
      selected_customer: null,
      customers: [
        {
          value: null,
          text: this.$i18n.t('Choose Customer Information'),
          disabled: true
        },
        { value: 1, text: this.$i18n.t('Corporate Customer') + ' X' },
        { value: 2, text: this.$i18n.t('Individual Customer') + ' X' }
      ],
      options: [{ value: null, text: 'Options', disabled: true }],
      unit: [{ value: null, text: '| adet', disabled: true }],
      currency: [
        { value: null, text: '₺', disabled: true },
        ,
        { value: null, text: '$', disabled: true }
      ],
      vat: [{ value: null, text: '18', disabled: true }, ,],
      discount_unit: [
        { value: null, text: '₺', disabled: true },
        ,
        { value: null, text: '%', disabled: true }
      ],
      payment_c: [
        { value: null, text: this.$i18n.t('Physical P.O.S'), disabled: true },
        ,
        { value: null, text: this.$i18n.t('Virtual POS'), disabled: true },
        { value: null, text: this.$i18n.t('Cash'), disabled: true },
        { value: null, text: this.$i18n.t('Garanti Bank'), disabled: true },
        { value: null, text: this.$i18n.t('İş Bank'), disabled: true },
        { value: null, text: this.$i18n.t('Ziraat Bank'), disabled: true }
      ],
      discount: false,
      sub_discount: false,
      second_number: false,
      third_number: false
    };
  },
  methods: {
    hideCustomerModal() {
      this.$bvModal.hide('customer-add-modal');
    },
    showAlert() {
      this.$bvModal.hide('customer-add-modal');
      // Use sweetalert2
      this.$swal.fire({
        iconHtml: '<img src="/img/success.svg" width="32" height="32">',
        title: this.$i18n.t('Customer successfully added.'),
        text: '',
        showConfirmButton: false,
        timer: 1500,
        toast: true,
        customClass: {
          icon: 'swal2-no-border'
        }
      });
    }
  }
};
</script>

<style></style>
