<template>
  <DefaultLayout>
    <div>
      <PageBreadcrumbs :breadcrumbs="breadcrumbs" />
      <div
        v-if="!loader"
        id="products-listing-table-wrapper"
        class="py-4">
        <div class="products-double-flex">
          <div class="products-double-flex-left">
            <p class="page-title">{{ $t('Calendar') }}</p>
          </div>
          <div class="products-double-flex-right">
            <button
              class="add-new-product-btn"
              @click="$router.push({ name: 'AddAppointment' })">
              <i class="fa-solid fa-plus"></i>
              {{ $t('Add') }}
            </button>
          </div>
        </div>
        <!-- <span>{{message}}</span> -->
        <div
          v-if="!loader"
          id="calendar-wrapper"
          class="product-listing-table">
          <calendar-view
            :displayPeriodUom="displayPeriod"
            :enableDateSelection="true"
            :items="events"
            :locale="$i18n.locale"
            :selection-end="selectionEnd"
            :selection-start="selectionStart"
            :show-date="showDate"
            :weeks="true"
            class="theme-default holiday-us-traditional holiday-us-official"
            @click-date="onClickDay"
            @click-item="onClickItem"
            @date-selection-start="setSelection"
            @date-selection="setSelection"
            @date-selection-finish="finishSelection">
            <template #header="{ headerProps }">
              <div class="cv-header">
                <div class="cv-header-nav">
                  <button
                    aria-label="Previous Period"
                    class="previousPeriod"
                    @click.prevent="decreaseMonth">
                    <i class="fa-regular fa-chevron-left"></i>
                  </button>
                  <button
                    aria-label="Next Period"
                    class="nextPeriod"
                    @click.prevent="increaseMonth">
                    <i class="fa-regular fa-chevron-right"></i>
                  </button>
                </div>
                <div>
                  <div class="periodLabel">
                    <slot name="label">{{ headerProps.periodLabel }}</slot>
                  </div>
                </div>
                <div class="calendar-header-buttons">
                  <button @click="displayPeriod = 'month'">
                    {{ $t('Month') }}
                  </button>
                  <button @click="displayPeriod = 'week'">
                    {{ $t('Week') }}
                  </button>
                </div>
              </div>
            </template>
          </calendar-view>

          <b-modal
            :id="'event-modal-' + event"
            centered
            hide-footer
            hide-header
            size="l">
            <b-overlay
              :show="modal_loader"
              rounded="sm">
              <div v-if="Object.keys(eventDetails).length > 0">
                <div>
                  <p class="customer-add-modal-title">
                    {{ eventDetails.title }}
                  </p>
                  <p class="customer-add-modal-text">
                    {{ eventDetails.description }}
                  </p>
                </div>
                <div class="flex-center-space mt-4">
                  <b-form-group
                    :label="$t('Name Surname')"
                    class="mr-6 w-100">
                    <b-form-input
                      :value="
                        eventDetails.customer.name +
                        ' ' +
                        eventDetails.customer.surname
                      "
                      disabled></b-form-input>
                  </b-form-group>
                  <b-form-group
                    class="w-100"
                    label="E-mail">
                    <b-form-input
                      :value="eventDetails.customer.email"
                      disabled></b-form-input>
                  </b-form-group>
                </div>
                <div class="flex-center-space mt-2">
                  <b-form-group
                    class="mr-6 w-100"
                    :label="$t('Start Date')">
                    <b-form-input
                      :value="eventDetails.start_date.slice(0, -3)"
                      disabled></b-form-input>
                  </b-form-group>
                  <b-form-group
                    class="w-100"
                    :label="$t('End Date')">
                    <b-form-input
                      :value="eventDetails.end_date.slice(0, -3)"
                      disabled></b-form-input>
                  </b-form-group>
                </div>
                <div>
                  <button
                    class="modal-foot-save mt-2"
                    @click="routeAppointmentDetail()">
                    {{ $t('Edit') }}
                  </button>
                  <button
                    class="modal-foot-save mt-2 ml-2"
                    @click="openDeleteModal(eventDetails.id)">
                    {{ $t('Delete') }}
                  </button>
                </div>
              </div>
            </b-overlay>
          </b-modal>

          <b-modal
            id="delete-modal"
            ref="delete-modal"
            centered
            hide-footer
            hide-header
            size="md">
            <div class="delete-modal-body">
              <div class="delete-modal-header">
                <i class="fa-solid fa-xmark cursor-pointer"></i>
              </div>
              <div class="delete-modal-content-text">
                {{ $t('Are you sure you want to delete the event?') }}
              </div>
              <div class="delete-modal-buttons">
                <b-button
                  class="cancel-button"
                  @click="$refs['delete-modal'].hide()"
                  >{{ $t('Cancel') }}
                </b-button>
                <b-button
                  class="delete-button"
                  @click="deleteEvent()"
                  >{{ $t('Delete') }}
                </b-button>
              </div>
            </div>
          </b-modal>
        </div>
      </div>
      <div
        v-if="loader"
        id="products-listing-table-wrapper"
        class="py-4">
        <div
          v-if="loader"
          class="p-3 loader">
          <content-placeholders :rounded="true">
            <content-placeholders-img />
            <content-placeholders-text :lines="10" />
          </content-placeholders>
        </div>
      </div>
    </div>
  </DefaultLayout>
</template>

<script>
/* eslint-disable */
import DefaultLayout from '@/layouts/default';
import PageBreadcrumbs from '../../components/PageBreadcrumbs.vue';
// https://github.com/richardtallent/vue-simple-calendar
import { CalendarView, CalendarViewHeader } from 'vue-simple-calendar';
import '@/assets/css/calendar.css';

export default {
  components: {
    PageBreadcrumbs,
    CalendarView,
    CalendarViewHeader,
    DefaultLayout
  },
  data() {
    return {
      breadcrumbs: [
        {
          name: this.$i18n.t('Calendar')
        }
      ],
      //
      showDate: this.thisMonth(1),
      displayPeriod: 'month',
      message: '',
      loader: false,
      start_date: '',
      end_date: '',
      events: [],
      headerProps: {},
      currentMonth: new Date().getMonth(),
      event: '',
      modal_loader: true,
      eventDetails: {},
      selectionStart: null,
      selectionEnd: null,
      deleted_event_id: '',
      event_code: ''
    };
  },
  mounted() {
    this.getFirstDayOfMonth();
    this.getLastDayOfMonth();
    this.getEvents();
  },
  methods: {
    openDeleteModal(id) {
      this.deleted_event_id = id;
      this.$refs['delete-modal'].show();
    },
    deleteEvent() {
      this.$refs['delete-modal'].hide();
      this.$axios
        .delete(`/events/${this.deleted_event_id}`)
        .then((response) => {
          if (response.data.status) {
            this.$swal.fire({
              iconHtml: '<img src="/img/success.svg" width="32" height="32">',
              position: 'top-end',
              title: this.$i18n.t('Date successfully deleted.'),
              showConfirmButton: false,
              timer: 2500,
              toast: true,
              customClass: {
                icon: 'swal2-no-border'
              }
            });
            this.getEvents();
          }
        });
    },
    setSelection(dateRange) {
      this.selectionEnd = dateRange[1];
      this.selectionStart = dateRange[0];
    },
    finishSelection(dateRange) {
      this.setSelection(dateRange);
      this.message = `You selected: ${this.selectionStart.toLocaleDateString()} -${this.selectionEnd.toLocaleDateString()}`;
      var selectedDates = [];
      selectedDates.push(this.selectionStart, this.selectionEnd);
      this.$store.commit('selectedDate/setSelectedDates', selectedDates);
      console.log(this.$store.state.selectedDate.selectedDates);
    },
    getDaysInMonth(year, month) {
      return new Date(year, month, 0).getDate();
    },
    increaseMonth() {
      if (this.currentMonth <= 12) {
        this.currentMonth =
          this.currentMonth !== 12 ? this.currentMonth + 1 : 12;
        const t = new Date();
        this.showDate = new Date(t.getFullYear(), this.currentMonth);
        // Get the current date
        const currentDate = new Date();
        // Set the day of the month to 1
        currentDate.setDate(1);
        // Now `currentDate` represents the first day of the current month
        // Extract the relevant components (year, month, day)
        const year = currentDate.getFullYear();
        // const month = String(currentDate.getMonth() + 1).padStart(2, '0')
        const day = String(currentDate.getDate()).padStart(2, '0');
        const last_day = this.getDaysInMonth(year, this.currentMonth + 1);
        // Combine the components into the "yyyy-mm-dd" format
        this.start_date = `${year}-${
          String(this.currentMonth).length === 1
            ? String(this.currentMonth + 1).padStart(2, '0')
            : this.currentMonth
        }-${day}`;
        this.end_date = `${year}-${
          String(this.currentMonth).length === 1
            ? String(this.currentMonth + 1).padStart(2, '0')
            : this.currentMonth
        }-${last_day}`;
        this.getEvents();
      }
    },
    decreaseMonth() {
      if (this.currentMonth <= 12) {
        this.currentMonth = this.currentMonth !== 0 ? this.currentMonth - 1 : 0;
        const t = new Date();
        this.showDate = new Date(t.getFullYear(), this.currentMonth);
        const currentDate = new Date();
        currentDate.setDate(1);
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0');
        const day = String(currentDate.getDate()).padStart(2, '0');
        const last_day = this.getDaysInMonth(year, this.currentMonth + 1);
        this.start_date = `${year}-${
          String(this.currentMonth).length === 1
            ? String(this.currentMonth + 1).padStart(2, '0')
            : this.currentMonth
        }-${day}`;
        this.end_date = `${year}-${
          String(this.currentMonth).length === 1
            ? String(this.currentMonth + 1).padStart(2, '0')
            : this.currentMonth
        }-${last_day}`;
        this.getEvents();
      }
    },
    getFirstDayOfMonth() {
      // Get the current date
      const currentDate = new Date();
      // Set the day of the month to 1
      currentDate.setDate(1);
      // Now `currentDate` represents the first day of the current month
      // Extract the relevant components (year, month, day)
      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, '0');
      const day = String(currentDate.getDate()).padStart(2, '0');
      // Combine the components into the "yyyy-mm-dd" format
      this.start_date = `${year}-${month}-${day}`;
    },
    getLastDayOfMonth() {
      // Get the current date
      const currentDate = new Date();
      // Set the day of the month to 1
      currentDate.setDate(1);
      // Move to the next month
      currentDate.setMonth(currentDate.getMonth() + 1);
      // Go back one day to get the last day of the current month
      currentDate.setDate(currentDate.getDate() - 1);
      // Extract the relevant components (year, month, day)
      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, '0');
      const day = String(currentDate.getDate()).padStart(2, '0');
      // Combine the components into the "yyyy-mm-dd" format
      this.end_date = `${year}-${month}-${day}`;
    },
    // yyyy-mm-dd 00:00:00
    getEvents() {
      this.loader = true;
      this.events = [];
      this.$axios
        .get(
          `/events?start_date=${this.start_date}&end_date=${this.end_date}`,
          {}
        )
        .then((response) => {
          var array = response.data.responseData.events;
          for (let index = 0; index < array.length; index++) {
            const element = array[index];
            var start_date = this.convertDateFormat(element.start_date)
            var end_date = this.convertDateFormat(element.end_date)
            this.events.push({
              id: element.id,
              startDate: start_date,
              endDate: end_date,
              title: element.title,
              code: 'offline'
            });
          }
          this.getChannelevents();
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getChannelevents() {
      this.$axios
        .get('/events/channel', {
          params: {
            code: 'servislet',
            start_date: this.start_date,
            end_date: this.end_date
          }
        })
        .then((response) => {
          var array = response.data.responseData.events;
          for (let index = 0; index < array.length; index++) {
            const element = array[index];
            var start_date = this.convertDateFormat(element.start_date)
            var end_date = this.convertDateFormat(element.end_date)
            this.events.push({
              id: element.id,
              startDate: start_date,
              endDate: end_date,
              title: element.title,
              code: 'servislet'
            });
          }
          this.loader = false;
        });
    },
    getEventDetails() {
      this.modal_loader = true;
      this.$axios
        .get(`/events/${this.event}`, {})
        .then((response) => {
          this.eventDetails = response.data.responseData.event;
          this.$bvModal.show('event-modal-' + this.event);
          this.message =  this.$i18n.t('Election')+`: ${this.event.title}`;
          this.modal_loader = false;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getChannelEventDetails() {
      this.modal_loader = true;
      this.$axios
        .get(`/events/channel/${this.event}`, {})
        .then((response) => {
          this.eventDetails = response.data.responseData.event;
          this.$bvModal.show('event-modal-' + this.event);
          this.message = this.$i18n.t('Election')+`: ${this.event.title}`;
          this.modal_loader = false;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    setShowDate(d) {
      this.showDate = d;
    },
    thisMonth(d, h, m) {
      const t = new Date();
      return new Date(t.getFullYear(), t.getMonth(), d, h || 0, m || 0);
    },
    onClickDay(d) {
      this.selectionStart = null;
      this.selectionEnd = null;
      this.message = this.$i18n.t('Election')+`: ${d.toLocaleDateString()}`;
    },
    onClickItem(e) {
      this.event = e.id;
      this.event_code = e.originalItem.code;
      if (e.originalItem.code === 'offline') {
        this.getEventDetails();
      } else {
        this.getChannelEventDetails();
      }
    },
    routeAppointmentDetail() {
      this.$router.push({
        path: `/edit-appointment/${this.eventDetails.id}`,
        query: { code: this.event_code }
      });
    },
    convertDateFormat(dateString) {
      if(dateString){
        // Split the date and time
        const [datePart, timePart] = dateString.split(' ');

        // Split the date into day, month, and year
        const [day, month, year] = datePart.split('-');

        // Reformat to "YYYY-MM-DD HH:mm:ss"
        const formattedDate = `${year}-${month}-${day} ${timePart}`;

        return formattedDate;
      }
    }
  }
};
</script>

<style scoped>
.product-listing-table {
  height: 100vh;
}

#calendar-wrapper .cv-header {
  width: 100%;
  margin-bottom: 2rem;
}

.modal-foot-save {
  width: 100%;
  max-width: 140px;
}

.modal-foot-save:hover {
  transition: 0.5s;
  background: #06a5d0;
  border-color: #06a5d0;
}
</style>
