<template>
  <div class="row">
    <div class="col-sm-12 col-md-12 col-lg-9">
      <div class="product-detail-left-wrapper">
        <div class="add-invoice-inputs">
          <p
            class="page-title"
            style="margin-bottom: 20px; font-size: 16px">
            {{ $t('Validity Date') }}
          </p>
          <b-form-group
            :label="$t('End Date')"
            style="width: 600px">
            <b-form-datepicker
              :labelNoDateSelected="$t('The date was not chosen.')"
              labelHelp=""
              locale="tr"
              v-model="expiration_at"
              :placeholder="$t('Choose')"></b-form-datepicker>
          </b-form-group>
          <p
            class="page-title mt-5"
            style="margin-bottom: 20px; font-size: 16px">
            {{ $t('Delivery') }}
          </p>
          <div class="row">
            <div class="col-6">
              <b-form-checkbox
                id="cargo_delivery"
                v-model="cargo_delivery"
                name="cargo_delivery"
                :value="true"
                :unchecked-value="false">
                <div class="with-icon">
                  <i class="fa-regular fa-truck"></i> {{ $t('Cargo') }}
                </div>
              </b-form-checkbox>
            </div>
            <div class="col-6">
              <b-form-checkbox
                id="mounting_delivery"
                v-model="mounting_delivery"
                name="mounting_delivery"
                :value="true"
                :unchecked-value="false">
                <div class="with-icon">
                  <i class="fa-sharp fa-regular fa-person-dolly-empty"></i>
                  {{ $t('Montage') }}
                </div>
              </b-form-checkbox>
            </div>
          </div>
          <p
            class="page-title mt-5"
            style="margin-bottom: 20px; font-size: 16px">
            {{ $t('Payment Type') }}
          </p>
          <div class="row">
            <div class="col-6">
              <b-form-checkbox
                id="pay_on_online"
                v-model="pay_on_online"
                name="pay_on_online"
                :value="true"
                :unchecked-value="false">
                <div class="with-icon">
                  <i class="fa-regular fa-credit-card"></i>
                  {{ $t('Debit / Credit Card') }}
                </div>
              </b-form-checkbox>
            </div>
            <div class="col-6">
              <b-form-checkbox
                id="pay_on_appointment"
                v-model="pay_on_appointment"
                name="pay_on_appointment"
                :value="true"
                :unchecked-value="false">
                <div class="with-icon">
                  <i class="fa-regular fa-calendar-check"></i>
                  {{ $t('Pay at Appointment') }}
                </div>
              </b-form-checkbox>
            </div>
          </div>
          <div class="conti">
            <b-button
              class="add-more mt-5"
              @click="updateOffer">
              {{ $t('Continue') }}
            </b-button>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-12 col-md-12 col-lg-3">
      <div class="product-detail-right-wrapper">
        <p
          class="page-title"
          style="margin-bottom: 20px">
          {{ $t('Offer') }}
        </p>
        <div class="offer-lines">
          <div class="offer-line">
            <span class="offer-line-title">{{ $t('Products') }}:</span>
            <span>₺{{ totalProductPrice }}</span>
          </div>
          <div class="offer-line">
            <span class="offer-line-title">{{ $t('Services') }}:</span>
            <span>₺{{ totalServicePrice }}</span>
          </div>
          <div class="offer-line">
            <span class="offer-line-title">{{ $t('Total') }}:</span>
            <span>₺{{ totalPrice }}</span>
          </div>
        </div>
      </div>
      <div class="product-detail-right-wrapper mt-3">
        <p
          class="page-title"
          style="margin-bottom: 20px">
          {{ $t('Customer') }}
        </p>
        <div class="offer-lines">
          <div class="offer-line">
            <span class="offer-line-title"
              >{{ customer.info.name }} {{ customer.info.surname }}</span
            >
          </div>
          <div class="offer-line">
            <span class="offer-line-title">{{ customer.info.phone }}</span>
          </div>
          <div class="offer-line">
            <span class="offer-line-title">{{ customer.info.email }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import tr from 'vee-validate/dist/locale/tr.json';

export default {
  name: 'ConditionStep',
  data() {
    return {
      expiration_at: false,
      cargo_delivery: false,
      mounting_delivery: false,
      pay_on_online: false,
      pay_on_appointment: false
    };
  },
  mounted() {
    this.expiration_at = this.$store.state.offers.addUpdateItem.expiration_at;
    this.cargo_delivery = this.$store.state.offers.addUpdateItem.cargo_delivery;
    this.mounting_delivery =
      this.$store.state.offers.addUpdateItem.mounting_delivery;
    this.pay_on_online = this.$store.state.offers.addUpdateItem.pay_on_online;
    this.pay_on_appointment =
      this.$store.state.offers.addUpdateItem.pay_on_appointment;
  },
  computed: {
    total_cargo() {
      return this.$store.state.offers.addUpdateItem.total_cargo;
    },
    customer() {
      return this.$store.state.offers.addUpdateItem.customer;
    },
    step() {
      this.$store.state.offers.addUpdateItem.step;
    },
    order_items() {
      return this.$store.state.offers.addUpdateItem.order_items;
    },
    order_discounts() {
      return this.$store.state.offers.addUpdateItem.order_discounts;
    },
    emails() {
      return this.$store.state.offers.addUpdateItem.emails;
    },
    phones() {
      return this.$store.state.offers.addUpdateItem.phones;
    },
    totalProductPrice() {
      var total = 0;
      var _this = this;
      if (this.order_items.length > 0) {
        this.order_items.forEach(function (item, key) {
          total += _this.getTotalWithDouble(key);
          if (item.discounts.length > 0) {
            item.discounts.forEach(function (discount, discountkey) {
              if (discount.type == 'amount') {
                var amount = _this.getTotalDiscountWithDouble(discount.amount);
                total -= amount;
              } else if (discount.type == 'rate') {
                var rate = discount.rate;
                var product = _this.getTotalWithDouble(key);
                total -= product * (rate / 100);
              }
            });
          }
        });
      }
      var options = {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      };
      return total.toLocaleString('tr-TR', options);
    },
    totalServicePrice() {
      var total = 0;
      var _this = this;
      if (this.order_items.length > 0) {
        this.order_items.forEach(function (item, key) {
          if (item.services.length > 0) {
            item.services.forEach(function (item, servicekey) {
              total += _this.getTotalServiceWithDouble(key, servicekey);
            });
          }
        });
      }
      var options = {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      };
      return total.toLocaleString('tr-TR', options);
    },
    totalPrice() {
      var total = 0;
      var _this = this;
      if (this.order_items.length > 0) {
        this.order_items.forEach(function (item, key) {
          total += _this.getTotalWithDouble(key);
          if (item.services.length > 0) {
            item.services.forEach(function (item, servicekey) {
              total += _this.getTotalServiceWithDouble(key, servicekey);
            });
          }
          if (item.discounts.length > 0) {
            item.discounts.forEach(function (discount, discountkey) {
              if (discount.type == 'amount') {
                var amount = _this.getTotalDiscountWithDouble(discount.amount);
                total -= amount;
              } else if (discount.type == 'rate') {
                var rate = discount.rate;
                var product = _this.getTotalWithDouble(key);
                total -= product * (rate / 100);
              }
            });
          }
        });
      }
      if (this.order_discounts.length > 0) {
        this.order_discounts.forEach(function (discount, key) {
          if (discount.type == 'amount') {
            var amount = _this.getTotalDiscountWithDouble(discount.amount);
            total -= amount;
          } else if (discount.type == 'rate') {
            var rate = discount.rate;
            var product = _this.getTotalWithDouble(key);
            total -= product * (rate / 100);
          }
        });
      }
      var options = {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      };
      if (this.total_cargo) {
        console.log(total);
        var totalcargo = this.total_cargo.substring(1, this.total_cargo.length);
        var totalcargo = totalcargo.replace('.', '');
        totalcargo = totalcargo.replace(',', '.');
        total += parseFloat(totalcargo);
      }
      return total.toLocaleString('tr-TR', options);
    }
  },
  methods: {
    getTotalWithDouble(key) {
      var price = this.order_items[key].total.replace('.', '');
      price = parseFloat(price.replace(',', '.')).toFixed(2);
      return parseFloat(price);
    },
    getTotalDiscountWithDouble(amount) {
      var price = amount.replace('.', '');
      price = parseFloat(price.replace(',', '.')).toFixed(2);
      return price;
    },
    getTotalServiceWithDouble(key, servicekey) {
      if (this.order_items[key].services[servicekey].total) {
        var price = this.order_items[key].services[servicekey].total.replace(
          '.',
          ''
        );
        price = parseFloat(price.replace(',', '.')).toFixed(2);
        return parseFloat(price);
      }
    },
    conti() {
      this.$store.commit('offers/setStep', 3);
    },
    updateOffer() {
      var orders = [];
      this.order_items.forEach(function (item) {
        var services = [];
        item.services.forEach(function (service) {
          var priceser = service.price.replace('.', '');
          priceser = priceser.replace(',', '.');
          services.push({
            service_id: service.service_id,
            quantity: parseInt(service.quantity),
            price: priceser,
            tax_rate: parseFloat(service.tax_rate).toFixed(2),
            description: service.description
          });
        });
        var discounts = [];
        item.discounts.forEach(function (discount) {
          var pricedis = discount.amount.replace('.', '');
          pricedis = pricedis.replace(',', '.');
          discounts.push({
            discount_type_id: discount.discount_type_id,
            rate: discount.rate,
            amount: pricedis
          });
        });
        var price = item.price.replace('.', '');
        price = price.replace(',', '.');
        orders.push({
          tire_stock_id: item.tire_stock_id,
          quantity: item.quantity,
          price: price,
          tax_rate: parseFloat(item.tax_rate).toFixed(2),
          description: item.description,
          services: services,
          discounts: discounts
        });
      });
      var orderDiscount = [];
      this.order_discounts.forEach(function (dis) {
        var pricedisc = dis.amount.replace('.', '');
        pricedisc = pricedisc.replace(',', '.');
        orderDiscount.push({
          discount_type_id: dis.discount_type_id,
          rate: dis.rate,
          amount: pricedisc
        });
      });
      var totalcargo = this.total_cargo.substring(1, this.total_cargo.length);
      var totalcargo = totalcargo.replace('.', '');
      totalcargo = totalcargo.replace(',', '.');
      var expr = '';
      if (this.expiration_at) {
        expr = `${this.expiration_at}`;
      }
      var emails = [];
      this.emails.forEach(function (email) {
        emails.push(email.email);
      });
      var phones = [];
      this.phones.forEach(function (phone) {
        phones.push(phone.phone);
      });
      var order = {
        payment_channel_id: this.payment_channel_id,
        order_channel_id: this.order_channel_id,
        order_status_id: this.order_status_id,
        total_cargo: totalcargo,
        tire_offer_items: orders,
        tire_offer_discounts: orderDiscount,
        customer_id: this.customer.info.id,
        description: this.description,
        expiration_at: expr,
        cargo_delivery: this.cargo_delivery,
        mounting_delivery: this.mounting_delivery,
        pay_on_online: this.pay_on_online,
        pay_on_appointment: this.pay_on_appointment,
        emails: emails,
        phones: phones
      };
      this.$axios
        .put(`/tire/offers/${this.$route.params.id}`, order)
        .then((response) => {
          if (response.data.status) {
            this.$swal
              .fire({
                iconHtml: '<img src="/img/success.svg" width="32" height="32">',
                position: 'top-end',
                title: this.$i18n.t('The proposal was successfully created.'),
                showConfirmButton: false,
                timer: 2500,
                toast: true,
                customClass: {
                  icon: 'swal2-no-border'
                }
              })
              .then(() => {
                window.location.href = '/offers';
              });
          }
        })
        .catch((error) => {
          if (error.response.data.responseData) {
            if (error.response.data.responseData.error) {
              if (error.response.data.responseData.error.length > 0) {
                this.$swal.fire({
                  iconHtml: '<img src="/img/error.svg" width="32" height="32">',
                  position: 'top-end',
                  title: error.response.data.responseData.error[0].message,
                  showConfirmButton: false,
                  timer: 2500,
                  toast: true,
                  customClass: {
                    icon: 'swal2-no-border'
                  }
                });
              }
            }

            if (error.response.data.responseData.message) {
              this.$swal.fire({
                iconHtml: '<img src="/img/error.svg" width="32" height="32">',
                position: 'top-end',
                title: error.response.data.responseData.message,
                showConfirmButton: false,
                timer: 2500,
                toast: true,
                customClass: {
                  icon: 'swal2-no-border'
                }
              });
            }
          } else {
            this.$swal.fire({
              iconHtml: '<img src="/img/error.svg" width="32" height="32">',
              position: 'top-end',
              title: error.response.data.statusMessage,
              showConfirmButton: false,
              timer: 2500,
              toast: true,
              customClass: {
                icon: 'swal2-no-border'
              }
            });
          }
        });
    }
  }
};
</script>

<style scoped>
.with-icon {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: -9px;
  font-size: 12px;
}

.with-icon i {
  border: 1px solid;
  border-radius: 5px;
  padding: 12px 16px;
  font-size: 17px;
}
</style>
