import Vue from 'vue';
import Vuex from 'vuex';
import carYears from './carYears';
import carBrands from './carBrands';
import carModels from './carModels';
import carVersions from './carVersions';
import tireBrands from './tireBrands';
import tireWidths from './tireWidths';
import tireHeights from './tireHeights';
import tireRadiuses from './tireRadiuses';
import customerTypes from './customerTypes';
import cities from './cities';
import districts from './districts';
import areas from './areas';
import invoiceTypes from './invoiceTypes';
import sales from './sales';
import category from './tireCategory';
import tireSeason from './tireSeasons';
import selectedDate from './selectedDate';
import sidemenu from '@/store/sidemenu';
import offers from '@/store/offers';
import offer from '@/store/offer';
import integrations from '@/store/integrations';
import orderStatus from '@/store/orderStatus';
import carTypes from '@/store/carTypes';
import files from "@/store/files";
import payments from "@/store/payments";
import warehouse from "@/store/warehouse";
import topbar from "@/store/topbar";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    carYears,
    carBrands,
    carModels,
    carVersions,
    tireBrands,
    tireWidths,
    tireHeights,
    tireRadiuses,
    customerTypes,
    cities,
    districts,
    areas,
    invoiceTypes,
    sales,
    category,
    tireSeason,
    selectedDate,
    sidemenu,
    offers,
    offer,
    integrations,
    orderStatus,
    carTypes,
    files,
    payments,
    warehouse,
    topbar
  }
});
