<template>
  <DefaultLayout>
    <PageBreadcrumbs :breadcrumbs="breadcrumbs"/>
    <div>
      <div id="products-listing-table-wrapper" class="mt-3 py-4">
        <div v-if="!$store.state.files.loading" class="product-listing-table">
          <table v-if="files.length>0">
            <tr class="product-listing-table-head">
              <th>ID</th>
              <th>{{ $t('File Name') }}</th>
              <th></th>
            </tr>
            <tr v-for="file in files" :key="file.$index">
              <td>{{ file.id }}</td>
              <td>
                {{ file.name }}
              </td>
              <td>
                <a :href="file.path" download class="download-btn" data-html="true" :title="$t('Download')">
                  <i class="fa-solid fa-download"></i>
                </a>
              </td>
            </tr>
          </table>
          <div v-else class="d-flex align-items-center justifyContentCenter mt-3">
            <p>{{ $t('No files found to display.') }}</p>
          </div>
        </div>
        <div v-else class="loader" style="padding: 24px 16px">
          <content-placeholders :rounded="true">
            <content-placeholders-img/>
            <content-placeholders-text :lines="10"/>
          </content-placeholders>
        </div>
      </div>
    </div>
  </DefaultLayout>
</template>

<script>
/* eslint-disable */
import PageBreadcrumbs from '../../components/PageBreadcrumbs.vue';
import DefaultLayout from '@/layouts/default.vue';

export default {
  components: {
    DefaultLayout,
    PageBreadcrumbs
  },
  data() {
    return {
      breadcrumbs: [
        {
          name: this.$i18n.t('Downloaded Files'),
          path: '/#'
        }
      ]
    };
  },
  computed: {
    files() {
      return this.$store.state.files.list
    }
  },
  mounted() {
    this.$store.dispatch('files/getFiles')
  }
};
</script>

<style scoped>
.download-btn{
  color: #777777;
  text-align: center;
  background: rgba(211, 211, 211, 0.4);
  border-radius: 5px;
  height: 35px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
