<template>
  <div>
    <div class="component-container" v-click-outside="close">
      <div :class="showOptions ? 'opened-border' : 'closed-border'" class="component__select" @click="showOptions = !showOptions">
        <span v-if="!selected" class="component__select--name">{{$t('Choose')}}</span>
        <div v-else class="component__select--name">
          <span style="min-width: 25px;">
                <img :src="selected.icon_asset" :alt="selected.name" height="24">
          </span>
          {{ selected.code === 'tire' ? $t('Tire') : (selected.code === 'oil' ? $t('Oil') : selected.name) }}
        </div>

        <div>
          <i v-if="selected && show_close_icon" class="fa-solid fa-xmark mr-2" @click="selected = null"></i>
          <i v-if="!showOptions" class="fa-solid fa-chevron-down"></i>
          <i v-if="showOptions" class="fa-solid fa-chevron-up"></i>
        </div>
      </div>
      <div v-if="showOptions" class="component__select-options">
        <div v-for="option in options" :class="option.name === selected.name ? 'selected-option' : ''" class="select--option" @click="appTypeRoute(option)">
          <div class="d-flex align-items-center gap-2">
            <span style="min-width: 25px;">
              <img :src="option.icon_asset" :alt="option.name" height="24">
            </span>
            <div>{{ option.code === 'tire' ? $t('Tire') : (option.code === 'oil' ? $t('Oil') : option.name) }}</div>
          </div>
          <i class="fa-solid fa-check" v-if="option.name === selected.name"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vClickOutside from "v-click-outside";

export default {
  name: 'ProductTypeSelect',
  directives: {
    clickOutside: vClickOutside.directive
  },
  props: {
    options: {
      type: Array,
      default: () => []
    },
    selected_key: {
      type: Object,
      default: () => {}
    },
    show_close_icon: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      showOptions: false,
      selected: {}
    };
  },
  watch:{
    $route: function () {
      if(this.$route.query.app_type_code){
        this.selected = this.options.find(item=>item.code = this.$route.query.app_type_code)
      }
    }
  },
  mounted(){
    if(this.$route.query.app_type_code){
      this.options.forEach(item=>{
        if(item.code === this.$route.query.app_type_code){
          this.selected = item
        }
      })
    }else{
      this.selected = this.options.find(item=>item.is_default)
    }
  },
  methods:{
    close() {
      this.showOptions = false;
    },
    appTypeRoute(option){
      this.selected = option;
      this.$emit('select', this.selected);

      var url = new URL(window.location.href);
      url.searchParams.set('app_type_code', this.selected.code);

      url.search = decodeURIComponent(url.search);
      window.history.pushState({}, '', url);
      this.close()
      this.$emit('filterByQuery');
    }
  }
};
</script>

<style scoped>
.component__select {
  height: 40px;
  line-height: 38px;
  width: 190px;
  grid-template-columns: 10fr 1fr;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  background-color: white;
  border: 1px solid #06a5d0;
  color: #06a5d0;
  padding: 0 12px;
}

.opened-border {
  border-radius: 5px 5px 0 0;
  border-bottom: 1px solid transparent;
}

.closed-border {
  border-radius: 5px;
}

.component__select--name {
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: .5rem;
}

.component__select-options {
  max-height: 180px;
  border: 1px solid #06a5d0;
  border-radius: 0 0 6px 6px;
  border-top: none;
  overflow: auto;
  position: absolute;
  z-index: 1500;
  max-width: 500px;
  width: 190px;
  margin: 0;
  padding: 0;
  background-color: #fff;
}

.select--option {
  height: 38px;
  display: flex;
  font-size: 14px;
  font-weight: 400;
  align-items: center;
  padding: 0 12px;
  background-color: white;
  cursor: pointer;
}

.select--option:hover {
  background-color: #06A5D01F;
  opacity: 1;
  color: #06a5d0;
}

.select--option:last-child {
  border-bottom: none;
  border-radius: 0 0 5px 5px;
}

.select--option input {
  display: none;
}

.component__select i {
  font-size: 12px;
  cursor: pointer;
  color: #06a5d0;
}
.selected-option{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: #06a5d0;
  font-size: 14px;
  font-weight: 500;
}

.selected_count {
  background-color: rgba(6, 165, 208, 0.16);
  color: var(--servislet-blue);
  width: 10px;
  height: 10px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  margin-left: 0.5rem;
}

.apply {
  padding: 12px;
  background-color: #fff;
}

.apply-btn {
  border: 1px solid var(--servislet-blue);
  width: 100%;
  height: 35px;
  display: flex;
  font-size: 14px;
  font-weight: 500;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background-color: var(--servislet-blue);
  color: #f4f4f4;
}

.apply-btn:hover {
  background-color: var(--servislet-blue);
  color: #f4f4f4;
}
@media screen and (max-width: 1200px) {
  .component__select {
    width: 135px;
  }
  .component__select-options{
    width: 135px;
  }
}
</style>
