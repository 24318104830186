<template>
  <div class="row w-100">
    <div class="d-sm-none d-md-none d-lg-block col-lg-3"></div>
    <div class="col-sm-12 col-md-12 col-lg-6 mt-3 offer-content">
      <img
        alt="Servislet Pro"
        class="logo pr-0"
        src="../../../assets/images/logo.png" />
      <div class="steps">
        <div
          class="step1"
          @click="$router.push('/customer/offer/2')">
          {{$t('Offer')}}
        </div>
        <div class="step2">{{$t('Delivery')}}</div>
        <div
          class="step3"
          @click="$router.push('/customer/offer/payment/2')">
          {{$t('Payment')}}
        </div>
      </div>
      <div class="mt-4 content-card centered-content">
        <span class="pp">OZ</span>
        <div class="branch-name">{{ offer.branch.name }}</div>
        <div class="d-flex justify-content-between mt-3">
          <div class="item">
            <span class="fw-700">Teklif ID:</span>
            <span>#{{ offer.id }}</span>
          </div>
          <div class="item">
            <span class="fw-700">Teklif Tarihi:</span>
            <span>{{ offer.offer_date ? offer.offer_date : '-' }}</span>
          </div>
          <div class="item">
            <span class="fw-700">Teklif Bitiş Tarihi:</span>
            <span>{{
              offer.expiration_at ? offer.expiration_at.substring(0, 16) : '-'
            }}</span>
          </div>
        </div>
        <div class="text-left">
          <div class="title fw-700">Teslimat</div>
          <div v-if="cargo_step">
            <div class="d-flex align-items-center mt-3">
              <b-form-radio
                name="some-radios"
                :value="true"
                v-model="cargo_active_status" />
              <span class="cargo-text ml-2">Kargo</span>
            </div>
            <div v-if="edit">
              <div class="delivery-address-card">
                <div class="d-flex justify-content-between align-items-center">
                  <div class="delivery-address-card-title">Teslimat Adresi</div>
                  <div
                    class="delivery-address-card-edit-btn"
                    @click="edit = !edit">
                    Düzenle
                  </div>
                </div>
                <div class="delivery-address-card-body">
                  <div class="name">Zehra Çaylı</div>
                  <div class="address">
                    Prensesler Mahallesi Öylesine Sokak E5 Anayol
                    Beşiktaş/İstanbul
                  </div>
                  <div class="phone">05412503145</div>
                </div>
              </div>
              <div class="mt-4 d-flex justify-content-end">
                <button
                  class="btn save-btn"
                  @click="
                    cargo_step = false;
                    appointment_step = true;
                  ">
                  Devam Et
                </button>
              </div>
            </div>

            <div
              class="delivery-address-card w-100"
              v-else>
              <div class="edit-form-body">
                <div class="row p-0">
                  <div class="col-sm-12 col-md-6 col-lg-6">
                    <b-form-group>
                      <label for="name">Ad Soyad</label>
                      <b-form-input
                        class="bg-transparent"
                        v-model="fullname" />
                    </b-form-group>
                  </div>

                  <div class="col-sm-12 col-md-6 col-lg-6">
                    <b-form-group>
                      <label for="phone">Telefon</label>
                      <b-form-input
                        class="bg-transparent"
                        v-model="phone"
                        v-mask="'+90 (###) ### ## ##'" />
                    </b-form-group>
                  </div>

                  <div class="col-sm-12 col-md-6 col-lg-4">
                    <b-form-group>
                      <label for="city">Şehir</label>
                      <b-form-select
                        v-model="city"
                        @change="
                          $store.dispatch('districts/fetchDistricts', city)
                        "
                        class="bg-transparent">
                        <b-form-select-option
                          :value="city.id"
                          v-for="city in cities"
                          :key="city.$index"
                          >{{ city.name }}</b-form-select-option
                        >
                      </b-form-select>
                    </b-form-group>
                  </div>

                  <div class="col-sm-12 col-md-6 col-lg-4">
                    <b-form-group>
                      <label for="district">İlçe</label>
                      <b-form-select
                        v-model="district"
                        @change="$store.dispatch('areas/fetchAreas', district)"
                        class="bg-transparent">
                        <b-form-select-option
                          :value="district.id"
                          v-for="district in districts"
                          :key="district.$index"
                          >{{ district.name }}</b-form-select-option
                        >
                      </b-form-select>
                    </b-form-group>
                  </div>

                  <div class="col-sm-12 col-md-6 col-lg-4">
                    <b-form-group>
                      <label for="area">Mahalle</label>
                      <b-form-select
                        v-model="area"
                        class="bg-transparent">
                        <b-form-select-option
                          :value="area.id"
                          v-for="area in areas"
                          :key="area.$index"
                          >{{ area.name }}</b-form-select-option
                        >
                      </b-form-select>
                    </b-form-group>
                  </div>

                  <div class="col-sm-12 col-md-12 col-lg-12">
                    <b-form-group>
                      <label for="address">Adres</label>
                      <b-form-textarea
                        class="bg-transparent"
                        v-model="address" />
                    </b-form-group>
                  </div>

                  <div class="d-flex justify-content-end">
                    <div>
                      <button
                        class="btn cancel-btn"
                        @click="edit = !edit">
                        İptal
                      </button>
                      <button class="btn save-btn">Kaydet</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="appointment_step">
            <div class="d-flex align-items-center mt-3">
              <b-form-radio
                name="some-radios"
                value="1" />
              <span
                class="cargo-text ml-2"
                @change=""
                >Randevu</span
              >
            </div>
            <div class="row mt-3">
              <div class="col-sm-12 col-md-6 col-lg-4">
                <b-input-group>
                  <flat-pickr
                    v-model="date"
                    :config="{ dateFormat: 'Y-m-d' }"
                    class="form-control b-r-0" />
                  <b-input-group-append
                    is-text
                    class="bg-f4">
                    <i class="fa-light fa-calendar"></i>
                  </b-input-group-append>
                </b-input-group>
              </div>

              <div class="col-sm-12 col-md-6 col-lg-3">
                <b-input-group>
                  <flat-pickr
                    v-model="hour"
                    :config="{
                      noCalendar: true,
                      enableTime: true,
                      dateFormat: 'H:i'
                    }"
                    class="form-control b-r-0" />
                  <b-input-group-append
                    is-text
                    class="bg-f4">
                    <i class="fa-light fa-clock"></i>
                  </b-input-group-append>
                </b-input-group>
              </div>
            </div>

            <div class="mt-5 d-flex justify-content-end">
              <button
                class="btn continue-btn"
                @click="$router.push('/customer/offer/payment/2')">
                Devam Et
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-sm-none d-md-none d-lg-block col-lg-3"></div>
  </div>
</template>

<script>
export default {
  name: 'step2',
  data() {
    return {
      edit: true,
      fullname: '',
      phone: '',
      city: null,
      district: null,
      area: null,
      address: '',
      cargo_step: true,
      appointment_step: false,
      date: null,
      hour: null,
      cargo_active_status: true
    };
  },
  computed: {
    cities() {
      if (this.$store.state.cities.cities.length < 1) {
        this.$store.dispatch('cities/fetchCities');
      }
      return this.$store.state.cities.cities;
    },
    districts() {
      return this.$store.state.districts.districts;
    },
    areas() {
      return this.$store.state.areas.areas;
    },
    offer() {
      if (Object.keys(this.$store.state.offer.offer).length < 1) {
        this.$store.dispatch('offer/fetchTireOffer', this.$route.params.token);
      }
      return this.$store.state.offer.offer;
    }
  }
};
</script>

<style scoped>
.centered-content {
  height: fit-content;
  padding: 3rem;
}
.offer-content {
  text-align: center;
}
.steps {
  display: flex;
  margin-top: 2rem;
  padding: 0 4rem;
}
.step1,
.step2,
.step3 {
  width: 33.3%;
  border: none;
  font-size: 14px;
  text-decoration: underline;
  cursor: pointer;
}
.step1,
.step2 {
  border-top: 7px solid #06a5d0;
  padding: 0.5rem 0 0 0;
}

.step3 {
  border-top: 7px solid #eaeaea;
  padding: 0.5rem 0 0 0;
}
.content-card {
  box-shadow: 5px 5px 8px #dcdcdc, -5px -5px 8px #f6f6f6;
  border-radius: 15px;
}
.pp {
  margin-top: 2rem;
  background-color: #1c1c1c;
  color: #fff;
  text-transform: uppercase;
  padding: 17px 16px;
  font-weight: 400;
  border-radius: 100%;
}
.branch-name {
  margin-top: 1.5rem;
  font-weight: 600;
}
.item span {
  font-size: 14px;
  margin-right: 6px;
}
.fw-700 {
  font-weight: 700;
}
.title {
  font-size: 20px;
  border-bottom: 1px solid #d9d9d9;
  /*text-align: left;*/
  margin-top: 2rem;
  padding-bottom: 0.5rem;
}
.custom-control {
  display: flex;
  align-items: flex-start;
}
.cargo-text {
  font-size: 14px !important;
  color: #777777 !important;
}
.delivery-address-card {
  background-color: #eee;
  padding: 1rem;
  border-radius: 10px;
  width: 60%;
  max-width: 100%;
  margin-top: 2rem;
}
.delivery-address-card-title {
  font-size: 13px;
  font-weight: 600;
  color: #777;
}
.delivery-address-card-edit-btn {
  font-size: 11px;
  font-weight: 500;
  color: #777;
  text-decoration: underline;
  cursor: pointer;
}
.delivery-address-card-body .name,
.delivery-address-card-body .address,
.delivery-address-card-body .phone {
  font-size: 12px;
  color: #777;
  font-weight: 400;
  line-height: 2;
}
.delivery-address-card-body .name {
  font-weight: 500;
  color: #1c1c1c;
  margin-top: 1rem;
}
.edit-form-body {
  margin-top: 1rem;
}
.cancel-btn {
  background-color: #eaeaea;
  color: rgba(119, 119, 119, 0.3);
  padding: 6px 30px;
  font-size: 14px;
}
.cancel-btn:hover {
  background-color: #eaeaea;
  color: rgba(119, 119, 119);
}

.save-btn,
.save-btn:hover {
  background-color: #1c1c1c;
  color: #fff;
  padding: 6px 30px;
  font-size: 14px;
}
.input-group-text {
  background-color: #f4f4f4;
}
.fa-calendar {
  background-color: #d9d9d9;
  padding: 4px 6px;
  border-radius: 5px;
}
.b-r-0 {
  border-right: none;
}

.continue-btn,
.continue-btn:hover {
  background-color: #1c1c1c;
  border: 1px solid #1c1c1c;
  color: #fff;
  padding: 7px 30px;
}
</style>
