import Vue from 'vue';
import Vuex from 'vuex';
import axiosInstance from '@/plugins/axios';
import swal from 'vue-sweetalert2';
import i18n from "@/i18n";

Vue.use(Vuex);

const state = {
  list: {
    items: [],
    pagination: {},
    loading: true
  },
  item: {
    sale: {},
    loading: true
  },
  sales_by_channel: {
    items: [],
    pagination: {},
    loading: true
  },
  channel_item: {
    sale: {},
    loading: true
  }
};

const mutations = {
  setSalesListItem(state, payload) {
    state.list.items = payload;
  },
  setSalesListPagination(state, payload) {
    state.list.pagination = payload;
  },
  setSalesItem(state, payload) {
    state.item.sale = payload;
  },
  setSalesItemLoading(state, payload) {
    state.item.loading = payload;
  },
  setSalesListLoading(state, payload) {
    state.list.loading = payload;
  },
  setSalesByChannelLoading(state, payload) {
    state.sales_by_channel.loading = payload;
  },
  setSalesChannelList(state, payload) {
    state.sales_by_channel.items = payload.items;
    state.sales_by_channel.pagination = payload.pagination;
  },
  setChannelSalesItemLoading(state, payload) {
    state.channel_item.loading = payload;
  },
  setChannelSalesItem(state, payload) {
    state.channel_item.sale = payload;
  }
};

const actions = {
  getSalesListItem({ commit }, data) {
    commit('setSalesListLoading', true);
    axiosInstance
      .get(`/tire/orders`, {
        params: {
          limit: 20,
          page: data.current_page,
          search: data.filter,
          status_code: data.status_code,
          start_date: data.start_date,
          end_date: data.end_date,
          today_appointment: data.today_appointment
        }
      })
      .then((response) => {
        if (response.data.status) {
          commit('setSalesListItem', response.data.responseData.orders.items);
          commit(
            'setSalesListPagination',
            response.data.responseData.orders.pagination
          );
          commit('setSalesListLoading', false);
        }
      });
  },
  async getSale({ commit }, data) {
    commit('setSalesItemLoading', true);
    var response = await axiosInstance.get(`/tire/orders/${data}`);
    if (response.data.status) {
      commit('setSalesItem', response.data.responseData.order);
      commit('setSalesItemLoading', false);
      return response.data.responseData.order;
    }
  },
  async saveOrder({ commit }, data) {
    try {
      var response = await axiosInstance.post(`/tire/orders`, data);

      if (response.data.status) {
        return {
          status: true
        };
      }
    } catch (error) {
      swal.fire({
        iconHtml: '<img src="/img/error.svg" width="32" height="32">',
        position: 'top-end',
        title: this.$i18n.t('Email and password field is required.'),
        showConfirmButton: false,
        timer: 2500,
        toast: true,
        customClass: {
          icon: 'swal2-no-border'
        }
      });
    }
  },
  async searchTireProduct({ commit }, data) {
    var response = await axiosInstance.get(`/tire/products/find`, {
      params: {
        search_text: data
      }
    });
    if (response.data.status) {
      return response.data.responseData;
    } else {
      return [];
    }
  },
  async searchTireProductService({ commit }, data) {
    var response = await axiosInstance.get(`/tire/products/services/find`, {
      params: {
        search_text: data.search,
        tire_brand_id: data.tire_brand_id,
        radius: data.radius
      }
    });
    if (response.data.status) {
      return response.data.responseData;
    } else {
      return [];
    }
  },
  async searchCustomer({ commit }, data) {
    var response = await axiosInstance.get(`/customers/find`, {
      params: {
        search_text: data
      }
    });
    if (response.data.status) {
      return response.data.responseData;
    } else {
      return [];
    }
  },
  async searchCustomerCar({ commit }, data) {
    var response = await axiosInstance.get(`/customers/cars/find-by-customer`, {
      params: {
        customer_id: data
      }
    });
    if (response.data.status) {
      return response.data.responseData;
    } else {
      return [];
    }
  },
  async getDiscountType({ commit }, data) {
    var response = await axiosInstance.get(`/discount-types`);
    if (response.data.status) {
      var discounts = [];
      response.data.responseData.discountTypes.forEach(async function (
        discount
      ) {
        discounts.push({
          text: discount.name,
          value: discount.id
        });
      });
      return discounts;
    } else {
      [];
    }
  },
  async getPaymentChannel({ commit }, data) {
    var response = await axiosInstance.get(`/payment-channels`);
    var payments = [
      {
        text: i18n.t('Choose'),
        value: ''
      }
    ];
    if (response.data.status) {
      response.data.responseData.forEach(function (payment) {
        payments.push({
          text: payment.name,
          value: payment.id
        });
      });
    }
    return payments;
  },
  async getOrderChannel({ commit }, data) {
    var response = await axiosInstance.get(`/order-channels`);
    var channels = [
      {
        text: i18n.t('Choose'),
        value: ''
      }
    ];
    if (response.data.status) {
      response.data.responseData.forEach(function (channel) {
        channels.push({
          text: channel.name,
          value: channel.id
        });
      });
    }
    return channels;
  },
  async getOrderStatus({ commit }, data) {
    var response = await axiosInstance.get(`/order-statuses`);
    var statuses = [
      {
        text: i18n.t('Choose'),
        value: ''
      }
    ];
    if (response.data.status) {
      response.data.responseData.forEach(function (status) {
        statuses.push({
          text: status.name,
          value: status.code
        });
      });
    }
    return statuses;
  },
  async getChannelOrderStatus({ commit }, data) {
    var response = await axiosInstance.get(`/order-statuses/channel`);
    var statuses = [
      {
        text: i18n.t('Choose'),
        value: ''
      }
    ];
    if (response.data.status) {
      response.data.responseData.forEach(function (status) {
        statuses.push({
          text: status.name,
          value: status.code
        });
      });
    }
    return statuses;
  },
  getSalesByChannel({ commit }, data) {
    commit('setSalesByChannelLoading', true);
    axiosInstance
      .get(`/tire/orders/channel`, {
        params: {
          limit: 20,
          page: data.current_page,
          search_text: data.filter,
          status_code: data.status_code,
          start_date: data.start_date,
          end_date: data.end_date,
          today_appointment: data.today_appointment
        }
      })
      .then((response) => {
        if (response.data.status) {
          commit('setSalesChannelList', response.data.responseData.orders);
          commit('setSalesByChannelLoading', false);
        }
      });
  },
  async getChannelSaleDetail({ commit }, data) {
    commit('setChannelSalesItemLoading', true);
    var response = await axiosInstance.get(`/tire/orders/channel/${data}`);
    if (response.data.status) {
      commit('setChannelSalesItem', response.data.responseData.order);
      commit('setChannelSalesItemLoading', false);
      return response.data.responseData.order;
    }
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
