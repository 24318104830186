<template>
  <div>
    <div class="component-container" @click="show = !show" v-click-outside="close">
      <div :class="show ? 'opened-border' : 'closed-border'" class="component__select cursor-pointer">
        <span v-if="Object.keys(selected).length>0" class="component__select--name">{{ selected.title }}</span>
        <span v-else class="component__select--name">{{ $t('Choose') }}</span>

        <div>
          <i v-if="!show" class="fa-solid fa-chevron-down cursor-pointer"></i>
          <i v-if="show" class="fa-solid fa-chevron-up cursor-pointer"></i>
        </div>
      </div>

      <div v-if="show" class="component__select-options">
        <div v-for="option in options" class="select--option" :class="{ 'active': option.title === selected.title}"
             @click="setItem(option)">
          <div>{{ option.title }}</div>
          <i class="fa-solid fa-check" v-if="selected.title === option.title"></i>
        </div>
      </div>
    </div>


    <div v-if="selected.code === 'selected'" class="mt-3">
      <multiple-warehouse-select :options="warehouses" @setWarehouseId="$emit('setWarehouseId',$event)"/>
    </div>

<!--    <div class="warning-text mt-3" v-if="selected.code === 'file'">-->
<!--      "-->
<!--      <span v-if="warehouses.length>2">-->
<!--         <span>-->
<!--           <span v-for="(w,key) in warehouses" :key="w.$index" v-if="key<2">{{ $t('warehouse') }}[{{ w.code }}]-->
<!--             <span v-if="key<1">,</span>-->
<!--             <span v-else>...</span>-->
<!--           </span>-->
<!--      </span>-->
<!--      </span>-->
<!--      <span v-if="warehouses.length === 1">-->
<!--        {{ $t('warehouse') }}[{{ warehouses[0].code }}]-->
<!--      </span>-->
<!--      " gibi farklı depolar için stoklar excel sütunu olarak eklenmiş olmalı.-->
<!--    </div>-->
  </div>
</template>

<script>
import vClickOutside from "v-click-outside";
import MultipleWarehouseSelect from "@/components/products/selects/MultipleWarehouseSelect.vue";

export default {
  name: 'ExcelWarehouseSelect',
  directives: {
    clickOutside: vClickOutside.directive
  },
  components: {
    MultipleWarehouseSelect
  },
  data() {
    return {
      options: [
        {
          key: 0,
          title: this.$i18n.t('All Warehouses'),
          code: 'all'
        },
        {
          key: 1,
          title: this.$i18n.t('Selected Warehouses'),
          code: 'selected'
        },
        // {
        //   key: 2,
        //   title: this.$i18n.t('The Warehouses Specified in The File'),
        //   code: 'file'
        // }
      ],
      selected: {},
      show: false,
      warehouses: []
    };
  },
  mounted() {
    this.selected = this.options[0]
    this.setItem(this.options[0])
    this.getWarehouses()
  },
  methods: {
    close() {
      this.show = false
    },
    getWarehouses() {
      this.$axios.get('/warehouses').then((response) => {
        this.warehouses = response.data.responseData.warehouses;
      });
    },
    setItem(option) {
      this.selected = option
      this.$emit('setWarehouseType',option.code)
    }
  }
};
</script>

<style scoped>
.component__select {
  height: 40px;
  line-height: 38px;
  width: 100%;
  grid-template-columns: 10fr 1fr;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  background-color: #fff;
  border: 1px solid #6D6E6F66;
  padding: 1.5rem 1rem;
}

.component__select--name {
  font-size: 13px;
  cursor: pointer;
}

.component__select-options {
//position: absolute; //z-index: 1500; width: 100%;
  margin: 0;
  padding: 0;
  border: 1px solid #6D6E6F66;
  border-top: none;
  background-color: #fff;
  border-radius: 0 0 5px 5px;
}

.select--option {
  height: 38px;
  display: flex;
  font-size: 13px;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  background-color: #fff;
  cursor: pointer;
}

.select--option:hover, .select--option.active {
  background: #06A5D01F;
  color: #06a5d0;
}

.select--option:last-child {
  border-bottom: none;
  border-radius: 0 0 5px 5px;
}

.select--option input {
  display: none;
}

.component__select i {
  font-size: 12px;
  cursor: pointer;
}

.border-top-first {
  border-top: 1px solid #404040;
}

.closed-border {
  border-radius: 5px;
}

.opened-border {
  border-radius: 5px 5px 0 0;
  border-bottom: none;
}

.warning-text {
  color: #dc3545;
  font-size: 13px;
  text-align: start;
}
</style>

