<template>
  <div>
    <ValidationObserver ref="corporateUserUpdateRules">
      <div class="row">
        <b-col
          sm="12"
          md="12"
          lg="12">
          <div class="row">
            <b-col
              sm="12"
              md="12"
              lg="3">
              <ValidationProvider
                rules="required|min:1|max:60"
                :name="$t('Name')"
                #default="{ errors }">
                <b-form-group>
                  <label for="name"
                    >{{ $t('Name') }}
                    <small class="text-danger">*</small></label
                  >
                  <b-form-input
                    v-model="name"
                    class="bg-transparent" />
                  <label
                    for="name"
                    v-if="errors.length > 0"
                    class="text-danger"
                    ><small>*</small>{{ errors[0] }}.</label
                  >
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col
              sm="12"
              md="12"
              lg="3">
              <ValidationProvider
                rules="required|min:1|max:30"
                :name="$t('Surname')"
                #default="{ errors }">
                <b-form-group>
                  <label for="surname"
                    >{{ $t('Surname') }}
                    <small class="text-danger">*</small></label
                  >
                  <b-form-input
                    v-model="surname"
                    class="bg-transparent" />
                  <label
                    for="surname"
                    v-if="errors.length > 0"
                    class="text-danger"
                    ><small>*</small>{{ errors[0] }}.</label
                  >
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col
              sm="12"
              md="12"
              lg="6">
              <ValidationProvider
                rules="max:120"
                :name="$t('Job Description')"
                #default="{ errors }">
                <b-form-group>
                  <label for="job_title">{{ $t('Job Description') }}</label>
                  <b-form-input
                    v-model="job_title"
                    class="bg-transparent" />
                  <label
                    for="identity_number"
                    v-if="errors.length > 0"
                    class="text-danger"
                    ><small>*</small>{{ errors[0] }}.</label
                  >
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col
              sm="12"
              md="12"
              lg="6">
              <ValidationProvider
                :name="$t('Phone Number')"
                rules="required|max:40"
                #default="{ errors }">
                <b-form-group class="mr-6">
                  <label for="phone"
                    >{{ $t('Phone Number') }}
                    <small class="text-danger">*</small></label
                  >
                  <b-form-input
                    v-model="mobile"
                    class="input bg-transparent"
                    v-mask="'+90 (###) ### ## ##'"></b-form-input>
                  <label
                    for="phone"
                    v-if="errors.length > 0"
                    class="text-danger"
                    ><small>*</small>{{ errors[0] }}.</label
                  >
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col
              sm="12"
              md="12"
              lg="6">
              <ValidationProvider
                rules="required|email|max:40"
                :name="$t('E-Mail')"
                #default="{ errors }">
                <b-form-group>
                  <label for="name"
                    >{{ $t('E-Mail') }}
                    <small class="text-danger">*</small></label
                  >
                  <b-form-input
                    v-model="email"
                    class="bg-transparent" />
                  <label
                    for="name"
                    v-if="errors.length > 0"
                    class="text-danger"
                    ><small>*</small>{{ errors[0] }}.</label
                  >
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <!--            <b-col sm="12" md="12" lg="6">-->
            <!--              <ValidationProvider rules="required|max:20" name="T.C. Kimlik No" #default="{errors}">-->
            <!--                <b-form-group>-->
            <!--                  <label for="identity_number">T.C. Kimlik No <small class="text-danger">*</small></label>-->
            <!--                  <b-form-input v-model="identity_number" type="number" />-->
            <!--                  <label for="identity_number" v-if="errors.length>0" class="text-danger"><small>*</small>{{ errors[0] }}.</label>-->
            <!--                </b-form-group>-->
            <!--              </ValidationProvider>-->
            <!--            </b-col>-->
          </div>
        </b-col>
        <div class="d-flex justify-content-end update">
          <b-button @click="updateUserandAddress()">{{
            $t('Update')
          }}</b-button>
        </div>
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
export default {
  name: 'corporateUserUpdateForm',
  props: {
    user: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      name: this.user.name,
      surname: this.user.surname,
      identity_number: this.user.identity_number,
      mobile: this.user.phones[0].phone,
      email: this.user.email,
      job_title: this.user.job_title
    };
  },
  methods: {
    updateUserandAddress() {
      this.$refs.corporateUserUpdateRules.validate().then((success) => {
        if (success) {
          this.$axios
            .put(`/customers/${this.user.id}`, {
              name: this.name,
              surname: this.surname,
              email: this.email,
              mobile: this.mobile,
              customer_type_id: this.user.customerType.id,
              job_title: this.job_title
            })
            .then((response) => {
              if (response.data.status) {
                this.$swal.fire({
                  iconHtml: '<img src="/img/success.svg" width="32" height="32">',
                  position: 'top-end',
                  title: this.$i18n.t(
                    'Customer information has been successfully updated.'
                  ),
                  showConfirmButton: false,
                  timer: 2500,
                  toast: true,
                  customClass: {
                    icon: 'swal2-no-border'
                  }
                });
                this.$emit('refresh');
              }
            })
            .catch((error) => {
              var message = error.response.data.responseData.error
                ? error.response.data.responseData.error[0].message
                : error.response.data.responseData.message;
              this.$swal.fire({
                iconHtml: '<img src="/img/error.svg" width="32" height="32">',
                position: 'top-end',
                title: message,
                showConfirmButton: false,
                timer: 2500,
                toast: true,
                customClass: {
                  icon: 'swal2-no-border'
                }
              });
            });
        } else {
          this.$swal.fire({
            iconHtml: '<img src="/img/error.svg" width="32" height="32">',
            position: 'top-end',
            title: this.$i18n.t('Please fill the required fields.'),
            showConfirmButton: false,
            timer: 2500,
            toast: true,
            customClass: {
              icon: 'swal2-no-border'
            }
          });
        }
      });
    }
  }
};
</script>

<style scoped>
.update button {
  transition: 0.5s;
  margin-top: 24px;
  background: var(--servislet-black);
  color: var(--servislet-white);
  border: 1px solid var(--servislet-black);
  outline: none;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.4px;
  padding: 8px 24px;
}
.update button:hover {
  transition: 0.5s;
  background: var(--servislet-black);
  color: var(--servislet-white);
  box-shadow: rgba(28, 28, 28, 0.2) 0px 8px 30px 0px;
}
</style>
