<template>
  <DefaultLayout>
    <div>
      <PageBreadcrumbs :breadcrumbs="breadcrumbs" />
      <div class="row">
        <div class="col-lg-9">
          <div class="product-detail-left-wrapper">
            <div class="products-double-flex">
              <div class="products-double-flex-left">
                <p class="page-title">#{{ order.id }}</p>
              </div>
            </div>
            <b-tabs
              active-nav-item-class="product-detail-tabs-active"
              content-class="sales-detail-tabs-content mt-2"
              class="product-detail-tabs mt-3">
              <b-tab
                :title="$t('Sale Details')"
                active>
                <div class="sales-detail-card">
                  <p class="sales-detail-card-title">{{ $t('Customer') }}</p>

                  <div class="product-detail-form-flex">
                    <div class="product-detail-form-flex-left">
                      <div class="sales-detail-item">
                        <p class="sales-detail-item-title">
                          {{ $t('Name Surname') }}
                        </p>
                        <p class="sales-detail-item-info">
                          {{
                            order.customer_name + ' ' + order.customer_surname
                          }}
                        </p>
                      </div>
                      <div class="sales-detail-item">
                        <p class="sales-detail-item-title">
                          {{ $t('E-Mail') }}
                        </p>
                        <p class="sales-detail-item-info">
                          {{ order.customer_email }}
                        </p>
                      </div>
                      <div class="sales-detail-item no-border">
                        <p class="sales-detail-item-title">{{ $t('Phone') }}</p>
                        <p class="sales-detail-item-info">
                          {{ order.customer_phone }}
                        </p>
                      </div>
                    </div>
                    <div class="product-detail-form-flex-right">
                      <div class="sales-detail-item">
                        <p class="sales-detail-item-title">
                          {{ $t('City/District') }}
                        </p>
                        <p class="sales-detail-item-info">Ankara / Çankaya</p>
                      </div>
                      <div class="sales-detail-item">
                        <p class="sales-detail-item-title">
                          {{ $t('Neighborhood') }}
                        </p>
                        <p class="sales-detail-item-info">
                          Ahmet Taner Kışlalı Mah.
                        </p>
                      </div>
                      <div class="sales-detail-item no-border">
                        <p class="sales-detail-item-title">
                          {{ $t('Address') }}
                        </p>
                        <p class="sales-detail-item-info">
                          {{ order.customer_address }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="sales-detail-card">
                  <p class="sales-detail-card-title">{{ $t('Car') }}</p>

                  <div class="product-detail-form-flex">
                    <div class="product-detail-form-flex-left">
                      <div class="sales-detail-item">
                        <p class="sales-detail-item-title">{{ $t('Plate') }}</p>
                        <p class="sales-detail-item-info">
                          {{ order.customer_car_plate_number }}
                        </p>
                      </div>
                      <div class="sales-detail-item">
                        <p class="sales-detail-item-title">
                          {{ $t('Chassis Number') }}
                        </p>
                        <p class="sales-detail-item-info">
                          {{ order.customer_car_chassis_number }}
                        </p>
                      </div>
                      <div class="sales-detail-item no-border">
                        <p class="sales-detail-item-title">{{ $t('Fuel') }}</p>
                        <p class="sales-detail-item-info">--</p>
                      </div>
                    </div>
                    <div class="product-detail-form-flex-right">
                      <div class="sales-detail-item">
                        <p class="sales-detail-item-title">{{ $t('Brand') }}</p>
                        <p class="sales-detail-item-info">--</p>
                      </div>
                      <div class="sales-detail-item">
                        <p class="sales-detail-item-title">
                          Model/{{ $t('Version') }}
                        </p>
                        <p class="sales-detail-item-info">--</p>
                      </div>
                      <div class="sales-detail-item no-border">
                        <p class="sales-detail-item-title">{{ $t('Year') }}</p>
                        <p class="sales-detail-item-info">
                          {{ order.customer_car_year }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="sales-detail-card">
                  <p class="sales-detail-card-title">{{ $t('Sale') }}</p>

                  <div class="sales-detail-card-table">
                    <table>
                      <tr class="sales-detail-card-table-head">
                        <th>{{ $t('Product or Service') }}</th>
                        <th>{{ $t('Count') }}</th>
                        <th>{{ $t('Amount') }}</th>
                        <th class="text-right">{{ $t('Total') }}</th>
                      </tr>
                      <tr
                        v-for="item in order.items"
                        :key="item.$index"
                        valign="baseline">
                        <td>
                          <p class="item-name">{{ item.name }}</p>
                          <span v-if="item.discounts.length > 0">
                            <p
                              class="discount-amount mb-2"
                              v-for="discount in item.discounts"
                              :key="discount.$index">
                              &nbsp;
                            </p>
                          </span>
                          <span v-if="item.services.length > 0">
                            <p
                              class="services mb-2"
                              v-for="service in item.services"
                              :key="service.$index">
                              &nbsp;&nbsp;{{ service.reference.name }}
                            </p>
                          </span>
                        </td>
                        <td>
                          <p>{{ item.quantity }}</p>
                          <span v-if="item.discounts.length > 0">
                            <p
                              class="discount-amount mb-2"
                              v-for="discount in item.discounts"
                              :key="discount.$index">
                              &nbsp;
                            </p>
                          </span>
                          <span v-if="item.services.length > 0">
                            <p
                              class="services mb-2"
                              v-for="service in item.services"
                              :key="service.$index">
                              {{ service.quantity }}
                            </p>
                          </span>
                        </td>
                        <!-- <td v-if="item.discounts.length === 0">{{ formatPrice(item.price) }}</td>
                                           <td v-if="item.discounts.length === 0">{{ formatPrice(item.amount) }}</td> -->

                        <!-- <td>
                                            <p>{{ formatPrice(item.price) }}</p>
                                            <span v-if="item.services.length > 0">
                                                <p class="services mb-2" v-for="service in item.services" :key="service.$index">
                                                    {{ formatPrice(service.price) }}
                                                </p>
                                            </span>
                                           </td> -->

                        <td>
                          <p class="old-price mb-2">
                            {{ formatPrice(item.price) }}
                          </p>
                          <span v-if="item.discounts.length > 0">
                            <p
                              class="discount-amount services mb-2"
                              v-for="discount in item.discounts"
                              :key="discount.$index">
                              {{$t('Discount')}}:
                            </p>
                          </span>
                          <span v-if="item.services.length > 0">
                            <p
                              class="services mb-2"
                              v-for="service in item.services"
                              :key="service.$index">
                              {{ formatPrice(service.price) }}
                            </p>
                          </span>
                        </td>

                        <td class="text-right">
                          <p class="old-price mb-2">
                            {{ formatPrice(item.amount) }}
                          </p>
                          <span v-if="item.discounts.length > 0">
                            <p
                              class="discount-amount services mb-2"
                              v-for="discount in item.discounts"
                              :key="discount.$index">
                              - {{ formatPrice(discount.discount_amount) }}
                            </p>
                          </span>
                          <span v-if="item.services.length > 0">
                            <p
                              class="services mb-2"
                              v-for="service in item.services"
                              :key="service.$index">
                              {{ formatPrice(service.amount) }}
                            </p>
                          </span>
                        </td>
                      </tr>
                      <tr class="sales-detail-table-sub-total">
                        <td></td>
                        <td></td>

                        <td>{{ $t('Sub Total') }}</td>
                        <td class="sales-detail-table-sub-total-res text-right">
                          {{ formatPrice(order.total_price) }}
                        </td>
                      </tr>
                      <tr class="sales-detail-table-vat">
                        <td></td>
                        <td></td>

                        <td>{{ $t('VAT') }}</td>
                        <td class="sales-detail-table-sub-total-res text-right">
                          {{ formatPrice(order.total_tax) }}
                        </td>
                      </tr>
                      <tr class="sales-detail-table-vat">
                        <td></td>
                        <td></td>

                        <td>{{ $t('Discount') }}</td>
                        <td class="sales-detail-table-sub-total-res text-right">
                          - {{ formatPrice(order.total_discount) }}
                        </td>
                      </tr>
                      <tr class="sales-detail-table-total">
                        <td></td>
                        <td></td>

                        <td>{{ $t('Total') }}</td>
                        <td class="text-right">
                          {{ formatPrice(order.total_amount) }}
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </b-tab>
              <b-tab :title="$t('Sale History')"> </b-tab>
            </b-tabs>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="product-detail-right-wrapper">
            <b-form-group
              id="channel-group"
              :label="$t('Sale Channel') + ':'"
              label-for="channel">
              <b-form-input
                id="channel"
                type="text"
                :placeholder="order.orderChannel.name"
                disabled></b-form-input>
            </b-form-group>

            <b-form-group
              id="channel-group"
              :label="$t('Order Status') + ':'"
              label-for="channel">
              <b-form-select
                v-model="order.orderStatus.id"
                :options="orderStatuses"
                class="sales-status-select-completed"></b-form-select>
            </b-form-group>

            <!-- <div class="product-detail-right-checkboxes" v-for="item in 3" :key="item">
                        <span>Stok Aç / Kapat</span>
                        <b-form-checkbox
                        id="product-detail-checkbox"
                        value="open_stock"
                        unchecked-value="close_stock"
                        switch
                        ></b-form-checkbox>
                    </div> -->

            <b-button @click="updateOrderStatus()">{{ $t('Update') }}</b-button>
          </div>
        </div>
      </div>
    </div>
  </DefaultLayout>
</template>

<script>
/* eslint-disable */
import PageBreadcrumbs from '../../components/PageBreadcrumbs.vue';
import StarRating from '../../components/StarRating.vue';
import DefaultLayout from '@/layouts/default.vue';
export default {
  components: {
    PageBreadcrumbs,
    StarRating,
    DefaultLayout
  },
  data() {
    return {
      breadcrumbs: [
        {
          name: this.$i18n.t('Sales'),
          path: '/sales'
        },
        {
          name: '#83948'
        }
      ],
      form: {
        stock: '',
        dot: '',
        salesPrice: '',
        paymemnt: '',
        commission: ''
      },
      selected: null,
      options: [
        { value: null, text: this.$i18n.t('Completed'), disabled: true }
      ],
      discountType: [],
      order_discounts: [],
      paymentChannels: [],
      orderChannels: [],
      orderStatuses: [],
      order: {}
    };
  },
  mounted() {
    this.$store.dispatch('sales/getDiscountType').then((response) => {
      this.discountType = response;
    });
    this.$store.dispatch('sales/getPaymentChannel').then((response) => {
      this.paymentChannels = response;
    });
    this.$store.dispatch('sales/getOrderChannel').then((response) => {
      this.orderChannels = response;
    });
    this.$store.dispatch('sales/getOrderStatus').then((response) => {
      this.orderStatuses = response;
    });
    this.$store
      .dispatch('sales/getSale', this.$route.params.id)
      .then((response) => {
        this.order = response;
      });
  },
  methods: {
    formatPrice(price) {
      return price.toLocaleString('tr-TR', {
        style: 'currency',
        currency: 'TRY'
      });
    },
    updateOrderStatus() {
      let body = {
        tire_order_id: this.order.id,
        order_status_id: this.order.orderStatus.id
      };
      this.$axios
        .put(`/tire/orders/status`, body, {})
        .then((response) => {
          if (response.status) {
            this.$swal.fire({
              iconHtml: '<img src="/img/success.svg" width="32" height="32">',
              position: 'top-end',
              title: this.$i18n.t('Sale successfully updated.'),
              showConfirmButton: false,
              timer: 2500,
              toast: true,
              customClass: {
                icon: 'swal2-no-border'
              }
            });
            window.location.reload();
          }
        })
        .catch((error) => {
          console.error(error);
          this.$swal.fire({
            iconHtml: '<img src="/img/error.svg" width="32" height="32">',
            position: 'top-end',
            title: this.$i18n.t('An error occurred, please try again later.'),
            showConfirmButton: false,
            timer: 2500,
            toast: true,
            customClass: {
              icon: 'swal2-no-border'
            }
          });
        });
    }
  }
};
</script>

<style scoped>
.discount-amount,
.old-price {
  margin: 0;
}
.sales-detail-card-table p {
  margin: 0;
}
.text-right {
  text-align: right !important;
}
</style>
