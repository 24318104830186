<template>
  <div>
    <div class="component-container mt-3">
      <div :class="show ? 'opened-border' : 'closed-border'" class="component__select cursor-pointer" @click="show = !show">
        <div v-if="loading">{{$t('Loading')}}...</div>
        <div v-else>
          <div v-if="selected_brands.length>0" class="component__select--name d-flex align-items-center gap-2">{{$t('Selected Brands')}}: <span class="number-area">+{{selected_brands.length}}</span> </div>
          <span v-else class="component__select--name">{{$t('Choose')}}</span>
        </div>

        <div>
          <i v-if="selected && show" class="fa-solid fa-xmark mr-2" @click="selected_brands = []; $emit('select', null);"></i>
          <i v-if="!show" class="fa-solid fa-chevron-down cursor-pointer"></i>
          <i v-if="show" class="fa-solid fa-chevron-up cursor-pointer"></i>
        </div>
      </div>
      <div v-if="show" v-click-outside="close">
        <div class="component__select-options">
          <div
              v-for="(option,key) in brands"
              class="select--option"
              :class="{ 'active': selected_brands.includes(option), 'border-top-first': key===0}"
              @click="setBrands(option);
          ">
            <div>{{ option.name }}</div>
            <i class="fa-solid fa-check" v-if="selected_brands.includes(option)"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vClickOutside from "v-click-outside";
export default {
  name: 'BrandExportSelect',
  data() {
    return {
      brands:[],
      loading: true,
      selected:{},
      show: false,
      selected_brands:[]
    };
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  mounted(){
    this.loading = true
    this.$axios.get('/tire/brands').then(response=>{
      this.brands = response.data.responseData.brands
      this.loading = false
    })
  },
  computed:{

  },
  methods:{
    setBrands(option){
      if(this.selected_brands.includes(option)){
        this.selected_brands.splice(this.selected_brands.indexOf(option),1)
      }else{
        this.selected_brands.push(option)
      }
      const slugs = this.selected_brands.map(brand => brand.slug).join(',');

      this.$emit('setBrandSlugs', slugs)
    },
    close(){
      this.show = false;
    }
  }
};
</script>

<style scoped>
.component__select {
  height: 40px;
  line-height: 38px;
  width: 100%;
  grid-template-columns: 10fr 1fr;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  background-color: #2C2C2E;
  border: 1px solid #2C2C2E;
  color: #f4f4f4;
  padding: 1.5rem 1rem;
}

.component__select--name {
  font-size: 13px;
  cursor: pointer;
}

.component__select-options {
  //position: absolute;
  z-index: 1500;
  width: 100%;
  margin: 0;
  padding: 0;
  max-height: 250px;
  overflow: scroll;
}

.select--option {
  height: 38px;
  display: flex;
  font-size: 13px;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  background-color: #2C2C2E;
  cursor: pointer;
}

.select--option:hover, .select--option.active {
  background: #06A5D01F;
  color: #06a5d0;
}

.select--option:last-child {
  border-bottom: none;
  border-radius: 0 0 5px 5px;
}

.select--option input {
  display: none;
}

.component__select i {
  font-size: 12px;
  cursor: pointer;
}
.border-top-first{
  border-top: 1px solid #404040;
}
.closed-border{
  border-radius: 5px;
}

.opened-border{
  border-radius: 5px 5px 0 0;
}
.number-area{
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background-color: #06A5D01F;
  color:#06a5d0;
}
</style>
