<template>
  <div id="periodic-maintenance-products-page-wrapper">
    <PageBreadcrumbs :breadcrumbs="breadcrumbs" />

    <div class="widgets">
      <div id="page-widgets-wrapper">
        <div class="row">
          <div
            class="col-lg-3"
            v-for="widget in 4"
            :key="widget.$index">
            <PageWidgets
              title="Lorem Ipsum"
              mainData="₺25.246,75"
              number="12.2%"
              :icon="true"
              text="vs last month" />
          </div>
        </div>
      </div>
    </div>

    <div class="products-double-flex mt-5">
      <div class="products-double-flex-left">
        <p class="page-title">Periyodik Bakım</p>
      </div>
      <div class="products-double-flex-right">
        <label
          id="product-type-selector-label"
          for="product-type-selector"
          >Lorem ipsum dolor sit</label
        >
        <select
          name="product-type-selector"
          id="product-type-selector">
          <option
            value=""
            selected>
            Periyodik Bakım
          </option>
          <option value="">Lastik</option>
        </select>
      </div>
    </div>
    <div
      id="products-listing-table-wrapper"
      class="mt-4 py-4">
      <div class="products-double-flex">
        <div class="products-double-flex-left">
          <b-form-input placeholder="Ara"></b-form-input>
          <button>
            <i class="fa-solid fa-filter"></i>
          </button>
        </div>
        <div class="products-double-flex-right">
          <button class="update-product-btn">Excel ile Güncelle</button>
          <button class="update-product-btn">Toplu Güncelle</button>
          <button
            class="add-new-product-btn"
            @click="$router.push({ name: 'ProductAdd2' })">
            Fiyat Ekle
          </button>
        </div>
      </div>
      <div class="product-listing-table">
        <table>
          <tr class="product-listing-table-head">
            <th>
              <label class="product-listing-checkbox-label">
                <input
                  type="checkbox"
                  @click="checkUncheck()" />
                <span class="checkmark"></span>
              </label>
            </th>
            <th>ID</th>
            <th>MARKA</th>
            <th>MODEL / VERSİYON</th>
            <th>YIL</th>
            <th>10B</th>
            <th>20B</th>
            <th>30B</th>
            <th>40B</th>
            <th></th>
          </tr>
          <tr
            v-for="item in 10"
            :key="item.$index">
            <td>
              <label class="product-listing-checkbox-label">
                <input
                  type="checkbox"
                  name="single-product-checkbox" />
                <span class="checkmark"></span>
              </label>
            </td>
            <td>#357841</td>
            <td>
              <div class="product-listing-img-wrapper">
                <div class="car-listing-img-wrapper">
                  <img
                    src="../../assets/images/car-brand-ex.png"
                    alt="" />
                </div>
              </div>
            </td>
            <td>Gallardo LP550-2 Valentino Balboni</td>
            <td>2022</td>
            <td>₺9.999,99</td>
            <td>₺9.999,99</td>
            <td>₺9.999,99</td>
            <td>
              <b-dropdown
                right
                no-caret>
                <template #button-content>
                  <i class="fa-solid fa-ellipsis-vertical"></i>
                </template>
                <b-dropdown-item class="table-dropdown-actions">
                  <router-link to="/product-detail-2">Düzenle</router-link>
                </b-dropdown-item>
                <b-dropdown-item
                  href="#"
                  class="table-dropdown-actions"
                  >Sil</b-dropdown-item
                >
              </b-dropdown>
            </td>
          </tr>
        </table>
        <div id="table-info-and-pagination">
          <div class="table-results-info">
            <p>Showing 1 to 20 of 100 entries</p>
          </div>
          <div class="pagination-wrapper">
            <div class="pagination">
              <a
                href="#"
                class="pagination-arrows"
                ><i class="fa-solid fa-angle-left"></i
              ></a>
              <div class="pagination-numbers">
                <a href="#">1</a>
                <a
                  href="#"
                  class="active"
                  >2</a
                >
                <a href="#">3</a>
                <a href="#">4</a>
                <a href="#">5</a>
                <a href="#">6</a>
              </div>
              <a
                href="#"
                class="pagination-arrows"
                ><i class="fa-solid fa-angle-right"></i
              ></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import PageBreadcrumbs from '../../components/PageBreadcrumbs.vue';
import PageWidgets from '../../components/PageWidgets.vue';
export default {
  components: {
    PageBreadcrumbs,
    PageWidgets
  },
  data() {
    return {
      breadcrumbs: [
        {
          name: 'Ürünler',
          path: '/#'
        },
        {
          name: 'Ürünler Alt Kırılım',
          path: '/#'
        },
        {
          name: 'Lastik / Yedek Parça / Akü'
        }
      ]
    };
  },
  methods: {
    checkUncheck(checkBox) {
      var get = document.getElementsByName('single-product-checkbox');
      for (var i = 0; i < get.length; i++) {
        if (get[i].checked === true) {
          get[i].checked = false;
        } else {
          get[i].checked = true;
        }
        // without if => get[i].checked = checkBox.checked;}
      }
    }
  }
};
</script>

<style></style>
