import Vue from 'vue';
import Vuex from 'vuex';
import axiosInstance from '@/plugins/axios';

Vue.use(Vuex);

const state = {
    list: [],
    loading: true
};

const mutations = {
    setList(state, payload) {
        state.list = payload;
    },
    setLoading(state, payload) {
        state.loading = payload;
    }
};

const actions = {
    getFiles({commit}, data) {
        commit('setLoading', true);
        axiosInstance.get(`/user-files`).then((response) => {
            if (response.data.status) {
                commit('setList', response.data.responseData.userFiles);
                commit('setLoading', false);
            }
        });
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions
};
