<template>
  <div id="login">
    <div class="container-fluid">
      <ValidationObserver ref="resetRules">
        <div class="row">
          <div class="col-sm-12 col-md-12 col-lg-12 vh-100"
               id="left">
            <div class="forms-g-wrapper text-center neu">
              <img alt="Servislet Pro" style="width: 300px;" src="@/assets/images/logo.png"/>
              <div class="desc-text mt-3">{{ $t('Reset Password') }}</div>
              <ValidationProvider rules="required|min:6|max:50" #default="{errors}" :name="$t('Password')" vid="password1">
                <div class="form-wrapper">
                  <div class="input-wrapper mt-4">
                    <b-input-group>
                      <template #prepend>
                        <i class="fa-solid fa-lock-keyhole"></i>
                      </template>
                      <b-form-input
                          :placeholder="$t('Password') +' *'"
                          type="password"
                          v-model="password"
                          v-on:keyup.enter="reset()"></b-form-input>
                    </b-input-group>
                  </div>
                </div>
                <div class="text-start" v-if="errors.length>0">
                  <span class="fs-12 text-danger">* {{ errors[0] }}.</span>
                </div>
                <div class="text-start opacity-0" v-else>
                  <span class="fs-12 text-danger">*</span>
                </div>
              </ValidationProvider>

              <ValidationProvider rules="required|confirmed:password1" #default="{errors}" :name="$t('Password Again')">
                <div class="form-wrapper">
                  <div class="input-wrapper mt-2">
                    <b-input-group>
                      <template #prepend>
                        <i class="fa-solid fa-lock-keyhole"></i>
                      </template>
                      <b-form-input
                          :placeholder="$t('Password Again')+' *'"
                          type="password"
                          v-model="password_conf"
                          v-on:keyup.enter="reset()"></b-form-input>
                    </b-input-group>
                  </div>
                </div>
                <div class="text-start" v-if="errors.length>0">
                  <span class="fs-12 text-danger">* {{ errors[0] }}.</span>
                </div>
                <div class="text-start opacity-0" v-else>
                  <span class="fs-12 text-danger">*</span>
                </div>
                <button @click="reset()">{{ $t('Reset') }}</button>
              </ValidationProvider>
            </div>
          </div>
        </div>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import axiosInstance from '@/plugins/axios';
/* eslint-disable */
export default {
  name: 'ResetPassword',
  data() {
    return {
      password: null,
      password_conf: null,
    };
  },
  methods: {
    reset() {
      this.$refs.resetRules.validate().then(success => {
        if (success) {
          axiosInstance.post('/auth/reset-password', {
            password: this.password,
            password_confirmation: this.password_conf,
            token: this.$route.params.token,
            user_id: this.$route.params.user_id
          }).then(response => {
            if (response.data.status) {
              this.$swal.fire({
                iconHtml: '<img src="/img/success.svg" width="32" height="32">',
                position: 'top-end',
                title: this.$i18n.t('Password was changed successfully. You will be redirected login page.'),
                showConfirmButton: false,
                timer: 2500,
                toast: true,
                customClass: {
                  icon: 'swal2-no-border'
                }
              });
              setTimeout(() => {
                this.$router.push('/login')
              }, 1000)
            }
          }).catch(error => {
            this.$swal.fire({
              iconHtml: '<img src="/img/error.svg" width="32" height="32">',
              position: 'top-end',
              title: error.response.data.statusMessage,
              showConfirmButton: false,
              timer: 2500,
              toast: true,
              customClass: {
                icon: 'swal2-no-border'
              }
            });
          });
        } else {
          this.$swal.fire({
            iconHtml: '<img src="/img/warning.png" width="32" height="32">',
            position: 'top-end',
            title: this.$i18n.t('Please check the fields.'),
            showConfirmButton: false,
            timer: 2500,
            toast: true,
            customClass: {
              icon: 'swal2-no-border'
            }
          });
        }
      })
    }
  }
};
</script>

<style scoped>

#left {
  display: flex;
  align-items: center;
  justify-content: center;
}

.forms-g-wrapper {
  padding: 40px;
}


.input-wrapper {
  border: 1px solid #d8d6de;
  padding: 2px 12px;
  border-radius: 5px;
  width: 100%;
  margin-top: 1rem;
  position: relative;
}


button {
  border-radius: 5px;
  background: #1c1c1c;
  color: #f4f4f4;
  width: 100%;
  margin-top: 1.5rem;
  border: 1px solid #1c1c1c;
  outline: none !important;
  box-shadow: none !important;
  transition: 0.5s;
  padding: 10px;
}

button:hover {
  background: #06a5d0;
  border-color: #06a5d0;
  transition: 0.5s;
}

.input-wrapper svg {
  color: #d9d9d9;
}

@media only screen and (max-width: 992px) {
  #login {
    height: 100vh;
    display: flex;
    align-items: center;
  }
}

.desc-text {
  font-size: 14px;
  font-weight: 500;

}
</style>
