<template>
  <DefaultLayout>
    <div class="steps">
      <div
        class="step"
        @click="setStep(1)"
        :class="[{ 'step-active': step == 1 }, { 'step-complated': step > 1 }]">
        <i class="fa-solid fa-link step-icon"></i>
        <span class="step-name">{{ $t('Offer') }}</span>
      </div>
      <i class="fa-solid fa-greater-than"></i>
      <div
        class="step"
        @click="setStep(2)"
        :class="[{ 'step-active': step == 2 }, { 'step-complated': step > 2 }]">
        <i class="fa-regular fa-user step-icon"></i>
        <span class="step-name">{{ $t('Customer') }}</span>
      </div>
      <i class="fa-solid fa-greater-than"></i>
      <div
        class="step"
        @click="setStep(3)"
        :class="[{ 'step-active': step == 3 }]">
        <i class="fa-regular fa-credit-card step-icon"></i>
        <span class="step-name">{{ $t('Conditions') }}</span>
      </div>
    </div>
    <div
      class="content"
      v-if="!loading">
      <OfferStep v-if="step == 1" />
      <CustomerStep v-if="step == 2" />
      <ConditionStep v-if="step == 3" />
    </div>
  </DefaultLayout>
</template>

<script>
import DefaultLayout from '@/layouts/default.vue';
import CustomerStep from '@/views/offer/updateStep/CustomerStep.vue';
import ConditionStep from '@/views/offer/updateStep/ContitionStep.vue';
import OfferStep from '@/views/offer/updateStep/OfferStep.vue';

export default {
  name: 'OfferUpdate',
  components: { OfferStep, ConditionStep, CustomerStep, DefaultLayout },
  beforeMount() {
    this.$store.commit('offers/setStep', 1);
  },
  computed: {
    step() {
      return this.$store.state.offers.addUpdateItem.step;
    },
    order_items() {
      return this.$store.state.offers.addUpdateItem.order_items;
    }
  },
  methods: {
    setStep(step) {
      if (this.order_items[0].name != '') {
        this.$store.commit('offers/setStep', step);
      } else {
        this.order_items[0].errorMessage = this.$i18n.t(
          'Please select a product for this row.'
        );
      }
    }
  },
  data() {
    return {
      loading: true
    };
  },
  async mounted() {
    this.loading = true;
    var response = await this.$axios.get(
      `/tire/offers/${this.$route.params.id}`
    );
    if (response.data.status) {
      var offer = response.data.responseData.tireOffer;
      var customer = {
        box: false,
        searchArea: '',
        searchResult: [],
        car: {},
        info: {
          id: offer.customer.id,
          name: offer.customer.name,
          surname: offer.customer.surname,
          phones: {
            id: 3,
            is_default: true,
            phone: offer.customer.phone
          },
          email: offer.customer.email,
          customerType: {
            id: 1,
            name: 'Bireysel'
          }
        }
      };
      var date = offer.expiration_at.split(' ');
      var data = {
        step: 1,
        order_items: [],
        order_discounts: [],
        emails: [],
        phones: [],
        customer: customer,
        cargo_delivery: offer.cargo_delivery,
        mounting_delivery: offer.mounting_delivery,
        pay_on_online: offer.pay_on_online,
        pay_on_appointment: offer.pay_on_appointment,
        total_cargo: offer.total_cargo,
        expiration_at: date[0]
      };
      var items = [];
      offer.items.forEach((item) => {
        var services = [];
        item.services.forEach(function (service) {
          var serviceTotal =
            service.price * service.quantity +
            service.price * service.quantity * ((service.tax_rate * 100) / 100);
          services.push({
            name: service.reference.name,
            id: service.id,
            service_id: service.reference.id,
            quantity: service.quantity,
            price: parseFloat(service.price).toFixed(2),
            tax_rate: service.tax_rate * 100,
            description: service.description,
            total: parseFloat(serviceTotal).toFixed(2),
            searchArea: '',
            searchResult: '',
            openSearchBox: false,
            loading: false
          });
        });
        var discounts = [];
        item.discounts.forEach(function (discount) {
          var type = 'amount';
          if (discount.discount_rate) {
            type = 'rate';
          }
          discounts.push({
            amount: parseFloat(discount.discount_amount).toFixed(2),
            rate: discount.discount_rate ? discount.discount_rate : '',
            discount_type_id: discount.discountType.id,
            type: type,
            id: discount.id
          });
        });
        items.push({
          tire_stock_id: item.reference_id,
          id: item.id,
          quantity: item.quantity,
          maxQuantity: 4,
          price: parseFloat(item.price).toFixed(2),
          name: `${item.brand} ${item.name}`,
          tax_rate: item.tax_rate * 100,
          description: item.description,
          isDesc: item.description ? true : false,
          total: parseFloat(item.amount).toFixed(2),
          openProductBox: false,
          searchArea: '',
          searchResult: [],
          loading: false,
          afterSearch: false,
          errorMessage: '',
          services: services,
          discounts: discounts,
          radius: '',
          tire_brand_id: ''
        });
      });
      var orderDiscount = [];
      offer.discounts.forEach(function (dis) {
        var type = 'amount';
        if (dis.discount_rate === '0,00') {
          type = 'amount';
        }
        if (dis.discount_amount === '0,00') {
          type = 'rate';
        }

        orderDiscount.push({
          id: dis.id,
          discount_type_id: dis.discountType.id,
          rate: dis.discount_rate,
          type: type,
          amount: parseFloat(dis.discount_amount).toFixed(2)
        });
      });
      data.order_items = items;
      data.order_discounts = orderDiscount;
      var emails = [];
      var phones = [];
      offer.emails.forEach(function (email) {
        emails.push({
          email: email
        });
      });
      offer.phones.forEach(function (phone) {
        phones.push({
          phone: phone
        });
      });
      data.emails = emails;
      data.phones = phones;
      this.$store.commit('offers/setAddUpdateItem', data);
      this.loading = false;
    }
  }
};
</script>

<style scoped>
.steps {
  margin-top: 30px;
  display: flex;
  align-items: center;
  margin-left: 5px;
}

.step {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-right: 50px;
  opacity: 0.5;
  cursor: pointer;
}

.step-icon {
  background-color: #eaeaea;
  padding: 15px;
  border-radius: 5px;
  color: #777777;
}

.step-name {
  color: #777777;
  font-weight: 600;
  font-size: 15px;
}

.fa-greater-than {
  color: #777777;
  margin-right: 20px;
  font-size: 15px;
}

.step-active .step-icon {
  background-color: #06a5d0 !important;
  color: #f4f4f4 !important;
}

.step-active .step-name {
  color: #06a5d0 !important;
}

.step-complated {
  opacity: 1;
}

.step-active {
  opacity: 1 !important;
}

.content {
  margin-top: 30px;
}
</style>
