<template>
  <div>
    <div class="component-container" v-click-outside="close">
      <span class="d-none">{{option_list}}</span>
      <div :class="{ 'opened-border': show, 'closed-border': !show, 'passive_select': close_check }" class="component__select cursor-pointer" @click="!close_check ? show = !show : ''">
        <span v-if="Object.keys(selected).length>0" class="component__select--name">{{selected.title}}</span>
        <span v-else class="component__select--name">{{$t('Choose')}}</span>

        <div>
<!--          <i v-if="selected && show" class="fa-solid fa-xmark mr-2" @click="selected = {}; $emit('select', null);"></i>-->
          <i v-if="!show" class="fa-solid fa-chevron-down cursor-pointer"></i>
          <i v-if="show" class="fa-solid fa-chevron-up cursor-pointer"></i>
        </div>
      </div>
      <div v-if="show" class="component__select-options">
        <div
            v-for="(option,key) in option_list"
            class="select--option"
            :class="{ 'active': option.title === selected.title, 'border-top-first': key===0}"
            @click="selected = option; $emit('select', selected.code); close();">
          <div>{{ option.title }}</div>
          <i class="fa-solid fa-check" v-if="selected.title === option.title"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vClickOutside from "v-click-outside";

export default {
  name: 'BulkSidebarSelect',
  directives: {
    clickOutside: vClickOutside.directive
  },
  data() {
    return {
      options: [
        {
          key: 5,
          title: this.$i18n.t('On Sale Products'),
          code: 'on_sale'
        },
        {
          key: 0,
          title: this.$i18n.t('All Products'),
          code: 'all'
        },
        {
          key: 1,
          title: this.$i18n.t('By Brand'),
          code: 'brand'
        },
        {
          key: 2,
          title: this.$i18n.t('By Product Family'),
          code: 'category'
        },
        {
          key: 3,
          title: this.$i18n.t('By Selected Products'),
          code: 'selected'
        },
        {
          key: 4,
          title: this.$i18n.t('By DOT'),
          code: 'dot'
        }
      ],
      selected:{},
      show: false
    };
  },
  props:{
    selected_option:{
      type: String,
      default: ''
    },
    selected_ids:{
      type: Array,
      default: []
    },
    close_check:{
      type: Boolean,
      default: false
    }
  },
  watch:{
    selected_option(){
      if(this.selected_option === null){
        this.selected = {}
      }
    }
  },
  computed:{
    option_list(){
      if(this.selected_ids.length>0){
        this.options = [
          {
            key: 3,
            title: this.$i18n.t('By Selected Products'),
            code: 'selected'
          },
          {
            key: 5,
            title: this.$i18n.t('On Sale Products'),
            code: 'on_sale'
          },
          {
            key: 0,
            title: this.$i18n.t('All Products'),
            code: 'all'
          },
          {
            key: 1,
            title: this.$i18n.t('By Brand'),
            code: 'brand'
          },
          {
            key: 2,
            title: this.$i18n.t('By Product Family'),
            code: 'category'
          },
          {
            key: 4,
            title: this.$i18n.t('By DOT'),
            code: 'dot'
          }
        ]
        this.selected = this.options[0];
        this.$emit('select', this.selected.code);
        this.close();
      }else{
        this.options = [
          {
            key: 5,
            title: this.$i18n.t('On Sale Products'),
            code: 'on_sale'
          },
          {
            key: 0,
            title: this.$i18n.t('All Products'),
            code: 'all'
          },
          {
            key: 1,
            title: this.$i18n.t('By Brand'),
            code: 'brand'
          },
          {
            key: 2,
            title: this.$i18n.t('By Product Family'),
            code: 'category'
          },
          {
            key: 3,
            title: this.$i18n.t('By Selected Products'),
            code: 'selected'
          },
          {
            key: 4,
            title: this.$i18n.t('By DOT'),
            code: 'dot'
          }
        ]
      }
      return this.options
    }
  },
  methods:{
    close(){
      this.show = false
    }
  }
};
</script>

<style scoped>
.component__select {
  height: 40px;
  line-height: 38px;
  width: 260px;
  grid-template-columns: 10fr 1fr;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  background-color: #2C2C2E;
  border: 1px solid #2C2C2E;
  color: #f4f4f4;
  padding: 1.5rem 1rem;
}

.component__select--name {
  font-size: 13px;
  cursor: pointer;
}

.component__select-options {
  position: absolute;
  z-index: 1500;
  width: 260px;
  margin: 0;
  padding: 0;
  background-color: #2C2C2E;
  border-radius:  0 0 5px 5px;
}

.select--option {
  height: 38px;
  display: flex;
  font-size: 13px;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  background-color: #2C2C2E;
  cursor: pointer;
}

.select--option:hover, .select--option.active {
  background: #06A5D01F;
  color: #06a5d0;
}

.select--option:last-child {
  border-bottom: none;
  border-radius: 0 0 5px 5px;
}

.select--option input {
  display: none;
}

.component__select i {
  font-size: 12px;
  cursor: pointer;
}
.border-top-first{
  border-top: 1px solid #404040;
}
.closed-border{
  border-radius: 5px;
}

.opened-border{
  border-radius: 5px 5px 0 0;
}
.passive_select{
  background-color: #3b3c3e26;
  border: 1px solid #3b3c3e26;
  color: #4b4b4b;
}
</style>
