<template>
  <DefaultLayout>
    <div class="steps">
      <div
        class="step"
        @click="setStep(1)"
        :class="[{ 'step-active': step == 1 }, { 'step-complated': step > 1 }]">
        <i class="fa-solid fa-link step-icon"></i>
        <span class="step-name">{{ $t('Offer') }}</span>
      </div>
      <i class="fa-solid fa-greater-than"></i>
      <div
        class="step"
        @click="setStep(2)"
        :class="[{ 'step-active': step == 2 }, { 'step-complated': step > 2 }]">
        <i class="fa-regular fa-user step-icon"></i>
        <span class="step-name">{{ $t('Customer') }}</span>
      </div>
      <i class="fa-solid fa-greater-than"></i>
      <div
        class="step"
        @click="setStep(3)"
        :class="[{ 'step-active': step == 3 }]">
        <i class="fa-regular fa-credit-card step-icon"></i>
        <span class="step-name">{{ $t('Conditions') }}</span>
      </div>
    </div>
    <div class="content">
      <OfferStep v-if="step === 1" />
      <CustomerStep v-if="step === 2" />
      <ConditionStep v-if="step === 3" />
    </div>
  </DefaultLayout>
</template>

<script>
import DefaultLayout from '@/layouts/default.vue';
import OfferStep from '@/views/offer/steps/OfferStep.vue';
import CustomerStep from '@/views/offer/steps/CustomerStep.vue';
import ConditionStep from '@/views/offer/steps/ContitionStep.vue';

export default {
  components: {
    ConditionStep,
    CustomerStep,
    OfferStep,
    DefaultLayout
  },
  beforeMount() {
    this.$store.commit('offers/setStep', 1);
    this.$store.commit('offers/setAddUpdateItem', {
      step: 1,
      order_items: [
        {
          tire_stock_id: '',
          quantity: '1',
          maxQuantity: 4,
          price: '',
          name: '',
          tax_rate: '20',
          description: '',
          isDesc: false,
          total: '',
          openProductBox: false,
          searchArea: '',
          searchResult: [],
          loading: false,
          afterSearch: false,
          errorMessage: '',
          services: [],
          discounts: [],
          radius: '',
          tire_brand_id: ''
        }
      ],
      order_discounts: [],
      emails: [
        {
          email: ''
        }
      ],
      phones: [
        {
          phone: ''
        }
      ],
      customer: {
        box: false,
        searchArea: '',
        searchResult: [],
        car: {},
        info: {}
      },
      cargo_delivery: '',
      mounting_delivery: '',
      pay_on_online: '',
      pay_on_appointment: '',
      total_cargo: '',
      expiration_at: ''
    });
  },
  computed: {
    step() {
      return this.$store.state.offers.addUpdateItem.step;
    },
    order_items() {
      return this.$store.state.offers.addUpdateItem.order_items;
    }
  },
  methods: {
    setStep(step) {
      if (this.order_items[0].name != '') {
        this.$store.commit('offers/setStep', step);
      } else {
        this.order_items[0].errorMessage = this.$i18n.t(
          'Please select a product for this row.'
        );
      }
    }
  }
};
</script>

<style scoped>
.steps {
  margin-top: 30px;
  display: flex;
  align-items: center;
  margin-left: 5px;
}

.step {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-right: 50px;
  opacity: 0.5;
  cursor: pointer;
}

.step-icon {
  background-color: #eaeaea;
  padding: 15px;
  border-radius: 5px;
  color: #777777;
}

.step-name {
  color: #777777;
  font-weight: 600;
  font-size: 15px;
}

.fa-greater-than {
  color: #777777;
  margin-right: 20px;
  font-size: 15px;
}

.step-active .step-icon {
  background-color: #06a5d0 !important;
  color: #f4f4f4 !important;
}

.step-active .step-name {
  color: #06a5d0 !important;
}

.step-complated {
  opacity: 1;
}

.step-active {
  opacity: 1 !important;
}

.content {
  margin-top: 30px;
}
</style>
