<template>
  <DefaultLayout>
    <div>
      <PageBreadcrumbs :breadcrumbs="breadcrumbs"/>
      <div class="row" v-if="!$store.state.sales.channel_item.loading">
        <div class="col-lg-9">
          <div class="product-detail-left-wrapper pb-4" style="height: fit-content">
            <div class="products-double-flex">
              <div class="products-double-flex-left">
                <span class="title">{{ $t('Sale') }} ID : <span class="page-title">#{{ order.id }}</span></span>
              </div>
              <div class="products-double-flex-right">
                <b-button class="cancel-button" @click="openCancelForm()" v-if="order.orderStatus.code !== 'completed' && order.orderStatus.code !== 'cancelled'">
                  {{ $t('Cancellation Request Form') }}
                </b-button>
              </div>
            </div>
            <b-tabs
                active-nav-item-class="product-detail-tabs-active"
                content-class="sales-detail-tabs-content mt-2"
                class="product-detail-tabs mt-3">
              <b-tab :title="$t('Order Details')" active>

                <div class="sales-detail-card">
                  <p class="sales-detail-card-title">{{ $t('Sale') }}</p>

                  <div class="sales-detail-card-table">
                    <table>
                      <tr class="sales-detail-card-table-head">
                        <th>{{ $t('Product') }} / {{ $t('Service') }}</th>
                        <th>{{ $t('Count') }}</th>
                        <th>{{ $t('Amount') }}</th>
                        <th class="text-right">{{ $t('Total') }}</th>
                      </tr>
                      <tr v-for="item in order.items" :key="item.$index">
                        <td>{{ item.name }}</td>
                        <td>{{ item.quantity }}</td>
                        <td>{{ formatPrice(item.price) }}</td>
                        <td class="text-right">{{ formatPrice(item.amount) }}</td>
                      </tr>
                      <tr v-for="service in services" :key="service.$index">
                        <td>{{ service.name }}</td>
                        <td>{{ service.quantity }}</td>
                        <td>{{ currenctFormat('tr-TR', service.price) }}</td>
                        <td class="text-right">{{ currenctFormat('tr-TR', service.price * service.quantity) }}</td>
                      </tr>
                    </table>

                    <div class="d-flex flex-column align-items-end amount-area">
                      <div class="space-between w-35">
                        <span class="key">{{ $t('Sub Total') }} :</span>
                        <span class="value">{{ currenctFormat('tr-TR', order.total_price) }}</span>
                      </div>

                      <div class="space-between w-35">
                        <span class="key">{{ $t('VAT') }} :</span>
                        <span class="value">{{ currenctFormat('tr-TR', order.total_tax) }}</span>
                      </div>

                      <div class="hr-line w-35"></div>

                      <div class="space-between w-35">
                        <span class="key-total">{{ $t('General Total') }} :</span>
                        <span class="value-total">{{ currenctFormat('tr-TR', order.total_amount) }}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="sales-detail-card">
                  <p class="sales-detail-card-title">{{ $t('Delivery') }}</p>

                  <div class="product-detail-form-flex">
                    <div class="product-detail-form-flex-left">
                      <div class="sales-detail-item">
                        <p class="sales-detail-item-title">
                          {{ $t('Delivery Type') }}
                        </p>
                        <p class="sales-detail-item-info">
                          {{ order.deliverType ? order.deliverType.name : '-' }}
                        </p>
                      </div>

                      <div class="sales-detail-item">
                        <p class="sales-detail-item-title">
                          {{ $t('Name Surname') }}
                        </p>
                        <p class="sales-detail-item-info">
                          {{
                            order.deliverType ? order.deliverType.delivery_name + ' ' + order.deliverType.delivery_surname : '-'
                          }}
                        </p>
                      </div>

                      <div class="sales-detail-item">
                        <p class="sales-detail-item-title">
                          {{ $t('Phone') }}
                        </p>
                        <p class="sales-detail-item-info">
                          {{ order.deliverType ? order.deliverType.delivery_mobile : '-' }}
                        </p>
                      </div>


                      <div class="sales-detail-item">
                        <p class="sales-detail-item-title">
                          {{ $t('E-Mail') }}
                        </p>
                        <p class="sales-detail-item-info">
                          {{ order.deliverType ? order.deliverType.delivery_email : '-' }}
                        </p>
                      </div>
                    </div>
                    <div class="product-detail-form-flex-right">
                      <div class="sales-detail-item">
                        <p class="sales-detail-item-title">
                          {{ $t('City') }}
                        </p>
                        <p class="sales-detail-item-info">
                          {{
                            order.deliverType && order.deliverType.delivery_city_name ? order.deliverType.delivery_city_name : '-'
                          }}
                        </p>
                      </div>

                      <div class="sales-detail-item">
                        <p class="sales-detail-item-title">
                          {{ $t('District') }}
                        </p>
                        <p class="sales-detail-item-info">
                          {{
                            order.deliverType && order.deliverType.delivery_district_name ? order.deliverType.delivery_district_name : '-'
                          }}
                        </p>
                      </div>

                      <div class="sales-detail-item">
                        <p class="sales-detail-item-title">
                          {{ $t('Address') }}
                        </p>
                        <p class="sales-detail-item-info">
                          {{
                            order.deliverType && order.deliverType.delivery_address ? order.deliverType.delivery_address : '-'
                          }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="sales-detail-card">
                  <p class="sales-detail-card-title">{{ $t('Invoice') }}</p>

                  <div class="product-detail-form-flex">
                    <div class="product-detail-form-flex-left">
                      <div class="sales-detail-item">
                        <p class="sales-detail-item-title">
                          {{ $t('Invoice Type') }}
                        </p>
                        <p class="sales-detail-item-info">
                          {{ order.billing ? (order.billing.invoiceType ?? '-') : '-' }}
                        </p>
                      </div>

                      <div class="sales-detail-item">
                        <p class="sales-detail-item-title">
                          {{ $t('Name Surname') }}
                        </p>
                        <p class="sales-detail-item-info">
                          {{ order.billing ? order.billing.name + ' ' + order.billing.surname : '-' }}
                        </p>
                      </div>

                      <div class="sales-detail-item">
                        <p class="sales-detail-item-title">
                          {{ $t('Phone') }}
                        </p>
                        <p class="sales-detail-item-info">
                          {{
                            order.billing ? (order.billing.mobile ? order.billing.mobile : (order.billing.phone ? order.billing.phone : '-')) : '-'
                          }}
                        </p>
                      </div>


                      <div class="sales-detail-item border-0">
                        <p class="sales-detail-item-title">
                          {{ $t('E-Mail') }}
                        </p>
                        <p class="sales-detail-item-info">
                          {{ order.billing ? (order.billing.email ? order.billing.email : '-') : '-' }}
                        </p>
                      </div>
                    </div>
                    <div class="product-detail-form-flex-right">
                      <div class="sales-detail-item">
                        <p class="sales-detail-item-title">
                          TC / {{ $t('Tax No.') }}
                        </p>
                        <p class="sales-detail-item-info">
                          {{order.billing ? (order.billing.identity_number ?? '-') : '-'}}
                          <span> / </span>
                          {{order.billing ? (order.billing.tax_number ?? '-') : '-' }}
                        </p>
                      </div>

                      <div class="sales-detail-item">
                        <p class="sales-detail-item-title">
                          {{ $t('Tax Administration') }}
                        </p>
                        <p class="sales-detail-item-info">
                          {{ order.billing ? (order.billing.tax_office ? order.billing.tax_office : '-') : '-' }}
                        </p>
                      </div>

                      <div class="sales-detail-item">
                        <p class="sales-detail-item-title">
                          {{ $t('Address') }}
                        </p>
                        <p class="sales-detail-item-info">
                          {{
                            order.billing ? (order.billing.address + ' ' + order.billing.district + ' / ' + order.billing.city) : '-'
                          }}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="hr-line w-100" style="background-color: rgba(233, 236, 239, 0.6)"></div>

                  <div class="product-detail-form-flex">
                    <div class="product-detail-form-flex-left">
                      <div class="sales-detail-item">
                        <p class="sales-detail-item-title">
                          {{ $t('Product Total') }}
                        </p>
                        <p class="sales-detail-item-info">
                          {{ currenctFormat('tr-TR', order.productTotalAmount) }}
                        </p>
                      </div>

                      <div class="sales-detail-item">
                        <p class="sales-detail-item-title">
                          {{ $t('Service Total') }}
                        </p>
                        <p class="sales-detail-item-info">
                          {{ currenctFormat('tr-TR', order.serviceTotalAmount) }}
                        </p>
                      </div>

                      <div class="sales-detail-item">
                        <p class="sales-detail-item-title">
                          {{ $t('Coupon Discount') }}
                        </p>
                        <p class="sales-detail-item-info">
                          {{ currenctFormat('tr-TR', order.voucherTotalAmount) }}
                        </p>
                      </div>

                      <div class="sales-detail-item">
                        <p class="sales-detail-item-title">
                          {{ $t('Cargo') }}
                        </p>
                        <p class="sales-detail-item-info">
                          {{ currenctFormat('tr-TR', order.total_cargo) }}
                        </p>
                      </div>

                      <div class="sales-detail-item">
                        <p class="sales-detail-item-title">
                          {{ $t('Bank Commission') }}
                        </p>
                        <p class="sales-detail-item-info">
                          {{ order.payment ? currenctFormat('tr-TR', order.payment.installment_commission) : '-' }}
                        </p>
                      </div>

                      <div class="sales-detail-item">
                        <p class="sales-detail-item-title fw-bold">
                          {{ $t('Total Invoice Amount') }}
                        </p>
                        <p class="sales-detail-item-info fw-bold">
                          {{ order.payment ? currenctFormat('tr-TR', order.payment.amount) : '-' }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="sales-detail-card">
                  <p class="sales-detail-card-title">{{ $t('Customer') }}</p>

                  <div class="product-detail-form-flex">
                    <div class="product-detail-form-flex-left">
                      <div class="sales-detail-item">
                        <p class="sales-detail-item-title">
                          {{ $t('Name Surname') }}
                        </p>
                        <p class="sales-detail-item-info">
                          {{
                            order.customer_name + ' ' + order.customer_surname
                          }}
                        </p>
                      </div>
                      <div class="sales-detail-item">
                        <p class="sales-detail-item-title">
                          {{ $t('E-Mail') }}
                        </p>
                        <p class="sales-detail-item-info">
                          {{ order.customer_email }}
                        </p>
                      </div>
                      <div class="sales-detail-item no-border">
                        <p class="sales-detail-item-title">{{ $t('Phone') }}</p>
                        <p class="sales-detail-item-info">
                          {{ order.customer_phone }}
                        </p>
                      </div>
                    </div>
                    <div class="product-detail-form-flex-right">
                      <div class="sales-detail-item">
                        <p class="sales-detail-item-title">
                          {{ $t('City/District') }}
                        </p>
                        <p class="sales-detail-item-info">Ankara / Çankaya</p>
                      </div>
                      <div class="sales-detail-item">
                        <p class="sales-detail-item-title">
                          {{ $t('Neighbourhood') }}
                        </p>
                        <p class="sales-detail-item-info">
                          Ahmet Taner Kışlalı Mah.
                        </p>
                      </div>
                      <div class="sales-detail-item no-border">
                        <p class="sales-detail-item-title">
                          {{ $t('Address') }}
                        </p>
                        <p class="sales-detail-item-info">
                          {{ order.customer_address }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="sales-detail-card">
                  <p class="sales-detail-card-title">{{ $t('Car') }}</p>

                  <div class="product-detail-form-flex">
                    <div class="product-detail-form-flex-left">
                      <div class="sales-detail-item">
                        <p class="sales-detail-item-title">{{ $t('Plate') }}</p>
                        <p class="sales-detail-item-info">
                          {{
                            order.customer_car_plate_number
                                ? order.customer_car_plate_number
                                : '-'
                          }}
                        </p>
                      </div>
                      <div class="sales-detail-item">
                        <p class="sales-detail-item-title">
                          {{ $t('Chassis No.') }}
                        </p>
                        <p class="sales-detail-item-info">
                          {{
                            order.customer_car_chassis_number
                                ? order.customer_car_chassis_number
                                : '-'
                          }}
                        </p>
                      </div>
                      <div class="sales-detail-item no-border">
                        <p class="sales-detail-item-title">{{ $t('Fuel') }}</p>
                        <p class="sales-detail-item-info">
                          {{
                            order.customer_car_fuel_type
                                ? order.customer_car_fuel_type
                                : '-'
                          }}
                        </p>
                      </div>
                    </div>
                    <div class="product-detail-form-flex-right">
                      <div class="sales-detail-item">
                        <p class="sales-detail-item-title">{{ $t('Brand') }}</p>
                        <p class="sales-detail-item-info">
                          {{
                            order.customer_car_brand_name
                                ? order.customer_car_brand_name
                                : '-'
                          }}
                        </p>
                      </div>
                      <div class="sales-detail-item">
                        <p class="sales-detail-item-title">
                          Model / {{ $t('Version') }}
                        </p>
                        <p class="sales-detail-item-info">
                          {{
                            order.customer_car_brand_name
                                ? order.customer_car_brand_name
                                : '-'
                          }}
                          <span v-if="order.customer_car_version"
                          >/
                            {{
                              order.customer_car_version
                                  ? order.customer_car_version
                                  : '-'
                            }}</span
                          >
                        </p>
                      </div>
                      <div class="sales-detail-item no-border">
                        <p class="sales-detail-item-title">{{ $t('Year') }}</p>
                        <p class="sales-detail-item-info">
                          {{ order.customer_car_year ?? '-' }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </b-tab>
              <b-tab :title="$t('Order History')">
                <div class="sales-detail-card">
                  <div class="space-between">
                    <div class="table-text">{{ $t('Cargo Status') }}</div>
                    <div class="table-text">{{ $t('Date') }}</div>
                  </div>

                  <div class="transaction-list">
                    <div style=" display: flex; flex-direction: column; gap:0;"
                         v-for="(item,key) in history_steps" :key="item.$index">
                      <div class="space-between">
                        <div class="check-status">
                          <i class="fa-solid fa-check-circle font-servislet" v-if="item.check"></i>
                          <span class="number" v-else>{{ item.id }}</span>
                          <span class="status-desc">{{ item.title }}</span>
                        </div>
                        <div class="table-text fw-400"> {{ dateTimeFormat(item.date) }}</div>
                      </div>
                      <div class="hr-line-area" v-if="key < history_steps.length-1">
                        <div class="horizontal" :class="item.check ? '' : 'inactive-line'"></div>
                        <div class="vertical"></div>
                      </div>
                    </div>
                  </div>
                </div>

              </b-tab>
            </b-tabs>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="product-detail-right-wrapper">
            <b-form-group
                id="channel-group"
                :label="$t('Order Channel')"
                label-for="channel"
                :class="order.orderStatus.code === 'completed' ? 'mb-0' : ''"
            >
              <b-form-input
                  id="channel"
                  type="text"
                  placeholder="Servislet"
                  disabled></b-form-input>
            </b-form-group>

            <b-form-group
                id="channel-group"
                :label="$t('Order Status')"
                label-for="channel"
                v-if="order.orderStatus.code !== 'completed'"
            >
              <b-form-select
                  v-model="order_status_code"
                  class="sales-status-select-completed">
                <option value="" disabled selected>{{$t('Choose')}}...</option>
                <option value="completed" v-if="order.orderStatus.code === 'approved'">
                  {{$t('Completed')}}
                </option>
                <option value="approved" v-if="order.orderStatus.code === 'payment-completed'">
                  {{$t('Approved')}}
                </option>
              </b-form-select>
            </b-form-group>

            <div v-else class="servislet-badge mt-3 w-100 commission-badge" style="border: 1px solid rgba(6, 165, 208, 0.12);">
              {{ $t('Completed') }}
            </div>



            <b-button class="mt-1" @click="updateOrderStatus()" v-if="order.orderStatus.code !== 'completed'">{{ $t('Update') }}</b-button>
          </div>

          <div class="product-detail-right-wrapper" style="margin-top: 30px;"
               v-if="order.deliverType && order.deliverType.code === 'use_cargo_delivery'">
            <div class="status-title">{{ $t('Cargo Details') }}</div>

            <div class="space-between space-border-bottom"  v-if="order.deliveries.length>0">
              <div class="status-key">{{$t('Cargo Company')}}</div>
              <div class="status-value">{{order.deliveries[0].company}}</div>
            </div>

            <div class="space-between space-border-bottom">
              <div class="status-key">{{ $t('Tracking Number') }}</div>
              <a href="https://www.test.com" target="_blank" class="status-value cursor-pointer text-decoration-none"
                 v-if="order.deliveries.length>0">
                <i class="fa-solid fa-arrow-up-right-from-square mr-1"></i>
                {{ order.deliveries[0].tracking_number }}
              </a>
              <span class="status-value" v-else>-</span>
            </div>

            <div class="space-between space-border-bottom">
              <div class="status-key">{{ $t('Person to Receive') }}</div>
              <div class="status-value">
                {{ order.deliverType ? order.deliverType.delivery_name + ' ' + order.deliverType.delivery_surname : '-' }}
              </div>
            </div>

            <div class="space-between space-border-bottom">
              <div class="status-key">{{ $t('Phone') }}</div>
              <div class="status-value">
                {{ order.deliverType ? (order.deliverType.delivery_mobile ?? order.deliverType.delivery_phone) : '-' }}
              </div>
            </div>

            <div class="d-flex flex-column space-border-bottom">
              <div class="status-key">{{ $t('Delivery Informations') }}</div>
              <div class="status-value mt-2 text-start">
                {{
                  order.deliverType && order.deliverType.delivery_address ? order.deliverType.delivery_address + ' ' + order.deliverType.delivery_district_name + ' ' + order.deliverType.delivery_city_name : '-'
                }}
              </div>
            </div>

            <div class="d-flex flex-column space-border-bottom">
              <div class="status-key">{{ $t('Billing Informations') }}</div>
              <div class="status-value mt-2 text-start">
                {{
                  order.billing ? (order.billing.address + ' ' + order.billing.district + ' / ' + order.billing.city) : '-'
                }}
              </div>
            </div>

            <b-button v-if="order.deliveries && order.deliveries.length>0 && order.deliveries[0].barcode_url"
                      :class="order.deliveries[0].tracking_number ? 'active-print-btn' : 'print-btn'" @click="openPrintModal()">
              {{ $t('Print Barcode') }}
            </b-button>
<!--            <b-button class="mt-3">{{ $t('Preview') }}</b-button>-->
          </div>
        </div>
      </div>
      <div v-else>
        <content-placeholders :rounded="true">
          <content-placeholders-img/>
          <content-placeholders-text :lines="10"/>
        </content-placeholders>
      </div>

      <b-modal id="iframe-modal" size="lg" centered hide-header hide-footer>
        <iframe :src="pdfUrl" frameborder="0" width="100%" height="600"></iframe>
      </b-modal>

      <b-modal
          id="cancel-modal"
          ref="cancel-modal"
          size="md"
          hide-footer
          centered
          hide-header>
        <div class="warehouse-modal-body">
          <div class="warehouse-modal-header">
            <i class="fa-solid fa-xmark cursor-pointer" @click="$refs['cancel-modal'].hide()"></i>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <b-form-group>
                <label for="name">{{ $t('Cancellation Reason') }} *</label>
                <select class="custom-select bg-transparent" v-model="cancel_reason">
                  <option value="">
                    {{$t('Choose')}}
                  </option>
                  <option value="Yanlış Ürün / Hatalı Satınalma">
                    {{$t('Wrong Product / Incorrect Purchase')}}
                  </option>
                  <option value="Stokta Yok">{{$t('Out of Stock')}}</option>
                  <option value="Diğer">{{$t('Other')}}</option>
                </select>
              </b-form-group>
            </div>

            <div class="col-sm-12">
              <b-form-group>
                <label for="description">{{ $t('Description') }}</label>
                <b-form-textarea rows="3" class="bg-transparent" v-model="cancel_description" />
              </b-form-group>
            </div>
          </div>

          <div class="d-flex justify-content-end mt-3">
            <b-button
                :class="!cancel_reason ? 'passive-cancel-btn' : 'select-button'"
                @click="cancelOrder()"
            >{{ $t('Send') }}
            </b-button>
          </div>
        </div>
      </b-modal>
    </div>
  </DefaultLayout>
</template>

<script>
/* eslint-disable */
import PageBreadcrumbs from '../../components/PageBreadcrumbs.vue';
import StarRating from '../../components/StarRating.vue';
import DefaultLayout from '@/layouts/default.vue';

export default {
  components: {
    PageBreadcrumbs,
    StarRating,
    DefaultLayout
  },
  data() {
    return {
      breadcrumbs: [
        {
          name: this.$i18n.t('Sales'),
          path: '/sales'
        },
        {
          name: this.$i18n.t('Order Details'),
          path: '#'
        }
      ],
      form: {
        stock: '',
        dot: '',
        salesPrice: '',
        paymemnt: '',
        commission: ''
      },
      selected: null,
      options: [
        {value: null, text: this.$i18n.t('Completed'), disabled: true}
      ],
      discountType: [],
      order_discounts: [],
      paymentChannels: [],
      orderChannels: [],
      orderStatuses: [],
      order: {
        orderStatus: {}
      },
      orderStatus: {},
      services: [],
      history_steps: [],
      pdfUrl: null,
      order_status_code: '',
      cancel_reason: '',
      cancel_description:''
    };
  },
  mounted() {
    this.$store.dispatch('sales/getDiscountType').then((response) => {
      this.discountType = response;
    });
    this.$store.dispatch('sales/getPaymentChannel').then((response) => {
      this.paymentChannels = response;
    });
    this.$store.dispatch('sales/getOrderStatus').then((response) => {
      this.orderStatuses = response;
    });
    this.$store.dispatch('sales/getChannelOrderStatus').then((response) => {
      this.orderStatuses = response;
    });

    this.getDetail();
  },
  methods: {
    getDetail(){
      this.$store.dispatch('sales/getChannelSaleDetail', this.$route.params.id).then((response) => {
        this.order = response;

        if (this.order.deliverType && this.order.deliverType.code === 'use_cargo_delivery') {
          this.history_steps = [
            {
              key: 0,
              id: 1,
              title: this.$i18n.t('Order Received'),
              code: 'payment-completed'
            },
            {
              key: 1,
              id: 2,
              title: this.$i18n.t('Payment Received'),
              code: 'payment-completed'
            },
            {
              key: 2,
              id: 3,
              title: this.$i18n.t('Order Approved'),
              code: 'approved'
            },
            {
              key: 3,
              id: 4,
              title: this.$i18n.t('Order Shipped'),
              code: 'cargo-given'
            },
            {
              key: 4,
              id: 5,
              title: this.$i18n.t('Order Delivered'),
              code: 'delivered'
            }
          ]
        } else {
          this.history_steps = [
            {
              key: 0,
              id: 1,
              title: this.$i18n.t('Order Received'),
              code: 'payment-completed'
            },
            {
              key: 1,
              id: 2,
              title: this.$i18n.t('Payment Received'),
              code: 'payment-completed'
            },
            {
              key: 2,
              id: 3,
              title: this.$i18n.t('Order Approved'),
              code: 'approved'
            },
            {
              key: 3,
              id: 4,
              title: this.$i18n.t('Order Delivered'),
              code: 'delivered'
            }
          ]
        }

        // this.order.orderStatus.code = 'completed'

        if (this.order.orderStatus.code === 'payment-completed') {
          this.history_steps[0].check = true
          this.history_steps[1].check = true
        } else if (this.order.orderStatus.code === 'approved') {
          this.history_steps[0].check = true
          this.history_steps[1].check = true
          this.history_steps[2].check = true
        } else if (this.order.orderStatus.code === 'cargo-given' && this.order.deliverType.code === 'use_cargo_delivery') {
          this.history_steps[0].check = true
          this.history_steps[1].check = true
          this.history_steps[2].check = true
          this.history_steps[3].check = true
        } else if (this.order.orderStatus.code === 'completed') {
          for (let i in this.history_steps) {
            this.history_steps[i].check = true
          }
        }

        for (let i in this.order.transactions) {
          for (let k in this.history_steps) {
            if (this.order.transactions[i].code === this.history_steps[k].code) {
              this.history_steps[k].status_title = this.order.transactions[i].title
              this.history_steps[k].date = this.order.transactions[i].created_at
            }
          }
        }

        this.services = []
        for (let i in this.order.items) {
          for (let k in this.order.items[i].services) {
            this.services.push(this.order.items[i].services[k])
          }
        }
      });
    },
    formatPrice(price) {
      if (price) {
        return price.toLocaleString('tr-TR', {
          style: 'currency',
          currency: 'TRY'
        });
      }
    },
    updateOrderStatus() {
      let body = {
        tire_order_id: this.order.id,
        integration_code: 'servislet',
        status_code: this.order_status_code
      };
      this.$axios.put(`/tire/orders/channel/${this.order.id}`, body, {}).then((response) => {
        if (response.status) {
          this.$swal.fire({
            iconHtml: '<img src="/img/success.svg" width="32" height="32">',
            position: 'top-end',
            title: this.$i18n.t('Sale successfully updated.'),
            showConfirmButton: false,
            timer: 2500,
            toast: true,
            customClass: {
              icon: 'swal2-no-border'
            }
          }).then(() => {
            window.location.reload();
          });
        }
      }).catch((error) => {
        var message = error.response.data.responseData.error ? error.response.data.responseData.error[0].message : error.response.data.responseData.message;

        this.$swal.fire({
          iconHtml: '<img src="/img/error.svg" width="32" height="32">',
          position: 'top-end',
          title: message,
          showConfirmButton: false,
          timer: 2500,
          toast: true,
          customClass: {
            icon: 'swal2-no-border'
          }
        });
      });
    },
    openPrintModal(){
      if(this.order.deliveries && this.order.deliveries[0].barcode_url){
        this.pdfUrl = this.order.deliveries[0].barcode_url
        this.$bvModal.show('iframe-modal')
      }
    },
    openCancelForm(){
      this.cancel_description = ''
      this.cancel_reason = ''
      this.$refs["cancel-modal"].show()
    },
    cancelOrder(){
      this.$axios.post(`/tire/request-forms`, {
        request_type_id: 3202,
        request_status_id: 3101,
        order_id: this.order.id,
        description:
            "İade nedeni: " +
            this.cancel_reason +
            " " +
            "Açıklama:" +
            " " +
            this.cancel_description
      }).then((response) => {
        if (response.data.status) {
          this.$refs["cancel-modal"].hide()
          this.$swal.fire({
            iconHtml: '<img src="/img/success.svg" width="32" height="32">',
            position: 'top-end',
            title: this.$i18n.t('Your order cancellation request has been received.'),
            showConfirmButton: false,
            timer: 2500,
            toast: true,
            customClass: {
              icon: 'swal2-no-border'
            }
          })
          this.getDetail()
        }
      }).catch((error) => {
        var message = error.response.data.responseData.error ? error.response.data.responseData.error[0].message : error.response.data.responseData.message;
        this.$swal.fire({
          iconHtml: '<img src="/img/error.svg" width="32" height="32">',
          position: 'top-end',
          title: message,
          showConfirmButton: false,
          timer: 2500,
          toast: true,
          customClass: {
            icon: 'swal2-no-border'
          }
        });
      });
    }
  }
};
</script>

<style scoped>
.discount-amount,
.old-price {
  margin: 0;
}

.sales-detail-card-table p {
  margin: 0;
}

.text-right {
  text-align: right !important;
}

.products-double-flex-left .title {
  font-size: 16px;
  font-weight: 400;
  line-height: 20.08px;
  text-align: left;
  color: #5E5873;
}

table tr th {
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  color: #777777;
  border-bottom: 1px solid #D9D9D9;
}

table tr td {
  font-size: 12px;
  font-weight: 400;
  line-height: 15.06px;
  color: #1C1C1C;
  border-bottom: 1px solid #D9D9D9;
}

.amount-area {
  margin-top: 20px;
  gap: .5rem;
}

.amount-area .key {
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
}

.amount-area .value {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: right;
}

.w-35 {
  width: 35%;
}

.hr-line {
  height: 1px;
  background-color: #D9D9D980;
}

.key-total {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  color: #18020C;
}

.value-total {
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  text-align: right;
  color: #18020C;
}

.table-text {
  font-size: 12px;
  font-weight: 500;
  line-height: 21px;
  color: #777;
}

.transaction-list {
  border-top: 1px solid #E9ECEF99;
  border-bottom: 1px solid #E9ECEF99;
  margin-top: 12px;
  padding: 18px 0;
}

.fw-400 {
  font-weight: 400;
}

.check-status {
  display: flex;
  gap: 12px;
}

.status-desc {
  font-size: 12px;
  font-weight: 500;
  line-height: 15.06px;
  color: #1C1C1C;
}

.hr-line-area {
  display: flex;
  align-items: center;
  gap: 12px;
  margin: 3px 0;
}

.horizontal {
  height: 36px;
  width: 3px;
  background-color: #06a5d0;
  border-radius: 3px;
  margin-left: 7px;
}

.vertical {
  width: 100%;
  height: 1px;
  background-color: #E9ECEF99;
}

.space-border-bottom {
  border-bottom: 1px solid #E9ECEF;
  padding: 14px 0;
}

.status-key {
  font-size: 12px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  color: #6E6B7B;
}

.status-value {
  color: #1C1C1C;
  font-family: Mulish;
  font-size: 13px;
  font-weight: 500;
  line-height: 16.32px;
  text-align: right;
}

.status-title {
  font-family: Mulish;
  font-size: 14px;
  font-weight: 600;
  line-height: 17.57px;
  text-align: left;
  color: #1C1C1C;
}

.print-btn, .print-btn:hover {
  background-color: #EAEAEA;
  border: 1px solid #eaeaea;
  color: #777777;
  pointer-events: none;
}

.active-print-btn, .active-print-btn:hover {
  background-color: #06a5d0;
  border: 1px solid #06a5d0;
  color: #f4f4f4;
}

.check-status .number {
  width: 18px;
  height: 18px;
  border-radius: 50px;
  background-color: #D3D3D380;
  border: none;
  color: #777777;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 500;
}

.fa-check-circle {
  font-size: 17px;
}

.inactive-line {
  background-color: #D3D3D380;
}
.cancel-button, .cancel-button:hover{
  background-color: #1C1C1C;
  border: 1px solid #1C1C1C;
  font-size: 14px;
  color: #f4f4f4;
}

.select-button, .select-button:hover , .select-button:focus, .select-button:focus-visible {
  background-color: #06a5d0;
  border: 1px solid #06a5d0;
  color: #fff;
  margin-left: 1rem;
  padding: 8px 38px;
  font-size: 14px;
}
.passive-cancel-btn,.passive-cancel-btn:hover{
  background-color: #e3e3e3;
  border: 1px solid #e3e3e3;
  color: #777;
  margin-left: 1rem;
  padding: 8px 38px;
  pointer-events: none;
  font-size: 14px;
}
</style>
