import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const state = {
  show: localStorage.getItem('show_menu') ? JSON.parse(localStorage.getItem('show_menu')) : true
};

const mutations = {
  setMenu(state, data) {
    state.show = data;
  }
};

const actions = {
  dispatchMenu({ commit }, data) {
    commit('setMenu', data);
  }
};

const getters = {
  getMenu(state) {
    if (state.show) {
      return state.show;
    } else {
      if (localStorage.expanded_menu) {
        state.show = localStorage.expanded_menu;
        return localStorage.expanded_menu;
      } else {
        state.show = true;
      }
    }
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
