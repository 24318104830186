<template>
  <div id="dashboard-widgets-wrapper">
    <b-row v-if="!loader">
      <b-col
        sm="12"
        md="4"
        lg="4">
        <div class="dashboard-widget-card">
          <p class="dashboard-widget-title">{{ $t('Customers') }}</p>
          <div class="dashboard-widgets-flex">
            <p class="dashboard-widgets-data">
              {{ data.customer.count }}
            </p>
            <span v-if="data.customer.weekly_rate > 0">
              %{{ data.customer.weekly_rate }}
              <i class="fa-regular fa-arrow-up-right" v-if="data.customer.weekly_rate > 0"></i>
            </span>
            <span
              class="down"
              v-if="data.customer.weekly_rate < 0">
              <span v-if="data.customer">%{{ data.customer.weekly_rate }}</span>
              <i class="fa-regular fa-arrow-down-right"></i>
            </span>
          </div>
          <p class="dashboard-widget-text" v-if="data.customer.count >0">
            {{ $t('Total conversion rate') }}
            <b>%{{ data.customer.conversion_rate }}</b>
          </p>
          <p class="dashboard-widget-text" v-else>
            {{ $t('No data found to display.')}}
          </p>
        </div>
      </b-col>

      <b-col
          sm="12"
          md="4"
          lg="4">
        <div class="dashboard-widget-card">
          <p class="dashboard-widget-title">{{ $t('Cars') }}</p>
          <div class="dashboard-widgets-flex">
            <p class="dashboard-widgets-data">
              {{ data.car.count }}
            </p>
            <span v-if="data.car.weekly_rate > 0">
              %{{ data.car.weekly_rate }}
              <i class="fa-regular fa-arrow-up-right" v-if="data.car.weekly_rate > 0"></i>
            </span>
            <span
                class="down"
                v-if="data.car.weekly_rate < 0">
              <span v-if="data.car">%{{ data.car.weekly_rate }}</span>
              <i class="fa-regular fa-arrow-down-right"></i>
            </span>
          </div>
          <p class="dashboard-widget-text" v-if="data.car.count >0">
            {{ $t('Increase in the last 7 days') }}
            <b>{{ data.car.weekly_count }}</b>
          </p>
          <p class="dashboard-widget-text" v-else>
            {{ $t('No data found to display.')}}
          </p>
        </div>
      </b-col>

      <b-col
          sm="12"
          md="4"
          lg="4" v-if="is_servislet_dealer">
        <div class="dashboard-widget-card">
          <p class="dashboard-widget-title">{{ $t('Current Balance') }}</p>
          <div class="dashboard-widgets-flex" v-if="data.balance.order_total>=0">
            <p class="dashboard-widgets-data">
              {{ currenctFormat('tr-TR',data.balance.order_total)}}
            </p>
            <span v-if="data.balance.weekly_rate > 0">
              %{{ data.balance.weekly_rate }}
              <i class="fa-regular fa-arrow-up-right"></i>
            </span>
            <span
                class="down"
                v-if="data.balance.weekly_rate < 0">
              %{{ data.balance.weekly_rate }}
              <i class="fa-regular fa-arrow-down-right"></i>
            </span>
          </div>
          <div class="dashboard-widgets-flex opacity-0" v-else>
            <p class="dashboard-widgets-data">
              -
            </p>
          </div>
          <p class="dashboard-widget-text" v-if="data.balance.order_total >0">
            {{
              $t('{percentage}% more than the previous week', {
                percentage: data.balance.weekly_rate
              })
            }}
          </p>
          <p class="dashboard-widget-text" v-else>
            {{
              $t('No data found to display.')
            }}
          </p>
        </div>
      </b-col>

      <b-col
          sm="12"
          md="4"
          lg="4">
        <div class="dashboard-widget-card">
          <p class="dashboard-widget-title">{{ $t('Products') }}</p>
          <div class="dashboard-widgets-flex">
            <p class="dashboard-widgets-data">
              {{ data.product.count >0 ? numberWithCommas(data.product.count) : data.product.count }}
            </p>
            <span v-if="data.product.weekly_rate > 0">
              %{{ data.product.weekly_rate }}
              <i class="fa-regular fa-arrow-up-right" v-if="data.product.weekly_rate > 0"></i>
            </span>
            <span
                class="down"
                v-if="data.product.weekly_rate < 0">
              <span v-if="data.product">%{{ data.product.weekly_rate }}</span>
              <i class="fa-regular fa-arrow-down-right"></i>
            </span>
          </div>
          <p class="dashboard-widget-text" v-if="data.product.count >0">
            {{ $t('In stocks') }}
            <b>{{ numberWithCommas(data.product.in_stock) }}</b>
          </p>
          <p class="dashboard-widget-text" v-else>
            {{ $t('No data found to display.')}}
          </p>
        </div>
      </b-col>
      <b-col
        sm="12"
        md="4"
        lg="4" v-if="is_servislet_dealer">
        <div class="dashboard-widget-card">
          <p class="dashboard-widget-title">{{ $t('Services') }}</p>
          <div class="dashboard-widgets-flex">
            <p class="dashboard-widgets-data" v-if="data.service.count>=0">
              {{ data.service.count > 0 ? numberWithCommas(data.service.count) : 0 }}
            </p>
            <p class="dashboard-widgets-data opacity-0" v-else>
              -
            </p>
          </div>
          <p class="dashboard-widget-text" v-if="data.service.count>0">
            {{
              $t('{count} active services', {
                count: numberWithCommas(data.service.active_count)
              })
            }}
          </p>
          <p class="dashboard-widget-text" v-else>
            {{
              $t('No data found to display.')
            }}
          </p>
        </div>
      </b-col>

      <b-col
          sm="12"
          md="4"
          lg="4" v-if="is_servislet_dealer">
        <div class="dashboard-widget-card">
          <p class="dashboard-widget-title">{{ $t('Feedbacks') }}</p>
          <div class="dashboard-widgets-flex">
            <p class="dashboard-widgets-data">
              0
            </p>
<!--            <p class="dashboard-widgets-data opacity-0" v-else>-->
<!--              - -->
<!--            </p>-->
          </div>
<!--          <p class="dashboard-widget-text" v-if="data.service.count>0">-->
<!--            {{-->
<!--              $t('{count} active services', {-->
<!--                count: numberWithCommas(data.service.active_count)-->
<!--              })-->
<!--            }}-->
<!--          </p>-->
          <p class="dashboard-widget-text">
            {{
              $t('No data found to display.')
            }}
          </p>
        </div>
      </b-col>
    </b-row>
    <b-row v-else>
      <b-col sm="12" md="4" lg="4" v-for="i in 3">
        <div class="dashboard-widget-card">
          <content-placeholders :rounded="true">
            <content-placeholders-text :lines="3"/>
          </content-placeholders>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loader: true,
      data: {
        product: {},
        service: {},
        balance: {}
      }
    };
  },
  mounted() {
    this.getWidgetData();
  },
  computed:{
    is_servislet_dealer(){
      return JSON.parse(localStorage.getItem('show_commission'))
    }
  },
  methods: {
    numberWithCommas(x) {
      if (x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
    },
    getWidgetData() {
      this.loader = true
      this.$axios.get(`/tire/widget/home-page`, {})
        .then((response) => {
          this.data = response.data.responseData.home_page;
          this.loader = false
        })
        .catch((error) => {
          console.error(error);
        });
    },
    formatPrice(price) {
      if (price) {
        return price.toLocaleString('tr-TR', {
          style: 'currency',
          currency: 'TRY'
        });
      }
    }
  }
};
</script>

<style scoped>
.down {
  background: rgba(239, 33, 34, 0.25);
  color: #ef2122;
}
</style>
