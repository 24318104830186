<template>
  <div>
    <ul id="page-breadcrumbs">
      <router-link to="#">
        <li>
          <i class="fa-solid fa-home"></i>
        </li>
      </router-link>
      <div
        class="breadcrumbs-loop"
        v-for="(crumb, key) in breadcrumbs"
        :key="crumb.$index">
        <li>
          <i class="fa-solid fa-angle-right"></i>
        </li>
        <router-link
          :to="crumb.path"
          v-if="crumb.path"
          :class="{ 'active-tab': key + 1 === breadcrumbs.length }">
          <li>
            {{ crumb.name }}
          </li>
        </router-link>
        <li
          v-else
          class="active-tab">
          {{ crumb.name }}
        </li>
      </div>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    breadcrumbs: Array,
    default: []
  }
};
</script>

<style></style>
