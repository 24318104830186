<template>
  <div>
    <b-sidebar id="bulk-sidebar" shadow backdrop right v-model="status">
      <template #header="{ hide }">
        <div class="d-flex flex-column gap-3 w-100" style="margin-bottom: 30px;">
          <div class="d-flex justify-content-end">
            <i class="fa-solid fa-xmark cursor-pointer" v-b-toggle.bulk-sidebar></i>
          </div>
          <div class="export-title">{{$t('Bulk Transactions')}}</div>
        </div>
      </template>

      <bulk-sidebar-select :close_check="close_check" @select="selected_option = $event" :selected_option="selected_option" :selected_ids="selected_product_ids" />

      <div class="mt-3" v-if="selected_option === 'all'">
        <all-products-selects @setUpdateType="update_type = $event" @setOption="update_option = $event" @setPriceOrRate="setPriceOrRate($event)" @setDisable="disable = $event"  />
      </div>

      <div class="mt-3" v-if="selected_option === 'brand'">
        <by-brand-select @setBrandName="brand_name = $event" @setBrandId="brand_id = $event" @setUpdateType="update_type = $event" @setOption="update_option = $event" @setPriceOrRate="setPriceOrRate($event)" @setDisable="disable = $event" />
      </div>

      <div class="mt-3" v-if="selected_option === 'category'">
        <by-product-family @setCategories="categories = $event" @setUpdateType="update_type = $event" @setOption="update_option = $event" @setPriceOrRate="setPriceOrRate($event)" @setDisable="disable = $event" />
      </div>

      <div class="mt-3" v-if="selected_option === 'selected'">
        <by-selected-products @setUpdateType="update_type = $event" @setOption="update_option = $event" @setPriceOrRate="setPriceOrRate($event)" @setDisable="disable = $event"/>
      </div>

      <div class="mt-3" v-if="selected_option === 'dot'">
        <by-dot-select @setYear="year = $event" @setUpdateType="update_type = $event" @setOption="update_option = $event" @setPriceOrRate="setPriceOrRate($event)" @setDisable="disable = $event" />
      </div>

      <div class="mt-3" v-if="selected_option === 'on_sale'">
        <on-sale-select @setUpdateType="update_type = $event" @setOption="update_option = $event" @setPriceOrRate="setPriceOrRate($event)" @setDisable="disable = $event"  />
      </div>

      <b-form-checkbox class="mt-3" v-model="close_check" @input="setOption()">{{$t('Close All Stocks')}}</b-form-checkbox>

      <template #footer="{ hide }">
        <b-button class="export-btn" @click="openWarningModal()" :disabled="disable" v-if="!close_check">{{$t('Apply')}}</b-button>
        <b-button class="export-btn" @click="openWarningModal();" v-else>{{$t('Apply')}}</b-button>
      </template>
    </b-sidebar>


    <b-modal id="warning-modal2" ref="warning-modal2" size="md" centered hide-header>
      <div class="col-sm-12 space-between px-0">
        <p class="customer-add-modal-title"></p>

        <i class="fa-solid fa-xmark cursor-pointer" @click="$refs['warning-modal2'].hide()"></i>
      </div>

      <div class="dynamic-text">
        <div v-if="locale === 'tr'">
          <div v-if="!close_check">
            <span class="fw-bold" v-if="selected_option === 'all'">Tüm ürünler</span>
            <span class="fw-bold" v-else-if="selected_option === 'brand'">{{brand_name}} ürünleri</span>
            <span class="fw-bold" v-else-if="selected_option === 'category'">Seçili ürün aileleri</span>
            <span class="fw-bold" v-else-if="selected_option === 'selected'">Seçili ürünler</span>
            <span class="fw-bold" v-else-if="selected_option === 'year'">{{year}} yılına ait ürünler</span>
            <span class="fw-bold" v-else>Satıştaki ürünler</span>
            için
            <span v-if="update_type === 'activate'" class="fw-bold">stokları sıfırlamak</span>
            <span v-else>
            fiyatı
            <span class="fw-bold" v-if="update_option === 'increase'">{{update_type === 'percent' ? '%' : '₺'}} {{update_type === 'percent' ? price_rate : price}} arttırmak</span>
            <span class="fw-bold" v-else-if="update_option === 'decrease'">{{update_type === 'percent' ? '%' : '₺'}} {{update_type === 'percent' ? price_rate : price}} azaltmak</span>
            <span class="fw-bold" v-else>{{price}} TL yapmak</span>
          </span>
            istediğinize emin misiniz?
          </div>

          <div v-else>
            <span class="fw-bold">Tüm ürünler</span> için <span class="fw-bold">stokları sıfırlamak</span> istediğinize emin misiniz?
          </div>
        </div>
        <div v-else>
          <div v-if="!close_check">
            Are you sure you want to
            <span v-if="update_type === 'activate'" class="fw-bold">reset stocks</span>
            <span v-else>
            <span class="fw-bold" v-if="update_option === 'increase'">increase {{update_type === 'percent' ? '%' : '₺'}} {{update_type === 'percent' ? price_rate : price}}</span>
            <span class="fw-bold" v-else-if="update_option === 'decrease'">decrease {{update_type === 'percent' ? '%' : '₺'}} {{update_type === 'percent' ? price_rate : price}}</span>
            <span class="fw-bold" v-else>set {{price}} TL </span> the price
          </span>
            for
            <span class="fw-bold" v-if="selected_option === 'all'">all products</span>
            <span class="fw-bold" v-else-if="selected_option === 'brand'">products of {{brand_name}}</span>
            <span class="fw-bold" v-else-if="selected_option === 'category'">selected product categories</span>
            <span class="fw-bold" v-else-if="selected_option === 'selected'">selected products</span>
            <span class="fw-bold" v-else-if="selected_option === 'year'">products for {{year}}</span>
            <span class="fw-bold" v-else>on sale products</span>
            ?
          </div>

          <div v-else>
            Are you sure you want to <span class="fw-bold">reset stocks for all products</span>?
          </div>
        </div>
      </div>

      <template #modal-footer="{}">
        <button class="update-btn" @click="controlProductIds()" v-if="!close_check">
          {{ $t('Update') }}
        </button>
        <button class="update-btn" @click="reset()" v-else>
          {{ $t('Update') }}
        </button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import BulkSidebarSelect from "@/components/products/selects/BulkSidebarSelect.vue";
import AllProductsSelects from "@/components/products/bulk/AllProductsSelects.vue";
import ByBrandSelect from "@/components/products/bulk/ByBrandSelect.vue";
import ByDotSelect from "@/components/products/bulk/ByDotSelect.vue";
import BySelectedProducts from "@/components/products/bulk/BySelectedProducts.vue";
import ByProductFamily from "@/components/products/bulk/ByProductFamily.vue";
import OnSaleSelect from "@/components/products/bulk/OnSaleSelect.vue";

export default {
  name: 'BulkSidebar',
  components:{
    ByProductFamily,
    BulkSidebarSelect,
    AllProductsSelects,
    ByBrandSelect,
    ByDotSelect,
    BySelectedProducts,
    OnSaleSelect
  },
  props: {
    selected_product_ids: {
      type: Array,
      default: []
    }
  },
  data(){
    return{
      status: false,
      disable: true,
      close_check: false,
      selected_option: null, // ilk seçilen seçenek
      update_type: null, // güncelleme tipi
      update_option: null, // arttır azalt seçeneği için
      price: null,
      price_rate: null,
      brand_name: null,
      brand_id: null,
      categories: null,
      year: null
    }
  },
  watch:{
      status(){
        if(!this.status){
          this.selected_option = null
          this.disable = true
          this.update_type = null
          this.update_option = null
          this.price = null
          this.price_rate = null
          this.brand_name = null
          this.brand_id = null
          this.categories = null
          this.year = null
          this.close_check = false
        }
      },
    // close_check(){
    //   this.selected_option = null
    //   this.disable = true
    //   this.update_type = null
    //   this.update_option = null
    //   this.price = null
    //   this.price_rate = null
    //   this.brand_name = null
    //   this.brand_id = null
    //   this.categories = null
    //   this.year = null
    // }
  },
  computed:{
    locale(){
      return localStorage.getItem('locale');
    }
  },
  methods:{
    openWarningModal(){
      this.$refs["warning-modal2"].show()
    },
    controlProductIds(){
      if(this.selected_option === 'selected'){
        if(this.selected_product_ids.length>0){
          this.multipleUpdate()
        }else{
          this.$swal.fire({
            iconHtml: '<img src="/img/error.svg" width="32" height="32">',
            position: 'top-end',
            title: this.$i18n.t('Please select products from table.'),
            showConfirmButton: false,
            timer: 3000,
            toast: true,
            customClass: {
              icon: 'swal2-no-border'
            }
          });
          this.$refs["warning-modal2"].hide();
        }
      }else{
        this.multipleUpdate()
      }
    },
    multipleUpdate(){
      var payload = {
        product_group: this.selected_option,
        update_type: this.update_type,
        option: this.update_option
      }
      if(this.price_rate){
        payload.price_rate = this.price_rate
      }
      if(this.price){
        payload.price = this.parsePrice(this.price)
      }
      if(this.year){
        payload.year = this.year
      }
      if(this.brand_id){
        payload.brand_ids = [this.brand_id]
      }
      if(this.categories){
        payload.categories = this.categories
      }
      if(this.selected_product_ids.length>0){
        payload.product_ids = this.selected_product_ids
      }

      this.$axios.post('/tire/products/multiple-update',payload).then(response=>{
        if(response.data.status){
          this.$swal.fire({
            iconHtml: '<img src="/img/success.svg" width="32" height="32">',
            position: 'top-end',
            title: this.$i18n.t('Your batch update has been received, this may take a few minutes to complete.'),
            showConfirmButton: false,
            timer: 3000,
            toast: true,
            customClass: {
              icon: 'swal2-no-border'
            }
          });
          this.$refs["warning-modal2"].hide();
          this.$root.$emit('bv::toggle::collapse', 'bulk-sidebar')
        }
      }).catch(error=>{
        var message = error.response.data.responseData.error ? error.response.data.responseData.error[0].message : error.response.data.responseData.message;
        this.$swal.fire({
          iconHtml: '<img src="/img/error.svg" width="32" height="32">',
          position: 'top-end',
          title: message,
          showConfirmButton: false,
          timer: 2500,
          toast: true,
          customClass: {
            icon: 'swal2-no-border'
          }
        });
      })
    },
    setPriceOrRate(event){
      if(this.update_type === 'percent'){
        this.price_rate = event
        this.price = null
      }else {
        this.price = event
        this.price_rate = null
      }
    },
    parsePrice(str){
      return parseFloat(str.replace(/\./g, '').replace(',', '.'))
    },
    setOption(){
      if(this.close_check){
        this.selected_option = null
      }
    },
    reset(){
      var payload = {
        product_group: 'all',
        update_type: 'activate'
      }

      this.$axios.post('/tire/products/multiple-update',payload).then(response=>{
        if(response.data.status){
          this.$swal.fire({
            iconHtml: '<img src="/img/success.svg" width="32" height="32">',
            position: 'top-end',
            title: this.$i18n.t('Your batch update has been received, this may take a few minutes to complete.'),
            showConfirmButton: false,
            timer: 3000,
            toast: true,
            customClass: {
              icon: 'swal2-no-border'
            }
          });
          this.$refs["warning-modal2"].hide();
          this.$root.$emit('bv::toggle::collapse', 'bulk-sidebar')
        }
      }).catch(error=>{
        var message = error.response.data.responseData.error ? error.response.data.responseData.error[0].message : error.response.data.responseData.message;
        this.$swal.fire({
          iconHtml: '<img src="/img/error.svg" width="32" height="32">',
          position: 'top-end',
          title: message,
          showConfirmButton: false,
          timer: 2500,
          toast: true,
          customClass: {
            icon: 'swal2-no-border'
          }
        });
      })
    }
  }
}
</script>

<style scoped>
.dynamic-text{
  font-size: 16px;
  margin-top: 2rem;
  text-align: center;
}
.update-btn {
  padding: 8px 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: .5rem;
  color: #f9f9f9;
  cursor: pointer;
  border-radius: 5px;
  background-color: #06a5d0;
  border: 1px solid #06a5d0;
  font-size: 14px;
}
.export-btn {
  background-color: #06a5d0;
  border: 1px solid #06a5d0;
  color: #F4F4F4;
  cursor: pointer;
  border-radius: 10px;
  width: 100%;
  padding: 12px;
}

.fa-xmark {
  font-size: 24px;
}

.export-title {
  font-size: 24px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
</style>

<style>
#bulk-sidebar {
  padding: 30px !important;
  background-color: #1C1C1C !important;
  color: #f4f4f4 !important;
  border-radius: 40px 0 0 40px !important;
}

#bulk-sidebar .b-sidebar-header {
  padding: 0 !important;
}

#bulk-sidebar .custom-control-label{
  font-size: 13px !important;
  padding-top: 2px !important;
  font-weight: 400 !important;
  text-align: left !important;
  color: #f4f4f4 !important;
}

#warning-modal2___BV_modal_footer_,#add-user-modal___BV_modal_footer_,#update-user-modal___BV_modal_footer_ {
  border-top: none !important;
}
</style>
