import Vue from 'vue';
import axios from 'axios';

const TOKEN = localStorage.userData
  ? 'Bearer ' + JSON.parse(localStorage.userData).access_token
  : '';

const lang = localStorage.getItem("locale") ? localStorage.getItem("locale") : 'tr'

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_URL ? process.env.VUE_APP_URL+'/api/v1/web/main' : 'https://staging-pro-api.servislet.com/api/v1/web/main',
  headers: {
    Authorization: TOKEN,
    'srvslt-locale': lang
  }
});
export default axiosInstance;

Vue.prototype.$axios = axiosInstance;
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.data.statusCode === 71001) {
      localStorage.removeItem('userData');
      window.location.href = '/login';
    }

    if (error.response.data.statusCode === 'token_not_found') {
      localStorage.removeItem('userData');
      window.location.href = '/login';
    }

    return Promise.reject(error);
  }
);
