<template>
  <div>
    <div class="component-container" v-click-outside="close">
      <div @click="show = !show;" :class="show ? 'opened-border' : 'closed-border'" class="component__select">

       <div class="d-flex align-items-center">
         <img src="/icons/car_icon.png" alt="Araç Tipi" width="28" class="mr-2" />
         <span class="component__select--name text-nowrap mr-2">{{content_will_select}}</span>
         <span v-if="selected_array.length > 0" class="selected_count">{{ selected_array.length }}</span>
       </div>

        <div class="icons">
          <i class="fa-solid fa-chevron-up" v-if="show" />
          <i class="fa-solid fa-chevron-down" v-else />
        </div>
      </div>
      <div v-if="show" class="component__select-options">
        <div v-for="option in options" class="select--option" @click="setItem(option); show=true;">
          <div  :class=" selected_array.includes(option) ? 'selected-option' : ''">
            {{ option.name }}
            <i class="fas fa-check" v-if="selected_array.includes(option)"></i>
          </div>
        </div>

        <div class="apply" v-if="options.length > 0">
          <button
              class="apply-btn"
              @click="getResult()">
            {{$t('Apply')}}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vClickOutside from 'v-click-outside';
import i18n from "@/i18n";

export default {
  name: 'CarTypeSelect',
  props: {
    content_will_select: {
      type: String,
      default: i18n.t('Choose')
    },
    selected_count: {
      type: Number,
      default: 0
    }
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  data() {
    return {
      options: [
        {
          icon: '/icons/search_car.svg',
          name: this.$i18n.t('Passenger')+', SUV, 4x4',
          code: 'passenger,suv,van'
        },
        {
          icon: '/icons/motorcycle.svg',
          name: this.$i18n.t('Motorcycle'),
          code: 'motorcycle'
        },
        {
          icon: '/icons/heavy.svg',
          name: this.$i18n.t('Truck'),
          code: 'truck'
        }
      ],
      show: false,
      search: '',
      model: '',
      selected_array: []
    };
  },
  mounted(){
    this.assignBrands();
  },
  methods:{
    assignBrands() {
      if (this.$route.query.car_type) {
        this.selected_array = [];
        if (this.$route.query.car_type.includes('passenger,suv,van')) {
          this.selected_array.push(this.options[0]);
        }
        let split_arr = this.$route.query.car_type.split(',');
        for (let i in split_arr) {
          this.options.forEach((value) => {
            if (value.code === split_arr[i]) {
              this.selected_array.push(value);
            }
          });
        }
        this.getResult();
      }
    },
    close() {
      this.show = false;
      this.search = '';
    },
    setItem(item) {
      if (this.selected_array.includes(item)) {
        this.selected_array.splice(this.selected_array.indexOf(item), 1);
      } else {
        this.selected_array.push(item);
      }
      this.model = this.selected_array;
    },
    removeModel() {
      this.model = '';
    },
    getResult() {
      if (this.model.length > 0) {
        let brands = this.selected_array
            .map(function (item) {
              return item.code;
            })
            .join(',');

        var url = new URL(window.location.href);
        url.searchParams.set('car_type', brands);

        if (this.model === '') {
          this.$route.query.car_type = '';
          url.searchParams.delete('car_type');
        } else {
          this.$route.query.car_type = brands;
        }
        url.search = decodeURIComponent(url.search);
        window.history.pushState({}, '', url);
        this.$emit('filterByBrand');
      }
      if (this.selected_array.length === 0) {
        const currentQuery = { ...this.$route.query };
        delete currentQuery.car_type;
        const queryString = Object.keys(currentQuery)
            .map((key) => `${key}=${currentQuery[key]}`)
            .join('&');
        window.location.href = `/products?${queryString}`;
        this.$emit('filterByBrand');
      }
      this.show = false
    }
  }
};
</script>

<style scoped>
.component__select {
  height: 40px;
  line-height: 38px;
  width: 190px;
  grid-template-columns: 10fr 1fr;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  background-color: white;
  border: 1px solid #06a5d0;
  color: #06a5d0;
  padding: 0 12px;
}

.opened-border {
  border-radius: 5px 5px 0 0;
  border-bottom: 1px solid transparent;
}

.closed-border {
  border-radius: 5px;
}

.component__select--name {
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}

.component__select-options {
  max-height: 180px;
  border: 1px solid #06a5d0;
  border-radius: 0 0 6px 6px;
  border-top: none;
  overflow: auto;
  position: absolute;
  z-index: 1500;
  max-width: 500px;
  width: 190px;
  margin: 0;
  padding: 0;
  background-color: #fff;
}

.select--option {
  height: 38px;
  display: flex;
  font-size: 14px;
  font-weight: 400;
  align-items: center;
  padding: 0 12px;
  background-color: white;
  cursor: pointer;
}

.select--option:hover {
  background-color: #06A5D01F;
  opacity: 1;
  color: #06a5d0;
}

.select--option:last-child {
  border-bottom: none;
  border-radius: 0 0 5px 5px;
}

.select--option input {
  display: none;
}

.component__select i {
  font-size: 12px;
  cursor: pointer;
  color: #06a5d0;
}
.selected-option{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: #06a5d0;
  font-size: 14px;
  font-weight: 500;
}

.selected_count {
  background-color: rgba(6, 165, 208, 0.16);
  color: var(--servislet-blue);
  width: 10px;
  height: 10px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  margin-left: 0.5rem;
}

.apply {
  padding: 12px;
  background-color: #fff;
}

.apply-btn {
  border: 1px solid var(--servislet-blue);
  width: 100%;
  height: 35px;
  display: flex;
  font-size: 14px;
  font-weight: 500;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background-color: var(--servislet-blue);
  color: #f4f4f4;
}

.apply-btn:hover {
  background-color: var(--servislet-blue);
  color: #f4f4f4;
}

@media screen and (max-width: 1200px) {
  .component__select {
    width: 135px;
  }
  .component__select-options {
    width: 135px;
  }
}
</style>
