<template>
  <div>
    <PageBreadcrumbs :breadcrumbs="breadcrumbs" />

    <div class="widgets">
      <div id="page-widgets-wrapper">
        <div class="row">
          <div
            class="col-lg-3"
            v-for="widget in 4"
            :key="widget.$index">
            <PageWidgets
              :title="$t('Individual Customer')"
              mainData="1.225.246"
              number="20.9%"
              :icon="true"
              :text="$t('vs last week')" />
          </div>
        </div>
      </div>
    </div>

    <div
      id="products-listing-table-wrapper"
      class="mt-5 py-4">
      <div class="products-double-flex">
        <div class="products-double-flex-left"></div>

        <div class="products-double-flex-right">
          <div
            class="products-double-flex-right-actions"
            v-for="action in 4"
            :key="action.$index">
            <span>
              <i class="fa-solid fa-search"></i>
              <p>{{ $t('Search') }}</p>
            </span>
          </div>
        </div>
      </div>
      <div class="product-listing-table">
        <table>
          <tr class="product-listing-table-head">
            <th>Ürün Detayı</th>
            <th>Sipariş ID</th>
            <th>Müşteri</th>
            <th>Tarih</th>
            <th>Değerlendirmeler</th>
            <th></th>
          </tr>
          <tr
            v-for="item in 10"
            :key="item.$index">
            <td class="flex-center-start">
              <div
                class="product-listing-img-wrapper"
                style="padding-right: 12px">
                <img
                  src="../../assets/images/tire-product-img.png"
                  alt="" />
              </div>
              <!-- <img src="https://i.pravatar.cc/200" class="table-customer-avatar-img"> -->
              Pilot Sport Acoustic K1 Ml 91V TL 205/55 R16
            </td>
            <td>#341768</td>
            <td>Ali Doğan</td>
            <td>10/09/2022</td>
            <td>
              Lorem ipsum dolor sit amet consectetur. Eu aliquam turp...
              <br />
              <StarRating />
            </td>
            <td>
              <button class="show-in-website-btn">
                <i class="fa-solid fa-eye"></i>
              </button>
            </td>
          </tr>
        </table>
        <div id="table-info-and-pagination">
          <div class="table-results-info">
            <p>Showing 1 to 20 of 100 entries</p>
          </div>
          <div class="pagination-wrapper">
            <div class="pagination">
              <a
                href="#"
                class="pagination-arrows"
                ><i class="fa-solid fa-angle-left"></i
              ></a>
              <div class="pagination-numbers">
                <a href="#">1</a>
                <a
                  href="#"
                  class="active"
                  >2</a
                >
                <a href="#">3</a>
                <a href="#">4</a>
                <a href="#">5</a>
                <a href="#">6</a>
              </div>
              <a
                href="#"
                class="pagination-arrows"
                ><i class="fa-solid fa-angle-right"></i
              ></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import PageBreadcrumbs from '../../components/PageBreadcrumbs.vue';
import PageWidgets from '../../components/PageWidgets.vue';
import StarRating from '../../components/StarRating.vue';
export default {
  components: {
    PageBreadcrumbs,
    PageWidgets,
    StarRating
  },
  data() {
    return {
      breadcrumbs: [
        {
          name: this.$i18n.t('Feedbacks'),
          path: '/#'
        }
      ],
      select: false
    };
  },
  methods: {
    checkUncheck(checkBox) {
      this.select = !this.select;
      var get = document.getElementsByName('single-payment-checkbox');
      for (var i = 0; i < get.length; i++) {
        if (get[i].checked === true) {
          get[i].checked = false;
        } else {
          get[i].checked = true;
        }
        // without if => get[i].checked = checkBox.checked;}
      }
    }
  }
};
</script>

<style></style>
