<template>
  <DefaultLayout>
    <div>
      <PageBreadcrumbs :breadcrumbs="breadcrumbs" />
      <div v-if="!loader">
        <div class="products-double-flex">
          <div class="products-double-flex-left">
            <p class="page-title">{{ $t('Services') }}</p>
          </div>
          <div class="products-double-flex-right">
            <gray-select
              :options="options"
              :selected_key="selected"
              :show_close_icon="false"
              @select="$event === 'tire' ? $router.push('/products') : ''" />
          </div>
        </div>
        <div
          id="products-listing-table-wrapper"
          class="mt-5 py-4">
          <div class="products-double-flex">
            <div class="products-double-flex-left"></div>
            <div class="products-double-flex-right">
              <div class="products-double-flex-right-actions">
                <span>
                  <i class="fa-solid fa-search"></i>
                  <p>{{ $t('Search') }}</p>
                </span>
              </div>
              <div class="products-double-flex-right-actions">
                <span>
                  <i class="fa-regular fa-file-import"></i>
                  <p>{{ $t('İmport') }}</p>
                </span>
              </div>
              <div class="products-double-flex-right-actions">
                <span>
                  <i class="fa-solid fa-filter"></i>
                  <p>{{ $t('Filter') }}</p>
                </span>
              </div>
              <div class="products-double-flex-right-actions">
                <span>
                  <i class="fa-regular fa-link-simple"></i>
                  <p>{{ $t('Send') }}</p>
                </span>
              </div>
              <div class="products-double-flex-right-actions">
                <span>
                  <i class="fa-regular fa-arrow-up-right-from-square"></i>
                  <p>{{ $t('Export') }}</p>
                </span>
              </div>
            </div>
          </div>
          <div class="product-listing-table">
            <table>
              <tr class="product-listing-table-head">
                <th>
                  <label class="product-listing-checkbox-label">
                    <input
                      type="checkbox"
                      @click="checkUncheck()" />
                    <span class="checkmark"></span>
                  </label>
                </th>
                <th>{{ $t('Service Name') }}</th>
                <th>{{ $t("Price 15'' and Below") }}</th>
                <th>{{ $t("Price 16''") }}</th>
                <th>{{ $t("Price 17'' and Above") }}</th>
                <th>{{ $t('Sale') }}</th>
              </tr>
              <tr
                v-for="service in services.items"
                :key="service.$index">
                <td>
                  <label class="product-listing-checkbox-label">
                    <input
                      name="single-product-checkbox"
                      type="checkbox" />
                    <span class="checkmark"></span>
                  </label>
                </td>
                <td>{{ service.name }}</td>
                <td>
                  <div
                    v-if="price_15_focus === true && service.id === focusDivId"
                    class="product-listing-input-focus-wrapper">
                    <div
                      class="focus-bg"
                      @click="price_15_focus = false"></div>
                    <div
                      class="product-listing-input-focus"
                      style="width: 200px">
                      <b-form-input
                        v-model="service.update_price_15"
                        v-money="money"
                        class="bg-transparent" />

                      <div class="form-label fs-12 d-flex align-items-center gap-1" style="font-weight: 400">{{$t('Assembly Fee')}}
                        <i class="fa-solid fa-info-circle font-servislet cursor-pointer"  v-b-tooltip.hover.right :title="$t('Additional service prices for products from other vendors')"></i>
                      </div>

                      <money
                          v-model="service.service_price_15"
                          class="bg-transparent fs-12"
                          v-bind="money" />

<!--                        <b-form-input-->
<!--                            v-model="service.service_price_15"-->
<!--                            class="bg-transparent"-->
<!--                            v-money="money"-->
<!--                        />-->
                      <button
                        class="product-listing-focus-edit-btn"
                        @click="updatePrice(service)">
                        {{ $t('Edit') }}
                      </button>
                    </div>
                  </div>
                  <div class="product-listing-input-wrapper">
                    <input
                      id="product-listing-price-input"
                      v-model="service.price_15"
                      type="text"
                      @click="
                        focusDivId = service.id;
                        price_15_focus = true;
                        service.update_price_15 = service.price_15;
                      " />
                  </div>
                </td>

                <td>
                  <div
                    v-if="price_16_focus === true && service.id === focusDivId"
                    class="product-listing-input-focus-wrapper">
                    <div
                      class="focus-bg"
                      @click="price_16_focus = false"></div>
                    <div
                      class="product-listing-input-focus"
                      style="width: 200px">
                      <b-form-input
                        v-model="service.update_price_16"
                        v-money="money"
                        class="bg-transparent" />

                      <div class="form-label fs-12 d-flex align-items-center gap-1" style="font-weight: 400">{{$t('Assembly Fee')}}
                        <i class="fa-solid fa-info-circle font-servislet cursor-pointer"  v-b-tooltip.hover.right :title="$t('Additional service prices for products from other vendors')"></i>
                      </div>

                      <money
                          v-model="service.service_price_16"
                          class="bg-transparent fs-12"
                          v-bind="money" />
                      <button
                        class="product-listing-focus-edit-btn"
                        @click="updatePrice(service)">
                        {{ $t('Edit') }}
                      </button>
                    </div>
                  </div>
                  <div class="product-listing-input-wrapper">
                    <input
                      id="product-listing-price-input"
                      v-model="service.price_16"
                      type="text"
                      @click="
                        focusDivId = service.id;
                        price_16_focus = true;
                        service.update_price_16 = service.price_16;
                      " />
                  </div>
                </td>

                <td>
                  <div
                    v-if="price_17_focus === true && service.id === focusDivId"
                    class="product-listing-input-focus-wrapper">
                    <div
                      class="focus-bg"
                      @click="price_17_focus = false"></div>
                    <div
                      class="product-listing-input-focus"
                      style="width: 200px">
                      <b-form-input
                        v-model="service.update_price_17"
                        v-money="money"
                        class="bg-transparent" />

                      <div class="form-label fs-12 d-flex align-items-center gap-1" style="font-weight: 400">{{$t('Assembly Fee')}}
                        <i class="fa-solid fa-info-circle font-servislet cursor-pointer"  v-b-tooltip.hover.right :title="$t('Additional service prices for products from other vendors')"></i>
                      </div>

                      <money
                          v-model="service.service_price_17"
                          v-bind="money"
                          class="bg-transparent fs-12"
                      />
                      <button
                        class="product-listing-focus-edit-btn"
                        @click="updatePrice(service)">
                        {{ $t('Edit') }}
                      </button>
                    </div>
                  </div>
                  <div class="product-listing-input-wrapper">
                    <input
                      id="product-listing-price-input"
                      v-model="service.price_17"
                      type="text"
                      @click="
                        focusDivId = service.id;
                        price_17_focus = true;
                        service.update_price_17 = service.price_17;
                      " />
                  </div>
                </td>
                <td>
                  <b-form-checkbox
                    :id="'checkbox-' + service.id"
                    v-model="service.is_active"
                    :unchecked-value="false"
                    :value="true"
                    name="checkbox-1"
                    switch
                    @change="changeStatus(service)" />
                </td>
              </tr>
            </table>
            <div
              v-if="!loader && services.items.length>0"
              id="table-info-and-pagination">
              <div class="table-results-info">
                <p>
                  {{$t('{total} {from} {start}-{end} is showing.',{total: services.pagination.total, from: $t('from services'), start: services.items.length>0 ? ( services.pagination.current_page === 1 ? 1 : ((services.pagination.current_page - 1) * services.pagination.per_page) + 1 ) : 0, end: services.pagination.total_page === services.pagination.current_page ? services.pagination.total : services.pagination.current_page * services.pagination.per_page})}}
                </p>
              </div>
              <div class="pagination-wrapper">

                <b-pagination
                    v-model="current_page"
                    :total-rows="services.pagination.total"
                    :per-page="services.pagination.per_page"
                    align="center"
                    pills
                    class="my-1"
                    @input="getProductServices()"/>
              </div>
            </div>

            <div v-if="!loader && services.items.length === 0" class="d-flex flex-column align-items-center">
              <p class="table-no-text"> {{
                  $t('No {data} found to list.', {data: $t('Service').toLocaleLowerCase()})
                }}</p>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="loader"
        class="loader">
        <content-placeholders :rounded="true">
          <content-placeholders-img />
          <content-placeholders-text :lines="10" />
        </content-placeholders>
      </div>

      <b-modal
        id="warning-modal"
        ref="warning-modal"
        :no-close-on-backdrop="true"
        centered
        hide-footer
        hide-header
        size="md">
        <div class="warning-modal-body">
          <div class="warning-modal-header">
            <i
              class="fa-solid fa-xmark cursor-pointer"
              @click="$refs['warning-modal'].hide()"></i>
          </div>
          <div class="warning-modal-content-text text-center">
            {{ change_message }}
          </div>
          <div class="warning-modal-buttons">
            <b-button
              class="cancel-button"
              @click="
                $refs['warning-modal'].hide();
                changed_service.is_active = !changed_service.is_active;
              "
              >{{ $t('Cancel') }}
            </b-button>
            <b-button
              class="delete-button"
              @click="changeStatusOfService()"
              >{{ change_button_text }}
            </b-button>
          </div>
        </div>
      </b-modal>
    </div>
  </DefaultLayout>
</template>

<script>
/* eslint-disable */
import DefaultLayout from '@/layouts/default';
import PageBreadcrumbs from '../../components/PageBreadcrumbs.vue';
import services from '@/collections/services';

export default {
  components: {
    PageBreadcrumbs,
    DefaultLayout
  },
  data() {
    return {
      breadcrumbs: [
        {
          name: this.$i18n.t('Products'),
          path: '/products'
        },
        {
          name: this.$i18n.t('Services')
        }
      ],
      options: [
        {
          id: 'tire',
          name: this.$i18n.t('Tire')
        },
        {
          id: 'service',
          name: this.$i18n.t('Services')
        }
      ],
      focusDivId: '',
      loader: true,
      per_page: 20,
      current_page: 1,
      services: {
        items: [],
        pagination: {}
      },
      price: '',
      quantity: '',
      is_active: true,
      year: '',
      selected: {
        id: 'service',
        name: this.$i18n.t('Services')
      },
      price_15_focus: false,
      price_16_focus: false,
      price_17_focus: false,
      change_message: '',
      change_button_text: '',
      changed_service: {},
      money: {
        decimal: ',',
        thousands: '.',
        prefix: '₺',
        suffix: '',
        precision: 2,
        masked: false
      }
    };
  },
  mounted() {
    this.getProductServices();
  },
  methods: {
    getProductServices() {
      this.loader = true;
      this.$axios
        .get(`/tire/products/services`, {
          params: {
            page: this.current_page,
            limit: this.per_page
          }
        })
        .then((response) => {
          this.services.items = services.list(
            response.data.responseData.products.items
          );
          this.services.pagination =
            response.data.responseData.products.pagination;
          this.loader = false;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    updateStock(product) {
      this.loader = true;
      let body = {
        // price: this.price.substring(1),
        price: this.parsePrice(this.price),
        quantity: this.quantity,
        is_active: this.is_active,
        year: this.year
      };
      this.$axios
        .put(`/tire/stocks/${product.id}`, body, {})
        .then((response) => {
          if (response.status) {
            this.$swal.fire({
              iconHtml: '<img src="/img/success.svg" width="32" height="32">',
              position: 'top-end',
              title: this.$i18n.t('The product successfully updated.'),
              showConfirmButton: false,
              timer: 2500,
              toast: true,
              customClass: {
                icon: 'swal2-no-border'
              }
            });
          }
          this.getProducts();
          this.loader = false;
        })
        .catch((error) => {
          this.$swal.fire({
            iconHtml: '<img src="/img/error.svg" width="32" height="32">',
            position: 'top-end',
            title: this.$i18n.t('An error occurred, please try again later.'),
            showConfirmButton: false,
            timer: 2500,
            toast: true,
            customClass: {
              icon: 'swal2-no-border'
            }
          });
        });
    },
    parsePrice(formattedPrice) {
      if (formattedPrice) {
        return parseFloat(formattedPrice.replace(/\D/g, '').replace(',', '.'));
      }
    },
    checkUncheck(checkBox) {
      var get = document.getElementsByName('single-product-checkbox');
      for (var i = 0; i < get.length; i++) {
        if (get[i].checked === true) {
          get[i].checked = false;
        } else {
          get[i].checked = true;
        }
        // without if => get[i].checked = checkBox.checked;}
      }
    },
    changeStatus(service) {
      this.changed_service = service;
      this.change_message =
        service.is_active === false
          ? this.$i18n.t('Are you sure you want to close the service for sale?')
          : this.$i18n.t(
              'Are you sure you want to put the service up for sale?'
            );
      this.change_button_text =
        service.is_active === false
          ? this.$i18n.t('Close')
          : this.$i18n.t('Open');
      this.$refs['warning-modal'].show();
    },
    updatePrice(service) {
      var price_15 =
        typeof service.update_price_15 === 'string'
          ? this.parsePrice(service.update_price_15).toString()
          : service.update_price_15;
      var price_16 =
        typeof service.update_price_16 === 'string'
          ? this.parsePrice(service.update_price_16).toString()
          : service.update_price_16;
      var price_17 =
        typeof service.update_price_17 === 'string'
          ? this.parsePrice(service.update_price_17).toString()
          : service.update_price_17;
      this.$axios.put(`/tire/products/services`, {
          service_id: service.id,
          is_active: service.is_active,
          price_15_all: typeof service.update_price_15 === 'string' ? parseInt(price_15.substring(0, price_15.length - 2)) + parseInt(price_15.substring(price_15.length - 2, price_15.length)) / 100 : price_15 ? price_15 : 0,
          price_16_all: typeof service.update_price_16 === 'string' ? parseInt(price_16.substring(0, price_16.length - 2)) + parseInt(price_16.substring(price_16.length - 2, price_16.length)) / 100 : price_16 ? price_16 : 0,
          price_17_all: typeof service.update_price_17 === 'string' ? parseInt(price_17.substring(0, price_17.length - 2)) + parseInt(price_17.substring(price_17.length - 2, price_17.length)) / 100 : price_17 ? price_17 : 0,
          service_price_15_all: service.service_price_15,
          service_price_16_all: service.service_price_16,
          service_price_17_all: service.service_price_17
        })
        .then((response) => {
          if (response.data.status) {
            this.$swal.fire({
              iconHtml: '<img src="/img/success.svg" width="32" height="32">',
              position: 'top-end',
              title: this.$i18n.t(
                'Price information has been successfully updated.'
              ),
              showConfirmButton: false,
              timer: 2500,
              toast: true,
              customClass: {
                icon: 'swal2-no-border'
              }
            });
            this.price_15_focus = false;
            this.price_16_focus = false;
            this.price_17_focus = false;
            this.getProductServices();
          }
        })
        .catch((error) => {
          var message = error.response.data.responseData.error
            ? error.response.data.responseData.error[0].message
            : error.response.data.responseData.message;
          this.$swal.fire({
            iconHtml: '<img src="/img/error.svg" width="32" height="32">',
            position: 'top-end',
            title: message,
            showConfirmButton: false,
            timer: 2500,
            toast: true,
            customClass: {
              icon: 'swal2-no-border'
            }
          });
        });
    },
    changeStatusOfService() {
      this.$axios
        .put(`/tire/products/services`, {
          is_active: this.changed_service.is_active,
          service_id: this.changed_service.id,
          price_15_all: this.changed_service.update_price_15,
          price_16_all: this.changed_service.update_price_16,
          price_17_all: this.changed_service.update_price_17
        })
        .then((response) => {
          if (response.data.status) {
            this.$swal.fire({
              iconHtml: '<img src="/img/success.svg" width="32" height="32">',
              position: 'top-end',
              title:
                this.changed_service.is_active === false
                  ? this.$i18n.t('The service is closed for sale.')
                  : this.$i18n.t('The service is up for sale.'),
              showConfirmButton: false,
              timer: 2500,
              toast: true,
              customClass: {
                icon: 'swal2-no-border'
              }
            });
            this.$refs['warning-modal'].hide();
            this.getProductServices();
          }
        })
        .catch((error) => {
          var message = error.response.data.responseData.error
            ? error.response.data.responseData.error[0].message
            : error.response.data.responseData.message;
          this.$swal.fire({
            iconHtml: '<img src="/img/error.svg" width="32" height="32">',
            position: 'top-end',
            title: message,
            showConfirmButton: false,
            timer: 2500,
            toast: true,
            customClass: {
              icon: 'swal2-no-border'
            }
          });
        });
    }
  }
};
</script>

<style scoped>
.show-in-website-btn a {
  color: inherit;
}
.cancel-button{
  border: 1px solid #1C1C1C;
  color: #1C1C1C;
}
</style>
