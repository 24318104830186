<template>
  <div class="row">
    <div class="col-sm-12 col-md-12 col-lg-9">
      <div class="product-detail-left-wrapper">
        <div class="add-invoice-inputs">
          <p class="page-title" style="margin-bottom: 20px">
            {{$t('Product')}} / {{$t('Service')}}
          </p>
          <div id="invoice-loop-item" v-for="(order, key) in order_items" :key="order.$index">
            <div class="row">
              <div class="col-sm-12 col-lg-3">
                <div class="form-group">
                  <legend class="bv-no-focus-ring col-form-label pt-0">{{$t('Product')}}</legend>
                </div>
                <div class="choose-product-container" v-b-tooltip.hover :title="order.name" v-click-outside="(event) => order.openProductBox = false">
                  <div class="choose-product" :class="[{'shadow-sm': order.openProductBox}]" @click="openProductBox(key)">
                    <span class="choose-product-label" v-if="order.name">{{ order.name }}</span>
                    <div v-else class="icon-with-name">
                      <span class="choose-product-label">{{$t('Choose Product')}}</span>
                      <i class="fa-solid fa-circle-exclamation text-danger" v-if="order.errorMessage"></i>
                    </div>
                  </div>
                  <div class="choose-product-list" v-show="order.openProductBox">
                    <div class="choose-product-input-area">
                      <input type="text" v-model="order.searchArea" :id="`choose-product-input-${key}`"
                             class="form-control m-3 choose-product-input"
                             @keyup="searchInput(key)">
                      <b-spinner label="Spinning" small class="input-spinner" v-if="order.loading"></b-spinner>
                    </div>
                    <span class="choose-product-list-item" v-for="search in order.searchResult" :key="search.$index" @click="setSearchInput(key, search)"><strong>{{ search.brand_name }}</strong> {{search.name }} - {{ search.sku }}</span>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 col-lg-2">
                <b-form-group id="dynamic-unit-group" class="" >
                  <template #label>
                    {{$t('Unit Price')}} <i class="fa-duotone fa-circle-info" v-b-tooltip.hover :title="$t('Unit price excluding VAT')"></i>
                  </template>
                  <b-input-group>
                    <b-form-input v-model="order.price" v-money="money" @keyup="getTotal(key)"></b-form-input>
                    <b-input-group-append>
                      <b-form-select v-model="selected" :options="currency"></b-form-select>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </div>
              <div class="col-sm-12 col-lg-2">
                <b-form-group id="dynamic-unit-group" class="" :label="$t('Count')">
                  <b-input-group>
                    <b-form-input type="number" @keyup="getTotal(key)" @change="getTotal(key)"  v-model="order.quantity" :min="1" :max="order.maxQuantity"></b-form-input>
                    <b-input-group-append>
                      <b-form-select v-model="selected" :options="unit"></b-form-select>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </div>
              <div class="col-sm-12 col-lg-2">
                <b-form-group id="dynamic-unit-group" class="" :label="$t('VAT')">
                  <b-input-group append="%">
                    <input class="form-control" v-model="order.tax_rate" @keyup="getTotal(key)"></input>
                  </b-input-group>
                </b-form-group>
              </div>
              <div class="col-sm-12 col-lg-2">
                <b-form-group id="dynamic-unit-group" class="" :label="$t('Total')">
                  <b-input-group>
                    <b-form-input v-model="order.total" :id="`total-price-${key}`" @keyup="productLineTotalChange(key)" v-money="money"></b-form-input>
                    <b-input-group-append>
                      <b-form-select v-model="selected" :options="currency"></b-form-select>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </div>
              <div class="col-sm-12 col-lg-1">
                <b-form-group id="add-invoice-inline-btn-group" class="" label="" style="margin-top: 21px">
                  <b-dropdown right>
                    <template #button-content>
                      <i class="fa-solid fa-plus"></i>
                    </template>
                    <b-dropdown-item class="drop-btn-content" @click="addService(key)">
                      <i class="fa-solid fa-file"></i>
                      {{$t('Add Service')}}
                    </b-dropdown-item>
                    <b-dropdown-item class="drop-btn-content" href="#">
                        <span @click="setProductDiscount(key)" v-if="order.discounts.length == 0">
                            <i class="fa-solid fa-percent"></i>
                            {{$t('Add Discount')}}
                        </span>
                    </b-dropdown-item>
                    <b-dropdown-item class="drop-btn-content" @click="addDesc(key)">
                      <i class="fa-solid fa-file"></i>
                      {{$t('Add/Remove Description')}}
                    </b-dropdown-item>
                  </b-dropdown>
                  <button class="no-bg" v-if="key != 0" @click="deleteProductLine(key)">
                    <i class="fa-solid fa-trash"></i>
                  </button>
                </b-form-group>
              </div>
            </div>
            <div class="row" v-for="(discount, discountkey) in order.discounts" :key="discount.$index" style="display: flex; align-items: center">
              <div class="col-sm-12 col-lg-8"></div>
              <div class="col-sm-12 col-lg-3">
                <b-form-group id="dynamic-unit-group" class="" :label="$t('Discount')">
                  <b-input-group style="align-items: center" class="prepend-icon">
                    <b-input-group-prepend>
                      <b-form-select v-model="discount.discount_type_id" :options="discountType"></b-form-select>
                    </b-input-group-prepend>
                    <b-form-input v-money="money" @keyup="getTotal(key)" v-model="discount.amount" v-if="discount.type == 'amount'"></b-form-input>
                    <input type="text" @keyup="getTotal(key)" v-if="discount.type == 'rate'" v-model="discount.rate" class="form-control" />
                    <b-input-group-append>
                      <b-form-select v-model="discount.type" :options="discount_unit" @change="changeProductLineDiscountType(key, discountkey)"></b-form-select>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </div>
              <div class="col-sm-12 col-lg-1">
                <i class="fa-solid fa-trash" style="cursor: pointer; margin-left: 7px; margin-top: 14px; color: #777777" @click="deleteDiscountProduct(key, discountkey)"></i>
              </div>
            </div>
            <div id="services-lines" v-for="(service, servicekey) in order.services" :key="service.$index" v-if="order.services.length > 0">
              <div class="row">
                <div class="col-sm-12 col-lg-3">
                  <div class="product-line-services">
                    <i class="fa fa-boxes"></i>
                    <div class="choose-product-container" v-b-tooltip.hover :title="service.name" v-click-outside="(event) => service.openSearchBox = false">
                      <div class="choose-product" :class="[{'shadow-sm': service.openSearchBox}]" @click="openProductServiceBox(key, servicekey)">
                        <span class="choose-product-label" v-if="service.name">{{ service.name }}</span>
                        <div v-else class="icon-with-name">
                          <span class="choose-product-label">{{$t('Choose Service')}}</span>
                        </div>
                      </div>
                      <div class="choose-product-list" v-if="service.openSearchBox">
                        <div class="choose-product-input-area">
                          <input type="text" v-model="service.searchArea" :id="`service-box-${key}-${servicekey}`"
                                 class="form-control m-3 choose-product-input"
                                 @keyup="searchInputService(key, servicekey)">
                          <b-spinner label="Spinning" small class="input-spinner" v-if="service.loading"></b-spinner>
                        </div>
                        <span class="choose-product-list-item" v-for="search in service.searchResult"
                              :key="search.$index"
                              @click="setSearchInputService(key, servicekey, search)">{{ search.name }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-lg-2">
                  <b-form-group id="dynamic-unit-group" class="">
                    <template #label>
                      {{$t('Unit Price')}} <i class="fa-duotone fa-circle-info" v-b-tooltip.hover :title="$t('Unit price excluding VAT')"></i>
                    </template>
                    <b-input-group>
                      <b-form-input v-model="service.price" @keyup="getTotalService(key, servicekey)" v-money="money"></b-form-input>
                      <b-input-group-append>
                        <b-form-select v-model="selected" :options="currency"></b-form-select>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </div>
                <div class="col-sm-12 col-lg-2">
                  <b-form-group id="dynamic-unit-group" class="" :label="$t('Count')">
                    <b-input-group>
                      <b-form-input type="number" @change="getTotalService(key, servicekey)" @keyup="getTotalService(key, servicekey)" v-model="service.quantity" :min="1" :max="order.maxQuantity"></b-form-input>
                      <b-input-group-append>
                        <b-form-select v-model="selected" :options="unit"></b-form-select>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </div>
                <div class="col-sm-12 col-lg-2">
                  <b-form-group id="dynamic-unit-group" class="" :label="$t('VAT')">
                    <b-input-group append="%">
                      <b-form-input v-model="service.tax_rate" @keyup="getTotalService(key, servicekey)"></b-form-input>
                    </b-input-group>
                  </b-form-group>
                </div>
                <div class="col-sm-12 col-lg-2">
                  <b-form-group id="dynamic-unit-group" class="" :label="$t('Total')">
                    <b-input-group>
                      <b-form-input v-model="service.total" v-money="money" :id="`total-price-service-${key}-${servicekey}`"  @keyup="productLineTotalChangeDiscount(key, servicekey)"></b-form-input>
                      <b-input-group-append>
                        <b-form-select v-model="selected" :options="currency"></b-form-select>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </div>
                <div class="col-sm-12 col-lg-1">
                  <b-form-group id="add-invoice-inline-btn-group" class="" label="" style="margin-top: 21px">
                    <button class="no-bg" @click="deleteProductLineService(key, servicekey)">
                      <i class="fa-solid fa-trash"></i>
                    </button>
                  </b-form-group>
                </div>
              </div>
            </div>
            <div class="products-double-flex m-0 no-border max-width-743 align-items-flex-start isDesc" v-if="order.isDesc">
              <b-form-group :label="$t('Description')+':'">
                <b-form-textarea
                  :placeholder="$t('Description')"
                  rows="3"
                  max-rows="6"
                ></b-form-textarea>
              </b-form-group>
            </div>
          </div>
          <b-button class="add-more mt-3 ml-1" @click="addProductLine">
            <i class="fa-solid fa-plus"></i>
            {{$t('Add Product')}}
          </b-button>
          <div class="bill-info">
            <div class="bill-info-line">
              <strong>{{$t('Sub Total')}}:</strong>
              <span>{{ subTotal }} ₺
                    <button class="addDiscount" @click="addDiscount">
                      <i class="fa-solid fa-plus"></i>
                    </button>
                  </span>
            </div>
            <div class="bill-info-line" v-for="(discount, key) in order_discounts" :key="discount.$index">
              <b-form-group id="dynamic-unit-group" class="" :label="$t('Sub Total Discount')" style="width: 100%">
                <b-input-group class="prepend-icon">
                  <b-input-group-prepend>
                    <b-form-select v-model="discount.discount_type_id" :options="discountType"></b-form-select>
                  </b-input-group-prepend>
                  <b-form-input v-money="money" v-model="discount.amount" v-if="discount.type == 'amount'"></b-form-input>
                  <input type="text" v-if="discount.type == 'rate'" v-model="discount.rate" class="form-control" />
                  <b-input-group-append>
                    <b-form-select v-model="discount.type" :options="discount_unit" @change="changeProductLineDiscountTypeTotal(key)"></b-form-select>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
              <button class="addDiscount" @click="deleteDiscount(key)" style="margin-top: 12px">
                <i class="fa-solid fa-trash"></i>
              </button>
            </div>
            <div class="bill-info-line">
              <strong>{{$t('Line Discount')}}:</strong>
              <span>{{ lineDiscountPrice }} ₺
                  </span>
            </div>
            <div class="bill-info-line">
              <strong>{{$t('VAT')}}</strong>
              <span>{{ totalVat }} ₺
                  </span>
            </div>
            <div class="bill-info-line">
              <strong>{{$t('Total')}}:</strong>
              <span>{{ totalPrice}} ₺</span>
            </div>
          </div>
          <div class="conti">
            <b-button class="add-more mt-5" @click="conti">
              {{$t('Continue')}}
            </b-button>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-12 col-md-12 col-lg-3">
      <div class="product-detail-right-wrapper">
        <p class="page-title" style="margin-bottom: 20px">
          {{$t('Offer')}}
        </p>
        <div class="offer-lines">
          <div class="offer-line">
            <span class="offer-line-title">{{$t('Products')}}:</span>
            <span>₺{{ totalProductPrice }}</span>
          </div>
          <div class="offer-line">
            <span class="offer-line-title">{{$t('Services')}}:</span>
            <span>₺{{ totalServicePrice }}</span>
          </div>
          <div class="offer-line">
            <span class="offer-line-title">{{$t('Total')}}:</span>
            <span>₺{{ totalPrice }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vClickOutside from 'v-click-outside'
export default {
  name: "OfferStep",
  directives: {
    clickOutside: vClickOutside.directive
  },
  computed: {
    step() {
      this.$store.state.offers.addUpdateItem.step
    },
    order_items() {
      return this.$store.state.offers.addUpdateItem.order_items
    },
    order_discounts() {
      return this.$store.state.offers.addUpdateItem.order_discounts
    },
    totalProductPrice() {
      var total = 0
      var _this = this
      if (this.order_items.length > 0) {
        this.order_items.forEach(function(item, key) {
          total += _this.getTotalWithDouble(key)
          if (item.discounts.length > 0) {
            item.discounts.forEach(function (discount, discountkey) {
              if (discount.type == 'amount') {
                var amount = _this.getTotalDiscountWithDouble(discount.amount)
                total -= amount
              } else if (discount.type == 'rate') {
                var rate = discount.rate
                var product = _this.getTotalWithDouble(key)
                total -= product * ((rate/100))
              }
            })
          }
        })
      }
      var options = {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      };
      return total.toLocaleString("tr-TR", options)
    },
    totalServicePrice() {
      var total = 0
      var _this = this
      if (this.order_items.length > 0) {
        this.order_items.forEach(function(item, key) {
          if (item.services.length > 0) {
            item.services.forEach(function(item, servicekey) {
              total += _this.getTotalServiceWithDouble(key, servicekey)
            })
          }
        })
      }
      var options = {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      };
      return total.toLocaleString("tr-TR", options)
    },
    totalPrice() {
      var total = this.subTotal
      total = total.replace(".", "")
      total = parseFloat(total.replace(",", "."))
      var lineDiscount = this.lineDiscountPrice.replace(".", "")
      lineDiscount = parseFloat(lineDiscount.replace(",", "."))
      total = total - lineDiscount
      var _this = this
      this.order_discounts.forEach(function (discount) {
        if (discount.type == 'amount') {
          var amount = _this.getTotalDiscountWithDouble(discount.amount)
          total -= parseFloat(amount)
        } else if (discount.type == 'rate') {
          var rate = discount.rate
          total -= (total * (rate / 100))
        }
      })
      var options = {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      };
      if (this.total_cargo) {
        var totalcargo = this.total_cargo.substring(1, this.total_cargo.length)
        var totalcargo = totalcargo.replace(".", "")
        totalcargo = totalcargo.replace(",", ".")
        total += parseFloat(totalcargo)
      }
      if (this.totalVat != '0,00') {
        var vat = this.totalVat.replace(".", "")
        vat = vat.replace(",", ".")
        total += parseFloat(vat)
      }
      return total.toLocaleString("tr-TR", options)
    },
    subTotal() {
      var total = 0
      var _this = this
      if (this.order_items.length > 0) {
        this.order_items.forEach(function (item, key) {
          total += (_this.getTotalWithDoublePrice(key) * item.quantity)
          if (item.services.length > 0) {
            item.services.forEach(function (service, servicekey) {
              total += (_this.getTotalServiceWithDoublePrice(key, servicekey) * service.quantity)
            })
          }
        })
      }
      var options = {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      };
      return total.toLocaleString("tr-TR", options)
    },
    lineDiscountPrice() {
      var total = 0
      var _this = this
      if (this.order_items.length > 0) {
        this.order_items.forEach(function (item, key) {
          item.discounts.forEach(function (discount, discountkey) {
            if (discount.type == 'amount') {
              var amount = _this.getTotalDiscountWithDouble(discount.amount)
              total += parseFloat(amount)
            } else if (discount.type == 'rate') {
              var rate = discount.rate
              var pprice = item.price.replace(".", "")
              pprice = pprice.replace(",", ".")
              total += parseFloat((pprice * item.quantity) * ((rate / 100)))
            }
          })
        })
      }
      var options = {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      };
      return parseFloat(total).toLocaleString("tr-TR", options)
    },
    totalVat() {
      var total = 0
      var _this = this
      this.order_discounts.forEach(function (discount) {
        if (discount.type == 'amount') {
          var amount = _this.getTotalDiscountWithDouble(discount.amount)
          total += parseFloat(amount)
        } else if (discount.type == 'rate') {
          var rate = discount.rate
          var t = _this.subTotal.replace(".", "")
          t = t.replace(",", ".")
          var lineDiscount2 = _this.lineDiscountPrice.replace(".", "")
          lineDiscount2 = parseFloat(lineDiscount2.replace(",", "."))
          t = t - lineDiscount2
          total += parseFloat(parseFloat(t) * ((rate / 100)))
        }
      })
      var subtotal = this.subTotal.replace(".", "")
      subtotal = subtotal.replace(",", ".")
      var lineDiscount = this.lineDiscountPrice.replace(".", "")
      lineDiscount = parseFloat(lineDiscount.replace(",", "."))
      subtotal = subtotal - lineDiscount
      var ratio = (subtotal - total) / subtotal
      var vat = 0
      this.order_items.forEach(function (item) {
        if (item.total != '0,00') {
          var t = item.total.replace(".", "")
          t = t.replace(",", ".")
        } else {
          var t = 0
        }
        if (item.price != '0,00') {
          var p = item.price.replace(".", "")
          p = p.replace(",", ".")
        } else {
          var p = 0
        }
        if (ratio > 0) {
          if (item.discounts.length > 0) {
            item.discounts.forEach(function (discountitem) {
              if (discountitem.type == 'amount') {
                var amount = _this.getTotalDiscountWithDouble(discountitem.amount)
                vat += (((p * item.quantity) - amount) * (item.tax_rate / 100)) * ratio
              } else if (discountitem.type == 'rate') {
                var rate = discountitem.rate
                vat += (((p * item.quantity) - ((p * item.quantity) * rate / 100)) * (item.tax_rate / 100)) * ratio
              }
            })
          } else {
            vat += (t - (p * item.quantity)) * ratio
          }
        } else {
          vat += t - ( t / (1 + (item.tax_rate / 100)))
        }
        if (item.services.length > 0) {
          item.services.forEach(function (service) {
            var servicePrice = service.price.replace(".", "")
            servicePrice = servicePrice.replace(",", ".")
            var totalPrice = service.total.replace(".", "")
            totalPrice = totalPrice.replace(",", ".")
            vat += totalPrice - (servicePrice * service.quantity)
          })
        }
      })
      var options = {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      };
      return parseFloat(vat).toLocaleString("tr-TR", options)
    },
  },
  mounted() {
    this.$store.dispatch('sales/getDiscountType').then((response) => {
      this.discountType = response
    })
  },
  data() {
    return {
      money: {
        decimal: ',',
        thousands: '.',
        prefix: '',
        suffix: '',
        precision: 2,
      },
      money2: {
        decimal: ',',
        thousands: '.',
        prefix: '₺',
        suffix: '',
        precision: 2,
      },
      currency: [
        {value: null, text: '₺', disabled: true}, ,
        {value: 'usd', text: '$', disabled: true},
        {value: 'euro', text: '€', disabled: true},
      ],
      vat: [
        {value: 18, text: '18', disabled: true}, ,
      ],
      discount_unit: [
        {value: 'amount', text: '₺'}, ,
        {value: 'rate', text: '%'},
      ],
      selected: null,
      unit: [
        {value: null, text: '| adet', disabled: true},
      ],
      discountType: [],
    }
  },
  methods: {
    changeProductLineDiscountType(key, discountkey) {
      if (this.order_items[key].discounts[discountkey].type == 'amount') {
        this.order_items[key].discounts[discountkey].rate = ''
      }
      if (this.order_items[key].discounts[discountkey].type == 'rate') {
        this.order_items[key].discounts[discountkey].amount = ''
      }
    },
    conti() {
      if (this.order_items[0].name != '') {
        this.$store.commit('offers/setStep', 2)
      } else {
        this.order_items[0].errorMessage = this.$i18n.t('Please select a product for this row.')
      }
    },
    openProductBox(key) {
      this.order_items[key].openProductBox = !this.order_items[key].openProductBox
      if (this.order_items[key].openProductBox == true) {
        this.$store.dispatch('sales/searchTireProduct', '205').then((response) => {
          this.order_items[key].searchResult = response
          this.order_items[key].afterSearch = true
          this.order_items[key].loading = false
        })
        setTimeout(() => document.getElementById(`choose-product-input-${key}`).focus(), 1)
      }
    },
    setSearchInput(key, search) {
      this.order_items[key].name = `${search.brand_name} ${search.name} - ${search.sku}`
      this.order_items[key].openProductBox = false
      this.order_items[key].searchArea = search.sku
      this.order_items[key].tax_rate = search.tax_rate
      if (search.quantity > 4) {
        this.order_items[key].quantity = 4
      } else {
        this.order_items[key].quantity = search.quantity
      }
      this.order_items[key].maxQuantity = search.quantity
      var options = {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      };
      this.order_items[key].price = search.price.toLocaleString("tr-TR", options);
      this.order_items[key].total = parseFloat(search.amount * search.quantity).toLocaleString("tr-TR", options);
      this.order_items[key].radius = search.radius
      this.order_items[key].tire_brand_id = search.brand_id
      this.order_items[key].tire_stock_id = search.id
    },
    getTotal(key) {
      if (this.order_items[key].price != '0,00') {
        var price = this.order_items[key].price.replace(".", "")
        price = parseFloat(price.replace(",", ".")).toFixed(2)
        var quantity = this.order_items[key].quantity
        var options = {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        };
        var total = 0
        var _this = this
        if (this.order_items[key].discounts.length > 0 ) {
          this.order_items[key].discounts.forEach(function (discount) {
            if (discount.type == 'amount') {
              var amount = _this.getTotalDiscountWithDouble(discount.amount)
              total += amount
              var result = (quantity * (price)) - total
              result = result + (result * (parseFloat(_this.order_items[key].tax_rate) / 100))
              result = result.toLocaleString("tr-TR", options);
              _this.order_items[key].total = result
            } else if (discount.type == 'rate') {
              var rate = discount.rate
              var productTotalPrice = (price * quantity) * (1 + (_this.order_items[key].tax_rate / 100))
              var dc = productTotalPrice - ((productTotalPrice) * ((rate / 100)))
              _this.order_items[key].total = dc.toLocaleString("tr-TR", options);
            }
          })
        } else {
          var result = (quantity * (price))
          result = result + (result * (parseFloat(this.order_items[key].tax_rate) / 100))
          result = result.toLocaleString("tr-TR", options);
          this.order_items[key].total = result
        }
      }
    },
    productLineTotalChange(key) {
      var price = document.getElementById(`total-price-${key}`).value.replace(".", "")
      price = parseFloat(price.replace(",", ".")).toFixed(2)
      var quantity = this.order_items[key].quantity
      var options = {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      };
      var total = 0
      var _this = this
      this.order_items[key].discounts.forEach(function (discount) {
        if (discount.type == 'amount') {
          var amount = _this.getTotalDiscountWithDouble(discount.amount)
          total += amount
        } else if (discount.type == 'rate') {
          var rate = discount.rate
          var product = _this.getTotalWithDouble(key)
          total += product * ((rate / 100))
        }
      })
      var result = price / (1 + ((parseFloat(this.order_items[key].tax_rate)) / 100))
      result = result + parseFloat(total)
      result = (result / quantity)
      result = result.toLocaleString("tr-TR", options);
      this.order_items[key].price = result
    },
    addService(key) {
      if (this.order_items[key].name != '') {
        this.order_items[key].services.push({
          service_id: '',
          quantity: '',
          price: '',
          tax_rate: '20',
          description: '',
          searchArea: '',
          searchResult: '',
          openSearchBox: false,
          loading: false
        })
      } else {
        this.order_items[key].errorMessage = this.$i18n.t('Please select a product for this row.')
      }
    },
    openProductServiceBox(key, servicekey) {
      this.order_items[key].services[servicekey].openSearchBox = !this.order_items[key].services[servicekey].openSearchBox
      if (this.order_items[key].services[servicekey].openSearchBox == true) {
        setTimeout(() => document.getElementById(`service-box-${key}-${servicekey}`).focus(), 1)
      }
    },
    setProductDiscount(key) {
      if (this.order_items[key].discounts.length == 0) {
        if (this.order_items[key].name != '') {
          this.order_items[key].discounts.push({
            amount: '',
            rate: '',
            discount_type_id: '2',
            type: 'amount'
          })
        } else {
          this.order_items[key].errorMessage = this.$i18n.t('Please select a product for this row.')
        }
      }
    },
    searchInputService(key, servicekey) {
      if (this.order_items[key].services[servicekey].searchArea === 0) {
        this.order_items[key].services[servicekey].searchResult = []
        this.order_items[key].services[servicekey].afterSearch = true
      }
      if (this.order_items[key].services[servicekey].searchArea.length > 2) {
        this.order_items[key].services[servicekey].loading = true
        this.$store.dispatch('sales/searchTireProductService', {
          search: this.order_items[key].services[servicekey].searchArea,
          tire_brand_id: this.order_items[key].tire_brand_id,
          radius: this.order_items[key].radius
        }).then((response) => {
          this.order_items[key].services[servicekey].searchResult = response
          this.order_items[key].services[servicekey].afterSearch = true
          this.order_items[key].services[servicekey].loading = false
        })
      }
    },
    setSearchInputService(key, servicekey, search) {
      this.order_items[key].services[servicekey].name = `${search.name}`
      this.order_items[key].services[servicekey].openSearchBox = false
      this.order_items[key].services[servicekey].searchArea = search.name
      this.order_items[key].services[servicekey].quantity = this.order_items[key].quantity
      this.order_items[key].services[servicekey].maxQuantity = this.order_items[key].maxQuantity
      this.order_items[key].services[servicekey].service_id = search.id
      var options = {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      };
      this.order_items[key].services[servicekey].price = search.price.toLocaleString("tr-TR", options);
      this.order_items[key].services[servicekey].total = parseFloat(search.amount * this.order_items[key].services[servicekey].quantity).toLocaleString("tr-TR", options);
    },
    getTotalService(key, servicekey) {
      var price = this.order_items[key].services[servicekey].price.replace(".", "")
      price = parseFloat(price.replace(",", ".")).toFixed(2)
      var quantity = this.order_items[key].services[servicekey].quantity
      var options = {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      };
      var result = (quantity * price)
      result = result + (result * (parseFloat(this.order_items[key].services[servicekey].tax_rate) / 100))
      result = result.toLocaleString("tr-TR", options);
      this.order_items[key].services[servicekey].total = result
    },
    productLineTotalChangeDiscount(key,servicekey) {
      var price = document.getElementById(`total-price-service-${key}-${servicekey}`).value.replace(".", "")
      price = parseFloat(price.replace(",", ".")).toFixed(2)
      var quantity = this.order_items[key].services[servicekey].quantity
      var options = {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      };
      var result = price / (1 + ((parseFloat(this.order_items[key].services[servicekey].tax_rate)) / 100))
      result = result / quantity
      result = result.toLocaleString("tr-TR", options);
      this.order_items[key].services[servicekey].price = result
    },
    deleteProductLineService(key, servicekey) {
      this.order_items[key].services.splice(servicekey, 1);
    },
    addDesc(key) {
      this.order_items[key].isDesc = !this.order_items[key].isDesc
      if (!this.order_items[key].isDesc) {
        this.order_items[key].description = ""
      }
    },
    getTotalWithDoublePrice(key) {
      var price = this.order_items[key].price.replace(".", "")
      price = parseFloat(price.replace(",", ".")).toFixed(2)
      return parseFloat(price)
    },
    addDiscount() {
      this.order_discounts.push({
        amount: '',
        rate: '',
        discount_type_id: '2',
        type: 'amount'
      })
    },
    getTotalWithDouble(key) {
      var price = this.order_items[key].total.replace(".", "")
      price = parseFloat(price.replace(",", ".")).toFixed(2)
      return parseFloat(price)
    },
    getTotalDiscountWithDouble(amount) {
      var price = amount.replace(".", "")
      price = parseFloat(price.replace(",", ".")).toFixed(2)
      return price
    },
    deleteDiscount(key) {
      this.order_discounts.splice(key, 1)
    },
    getTotalServiceWithDouble(key, servicekey) {
      if (this.order_items[key].services[servicekey].total) {
        var price = this.order_items[key].services[servicekey].total.replace(".", "")
        price = parseFloat(price.replace(",", ".")).toFixed(2)
        return parseFloat(price)
      }
    },
    getTotalServiceWithDoublePrice(key, servicekey) {
      if (this.order_items[key].services[servicekey].total) {
        var price = this.order_items[key].services[servicekey].price.replace(".", "")
        price = parseFloat(price.replace(",", ".")).toFixed(2)
        return parseFloat(price)
      }
    },
    addProductLine() {
      var size = this.order_items.length
      if (size > 0) {
        if (this.order_items[size - 1].name != '') {
          this.order_items[size - 1].errorMessage = ''
          this.order_items.push({
            tire_stock_id: '',
            quantity: '1',
            maxQuantity: 4,
            price: '',
            name: '',
            tax_rate: '20',
            description: '',
            isDesc: false,
            total: '',
            openProductBox: false,
            searchArea: '',
            searchResult: [],
            loading: false,
            afterSearch: false,
            errorMessage: '',
            services: [],
            discounts: [],
            radius: '',
            tire_brand_id: ''
          })
        } else {
          this.order_items[size - 1].errorMessage = this.$i18n.t('Please select a product for this row.')
        }
      }
    },
    deleteProductLine(key) {
      this.order_items[key] = {
        tire_stock_id: '',
        quantity: '1',
        price: '',
        tax_rate: '20',
        description: '',
        isDesc: false,
        total: '',
        openProductBox: false,
        searchArea: ''
      }
      this.order_items.splice(key, 1);
    },
  }
}
</script>


<style scoped>
.product-line-services {
  display: flex;
  gap: 15px;
  align-items: center;
  width: 100%;
  margin-top: 17px;
}

.choose-product-container {
  position: relative;
  width: 100% !important;
}

.choose-customer-container {
  position: relative;
  max-width: 500px;
  min-width: 500px;
}


.choose-product {
  border: 1px solid #dee2e6;
  padding: .65rem .85rem !important;
  border-radius: 5px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 8px;
  background-color: #F4F4F4;
  cursor: pointer;
}

.choose-product-label {
  font-size: 14px;
  color: #6E6B7B !important;
  font-style: normal;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.icon-with-name {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.choose-product-list {
  position: absolute;
  top: 40px;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  border: 1px solid #dee2e6;
  background-color: #F4F4F4;
  border-top: none;
  z-index: 1;
  border-radius: 5px;
}

.choose-product-list-item {
  color: #6E6B7B !important;
  border-top: 1px solid #dee2e6;
  padding: .65rem .85rem;
  font-size: 14px;
  cursor: pointer;
}

.choose-product-list-item:hover {
  background-color: #dadada;
  cursor: pointer;
}

.choose-product-input {
  width: 88%;
}

.input-group-text {
  background-color: #F4F4F4;
  border-left: 1px solid #dee2e6 !important;
}

.choose-product-input-area {
  position: relative;
}

.input-spinner {
  position: absolute;
  right: 26px;
  top: 27px;
}


#services-lines:first-child {
  border-top: 1px solid #dee2e6;
  margin-bottom: 20px;
}

#services-lines:last-child{
  border-bottom: 1px solid #dee2e6;
  margin-bottom: 20px;
}
.isDesc {
  border-bottom: 1px solid #dee2e6 !important;
  width: 100%;
  max-width: 100% !important;
  margin-bottom: 20px !important;
}

.choose-product-list-item-info {
  display: flex;
  flex-direction: column;
  border-top: 1px solid #dee2e6;
  padding-top: 5px;
  margin-top: 5px;
}

.choose-product-list-item-name {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.customer-name {
  display: flex;
  gap: 5px;
  flex-direction: column;
}

.customer-div {
  height: auto;
  width: auto !important;
}

.customer-name-name {
  font-size: 20px;
}

.customer-name-info {
  font-size: 12px;
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.customer-info {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  position: relative;
}

.customer-div .choose-product-label {
  width: 1000%;
}

.discount-line {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.add-invoice-flex-total-wrapper {
  flex: 1;
}

.bill-info {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex-direction: column;
}

.bill-info-line {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px dashed #dee2e6;
  padding-bottom: 10px;
  padding-top: 10px;
  width: 650px;
  gap: 20px;
}

.addDiscount {
  border: none;
  background-color: rgba(119, 119, 119, 0.21);
  border-radius: 5px;
  color: #777777;
  margin: 5px;
}

.addDiscount:hover {
  background-color: black;
  color: #dee2e6;
}

.bill-info {
  margin-top: 50px;
}

.add-more {
  background: var(--servislet-black) !important;
  border-radius: 5px;
  border: 1px solid var(--servislet-black);
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  text-align: center;
  letter-spacing: 0.366667px;
  color: #FFFFFF;
  outline: none;
  padding: 8px 14px;
  transition: .5s;
}

.add-more:hover {
  box-shadow: rgba(28, 28, 28, 0.2) 0px 8px 30px 0px;
  background: var(--servislet-black);
  border: 1px solid var(--servislet-black);
  color: #FFFFFF;
  outline: none;
  transition: .5s;
}

.add-more i {
  padding-right: 6pxs;
}

.offer-lines {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.offer-line {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
}

.offer-line-title {
  color: #777777;
}

.conti {
  display: flex;
  justify-content: flex-end;
}
</style>
