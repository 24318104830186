<template>
  <DefaultLayout>
    <div>
      <PageBreadcrumbs :breadcrumbs="breadcrumbs"/>
      <div v-if="!loading">
        <div class="widgets">
          <div id="page-widgets-wrapper">
            <div class="row">
              <div
                  class="col-lg-4"
                  v-for="widget in dashboard"
                  :key="widget.$index">
                <PageWidgets
                    :title="widget.name"
                    :mainData="widget.total_customer.toString()"
                    :number="'%' + widget.weekly_rate"
                    :icon="true"
                    :text="widget.total_customer ? $t('About last week') : $t('No data found to display.')"/>
              </div>
            </div>
          </div>
        </div>

        <div
            id="products-listing-table-wrapper"
            class="mt-5 py-4">
          <div
              class="filter-double-flex"
              v-if="show_filter">
            <div class="filter-left-content-flex">
              <div class="filter-text">{{ $t('Filters') }}:</div>
              <div>
                <customer-select
                    :options="customer_types"
                    :content_will_select="$t('User Type')"
                    :selected_count="
                    $route.query.customer_type
                      ? $route.query.customer_type.split(',').length
                      : 0
                  "
                    @filterByType="filterByQuery()"
                    @clearQuery="clearQueryParameters()"/>
              </div>
            </div>
            <div class="filter-double-flex-right">
              <div class="filter-double-flex-right-actions">
                <span
                    class="clear-filters-text"
                    @click="clearQueryParameters()"
                >{{ $t('Clear Filters') }}</span
                >
                <i
                    class="fas fa-xmark"
                    @click="show_filter = !show_filter"></i>
              </div>
            </div>
          </div>
          <div
              class="products-double-flex"
              v-else>
            <div class="products-double-flex-left">
              <button
                  class="add-new-product-btn"
                  @click="$router.push({ name: 'CustomerAdd' })">
                <i class="fa-solid fa-plus"></i>
                {{ $t('Customer') }}
              </button>
              <button
                  class="other-btns"
                  v-b-modal.customer-sms-modal>
                <i class="fa-solid fa-link"></i>
                SMS Link
              </button>
              <button
                  class="other-btns"
                  @click="getQr()"
                  v-b-modal.customer-qr-modal>
                <i class="fa-solid fa-qrcode"></i>
                QR
              </button>
            </div>
            <!-- customer sms modal -->
            <b-modal
                id="customer-sms-modal"
                ref="customer-sms-modal"
                size="md"
                centered
                hide-footer>
              <p class="customer-add-modal-title">{{ $t('Share SMS Link') }}</p>
              <p class="customer-add-modal-text">
                {{
                  $t(
                      'Enter the 10-digit phone number of the customer you want to send the link to.'
                  )
                }}
              </p>

              <div class="d-flex align-items-center phone-area-div">
                <div class="area">+90</div>
                <input
                    class="bg-transparent form-control m-0"
                    v-mask="'(###) ### ## ##'"
                    v-model="sms_phone">
              </div>

              <button
                  class="modal-foot-save"
                  @click="createBySms()">
                {{ $t('Send') }}
              </button>
            </b-modal>
            <!-- end of customer sms modal -->
            <!-- customer qr modal -->
            <b-modal
                id="customer-qr-modal"
                ref="customer-qr-modal"
                size="l"
                centered
                hide-footer
                hide-header>
              <div class="col-sm-12 d-flex justify-content-end">
                <i
                    class="fa-solid fa-xmark cursor-pointer"
                    @click="$refs['customer-qr-modal'].hide()"></i>
              </div>
              <p class="customer-add-modal-title">{{ $t('Read QR') }}</p>
              <p class="customer-add-modal-text">
                {{
                  $t(
                      'Scan the QR code from the phone of the customer you want to add.'
                  )
                }}
              </p>
              <img
                  :src="qr_img"
                  v-if="qr_img"
                  width="200"
                  height="200"/>
              <!-- <span>00:00</span> -->
            </b-modal>
            <!-- end of customer qr modal -->
            <div class="products-double-flex-right">
              <div
                  class="products-double-flex-right-actions"
                  style="max-width: unset;"
                  v-if="!show_search_text"
                  :class="!show_search_text ? 'opened' : ''"
                  v-click-outside="close">
                <span>
                  <i class="fa-solid fa-search ml-0"></i>
                </span>
                <b-form-input
                    class="search-text-input"
                    v-model="filter"
                    v-on:keyup.enter="getCustomerList()"
                    :placeholder="$t('Search') + '...'"
                    :class="!show_search_text ? 'show-search_text' : ''"/>
                <i
                    class="fa-solid fa-xmark close"
                    @click="
                    show_search_text = !show_search_text;
                    filter = '';
                    getCustomerList();
                  "></i>
              </div>
              <div
                  class="products-double-flex-right-actions"
                  v-if="show_search_text"
                  @click="show_search_text = !show_search_text">
                <span>
                  <i class="fa-solid fa-search"></i>
                  <p>{{ $t('Search') }}</p>
                </span>
              </div>
              <div
                  class="products-double-flex-right-actions"
                  @click="show_filter = !show_filter">
                <span>
                  <i class="fa-solid fa-filter"></i>
                  <p>{{ $t('Filter') }}</p>
                </span>
              </div>
            </div>
          </div>
          <div class="product-listing-table">
            <table>
              <tr class="product-listing-table-head">
                <th>
                  <label class="product-listing-checkbox-label">
                    <input
                        type="checkbox"
                        @click="checkUncheck()"/>
                    <span class="checkmark"></span>
                  </label>
                </th>
                <th>
                  <div class="d-flex">
                    <span>{{ $t('Name Surname') }}</span>
                    <i
                        class="fa fa-chevron-down cursor-pointer float-right mt-1 fs-12 ml-3"
                        @click="sortByName()"
                        v-if="sort_by_name !== 'desc'"></i>
                    <i
                        class="fa fa-chevron-up cursor-pointer float-right mt-1 fs-12 ml-3"
                        @click="sortByName()"
                        v-else></i>
                  </div>
                </th>
                <th>
                  <div class="d-flex">
                    <span>{{ $t('Type') }}</span>
                    <i
                        class="fa fa-chevron-down cursor-pointer float-right mt-1 fs-12 ml-3"
                        @click="sortByCustomerType()"
                        v-if="sort_by_customer_type !== 'desc'"></i>
                    <i
                        class="fa fa-chevron-up cursor-pointer float-right mt-1 fs-12 ml-3"
                        @click="sortByCustomerType()"
                        v-else></i>
                  </div>
                </th>
                <th>{{ $t('Email') }}</th>
                <th>{{ $t('Phone') }}</th>
                <th>{{ $t('Car') }}</th>
                <th></th>
                <th>{{ $t('Actions') }}</th>
              </tr>
              <tr
                  v-for="item in users"
                  :key="item.$index">
                <td>
                  <label class="product-listing-checkbox-label">
                    <input
                        type="checkbox"
                        name="single-payment-checkbox"
                        @click="select = !select"/>
                    <span class="checkmark"></span>
                  </label>
                </td>
                <td>
                  <!--                  <img src="https://i.pravatar.cc/200" class="table-customer-avatar-img">-->
                  {{ item.full_name }}
                </td>
                <td>
                  <div class="type-of-customer-wrapper d-flex align-items-center gap-2">
                    <i :class="item.customerType.icon" :style="`color: ${item.customerType.code === '101' ? '#7367f0' : (item.customerType.code === '102' ? '#28c76f': (item.customerType.code === '103' ? '#ea5455' : (item.customerType.code === '104' ? '#ff9f43' : '#1c1c1c')))}`"></i>
                    {{ item.customer_type }}
                  </div>
                </td>
                <td>{{ item.email }}</td>
                <td>{{ item.mobile }}</td>
                <td>{{ item.full_car }}</td>
                <td>
                  <button
                      class="btn approved-btn"
                      @click="
                      getUserDetail(item.id);
                      show_approve_btn = true;
                    "
                      v-if="!item.approved_at">
                    {{ $t('Approve') }}
                  </button>
                </td>
                <td>
                  <!-- v-if="select === false" -->
                  <div class="d-flex">
                    <button
                        class="show-in-website-btn"
                        @click="
                        getUserDetail(item.id);
                        show_approve_btn = false;
                      ">
                      <i class="fa-solid fa-eye"></i>
                    </button>
                    <b-dropdown
                        right
                        no-caret>
                      <template #button-content>
                        <i class="fa-solid fa-ellipsis-vertical"></i>
                      </template>
                      <b-dropdown-item class="table-dropdown-actions">
                        <router-link :to="`/customer/${item.id}`"
                        ><p class="w-100 m-0">
                          {{ $t('Edit') }}
                        </p></router-link
                        >
                      </b-dropdown-item>
                      <b-dropdown-item
                          @click="openDeleteModal(item.id)"
                          class="table-dropdown-actions"
                      ><p class="w-100 m-0">
                        {{ $t('Delete') }}
                      </p></b-dropdown-item
                      >
                    </b-dropdown>
                  </div>
                </td>
              </tr>
            </table>
            <div id="table-info-and-pagination" v-if="users.length>0">
              <div class="table-results-info">
                <p>
                  {{$t('{total} {from} {start}-{end} is showing.',{total: pagination.total, from: $t('from customers'), start: users.length>0 ? (pagination.current_page === 1 ? 1 : ((pagination.current_page - 1) * pagination.per_page) + 1) : 0, end: pagination.total_page === pagination.current_page ? pagination.total : pagination.current_page * pagination.per_page})}}
                </p>
              </div>
              <div class="pagination-wrapper">

                <b-pagination
                    v-model="currentPage"
                    :total-rows="pagination.total"
                    :per-page="pagination.per_page"
                    align="center"
                    pills
                    class="my-1"
                    @input="getCustomerList();"/>
              </div>
            </div>
            <div v-if="!loading && users.length === 0" class="d-flex flex-column align-items-center">
              <p class="table-no-text"> {{
                  $t('No {data} found to list.', {data: $t('Customer').toLocaleLowerCase()})
                }}</p>
              <button @click="$router.push({ name: 'CustomerAdd' })" class="no-data-btn"><i class="fa-solid fa-plus"></i> {{$t('Add Customer')}}</button>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <content-placeholders :rounded="true">
          <content-placeholders-img/>
          <content-placeholders-text :lines="10"/>
        </content-placeholders>
      </div>

      <b-modal
          id="delete-modal"
          ref="delete-modal"
          size="md"
          hide-footer
          centered
          hide-header>
        <div class="delete-modal-body">
          <div class="delete-modal-header">
            <i class="fa-solid fa-xmark cursor-pointer"></i>
          </div>
          <div class="delete-modal-content-text">
            {{ $t('Are you sure you want to delete the customer?') }}
          </div>
          <div class="delete-modal-buttons">
            <b-button
                class="cancel-button"
                @click="$refs['delete-modal'].hide()"
            >{{ $t('Cancel') }}
            </b-button
            >
            <b-button
                class="delete-button"
                @click="deleteCustomer()"
            >{{ $t('Delete') }}
            </b-button
            >
          </div>
        </div>
      </b-modal>

      <b-modal
          id="customer-approved-btn"
          ref="customer-approved-btn"
          size="lg"
          hide-footer
          centered
          hide-header>
        <b-col
            sm="12"
            md="12"
            lg="12"
            class="p-0">
          <div class="approved-header">
            <div class="approved-header-title">
              {{ $t('Customer Informations') }}
            </div>
            <div>
              <i
                  class="fas fa-xmark"
                  @click="$refs['customer-approved-btn'].hide()"></i>
            </div>
          </div>
          <div
              class="mt-3 p-15"
              :class="
              Object.keys(selected_user_car.brand).length > 0 ? 'bg-white' : ''
            ">
            <div class="customer-type">
              <i
                  :class="selected_user_customer_type.icon"
                  :style="'color:' + `${selected_user_customer_type.color}`"></i>
              <span class="ml-3">{{ selected_user_customer_type.name }}</span>
            </div>
            <div class="row mt-3">
              <b-col
                  sm="12"
                  md="6"
                  lg="6">
                <div class="customer-type-wrapper">
                  <div class="customer-type-left">{{ $t('Name Surname') }}</div>
                  <div class="customer-type-right">
                    {{ selected_user.name }} {{ selected_user.surname }}
                  </div>
                </div>
                <div class="customer-type-wrapper">
                  <div class="customer-type-left">{{ $t('Phone') }}</div>
                  <div class="customer-type-right">
                    {{ selected_user_phone }}
                  </div>
                </div>
                <div class="customer-type-wrapper">
                  <div class="customer-type-left">{{ $t('E-Mail') }}</div>
                  <div class="customer-type-right">
                    {{ selected_user.email }}
                  </div>
                </div>
              </b-col>
              <b-col
                  sm="12"
                  md="6"
                  lg="6">
                <div class="customer-type-wrapper">
                  <div class="customer-type-left">
                    {{ $t('City') }} / {{ $t('District') }}
                  </div>
                  <div class="customer-type-right">
                    {{
                      selected_user_address.city.name
                          ? selected_user_address.city.name
                          : ''
                    }}
                    {{
                      selected_user_address.district.name
                          ? selected_user_address.district.name
                          : '-'
                    }}
                  </div>
                </div>
                <div class="customer-type-wrapper">
                  <div class="customer-type-left">{{ $t('Neighborhood') }}</div>
                  <div class="customer-type-right">
                    {{
                      selected_user_address.area.name
                          ? selected_user_address.area.name
                          : '-'
                    }}
                  </div>
                </div>
                <div class="customer-type-wrapper">
                  <div class="customer-type-left">
                    {{ $t('Address Definition') }}
                  </div>
                  <div class="customer-type-right">
                    {{
                      selected_user_address.address
                          ? selected_user_address.address
                          : '-'
                    }}
                  </div>
                </div>
              </b-col>
            </div>
          </div>

          <div
              class="mt-3 p-15"
              v-if="Object.keys(selected_user_car.brand).length > 0"
              :class="
              Object.keys(selected_user_car.brand).length > 0 ? 'bg-white' : ''
            ">
            <div class="customer-type">
              <i class="fa-regular fa-car"></i>
              <span class="ml-3">{{ $t('Car') }}</span>
            </div>
            <div class="row mt-3">
              <b-col
                  sm="12"
                  md="6"
                  lg="6">
                <div class="customer-type-wrapper">
                  <div class="customer-type-left">{{ $t('Plate') }}</div>
                  <div class="customer-type-right">
                    {{
                      selected_user_car.plate_number
                          ? selected_user_car.plate_number
                          : '-'
                    }}
                  </div>
                </div>
                <div class="customer-type-wrapper">
                  <div class="customer-type-left">{{ $t('Chassis No.') }}</div>
                  <div class="customer-type-right">
                    {{
                      selected_user_car.chassis_number
                          ? selected_user_car.chassis_number
                          : '-'
                    }}
                  </div>
                </div>
                <div class="customer-type-wrapper">
                  <div class="customer-type-left">{{ $t('Fuel') }}</div>
                  <div class="customer-type-right">
                    {{
                      selected_user_car.fuel_type
                          ? selected_user_car.fuel_type
                          : '-'
                    }}
                  </div>
                </div>
              </b-col>
              <b-col
                  sm="12"
                  md="6"
                  lg="6">
                <div class="customer-type-wrapper">
                  <div class="customer-type-left">{{ $t('Brand') }}</div>
                  <div class="customer-type-right">
                    {{
                      selected_user_car.brand
                          ? selected_user_car.brand.name
                              ? selected_user_car.brand.name
                              : '-'
                          : '-'
                    }}
                  </div>
                </div>
                <div class="customer-type-wrapper">
                  <div class="customer-type-left">
                    {{ $t('Model') }} / {{ $t('Version') }}
                  </div>
                  <div
                      class="customer-type-right"
                      v-if="selected_user_car.version">
                    {{ selected_user_car.model }}
                    {{ selected_user_car.version_type }}
                    {{ selected_user_car.version }}
                  </div>
                  <div
                      class="customer-type-right"
                      v-else>
                    -
                  </div>
                </div>
                <div class="customer-type-wrapper">
                  <div class="customer-type-left">{{ $t('Year') }}</div>
                  <div class="customer-type-right">
                    {{ selected_user_car.year ? selected_user_car.year : '-' }}
                  </div>
                </div>
              </b-col>
            </div>
          </div>

          <div
              class="customer-approved-buttons"
              v-if="show_approve_btn">
            <div>
              <button
                  class="btn cancel-button"
                  @click="$refs['customer-approved-btn'].hide()">
                {{ $t('Cancel') }}
              </button>
              <button
                  class="btn approved-button"
                  @click="approve(selected_user.id)">
                {{ $t('Approve') }}
              </button>
            </div>
          </div>
        </b-col>
      </b-modal>
    </div>
  </DefaultLayout>
</template>

<script>
/* eslint-disable */
import PageBreadcrumbs from '../../components/PageBreadcrumbs.vue';
import PageWidgets from '../../components/PageWidgets.vue';
import DefaultLayout from '@/layouts/default';
import customer from '@/collections/customer';
import vClickOutside from 'v-click-outside';

export default {
  components: {
    PageBreadcrumbs,
    PageWidgets,
    DefaultLayout
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  data() {
    return {
      status: true,
      loading: true,
      breadcrumbs: [
        {
          name: this.$i18n.t('Customers')
        }
      ],
      currentPage: 1,
      select: false,
      sms_phone: '',
      users: [],
      pagination: {},
      show_search_text: true,
      filter: '',
      show_filter: false,
      customer_types: [],
      sort_by_name: '',
      sort_by_customer_type: '',
      deleted_user_id: null,
      dashboard: [],
      qr_img: '',
      selected_user: {},
      selected_user_phone: null,
      selected_user_address: {
        city: {},
        district: {},
        area: {}
      },
      selected_user_customer_type: {},
      selected_user_car: {
        brand: {}
      },
      show_approve_btn: false
    };
  },
  mounted() {
    this.getCustomerDashboardData();
    this.getCustomerTypes();
    this.getCustomerList();
  },
  methods: {
    getQr() {
      var self = this;
      this.$axios
          .post('/customers/create-by-qr', {})
          .then(function (response) {
            self.qr_img =
                'data:image/svg+xml;base64,' + response.data.responseData.qrCode;
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    createBySms() {
      if(this.sms_phone){
        this.$axios.post('/customers/create-by-sms', {
          phone: '+90 '+ this.sms_phone
        }).then(response => {
          if (response.data.status) {
            this.$refs["customer-sms-modal"].hide();
            this.$swal.fire({
              iconHtml: '<img src="/img/success.svg" width="32" height="32">',
              position: 'top-end',
              title: this.$i18n.t('Sms link was send successfully.'),
              showConfirmButton: false,
              timer: 2500,
              toast: true,
              customClass: {
                icon: 'swal2-no-border'
              }
            });
            //self.$bvModal.hide('customer-sms-modal');
          }
        }).catch(error => {
          this.$swal.fire({
            iconHtml: '<img src="/img/error.svg" width="32" height="32">',
            position: 'top-end',
            title: error.response.data.responseData.message,
            showConfirmButton: false,
            timer: 2500,
            toast: true,
            customClass: {
              icon: 'swal2-no-border'
            }
          });
        });
      }
    },
    checkUncheck(checkBox) {
      this.select = !this.select;
      var get = document.getElementsByName('single-payment-checkbox');
      for (var i = 0; i < get.length; i++) {
        if (get[i].checked === true) {
          get[i].checked = false;
        } else {
          get[i].checked = true;
        }
        // without if => get[i].checked = checkBox.checked;}
      }
    },
    getCustomerList() {
      this.loading = true;
      let ct = [];
      if (this.$route.query.customer_type) {
        ct = this.$route.query.customer_type.split(',');
      }
      this.$axios
          .get('/customers', {
            params: {
              page: this.currentPage,
              limit: 20,
              order_by_name: this.sort_by_name,
              order_by_customer_type_id: this.sort_by_customer_type,
              search_text: this.filter,
              customer_type_ids: ct
            }
          })
          .then((response) => {
            if (response.data.status) {
              this.users = customer.listCollection(
                  response.data.responseData.items
              );
              this.pagination = response.data.responseData.pagination;
              this.loading = false;
            }
          })
          .catch((error) => {
            this.$swal.fire({
              iconHtml: '<img src="/img/error.svg" width="32" height="32">',
              position: 'top-end',
              title: error.response.data.responseData.message,
              showConfirmButton: false,
              timer: 2500,
              toast: true,
              customClass: {
                icon: 'swal2-no-border'
              }
            });
          });
    },
    getCustomerDashboardData() {
      this.$axios.get('/customers/dashboard').then((response) => {
        this.dashboard = response.data.responseData;
      });
    },
    getCustomerTypes() {
      this.$axios.get('/customer-types').then((response) => {
        if (response.data.status) {
          this.customer_types = response.data.responseData.customerTypes;
        }
      });
    },
    changeCurrentPage(number) {
      this.currentPage = number;
      this.getCustomerList();
    },
    sortByName() {
      this.sort_by_name = this.sort_by_name === 'desc' ? 'asc' : 'desc';
      this.getCustomerList();
    },
    sortByCustomerType() {
      this.sort_by_customer_type =
          this.sort_by_customer_type === 'desc' ? 'asc' : 'desc';
      this.getCustomerList();
    },
    openDeleteModal(id) {
      this.deleted_user_id = id;
      this.$refs['delete-modal'].show();
    },
    deleteCustomer() {
      this.$refs['delete-modal'].hide();
      this.$axios
          .delete(`/customers/${this.deleted_user_id}`)
          .then((response) => {
            if (response.data.status) {
              this.$swal.fire({
                iconHtml: '<img src="/img/success.svg" width="32" height="32">',
                position: 'top-end',
                title: this.$i18n.t('Customer deleted successfully.'),
                showConfirmButton: false,
                timer: 2500,
                toast: true,
                customClass: {
                  icon: 'swal2-no-border'
                }
              });
              this.getCustomerList();
            }
          });
    },
    close() {
      this.show_search_text = !this.show_search_text;
    },
    filterByQuery() {
      this.getCustomerList();
    },
    clearQueryParameters() {
      if (this.$route.query.customer_type) {
        const currentQuery = {...this.$route.query}; // Make a copy of the current query object
        delete currentQuery.customer_type;
        this.$router.push({path: '/customers', query: {...currentQuery}});
        this.getCustomerList();
      }
    },
    getUserDetail(id) {
      this.$axios.get(`/customers/${id}`).then((response) => {
        this.selected_user = response.data.responseData;
        this.selected_user_customer_type =
            response.data.responseData.customerType;
        this.selected_user_car =
            response.data.responseData.cars.length > 0
                ? response.data.responseData.cars[0]
                : {brand: {}};
        this.selected_user_phone =
            response.data.responseData.phones.length > 0
                ? response.data.responseData.phones[0].phone
                : '-';
        this.selected_user_address =
            response.data.responseData.addresses.length > 0
                ? response.data.responseData.addresses[0]
                : {
                  city: {},
                  district: {},
                  area: {}
                };
        this.$refs['customer-approved-btn'].show();
      });
    },
    approve(id) {
      this.$axios
          .put(`/customers/approved/${id}`)
          .then((response) => {
            if (response.data.status) {
              this.$swal.fire({
                iconHtml: '<img src="/img/success.svg" width="32" height="32">',
                position: 'top-end',
                title: this.$i18n.t('Customer approved.'),
                showConfirmButton: false,
                timer: 2500,
                toast: true,
                customClass: {
                  icon: 'swal2-no-border'
                }
              });
              this.$refs['customer-approved-btn'].hide();
              this.getCustomerList();
            }
          })
          .catch((error) => {
            var message = error.response.data.responseData.error
                ? error.response.data.responseData.error[0].message
                : error.response.data.responseData.message;
            this.$swal.fire({
              iconHtml: '<img src="/img/error.svg" width="32" height="32">',
              position: 'top-end',
              title: message,
              showConfirmButton: false,
              timer: 2500,
              toast: true,
              customClass: {
                icon: 'swal2-no-border'
              }
            });
          });
    }
  }
};
</script>

<style scoped>
.phone-area-div{
  border: 1px solid #D8D6DE;
  border-radius: 5px;
}
.phone-area-div input, .phone-area-div input:focus, .phone-area-div input:focus-within {
  border: 1px solid transparent !important;
  outline: 0;
}
.phone-area-div .area{
  font-weight: 400;
  color: #777;
  font-size: 14px;
  padding: 0 12px;
  border-right: 1px solid #777;
}
.approved-btn, .approved-btn:hover {
  background-color: #d9efe3;
  border: 1px solid #2fb46a;
  color: #2fb46a;
  padding: 6px 14px;
  text-align: center;
  font-size: 13px;
}

.approved-header {
  display: flex;
  justify-content: space-between;
  padding: 0;
}

.customer-type {
  display: flex;
  align-items: center;
}

.customer-type span {
  color: #1c1c1c;
}

.customer-type-wrapper {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e9ecef99;
  padding: 13px 0;
}

.customer-type-left,
.customer-type-right {
  font-size: 12px;
}

.customer-type-left {
  color: #777;
  font-weight: 400;
}

.customer-type-right {
  color: #1c1c1c;
  font-weight: 500;
  width: 50%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: right;
}

.approved-header-title {
  font-size: 18px;
  font-weight: 600;
  line-height: 23px;
  letter-spacing: 0px;
}

.approved-header i {
  font-size: 18px;
}

.p-15 {
  padding: 15px;
  border-radius: 10px;
}

.customer-approved-buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
}

.customer-approved-buttons .approved-button,
.customer-approved-buttons .approved-button:hover {
  background-color: #1c1c1c;
  color: #fff;
  border: 1px solid #1c1c1c;
  padding: 6px 24px;
  font-size: 15px;
  margin-left: 1rem;
}

.customer-approved-buttons .cancel-button,
.customer-approved-buttons .cancel-button:hover {
  background-color: transparent;
  color: #777;
  border: 1px solid #777;
  padding: 6px 33px;
  font-size: 15px;
}
</style>

<style>
.search-text-input {
  background: transparent !important;
  border: 1px solid transparent !important;
  width: 0;
  transition: 0.5s width;
  padding-left: 0;
  padding-top: 0;
  padding-bottom: 0;
}

.search-text-input:focus {
  background: transparent !important;
  border: 1px solid transparent !important;
}

.show-search_text {
  width: 350px !important;
}

.opened {
  border: 1px solid rgba(119, 119, 119, 0.6) !important;
  width: 350px !important;
  padding: 0 8px !important;
}

.close {
  background-color: #d3d3d3;
  padding: 5px;
  font-size: 12px;
  border-radius: 3px;
  color: #777777;
  margin-right: 0;
}

#products-listing-table-wrapper .filters {
  padding: 1rem;
  margin: 0 1rem;
  border-radius: 5px;
  background: rgba(211, 211, 211, 0.4);
  display: flex;
  justify-content: space-between;
}

#products-listing-table-wrapper .filters .filter-text {
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  color: #777;
}

#customer-approved-btn___BV_modal_body_ {
  padding: 2rem 1.5rem;
  background-color: #f8f8f8;
}

.bg-white {
  background-color: #fff;
}
</style>
