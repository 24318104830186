<template>
  <div
      id="left-sidebar-wrapper-g"
      :class="{
      'w-100': expanded_menu === true,
      'max-width-100': !expanded_menu
    }">
    <div
        id="left-sidebar-wrapper"
        :class="{ 'max-width-100': expanded_menu === false }">
      <!-- @mouseover="expanded_menu = true" @mouseleave="expanded_menu = false" -->


              <span id="toggle-ico" class="neu" @click="menuToggle">
        <i class="fa-solid" :class="expanded_menu ? 'fa-arrow-left-from-line' : 'fa-arrow-right-from-line'"></i>
                <!--          <i class="fa-regular fa-circle-dot" :class="expanded_menu ? '' : ''"></i>-->
      </span>

      <router-link class="expand-logo" :class="!expanded_menu ? 'd-block opacity-0 h-0' : ''" to="/" >
        <img
            alt="Servislet Pro"
            class="logo mt-3"
            src="../assets/images/logo.png" />
      </router-link>
      <router-link to="/" class="expand-mobil-logo" :class="expanded_menu ? 'd-block opacity-0 h-0' : ''">
        <div class="d-flex justify-content-center">
          <img
              alt="Servislet Pro"
              class="mobile-logo mt-3"
              style="height: 44px; width: auto"
              src="../assets/images/mobile-logo.png" />
        </div>
      </router-link>

      <ul id="navigation-list">
        <router-link to="/">
          <li
              :class="{ justifyContentCenter: expanded_menu === false }"
              v-b-toggle.sum-toggle>
            <div class="navigation-list-flex">
              <i class="fa-regular fa-home menu-ico"></i>
              <span
                  class="menu-txt"
                  :class="expanded_menu ? 'd-block' : 'd-none'"
              >{{ $t('Dashboard') }}</span
              >
            </div>
          </li>
        </router-link>
        <router-link
            :class="{
            'router-link-exact-active':
              currentPath === '/customer-add' ||
              currentPath.includes('/customer/')
          }"
            to="/customers">
          <li :class="{ justifyContentCenter: expanded_menu === false }">
            <i class="fa-regular fa-user menu-ico"></i>
            <span
                :class="expanded_menu ? 'd-block' : 'd-none'"
                class="menu-txt"
            >{{ $t('Retail') }}</span
            >
          </li>
        </router-link>
        <router-link
            :class="{
            'router-link-exact-active':
              currentPath === '/car-add' || currentPath.includes('/car/')
          }"
            to="/cars">
          <li :class="{ justifyContentCenter: expanded_menu === false }">
            <i class="fa-regular fa-car menu-ico"></i>
            <!-- v-if="expanded_menu === false" -->
            <span
                :class="expanded_menu ? 'd-block' : 'd-none'"
                class="menu-txt"
            >{{ $t('Cars') }}</span
            >
          </li>
        </router-link>
        <router-link
            :class="{
            'router-link-exact-active':
              currentPath === '/product-add' ||
              currentPath.includes('/product-detail/')
          }"
            to="/products">
          <li :class="{ justifyContentCenter: expanded_menu === false }">
            <i class="fa-regular fa-box menu-ico"></i>
            <!-- v-if="expanded_menu === false" -->
            <span
                :class="expanded_menu ? 'd-block' : 'd-none'"
                class="menu-txt"
            >{{ $t('Products') }}</span
            >
          </li>
        </router-link>
        <router-link to="/services">
          <!-- /products-view-2 -->
          <li :class="{ justifyContentCenter: expanded_menu === false }">
            <i class="fa-regular fa-wrench-simple menu-ico"></i>
            <!-- v-if="expanded_menu === false" -->
            <span
                :class="expanded_menu ? 'd-block' : 'd-none'"
                class="menu-txt"
            >{{ $t('Services') }}</span
            >
          </li>
        </router-link>
        <router-link
            :class="{
            'router-link-exact-active':
              currentPath === '/add-sale' || currentPath.includes('/sale/')
          }"
            to="/sales">
          <li :class="{ justifyContentCenter: expanded_menu === false }">
            <i class="fa-regular fa-cart-shopping menu-ico"></i>
            <!-- v-if="expanded_menu === false" -->
            <span
                :class="expanded_menu ? 'd-block' : 'd-none'"
                class="menu-txt"
            >{{ $t('Sales') }}</span
            >
          </li>
        </router-link>
        <router-link
            :class="{
            'router-link-exact-active':
              currentPath === '/add-offer' || currentPath.includes('/offer/')
          }"
            to="/offers">
          <li :class="{ justifyContentCenter: expanded_menu === false }">
            <i class="fa-regular fa-copy menu-ico"></i>
            <!-- v-if="expanded_menu === false" -->
            <span
                :class="expanded_menu ? 'd-block' : 'd-none'"
                class="menu-txt"
            >{{ $t('Offers') }}</span
            >
          </li>
        </router-link>
        <router-link
            :class="{
            'router-link-exact-active': currentPath === '/add-appointment'
          }"
            to="/calendar">
          <li :class="{ justifyContentCenter: expanded_menu === false }">
            <i class="fa-regular fa-calendar menu-ico"></i>
            <!-- v-if="expanded_menu === false" -->
            <span
                :class="expanded_menu ? 'd-block' : 'd-none'"
                class="menu-txt"
            >{{ $t('Calendar') }}</span
            >
          </li>
        </router-link>
        <li v-b-toggle.finance-toggle :class="{justifyContentCenter: expanded_menu === false}">
          <i class="fa-regular fa-credit-card menu-ico"></i>
          <!-- v-if="expanded_menu === false" -->
          <span
              :class="expanded_menu ? 'd-block' : 'd-none'"
              class="menu-txt"
          >{{ $t('Finance') }}</span
          >
          <i
              v-if="expanded_menu === true"
              class="fa-regular fa-angle-down"></i>
        </li>
        <b-collapse
            :visible="currentPath === '/payments' || currentPath === '/invoices'"
            id="finance-toggle"
            :class="{ 'expanded-menu-toggle-txt': expanded_menu === false }">
          <router-link to="/payments">
            <li :class="{ justifyContentCenter: expanded_menu === false }">
              {{ $t('Payments') }}
            </li>
          </router-link>
          <router-link to="/invoices" :class="{disabled: disabled}">
            <li :class="{ justifyContentCenter: expanded_menu === false }">
              {{ $t('Invoices') }}
            </li>
          </router-link>
        </b-collapse>
        <router-link
            :class="{ disabled: disabled }"
            to="/reviews">
          <li :class="{ justifyContentCenter: expanded_menu === false }">
            <i class="fa-regular fa-message menu-ico"></i>
            <!-- v-if="expanded_menu === false" -->
            <span
                :class="expanded_menu ? 'd-block' : 'd-none'"
                class="menu-txt"
            >{{ $t('Reviews') }}</span
            >
          </li>
        </router-link>
        <router-link
            :class="{ disabled: disabled }"
            to="#">
          <li :class="{ justifyContentCenter: expanded_menu === false }">
            <i class="fa-regular fa-file-lines menu-ico"></i>
            <!-- v-if="expanded_menu === false" -->
            <span
                :class="expanded_menu ? 'd-block' : 'd-none'"
                class="menu-txt"
            >{{ $t('Reports') }}</span
            >
          </li>
        </router-link>
        <router-link
            to="/downloaded-files">
          <li :class="{ justifyContentCenter: expanded_menu === false }">
            <div class="navigation-list-flex">
              <i class="fa-solid fa-arrow-down-to-bracket menu-ico"></i>
              <span
                  :class="expanded_menu ? 'd-block' : 'd-none'"
                  class="menu-txt"
              >{{ $t('Downloaded Files') }}</span
              >
            </div>
          </li>
        </router-link>
        <a href="https://pro-cdn.servislet.com/Servislet-pro-support.pdf" target="_blank">
          <li :class="{ justifyContentCenter: expanded_menu === false }">
            <div class="navigation-list-flex">
              <i class="fa-regular fa-question-circle menu-ico"></i>
              <span
                  :class="expanded_menu ? 'd-block' : 'd-none'"
                  class="menu-txt"
              >{{ $t('Help & Support') }}</span
              >
            </div>
          </li>
        </a>
        <router-link
            :class="{ disabled: disabled }"
            to="#">
          <li :class="{ justifyContentCenter: expanded_menu === false }">
            <div class="navigation-list-flex">
              <i class="fa-regular fa-gear menu-ico"></i>
              <span
                  :class="expanded_menu ? 'd-block' : 'd-none'"
                  class="menu-txt"
              >{{ $t('Settings') }}</span
              >
            </div>
          </li>
        </router-link>
        <router-link
            :class="{ disabled: disabled }"
            to="#">
          <li
              :class="{ justifyContentCenter: expanded_menu === false }"
              class="go-pro">
            <span
                :class="expanded_menu ? 'd-block' : 'd-none'"
                class="menu-txt"
            >{{ $t('Upgrate Plan') }}</span
            >
            <b :class="expanded_menu ? 'd-block' : 'd-none'">PRO</b>
            <b :class="!expanded_menu ? 'd-block' : 'd-none'">PRO</b>
            <i
                :class="expanded_menu ? 'd-block' : 'd-none'"
                class="fa-regular fa-angle-right"></i>
          </li>
        </router-link>
      </ul>
      <span class="version-text">Version 1.0</span>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  data() {
    return {
      disabled: true,
      width:0,
      currentPath: localStorage.currentPath,
      expanded_menu: localStorage.getItem('show_menu') ? JSON.parse(localStorage.getItem('show_menu')) : this.$store.state.sidemenu.show
    };
  },
  watch:{
    width(){
      this.closeMenu();
    },
    currentPath(){
      this.closeMenu();
    }
  },
  methods: {
    closeMenu(){
      if(this.width<992){
        this.expanded_menu = false
        this.$store.dispatch('sidemenu/dispatchMenu', false);
        localStorage.setItem('show_menu', false);
      }
    },
    menuToggle() {
      this.expanded_menu = !this.expanded_menu
      this.$store.dispatch('sidemenu/dispatchMenu', this.expanded_menu);
      localStorage.setItem('show_menu', this.expanded_menu);
    },
    handleResize() {
      this.width = window.innerWidth;
    }
  },
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  }
};
</script>

<style scoped>
.disabled {
  opacity: 0.5;
  pointer-events: none;
}

#toggle-ico {
  //position: absolute;
  //top: 10px;
  //right: 10px;
  //cursor: pointer;

  position: absolute;
  top: 0px;
  right: 1px;
  cursor: pointer;
  width: 28px;
  height: 28px;
  background: #f9f9f9;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0 0 5px 5px;
  color: #b3b3b3;
}

.menu-ico {
  padding-left: 0.5rem !important;
}

.p-left {
  padding-left: 0.5rem !important;
}

.p-right {
  padding-right: 0.5rem !important;
}

.max-width-100 {
  max-width: 100px !important;
  width: 100% !important;
  min-width: 100px !important;
}

.max-width-100 .menu-ico {
  padding-left: 0 !important;
}

.max-width-100 .p-left {
  padding-left: 0 !important;
}
</style>
