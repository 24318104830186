<template>
  <DefaultLayout>
    <div>
      <PageBreadcrumbs :breadcrumbs="breadcrumbs" />
      <div
        id="products-listing-table-wrapper"
        class="mt-3 py-4">
        <div
          class="filter-double-flex"
          v-if="show_filter">
          <div class="filter-left-content-flex">
            <div class="filter-text">{{ $t('Filters') }}:</div>
            <div>
              <customer-select
                :options="customers"
                @filterByCustomer="getCars()"
                @clearQuery="clearQueryByCustomer()"
                :content_will_select="$t('Customer')"
                :selected_count="$route.query.customer ? 1 : 0" />
            </div>
            <div class="ml-3">
              <car-brand-select
                :options="carBrands"
                @filterByCarBrand="
                  getCars();
                  getYears();
                "
                @clearQuery="clearQueryByCarBrand()"
                :content_will_select="$t('Brand')"
                :selected_count="$route.query.car_brand ? 1 : 0" />
            </div>
            <div class="ml-3">
              <car-year-select
                :options="carYears"
                :content_will_select="$t('Year')"
                @filterByCarYear="
                  getCars();
                  getCarModels();
                "
                @clearQuery="clearQueryByCarYear()"
                :selected_count="$route.query.car_year ? 1 : 0" />
            </div>
            <div class="ml-3">
              <car-model-select
                :options="carModels"
                :content_will_select="$t('Model')"
                @filterByCarModel="
                  getCars();
                  getCarVersions();
                "
                @clearQuery="clearQueryByCarModel()"
                :selected_count="$route.query.car_model ? 1 : 0" />
            </div>
            <div class="ml-3">
              <car-version-select
                :options="carVersions"
                :content_will_select="$t('Version')"
                @filterByCarVersion="getCars()"
                @clearQuery="clearQueryByCarVersion()"
                :selected_count="$route.query.car_version ? 1 : 0" />
            </div>
          </div>
          <div class="filter-double-flex-right">
            <div class="filter-double-flex-right-actions">
              <span
                class="clear-filters-text ml-3"
                @click="clearQueryParameters()"
                >{{ $t('Clear Filters') }}</span
              >
              <i
                class="fas fa-xmark"
                @click="show_filter = !show_filter"></i>
            </div>
          </div>
        </div>
        <div
          class="products-double-flex"
          v-if="!show_filter">
          <div class="products-double-flex-left">
            <button
              class="add-new-product-btn"
              @click="$router.push({ name: 'CarAdd' })">
              <i class="fa-solid fa-plus"></i>
              {{ $t('Add Car') }}
            </button>
          </div>
          <div class="products-double-flex-right">
<!--            <div-->
<!--              class="products-double-flex-right-actions"-->
<!--              v-if="!show_search_text"-->
<!--              :class="!show_search_text ? 'opened' : ''">-->
<!--              <span>-->
<!--                <i class="fa-solid fa-search ml-0"></i>-->
<!--              </span>-->
<!--              <b-form-input-->
<!--                class="search-text-input"-->
<!--                v-model="filter"-->
<!--                v-on:keyup.enter="getCars()"-->
<!--                :placeholder="$t('Plate, brand or car owner')"-->
<!--                :class="!show_search_text ? 'show-search_text' : ''" />-->
<!--              <i-->
<!--                class="fa-solid fa-xmark close"-->
<!--                @click="-->
<!--                  show_search_text = !show_search_text;-->
<!--                  filter = '';-->
<!--                "></i>-->
<!--            </div>-->
<!--            <div-->
<!--              class="products-double-flex-right-actions"-->
<!--              v-if="show_search_text"-->
<!--              @click="show_search_text = !show_search_text">-->
<!--              <span>-->
<!--                <i class="fa-solid fa-search"></i>-->
<!--                <p>{{ $t('Search') }}</p>-->
<!--              </span>-->
<!--            </div>-->
            <div
              class="products-double-flex-right-actions"
              @click="show_filter = !show_filter">
              <span>
                <i class="fa-solid fa-filter"></i>
                <p>{{ $t('Filter') }}</p>
              </span>
            </div>
          </div>
        </div>
        <div class="product-listing-table">
          <table v-if="!loader">
            <tr class="product-listing-table-head">
              <th>
                <label class="product-listing-checkbox-label">
                  <input
                    type="checkbox"
                    @click="checkUncheck()" />
                  <span class="checkmark"></span>
                </label>
              </th>
              <th>{{ $t('Plate') }}</th>
              <th>{{ $t('Brand') }}</th>
              <th>Model / {{ $t('Version') }}</th>
              <th>{{ $t('Year') }}</th>
              <th>{{ $t('Fuel') }}</th>
              <th>{{ $t('Car Owner') }}</th>
              <th></th>
            </tr>
            <tr
              v-for="car in cars.items"
              :key="car.$index">
              <td>
                <label class="product-listing-checkbox-label">
                  <input
                    type="checkbox"
                    name="single-payment-checkbox" />
                  <span class="checkmark"></span>
                </label>
              </td>
              <td>{{ car.plate_number }}</td>
              <td>
                <div class="car-listing-img-wrapper">
                  <img
                    :src="car.carBrand.asset"
                    alt=""
                    v-if="car.carBrand.asset.length > 0" />
                  <span
                    class="no-brand"
                    v-else
                    >{{ car.carBrand.name }}</span
                  >
                </div>
              </td>
              <td>{{ car.car.name }}</td>
              <td>{{ car.car.year }}</td>
              <td>{{ car.car.fuel_type }}</td>
              <td>{{ car.customer.name + ' ' + car.customer.surname }}</td>

              <td>
                <b-dropdown
                  right
                  no-caret>
                  <template #button-content>
                    <i class="fa-solid fa-ellipsis-vertical"></i>
                  </template>
                  <b-dropdown-item class="table-dropdown-actions">
                    <router-link :to="'/car/' + car.id"
                      ><p class="w-100 m-0">{{ $t('Edit') }}</p></router-link
                    >
                  </b-dropdown-item>
                  <b-dropdown-item
                    href="#"
                    class="table-dropdown-actions"
                    v-b-modal="'delete_modal' + '_' + car.id"
                    >{{ $t('Delete') }}
                  </b-dropdown-item>
                </b-dropdown>
              </td>
              <b-modal
                :id="'delete_modal' + '_' + car.id"
                centered
                hide-header
                hide-footer>
                <div class="flex-row">
                  <div>
                    {{ $t('Are you sure you want to delete the vehicle?') }}
                  </div>
                  <div class="flex-center-space mt-4">
                    <button class="cancel">{{ $t('Cancel') }}</button>
                    <button
                      class="approve"
                      @click="deleteCar(car.id)">
                      {{ $t('Delete') }}
                    </button>
                  </div>
                </div>
              </b-modal>
            </tr>
          </table>
          <div
            id="table-info-and-pagination"
            v-if="!loader && cars.items.length>0">
            <div class="table-results-info">
              <p>
                {{$t('{total} {from} {start}-{end} is showing.',{total: cars.pagination.total, from: $t('from cars'), start: cars.items.length>0 ? (cars.pagination.current_page === 1 ? 1 : ((cars.pagination.current_page - 1) * cars.pagination.per_page) + 1) : 0, end: cars.pagination.total_page === cars.pagination.current_page ? cars.pagination.total : cars.pagination.current_page * cars.pagination.per_page})}}
              </p>
            </div>
            <div class="pagination-wrapper">
              <b-pagination
                  v-model="current_page"
                  :total-rows="cars.pagination.total"
                  :per-page="cars.pagination.per_page"
                  align="center"
                  pills
                  class="my-1"
                  @input="getCars()"/>
            </div>
          </div>
          <div v-if="!loader && cars.items.length === 0" class="d-flex flex-column align-items-center">
            <p class="table-no-text"> {{
                $t('No {data} found to list.', {data: $t('Car').toLocaleLowerCase()})
              }}</p>
            <button @click="$router.push({ name: 'CarAdd' })" class="no-data-btn"><i class="fa-solid fa-plus"></i> {{$t('Add Car')}}</button>
          </div>
          <div
            class="loader"
            v-if="loader">
            <content-placeholders :rounded="true">
              <content-placeholders-img />
              <content-placeholders-text :lines="10" />
            </content-placeholders>
          </div>
        </div>
      </div>
    </div>
  </DefaultLayout>
</template>

<script>
/* eslint-disable */
import DefaultLayout from '@/layouts/default';
import PageBreadcrumbs from '../../components/PageBreadcrumbs.vue';
import CarBrandSelect from '@/components/shared/CarBrandSelect';
import CarYearSelect from '@/components/shared/CarYearSelect';
import CarModelSelect from '@/components/shared/CarModelSelect';
import CarVersionSelect from '@/components/shared/CarVersionSelect';
import CustomerSelect from '@/components/shared/CustomerSelect';

export default {
  components: {
    PageBreadcrumbs,
    DefaultLayout,
    CarBrandSelect,
    CarYearSelect,
    CarModelSelect,
    CarVersionSelect,
    CustomerSelect
  },
  data() {
    return {
      breadcrumbs: [
        {
          name: this.$i18n.t('Cars')
        }
      ],
      cars: {
        items: [],
        pagination: {}
      },
      loader: false,
      per_page: 20,
      current_page: 1,
      show_search_text: true,
      filter: '',
      show_filter: false,
      customers: []
    };
  },
  watch: {
    '$route.query': function () {
      this.getCars();
    }
  },
  mounted() {
    this.getCars();
    this.$axios.get('/customers/only-name').then((response) => {
      this.customers = response.data.responseData.customers;
    });
  },
  computed: {
    carBrands() {
      if (this.$store.state.carBrands.brands.length < 1) {
        this.$store.dispatch('carBrands/fetchCarBrands');
      }
      return this.$store.state.carBrands.brands;
    },
    carYears() {
      if (
        this.$store.state.carYears.years.length < 1 &&
        this.$route.query.car_brand
      ) {
        this.$store.dispatch(
          'carYears/fetchCarYears',
          this.$route.query.car_brand
        );
      }
      return this.$store.state.carYears.years;
    },
    carModels() {
      if (
        this.$store.state.carModels.models.length < 1 &&
        this.$route.query.car_brand &&
        this.$route.query.car_year
      ) {
        this.$store.dispatch('carModels/fetchCarModels', {
          car_brand_id: this.$route.query.car_brand,
          car_year: this.$route.query.car_year
        });
      }
      return this.$store.state.carModels.models;
    },
    carVersions() {
      if (
        this.$store.state.carVersions.versions.length < 1 &&
        this.$route.query.car_brand &&
        this.$route.query.car_year &&
        this.$route.query.car_model
      ) {
        this.$store.dispatch('carVersions/fetchCarVersions', {
          car_brand_id: this.$route.query.car_brand,
          car_year: this.$route.query.car_year,
          car_model_id: this.$route.query.car_model
        });
      }
      return this.$store.state.carVersions.versions;
    }
  },
  methods: {
    getCars() {
      this.loader = true;
      this.$axios
        .get(`/customers/cars`, {
          params: {
            page: this.current_page,
            limit: this.per_page,
            brand_id: this.$route.query.car_brand,
            year: this.$route.query.car_year,
            model_id: this.$route.query.car_model,
            version: this.$route.query.car_version,
            customer_id: this.$route.query.customer
          }
        })
        .then((response) => {
          this.cars = response.data.responseData;
          this.loader = false;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    deleteCar(id) {
      this.loader = true;
      this.$axios
        .delete(`/customers/cars/${id}`, {})
        .then((response) => {
          this.$swal.fire({
            iconHtml: '<img src="/img/success.svg" width="32" height="32">',
            position: 'top-end',
            title: this.$i18n.t('Vehicle deleted successfully'),
            showConfirmButton: false,
            timer: 2500,
            toast: true,
            customClass: {
              icon: 'swal2-no-border'
            }
          });
          this.getCars();
        })
        .catch((error) => {
          var message = error.response.data.responseData.error
            ? error.response.data.responseData.error[0].message
            : error.response.data.responseData.message;
          this.$swal.fire({
            iconHtml: '<img src="/img/error.svg" width="32" height="32">',
            position: 'top-end',
            title: message,
            showConfirmButton: false,
            timer: 2500,
            toast: true,
            customClass: {
              icon: 'swal2-no-border'
            }
          });
        });
    },
    checkUncheck(checkBox) {
      var get = document.getElementsByName('single-payment-checkbox');
      for (var i = 0; i < get.length; i++) {
        if (get[i].checked === true) {
          get[i].checked = false;
        } else {
          get[i].checked = true;
        }
        // without if => get[i].checked = checkBox.checked;}
      }
    },
    clearQueryByCarBrand() {
      const currentQuery = { ...this.$route.query }; // Make a copy of the current query object
      delete currentQuery.car_brand;
      this.$router.push({ path: '/cars', query: { ...currentQuery } });
      this.getCars();
    },
    clearQueryByCarYear() {
      const currentQuery = { ...this.$route.query }; // Make a copy of the current query object
      delete currentQuery.car_year;
      this.$router.push({ path: '/cars', query: { ...currentQuery } });
      this.getCars();
    },
    clearQueryByCarModel() {
      const currentQuery = { ...this.$route.query }; // Make a copy of the current query object
      delete currentQuery.car_model;
      this.$router.push({ path: '/cars', query: { ...currentQuery } });
      this.getCars();
    },
    getYears() {
      if (this.$route.query.car_brand) {
        this.$store.dispatch(
          'carYears/fetchCarYears',
          this.$route.query.car_brand
        );
      }
    },
    getCarModels() {
      if (this.$route.query.car_brand && this.$route.query.car_year) {
        this.$store.dispatch('carModels/fetchCarModels', {
          car_brand_id: this.$route.query.car_brand,
          car_year: this.$route.query.car_year
        });
      }
    },
    getCarVersions() {
      if (
        this.$route.query.car_brand &&
        this.$route.query.car_year &&
        this.$route.query.car_model
      ) {
        this.$store.dispatch('carVersions/fetchCarVersions', {
          car_brand_id: this.$route.query.car_brand,
          car_year: this.$route.query.car_year,
          car_model_id: this.$route.query.car_model
        });
      }
    },
    clearQueryByCarVersion() {
      const currentQuery = { ...this.$route.query }; // Make a copy of the current query object
      delete currentQuery.car_version;
      this.$router.push({ path: '/cars', query: { ...currentQuery } });
      this.getCars();
    },
    clearQueryByCustomer() {
      const currentQuery = { ...this.$route.query }; // Make a copy of the current query object
      delete currentQuery.customer;
      this.$router.push({ path: '/cars', query: { ...currentQuery } });
      this.getCars();
    },
    clearQueryParameters() {
      this.$router.push({ path: '/cars' });
      this.getCars();
    }
  }
};
</script>

<style scoped>
.car-listing-img-wrapper img {
  width: 100%;
  max-width: 60px;
}

.car-listing-img-wrapper .no-brand {
  border: 1px solid #d3d3d3;
  padding: 6px 8px;
  border-radius: 5px;
  background: #fff;
}

.flex-row {
  padding: 2rem;
  color: #1c1c1c;
  text-align: center;
  font-family: Montserrat;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.cancel,
.approve {
  padding: 0.75rem;
  width: 100%;
  margin: 0 0.75rem;
  outline: none;
  border-radius: 5px;
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.4px;
  transition: 0.5s;
}

.cancel {
  background: transparent;
  border: 1px solid #d3d3d3;
  color: #d3d3d3;
}

.approve {
  background: #1c1c1c;
  border: 1px solid #1c1c1c;
  color: #f4f4f4;
}
</style>
