<template>
  <div class="row w-100">
    <div class="d-sm-none d-md-none d-lg-block col-lg-3"></div>
    <div class="col-sm-12 col-md-12 col-lg-6 mt-3 offer-content">
      <img
        alt="Servislet Pro"
        class="logo pr-0"
        src="../../../assets/images/logo.png" />
      <div class="steps">
        <div
          class="step1"
          @click="$router.push('/customer/offer/2')">
          Teklif
        </div>
        <div
          class="step2"
          @click="$router.push('/customer/offer/delivery/2')">
          Teslimat
        </div>
        <div class="step3">Ödeme</div>
      </div>
      <div class="mt-4 content-card centered-content">
        <span class="pp">OZ</span>
        <div class="branch-name">Bayi Adı</div>
        <div class="d-flex justify-content-between mt-3">
          <div class="item">
            <span class="fw-700">Teklif ID:</span>
            <span>#12345678</span>
          </div>
          <div class="item">
            <span class="fw-700">Teklif Tarihi:</span>
            <span>01/01/2023</span>
          </div>
          <div class="item">
            <span class="fw-700">Teklif Bitiş Tarihi:</span>
            <span>02/03/2023</span>
          </div>
        </div>
        <div class="text-left">
          <div class="title fw-700">Teslimat</div>
          <div>
            <div class="d-flex align-items-center mt-3">
              <b-form-radio
                name="some-radios"
                value="1" />
              <span class="cargo-text ml-2">
                <i class="fa-regular fa-credit-card"></i>
                <span class="ml-2">Banka / Kredi Kartı</span>
              </span>
            </div>
            <div>
              <div class="row mt-3">
                <div class="col-sm-12 col-md-6 col-lg-6 mt-3">
                  <div class="row">
                    <div class="col-sm-12">
                      <b-form-group>
                        <label for="card_name">Kart Sahibinin Adı Soyadı</label>
                        <b-form-input v-model="card_name" />
                      </b-form-group>
                    </div>

                    <div class="col-sm-12">
                      <b-form-group>
                        <label for="card_number">Kart Numarası</label>
                        <b-form-input
                          v-model="card_number"
                          v-mask="'#### #### #### ####'" />
                      </b-form-group>
                    </div>

                    <div class="col-sm-12 col-md-8 col-lg-8">
                      <b-form-group>
                        <label for="car_month">Son Kullanma Tarihi</label>
                        <div class="row">
                          <div class="col-sm-12 col-md-6">
                            <b-form-select v-model="card_month">
                              <b-form-select-option
                                :value="i"
                                v-for="i in 31"
                                :key="i.$index"
                                >{{
                                  i < 10 ? '0' + i : i
                                }}</b-form-select-option
                              >
                            </b-form-select>
                          </div>

                          <div class="col-sm-12 col-md-6">
                            <b-form-select v-model="card_year">
                              <b-form-select-option
                                :value="i + 2022"
                                v-for="i in 20"
                                :key="i.$index"
                                >{{ i + 2022 }}</b-form-select-option
                              >
                            </b-form-select>
                          </div>
                        </div>
                      </b-form-group>
                    </div>

                    <div class="col-sm-12 col-md-4">
                      <b-form-group>
                        <label for="car_cvv">CVV</label>
                        <b-form-input
                          v-model="card_cvv"
                          type="number" />
                      </b-form-group>
                    </div>
                  </div>
                </div>
                <div class="d-sm-none d-md-block col-md-6 col-lg-6">
                  <div
                    class="flip-card"
                    id="flip-card">
                    <div
                      class="flip-card-inner"
                      id="flip-card-inner">
                      <div class="flip-card-front">
                        <span class="card-bank-name">
                          <span> Amerikan Express </span>
                        </span>
                        <div class="d-flex flex-column">
                          <div class="card-number">
                            <span>{{
                              card_number ? card_number : '#### #### #### ####'
                            }}</span>
                          </div>
                        </div>
                        <div class="bottombar-front">
                          <div class="holder">
                            <div class="d-flex">
                              <span class="hold">{{
                                card_name ? card_name : 'Servislet'
                              }}</span>
                              <div class="valid-date">
                                {{
                                  card_year && card_month
                                    ? card_year + '/' + card_month
                                    : '##/##'
                                }}
                              </div>
                            </div>
                            <img
                              src="https://ww1.freelogovectors.net/svg15/barbie-logo-1-freelogovectors.net.svg"
                              alt=""
                              width="100" />
                          </div>
                        </div>
                      </div>
                      <div class="flip-card-back">
                        <div class="topbar-back">
                          <div></div>
                          <!--                          <img class="fill-white" :src="bank.programAsset" alt="" width="50" v-if="bank.programAsset">-->
                        </div>
                        <div class="back-band"></div>
                        <div class="cvv-content">
                          CVV: {{ card_cvv ? card_cvv : '###' }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-sm-12 mt-3 d-flex align-items-center">
                  <b-form-checkbox
                    id="checkbox-1"
                    name="checkbox-1"
                    :value="true"
                    :unchecked-value="false" />
                  <span
                    class="accept-text"
                    @click="accept_status = trye"
                    ><span class="font-servislet cursor-pointer"
                      >Ön Bilgilendirme Formu</span
                    >
                    ve
                    <span class="font-servislet cursor-pointer"
                      >Mesafeli Satış Sözleşmesi</span
                    >'ni okudum, kabul ediyorum.</span
                  >
                </div>
              </div>
            </div>
            <div class="mt-4 d-flex justify-content-end">
              <button class="btn save-btn">Devam Et</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-sm-none d-md-none d-lg-block col-lg-3"></div>
  </div>
</template>

<script>
export default {
  name: 'step3',
  data() {
    return {
      card_name: null,
      card_number: null,
      card_year: null,
      card_month: null,
      card_cvv: null
    };
  }
};
</script>

<style scoped>
.centered-content {
  height: fit-content;
  padding: 3rem;
}
.offer-content {
  text-align: center;
}
.steps {
  display: flex;
  margin-top: 2rem;
  padding: 0 4rem;
}
.step1,
.step2,
.step3 {
  width: 33.3%;
  border: none;
  font-size: 14px;
  text-decoration: underline;
  cursor: pointer;
}
.step1,
.step2,
.step3 {
  border-top: 7px solid #06a5d0;
  padding: 0.5rem 0 0 0;
}
.content-card {
  box-shadow: 5px 5px 8px #dcdcdc, -5px -5px 8px #f6f6f6;
  border-radius: 15px;
}
.pp {
  margin-top: 2rem;
  background-color: #1c1c1c;
  color: #fff;
  text-transform: uppercase;
  padding: 17px 16px;
  font-weight: 400;
  border-radius: 100%;
}
.branch-name {
  margin-top: 1.5rem;
  font-weight: 600;
}
.item span {
  font-size: 14px;
  margin-right: 6px;
}
.fw-700 {
  font-weight: 700;
}
.title {
  font-size: 20px;
  border-bottom: 1px solid #d9d9d9;
  margin-top: 2rem;
  padding-bottom: 0.5rem;
}
.cargo-text {
  font-size: 14px !important;
  color: #1c1c1c !important;
}

.save-btn,
.save-btn:hover {
  background-color: #1c1c1c;
  color: #fff;
  padding: 6px 30px;
  font-size: 14px;
}
.fa-credit-card {
  color: #1c1c1c;
  border: 1px solid #1c1c1c;
  padding: 7px;
  border-radius: 5px;
}
.custom-control {
  display: flex;
  align-items: flex-start;
}

.flip-card {
  width: 100%;
  height: 250px;
  perspective: 1000px;
  background-color: inherit;
  box-shadow: rgb(162 162 162 / 20%) 0px 4px 8px 0px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-front {
  background: linear-gradient(90deg, #1c1c1c 70%, #2c2c2c 30%);
  color: #fefefe;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 5px;
  margin-top: 1rem;
}

.flip-card-back {
  background: linear-gradient(90deg, #1c1c1c 70%, #2c2c2c 30%);
  color: #fefefe;
  transform: rotateY(180deg);
  border-radius: 5px;
}

.topbar-front {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.card-number {
  font-size: 18px;
  font-weight: 600;
  margin-top: 25%;
}

.valid {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
}

.valid-text {
  display: flex;
  flex-direction: column;
  font-size: 15px;
}

.valid-date {
  font-size: 15px;
  font-weight: 600;
  margin-left: 3rem;
}

.bottombar-front {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.holder {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.card-holder {
  font-size: 10px;
}

.hold {
  font-size: 15px;
  font-weight: 600;
}

.topbar-back {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
}

.back-band {
  height: 35px;
  background-color: var(--version-text);
}

.cvv-content {
  padding: 20px 10px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 60%;
  background-color: transparent;
  color: #fefefe;
  border-radius: 5px;
  font-size: 14px;
}

.card-bank-name {
  font-size: 15px;
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
}
.accept-text {
  font-size: 12px;
}
</style>
