<template>
  <b-sidebar id="export-sidebar" shadow backdrop title="Sidebar" right v-model="status">
    <template #header="{ hide }">
      <div class="d-flex flex-column gap-3 w-100" style="margin-bottom: 30px;">
        <div class="d-flex justify-content-end">
          <i class="fa-solid fa-xmark cursor-pointer" v-b-toggle.export-sidebar></i>
        </div>
        <div class="export-title">{{$t('Export')}}</div>
      </div>
    </template>

    <div v-if="$store.state.warehouse.warehouses.length>1">
      <div class="fs-12">{{$t('Warehouse')}}: </div>

      <export-warehouse-select @select="warehouse_id = $event" />
    </div>

    <div class="fs-12" :class="$store.state.warehouse.warehouses.length>1 ? 'mt-3' : ''">{{$t('Transfer Type')}}: </div>

    <export-sidebar-select @select="selected_option = $event" :selected_ids="selected_product_ids" :selected_option="selected_option" />

    <div v-if="selected_option === 5">
      <brand-export-select @setBrandSlugs="selected_brand_slugs = $event" />
    </div>

    <template #footer="{ hide }">
      <b-button class="export-btn" :disabled="selected_option === null || (selected_option === 5 && !selected_brand_slugs)" @click="exportProducts()">{{$t('Export')}}</b-button>
    </template>
  </b-sidebar>
</template>

<script>
import ExportSidebarSelect from "@/components/products/selects/ExportSidebarSelect.vue";
import ExportWarehouseSelect from "@/components/products/selects/ExportWarehouseSelect.vue";
import axios from "@/plugins/axios";
import BrandExportSelect from "@/components/products/selects/BrandExportSelect.vue";
export default {
  name: 'ExportSidebar',
  components:{
    BrandExportSelect,
    ExportSidebarSelect,
    ExportWarehouseSelect
  },
  props: {
    selected_product_ids: {
      type: Array,
      default: []
    }
  },
  data(){
    return {
      status: false,
      selected_option: null,
      selected_brand_slugs: '',
      warehouse_id: null
    }
  },
  watch:{
    status(){
      if(!this.status){
        this.selected_option = null
        this.selected_brand_slugs = ''
      }
    }
  },
  methods:{
    exportProducts(){
      if(this.selected_option === 0){
        if(this.selected_product_ids.length>0){
          axios.post('/tire/products/download',{
            warehouse_id: this.warehouse_id,
            product_ids: this.selected_option === 4 ? null : (this.selected_product_ids.length>0 ? this.selected_product_ids : null),
            is_active: this.selected_option === null ? null : (this.selected_option === 1 ? true : (this.selected_option === 3 ? false : null)),
            is_popular: this.selected_option === null ? null : (this.selected_option === 2 ? true : null)
          }).then(response=>{
            if(response.data.status){
              this.$root.$emit('bv::toggle::collapse', 'export-sidebar')
              this.$swal.fire({
                iconHtml: '<img src="/img/success.svg" width="32" height="32">',
                position: 'top-end',
                title: this.$i18n.t('You can save exported products in the downloaded files section.'),
                showConfirmButton: false,
                timer: 2500,
                toast: true,
                customClass: {
                  icon: 'swal2-no-border'
                }
              });
              setTimeout(()=>{
                this.$store.dispatch('topbar/getCount')
              },5000)
              this.$emit('clear')
            }
          }).catch(error=>{
            console.log(error.response)
          })
        }else{
          this.$swal.fire({
            iconHtml: '<img src="/img/error.svg" width="32" height="32">',
            position: 'top-end',
            title: this.$i18n.t('Please select products from table.'),
            showConfirmButton: false,
            timer: 2500,
            toast: true,
            customClass: {
              icon: 'swal2-no-border'
            }
          });
        }
      }else{
        axios.post('/tire/products/download',{
          warehouse_id: this.warehouse_id,
          product_ids: this.selected_option === 4 ? null : (this.selected_product_ids.length>0 ? this.selected_product_ids : null),
          is_active: this.selected_option === null ? null : (this.selected_option === 1 ? true : (this.selected_option === 3 ? false : null)),
          is_popular: this.selected_option === null ? null : (this.selected_option === 2 ? true : null),
          brand_slugs: this.selected_brand_slugs
        }).then(response=>{
          if(response.data.status){
            this.$root.$emit('bv::toggle::collapse', 'export-sidebar')
            this.$swal.fire({
              iconHtml: '<img src="/img/success.svg" width="32" height="32">',
              position: 'top-end',
              title: this.$i18n.t('You can save exported products in the downloaded files section.'),
              showConfirmButton: false,
              timer: 2500,
              toast: true,
              customClass: {
                icon: 'swal2-no-border'
              }
            });
            setTimeout(()=>{
              this.$store.dispatch('topbar/getCount')
            },5000)
            this.$emit('clear')
            this.selected_option = null;
          }
        }).catch(error=>{
          console.log(error.response)
        })
      }
    }
  }
}
</script>

<style scoped>
.export-btn {
  background-color: #06a5d0;
  border: 1px solid #06a5d0;
  color: #F4F4F4;
  cursor: pointer;
  border-radius: 10px;
  width: 100%;
  padding: 12px;
}

.fa-xmark {
  font-size: 24px;
}

.export-title {
  font-size: 24px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;

}
</style>

<style>
#export-sidebar {
  padding: 30px !important;
  background-color: #1C1C1C !important;
  color: #f4f4f4 !important;
  border-radius: 40px 0 0 40px !important;
}

#export-sidebar .b-sidebar-header {
  padding: 0 !important;
}
</style>
