<template>
  <div v-click-outside="close">
    <div
      class="component-container"
      @click="showOptions = !showOptions">
      <div
        class="component__select"
        :class="showOptions ? 'opened-border' : 'closed-border'">
        <span
          class="component__select--name"
          v-if="!selected"
          >{{$t('Choose')}}</span
        >
        <span
          class="component__select--name"
          v-else
          >{{ selected.name }}</span
        >

        <div>
          <i
            class="fa-solid fa-xmark mr-2"
            @click="selected = null"
            v-if="selected && show_close_icon"></i>
          <i
            class="fa-solid fa-chevron-down"
            v-if="!showOptions"></i>
          <i
            class="fa-solid fa-chevron-up"
            v-if="showOptions"></i>
        </div>
      </div>
      <div
        class="component__select-options"
        v-if="showOptions">
        <div
          @click="
            selected = option;
            $emit('select', selected.id);
          "
          class="select--option"
          :class="option.name === selected.name ? 'selected-option' : ''"
          v-for="option in options">
          <div>{{ option.name }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vClickOutside from "v-click-outside";

export default {
  name: 'GraySelect',
  directives: {
    clickOutside: vClickOutside.directive
  },
  props: {
    options: {
      type: Array,
      default: () => []
    },
    selected_key: {
      type: Object,
      default: () => {}
    },
    show_close_icon: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      showOptions: false,
      selected: this.selected_key
    };
  },
  methods:{
    close(){
      this.showOptions = false
    }
  }
};
</script>

<style scoped>
.component__select {
  height: 38px;
  border: 1px solid rgba(211, 211, 211, 0.4);
  line-height: 38px;
  width: 150px;
  grid-template-columns: 10fr 1fr;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  background: rgba(211, 211, 211, 0.4);
}
.opened-border {
  border-radius: 5px 5px 0 0;
}
.closed-border {
  border-radius: 5px;
}

.component__select--name {
  font-size: 13px;
  padding: 0 0 0 16px;
  cursor: pointer;
}

.component__select-options {
  max-height: 180px;
  border: 1px solid rgba(211, 211, 211, 0.4);
  border-top: none;
  overflow: auto;
  position: absolute;
  z-index: 1500;
  max-width: 500px;
  width: 150px;
  margin: 0;
  padding: 0;
}

.select--option {
  height: 38px;
  display: flex;
  font-size: 13px;
  align-items: center;
  padding: 0 0 0 16px;
  background-color: rgba(211, 211, 211, 0.4);
  border-bottom: 1px solid rgba(211, 211, 211, 0.4);
  cursor: pointer;
}
.select--option:hover {
  background-color: rgba(139, 138, 138, 0.4);
}

.select--option:last-child {
  border-bottom: none;
  border-radius: 0 0 5px 5px;
}

.select--option input {
  display: none;
}

.component__select i {
  margin-right: 16px;
  font-size: 12px;
  cursor: pointer;
  color: #828282;
}
.selected-option {
  background-color: rgba(139, 138, 138, 0.4);
}
</style>
