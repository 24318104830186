<!-- eslint-disable -->
<template>
  <Default>
    <div>
      <PageBreadcrumbs :breadcrumbs="breadcrumbs" />
      <div id="car-adding-steps-wrapper">
        <div class="car-adding-steps">
          <div
            class="car-adding-step-item"
            :class="{ activeCarAddingStep: customerStep }"
            @click="
              carStep = false;
              addressStep = false;
              customerStep = true;
            ">
            <div>
              <i class="fa-regular fa-user"></i>
            </div>
            <div>
              <p class="car-adding-step-title">{{ $t('Customer') }}</p>
            </div>
          </div>
          <div class="car-adding-step-indicator">
            <i class="fa-solid fa-angle-right"></i>
          </div>
          <div
            class="car-adding-step-item"
            :class="{ activeCarAddingStep: addressStep }"
            @click="
              carStep = false;
              customerStep = false;
              addressStep = true;
            ">
            <div>
              <i class="fa-regular fa-home"></i>
            </div>
            <div>
              <p class="car-adding-step-title">{{ $t('Address') }}</p>
            </div>
          </div>
          <div class="car-adding-step-indicator">
            <i class="fa-solid fa-angle-right"></i>
          </div>
          <div
            class="car-adding-step-item"
            :class="{ activeCarAddingStep: carStep }"
            @click="
              addressStep = false;
              customerStep = false;
              carStep = true;
            ">
            <div>
              <i
                class="fa-regular fa-car"
                :class="carStep ? 'text-light' : ''"></i>
            </div>
            <div>
              <p class="car-adding-step-title">{{ $t('Car') }}</p>
            </div>
          </div>
        </div>
      </div>

      <div
        class="row"
        id="car-add-main-wrapper">
        <div class="col-lg-8">
          <ValidationObserver ref="customerAddRules">
            <div class="product-detail-left-wrapper">
              <div id="car-add-wrapper">
                <div
                  id="car-step"
                  v-if="carStep === true">
                  <p class="page-title">{{ $t('Add Car') }}</p>
                  <div class="row mt-3">
                    <b-col
                      sm="12"
                      md="4"
                      lg="4">
                      <ValidationProvider
                        :name="$t('Plate')"
                        rules="max:20"
                        #default="{ errors }"
                        :rules="user_car_id ? 'required' : ''">
                        <b-form-group>
                          <label for="plate"
                            >{{ $t('Plate') }}
                            <small
                              class="text-danger"
                              v-if="user_car_id"
                              >*</small
                            ></label
                          >
                          <b-form-input
                            v-model="plate"
                            class="bg-transparent"
                            @input="formatCarPlate"></b-form-input>
                          <label
                            for="plate"
                            class="text-danger"
                            v-if="errors.length > 0"
                            ><small>*</small>{{ errors[0] }}.</label
                          >
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>

                    <b-col
                      sm="12"
                      md="4"
                      lg="4">
                      <ValidationProvider
                        :name="$t('Chassis number')"
                        rules="min:17|max:17"
                        #default="{ errors }">
                        <b-form-group>
                          <label for="chassis_number">{{
                            $t('Chassis number')
                          }}</label>
                          <b-form-input
                            v-model="chassis_number"
                            type="number"
                            class="bg-transparent"></b-form-input>
                          <label
                            for="chassis_number"
                            class="text-danger"
                            v-if="errors.length > 0"
                            ><small>*</small>{{ errors[0] }}.</label
                          >
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col
                      sm="12"
                      md="4"
                      lg="4">
                      <b-form-group>
                        <label for="chassis_number">KM</label>
                        <b-form-input
                          v-model="total_mileage"
                          type="number"
                          class="bg-transparent"></b-form-input>
                      </b-form-group>
                    </b-col>
                  </div>
                  <p class="page-title mt-3">{{ $t('Car Information') }}</p>
                  <div class="row mt-3">
                    <b-col
                      sm="12"
                      md="4"
                      lg="4">
                      <ValidationProvider
                        :name="$t('Brand')"
                        #default="{ errors }"
                        :rules="plate ? 'required' : ''">
                        <b-form-group>
                          <label for="brand"
                            >{{ $t('Brand') }}
                            <small
                              class="text-danger"
                              v-if="plate"
                              >*</small
                            ></label
                          >
                          <b-form-select
                            v-model="brand"
                            :options="brands"
                            @change="getCarYears()"></b-form-select>
                          <label
                            for="brand"
                            class="text-danger"
                            v-if="errors.length > 0"
                            ><small>*</small>{{ errors[0] }}.</label
                          >
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>

                    <b-col
                      sm="12"
                      md="4"
                      lg="4">
                      <ValidationProvider
                        :name="$t('Year')"
                        #default="{ errors }"
                        :rules="plate ? 'required' : ''">
                        <b-form-group>
                          <label for="year"
                            >{{ $t('Year') }}
                            <small
                              class="text-danger"
                              v-if="plate"
                              >*</small
                            ></label
                          >
                          <b-form-select
                            v-model="year"
                            :options="years"
                            @change="getCarModels()"
                            :disabled="!brand"></b-form-select>
                          <label
                            for="year"
                            class="text-danger"
                            v-if="errors.length > 0"
                            ><small>*</small>{{ errors[0] }}.</label
                          >
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>

                    <b-col
                      sm="12"
                      md="4"
                      lg="4">
                      <ValidationProvider
                        name="Model"
                        #default="{ errors }"
                        :rules="plate ? 'required' : ''">
                        <b-form-group>
                          <label for="model"
                            >Model
                            <small
                              class="text-danger"
                              v-if="plate"
                              >*</small
                            ></label
                          >
                          <b-form-select
                            v-model="model"
                            :options="models"
                            @change="getVersions()"
                            :disabled="!brand || !year"></b-form-select>
                          <label
                            for="model"
                            class="text-danger"
                            v-if="errors.length > 0"
                            ><small>*</small>{{ errors[0] }}.</label
                          >
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>

                    <b-col
                      sm="12"
                      md="4"
                      lg="4">
                      <ValidationProvider
                        :name="$t('Version')"
                        #default="{ errors }"
                        :rules="plate ? 'required' : ''">
                        <b-form-group>
                          <label for="version"
                            >{{ $t('Version') }}
                            <small
                              class="text-danger"
                              v-if="plate"
                              >*</small
                            ></label
                          >
                          <b-form-select
                            v-model="version"
                            :options="versions"
                            @change="findCar()"
                            :disabled="
                              !brand || !year || !model
                            "></b-form-select>
                          <label
                            for="version"
                            class="text-danger"
                            v-if="errors.length > 0"
                            ><small>*</small>{{ errors[0] }}.</label
                          >
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>

                    <b-col
                      sm="12"
                      md="4"
                      lg="4">
                      <ValidationProvider
                        :name="$t('Car')"
                        #default="{ errors }"
                        :rules="plate ? 'required' : ''">
                        <b-form-group>
                          <label for="user_car_id"
                            >{{ $t('Car') }}
                            <small
                              class="text-danger"
                              v-if="plate"
                              >*</small
                            ></label
                          >
                          <b-form-select
                            v-model="user_car_id"
                            :options="user_cars"
                            :disabled="
                              !brand || !year || !model || !version
                            "></b-form-select>
                          <label
                            for="version"
                            class="text-danger"
                            v-if="errors.length > 0"
                            ><small>*</small>{{ errors[0] }}.</label
                          >
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                  </div>
                  <p class="page-title mt-3">{{ $t('Tire Information') }}</p>
                  <div class="row mt-3">
                    <b-col
                      sm="12"
                      md="4"
                      lg="4">
                      <b-form-group>
                        <label for="model">{{ $t('Brand') }}</label>
                        <b-form-select
                          v-model="tireBrand"
                          @change="getWidthsByBrand()"
                          :options="tire_brands"></b-form-select>
                      </b-form-group>
                    </b-col>

                    <b-col
                      sm="12"
                      md="4"
                      lg="4">
                      <b-form-group>
                        <label for="width">{{ $t('Width') }}</label>
                        <b-form-input
                          v-model="width"
                          class="input"
                          type="number"></b-form-input>
                      </b-form-group>
                    </b-col>

                    <b-col
                      sm="12"
                      md="4"
                      lg="4">
                      <b-form-group>
                        <label for="height">{{ $t('Height') }}</label>
                        <!-- <b-form-select v-model="height" :options="heights" :disabled="!width" @change="getRadiuses()"></b-form-select> -->
                        <b-form-input
                          v-model="height"
                          class="input"
                          type="number"></b-form-input>
                      </b-form-group>
                    </b-col>

                    <b-col
                      sm="12"
                      md="4"
                      lg="4">
                      <b-form-group>
                        <label for="height">{{ $t('Radius') }}</label>
                        <b-form-input
                          v-model="radius"
                          class="input"
                          type="number"></b-form-input>
                      </b-form-group>
                    </b-col>
                  </div>
                  <div class="flex-center-space">
                    <div></div>
                    <div class="flex-center-space">
                      <b-button
                        @click="
                          carStep = false;
                          customerStep = false;
                          addressStep = true;
                        "
                        style="visibility: hidden"
                        >{{ $t('Before') }}
                      </b-button>
                      <b-button
                        class="step-btn"
                        @click="addCustomer()"
                        >{{ $t('Save') }}</b-button
                      >
                    </div>
                  </div>
                </div>
                <!--  -->
                <div
                  id="customer-step"
                  v-if="customerStep === true">
                  <div class="flex-start-space">
                    <div>
                      <p class="page-title">{{ $t('Add Customer') }}</p>
                      <p
                        class="page-txt"
                        style="visibility: hidden">
                        -
                      </p>
                    </div>
                    <div class="customer-type-selector-wrapper">
                      <b-form-select
                        v-model="selected"
                        :options="customer_types"></b-form-select>
                    </div>
                  </div>
                  <div class="row">
                    <b-col
                      sm="12"
                      md="6"
                      lg="6">
                      <ValidationProvider
                        :name="$t('Name')"
                        rules="required|min:1|max:60"
                        #default="{ errors }">
                        <b-form-group class="mr-6">
                          <label for="name"
                            >{{ $t('Name') }}
                            <small class="text-danger">*</small></label
                          >
                          <b-form-input
                            v-model="name"
                            required></b-form-input>
                          <label
                            for="name"
                            v-if="errors.length > 0"
                            class="text-danger"
                            ><small>*</small>{{ errors[0] }}.</label
                          >
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>

                    <b-col
                      sm="12"
                      md="6"
                      lg="6">
                      <ValidationProvider
                        :name="$t('Surname')"
                        rules="required|min:1|max:30"
                        #default="{ errors }">
                        <b-form-group class="mr-6">
                          <label for="surname"
                            >{{ $t('Surname') }}
                            <small class="text-danger">*</small></label
                          >
                          <b-form-input
                            v-model="surname"
                            required></b-form-input>
                          <label
                            for="surname"
                            v-if="errors.length > 0"
                            class="text-danger"
                            ><small>*</small>{{ errors[0] }}.</label
                          >
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>

                    <b-col
                      sm="12"
                      md="6"
                      lg="6">
                      <ValidationProvider
                        name="GSM"
                        rules="required|max:40"
                        #default="{ errors }">
                        <b-form-group class="mr-6">
                          <label for="phone"
                            >GSM <small class="text-danger">*</small></label
                          >
                          <b-input-group>
                            <!-- <b-form-input v-model="phone" @input="address_phone = phone" class="input" v-mask="'+90 (###) ### ## ##'"></b-form-input> -->
                            <b-form-tags
                              v-model="phones"
                              :limit="6"
                              no-outer-focus
                              class="mb-2"
                              id="form-tags">
                              <template
                                v-slot="{
                                  tags,
                                  inputAttrs,
                                  inputHandlers,
                                  tagVariant,
                                  addTag,
                                  removeTag
                                }">
                                <b-input-group class="mb-2">
                                  <b-form-input
                                    v-bind="inputAttrs"
                                    v-on="inputHandlers"
                                    :placeholder="$t('Enter for add new phone')"
                                    class="form-control"
                                    v-mask="'+90 (###) ### ## ##'"
                                    style="border-right: none;"
                                    @blur="addTag()"
                                    ref="phone"></b-form-input>
                                  <b-input-group-append>

                                    <div class="add-number-area">
                                      <div class="cursor-pointer add-number" @click="addTag(); findCustomer('phone',$refs.phone.value);">
                                        <i class="fa-regular fa-plus"></i>
                                        {{$t('Add Number')}}
                                      </div>
                                    </div>
<!--                                    <b-button-->
<!--                                      @click="-->
<!--                                        addTag();-->
<!--                                        findCustomer(-->
<!--                                          'phone',-->
<!--                                          $refs.phone.value-->
<!--                                        );-->
<!--                                      "-->
<!--                                      variant="primary"-->
<!--                                      style="width: 30px !important">-->
<!--                                      <i class="fa-regular fa-plus"></i>-->
<!--                                    </b-button>-->
                                  </b-input-group-append>
                                </b-input-group>

                                <div class="row" style="padding: 0 12px;">
                                  <div class="tag-item" v-for="tag in tags" :key="tag">
                                      {{tag}}
                                      <i class="fa-solid fa-xmark cursor-pointer" @click="removeTag(tag)"></i>
                                  </div>
                                </div>
<!--                                <div-->
<!--                                  class="d-inline-block"-->
<!--                                  style="font-size: 1.5rem">-->
<!--                                  <b-form-tag-->
<!--                                    v-for="tag in 3"-->
<!--                                    @remove="removeTag(tag)"-->
<!--                                    :key="tag"-->
<!--                                    :title="tag"-->
<!--                                    :variant="tagVariant"-->
<!--                                    class="mr-1"-->
<!--                                    >{{ tag }}-->
<!--                                  </b-form-tag>-->
<!--                                </div>-->
                              </template>
                            </b-form-tags>
                          </b-input-group>
                          <label
                            for="phone"
                            v-if="errors.length > 0"
                            class="text-danger"
                            ><small>*</small>{{ errors[0] }}.</label
                          >
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col
                      sm="12"
                      md="6"
                      lg="6"
                      v-if="second_number">
                      <ValidationProvider
                        name="GSM 2"
                        rules="required|max:40"
                        #default="{ errors }">
                        <b-form-group class="mr-6">
                          <label for="phone2">GSM 2</label>
                          <b-input-group>
                            <b-form-input
                              v-model="phone2"
                              class="input"
                              v-mask="'+90 (###) ### ## ##'"></b-form-input>
                            <b-input-group-append>
                              <button
                                class="add-more-number-button"
                                @click="third_number = true">
                                <span>
                                  <i class="fa-solid fa-plus mr-6"></i>
                                  {{ $t('Add Number') }}
                                </span>
                              </button>
                            </b-input-group-append>
                          </b-input-group>
                          <label
                            for="phone2"
                            v-if="errors.length > 0"
                            class="text-danger"
                            ><small>*</small>{{ errors[0] }}.</label
                          >
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col
                      sm="12"
                      md="6"
                      lg="6"
                      v-if="third_number === true">
                      <ValidationProvider
                        name="GSM 3"
                        rules="required|max:40"
                        #default="{ errors }">
                        <b-form-group>
                          <label for="phone3">GSM 3</label>
                          <b-input-group>
                            <b-form-input
                              v-model="phone3"
                              v-mask="'+90 (###) ### ## ##'"></b-form-input>
                          </b-input-group>
                          <label
                            for="phone3"
                            v-if="errors.length > 0"
                            class="text-danger"
                            ><small>*</small>{{ errors[0] }}.</label
                          >
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>

                    <b-col
                      sm="12"
                      md="6"
                      lg="6">
                      <ValidationProvider
                        :name="$t('E-Mail')"
                        rules="required|email|max:40"
                        #default="{ errors }">
                        <b-form-group class="mr-6">
                          <label for="email"
                            >{{ $t('E-Mail') }}
                            <small class="text-danger">*</small></label
                          >
                          <b-form-input
                            v-model="email"
                            required
                            @blur.native="
                              findCustomer('email', email)
                            "></b-form-input>
                          <label
                            for="email"
                            v-if="errors.length > 0"
                            class="text-danger"
                            ><small>*</small>{{ errors[0] }}.</label
                          >
                          <label
                            for="email"
                            v-if="show_user_already_find"
                            class="text-danger"
                            ><small>*</small
                            >{{ $t('This e-mail already registered.') }}</label
                          >
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>

                    <b-col
                      sm="12"
                      md="6"
                      lg="6"
                      v-if="selected === 2">
                      <ValidationProvider
                        :name="$t('Job Description')"
                        rules="max:120"
                        #default="{ errors }">
                        <b-form-group class="mr-6">
                          <label for="job_title">{{
                            $t('Job Description')
                          }}</label>
                          <b-form-input v-model="job_title"></b-form-input>
                          <label
                            for="job_title"
                            v-if="errors.length > 0"
                            class="text-danger"
                            ><small>*</small>{{ errors[0] }}.</label
                          >
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>

                    <b-col
                      sm="12"
                      md="6"
                      lg="6"
                      v-if="selected === 2 || selected === 3 || selected === 4">
                      <ValidationProvider
                        :name="$t('Company')"
                        rules="required|max:120"
                        #default="{ errors }">
                        <b-form-group class="mr-6">
                          <label for="company_title"
                            >{{ $t('Company') }}
                            <small class="text-danger">*</small></label
                          >
                          <b-form-input v-model="company_title"></b-form-input>
                          <label
                            for="company_title"
                            v-if="errors.length > 0"
                            class="text-danger"
                            ><small>*</small>{{ errors[0] }}.</label
                          >
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <b-col
                      sm="12"
                      md="6"
                      lg="6"
                      v-if="selected === 3 || selected === 4"></b-col>

                    <b-col
                      sm="12"
                      md="6"
                      lg="6"
                      v-if="selected === 2 || selected === 3 || selected === 4">
                      <ValidationProvider
                        :name="$t('Tax Administration')"
                        rules="required|max:60"
                        #default="{ errors }">
                        <b-form-group class="mr-6">
                          <label for="tax_office"
                            >{{ $t('Tax Administration') }}
                            <small class="text-danger">*</small></label
                          >
                          <b-form-input v-model="tax_office"></b-form-input>
                          <label
                            for="tax_office"
                            v-if="errors.length > 0"
                            class="text-danger"
                            ><small>*</small>{{ errors[0] }}.</label
                          >
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>

                    <b-col
                      sm="12"
                      md="6"
                      lg="6"
                      v-if="selected === 2 || selected === 3 || selected === 4">
                      <ValidationProvider
                        :name="$t('Tax Number')"
                        rules="required|max:20"
                        #default="{ errors }">
                        <b-form-group class="mr-6">
                          <label for="tax_number"
                            >{{ $t('Tax Number') }}
                            <small class="text-danger">*</small></label
                          >
                          <b-form-input
                            type="number"
                            v-model="tax_number"></b-form-input>
                          <label
                            for="tax_number"
                            v-if="errors.length > 0"
                            class="text-danger"
                            ><small>*</small>{{ errors[0] }}.</label
                          >
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>

                    <b-col
                      sm="12"
                      class="mt-0">
                      <div class="contracts">
                        <b-form-checkbox
                          id="checkbox-1"
                          name="checkbox-1"
                          :value="true"
                          :unchecked-value="false"
                          switch />
                        <span>{{
                          $t(
                            'I want to receive campaign and information notifications.'
                          )
                        }}</span>
                      </div>
                    </b-col>
                  </div>

                  <b-overlay
                    :show="button_loader"
                    rounded="sm">
                    <b-button
                      class="step-btn hover s-button"
                      @click="controlCustomer()">
                      {{ $t('Continue') }}
                    </b-button>
                  </b-overlay>
                </div>
                <!--  -->
                <div
                  id="customer-step"
                  v-if="addressStep === true">
                  <div class="car-add-row">
                    <div>
                      <p class="page-title">{{ $t('Add Address') }}</p>
                    </div>
                  </div>
                  <div class="row mt-5">
                    <b-col
                      sm="12"
                      md="6"
                      lg="6">
                      <b-form-group>
                        <label for="address_type"
                          >{{ $t('Address Type')
                          }}<small
                            class="text-danger"
                            v-if="address_type"
                            >*</small
                          ></label
                        >
                        <b-form-select
                          v-model="address_type"
                          :options="address_types"></b-form-select>
                      </b-form-group>
                    </b-col>

                    <b-col
                      sm="12"
                      md="6"
                      lg="6">
                      <ValidationProvider
                        :name="$t('Phone')"
                        rules="max:20"
                        #default="{ errors }"
                        :rules="city ? 'required' : ''">
                        <b-form-group>
                          <label for="address_phone"
                            >{{ $t('Phone') }}
                            <small
                              class="text-danger"
                              v-if="city"
                              >*</small
                            ></label
                          >
                          <b-form-input
                            v-model="address_phone"
                            :v-mask="
                              address_phone.length === 0
                                ? null
                                : '+90 (###) ### ## ##'
                            "></b-form-input>
                          <label
                            for="address_phone"
                            v-if="errors.length > 0"
                            class="text-danger"
                            ><small>*</small>{{ errors[0] }}.</label
                          >
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>

                    <b-col
                      sm="12"
                      md="6"
                      lg="6"
                      v-if="address_type !== 1 && address_type !== null">
                      <ValidationProvider
                        :name="$t('Company')"
                        rules="required|max:120"
                        #default="{ errors }">
                        <b-form-group class="mr-6">
                          <label for="company_title"
                            >{{ $t('Company') }}
                            <small class="text-danger">*</small></label
                          >
                          <b-form-input
                            v-model="address_company_title"></b-form-input>
                          <label
                            for="company_title"
                            v-if="errors.length > 0"
                            class="text-danger"
                            ><small>*</small>{{ errors[0] }}.</label
                          >
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>

                    <b-col
                      sm="12"
                      md="6"
                      lg="6"
                      v-if="address_type !== 1 && address_type !== null">
                      <ValidationProvider
                        :name="$t('Tax Administration')"
                        rules="required|max:60"
                        #default="{ errors }">
                        <b-form-group class="mr-6">
                          <label for="tax_office"
                            >{{ $t('Tax Administration') }}
                            <small class="text-danger">*</small></label
                          >
                          <b-form-input
                            v-model="address_tax_office"></b-form-input>
                          <label
                            for="tax_office"
                            v-if="errors.length > 0"
                            class="text-danger"
                            ><small>*</small>{{ errors[0] }}.</label
                          >
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>

                    <b-col
                      sm="12"
                      md="6"
                      lg="6"
                      v-if="address_type !== 1 && address_type !== null">
                      <ValidationProvider
                        :name="$t('Tax Number')"
                        rules="required|max:20"
                        #default="{ errors }">
                        <b-form-group class="mr-6">
                          <label for="tax_number"
                            >{{ $t('Tax Number') }}
                            <small class="text-danger">*</small></label
                          >
                          <b-form-input
                            type="number"
                            v-model="address_tax_number"></b-form-input>
                          <label
                            for="tax_number"
                            v-if="errors.length > 0"
                            class="text-danger"
                            ><small>*</small>{{ errors[0] }}.</label
                          >
                        </b-form-group>
                      </ValidationProvider>
                    </b-col>

                    <b-col
                      sm="12"
                      md="6"
                      lg="6"
                      v-if="
                        address_type !== 1 && address_type !== null
                      "></b-col>

                    <b-col
                      sm="12"
                      md="4"
                      lg="4">
                      <b-form-group>
                        <label for="city">{{ $t('City') }}</label>
                        <b-form-select
                          v-model="city"
                          @change="getDistricts()"
                          :options="cities"></b-form-select>
                      </b-form-group>
                    </b-col>

                    <b-col
                      sm="12"
                      md="4"
                      lg="4">
                      <b-form-group>
                        <label for="district">{{ $t('District') }}</label>
                        <b-form-select
                          v-model="district"
                          :options="districts"
                          @change="getAreas()"
                          :disabled="!city"></b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col
                      sm="12"
                      md="4"
                      lg="4">
                      <b-form-group>
                        <label for="area">{{ $t('Neighbourhood') }}</label>
                        <b-form-select
                          v-model="area"
                          :options="areas"
                          :disabled="!city || !district"></b-form-select>
                      </b-form-group>
                    </b-col>
                  </div>
                  <b-form-group>
                    <label for="address">{{ $t('Address') }}</label>
                    <b-form-textarea
                      rows="3"
                      class="w-100"
                      style="max-width: 100%"
                      v-model="address"></b-form-textarea>
                  </b-form-group>
                  <div class="flex-center-space">
                    <div></div>
                    <div class="flex-center-space">
                      <b-button
                        @click="
                          carStep = true;
                          addressStep = false;
                        "
                        >{{ $t('Skip') }}</b-button
                      >
                      <b-button
                        @click="
                          carStep = true;
                          addressStep = false;
                          step_3 = true;
                        "
                        >{{ $t('Continue') }}</b-button
                      >
                    </div>
                  </div>
                </div>

                <!--  -->
                <b-modal
                  id="sms-verification-modal"
                  ref="sms-verification-modal"
                  size="l"
                  centered
                  hide-footer
                  hide-header>
                  <!-- <p class="customer-add-modal-title">SMS Doğrulama</p>-->
                  <div class="header">
                    <i
                      class="fa-regular fa-xmark"
                      @click="$refs['sms-verification-modal'].hide()"></i>
                  </div>
                  <div class="customer-add-modal-title text-success">
                    <i class="fa-regular fa-circle-check"></i>
                  </div>
                  <p class="customer-add-modal-text">
                    {{ $t('Customer successfully registered.') }}
                  </p>
                  <!--  <div class="flex-center-space mt-3 mb-4">-->
                  <!--  <input type="number" class="form-control" v-for="input in 6" :key="input.$index">-->
                  <!--  </div>-->
                  <!--  <button class="modal-foot-save">Gönder</button>-->
                  <button
                    class="modal-foot-save"
                    @click="$router.push('/customers')">
                    {{ $t('See Customer List') }}
                  </button>
                </b-modal>
              </div>
            </div>
          </ValidationObserver>
        </div>
        <div class="col-lg-4">
          <div
            class="product-detail-right-wrapper"
            id="customer-detail-right-wrapper">
            <div class="add-car-right-info-card">
              <div class="flex-center-space">
                <div class="add-car-right-info-card-head">
                  <p>{{ $t('User Informations') }}</p>
                  <button
                    @click="
                      customerStep = true;
                      addressStep = false;
                      carStep = false;
                    "
                    v-if="!customerStep">
                    {{ $t('Edit') }}
                  </button>
                </div>
              </div>
              <div v-if="selected === 1">
                <div class="flex-center-space">
                  <span>{{ $t('Name Surname') }}</span>
                  <p>{{ name }} {{ surname }}</p>
                </div>
                <div class="phones">
                  <span>{{ $t('Phone') }}</span>
                  <div>
                    <p
                      v-for="phone in phones"
                      :key="phone.$index">
                      {{ phone }}
                    </p>
                  </div>
                </div>
                <div class="flex-center-space">
                  <span>{{ $t('E-Mail') }}</span>
                  <p>{{ email }}</p>
                </div>
              </div>
              <div v-else>
                <div class="flex-center-space">
                  <span>{{ $t('Company') }}</span>
                  <p>{{ company_title }}</p>
                </div>
                <div class="flex-center-space">
                  <span>{{ $t('E-Mail') }}</span>
                  <p>{{ email }}</p>
                </div>
                <div class="flex-center-space">
                  <span>{{ $t('Tax Number') }}</span>
                  <p>{{ tax_number }}</p>
                </div>
              </div>
            </div>
          </div>
          <div
            class="customer-already-find"
            v-if="show_user_already_find">
            <div class="already-text">
              {{
                $t(
                  'A user was found registered to this phone. Would you like to use it?'
                )
              }}
            </div>
            <div
              class="use-btn"
              @click="$router.push(`/customer/${user_id}`)">
              {{ $t('Use') }}
            </div>
          </div>
          <div
            class="product-detail-right-wrapper mt-2"
            id="customer-detail-right-wrapper"
            v-if="step_1 && step_2">
            <div class="add-car-right-info-card">
              <div class="flex-center-space">
                <div class="add-car-right-info-card-head">
                  <p>{{ $t('Address Informations') }}</p>
                  <button
                    @click="
                      addressStep = true;
                      carStep = false;
                    "
                    v-if="!addressStep">
                    {{ $t('Edit') }}
                  </button>
                </div>
              </div>
              <div class="flex-center-space">
                <span>{{ $t('City/District') }}</span>
                <p v-if="city">
                  {{ city.name }}
                  <span
                    v-if="district"
                    class="font"
                    >/ {{ district.name }}</span
                  >
                </p>
              </div>
              <div class="flex-center-space">
                <span>{{ $t('Address') }}</span>
                <p class="text-end">{{ address }}</p>
              </div>
            </div>
          </div>
          <div
            class="product-detail-right-wrapper mt-2"
            id="customer-detail-right-wrapper"
            v-if="step_3">
            <div class="add-car-right-info-card">
              <div class="flex-center-space">
                <div class="add-car-right-info-card-head">
                  <p>{{ $t('Car Informations') }}</p>
                </div>
              </div>
              <div class="flex-center-space">
                <span>{{ $t('Plate') }}</span>
                <p>{{ plate }}</p>
              </div>
              <div class="flex-center-space">
                <span>{{ $t('Brand') }} / {{ $t('Model') }}</span>
                <p v-if="brand">
                  {{ brand.name }}
                  <span
                    v-if="model"
                    class="font"
                    >/ {{ model.car_model_name }}</span
                  >
                </p>
              </div>
              <div class="flex-center-space">
                <span>{{ $t('Tire Information') }}</span>
                <p>
                  {{ width }}
                  <span
                    v-if="height"
                    class="font"
                    >/ {{ height }}</span
                  ><span
                    v-if="radius"
                    class="font">
                    R{{ radius }}</span
                  >
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Default>
</template>

<script>
/* eslint-disable */
import PageBreadcrumbs from '../../components/PageBreadcrumbs.vue';
import StarRating from '../../components/StarRating.vue';
import Default from '@/layouts/default';

export default {
  components: {
    PageBreadcrumbs,
    StarRating,
    Default
  },
  mounted() {
    this.getCustomerTypes();
    this.getAddressTypes();
    this.getCities();
    this.getCarBrands();
    this.getTireBrands();
    this.getWidthsByBrand();
  },
  data() {
    return {
      breadcrumbs: [
        {
          name: this.$i18n.t('Retail'),
          path: '/customers'
        },
        {
          name: this.$i18n.t('Add New')
        }
      ],
      selected: 1,
      step_1: false,
      step_2: false,
      step_3: false,
      carStep: false,
      customerStep: true,
      addressStep: false,
      name: '',
      surname: '',
      phones: [],
      phone: '',
      phone2: '',
      phone3: '',
      email: '',
      city: null,
      address: '',
      plate: '',
      brand: null,
      model: null,
      version: null,
      year: null,
      tireBrand: null,
      width: null,
      height: null,
      radius: null,
      job_title: '',
      company_title: '',
      tax_office: '',
      tax_number: '',
      second_number: false,
      third_number: false,
      customer_types: [],
      cities: [],
      district: null,
      districts: [
        {
          value: null,
          text: this.$i18n.t('Choose')
        }
      ],
      area: null,
      areas: [
        {
          value: null,
          text: this.$i18n.t('Choose')
        }
      ],
      chassis_number: null,
      brands: [
        {
          value: null,
          text: this.$i18n.t('Choose')
        }
      ],
      models: [
        {
          value: null,
          text: this.$i18n.t('Choose')
        }
      ],
      years: [
        {
          value: null,
          text: this.$i18n.t('Choose')
        }
      ],
      versions: [
        {
          value: null,
          text: this.$i18n.t('Choose')
        }
      ],
      user_cars: [
        {
          value: null,
          text: this.$i18n.t('Choose')
        }
      ],
      user_car_id: null,
      tire_brands: [
        {
          value: null,
          text: this.$i18n.t('Choose')
        }
      ],
      widths: [
        {
          value: null,
          text: this.$i18n.t('Choose')
        }
      ],
      heights: [
        {
          value: null,
          text: this.$i18n.t('Choose')
        }
      ],
      radiuses: [
        {
          value: null,
          text: this.$i18n.t('Choose')
        }
      ],
      address_type: null,
      address_types: [
        {
          value: null,
          text: this.$i18n.t('Choose')
        }
      ],
      address_phone: '',
      total_mileage: null,
      registeredCustomers: [],
      button_loader: false,
      address_company_title: '',
      address_tax_office: '',
      address_tax_number: '',
      show_user_already_find: false,
      user_id: null,
      disable: true
    };
  },
  watch: {
    selected(from, to) {
      if (this.selected === 1) {
        this.address_type = this.selected;
      } else {
        this.address_type = 2;
      }
    },
    show_user_already_find() {
      if (this.show_user_already_find) {
        this.disable = true;
      } else {
        this.disable = false;
      }
    }
  },
  methods: {
    controlCustomer(){
      this.address_type = this.selected;
      this.address_phone = this.phones[0];
      this.address_company_title = this.company_title;
      this.address_tax_office = this.tax_office;
      this.address_tax_number = this.tax_number;
      this.step_1 = true;
      this.step_2 = true;
      this.customerStep = false;
      this.addressStep = true;
    },
    formatCarPlate() {
      const cleanedNumber = this.plate.toUpperCase();
      const formattedNumber = cleanedNumber.replace(
        /(\d{2})(\s?[A-Z]{1,3})(\d{3,4})/,
        '$1 $2 $3'
      );
      this.plate = formattedNumber;
    },
    findCustomer(type, data) {
      this.button_loader = true;
      var query = '';
      if (type === 'email') {
        query = 'email=' + data;
      } else {
        query = 'phone=' + data;
      }
      if (data) {
        this.$axios
          .get(`/customers/find-with-detail?${query}`)
          .then((response) => {
            if (response.data.status) {
              this.registeredCustomers = response.data.responseData;
              if (this.registeredCustomers.length > 0) {
                this.show_user_already_find = true;
                this.user_id = response.data.responseData[0].id;
                //this.$refs["registered-customer-modal"].show()
              } else {
                this.show_user_already_find = false;
              }
            }
          });
      }
      this.button_loader = false;
    },
    getCustomerTypes() {
      this.customer_types = [
        {
          value: null,
          text: this.$i18n.t('Choose'),
          disabled: true
        }
      ];
      this.$axios.get('/customer-types').then((response) => {
        if (response.data.status) {
          response.data.responseData.customerTypes.forEach((customer) => {
            this.customer_types.push({
              value: customer.id,
              text: customer.name
            });
          });
        }
      });
    },
    getCities() {
      this.$axios.get('/regions/cities').then((response) => {
        if (response.data.status) {
          this.cities = [
            {
              value: null,
              text: this.$i18n.t('Choose')
            }
          ];
          response.data.responseData.cities.forEach((city) => {
            this.cities.push({
              value: city,
              text: city.name
            });
          });
        }
      });
    },
    getDistricts() {
      if (this.city) {
        this.$axios
          .get(`/regions/districts`, {
            params: {
              city_id: this.city.id
            }
          })
          .then((response) => {
            this.districts = [
              {
                value: null,
                text: this.$i18n.t('Choose')
              }
            ];
            response.data.responseData.districts.forEach((district) => {
              this.districts.push({
                value: district,
                text: district.name
              });
            });
          });
      }
    },
    getAreas() {
      if (this.district) {
        this.$axios
          .get(`/regions/areas`, {
            params: {
              district_id: this.district.id
            }
          })
          .then((response) => {
            this.areas = [
              {
                value: null,
                text: this.$i18n.t('Choose')
              }
            ];
            response.data.responseData.areas.forEach((area) => {
              this.areas.push({
                value: area.id,
                text: area.name
              });
            });
          });
      }
    },
    getAddressTypes() {
      this.$axios.get('/invoice-types').then((response) => {
        if (response.data.status) {
          response.data.responseData.invoiceTypes.forEach((type) => {
            this.address_types.push({
              value: type.id,
              text: type.name
            });
          });
        }
      });
    },
    getCarBrands() {
      this.$axios.get('/cars/brands').then((response) => {
        this.brands = [
          {
            value: null,
            text: this.$i18n.t('Choose')
          }
        ];
        response.data.responseData.carBrands.forEach((brand) => {
          this.brands.push({
            value: brand,
            text: brand.name
          });
        });
      });
    },
    getCarYears() {
      if (this.brand) {
        this.$axios
          .get('/cars/years', {
            params: {
              car_brand_id: this.brand.car_brand_id
            }
          })
          .then((response) => {
            this.years = [
              {
                value: null,
                text: this.$i18n.t('Choose')
              }
            ];
            response.data.responseData.carYears.forEach((year) => {
              this.years.push({
                value: year,
                text: year
              });
            });
          });
      }
    },
    getCarModels() {
      this.$axios
        .get('/cars/models', {
          params: {
            car_brand_id: this.brand.car_brand_id,
            year: this.year
          }
        })
        .then((response) => {
          this.models = [
            {
              value: null,
              text: this.$i18n.t('Choose')
            }
          ];
          response.data.responseData.carModels.forEach((model) => {
            this.models.push({
              value: model,
              text: model.car_model_name
            });
          });
        });
    },
    getVersions() {
      this.$axios
        .get('/cars/versions', {
          params: {
            car_brand_id: this.brand.car_brand_id,
            year: this.year,
            car_model_id: this.model.car_model_id
          }
        })
        .then((response) => {
          this.versions = [
            {
              value: null,
              text: this.$i18n.t('Choose')
            }
          ];
          response.data.responseData.forEach((vs) => {
            this.versions.push({
              value: vs,
              text: vs.version_name_1 + ' ' + vs.version_name_2
            });
          });
        });
    },
    findCar() {
      if (this.version) {
        this.width = this.version.tire.width;
        this.height = this.version.tire.height;
        this.radius = this.version.tire.radius;
        this.$axios
          .get('/cars/find-car', {
            params: {
              car_brand_id: this.brand.car_brand_id,
              year: this.year,
              car_model_id: this.model.car_model_id,
              body_style: this.version.body_style,
              fuel_type: this.version.fuel_type,
              gear_type: this.version.gear_type,
              version_type: this.version.version_type
            }
          })
          .then((response) => {
            // auto fill the inputs according to index 0 of array
            /*
          if(response.status) {
            this.width = response.data.responseData.cars[0].width
            this.height = response.data.responseData.cars[0].height
            this.radius = response.data.responseData.cars[0].radius
          }
          */
            if (response.data.responseData.cars.length > 0) {
              response.data.responseData.cars.forEach((car) => {
                this.user_cars.push({
                  value: car.car_id,
                  text: car.car
                });
              });
              if (response.data.responseData.cars.length === 1) {
                this.user_car_id = response.data.responseData.cars[0].car_id;
              }
            } else {
              this.$swal.fire({
                iconHtml: '<img src="/img/warning.png" width="32" height="32">',
                position: 'top-end',
                title: this.$i18n.t(
                  'No vehicles found according to your selected filters.'
                ),
                showConfirmButton: false,
                timer: 2500,
                toast: true,
                customClass: {
                  icon: 'swal2-no-border'
                }
              });
            }
          });
      }
    },
    getTireBrands() {
      this.$axios.get('/tire/brands').then((response) => {
        this.tire_brands = [
          {
            value: null,
            text: this.$i18n.t('Choose')
          }
        ];
        response.data.responseData.brands.forEach((brand) => {
          this.tire_brands.push({
            value: brand,
            text: brand.name
          });
        });
      });
    },
    getWidthsByBrand() {
      let params = {
        brand_slugs: this.tireBrand ? this.tireBrand.slug : null
      };
      this.$axios
        .get('/tire/widths', {
          params: params
        })
        .then((response) => {
          this.widths = [
            {
              value: null,
              text: this.$i18n.t('Choose')
            }
          ];
          response.data.responseData.widths.forEach((width) => {
            this.widths.push({
              value: width,
              text: width
            });
          });
        });
    },
    getHeights() {
      if (this.width) {
        let params = {
          brand_slugs: this.tireBrand ? this.tireBrand.slug : null,
          width: this.width
        };
        this.$axios
          .get('/tire/heights', {
            params: params
          })
          .then((response) => {
            this.heights = [
              {
                value: null,
                text: this.$i18n.t('Choose')
              }
            ];
            response.data.responseData.heights.forEach((height) => {
              this.heights.push({
                value: height,
                text: height
              });
            });
          });
      }
    },
    getRadiuses() {
      if (this.height) {
        let params = {
          brand_slugs: this.tireBrand ? this.tireBrand.slug : null,
          width: this.width,
          height: this.height
        };
        this.$axios
          .get('/tire/radiuses', {
            params: params
          })
          .then((response) => {
            this.radiuses = [
              {
                value: null,
                text: this.$i18n.t('Choose')
              }
            ];
            response.data.responseData.radiuses.forEach((radius) => {
              this.radiuses.push({
                value: radius,
                text: radius
              });
            });
          });
      }
    },
    addCustomer() {
      var manipulatedPhones = [];
      for (let index = 0; index < this.phones.length; index++) {
        const element = this.phones[index];
        manipulatedPhones.push({
          phone: element,
          is_default: false
        });
      }
      manipulatedPhones[0].is_default = true;
      this.$refs.customerAddRules.validate().then((success) => {
        if (success) {
          let body = {
            customer: {
              name: this.name,
              surname: this.surname,
              email: this.email,
              job_title: this.job_title,
              company_title: this.company_title,
              tax_number: this.tax_number,
              tax_office: this.tax_office,
              customer_type_id: this.selected
            },
            phone: manipulatedPhones
          };
          if (this.address_type && this.city) {
            body.address = {
              is_active: true,
              is_default: true,
              invoice_type_id: this.address_type,
              city_id: this.city ? this.city.id : null,
              district_id: this.district ? this.district.id : null,
              area_id: this.area,
              address: this.address,
              phone: this.address_phone,
              // company_title: this.address_company_title,
              tax_number: this.address_tax_number,
              tax_office: this.address_tax_office
            };
          }
          if (this.user_car_id || this.plate) {
            body.car = {
              is_active: true,
              is_default: true,
              car_id: this.user_car_id,
              plate_number: this.plate,
              chassis_number: this.chassis_number,
              tire_brand_id: this.tireBrand ? this.tireBrand.id : null,
              width: this.width,
              height: this.height,
              radius: this.radius,
              year: this.year ? this.year.toString() : null,
              total_mileage: this.total_mileage
            };
          }
          this.$axios
            .post('/customers', body)
            .then((response) => {
              if (response.data.status) {
                this.$refs['sms-verification-modal'].show();
              }
            })
            .catch((error) => {
              this.$swal.fire({
                iconHtml: '<img src="/img/error.svg" width="32" height="32">',
                position: 'top-end',
                title: error.response.data.statusMessage,
                showConfirmButton: false,
                timer: 2500,
                toast: true,
                customClass: {
                  icon: 'swal2-no-border'
                }
              });
            });
        } else {
          this.$swal.fire({
            iconHtml: '<img src="/img/error.svg" width="32" height="32">',
            position: 'top-end',
            title: this.$i18n.t('Please fill the required fields.'),
            showConfirmButton: false,
            timer: 2500,
            toast: true,
            customClass: {
              icon: 'swal2-no-border'
            }
          });
        }
      });
    }
  }
};
</script>

<style scoped>
.tag-item{
  padding: 4px 8px;
  border-radius: 6px;
  font-size: 12px;
  background-color: #1C1C1C;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 2px .5rem 2px;
  white-space: nowrap;
  width: unset;
  gap: 1rem;
}
.tag-item .fa-xmark{
  color: #8f8f8f;
  font-size: 14px;
}
.add-number-area{
  background-color: #F4F4F4;
  border: 1px solid #dee2e6;
  border-left: none;
}
.add-number{
  background-color: #D3D3D399;
  border: 1px solid transparent;
  color: #6E6B7B;
  border-radius: 2px;
  margin: 4px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: .5rem;
  padding: 4px 8px;
}
.add-car-right-info-card .phones {
  display: flex;
  justify-content: space-between;
}

.car-adding-step-item {
  transition: 0.5s;
  cursor: pointer;
}

.car-adding-step-item .fa-car {
  background: rgba(186, 191, 199, 0.12);
  color: #b3b3b3;
  border: 1px solid #eaeaea;
}

.text-b3 {
  color: #b3b3b3;
}

.add-more-number-button span {
  margin: 4px 0;
  background: rgba(211, 211, 211, 0.6);
  padding: 8px;
  border-radius: 5px;
}

.font {
  color: #1c1c1c !important;
  font-weight: 500 !important;
}

.registered-customer-info {
  margin: 0;
}

#registered-customer-modal input {
  max-width: 45px;
  height: 50px;
  border: 1px solid #d8d6de;
  background: #fff;
}

#registered-customer-modal .modal-header {
  border: none;
  padding-bottom: 0;
}

#registered-customer-modal .modal-body {
  text-align: center;
  padding: 0px 48px 32px 48px;
}

#registered-customer-modal .customer-add-modal-title {
  font-weight: 500;
  font-size: 100px;
  color: #1c1c1c;
}

#registered-customer-modal .customer-add-modal-text {
  font-weight: 600;
  /*font-weight: 400;*/
  font-size: 24px;
  /*font-size: 13px;*/
  color: #1c1c1c;
}

.s-button {
  transition: 0.5s;
  width: 100%;
  max-width: 150px;
  background: #1c1c1c !important;
  color: #f4f4f4 !important;
  border: 1px solid #1c1c1c !important;
  outline: none;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.4px;
  padding: 8px 0px;
  margin-right: 12px;
}

.customer-already-find {
  margin-top: 1rem;
  background-color: rgba(211, 211, 211, 0.18);
  color: #d3d3d3;
  display: flex;
  justify-content: space-between;
  padding: 24px 16px;
  border-radius: 10px;
}

.already-text {
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  color: #1c1c1c;
}

.use-btn {
  display: flex;
  align-items: center;
  color: #777;
  font-size: 14px;
  text-decoration: underline;
  cursor: pointer;
  font-weight: 500;
}

.contracts {
  display: flex;
  align-items: center;
  font-size: 12px;
}

.custom-control {
  display: flex;
  align-items: flex-start;
}

.header {
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
}

.header .fa-xmark {
  font-size: 24px;
  cursor: pointer;
}
</style>

<style>
#registered-customer-modal {
  text-align: center;
}
</style>
