<template>
  <div
    style="padding: 0 16px; gap: 1rem"
    class="d-flex justify-content-between align-items-center mt-3">
    <div v-if="add_filter" class="no-visible-scroll">
      <div
        class="d-flex"
        style="gap: 1rem">
        <div
            class="count-select"
            v-if="$route.query.warehouse">
          <span class="d-flex align-items-center text-semi-dark"
          >
            {{$t('Warehouse')}}
            <span class="selected_count">{{ selected_warehouses.length }}</span>
          </span>
          <i
              class="fa-solid fa-xmark cursor-pointer"
              @click="resetWarehouse()"></i>
        </div>
        <div
          class="count-select"
          v-if="$route.query.brand">
          <span class="d-flex align-items-center text-semi-dark"
            >
            {{$t('Brand')}}
            <span class="selected_count">{{ selected_brands.length }}</span>
          </span>
          <i
            class="fa-solid fa-xmark cursor-pointer"
            @click="resetBrand()"></i>
        </div>

        <div
          class="count-select"
          v-if="$route.query.car_type">
          <span class="d-flex align-items-center text-semi-dark"
            >{{$t('Car Type')}}
            <span class="selected_count">{{ selected_car_brand.length }}</span>
          </span>
          <i
            class="fa-solid fa-xmark cursor-pointer"
            @click="resetCarType()"></i>
        </div>

        <div
          class="count-select"
          v-if="$route.query.season">
          <span class="d-flex align-items-center text-semi-dark"
            >{{$t('Season')}}<span class="selected_count">{{
              selected_season.length
            }}</span></span
          >
          <i
            class="fa-solid fa-xmark cursor-pointer"
            @click="resetSeason()"></i>
        </div>

        <div
          class="count-select"
          v-if="$route.query.category">
          <span class="d-flex align-items-center text-semi-dark"
            >{{$t('Product Family')}}<span class="selected_count">{{
              selected_category.length
            }}</span></span
          >
          <i
            class="fa-solid fa-xmark cursor-pointer"
            @click="resetCategory()"></i>
        </div>

        <div
          class="count-select"
          v-if="size">
          <span class="d-flex align-items-center text-semi-dark"
            >{{$t('Tire Size')}}<span class="selected_size_count">{{
              size
            }}</span></span
          >
          <i
            class="fa-solid fa-xmark cursor-pointer"
            @click="resetSize()"></i>
        </div>

        <div
          class="count-select"
          v-if="$route.query.year">
          <span class="d-flex align-items-center text-semi-dark"
            >DOT<span class="selected_count">{{
              selected_year.length
            }}</span></span
          >
          <i
            class="fa-solid fa-xmark cursor-pointer"
            @click="resetYear()"></i>
        </div>

        <div
          class="count-select"
          v-if="range">
          <span class="d-flex align-items-center text-semi-dark"
            >{{$t('Stock')}}<span class="selected_size_count">{{ range }}</span></span
          >
          <i
            class="fa-solid fa-xmark cursor-pointer"
            @click="resetStock()"></i>
        </div>

        <div
          class="count-select"
          v-if="show_price_range">
          <span class="d-flex align-items-center text-semi-dark"
            >{{$t('Sale Price')}}<span class="selected_size_count"
              >{{ min_price }}<{{ max_price }}</span
            ></span
          >
          <i
            class="fa-solid fa-xmark cursor-pointer"
            @click="resetPrice()"></i>
        </div>

        <div
          class="srvslt-select selected"
          v-if="show_main_select"
          v-click-outside="closeMain">
          <div class="select-list">
            <div class="search-input">
              <div class="select-control">
                <input
                  type="text"
                  class="select-input"
                  v-model="search_filter"
                  :placeholder="$t('Search')" />
                <i
                  class="fa-solid fa-magnifying-glass"
                  style="color: #6d6e6f"></i>
              </div>
            </div>
            <div class="select-div">
              <div
                class="list-content font-gray"
                v-for="item in options"
                @click="selectFilter(item)"
                :key="item.$index">
                <span class="overflow-text">{{ item.name }}</span>
                <i class="fas fa-chevron-down"></i>
              </div>
            </div>
          </div>
        </div>

        <div v-else>
          <!--        Marka-->
          <div
            class="srvslt-select selected"
            v-if="tab === 0">
            <div class="select-list">
              <div class="search-input">
                <div class="select-control">
                  <input
                    type="text"
                    class="select-input"
                    v-model="search_brand"
                    :placeholder="$t('Search')" />
                  <i
                    class="fa-solid fa-magnifying-glass"
                    style="color: #6d6e6f"></i>
                </div>
              </div>
              <div class="select-div-scroll">
                <div
                  class="list-content font-gray"
                  v-for="item in brands"
                  @click="selectBrand(item)"
                  :key="item.$index">
                  <span class="overflow-text">{{ item.name }}</span>
                  <i
                    class="fas fa-check font-servislet"
                    v-if="selected_brands.includes(item)"></i>
                </div>
              </div>

              <div class="apply">
                <button
                  class="apply-btn"
                  @click="setBrands()">
                  {{$t('Apply')}}
                </button>
              </div>
            </div>
          </div>

          <!--        Araç Tipi-->
          <div
            class="srvslt-select selected"
            v-if="tab === 1">
            <div class="select-list">
              <div class="search-input">
                <div class="select-control">
                  <input
                    type="text"
                    class="select-input"
                    v-model="search_car_brand"
                    :placeholder="$t('Search')" />
                  <i
                    class="fa-solid fa-magnifying-glass"
                    style="color: #6d6e6f"></i>
                </div>
              </div>
              <div class="select-div-scroll">
                <div
                  class="list-content font-gray"
                  v-for="item in car_brands"
                  @click="selectCarBrand(item)"
                  :key="item.$index">
                  <span class="overflow-text"
                    ><img
                      :src="item.icon"
                      :alt="item.name"
                      width="28"
                      class="mr-2" />{{ item.name }}</span
                  >
                  <i
                    class="fas fa-check font-servislet"
                    v-if="selected_car_brands.includes(item)"></i>
                </div>
              </div>

              <div class="apply">
                <button
                  class="apply-btn"
                  @click="setCarBrands()">
                  {{$t('Apply')}}
                </button>
              </div>
            </div>
          </div>

          <!--        Mevsim-->
          <div
            class="srvslt-select selected"
            v-if="tab === 2">
            <div class="select-list">
              <div class="search-input">
                <div class="select-control">
                  <input
                    type="text"
                    class="select-input"
                    v-model="search_season"
                    :placeholder="$t('Search')" />
                  <i
                    class="fa-solid fa-magnifying-glass"
                    style="color: #6d6e6f"></i>
                </div>
              </div>
              <div class="select-div-scroll">
                <div
                  class="list-content font-gray"
                  v-for="item in seasons"
                  @click="selectSeason(item)"
                  :key="item.$index">
                  <span class="overflow-text">
                    <img
                      src="/icons/sun.png"
                      :alt="item.name"
                      v-if="item.code === 'summer'"
                      width="20"
                      class="mr-2" />
                    <img
                      src="/icons/snow.png"
                      :alt="item.name"
                      v-else-if="item.code === 'winter'"
                      width="20"
                      class="mr-2" />
                    <img
                      src="/icons/all-season.png"
                      :alt="item.name"
                      v-else
                      width="20"
                      class="mr-2" />
                    {{ item.name }}</span
                  >
                  <i
                    class="fas fa-check font-servislet"
                    v-if="selected_seasons.includes(item)"></i>
                </div>
              </div>

              <div class="apply">
                <button
                  class="apply-btn"
                  @click="setSeasons()">
                  {{$t('Apply')}}
                </button>
              </div>
            </div>
          </div>

          <!--        Ürün Ailesi-->
          <div
            class="srvslt-select selected"
            v-if="tab === 3">
            <div class="select-list">
              <div class="search-input">
                <div class="select-control">
                  <input
                    type="text"
                    class="select-input"
                    v-model="search_category"
                    :placeholder="$t('Search')" />
                  <i
                    class="fa-solid fa-magnifying-glass"
                    style="color: #6d6e6f"></i>
                </div>
              </div>
              <div class="select-div-scroll">
                <div
                  class="list-content-category flex-column align-items-start font-gray"
                  v-for="(item, key) in categories.groups"
                  :key="item.$index">
                  <span
                    class="font-servislet-dark bold"
                    style="padding: 10px"
                    >{{ key }}</span
                  >
                  <div
                    class="d-flex justify-content-between w-100 list-content-category-item"
                    v-for="i in item"
                    :key="i.$index"
                    @click="selectCategory(i)">
                    <span class="overflow-text">{{ i.name }}</span>
                    <i
                      class="fas fa-check font-servislet"
                      v-if="selected_categories.includes(i)"></i>
                  </div>
                </div>
              </div>

              <div class="apply">
                <button
                  class="apply-btn"
                  @click="setCategories()">
                  {{$t('Apply')}}
                </button>
              </div>
            </div>
          </div>

          <!--        Lastik Ebatı-->
          <div
            class="srvslt-select selected"
            v-if="tab === 4">
            <div class="select-list-inner box-shadow">
              <div class="search-input">
                <div
                  class="select-control"
                  style="border-bottom: none">
                  {{$t('Tire Size')}}
                </div>
              </div>
              <div>
                <!--              Genişlik-->
                <div
                  class=""
                  style="margin: 0 10px"
                  v-click-outside="closeWidth">
                  <div
                    class="input input-inner"
                    :class="`${show ? 'select-open' : 'select-close'}`"
                    @click="show = !show">
                    <div
                      v-if="widths.length > 0"
                      class="text-overflow">
                      <span
                        class="select-content-dark"
                        v-if="selected_width">
                        {{$t('Width')}} <span class="font-servislet">({{ selected_width }})</span></span
                      >
                      <span
                        class="select-content"
                        :class="show ? 'font-servislet' : 'muted-text'"
                        v-else
                        >{{$t('Width')}}</span
                      >
                    </div>
                    <div v-else>
                      <span class="select-content-placeholder"
                        >{{$t('Loading')}}...</span
                      >
                    </div>
                    <div class="icons ml-3">
                      <i
                        class="fa-solid fa-xmark mr-1"
                        v-if="selected_width"></i>
                      <i
                        v-if="widths.length > 0"
                        :class="{
                          'fa-solid fa-chevron-down': !show,
                          'fa-solid fa-chevron-up': show,
                          'muted-text': !show && !selected_width,
                          'font-servislet': show && !selected_width
                        }" />
                    </div>
                  </div>
                  <div
                    class="select-list-inner open-border"
                    style="width: 180px; z-index: 5"
                    v-if="show">
                    <div>
                      <div class="select-control-inner">
                        <input
                          type="text"
                          class="select-input-inner search-bg"
                          v-model="search_width"
                          :placeholder="$t('Search')" />
                      </div>
                    </div>
                    <div class="select-div-inner">
                      <div
                        class="list-content"
                        v-for="item in widths"
                        :key="item.$index"
                        @click="selectWidth(item)"
                        v-if="widths.length > 0"
                        :class="
                          selected_width && selected_width === item
                            ? 'checked'
                            : ''
                        ">
                        <span class="text-overflow font-gray">{{ item }}</span>
                        <span>
                          <i
                            class="fa-solid fa-check"
                            v-if="selected_width === item"></i>
                        </span>
                      </div>
                      <div
                        class="list-content no-hover"
                        v-if="widths.length === 0">
                        <span>{{$t('No result.')}}..</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!--            Yükseklik-->
              <div
                class="mt-3"
                v-click-outside="closeHeight">
                <div
                  class=""
                  style="margin: 0 10px">
                  <div
                    class="input input-inner"
                    :class="`${show_height ? 'select-open' : 'select-close'}`"
                    @click="
                      heights.length > 0 ? (show_height = !show_height) : ''
                    ">
                    <div
                      v-if="heights.length > 0"
                      class="text-overflow">
                      <span
                        class="select-content-dark"
                        v-if="selected_height">
                        {{$t('Height')}} <span class="font-servislet">({{ selected_height }})</span></span
                      >
                      <span
                        class="select-content"
                        :class="show_height ? 'font-servislet' : 'muted-text'"
                        v-else
                        >{{$t('Height')}}</span
                      >
                    </div>
                    <div v-else>
                      <span class="select-content-placeholder">{{$t('Height')}}</span>
                    </div>
                    <div class="icons ml-3">
                      <i
                        class="fa-solid fa-xmark mr-1"
                        v-if="selected_height"></i>
                      <i
                        :class="{
                          'fa-solid fa-chevron-down': !show_height,
                          'fa-solid fa-chevron-up': show_height,
                          'muted-text': !show_height && !selected_height,
                          'font-servislet': show_height && !selected_height
                        }" />
                    </div>
                  </div>
                  <div
                    class="select-list-inner open-border"
                    style="width: 180px; z-index: 4"
                    v-if="show_height">
                    <div>
                      <div class="select-control-inner">
                        <input
                          type="text"
                          class="select-input-inner search-bg"
                          v-model="search_height"
                          :placeholder="$t('Search')" />
                      </div>
                    </div>
                    <div class="select-div-inner">
                      <div
                        class="list-content"
                        v-for="item in heights"
                        :key="item.$index"
                        @click="selectHeight(item)"
                        v-if="heights.length > 0"
                        :class="
                          selected_height && selected_height === item
                            ? 'checked'
                            : ''
                        ">
                        <span class="text-overflow font-gray">{{ item }}</span>
                        <span>
                          <i
                            class="fa-solid fa-check"
                            v-if="selected_height === item"></i>
                        </span>
                      </div>
                      <div
                        class="list-content no-hover"
                        v-if="heights.length === 0">
                        <span>{{$t('No result.')}}..</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!--                Jant Çapı-->
              <div
                class="mt-3"
                v-click-outside="closeRadius">
                <div
                  class=""
                  style="margin: 0 10px">
                  <div
                    class="input input-inner"
                    :class="`${show_radius ? 'select-open' : 'select-close'}`"
                    @click="
                      radiuses.length > 0 ? (show_radius = !show_radius) : ''
                    ">
                    <div
                      v-if="radiuses.length > 0"
                      class="text-overflow">
                      <span
                        class="select-content-dark"
                        v-if="selected_radius">
                        {{$t('Radius')}} <span class="font-servislet">({{selected_radius}})</span>
                      </span
                      >
                      <span
                        class="select-content"
                        :class="show_radius ? 'font-servislet' : 'muted-text'"
                        v-else
                        >{{$t('Radius')}}</span
                      >
                    </div>
                    <div v-else>
                      <span class="select-content-placeholder">{{$t('Radius')}}</span>
                    </div>
                    <div class="icons ml-3">
                      <i
                        class="fa-solid fa-xmark mr-1"
                        v-if="selected_radius"></i>
                      <i
                        :class="{
                          'fa-solid fa-chevron-down': !show_radius,
                          'fa-solid fa-chevron-up': show_radius,
                          'muted-text': !show_radius && !selected_radius,
                          'font-servislet': show_radius && !selected_radius
                        }" />
                    </div>
                  </div>
                  <div
                    class="select-list-inner open-border"
                    style="width: 180px"
                    v-if="show_radius">
                    <div>
                      <div class="select-control-inner">
                        <input
                          type="text"
                          class="select-input-inner search-bg"
                          v-model="selected_radius"
                          :placeholder="$t('Search')" />
                      </div>
                    </div>
                    <div class="select-div-inner">
                      <div
                        class="list-content"
                        v-for="item in radiuses"
                        :key="item.$index"
                        @click="selectRadius(item)"
                        v-if="radiuses.length > 0"
                        :class="
                          selected_radius && selected_radius === item
                            ? 'checked'
                            : ''
                        ">
                        <span class="text-overflow font-gray">{{ item }}</span>
                        <span>
                          <i
                            class="fa-solid fa-check"
                            v-if="selected_radius === item"></i>
                        </span>
                      </div>
                      <div
                        class="list-content no-hover"
                        v-if="radiuses.length === 0">
                        <span>{{$t('No result.')}}..</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="apply"
                style="background-color: #f4f4f4">
                <button
                  class="apply-btn"
                  @click="setSize()">
                  {{$t('Apply')}}
                </button>
              </div>
            </div>
          </div>

          <!--        DOT-->
          <div
            class="srvslt-select selected"
            v-if="tab === 5">
            <div class="select-list">
              <div class="search-input">
                <div class="select-control">
                  <input
                    type="text"
                    class="select-input"
                    v-model="search_year"
                    :placeholder="$t('Search')" />
                  <i
                    class="fa-solid fa-magnifying-glass"
                    style="color: #6d6e6f"></i>
                </div>
              </div>
              <div class="select-div-scroll">
                <div
                  class="list-content font-gray"
                  v-for="item in years"
                  @click="selectYears(item)"
                  :key="item.$index">
                  <span class="overflow-text">{{ item }}</span>
                  <i
                    class="fas fa-check font-servislet"
                    v-if="selected_years.includes(item)"></i>
                </div>
              </div>

              <div class="apply">
                <button
                  class="apply-btn"
                  @click="setYears()">
                  {{$t('Apply')}}
                </button>
              </div>
            </div>
          </div>

          <!--       Stok-->
          <div
            class="srvslt-select selected"
            style="min-width: 230px"
            v-if="tab === 6">
            <div class="select-list-inner box-shadow">
              <div class="search-input">
                <div
                  class="select-control"
                  style="border-bottom: none">
                  {{$t('Stock')}}
                </div>
              </div>
              <div class="search-input">
                <div
                  class="select-control"
                  style="border-bottom: none; gap: 0.25rem">
                  <b-input-group>
                    <b-form-input
                      type="number"
                      class="price-input"
                      v-model="min"
                      :placeholder="$t('At Least')" />
                    <template #append>
                      <b-input-group-text>
                        <i
                          class="fa-solid fa-circle-info text-danger"
                          v-if="parseInt(max) < parseInt(min)"
                          v-b-tooltip.hover.top :title="$t('No more than the maximum quantity.')"></i>
                      </b-input-group-text>
                    </template>
                  </b-input-group>
                  <span>-</span>

                  <b-input-group>
                    <b-form-input
                      type="number"
                      class="price-input"
                      v-model="max"
                      :placeholder="$t('At Most')" />
                    <template #append>
                      <b-input-group-text>
                        <i
                          class="fa-solid fa-circle-info text-danger"
                          v-if="parseInt(max) < parseInt(min)"
                          v-b-tooltip.hover.top :title="$t('It cannot be less than the minimum quantity.')"></i>
                      </b-input-group-text>
                    </template>
                  </b-input-group>
                </div>
              </div>
              <div
                style="padding: 0 10px"
                id="checkbox-1">
                <b-form-checkbox
                  v-model="show_no_stock"
                  name="checkbox-1"
                  :value="true"
                  :unchecked-value="false"
                  >{{$t('Show Products Out of Stock')}}
                </b-form-checkbox>
              </div>

              <div
                class="apply"
                style="background-color: #f4f4f4"
                v-if="show_no_stock">
                <button
                  class="apply-btn"
                  @click="setNoPrice()">
                  {{$t('Apply')}}
                </button>
              </div>

              <div
                class="apply"
                style="background-color: #f4f4f4"
                v-else>
                <button
                  class="apply-btn"
                  :disabled="parseInt(max) < parseInt(min)"
                  @click="setPrice()">
                  {{$t('Apply')}}
                </button>
              </div>
            </div>
          </div>

          <!--       Satış Fiyatı-->
          <div
            class="srvslt-select selected"
            v-if="tab === 7">
<!--            style="min-width: 260px"-->
            <div class="select-list-inner box-shadow">
              <div class="search-input">
                <div
                  class="select-control"
                  style="border-bottom: none">
                  {{$t('Sale Price Range')}}
                </div>
              </div>
              <div class="search-input">
                <div
                  class="select-control"
                  style="border-bottom: none; gap: 0.25rem">
                  <div class="price-input-group">
                    <input
                      v-money="money"
                      :class="min_price > max_price ? 'border-danger' : ''"
                      v-model="min_price"
                      :placeholder="$t('At Least')" />
                    <span>₺</span>
                  </div>
                  <span>-</span>
                  <div class="price-input-group">
                    <input
                      v-money="money"
                      :class="min_price > max_price ? 'border-danger' : ''"
                      v-model="max_price"
                      :placeholder="$t('At Most')" />
                    <span>₺</span>
                  </div>
                </div>
              </div>
              <div
                style="padding: 0 10px"
                id="radio-1">
                <div
                  class="radio-item"
                  :class="min_price == 0 && max_price == 1.4 ? 'font-servislet' : ''"
                  @click="
                    min_price = 0;
                    max_price = 1400;
                  ">
                  <i
                    class="fa-solid fa-circle-check font-servislet"
                    v-if="min_price == 0 && max_price == 1.4"></i>
                  <i
                    class="fa-regular fa-circle"
                    v-else></i>
                  0 - 1.400 TL
                </div>
                <div
                  class="radio-item mt-3"
                  :class="min_price == 1.4 && max_price == 7.0 ? 'font-servislet' : ''"
                  @click="
                    min_price = 1400;
                    max_price = 7000;
                  ">
                  <i
                    class="fa-solid fa-circle-check font-servislet"
                    v-if="min_price == 1.4 && max_price == 7.0"></i>
                  <i
                    class="fa-regular fa-circle"
                    v-else></i>
                  1.400 - 7.000 TL
                </div>
                <div
                  class="radio-item mt-3"
                  :class="min_price == 7.0 && max_price == 14.0 ? 'font-servislet' : ''"
                  @click="
                    min_price = 7000;
                    max_price = 14000;
                  ">
                  <i
                    class="fa-solid fa-circle-check font-servislet"
                    v-if="min_price == 7.0 && max_price == 14.0"></i>
                  <i
                    class="fa-regular fa-circle"
                    v-else></i>
                  7.000 - 14.000 TL
                </div>
                <div
                  class="radio-item mt-3"
                  :class="min_price == 14.0 && max_price == 26.0 ? 'font-servislet' : ''"
                  @click="
                    min_price = 14000;
                    max_price = 26000;
                  ">
                  <i
                    class="fa-solid fa-circle-check font-servislet"
                    v-if="min_price == 14.0 && max_price == 26.0"></i>
                  <i
                    class="fa-regular fa-circle"
                    v-else></i>
                  14.000 - 26.000 TL
                </div>
              </div>

              <div
                class="apply"
                style="background-color: #f4f4f4">
                <button
                  class="apply-btn"
                  @click="setRange()">
                  {{$t('Apply')}}
                </button>
              </div>
            </div>
          </div>

          <!--        Depo-->
          <div
              class="srvslt-select selected"
              v-if="tab === 8">
            <div class="select-list">
              <div class="search-input">
                <div class="select-control">
                  <input
                      type="text"
                      class="select-input"
                      v-model="search_warehouse"
                      :placeholder="$t('Search')" />
                  <i
                      class="fa-solid fa-magnifying-glass"
                      style="color: #6d6e6f"></i>
                </div>
              </div>
              <div class="select-div-scroll">
                <div
                    class="list-content font-gray"
                    v-for="item in warehouses"
                    @click="selectWarehouse(item)"
                    :key="item.$index">
                  <span class="overflow-text">{{ item.name }}</span>
                  <i
                      class="fas fa-check font-servislet"
                      v-if="selected_warehouses.includes(item)"></i>
                </div>
              </div>

              <div class="apply">
                <button
                    class="apply-btn"
                    @click="setWarehouses()">
                  {{$t('Apply')}}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="mt-2">
          <span
            class="add-filter-btn"
            @click="
              add_filter = true;
              add_plus = false;
              show_main_select = true;
            "
            ><i class="fa-solid fa-plus mr-2"></i> {{$t('Add Filter')}}
          </span>
        </div>
      </div>
    </div>

    <div
      @click="clearFilters()"
      class="font-servislet text-decoration-underline cursor-pointer clear-filter-text text-right"
      v-if="
        add_filter &&
        (selected_brand.length > 0 ||
          selected_car_brand.length > 0 ||
          selected_season.length > 0 ||
          selected_category.length > 0 ||
          size ||
          selected_year.length > 0 ||
          range ||
          show_price_range || selected_warehouse.length>0)
      ">
      {{$t('Clear Filters')}}
    </div>
  </div>
</template>

<script>
import vClickOutside from 'v-click-outside';

export default {
  name: 'Filters',
  directives: {
    clickOutside: vClickOutside.directive
  },
  data() {
    return {
      add_plus: true,
      option_list: [
        {
          key: 0,
          name: this.$i18n.t('Brand'),
          show: false
        },
        {
          key: 1,
          name: this.$i18n.t('Car Type'),
          show: false
        },
        {
          key: 2,
          name: this.$i18n.t('Season'),
          show: false
        },
        {
          key: 3,
          name: this.$i18n.t('Product Family'),
          show: false
        },
        {
          key: 4,
          name: this.$i18n.t('Tire Size'),
          show: false
        },
        {
          key: 5,
          name: 'DOT',
          show: false
        },
        {
          key: 6,
          name: this.$i18n.t('Stock'),
          show: false
        },
        {
          key: 7,
          name: this.$i18n.t('Sale Price'),
          show: false
        },
        {
          key: 8,
          name: this.$i18n.t('Warehouse'),
          show: false
        }
      ],
      search_filter: '',
      show_main_select: false,
      tab: -1,
      selected_brands: [], //seçilen markalar
      selected_brand: [], //marka sayısını gösterdiğimiz yer için
      search_brand: '',

      car_brand_list: [
        {
          icon: '/icons/search_car.svg',
          name: this.$i18n.t('Passenger')+', SUV, 4x4',
          code: 'passenger,suv,van'
        },
        {
          icon: '/icons/motorcycle.svg',
          name: this.$i18n.t('Motorcycle'),
          code: 'motorcycle'
        },
        {
          icon: '/icons/heavy.svg',
          name: this.$i18n.t('Truck'),
          code: 'truck'
        }
      ],
      selected_car_brands: [],
      selected_car_brand: [],
      search_car_brand: '',

      selected_seasons: [],
      selected_season: [],
      search_season: '',

      selected_categories: [],
      selected_category: [],
      search_category: '',

      selected_width: null,
      search_width: '',
      show: false,

      selected_height: null,
      search_height: '',
      show_height: false,

      selected_radius: null,
      search_radius: '',
      show_radius: false,

      size: null,
      search_year: '',
      selected_years: [],
      selected_year: [],
      min: null,
      max: null,
      show_no_stock: false,
      range: null,
      money: {
        thousands: '.',
        precision: 0,
        masked: false
      },
      min_price: 0,
      max_price: 0,
      show_price_range: false,

      selected_warehouses: [],
      selected_warehouse: [],
      search_warehouse: '',
    };
  },
  props: {
    add_filter: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    options() {
      return this.search_filter
        ? this.option_list.filter((item) =>
            item.name.toUpperCase().includes(this.search_filter.toUpperCase())
          )
        : this.option_list;
    },
    brands() {
      return this.search_brand
        ? this.$store.state.tireBrands.brands.filter((item) =>
            item.name.toUpperCase().includes(this.search_brand.toUpperCase())
          )
        : this.$store.state.tireBrands.brands;
    },
    car_brands() {
      return this.search_car_brand
        ? this.car_brand_list.filter((item) =>
            item.name
              .toUpperCase()
              .includes(this.search_car_brand.toUpperCase())
          )
        : this.car_brand_list;
    },
    seasons() {
      return this.search_season
        ? this.$store.state.tireSeason.seasons.filter((item) =>
            item.name.toUpperCase().includes(this.search_season.toUpperCase())
          )
        : this.$store.state.tireSeason.seasons;
    },
    categories() {
      if (this.search_category) {
        var ft = this.$store.state.category.categories.filter((item) =>
          item.name.toUpperCase().includes(this.search_category.toUpperCase())
        );
        var groups = Object.groupBy(ft, ({ brand_name }) => brand_name);
        return { groups };
      } else {
        var groups = Object.groupBy(
          this.$store.state.category.categories,
          ({ brand_name }) => brand_name
        );

        return { groups };
      }
    },
    widths() {
      return this.search_width
        ? this.$store.state.tireWidths.widths.filter((item) =>
            item
              .toString()
              .toUpperCase()
              .includes(this.search_width.toUpperCase())
          )
        : this.$store.state.tireWidths.widths;
    },
    heights() {
      return this.search_height
        ? this.$store.state.tireHeights.heights.filter((item) =>
            item
              .toString()
              .toUpperCase()
              .includes(this.search_height.toUpperCase())
          )
        : this.$store.state.tireHeights.heights;
    },
    radiuses() {
      return this.search_radius
        ? this.$store.state.tireRadiuses.radiuses.filter((item) =>
            item
              .toString()
              .toUpperCase()
              .includes(this.search_radius.toUpperCase())
          )
        : this.$store.state.tireRadiuses.radiuses;
    },
    warehouses() {
      return this.search_warehouse
        ? this.$store.state.warehouse.warehouses.filter((item) =>
            item.name.toString().toUpperCase().includes(this.search_warehouse.toUpperCase())
          )
        : this.$store.state.warehouse.warehouses;
    },
    years() {
      var list = [
        2024, 2023, 2022, 2021, 2020, 2019, 2018, 2017, 2016, 2015, 2014, 2013
      ];
      return this.search_year
        ? list.filter((item) =>
            item
              .toString()
              .toUpperCase()
              .includes(this.search_year.toUpperCase())
          )
        : list;
    }
  },
  mounted() {
    this.$store.dispatch('tireBrands/fetchTireBrands');
    this.$store.dispatch('tireSeason/fetchTireCategories');
    this.$store.dispatch('category/fetchTireCategories').then(() => {
      setTimeout(() => {
        this.setFilterByRoute();
      }, 2000);
    });
    this.$store.dispatch('tireWidths/fetchTireWidths');
    this.$store.dispatch('warehouse/fetchWarehouses',{
      is_active: 1
    })
  },
  methods: {
    closeMain() {
      this.show_main_select = false;
      this.add_plus = true;
    },
    setFilterByRoute() {
      if (this.$route.query.brand && this.brands.length > 0) {
        var brand = this.$route.query.brand.split(',');
        for (let k in brand) {
          this.selected_brands.push(
            this.brands.find((item) => item.slug === brand[k])
          );
        }
        this.selected_brand = this.selected_brands;
      }
      if (this.$route.query.category) {
        var category = this.$route.query.category.split(',');
        for (let k in category) {
          this.selected_categories.push(
            this.$store.state.category.categories.find(
              (item) => item.slug === category[k]
            )
          );
        }
        this.selected_category = this.selected_categories;
      }

      if (this.$route.query.car_type) {
        if (this.$route.query.car_type.includes('passenger,suv,van')) {
          this.selected_car_brands.push(this.car_brand_list[0]);
          var st = this.$route.query.car_type.replace('passenger,suv,van', '');
          var car_type = st.split(',');
          for (let k in car_type) {
            if (car_type[k]) {
              this.selected_car_brands.push(
                this.car_brand_list.find((item) => item.code === car_type[k])
              );
            }
          }
          this.selected_car_brand = this.selected_car_brands;
        } else {
          car_type = this.$route.query.car_type.split(',');
          for (let k in car_type) {
            this.selected_car_brands.push(
              this.car_brand_list.find((item) => item.code === car_type[k])
            );
          }
          this.selected_car_brand = this.selected_car_brands;
        }
      }

      if (this.$route.query.season) {
        var season = this.$route.query.season.split(',');
        for (let k in season) {
          this.selected_seasons.push(
            this.seasons.find((item) => item.code === season[k])
          );
        }
        this.selected_season = this.selected_seasons;
      }

      if (
        this.$route.query.width &&
        this.$route.query.height &&
        this.$route.query.radius
      ) {
        this.size =
          this.$route.query.width +
          '/' +
          this.$route.query.height +
          ' R' +
          this.$route.query.radius;
        this.selected_width = this.$route.query.width;
        this.$store.dispatch('tireHeights/fetchTireHeights', {
          width: this.selected_width
        });
        this.selected_height = this.$route.query.height;
        this.$store.dispatch('tireRadiuses/fetchTireRadiuses', {
          width: this.selected_width,
          height: this.selected_height
        });
        this.selected_radius = this.$route.query.radius;
      }

      if (this.$route.query.year) {
        var year = this.$route.query.year.split(',');
        for (let k in year) {
          this.selected_years.push(parseInt(year[k]));
        }
        this.selected_year = this.selected_years;
      }

      if (parseInt(this.$route.query.min)>=0 && parseInt(this.$route.query.max)>=0) {
        this.min = parseInt(this.$route.query.min);
        this.max = parseInt(this.$route.query.max);
        if(this.min === 0 && this.max === 0 ){
          this.range = '0'
        }else{
          this.range = this.min + '<' + this.max;
        }
      }

      if (this.$route.query.min_price && this.$route.query.max_price) {
        this.min_price = this.$route.query.min_price;
        this.max_price = this.$route.query.max_price;
        this.show_price_range = true;
      }

      if (this.$route.query.warehouse && this.warehouses.length > 0) {
        var warehouse = this.$route.query.warehouse.split(',');
        for (let k in warehouse) {
          this.selected_warehouses.push(
              this.warehouses.find((item) => item.id === parseInt(warehouse[k]))
          );
        }
        this.selected_warehouse = this.selected_warehouses;
      }
    },
    close() {
      this.add_filter = false;
      this.search_filter = '';
    },
    selectFilter(item) {
      this.tab = item.key;
      this.show_main_select = false;
    },
    selectBrand(item) {
      if (this.selected_brands.includes(item)) {
        this.selected_brands.splice(this.selected_brands.indexOf(item), 1);
      } else {
        this.selected_brands.push(item);
      }
    },
    selectWarehouse(item) {
      if (this.selected_warehouses.includes(item)) {
        this.selected_warehouses.splice(this.selected_warehouses.indexOf(item), 1);
      } else {
        this.selected_warehouses.push(item);
      }
    },
    setBrands() {
      this.tab = -1;
      this.selected_brand = this.selected_brands;
      this.add_plus = true;
      let brands = this.selected_brand
        .map(function (item) {
          return item.slug;
        })
        .join(',');

      var url = new URL(window.location.href);
      url.searchParams.set('brand', brands);

      if (this.selected_brand.length === 0) {
        this.$route.query.brand = '';
        url.searchParams.delete('brand');
      } else {
        this.$route.query.brand = brands;
      }
      url.search = decodeURIComponent(url.search);
      window.history.pushState({}, '', url);
      this.$emit('filterByQuery');
    },
    setWarehouses(){
      this.tab = -1;
      this.selected_warehouse = this.selected_warehouses;
      this.add_plus = true;
      let ws = this.selected_warehouse.map(function (item) {
        return item.id;
      }).join(',');

      var url = new URL(window.location.href);
      url.searchParams.set('warehouse', ws);

      if (this.selected_warehouse.length === 0) {
        this.$route.query.warehouse = '';
        url.searchParams.delete('warehouse');
      } else {
        this.$route.query.warehouse = ws;
      }
      url.search = decodeURIComponent(url.search);
      window.history.pushState({}, '', url);
      this.$emit('filterByQuery');
    },
    resetBrand() {
      this.selected_brands = [];
      this.selected_brand = [];
      var url = new URL(window.location.href);
      this.$route.query.brand = '';
      url.searchParams.delete('brand');

      url.search = decodeURIComponent(url.search);
      window.history.pushState({}, '', url);
      this.$emit('filterByQuery');
    },
    resetWarehouse(){
      this.selected_warehouses = [];
      this.selected_warehouse = [];
      var url = new URL(window.location.href);
      this.$route.query.warehouse = '';
      url.searchParams.delete('warehouse');

      url.search = decodeURIComponent(url.search);
      window.history.pushState({}, '', url);
      this.$emit('filterByQuery');
    },
    selectCarBrand(item) {
      if (this.selected_car_brands.includes(item)) {
        this.selected_car_brands.splice(
          this.selected_car_brands.indexOf(item),
          1
        );
      } else {
        this.selected_car_brands.push(item);
      }
    },
    setCarBrands() {
      this.tab = -1;
      this.selected_car_brand = this.selected_car_brands;
      this.add_plus = true;

      let car_brands = this.selected_car_brand
        .map(function (item) {
          return item.code;
        })
        .join(',');

      var url = new URL(window.location.href);
      url.searchParams.set('car_type', car_brands);

      if (this.selected_car_brand.length === 0) {
        this.$route.query.car_type = '';
        url.searchParams.delete('car_type');
      } else {
        this.$route.query.car_type = car_brands;
      }
      url.search = decodeURIComponent(url.search);
      window.history.pushState({}, '', url);
      this.$emit('filterByQuery');
    },
    resetCarType() {
      this.selected_car_brands = [];
      this.selected_car_brand = [];
      var url = new URL(window.location.href);
      this.$route.query.car_type = '';
      url.searchParams.delete('car_type');

      url.search = decodeURIComponent(url.search);
      window.history.pushState({}, '', url);
      this.$emit('filterByQuery');
    },
    selectSeason(item) {
      if (this.selected_seasons.includes(item)) {
        this.selected_seasons.splice(this.selected_seasons.indexOf(item), 1);
      } else {
        this.selected_seasons.push(item);
      }
    },
    setSeasons() {
      this.tab = -1;
      // this.show_main_select = true
      this.selected_season = this.selected_seasons;
      this.add_plus = true;
      // this.emitFilters()
      let seasons = this.selected_season
        .map(function (item) {
          return item.code;
        })
        .join(',');

      var url = new URL(window.location.href);
      url.searchParams.set('season', seasons);

      if (this.selected_season.length === 0) {
        this.$route.query.season = '';
        url.searchParams.delete('season');
      } else {
        this.$route.query.season = seasons;
      }
      url.search = decodeURIComponent(url.search);
      window.history.pushState({}, '', url);
      this.$emit('filterByQuery');
    },
    resetSeason() {
      this.selected_seasons = [];
      this.selected_season = [];
      var url = new URL(window.location.href);
      this.$route.query.season = '';
      url.searchParams.delete('season');

      url.search = decodeURIComponent(url.search);
      window.history.pushState({}, '', url);
      this.$emit('filterByQuery');
    },
    selectCategory(i) {
      if (this.selected_categories.includes(i)) {
        this.selected_categories.splice(this.selected_categories.indexOf(i), 1);
      } else {
        this.selected_categories.push(i);
      }
    },
    setCategories() {
      this.tab = -1;
      // this.show_main_select = true
      this.selected_category = this.selected_categories;
      this.add_plus = true;

      let cts = this.selected_category
        .map(function (item) {
          return item.slug;
        })
        .join(',');

      var url = new URL(window.location.href);
      url.searchParams.set('category', cts);

      if (this.selected_category.length === 0) {
        this.$route.query.category = '';
        url.searchParams.delete('category');
      } else {
        this.$route.query.category = cts;
      }
      url.search = decodeURIComponent(url.search);
      window.history.pushState({}, '', url);
      this.$emit('filterByQuery');
    },
    resetCategory() {
      this.selected_categories = [];
      this.selected_category = [];
      var url = new URL(window.location.href);
      this.$route.query.category = '';
      url.searchParams.delete('category');

      url.search = decodeURIComponent(url.search);
      window.history.pushState({}, '', url);
      this.$emit('filterByQuery');
    },
    selectWidth(item) {
      this.selected_width = item;
      this.show = false;
      this.$store
        .dispatch('tireHeights/fetchTireHeights', {
          width: this.selected_width
        })
        .then(() => {
          setTimeout(() => {
            this.show_height = true;
          }, 1000);
        });
    },
    selectHeight(item) {
      this.selected_height = item;
      this.show_height = false;
      this.$store
        .dispatch('tireRadiuses/fetchTireRadiuses', {
          width: this.selected_width,
          height: this.selected_height
        })
        .then(() => {
          setTimeout(() => {
            this.show_radius = true;
          }, 1000);
        });
    },
    selectRadius(item) {
      this.selected_radius = item;
      this.show_radius = false;
    },
    setSize() {
      if (this.selected_width && this.selected_height && this.selected_radius) {
        this.tab = -1;
        // this.show_main_select = true
        this.size =
          this.selected_width +
          '/' +
          this.selected_height +
          ' R' +
          this.selected_radius;
        this.add_plus = true;
        // this.emitFilters()
        var url = new URL(window.location.href);
        url.searchParams.set('width', this.selected_width);
        url.searchParams.set('height', this.selected_height);
        url.searchParams.set('radius', this.selected_radius);

        url.search = decodeURIComponent(url.search);
        window.history.pushState({}, '', url);
        this.$emit('filterByQuery');
      }
    },
    resetSize() {
      this.size = null;
      this.search_width = '';
      this.search_height = '';
      this.search_radius = '';
      this.selected_width = null;
      this.selected_height = null;
      this.selected_radius = null;
      var url = new URL(window.location.href);
      this.$route.query.width = '';
      this.$route.query.height = '';
      this.$route.query.radius = '';
      url.searchParams.delete('width');
      url.searchParams.delete('height');
      url.searchParams.delete('radius');

      url.search = decodeURIComponent(url.search);
      window.history.pushState({}, '', url);
      this.$emit('filterByQuery');
    },
    selectYears(item) {
      if (this.selected_years.includes(item)) {
        this.selected_years.splice(this.selected_years.indexOf(item), 1);
      } else {
        this.selected_years.push(item);
      }
    },
    setYears() {
      this.tab = -1;
      // this.show_main_select = true
      this.selected_year = this.selected_years;
      this.add_plus = true;
      // this.emitFilters()
      let yrs = this.selected_year
        .map(function (item) {
          return item;
        })
        .join(',');

      var url = new URL(window.location.href);
      url.searchParams.set('year', yrs);

      if (this.selected_year.length === 0) {
        this.$route.query.year = '';
        url.searchParams.delete('year');
      } else {
        this.$route.query.year = yrs;
      }
      url.search = decodeURIComponent(url.search);
      window.history.pushState({}, '', url);
      this.$emit('filterByQuery');
    },
    resetYear() {
      this.selected_year = [];
      this.selected_years = [];
      var url = new URL(window.location.href);
      this.$route.query.year = '';
      url.searchParams.delete('year');

      url.search = decodeURIComponent(url.search);
      window.history.pushState({}, '', url);
      this.$emit('filterByQuery');
    },
    setNoPrice() {
      this.tab = -1;
      // this.show_main_select = true
      this.range = '0'
      this.add_plus = true;
      // this.emitFilters()

      var url = new URL(window.location.href);
      url.searchParams.set('min', 0);
      url.searchParams.set('max', 0);


      this.$route.query.min = 0;
      this.$route.query.max = 0;


      url.search = decodeURIComponent(url.search);
      window.history.pushState({}, '', url);
      this.$emit('filterByQuery');
    },
    setPrice() {
      this.tab = -1;
      // this.show_main_select = true
      this.range = this.min + '<' + this.max;
      this.add_plus = true;
      // this.emitFilters()

      var url = new URL(window.location.href);
      url.searchParams.set('min', this.min);
      url.searchParams.set('max', this.max);

      if (!(this.min && this.max)) {
        url.searchParams.delete('min');
        url.searchParams.delete('max');
      } else {
        this.$route.query.min = this.min;
        this.$route.query.max = this.max;
      }

      url.search = decodeURIComponent(url.search);
      window.history.pushState({}, '', url);
      this.$emit('filterByQuery');
    },
    resetStock() {
      this.range = null;
      this.min = null;
      this.max = null;
      var url = new URL(window.location.href);
      this.$route.query.min = '';
      this.$route.query.max = '';
      url.searchParams.delete('min');
      url.searchParams.delete('max');

      url.search = decodeURIComponent(url.search);
      window.history.pushState({}, '', url);
      this.$emit('filterByQuery');
    },
    setRange() {
      this.show_price_range = true;
      this.tab = -1;
      // this.show_main_select = true
      this.add_plus = true;
      // this.emitFilters()

      var url = new URL(window.location.href);
      url.searchParams.set('min_price', this.min_price);
      url.searchParams.set('max_price', this.max_price);

      if (!(this.min_price && this.max_price)) {
        this.$route.query.min_price = '';
        this.$route.query.max_price = '';
        url.searchParams.delete('min_price');
        url.searchParams.delete('max_price');
      } else {
        this.$route.query.min_price = this.min_price;
        this.$route.query.max_price = this.max_price;
      }
      url.search = decodeURIComponent(url.search);
      window.history.pushState({}, '', url);
      this.$emit('filterByQuery');
    },
    resetPrice() {
      this.min_price = 0;
      this.max_price = 0;
      var url = new URL(window.location.href);
      this.$route.query.min_price = '';
      this.$route.query.max_price = '';
      url.searchParams.delete('min_price');
      url.searchParams.delete('max_price');

      url.search = decodeURIComponent(url.search);
      window.history.pushState({}, '', url);
      this.$emit('filterByQuery');
    },
    emitFilters() {
      var filters = {};

      if (this.selected_brands.length > 0) {
        filters.brand = this.selected_brands
          .map(function (item) {
            return item.slug;
          })
          .join(',');
      }

      if (this.selected_car_brands.length > 0) {
        filters.car_type = this.selected_car_brands
          .map(function (item) {
            return item.code;
          })
          .join(',');
      }

      if (this.selected_seasons.length > 0) {
        filters.season = this.selected_seasons
          .map(function (item) {
            return item.code;
          })
          .join(',');
      }

      if (this.selected_categories.length > 0) {
        filters.category = this.selected_categories
          .map(function (item) {
            return item.slug;
          })
          .join(',');
      }
      if (this.selected_width && this.selected_height && this.selected_radius) {
        filters.width = this.selected_width;
        filters.height = this.selected_height;
        filters.radius = this.selected_radius;
      }

      if (this.selected_years.length > 0) {
        filters.year = this.selected_years
          .map(function (item) {
            return item;
          })
          .join(',');
      }
      if (this.min) {
        filters.min = parseInt(this.min);
      }

      if (this.max) {
        filters.max = parseInt(this.max);
      }

      if (this.min_price) {
        filters.min_price = this.min_price;
      }

      if (this.max_price) {
        filters.max_price = this.max_price;
      }

      this.$emit('filterByQuery', filters);
    },
    closeWidth() {
      this.show = false;
    },
    closeHeight() {
      this.show_height = false;
    },
    closeRadius() {
      this.show_radius = false;
    },
    clearFilters() {
      this.add_plus = true;
      this.selected_brands = [];
      this.selected_brand = [];
      this.search_brand = '';


      this.selected_warehouses = [];
      this.selected_warehouse = [];
      this.search_warehouse = '';

      this.selected_car_brands = [];
      this.selected_car_brand = [];
      this.search_car_brand = '';

      this.selected_seasons = [];
      this.selected_season = [];
      this.search_season = '';

      this.selected_categories = [];
      this.selected_category = [];
      this.search_category = '';

      this.selected_width = null;
      this.search_width = '';
      this.show = false;

      this.selected_height = null;
      this.search_height = '';
      this.show_height = false;

      this.selected_radius = null;
      this.search_radius = '';
      this.show_radius = false;

      this.size = null;
      this.search_year = '';
      this.selected_years = [];
      this.selected_year = [];
      this.min = null;
      this.max = null;
      this.show_no_stock = false;
      this.range = null;
      this.min_price = 0;
      this.max_price = 0;
      this.show_price_range = false;

      var url = new URL(window.location.href);

      this.$route.query.min_price = '';
      this.$route.query.max_price = '';

      this.$route.query.min = '';
      this.$route.query.max = '';

      this.$route.query.brand = '';
      this.$route.query.warehouse = '';
      this.$route.query.car_type = '';

      this.$route.query.season = '';
      this.$route.query.category = '';
      this.$route.query.width = '';
      this.$route.query.height = '';
      this.$route.query.radius = '';
      this.$route.query.year = '';

      url.searchParams.delete('min_price');//
      url.searchParams.delete('max_price');//
      url.searchParams.delete('min');//
      url.searchParams.delete('max');//
      url.searchParams.delete('brand');//
      url.searchParams.delete('year');
      url.searchParams.delete('width');//
      url.searchParams.delete('height');//
      url.searchParams.delete('radius');//
      url.searchParams.delete('category');//
      url.searchParams.delete('season');//
      url.searchParams.delete('car_type');//
      url.searchParams.delete('warehouse');//

      url.search = decodeURIComponent(url.search);
      window.history.pushState({}, '', url);

      this.$emit('resetFilters')
    }
  }
};
</script>

<style scoped>
.no-visible-scroll {
  overflow-x: scroll;
  display: contents;
  max-width: 100%;
}

.no-visible-scroll::-webkit-scrollbar {
  display: none;
}

.text-semi-dark {
  color: #323338;
}

.add-filter-btn {
  background-color: transparent;
  cursor: pointer;
  color: #6d6e6f;
  display: flex;
  align-items: center;
  font-size: 14px;
  width: fit-content;
}

.srvslt-select {
  position: relative;
  width: 200px;
  z-index: 1;
}

.not-selected .input {
  border-radius: 5px;
  background: rgba(246, 246, 246, 0.8);
}

.input {
  background-color: #f4f4f4;
  padding: 15px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  position: relative;
  z-index: 2;
  height: 40px;
  width: 200px;
  font-size: 14px;
  border-radius: 5px;
}

.select-list {
  position: absolute;
  cursor: pointer;
  width: 100%;
  z-index: 3;
  max-height: 400px;
  border-radius: 5px;
  background: #f6f6f6;
  box-shadow: -2px 2px 10px 0 rgba(0, 0, 0, 0.2);
}

.select-list::-webkit-scrollbar {
  display: none !important;
}

.list-content {
  display: flex;
  align-items: center;
  padding: 10px;
  gap: 10px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  justify-content: space-between;
}

.list-content-category {
  display: flex;
  flex-direction: column;
  text-align: left;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
}

.list-content .no-option {
  font-size: 12px;
}

.select-content {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  font-size: 14px;
  align-items: center;
}

.icons {
  display: flex;
  gap: 5px;
}

.icons i {
  font-size: 12px;
}

.select-control {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(170, 170, 170, 0.8);
  margin: 0 10px 10px 10px;
  height: 40px;
}

.select-input {
  width: 100%;
  height: 35px;
  border: none;
  color: #777;
  font-size: 14px;
  border-radius: 0;
  background: #f4f4f4;
}

.list-content-category-item {
  padding: 10px;
}

.list-content-category-item:hover,
.list-content:hover {
  background: rgba(6, 165, 208, 0.12);
  color: var(--servislet-blue);
}

@media only screen and (max-width: 575px) {
  .srvslt-select {
    max-width: 100%;
  }
}

.checked {
  background: rgba(6, 165, 208, 0.12);
  color: var(--servislet-blue);
}

.apply {
  padding: 15px 10px 10px;
  border-radius: 0 0 5px 5px;
  background-color: var(--background-color-sidebar);
}

.apply-btn {
  border: 1px solid var(--servislet-blue);
  width: 100%;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background-color: var(--servislet-blue);
  color: var(--background-color-sidebar);
}

.apply-btn:hover {
  background-color: var(--servislet-blue);
  color: var(--background-color-sidebar);
}

.select-div {
  height: fit-content;
}

.focused-border {
  border: 1px solid #06a5d0;
  color: #06a5d0;
}

.not-focused-border {
  border: 1px solid rgba(119, 119, 119, 0.6);
}

.selected_count {
  background-color: rgba(6, 165, 208, 0.16);
  color: var(--servislet-blue);
  width: 10px;
  height: 10px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  margin-left: 0.5rem;
}

.selected_size_count {
  background-color: rgba(6, 165, 208, 0.16);
  color: var(--servislet-blue);
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  margin-left: 0.5rem;
  white-space: nowrap;
  height: 24px;
}

.search-input {
  font-size: 14px;
}

.select-input:focus,
.select-input-inner:focus {
  border: none !important;
}

.select-input::placeholder {
  color: rgba(119, 119, 119, 0.8) !important;
  font-size: 14px;
}

.overflow-text {
  overflow-x: scroll;
  white-space: nowrap;
}

.overflow-text::-webkit-scrollbar {
  display: none;
}

.select-div-scroll {
  max-height: 245px;
  overflow-y: scroll;
}

.select-div-scroll::-webkit-scrollbar {
  display: none;
}

.count-select {
  color: #8b8b8b;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 3rem;
  width: fit-content;
  border: 1px solid #6d6e6f66;
  border-radius: 5px;
  height: 40px;
  font-size: 14px;
  white-space: nowrap;
}

.select-list-inner {
  position: absolute;
  cursor: pointer;
  z-index: 3;
  max-height: 355px;
  border-radius: 5px;
  background: #f4f4f4;
  width: 100%;
}

.open-border {
  border: 1px solid #06a5d0;
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select-control-inner {
  height: 40px;
  margin: 0 10px;
}

.select-input-inner {
  width: 100%;
  height: 35px;
  color: #777;
  font-size: 14px;
  border-radius: 5px;
  border: none;
  background: #f4f4f4;
  padding-left: 0.5rem;
}

.select-div-inner {
  max-height: 245px;
  overflow-y: scroll;
}

.select-div-inner::-webkit-scrollbar {
  display: none;
}

.input.select-close {
  border: 1px solid #6d6e6f66;
  color: #777;
}

.input.select-open {
  border: 1px solid #06a5d0;
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.input-inner {
  width: 180px;
}

.price-input {
  width: 50%;
  height: 35px;
  color: #323338;
  font-size: 14px;
  border-radius: 5px;
  background: #f4f4f4 !important;
  padding-left: 0.5rem;
  border: 1px solid #6d6e6f66;
  border-right: none;
}

.price-input-group {
  height: 35px;
  border: 1px solid #6d6e6f66;
  color: #323338;
  font-size: 14px;
  border-radius: 5px;
  background: #f4f4f4 !important;
  padding: 0 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 48%;
}

.price-input-group input,
.price-input-group input:focus {
  background-color: #f4f4f4 !important;
  border: none;
  width: 100%;
}

.radio-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 14px;
  color: #323338;
}

.radio-item i {
  color: #6d6e6f66;
}

.clear-filter-text {
  font-size: 14px;
  font-weight: 500;
}
.input-group-append .input-group-text {
  height: 38px !important;
  background-color: #f4f4f4 !important;
  border: 1px solid #6d6e6f66;
  border-left: none;
  padding: 6px 8px;
  padding-left: 4px;
}
.b-tooltip {
  top: 54% !important;
}
.select-control .form-control:focus {
  border-right: none !important;
}
.border-danger {
  border-right: none;
}
.box-shadow{
  box-shadow: -2px 2px 10px 0 rgba(0, 0, 0, 0.2);
}
.search-bg{
  background-color: #6D6E6F1F !important;
  color: #777777E5 !important;
}
.no-hover:hover{
  background-color: #f4f4f4;
  color: #212529;
}
</style>

<style>
#checkbox-1 .custom-control-label {
  font-size: 14px !important;
}
</style>
