// store/carYears.js

import Vue from 'vue';
import Vuex from 'vuex';
// import axios from 'axios';
import axios from '@/plugins/axios';

// const TOKEN = localStorage.userData ? 'Bearer '+JSON.parse(localStorage.userData).access_token : ''
// const API_BASE_URL = 'https://staging-pro-api.servislet.com/api/v1/web/main'

Vue.use(Vuex);

const state = {
  models: []
};

const mutations = {
  setCarModelsData(state, data) {
    state.models = data;
  }
};

const actions = {
  fetchCarModels({ commit }, data) {
    axios
      .get(
        `/cars/models?car_brand_id=${data.car_brand_id}&year=${data.car_year}`,
        {}
      )
      .then((response) => {
        commit('setCarModelsData', response.data.responseData.carModels);
      })
      .catch((error) => {
        console.error(error);
      });
  }
};

const getters = {
  getCarModelsData(state) {
    return state.models;
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
