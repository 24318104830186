<template>
  <DefaultLayout>
    <div
      id="add-appointment-wrapper"
      v-if="!loading">
      <PageBreadcrumbs :breadcrumbs="breadcrumbs" />
      <div
        id="products-listing-table-wrapper"
        class="py-4">
        <div class="products-double-flex">
          <div class="products-double-flex-left">
            <p class="page-title">{{ $t('Edit in Calendar') }}</p>
            <p class="page-txt">Lorem ipsum dolor sit amet consectetur.</p>
          </div>
        </div>
        <div
          class="add-appointment-wrapper pt-2"
          id="calendar-wrapper">
          <ValidationObserver ref="customerAddRules">
            <div class="flex-center-space">
              <div
                class="products-double-flex p-0"
                v-if="selected_customer !== 1 && selected_customer !== 2">
                <div class="choose-product-container">
                  <div
                    class="choose-product"
                    v-if="Object.keys(customer.info).length > 0"
                    :class="[
                      { 'shadow-sm': customer.box },
                      { 'customer-div': Object.keys(customer.info).length > 0 }
                    ]">
                    <div
                      class="choose-product-label"
                      v-if="Object.keys(customer.info).length > 0">
                      <div class="customer-info">
                        <div class="customer-name">
                          <div class="customer-name-name">
                            <i
                              class="fa-regular fa-user"
                              style="color: #7367f0"></i>
                            <strong
                              >{{ customer.info.name }}
                              {{ customer.info.surname }}</strong
                            >
                          </div>
                          <div class="customer-name-info">
                            <span
                              ><i class="fa-regular fa-envelope"></i>
                              {{
                                customer.info.email ?? $t('Not defined')
                              }}</span
                            >
                            <span v-if="customer.info.phones"
                              ><i class="fa-regular fa-phone"></i>
                              {{
                                customer.info.phones.phone ?? $t('Not defined')
                              }}</span
                            >
                          </div>
                        </div>
                        <div
                          class="customer-name"
                          v-if="Object.keys(customer.car).length > 0"
                          style="
                            border-left: 1px solid #dee2e6;
                            margin-left: 25px;
                            padding-left: 35px;
                          ">
                          <div class="customer-name-name">
                            <i
                              class="fa-regular fa-car"
                              style="color: #7367f0"></i>
                            <strong>{{ customer.car.plate_number }}</strong>
                          </div>
                          <div class="customer-name-info">
                            <span
                              >{{ customer.car.brand }}
                              {{ customer.car.model }}
                              {{ customer.car.year }}</span
                            >
                            <span
                              >{{ customer.car.version }}
                              {{ customer.car.version_type }}</span
                            >
                          </div>
                        </div>
                        <i
                          class="fa fa-times ml-3"
                          @click="removeCustomer"></i>
                      </div>
                    </div>
                    <div
                      v-else
                      class="icon-with-name">
                      <span class="choose-product-label">{{
                        $t('Choose')
                      }}</span>
                      <i
                        class="fa-solid fa-circle-exclamation text-danger"
                        v-if="customer.errorMessage"></i>
                    </div>
                  </div>
                  <div
                    class="choose-product"
                    v-else
                    @click="openCustomerBox"
                    :class="[
                      { 'shadow-sm': customer.box },
                      { 'customer-div': Object.keys(customer.info).length > 0 }
                    ]">
                    <span
                      class="choose-product-label"
                      v-if="Object.keys(customer.info).length > 0">
                      <div class="customer-name">
                        <div class="customer-name-name">
                          <i
                            class="fa-regular fa-user"
                            style="color: #7367f0"></i>
                          <strong
                            >{{ customer.info.name }}
                            {{ customer.info.surname }}</strong
                          >
                        </div>
                        <div class="customer-name-info">
                          <span
                            ><i class="fa-regular fa-envelope"></i>
                            {{ customer.info.email ?? $t('Not defined') }}</span
                          >
                          <span
                            ><i class="fa-regular fa-phone"></i>
                            {{
                              customer.info.phones.phone ?? $t('Not defined')
                            }}</span
                          >
                        </div>
                      </div>
                    </span>
                    <div
                      v-else
                      class="icon-with-name">
                      <span class="choose-product-label">{{
                        $t('Choose Customer')
                      }}</span>
                      <i
                        class="fa-solid fa-circle-exclamation text-danger"
                        v-if="customer.errorMessage"></i>
                    </div>
                  </div>
                  <div
                    class="choose-product-list"
                    :class="customer.box ? 'shadow-sm' : ''"
                    v-show="customer.box">
                    <div class="choose-product-input-area">
                      <input
                        type="text"
                        v-model="customer.searchArea"
                        :id="`choose-customer-input`"
                        class="form-control m-3 choose-product-input"
                        @keyup="searchInputCustomer" />
                      <b-spinner
                        label="Spinning"
                        small
                        class="input-spinner"
                        v-if="customer.loading"></b-spinner>
                    </div>
                    <div
                      class="choose-product-list-item"
                      v-for="cust in customer.searchResult"
                      :key="cust.$index"
                      @click="setCustomerSearch(cust)">
                      <strong class="choose-product-list-item-name"
                        >{{ cust.name }} {{ cust.surname }}
                        <small style="font-size: 10px">{{
                          cust.customerType.name
                        }}</small></strong
                      >
                      <div class="choose-product-list-item-info">
                        <small style="font-size: 11px"
                          ><strong>{{ $t('E-Mail') }}:</strong> {{ cust.email }}
                        </small>
                        <small style="font-size: 11px"
                          ><strong>{{ $t('Phone') }}:</strong>
                          {{ cust.phones.phone }}
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
                <div></div>
              </div>
              <!--  -->
              <b-form-group class="visibility-hidden w-100">
                <b-form-input></b-form-input>
              </b-form-group>
              <b-form-group class="visibility-hidden w-100">
                <b-form-input></b-form-input>
              </b-form-group>
            </div>
          </ValidationObserver>
          <!-- customer add modal -->
          <b-modal
            id="customer-add-modal"
            size="xl"
            centered
            hide-footer>
            <template #modal-title>
              <div class="flex-start-space">
                <div>
                  <p class="customer-add-modal-title">
                    {{ $t('Add New Customer') }}
                  </p>
                  <p class="customer-add-modal-text">
                    Lorem ipsum dolor sit amet consectetur.
                  </p>
                </div>
                <div>
                  <b-form-group>
                    <b-form-select
                      v-model="selected"
                      :options="customers"></b-form-select>
                  </b-form-group>
                </div>
              </div>
            </template>
            <div class="flex-center-space">
              <b-form-group
                :label="$t('Name Surname') + ' *'"
                class="mr-6">
                <b-form-input :placeholder="$t('Name Surname')"></b-form-input>
              </b-form-group>
              <!--  -->
              <b-form-group :label="$t('Mobile') + ' *'">
                <b-input-group>
                  <b-form-input
                    :placeholder="$t('Mobile')"
                    v-mask="'+90 (###) ### ## ##'"></b-form-input>
                  <b-input-group-append>
                    <button
                      class="add-more-number-btn"
                      @click="second_number = true">
                      <i class="fa-solid fa-plus mr-6"></i>
                      {{ $t('Add Number') }}
                    </button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </div>
            <div
              class="flex-center-space"
              v-if="second_number === true">
              <b-form-group
                :label="$t('Mobile') + ' 2'"
                class="mr-6">
                <b-input-group>
                  <b-form-input
                    :placeholder="$t('Mobile') + ' 2'"
                    v-mask="'+90 (###) ### ## ##'"></b-form-input>
                  <b-input-group-append>
                    <button
                      class="add-more-number-btn"
                      @click="third_number = true">
                      <i class="fa-solid fa-plus mr-6"></i>
                      {{ $t('Add Number') }}
                    </button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>

              <b-form-group
                style="visibility: hidden"
                v-if="third_number === false">
                <b-form-input></b-form-input>
              </b-form-group>

              <b-form-group
                :label="$t('Mobile') + ' 3'"
                v-if="third_number === true">
                <b-input-group>
                  <b-form-input
                    :placeholder="$t('Mobile') + ' 3'"
                    v-mask="'+90 (###) ### ## ##'"></b-form-input>
                </b-input-group>
              </b-form-group>
            </div>
            <!--  -->
            <div class="flex-center-space">
              <b-form-group
                :label="$t('E-Mail') + ' *'"
                class="mr-6">
                <b-form-input :placeholder="$t('E-Mail')"></b-form-input>
              </b-form-group>
              <b-form-group :label="$t('Company Title') + ' *'">
                <b-form-input :placeholder="$t('Company Title')"></b-form-input>
              </b-form-group>
            </div>
            <div class="flex-center-space">
              <b-form-group
                :label="$t('Tax Administration') + ' *'"
                class="mr-6">
                <b-form-input
                  :placeholder="$t('Tax Administration')"></b-form-input>
              </b-form-group>
              <b-form-group :label="$t('Company Title') + ' *'">
                <b-form-input :placeholder="$t('Company Title')"></b-form-input>
              </b-form-group>
            </div>
            <p class="customer-add-modal-title">
              {{ $t('Address Informations') }}
            </p>
            <div class="flex-center-space">
              <b-form-group
                :label="$t('City')"
                class="mr-6">
                <b-form-select
                  v-model="selected"
                  :options="options"></b-form-select>
              </b-form-group>
              <b-form-group
                :label="$t('District')"
                class="mr-6">
                <b-form-select
                  v-model="selected"
                  :options="options"></b-form-select>
              </b-form-group>
              <b-form-group :label="$t('Neighborhood') + ' / ' + $t('Street')">
                <b-form-select
                  v-model="selected"
                  :options="options"></b-form-select>
              </b-form-group>
            </div>
            <div>
              <b-form-group :label="$t('Address Definition')">
                <b-form-textarea
                  :placeholder="$t('Address Definition')"
                  rows="3"
                  max-rows="6"></b-form-textarea>
              </b-form-group>
            </div>
            <div
              class="flex-center-space"
              id="modal-foot">
              <div></div>
              <div>
                <button
                  class="modal-foot-deny"
                  @click="hideCustomerModal">
                  {{ $t('Skip') }}
                </button>
                <button
                  class="modal-foot-save"
                  @click="showAlert">
                  {{ $t('Save') }}
                </button>
              </div>
            </div>
          </b-modal>
          <!-- end of customer add modal -->
          <div class="flex-start-space mt-3 mb-3">
            <b-form-group
              :label="$t('Start Date') + ' *'"
              class="w-100 pr-2">
              <ValidationProvider
                :name="$t('Start Date')"
                rules="required|max:20"
                #default="{ errors }">
                <b-form-input
                  type="date"
                  v-model="start_date"
                  required></b-form-input>
                <label
                  for="start_date"
                  v-if="errors.length > 0"
                  class="text-danger"
                  ><small>*</small>{{ errors[0] }}.</label
                >
              </ValidationProvider>
            </b-form-group>

            <b-form-group
              :label="$t('Start Hour') + ' *'"
              class="w-100 pr-2">
              <ValidationProvider
                :name="$t('Start Hour')"
                rules="required|max:20"
                #default="{ errors }">
                <b-form-timepicker
                  :labelNoTimeSelected="$t('The hour was not chosen.')"
                  :labelCloseButton="$t('Close')"
                  v-model="start_time"
                  locale="tr"></b-form-timepicker>
                <label
                  for="start_time"
                  v-if="errors.length > 0"
                  class="text-danger"
                  ><small>*</small>{{ errors[0] }}.</label
                >
              </ValidationProvider>
            </b-form-group>
            <b-form-group class="visibility-hidden w-100">
              <b-form-input></b-form-input>
            </b-form-group>
          </div>

          <div class="flex-start-space mt-3 mb-3">
            <b-form-group
              :label="$t('End Date') + ' *'"
              class="w-100">
              <ValidationProvider
                :name="$t('End Date')"
                rules="required|max:20"
                #default="{ errors }">
                <b-form-input
                  type="date"
                  v-model="end_date"
                  required></b-form-input>
                <label
                  for="end_date"
                  v-if="errors.length > 0"
                  class="text-danger"
                  ><small>*</small>{{ errors[0] }}.</label
                >
              </ValidationProvider>
            </b-form-group>

            <b-form-group
              :label="$t('End Hour') + ' *'"
              class="w-100 pl-2">
              <ValidationProvider
                :name="$t('End Hour')"
                rules="required|max:20"
                #default="{ errors }">
                <b-form-timepicker
                  :labelNoTimeSelected="$t('The hour was not chosen.')"
                  :labelCloseButton="$t('Close')"
                  v-model="end_time"
                  locale="tr"></b-form-timepicker>
                <label
                  for="end_time"
                  v-if="errors.length > 0"
                  class="text-danger"
                  ><small>*</small>{{ errors[0] }}.</label
                >
              </ValidationProvider>
            </b-form-group>

            <b-form-group class="visibility-hidden w-100">
              <b-form-input></b-form-input>
            </b-form-group>
          </div>
          <div>
            <b-form-group :label="$t('Title')">
              <ValidationProvider
                :name="$t('Title')"
                rules="required|max:20"
                #default="{ errors }">
                <b-form-input
                  type="text"
                  :placeholder="$t('Title')"
                  v-model="title"></b-form-input>
                <label
                  for="title"
                  v-if="errors.length > 0"
                  class="text-danger"
                  ><small>*</small>{{ errors[0] }}.</label
                >
              </ValidationProvider>
            </b-form-group>

            <b-form-group :label="$t('Note')">
              <b-form-textarea
                :placeholder="$t('Note')"
                v-model="description"
                rows="3"
                max-rows="6"></b-form-textarea>
            </b-form-group>
            <b-form-checkbox
              id="campaign-notification-checkbox"
              v-model="is_notify"
              switch>
              <span class="campaign-notification-checkbox-txt">{{
                $t('Be reminded of appointment information via SMS and E-Mail.')
              }}</span>
            </b-form-checkbox>
          </div>
          <div class="flex-center-space mt-4">
            <!-- <button class="empty-btn" @click="sale = true">
              <i class="fa-solid fa-plus"></i>
              Satış Ekle
            </button> -->
            <button
              class="servislet-black-btn"
              v-if="$route.query.code === 'offline'"
              @click="addAppointment()">
              {{ $t('Save') }}
            </button>
          </div>
          <div
            class="mt-4"
            id="add-sale-wrapper"
            v-if="sale === true">
            <p class="page-title">{{ $t('Add Sale') }}</p>
            <div
              class="mt-3"
              style="max-width: 565px">
              <div class="add-invoice-customer-card">
                <div class="add-invoice-customer-info">
                  <i class="fa-solid fa-database"></i>
                  <div>
                    <p>İsmail Abi</p>
                    <p>0541 850 60 70</p>
                    <p>Servislet Yazılım A.Ş</p>
                  </div>
                </div>
                <div class="add-invoice-vehicle-info">
                  <i class="fa-solid fa-car"></i>
                  <div>
                    <p>06 SZM 685</p>
                    <p>Ferrari 2022 F8 Spyder</p>
                  </div>
                </div>
                <div
                  class="remove-add-invoice-customer-card"
                  @click="selected_customer = null">
                  <i class="fa-solid fa-close"></i>
                </div>
              </div>
            </div>
            <div class="flex-center-space mt-3">
              <b-form-group
                id="product-or-service-selector-wrapper"
                :label="$t('Choose product or service')">
                <b-form-select
                  v-model="selected"
                  :options="options"></b-form-select>
              </b-form-group>

              <b-form-group
                id="dynamic-unit-group"
                class="add-invoice-flex-amount-wrapper"
                :label="$t('Quantity')">
                <b-input-group>
                  <b-form-input value="10"></b-form-input>
                  <b-input-group-append>
                    <b-form-select
                      v-model="selected"
                      :options="unit"></b-form-select>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>

              <b-form-group
                id="dynamic-unit-group"
                class="add-invoice-flex-price-wrapper"
                :label="$t('Amount')">
                <b-input-group>
                  <b-form-input value="100,00"></b-form-input>
                  <b-input-group-append>
                    <b-form-select
                      v-model="selected"
                      :options="currency"></b-form-select>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>

              <b-form-group
                id="dynamic-unit-group"
                class="add-invoice-flex-vat-wrapper"
                :label="$t('VAT')">
                <b-input-group>
                  <b-form-input value="10"></b-form-input>
                  <b-input-group-append>
                    <b-form-select
                      v-model="selected"
                      :options="currency"></b-form-select>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>

              <b-form-group
                id="dynamic-unit-group"
                class="add-invoice-flex-total-wrapper"
                :label="$t('Total')">
                <b-input-group>
                  <b-form-input value="120,00"></b-form-input>
                  <b-input-group-append>
                    <b-form-select
                      v-model="selected"
                      :options="currency"></b-form-select>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </div>
            <div>
              <b-form-group :label="$t('Note')">
                <b-form-textarea
                  :placeholder="$t('Note')"
                  rows="3"
                  max-rows="6"></b-form-textarea>
              </b-form-group>
              <button class="servislet-black-btn mt-2">
                <i class="fa-solid fa-plus"></i>
                {{ $t('Product / Service Add') }}
              </button>
            </div>
            <div class="products-double-flex invoice-bill-section-wrapper mt-5">
              <div></div>
              <div class="invoice-bill-section">
                <div class="invoice-bill-item p-0">
                  <p>{{ $t('Sub Total') }}:</p>
                  <p>₺1.800</p>
                </div>
                <div
                  class="invoice-bill-item p-0"
                  v-if="discount === true">
                  <p>{{ $t('Sub Total Discount') }}:</p>
                  <div class="flex-center-space">
                    <b-input-group style="max-width: 150px">
                      <b-form-input value="20,00"></b-form-input>
                      <b-input-group-append>
                        <b-form-select
                          v-model="selected"
                          :options="discount_unit"></b-form-select>
                      </b-input-group-append>
                    </b-input-group>
                    <button @click="discount = false">
                      <i class="fa-solid fa-close"></i>
                    </button>
                  </div>
                </div>
                <div class="invoice-bill-item p-0">
                  <p>{{ $t('VAT') }}:</p>
                  <p>₺1.000</p>
                </div>
                <div class="invoice-bill-item invoice-bill-item-total">
                  <p>{{ $t('Total') }}:</p>
                  <p>₺2.000</p>
                </div>
              </div>
            </div>

            <div>
              <b-form-group :label="$t('Not') + ':'">
                <b-form-textarea
                  rows="1"
                  max-rows="6"></b-form-textarea>
              </b-form-group>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="mt-5"
      v-else>
      <content-placeholders :rounded="true">
        <content-placeholders-img />
        <content-placeholders-text :lines="10" />
      </content-placeholders>
    </div>
  </DefaultLayout>
</template>

<script>
/* eslint-disable */
import DefaultLayout from '@/layouts/default';
import PageBreadcrumbs from '../../components/PageBreadcrumbs.vue';
import VueClickOutside from 'vue-click-outside';

export default {
  components: {
    PageBreadcrumbs,
    DefaultLayout
  },
  data() {
    return {
      breadcrumbs: [
        {
          name: 'Takvim',
          path: '/calendar'
        },
        {
          name: 'Düzenle'
        }
      ],
      selected: null,
      selected_customer: null,
      customers: [
        {
          value: null,
          text: this.$i18n.t('Choose Customer Information'),
          disabled: true
        },
        { value: 1, text: this.$i18n.t('Corporate Customer') + ' X' },
        { value: 2, text: this.$i18n.t('Individual Customer') + ' X' }
      ],
      options: [
        { value: null, text: this.$i18n.t('Completed'), disabled: true }
      ],
      sale: false,
      unit: [{ value: null, text: '| adet', disabled: true }],
      currency: [
        { value: null, text: '₺', disabled: true },
        ,
        { value: null, text: '$', disabled: true }
      ],
      second_number: false,
      third_number: false,
      customer: {
        box: false,
        searchArea: '',
        searchResult: [],
        car: {},
        info: {}
      },
      money: {
        decimal: ',',
        thousands: '.',
        prefix: '',
        suffix: '',
        precision: 2
      },
      title: '',
      description: '',
      is_notify: false,
      start_date: '',
      end_date: '',
      start_time: '',
      end_time: '',
      event: {},
      loading: true
    };
  },
  directives: {
    VueClickOutside
  },
  mounted() {
    this.getEventDetails();
  },
  methods: {
    reverseDateString(dateString) {
      const [day, month, year] = dateString.split('-');
      const reversedDateString = `${year}-${month}-${day}`;
      return reversedDateString;
    },
    getEventDetails() {
      this.loading = true;
      var url = '';
      if (this.$route.query.code === 'offline') {
        url = `/events/${this.$route.params.id}`;
      } else if (this.$route.query.code === 'servislet') {
        url = `/events/channel/${this.$route.params.id}`;
      }
      this.$axios
        .get(url)
        .then((response) => {
          this.event = response.data.responseData.event;
          if (this.$route.query.code === 'offline') {
            this.setCustomerSearch(this.event.customer);
          }
          // this.customer = this.event.customer
          this.title = this.event.title;
          this.description = this.event.description;
          this.is_notify = this.event.is_notify;
          this.start_date = this.reverseDateString(
            this.event.start_date.slice(0, -9)
          );
          this.end_date = this.reverseDateString(
            this.event.end_date.slice(0, -9)
          );
          this.start_time = this.event.start_date.substring(
            this.event.start_date.length - 8
          );
          this.end_time = this.event.end_date.substring(
            this.event.end_date.length - 8
          );
          this.loading = false;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    hideCustomerModal() {
      this.$bvModal.hide('customer-add-modal');
    },
    setCustomerSearch(customer) {
      this.$store
        .dispatch('sales/searchCustomerCar', customer.id)
        .then((response) => {
          if (response) {
            this.customer.car = response;
          }
          this.customer.info = customer;
          this.customer.box = false;
        });
    },
    openCustomerBox() {
      this.customer.box = !this.customer.box;
    },
    searchInputCustomer() {
      if (this.customer.searchArea === 0) {
        this.customer.searchResult = [];
      }
      if (this.customer.searchArea.length > 2) {
        this.customer.loading = true;
        this.$store
          .dispatch(`sales/searchCustomer`, this.customer.searchArea)
          .then((response) => {
            this.customer.searchResult = response;
            this.customer.loading = false;
          });
      }
    },
    showAlert() {
      this.$bvModal.hide('customer-add-modal');
      // Use sweetalert2
      this.$swal.fire({
        iconHtml: '<img src="/img/success.svg" width="32" height="32">',
        title: this.$i18n.t('Customer successfully added.'),
        text: '',
        showConfirmButton: false,
        timer: 1500,
        toast: true,
        customClass: {
          icon: 'swal2-no-border'
        }
      });
    },
    removeCustomer() {
      this.customer = {
        box: false,
        searchArea: '',
        searchResult: [],
        car: {},
        info: {}
      };
    },
    addAppointment() {
      var startTime = '';
      var endTime = '';
      if (this.start_time) {
        startTime = this.start_time;
      } else {
        startTime = '00:00:00';
      }
      if (this.end_time) {
        endTime = this.end_time;
      } else {
        endTime = '00:00:00';
      }
      var self = this;
      this.$axios
        .put('/events/' + this.$route.params.id, {
          customer_id: this.customer.info.id,
          // user_id: "ut",
          app_type_id: 1,
          // module_id: "dolorem",
          title: this.title,
          description: this.description,
          is_notify: this.is_notify,
          // latitude: "sequi",
          // longitude: "debitis",
          start_date: this.start_date + ' ' + startTime,
          end_date: this.end_date + ' ' + endTime
          // referer_id: "perspiciatis"
        })
        .then(function (response) {
          console.log(response);
          self.$swal.fire({
            iconHtml: '<img src="/img/success.svg" width="32" height="32">',
            position: 'top-end',
            title: this.$i18n.t('Date successfully saved') + '!',
            showConfirmButton: false,
            timer: 2500,
            toast: true,
            customClass: {
              icon: 'swal2-no-border'
            }
          });
          self.$router.push('/calendar');
        })
        .catch(function (error) {
          var message = error.response.data.responseData.error
            ? error.response.data.responseData.error[0].message
            : error.response.data.responseData.message;
          self.$swal.fire({
            iconHtml: '<img src="/img/error.svg" width="32" height="32">',
            position: 'top-end',
            title: message,
            showConfirmButton: false,
            timer: 2500,
            toast: true,
            customClass: {
              icon: 'swal2-no-border'
            }
          });
        });
    }
  }
};
</script>

<style>
#add-appointment-wrapper .b-form-timepicker {
  height: auto !important;
  align-items: center !important;
  max-height: 30px !important;
}
</style>

<style scoped>
.page-txt {
  visibility: hidden;
}

.choose-product-container {
  position: relative;
}

.choose-product {
  border: 1px solid #dee2e6;
  padding: 0.65rem 0.85rem !important;
  border-radius: 5px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 8px;
  background-color: #f4f4f4;
  cursor: pointer;
  min-width: 400px;
  max-width: 400px;
}

.choose-product-label {
  font-size: 14px;
  color: #6e6b7b !important;
  font-style: normal;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.icon-with-name {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.choose-product-list {
  position: absolute;
  top: 40px;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  border: 1px solid #dee2e6;
  background-color: #f4f4f4;
  border-top: none;
  z-index: 1;
  border-radius: 5px;
}

.choose-product-list-item {
  color: #6e6b7b !important;
  border-top: 1px solid #dee2e6;
  padding: 0.65rem 0.85rem;
  font-size: 14px;
  cursor: pointer;
}

.choose-product-list-item:hover {
  background-color: #dadada;
  cursor: pointer;
}

.choose-product-input {
  width: 92%;
}

.input-group-text {
  background-color: #f4f4f4;
  border-left: 1px solid #dee2e6 !important;
}

.choose-product-input-area {
  position: relative;
}

.input-spinner {
  position: absolute;
  right: 26px;
  top: 27px;
}

#services-lines {
  border-bottom: 1px solid #dee2e6;
  margin-bottom: 20px;
}

#services-lines .choose-product {
  max-width: 359px;
  min-width: 359px;
}

.isDesc {
  border-bottom: 1px solid #dee2e6 !important;
  width: 100%;
  max-width: 100% !important;
  margin-bottom: 20px;
}

.choose-product-list-item-info {
  display: flex;
  flex-direction: column;
  border-top: 1px solid #dee2e6;
  padding-top: 5px;
  margin-top: 5px;
}

.choose-product-list-item-name {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.customer-name {
  display: flex;
  gap: 5px;
  flex-direction: column;
}

.customer-div {
  height: auto;
  width: auto !important;
}

.customer-name-name {
  font-size: 20px;
}

.customer-name-info {
  font-size: 12px;
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.customer-info {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  position: relative;
}

.customer-div .choose-product-label {
  width: 1000%;
}

.choose-product.customer-div {
  max-width: 500px;
  min-width: 500px;
}

.discount-line {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.add-invoice-flex-total-wrapper {
  flex: 1;
}

.flex-start-space {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
</style>
