import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/DashboardView.vue';
// import DashboardView from '../views/DashboardView.vue'
import Products from '../views/products/index.vue';
import Products2 from '../views/products/index2.vue';
import ProductDetail from '../views/product/index.vue';
import ProductDetail2 from '../views/product/index2.vue';
import ProductAdd from '../views/product/add.vue';
import ProductAdd2 from '../views/product/add2.vue';
import Payments from '../views/payments/index.vue';
import Sales from '../views/sales/index.vue';
import Sale from '../views/sale/index.vue';
import AddSale from '../views/sale/add.vue';
import UpdateSale from '../views/sale/update.vue';
import Invoices from '../views/invoice/index.vue';
import InvoiceAdd from '../views/invoice/add.vue';
import Customers from '../views/customers/index.vue';
import Customer from '../views/customer/index.vue';
import CustomerAdd from '../views/customer/add.vue';
import Cars from '../views/cars/index.vue';
import CarDetail from '../views/cars/detail.vue';
import CarAdd from '../views/cars/add.vue';
import Calendar from '../views/calendar/index.vue';
import AddAppointment from '../views/calendar/add.vue';
import UpdateAppointment from '../views/calendar/detail.vue';
import Profile from '../views/profile/index.vue';
import Payment from '../views/profile/payment.vue';
import Reviews from '../views/reviews/index.vue';
import Help from '../views/help/index.vue';
import Offers from '../views/offers/index.vue';
import Offer from '../views/offer/index.vue';
import Import from '../views/products/import.vue';
import AddOffer from '../views/offer/add.vue';
import Login from '../views/login/index.vue';
import Services from '../views/services/index.vue';
import Register from '../views/register/index.vue';
import UpdateOffer from '../views/offer/update.vue';
import CustomerOffer from '../views/customer/offer/index';
import OfferStep1 from '../views/customer/offer/step1';
import OfferStep2 from '../views/customer/offer/step2';
import OfferStep3 from '../views/customer/offer/step3';
import Channel_detail from '@/views/sale/channel_detail';
import Files from '@/views/files/index';
import PaymentDetail from '@/views/payments/detail';
import Forgetpassword from "@/views/forgetpassword/index.vue";
import Resetpassword from "@/views/resetpassword/index.vue";
import Integrations from "@/views/integrations/index.vue";

import i18n from '@/i18n';

Vue.use(VueRouter);

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/products',
    name: 'Products',
    component: Products
  },
  {
    path: '/products-view-2',
    name: 'Products2',
    component: Products2
  },
  {
    path: '/product-detail/:id',
    name: 'ProductDetail',
    component: ProductDetail
  },
  {
    path: '/product-detail-2',
    name: 'ProductDetail2',
    component: ProductDetail2
  },
  {
    path: '/product-add',
    name: 'ProductAdd',
    component: ProductAdd
  },
  {
    path: '/product-add-2',
    name: 'ProductAdd2',
    component: ProductAdd2
  },
  {
    path: '/payments',
    name: 'Payments',
    component: Payments
  },
  {
    path: '/downloaded-files',
    name: 'Files',
    component: Files
  },
  {
    path: '/sales',
    name: 'Sales',
    component: Sales
  },
  {
    path: '/sale/:id',
    name: 'Sale',
    component: Sale
  },
  {
    path: '/invoices',
    name: 'Invoices',
    component: Invoices
  },
  {
    path: '/invoice-add',
    name: 'InvoiceAdd',
    component: InvoiceAdd
  },
  {
    path: '/customers',
    name: 'Customers',
    component: Customers
  },
  {
    path: '/customer/:id',
    name: 'Customer',
    component: Customer
  },
  {
    path: '/customer-add',
    name: 'CustomerAdd',
    component: CustomerAdd
  },
  {
    path: '/cars',
    name: 'Cars',
    component: Cars
  },
  {
    path: '/car/:id',
    name: 'Cars',
    component: CarDetail
  },
  {
    path: '/car-add',
    name: 'CarAdd',
    component: CarAdd
  },
  {
    path: '/calendar',
    name: 'Calendar',
    component: Calendar
  },
  {
    path: '/add-appointment',
    name: 'AddAppointment',
    component: AddAppointment
  },
  {
    path: '/edit-appointment/:id',
    name: 'UpdateAppointment',
    component: UpdateAppointment
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile
  },
  {
    path: '/payment',
    name: 'Payment',
    component: Payment
  },
  {
    path: '/reviews',
    name: 'Reviews',
    component: Reviews
  },
  {
    path: '/help-and-support',
    name: 'Help',
    component: Help
  },
  {
    path: '/offers',
    name: 'Offers',
    component: Offers
  },
  {
    path: '/offer/:id',
    name: 'Offer',
    component: Offer
  },
  {
    path: '/import',
    name: 'Import',
    component: Import
  },
  {
    path: '/add-offer',
    name: 'AddOffer',
    component: AddOffer
  },
  {
    path: '/update-offer/:id',
    name: 'UpdateOffer',
    component: UpdateOffer
  },
  {
    path: '/add-sale',
    name: 'AddSale',
    component: AddSale
  },
  {
    path: '/update-sale/:id',
    name: 'UpdateSale',
    component: UpdateSale
  },
  {
    path: '/services',
    name: 'Services',
    component: Services
  },
  {
    path: '/customer-register/:token',
    name: 'Register',
    component: Register
  },
  {
    path: '/customer-offer/detail/:token',
    name: 'CustomerOffer',
    component: CustomerOffer
  },
  {
    path: '/customer/offer/:token',
    name: 'OfferStep1',
    component: OfferStep1
  },
  {
    path: '/customer/offer/delivery/:token',
    name: 'OfferStep2',
    component: OfferStep2
  },
  {
    path: '/customer/offer/payment/:token',
    name: 'OfferStep3',
    component: OfferStep3
  },
  {
    path: '/sale-channel/:id',
    name: 'ChannelDetail',
    component: Channel_detail
  },
  {
    path: '/payment-detail/:id',
    name: 'PaymentDetail',
    component: PaymentDetail
  },
  {
    path: '/forget-password',
    name: 'Forgetpassword',
    component: Forgetpassword
  },
  {
    path: '/sifre-sifirlama/:token/:user_id',
    name: 'Resetpassword',
    component: Resetpassword
  },
  {
    path: '/integrations',
    name: 'Integrations',
    component: Integrations
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  i18n.locale = localStorage.getItem('locale') ?? 'tr';
  next();
});

export default router;
